import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { InfoCircleIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';

@Component({
    selector: 'app-btn',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input()
    type: 'button' | 'text' = 'button';

    @Input()
    disabled: boolean = false;

    @Input()
    small: boolean = false;

    @Output()
    clickEvent = new EventEmitter<string>();


    // ngOnChanges(changes: SimpleChanges) {
    //     console.log(changes)
    //     // if (changes.errorArray?.firstChange === false) {

    //     // }
    // }

    click(event: any) {
        if (!this.disabled) {
            this.clickEvent.emit()
        }
    }

    constructor() {
    }

    ngOnInit() {
    }

}

/**
 * Created by MosNeuro on 04.12.2017.
 */
export class CommonResponse {
  Code: number;
  Message: string;

  constructor(code: number = 0, message: string = '') {
    this.Code = code;
    this.Message = message;
  }
}

export class ListIdResponse extends CommonResponse {
  List: number[];
}

export class CommonRequest {
  ChangerId: number;
  SessionKey: string;
  UserAuthToken: string;

  constructor(changerId: number = 0, sessionKey: string = "", jwt = "") {
    this.ChangerId = changerId;
    this.SessionKey = sessionKey;
    this.UserAuthToken = jwt;
  }
}

export class SingleIdRequest extends CommonRequest {
  Id: number;

  constructor(changerId: number = 0, sessionKey: string = "", id = 0, jwt: string = "") {
    super(changerId, sessionKey, jwt);
    this.Id = id;
  }
}


export class SingleIdGiver extends CommonResponse {
  Id: number;
}

export class SingleStringRequest extends CommonRequest {
  Value: string;
  constructor(changerId: number = 0, sessionKey: string = "", value: string, jwt: string = "") {
    super(changerId, sessionKey, jwt);
    this.Value = value;
  }
}
export class SingleStringGiver extends CommonResponse {
  Value: string;
}




export class RenameAsker extends SingleIdRequest
{
    Name: string;
}

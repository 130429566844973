import { FolderIcon, PaperClipIcon, ArrowBottomIcon, FolderAddIcon } from './../../../shared/components/svg-icon/svg-icon.data';
import { Component, OnInit, Input, EventEmitter, Output, Inject, ViewChild, HostListener, SimpleChanges, SimpleChange, ElementRef } from '@angular/core';
import { FolderOut, FileGiver, ReplaceOldFile, FolderSystemSetting } from '../../../models/model.Files/Inner/model.FileSystemInner';
import { Common_LangSet, Files_LangSet, LanguageSetCommon } from '../../../models/model.language';
import { HttpServiceFile } from "../../../httpserv/httserv.file";
import { DirectoryAddAsker, FileUploadAsker, FileReplaceAsker } from '../../../models/model.Files/Out/model.FileSystemAsker';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { FolderGiver } from '../../../models/model.Files/In/model.FileSystemGiver';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { Router } from '@angular/router';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { SingleIdRequest, CommonResponse } from '../../../models/model.http';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';


import { finalize, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { StageSetToShowWithAccess } from '../../../models/model.stageSet';
import { SvgIconsRegistry } from '../../../shared/components/svg-icon/svg-icon-registry.service';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { DeleteSession } from '../../../store/actions/session.action';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'folder-element',
  templateUrl: './folder-element.component.html',
  styleUrls: ['./folder-element.component.scss',
    '../../../../style/font-awesome.min.scss',
    '../../../../style/supportLibrary.scss',
    '../../../../iconfont/material-icons.scss'],
  providers: [HttpServiceFile]
})
export class FolderElementComponent implements OnInit {
  @Input("folder") Folder: FolderOut;
  @Output() FolderChange = new EventEmitter<FolderOut>();


  @Input("access") FileAccess: number;
  @Input("rule") RuleAccess: number[];
  @Input("admin") AdminAccess: boolean;

  
  @Input("clientId") ClientId: number;
  @Input("projectId") ProjectId: number;
  @Input("level") Level: number;
  SessionKey: string;
  UserId: number;
  @Input("groupId") GroupId: number[];
  @Input("groupUserId") GroupUserId: number;

  @Input("setting") FileSystemSetting: FolderSystemSetting = new FolderSystemSetting();
  @Input("setting-search") searchInput: string;

  @Output() updateFiles: EventEmitter<any> = new EventEmitter();
  @Output() runFileLoading: EventEmitter<any> = new EventEmitter();
  @Output() stopFileLoading: EventEmitter<any> = new EventEmitter();
  @Output() makeFolderVisible: EventEmitter<any> = new EventEmitter();


  @ViewChild('hiddenInput') hiddenInput: ElementRef;

  // @ViewChild('compfolder') compfolder;

  // ChildsVisible: boolean = false;

  showAddFolder: boolean;
  addFolderLeftPosinionLeft: string = "80px";
  addFolderLeftPosinionTop: string = "-680px";
  replaceFilePosinionLeft: string = "80px";
  replaceFilePosinionTop: string = "-680px";
  // addFolderTopPosinion: string = "80px";

  InputToAddFolder: string = "";
  replaceFileShowDialog: boolean = false;
  objectToReplace: ReplaceOldFile = new ReplaceOldFile();

  isDifferentFormats: boolean;
  oldFileStringShort: string;
  oldFileStringFull: string;
  newFileStringShort: string;
  newFileStringFull: string;
  indexCheckOldFilesTags: number;
  oldfileFormat: string[];
  newfileFormat: string[];

  isNameAlreadyExists: boolean = false;
  background: string;
  opacity: string;

  isHover: boolean = false;


  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  files_Langset: Files_LangSet;
  fl_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  constructor(
    private store$: Store,
    private cookieService: CookieService,
    private httpServiceFile: HttpServiceFile,
    private router: Router,
    private sessionService: SessionService,
    private languageService: LanguageService,
    private cookieOptionsMy: CookieOptionsMy,
    @Inject(DOCUMENT) private document: Document,
    private svgIconsRegistry: SvgIconsRegistry
  ) {
    this.svgIconsRegistry.registerIcons([
      FolderIcon,
      FolderAddIcon,
      PaperClipIcon
    ])
  }

  changeHover(state: boolean) {
    this.isHover = state;
  }


  ngOnInit() {
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.fl_sub=this.languageService.filesLanguage$.subscribe(lang=>this.files_Langset=lang);
    this.Folder.ChildsVisible = (this.Level > 0) ? false : true;
    this.Folder.Visible = true;
    // console.log(this.Folder.Name)
    // console.log(this.GroupId);
    // console.log('-------')

  }

  ReplaceHtmlTags(string: string) {
    let separator = new RegExp(/<[^>]+>/, "gi");
    string = string.replace(separator, '');
    return string;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      if (!chng.isFirstChange()) {
        if (this.Level > 0) {
          this.Folder.Name = this.ReplaceHtmlTags(this.Folder.Name);
          if (this.FileSystemSetting.AdvancedMode && (this.FileSystemSetting.FileSearch != '' || this.FileSystemSetting.FileSearch.length != 0)) {
            let visibleCheck = 0;
            let filter = new RegExp(this.FileSystemSetting.FileSearch, 'gi')
            if (this.Folder.Name.match(filter)) {
              visibleCheck++;
              this.Folder.Name = SearchSelectHelper.getSearchedText(this.FileSystemSetting.FileSearch, this.Folder.Name);
            }
            // console.log(visibleCheck)
            if (visibleCheck > 0) {
              this.Folder.Visible = true;
              this.Folder.ChildsVisible = true;
              this.MakeFolderVisible()
            } else {
              this.Folder.Visible = false;
              this.Folder.ChildsVisible = false;
            }
          } else {
            this.Folder.Visible = true;
            this.Folder.ChildsVisible = (this.Level > 0) ? false : true;
          }
        }
      }

    }
  }

  MakeSelected() {
    this.Folder.isSelected = true;
    // document.getElementById("hidden_button_" + this.Folder.Id).click();
  }

  MakeNotSelected() {
    this.Folder.isSelected = false;
    // document.getElementById("hidden_button2_" + this.Folder.Id).click();
  }

  updateFilesEmit() {
    this.updateFiles.emit();
  }

  runFileLoadingEmit() {
    this.runFileLoading.emit();
  }

  stopFileLoadingEmit() {
    this.stopFileLoading.emit();
  }

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }


  keyControl(event: any) {
    if (event.keyCode == 27) {
      if (this.replaceFileShowDialog) {
        this.replaceFileShowDialogClose();
        event.stopPropagation();
      }
      if (this.showAddFolder) {
        this.closeAddFolder();
        event.stopPropagation();
      }
    }
    if (event.keyCode == 13 && this.showAddFolder) {
      this.AddFolder();
      event.stopPropagation();
    }

  }

  SelectAllFiles(Folder: FolderOut) {
    console.log('click check!')
    if (!this.Folder.isSelected) {
      if (Folder != null) {
        if (Folder.Files != null && Folder.Files.length > 0) {
          Folder.Files.forEach(element => {
            element.isSelected = true;
          });
        }
        if (Folder.Folders != null && Folder.Folders.length > 0) {
          Folder.Folders.forEach(element => {
            this.SelectAllFiles(element)
          });
        }
        Folder.isSelected = true;
      }
    } else {
      if (Folder != null) {
        if (Folder.Files != null && Folder.Files.length > 0) {
          Folder.Files.forEach(element => {
            element.isSelected = false;
          });
        }
        if (Folder.Folders != null && Folder.Folders.length > 0) {
          Folder.Folders.forEach(element => {
            this.SelectAllFiles(element)
          });
        }
        Folder.isSelected = false;
      }
    }
  }

  closeAddFolder() {
    this.showAddFolder = false;
    this.InputToAddFolder = '';
    this.addFolderLeftPosinionTop = "-680px";
    this.isNameAlreadyExists = false;
  }

  AddFolderShow(event: any) {
    if (this.Folder.Id == 0 || (this.FileAccess != 2 && !this.AdminAccess)) return;
    this.showAddFolder = !this.showAddFolder;
    if (this.showAddFolder) {
      // console.log(event);
      // let elementCoordinates = document.getElementById('foldebtn_' + this.Folder.Id).getBoundingClientRect();
      let h = window.innerHeight;
      setTimeout(() => {
        // let ElementCoordinates = document.getElementById('folderadd_' + this.Folder.Id).getBoundingClientRect();
        let heightAddDialog = 94;
        this.addFolderLeftPosinionLeft = event.clientX + 20 + "px";
        if (event.clientY + heightAddDialog > h) {
          this.addFolderLeftPosinionTop = h - heightAddDialog - 10 + "px";
        } else {
          this.addFolderLeftPosinionTop = event.clientY - heightAddDialog / 2 + "px";
        }
      }, 100);
      // this.addFolderLeftPosinion = 6 + 10 * this.Level + 50 + "px";
      // this.addFolderLeftPosinion = elementCoordinates.left.toString() + "px";
      // DOMHelper.FocusElement("input_add_" + this.Folder.Id, 50);
    }
  }

  checkNameAdFolder() {
    this.isNameAlreadyExists = false;
    if (this.InputToAddFolder != null && this.InputToAddFolder != undefined && this.InputToAddFolder != "") {
      if (this.Folder.Folders != null) {
        this.Folder.Folders.forEach(element => {
          if (element.Name == ExtendedHelper.DeleteAnySpaces(this.InputToAddFolder)) {
            this.isNameAlreadyExists = true;
          }
        });
      }
    }
  }

  AddFolder() {
    if (this.isNameAlreadyExists || this.InputToAddFolder.length == 0) return;
    this.runFileLoadingEmit();
    let checker = new DirectoryAddAsker();
    checker.Name = ExtendedHelper.ReplaceBadThings(this.InputToAddFolder);
    if (checker.Name.length > 100 || checker.Name == "") {
      alert(this.files_Langset.AccessGroupsEdition);
      return;
    }
    checker.ParentDirectoryId = this.Folder.Id;
    checker.SessionKey = this.SessionKey;
    checker.ChangerId = this.UserId;

    this.httpServiceFile.createDirectory(checker).subscribe((data: FolderGiver) => {
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 200) {
        alert(this.files_Langset.FolderNameInvalidSymbols);
        this.stopFileLoadingEmit();
        return;
      }
      if (data.Code == 201) {
        alert(this.files_Langset.ParentFolderRemoved);
        this.stopFileLoadingEmit();
        return;
      }
      if (data.Code == 301) {
        alert(this.files_Langset.NoAccessAddFolder);
        this.stopFileLoadingEmit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      if (this.Folder.Folders == null) {
        this.Folder.Folders = [];
      }
      // console.log(this.Folder);
      // console.log(data)
      this.Folder.Folders.push(data.Folder);
      this.InputToAddFolder = "";
      this.closeAddFolder();
      this.Folder.ChildsVisible = true;
      this.stopFileLoadingEmit();
    });
  }

  DeleteFolder() {
    if (confirm(this.files_Langset.RemoveFolderConfirm)) {
      this.runFileLoadingEmit();
      let checker = new SingleIdRequest(this.UserId, this.SessionKey, this.Folder.Id);
      this.httpServiceFile.deleteDirectory(checker).subscribe((data: CommonResponse) => {
        if (data.Code == 100) {
          this.unLogin();
          return;
        }
        if (data.Code == 200) {
          alert(this.files_Langset.NoAccessRemoveFolder);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 300) {
          alert(this.files_Langset.NoAccessRemoveFile);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 500) { console.error(data.Message); this.stopFileLoadingEmit(); return; }

        this.updateFilesEmit();
        this.stopFileLoadingEmit();
        // this.Folder = null;
        // this.LoadFiles();
      });
    }
  }

  MakeFolderVisible() {
    if (this.Level > 0) this.makeFolderVisible.emit();
    this.Folder.Visible = true;
    this.Folder.ChildsVisible = true;
  }

  FolderClick() {
    this.Folder.ChildsVisible = !this.Folder.ChildsVisible;
  }



  private destroy$ = new Subject<void>();
  deleteFileById(fileId: number) {
    let fileIndex = this.Folder.Files.findIndex(c => c.Id == fileId);
    if (fileIndex != -1) {
      this.Folder.Files.splice(fileIndex, 1);
    }
  }

  ReplaceDroppedFiles(object: ReplaceOldFile) {
    if (object.Files.length > 1) {
      alert(this.files_Langset.MoveOneFile);
      return;
    }
    this.replaceFileShowDialog = true;
    this.objectToReplace = object;
    this.indexCheckOldFilesTags = this.Folder.Files.findIndex(c => c.Id == this.objectToReplace.FileId);

    this.oldfileFormat = object.OldName.split('.');
    this.newfileFormat = object.Files[0].name.split('.');
    this.oldFileStringShort = '';
    this.newFileStringShort = '';
    this.oldFileStringFull = object.OldName;
    this.newFileStringFull = object.Files[0].name;
    if (this.oldfileFormat[this.oldfileFormat.length - 1] != this.newfileFormat[this.newfileFormat.length - 1]) {
      this.isDifferentFormats = true;
      for (let i = 0; i < this.oldfileFormat.length; i++) {
        if (i != this.oldfileFormat.length - 1) {
          this.oldFileStringShort = this.oldFileStringShort + this.oldfileFormat[i];
        }
      }
      for (let i = 0; i < this.newfileFormat.length; i++) {
        if (i != this.newfileFormat.length - 1) {
          this.newFileStringShort = this.newFileStringShort + this.newfileFormat[i];
        }
      }
      if (this.oldFileStringShort.length > 7) {
        let buffer = '';
        for (let i = 0; i < this.oldFileStringShort.length; i++) {
          if (i < 7) {
            buffer = buffer + this.oldFileStringShort[i];
          }
        }
        this.oldFileStringShort = buffer + '... .' + this.oldfileFormat[this.oldfileFormat.length - 1];
      } else {
        this.oldFileStringShort = this.oldFileStringFull;
      }
      if (this.newFileStringShort.length > 7) {
        let buffer = '';
        for (let i = 0; i < this.newFileStringShort.length; i++) {
          if (i < 7) {
            buffer = buffer + this.newFileStringShort[i];
          }
        }
        this.newFileStringShort = buffer + '... .' + this.newfileFormat[this.newfileFormat.length - 1];
      } else {
        this.newFileStringShort = this.newFileStringFull;
      }

    } else {
      this.isDifferentFormats = false;
    }

    let h = window.innerHeight;
    setTimeout(() => {
      let ElementCoordinates = document.getElementById('folderbtn_' + this.Folder.Id).getBoundingClientRect();
      let ElementCoordinatesFile = document.getElementById('InfoFile_' + object.FileId).getBoundingClientRect();
      this.replaceFilePosinionLeft = ElementCoordinates.left + 20 + "px";
      if (ElementCoordinates.top + ElementCoordinates.height > h) {
        this.replaceFilePosinionTop = h - ElementCoordinatesFile.height - 50 + "px";
      } else {
        this.replaceFilePosinionTop = ElementCoordinatesFile.top - 90 + "px";
      }
    }, 100);

    DOMHelper.FocusElement("replaceOldFileBtn", 100);
  }

  replaceFileShowDialogClose() {
    this.replaceFileShowDialog = false;
    this.replaceFilePosinionTop = "-680px";
    // (<HTMLInputElement>document.getElementById('file_' + this.objectToReplace.FileId)).value = '';
  }



  ReplaceDroppedFilesToServer() {
    this.runFileLoadingEmit();
    if (this.objectToReplace.Files[0]) {
      let fileToUpload = this.objectToReplace.Files[0];
      let str = new FileReplaceAsker(this.UserId, this.SessionKey);
      str.FolderId = this.Folder.Id;//ид папки
      str.FileId = this.objectToReplace.FileId;
      str.Filename = '';
      if (this.objectToReplace.Type == 0) {
        for (let i = 0; i < this.oldfileFormat.length; i++) {
          if (i != this.oldfileFormat.length - 1) {
            str.Filename += this.oldfileFormat[i];
          }
        }
        str.Filename = this.newfileFormat[this.newfileFormat.length - 1];
        // str.Filename = this.objectToReplace.OldName;
      } else {
        if (this.objectToReplace.Type == 1) {
          str.Filename = this.objectToReplace.Files[0].name;//название файла
        }
      }
      str.Type = this.objectToReplace.Type;
      str.KeepTags = this.objectToReplace.KeepTags;
      const str2 = JSON.stringify(str);
      let input = new FormData();
      input.append("data", fileToUpload, str2);
      this.httpServiceFile.replaceFile(input).subscribe((data: FileGiver) => {
        if (data.Code == 100) {
          this.unLogin();
          return;
        }
        if (data.Code == 200 || data.Code == 500) {
          console.log(data.Message);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 201) {
          console.log(data.Message);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 300) {
          console.log(data.Message);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 301) {
          console.log(data.Message);
          this.stopFileLoadingEmit();
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        if (this.Folder.Files == null) {
          this.Folder.Files = [];
        }
        let fileIndex = this.Folder.Files.findIndex(c => c.Id == this.objectToReplace.FileId);
        if (fileIndex != -1) {
          this.Folder.Files[fileIndex] = data.File;
        };
        this.objectToReplace = new ReplaceOldFile();
        this.replaceFileShowDialogClose();
        this.stopFileLoadingEmit();
      });
    }
  }

  chooseAndUploadFile() {
    // let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    let element: HTMLElement = document.getElementById("file_input_" + this.Folder.Id) as HTMLElement;
    this.hiddenInput.nativeElement.value = '';
    element.click();
  }

  UploadDroppedFilesToServer(event: any) {
    if (this.FileAccess != 2 && !this.AdminAccess) return;
    let files;
    if (event.target.files != null) {
      files = event.target.files;
    } else {
      files = event.dataTransfer.files;
    }
    // console.log(event);
    if (files && files.length > 0) {
      this.runFileLoadingEmit();
      let size = files.length;
      for (var i = 0; i < files.length; i++) {
        if (files[i]) {
          let fileToUpload = files[i];
          let str = new FileUploadAsker();
          str.FolderId = this.Folder.Id;//ид папки
          str.Filename = files[i].name;//название файла
          str.Type = 0;//тип загрузки FileUploadType
          str.ChangerId = this.UserId;
          str.SessionKey = this.SessionKey;
          const str2 = JSON.stringify(str);
          let input = new FormData();
          input.append("data", fileToUpload, str2);
          this.httpServiceFile.uploadFile(input).subscribe((data: FileGiver) => {
            if (data.Code == 100) {
              this.unLogin();
              return;
            }
            if (data.Code == 200) {
              console.log(data.Message);
              this.stopFileLoadingEmit();
              return;
            }
            if (data.Code == 201) {
              this.stopFileLoadingEmit();
              console.log(data.Message);
              return;
            }
            if (data.Code == 300) {
              this.stopFileLoadingEmit();
              console.log(data.Message);
              return;
            }
            if (data.Code == 301) {
              console.log(data.Message);
              this.stopFileLoadingEmit();
              return;
            }
            if (data.Code == 500) { console.error(data.Message); return; }

            size--;
            if (this.Folder.Files == null) {
              this.Folder.Files = [];
            }
            this.Folder.Files.push({...data.File});
            this.FolderChange.emit(this.Folder);
            console.log(this.Folder)
            this.Folder.ChildsVisible = true;
            this.stopFileLoadingEmit();
          });
        }
      }
    } else {
      this.stopFileLoadingEmit();
    }
  }


}

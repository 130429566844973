import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InfoCircleIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {

    constructor(
    ) {
    }

    ngOnInit() {
    }

}

import { Injectable } from "@angular/core";
import { SvgIcon } from "./svg-icon.data";




@Injectable({
    providedIn: 'root'
})

export class SvgIconsRegistry {
    private registry = new Map<string, string>();

    public registerIcons(icons: SvgIcon[]) {
        icons.forEach((icon: SvgIcon) => this.registry.set(icon.name, icon.data))
    }

    public getIcon(iconName: string): string | undefined {
        if (!this.registry.has(iconName)) {
            console.warn(`We could not find the Icon with name ${iconName}, did you add it to the Icon registry?`)
        }
        return this.registry.get(iconName);
    }

}
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { AddressAutocompleteContainer } from '../../models/model.Address/Inner/AddressAutocomplete/model.AddressAutocompleteContainer';
import { Common_LangSet, LanguageSetCommon } from '../../models/model.language';
import { AddressInputContainerInner } from '../../models/model.Address/Inner/InputContainer/model.AddressInputContainerInner';
// import { PersonCrudContainer } from '../../models/model.Person/Inner/model.PersonCrudContainer';
import { HttpServiceAddressAny } from '../../httpserv/AddressServices/httpserv.addressAny';
import { AddressFilterAsker } from '../../models/model.Address/Out/Any/model.AddressFilterAsker';
import { AddressAutocompleteListGiver } from '../../models/model.Address/In/Any/Autocomplete/model.AddressAutocompleteListGiver';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { Router } from '@angular/router';
import { CookieOptionsMy } from '../../config/CookieOptionsMy';
import * as _ from 'lodash';
import { ClientRolesToSelectInProjectGiver } from '../../models/model.clientRole';
import { DOMHelper } from '../../Helpers/DOMHelper';
import { AddressInputComponentInner } from '../../models/model.Address/Inner/InputContainer/model.AddressInputComponentInner';
import { AddressAutocompleteChainInner } from '../../models/model.Address/Inner/AddressAutocomplete/model.AddressAutocompleteChainInner';
// import { elementEnd } from '@angular/core/src/render3/instructions';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { SvgIconsRegistry } from '../../shared/components/svg-icon/svg-icon-registry.service';
import { CancelArrowBackIcon } from '../../shared/components/svg-icon/svg-icon.data';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { DeleteSession } from '../../store/actions/session.action';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { ClientAttachedToProjectListGiver } from '../../models/model.client';


type Changes = {
  addressMassive: SimpleChange;
};

@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['../../../style/font-awesome.min.scss', './address-input.component.scss', '../../../style/supportLibrary.scss', '../../../iconfont/material-icons.scss']
})
export class AddressInputComponent implements OnInit, AfterViewInit {

  AddressInputContainerInner: AddressInputContainerInner;
  AddressState: number = 1;// 0-редактирование, 1-представление
  addressAutocompleteElementId: string = "addressAutocompleteElement";
  addressAutocompleteListId: string = "addressAutocompleteList";
  addressAutocompleteRequestNumber = 0;
  personAddressInputId: string = "personAddressInput";
  addressAutocompleteContainer: AddressAutocompleteContainer;
  // @Input("address") addressAutocompleteContainer: AddressAutocompleteContainer;
  // @Input("personInput") personCrudContainer: PersonCrudContainer;
  @Input("address") addressMassive: AddressInputComponentInner[];
  addressMassiveBuffer: AddressInputComponentInner[];
  @Input("AddressString") AddressString: string;

  @Input("SaveBtn") SaveBtn: boolean = true;
  @Input("StartFromEdit") StartFromEdit: boolean = false;
  @Input("ChngVal") ChngVal: boolean = true;
  @Input("Changeble") Changeble: boolean = true;
  @Input("Closeble") Closeble: boolean = true;
  // @Input("Saving") Saving: Function;
  @Input("Saving") Saving: boolean = false;
  @Input("OnlyEdit") OnlyEdit: boolean = false;

  @Input("serial") autoId: any;
  @Output() saveAddress: EventEmitter<any> = new EventEmitter();
  @Output() changeAddress: EventEmitter<any> = new EventEmitter();
  @Input("search") search: boolean = false;
  @Output() searchAddress: EventEmitter<any> = new EventEmitter();

  SessionKey: string;
  UserId: number = 0;
  UserName: string;
  showAutoComplete: boolean = false;
  Minimized: boolean;
  isFullAddress: boolean;
  isWasChange: boolean;

  selecting: boolean = false;

  @Input()
  setMinimise: boolean = false;


  isFullEmpty: boolean;

  
  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;


  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private httpServiceAddressAny: HttpServiceAddressAny,
    private cookieOptionsMy: CookieOptionsMy,
    private sessionService: SessionService,
    private svgIconsRegistry: SvgIconsRegistry,
    private languageService: LanguageService
  ) {
    this.svgIconsRegistry.registerIcons([
      CancelArrowBackIcon
    ])
  }

  ngOnInit() {
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);

    this.addressMassiveBuffer = _.cloneDeep(this.addressMassive);

    if (this.checkIsEmpty() && this.setMinimise) {
      this.Minimized = false;
      this.checkSave();
      this.checkChange();
      setTimeout(() => {
        this.resizeAddressInputAll();
      }, 100);
    } else {
      this.Minimized = true;
      this.checkSave();
      this.checkChange();
    }

  }

  ngAfterViewInit() {
    if(this.StartFromEdit)
    {
      this.editAddressPencil();
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      if (!change.isFirstChange()) {
        setTimeout(() => {
          this.addressMassiveBuffer = _.cloneDeep(this.addressMassive);
          this.resizeAddressInputAll();
          this.AddressAutocompleteFill(null);
          // this.addressMassiveBuffer.forEach((element, index) => {
          //   this.addressMassive[index].Value = element.Value;
          // });
        }, 100);
      }
    }
  }


  SearchEmit() {
    this.searchAddress.emit();
    this.CloseAutocomplete();
    this.resizeAddressInputAll();
  }

  editAddressPencil() {
    this.addressMassiveBuffer.forEach((element, index) => {
      this.addressMassive[index].Value = element.Value;
    });
    // this.addressMassiveBuffer = _.cloneDeep(this.addressMassive);
    // this.addressMassive = _.cloneDeep(this.addressMassiveBuffer);

    this.resizeAddressInputAll();
    this.checkChange();
    this.checkSave();
    let focusElementId = -1;
    for (let index = 0; index < this.addressMassive.length; index++) {
      const element = this.addressMassive[index];
      if (element.Required) { focusElementId = index; } else { break; }
    }
    setTimeout(() => {
      this.Minimized = false;
      DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + focusElementId, 50);
    }, 100);
  }

  Minimize() {
    this.AddressString = "";
    this.addressMassiveBuffer = _.cloneDeep(this.addressMassive);

    if (this.addressMassive != null) {
      for (let i = 0; i < this.addressMassive.length; i++) {
        let comp = this.addressMassive[i];

        if (ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) continue;
        if (i > 0 && this.AddressString.length > 0) {
          this.AddressString += ", ";
        }
        this.AddressString += comp.Value;
      }
    }
    if (this.Saving) {
      this.saveAddress.emit();
      
    }
    if (this.Closeble) {
      this.Minimized = true;
    } else {
      this.isFullAddress = false;
      let focusElementId = -1;
      for (let index = 0; index < this.addressMassive.length; index++) {
        const element = this.addressMassive[index];
        if (element.Required) { focusElementId = index; } else { break; }
      }
      DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + focusElementId, 50);
      for (let i = 0; i < this.addressMassive.length; i++) {
        this.addressMassive[i].Value = "";
      }
      this.resizeAddressInputAll();
      this.checkChange();
      this.checkSave();
    }

  }

  NotMinimize() {
    this.AddressString = "";
    if (this.addressMassive != null) {
      for (let i = 0; i < this.addressMassive.length; i++) {
        let comp = this.addressMassive[i];
        if (ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) continue;
        if (i > 0 && this.AddressString.length > 0) {
          this.AddressString += ", ";
        }
        this.AddressString += comp.Value;
      }
    }
  }

  MinimizeNotSave() {
    this.addressMassiveBuffer.forEach((element, index) => {
      this.addressMassive[index].Value = element.Value;
    });
    // this.addressMassiveBuffer = _.cloneDeep(this.addressMassive);
    // this.addressMassive = _.cloneDeep(this.addressMassiveBuffer);
    this.NotMinimize();
    this.Minimized = true;
    this.showAutoComplete = false;
  }


  checkChange() {
    for (let i = 0; i < this.addressMassive.length; i++) {
      const element = this.addressMassive[i];
      const element2 = this.addressMassiveBuffer[i];
      if (element.Value == element2.Value) { this.isWasChange = false; } else { this.isWasChange = true; break; }
    }
  }

  checkSave() {
    // for (let i = 0; i < this.addressMassive.length; i++) {
    //   let comp = this.addressMassive[i];
    //   if (ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) continue;
    //   if (i > 0 && this.AddressString.length > 0) {
    //     this.AddressString += ", ";
    //   }
    //   this.AddressString += comp.Value;
    // }
    this.NotMinimize();
    for (let i = 0; i < this.addressMassive.length; i++) {
      const element = this.addressMassive[i];
      if (element.Value == null || element.Value.length == 0 && element.Required
        || element.Value.length > 0 && ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(element.Value) && element.Required
        || (element.Value.length == 0 || element.Value.length > 0 && ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(element.Value)) && this.addressMassive[i + 1] != null && (this.addressMassive[i + 1].Value.length > 0 && !ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(this.addressMassive[i + 1].Value)) && !element.Required) {
        this.isFullAddress = false;
        break;
      } else {
        this.isFullAddress = true;
      }
    }
  }

  checkIsFullEmpty() {
    for (let i = 0; i < this.addressMassive.length; i++) {
      const element = this.addressMassive[i];
      if (ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(element.Value)) {
        this.isFullEmpty = true;
      } else {
        this.isFullEmpty = false;
        break;
      }
    }
  }

  PutAddressFromAutocomplete(chain: AddressAutocompleteChainInner) {
    // this.selecting = true;
    for (let i = 0; i < chain.Components.length; i++) {
      chain.Components[i].Value = ExtendedHelper.ReplaceHtmlTags(chain.Components[i].Value);
      this.addressMassive[i].Value = ExtendedHelper.ReplaceHtmlTags(chain.Components[i].Value);
    }

    if (this.Saving ) {
      this.changeAddress.emit(chain);
      }

    if (this.Saving && !this.SaveBtn) {
      this.saveAddress.emit(chain);
      return;
    }

    
    setTimeout(() => {
      this.checkSave();
      this.checkChange();
    }, 50);

    let focusElementId = -1;
    for (let index = 0; index < this.addressMassive.length; index++) {
      const element = this.addressMassive[index];
      if (element.Value.length == 0) { focusElementId = index; break; }
    }
    setTimeout(() => {
      DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + focusElementId, 50);
    }, 100);
    // this.selecting = false;
  }

  checkIsEmpty() {
    let isEmpty = true;
    for (let i = 0; i < this.addressMassive.length; i++) {
      const element = this.addressMassive[i];
      if (element.Value != null && element.Value.length != 0) { isEmpty = false; break; }
    }
    return isEmpty;
  }

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }

  keyControl(event, index: number) {
    if (event.keyCode == 39) {//right arrow
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index);
      var end = input.selectionEnd;
      if (this.addressMassive[index].Value.length == end) {
        DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + (index + 1), 50);
      }
    }
    // if (event.keyCode == 35) {//end
    //   DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + (index + 1), 50);
    // }
    if (event.keyCode == 37) {//left arrow
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index);
      var start = input.selectionStart;
      if (start == 0) {
        DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + (index - 1), 50);
      }
    }
    if (event.keyCode == 8) {//backspace
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index);
      var start = input.selectionStart;
      if (start == 0) {
        DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + (index - 1), 50);
      }
    }
    if (event.keyCode == 13 && this.showAutoComplete && this.addressAutocompleteContainer.FindSelectedIndex() >= 0) {
      event.preventDefault();
      event.stopPropagation();
      if (this.addressAutocompleteContainer != null) {
        setTimeout(() => {
          let selected = this.addressAutocompleteContainer.FindSelectedChain();
          if (selected != null) {
            this.PutAddressFromAutocomplete(selected);
            this.CloseAutocomplete();
            this.resizeAddressInputAll();
            this.addressAutocompleteContainer = null;

            return;
          }

        }, 100);
      }
    }
    if (event.keyCode == 13 && event.ctrlKey && this.SaveBtn) {
      if (this.isFullAddress) {
        this.Minimize();
      }
      event.stopPropagation();
    }
    if (event.keyCode == 13 && this.search && this.SaveBtn) {
      this.SearchEmit();
    }
    if (event.keyCode == 27 && this.Closeble) {
      this.MinimizeNotSave();
      event.stopPropagation();
    }
    if (event.keyCode == 40 && this.addressAutocompleteContainer != null)
      this.addressAutocompleteContainer.GoDownInContainer(this.addressAutocompleteElementId, this.addressAutocompleteListId + '_' + this.autoId);
    if (event.keyCode == 38 && this.addressAutocompleteContainer != null)
      this.addressAutocompleteContainer.GoUpInContainer(this.addressAutocompleteElementId, this.addressAutocompleteListId + '_' + this.autoId);

  }

  AddressAutocompleteFill(event: any, activeLevel?: number) {
    if (event != null
      && (event.keyCode == 13
        || (event.keyCode >= 37 && event.keyCode <= 40
          && this.addressAutocompleteContainer != null && this.addressAutocompleteContainer.Chains.length > 0))) return;
    if (this.addressMassive == null)
      return;

    this.addressAutocompleteRequestNumber = this.addressAutocompleteRequestNumber == 50 ?
      0
      : this.addressAutocompleteRequestNumber + 1;

    // if (this.checkIsEmpty()) {
    //   this.addressAutocompleteContainer = null;
    //   return;
    // }
    // this.checkColumnPosition(activeLevel);
    this.checkIsFullEmpty();
    this.addressMassive.forEach(addressChain => {
      addressChain.Value = addressChain.Value.trim();
      // = ExtendedHelper.DeleteAnySpaces(addressChain.Value);
    });
    let requestNum = this.addressAutocompleteRequestNumber;
    let checker = new AddressFilterAsker(this.UserId, this.SessionKey, this.addressMassive, (activeLevel) ? activeLevel : 0);
    this.httpServiceAddressAny.autocompleteListGet(checker).subscribe((data: AddressAutocompleteListGiver) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      if (requestNum != this.addressAutocompleteRequestNumber) return;

      console.log("autocompletestart");
      this.addressAutocompleteContainer = new AddressAutocompleteContainer(data);
      console.log(this.addressAutocompleteContainer)
      this.addressAutocompleteContainer.Chains.forEach(element => {
        element.Components.forEach((addressChild, index) => {
          if (this.addressMassive[index].Value.length > 0 && addressChild.Value.length > 0) {
            addressChild.Value = SearchSelectHelper.getSearchedText(this.addressMassive[index].Value, addressChild.Value);
          }
        })
      })
    },
      error => {
        console.error(error["message"]);
      });
  }

  checkColumnPosition(activeLevel: number) {
    setTimeout(() => {
      //console.log("startshow");
      // let addressContainer = document.getElementById("autocompleteContainer" + this.autoId);
      // addressContainer.style.right = "4px";
      if (this.addressAutocompleteContainer != null) {
        if (activeLevel > 0) {
          //console.log(activeLevel)
          let inputBoxStart = document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + 0).getBoundingClientRect();
          //let inputBox = document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + activeLevel).getBoundingClientRect();
          //let moveLeft = 0;
         // let divTable = document.getElementById(this.addressAutocompleteListId + "_" + this.autoId).getBoundingClientRect();
          //let divColumn = document.getElementsByClassName("column-level-" + activeLevel)[0].getBoundingClientRect();
          //console.log(inputBoxStart)
         // console.log("Move left: " + (divColumn.left - inputBox.left) + "px")
          let addressContainer = document.getElementById("autocompleteContainer" + this.autoId);
         // addressContainer.style.left = inputBoxStart.left + "px";
          // Array.from(document.getElementsByClassName("column-level-" + activeLevel)).forEach(
          //   function (element, index, array) {
          //     element.style.marginLeft = `${(element.getBoundingClientRect().left - inputBox.left - 4)}px`;
          //   }
          // );

        }
      }
    })
  }


  OpenAutocomplete(activeLevel: number) {
    this.showAutoComplete = true;
    this.checkColumnPosition(activeLevel);
    this.AddressAutocompleteFill(null, activeLevel);
  }



  resizeAddressInputAll() {
    for (let index = 0; index < this.addressMassive.length; index++) {
      if (this.addressMassive[index].Value.length > 3) {
        if (this.addressMassive[index].Value.length > 8) {
          document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', this.addressMassive[index].Value.length - 1 + 'px');
        } else {
          document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', this.addressMassive[index].Value.length - 2 + 'px');
        }
      } else {
        if (this.addressMassive[index].Value.length == 0) {
          if (document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).getAttribute('placeholder').length > 7) {
            document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).getAttribute('placeholder').length + 'px');
          } else {
            document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).getAttribute('placeholder').length - 2 + 'px');
          }
        } else {
          document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', 1.5 + 'px');
        }
      }
    }
  }

  resizeAdressInput(event: any, index: number) {
    if (event.keyCode != 13) {
      this.checkChange();
      this.checkSave();
      if (this.addressMassive[index].Value.length > 3) {
        document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', this.addressMassive[index].Value.length - 2 + 'px');
      } else {
        if (this.addressMassive[index].Value.length == 0) {
          document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).getAttribute('placeholder').length - 2 + 'px');
        } else {
          document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index).setAttribute('size', 1.5 + 'px');
        }
      }

    }
  }

  // resizeAdressDiv(adress, component, index, autoId = 0) {
  //   let idText = "div_" + autoId + "_" + adress + component;
  //   let adressText = <HTMLDivElement>document.getElementById(idText);
  //   let idInput = adress + component;
  //   //let adressInput =<HTMLDivElement>document.getElementById(idInput);
  //   adressText.textContent = this.addressMassive[index].Value;
  // }

  CloseAutocomplete() {
    if (this.selecting) return;
    let isClose: boolean = true;
    setTimeout(() => {
      for (let index = 0; index < this.addressMassive.length; index++) {
        if (document.getElementById("div_" + this.autoId + "_" + this.personAddressInputId + index) == document.activeElement) {
          isClose = false;
          break;
        }
      }
      if (isClose) {
        this.showAutoComplete = false;
        // this.autocompleteDisplay = "none";
        // this.adressVisible = 'hidden';
        //this.addressAutocompleteContainer = null;
      }
    }, 100);
  }

  ChangeAddressTextFromDivToInput(adress, component, index, event: any, autoId = 0) {
    let idText = "div_" + autoId + '_' + adress + component;
    let adressText = <HTMLDivElement>document.getElementById(idText);
    if (event.keyCode == 17) {
      adressText.innerText = adressText.textContent;
      let range = document.createRange();
      range.selectNodeContents(adressText);
      range.collapse(false);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

    }
    this.addressMassive[index].Value = adressText.textContent;
    this.AddressAutocompleteFill(event);
    this.checkSave();
    this.checkChange();
  }

  ClearAddress(autoId = 0) {
    for (let i = 0; i < this.addressMassive.length; i++) {
      this.addressMassive[i].Value = "";
    }
    let focusElementId = -1;

    for (let index = 0; index < this.addressMassive.length; index++) {
      const element = this.addressMassive[index];
      if (element.Required) { focusElementId = index; } else { break; }
    }
    DOMHelper.FocusElement("div_" + this.autoId + "_" + this.personAddressInputId + focusElementId, 50);
    this.resizeAddressInputAll();
    this.checkChange();
    this.checkSave();
    this.isFullAddress = true;
  }

  clearAdressPlaceholder(adress, component, index, autoId = 0) {
    let idText = "div_" + autoId + '_' + adress + component;
    let adressText = <HTMLDivElement>document.getElementById(idText);
    if (adressText.textContent == this.addressMassive[index].Placeholder) {
      adressText.textContent = "";
      adressText.classList.remove('redText');
      adressText.classList.remove('grayText');
    }
  }


  SetAdressPlaceholder(adress, component, index, autoId = 0) {
    let idText = "div_" + autoId + "_" + adress + component;
    let adressText = <HTMLDivElement>document.getElementById(idText);
    if (adressText.textContent == null || adressText.textContent == '' || adressText.textContent == undefined) {
      adressText.textContent = this.addressMassive[index].Placeholder;
    }
  }

  SetEmptyAdressPlaceholders(autoId = 0) {
    let inputs = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName('inputdiv' + autoId);
    for (let i = 0; i < this.addressMassive.length; i++) {
      if (inputs[i].textContent == null || inputs[i].textContent == '' || inputs[i].textContent == undefined) {
        inputs[i].textContent = this.addressMassive[i].Placeholder;
        if (this.addressMassive[i].Required) {
          inputs[i].classList.add('redText');
        }
        else {

          inputs[i].classList.add('grayText');
        }
      }
    }
  }


  ChooseAdress(autoId = 0) {
    let inputs = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName('inputdiv' + autoId);
    for (let i = 0; i < this.addressMassive.length; i++) {

      inputs[i].textContent = this.addressMassive[i].Value;
      inputs[i].classList.remove('redText');
      inputs[i].classList.remove('grayText');
    }
    this.SetEmptyAdressPlaceholders();
  }

  focusInput(level: number, autoId) {
    setTimeout(() => {
      this.OpenAutocomplete(level);
    }, 100);
  }

  blurInput(autoId) {
    this.SetEmptyAdressPlaceholders(autoId);
    this.CloseAutocomplete();
  }

  loseFocusInput(event: any, id, autoId) {
    if (event.keyCode == 9) {
      this.SetEmptyAdressPlaceholders(autoId);
      if ((this.addressMassive != null && id == this.addressMassive.length - 1
        && !event.shiftKey) || (this.addressMassive != null && id == 0 && event.shiftKey)) {
        this.CloseAutocomplete();
      }
    }
  }

  PrevInput(event: any) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

}


import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 29.11.2016.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {JobLight, JobToSend, FilterJob, JobRedact, JobAsker, JobAskerOneOrUpper} from '../models/model.job'



import {Deleting} from "../models/model.deleting";
import {IdToId} from "../models/model.idtoid";
import {SingleIdRequest} from "../models/model.http";

@Injectable()
export class HttpServiceJob {

    constructor(private http: HttpClient,private param:ServerString) {
    }

    addJob(obj: JobToSend) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobadd', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    deleteJob(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobdelete', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    searchJobs(obj: JobAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobsearchalter', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    searchJobsOneOrUpper(obj: JobAskerOneOrUpper) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'JobGetOneOrUpper', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    getJobsCounter(obj: JobAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobcountalter', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    changeJobStatus(obj: Deleting) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobchangestatus', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    sendRedactedJob(obj: JobRedact) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'jobredact', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }

    updateOneJob(obj:Deleting)
    {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'JobUpdateOne', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {return observableThrowError(error);}));
    }



}

/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import { LocalStorageService } from 'angular-2-local-storage';
import { ClientInProjectFilterWithUnion } from '../../models/model.ClientInProjectFilter';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientLight } from '../../models/model.client';
import { ProjectLight } from '../../models/model.project';
import { Observable ,  Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TagHelper } from '../../Helpers/taghelper';
import { ElementWithVisible, ElementWithSelect } from '../../models/model.elementWithVisible';
import * as _ from 'lodash';
import { ExtendedHelper } from '../../config/ExtendedHelper';


declare var require: any;


@Component({
  selector: 'rolesFormElement',
  templateUrl: 'temp.rolesFormElement.html',
  styleUrls: ['rolesFormElement.scss',
    '../../../style/style.scss',
    '../../../style/supportLibrary.scss',
    '../../../style/font-awesome.min.scss',
    '../../../iconfont/material-icons.scss'
  ],

})
export class RolesFormElementComponent implements OnInit {
  //@Input() closable = true;
  @Input() inputElements: any[] = [];
  searchFilter: string = "";


  @Input() idPrefix: string;
  @Input() entityId: number;
  @Input() rolesForForm: Subject<any[]> = new Subject();
  @Input() closeRolesForm: Subject<boolean> = new Subject();

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() attachElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() openFormClick: EventEmitter<number> = new EventEmitter<number>();
  CloseText: string = "";
  Elements: ElementWithSelect[] = [];
  ElementsBack: ElementWithSelect[] = [];
  formVisible: boolean = false;
  formLeft: string = "0px";
  formTop: string = "0px";
  waitOpen: boolean = false;






  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
  ) { }




  ngOnInit() {
    this.LanguageSetGet();
    if (this.inputElements != null) {
      for (let i = 0; i < this.inputElements.length; i++) {
        let elem = new ElementWithSelect();
        elem.Element = _.cloneDeep(this.inputElements[i]);
        elem.Visible = true;
        elem.Selected = false;
        this.Elements.push(elem);
      }
      this.ElementsBack = _.cloneDeep(this.Elements);
    }

    this.rolesForForm.subscribe(c => {
      this.setRoles(c);
    });

    this.closeRolesForm.subscribe(c => {
      this.formVisible = false;
    });
    //this.ScrollTrackerSubscription();
    //        this.renderText=true;
    // this.element[0].focus();
  }

  setRoles(roles: any[]) {
    this.Elements = [];
    this.ElementsBack = [];
    if (roles != null && roles.length > 0) {
      for (let i = 0; i < roles.length; i++) {
        let elem = new ElementWithSelect();
        elem.Element = _.cloneDeep(roles[i]);
        elem.Visible = true;
        elem.Selected = false;
        this.Elements.push(elem);
      }
      this.ElementsBack = _.cloneDeep(this.Elements);
      this.show();
    }
    else{
      this.waitOpen = false;
      this.formVisible = false;
      this.visibleChange.emit(true);
    }
  }

  close() {
    if (this.formVisible == true) {
      this.formVisible = false;
      this.visibleChange.emit(true);
    }
  }


  show() {
    if (this.formVisible == false && this.waitOpen) {
      //this.setElements();
      let button = <HTMLButtonElement>document.getElementById("button" + this.idPrefix + this.entityId);
      let elementCoordinates = button.getBoundingClientRect();
      let clientWidth = document.documentElement.clientWidth;
      let clientHeight = document.documentElement.clientHeight;
      if (clientWidth - elementCoordinates.right > 310) {
        this.formLeft = (elementCoordinates.right + 4).toString() + "px";
      }
      else {
        this.formLeft = (elementCoordinates.left - 304).toString() + "px";
      }

      if (clientHeight - elementCoordinates.top > 310) {
        this.formTop = (elementCoordinates.top).toString() + "px";
      }
      else {
        if (elementCoordinates.top > 310) {
          this.formTop = (elementCoordinates.top - 300).toString() + "px";
        }
        else {
          this.formTop = "15px";
        }
      }
      this.searchFilter = "";
      this.formVisible = true;
      setTimeout(() => {
        let filter = <HTMLInputElement>document.getElementById("search" + this.idPrefix + this.entityId);
        if (filter != undefined) {
          filter.focus();
        }
      }, 0);
      this.waitOpen = false;
    }
  }

  openForm() {
    this.waitOpen = true;
    this.openFormClick.emit(this.entityId);
  }


  setElements() {
    this.Elements = [];
    this.ElementsBack = [];

    if (this.inputElements != null) {
      for (let i = 0; i < this.inputElements.length; i++) {
        let elem = new ElementWithSelect();
        elem.Element = _.cloneDeep(this.inputElements[i]);
        elem.Visible = true;
        elem.Selected = false;
        this.Elements.push(elem);
      }
      this.ElementsBack = _.cloneDeep(this.Elements);
    }
  }




  changeFormVisible(evenr: any) {

    if (this.formVisible == true) {
      this.formVisible = false;
    }
    else {
      this.setElements();
      let button = <HTMLButtonElement>event.target;
      let elementCoordinates = button.getBoundingClientRect();
      let clientWidth = document.documentElement.clientWidth;
      let clientHeight = document.documentElement.clientHeight;
      if (clientWidth - elementCoordinates.right > 310) {
        this.formLeft = (elementCoordinates.right + 4).toString() + "px";
      }
      else {
        this.formLeft = (elementCoordinates.left - 304).toString() + "px";
      }

      if (clientHeight - elementCoordinates.top > 310) {
        this.formTop = (elementCoordinates.top).toString() + "px";
      }
      else {
        if (elementCoordinates.top > 310) {
          this.formTop = (elementCoordinates.top - 300).toString() + "px";
        }
        else {
          this.formTop = "15px";
        }
      }

      this.formVisible = true;
      this.waitOpen = false;
    }
  }


  LanguageSetGet() {

    let localSt = this.localStorageService.isSupported;
    if (localSt) {

      this.CloseText = this.localStorageService.get("Close");

    }

  }

  search(event: KeyboardEvent) {
    if (event.keyCode != 40 && event.keyCode != 38 && event.keyCode != 13) {
      this.UnselectElements()
      if (ExtendedHelper.IsNullOrEmpty(this.searchFilter)) {
        for (let i = 0; i < this.Elements.length; i++) {
          this.Elements[i].Visible = true;
          this.Elements[i].Element.Name = this.ElementsBack[i].Element.Name;
        }
      }
      else {

        let separator = new RegExp(/ *\s/, "gi");
        let filtersElements = this.searchFilter.toLowerCase().split(separator);
        let containFilter: boolean = false;
        for (let i = 0; i < this.Elements.length; i++) {
          containFilter = false;
          for (var f = 0; f < filtersElements.length; f++) {
            if (filtersElements[f] != "") {
              if (this.ElementsBack[i].Element.Name.toLowerCase().indexOf(filtersElements[f]) != -1) {
                containFilter = true;
              }
            }
          }
          if (containFilter) {
            this.Elements[i].Element.Name = SearchSelectHelper.getSearchedText(this.searchFilter, this.ElementsBack[i].Element.Name);

            this.Elements[i].Visible = true;
          }
          else {
            this.Elements[i].Element.Name = this.ElementsBack[i].Element.Name;
            this.Elements[i].Visible = false;
          }
        }

      }
    }
  }

  SelectElement(element: any) {
    this.attachElement.emit(element.Id);
  }

  SelectElementFromKeyboard(event: KeyboardEvent, element: any) {
    if (event.keyCode == 13 && element.Selected) {
      this.attachElement.emit(element.Element.Id);
    }
  }

  KeyEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) {
      this.close();
    }
    if (event.keyCode == 40) {
      this.MoveDownByList()
    }
    else if (event.keyCode == 38) {
      this.MoveUpByList();
    }
    else if (event.keyCode == 13) {
      this.SelectElementFromList();
    }
    else {
      //this.UnselectElements();
      //this.search();
    }
  }

  MoveDownByList() {
    let ind = this.GetSelectedElementIndex();
    if (ind > -1) {
      if (ind < this.Elements.length - 1) {
        let cur = ind;
        for (let i = ind + 1; i < this.Elements.length; i++) {
          if (this.Elements[i].Visible && !this.Elements[i].Selected) {
            cur = i;
            break;
          }
        }
        if (cur != ind) {
          this.Elements[ind].Selected = false;
          this.Elements[cur].Selected = true;
          this.ScrollToSelected(cur);
        }
      }
    }
    else {
      let cur = ind;
      for (let i = ind + 1; i < this.Elements.length; i++) {
        if (this.Elements[i].Visible && !this.Elements[i].Selected) {
          cur = i;
          break;
        }
      }
      if (cur != ind) {

        this.Elements[cur].Selected = true;
      }
    }

  }

  MoveUpByList() {
    let ind = this.GetSelectedElementIndex();
    if (ind > -1) {
      if (ind == 0) {
        this.Elements[ind].Selected = false;
      }
      else {
        let cur = ind;
        for (let i = ind - 1; i >= 0; i--) {
          if (this.Elements[i].Visible && !this.Elements[i].Selected) {
            cur = i;
            break;
          }
        }
        if (cur != ind) {
          this.Elements[ind].Selected = false;
          this.Elements[cur].Selected = true;
          this.ScrollToSelected(cur);
        }
        else {
          this.Elements[ind].Selected = false;
        }
      }
    }

  }

  SelectElementFromList() {
    let ind = this.GetSelectedElementIndex();
    if (ind > -1) {
      this.attachElement.emit(this.Elements[ind].Element.Id);
    }
  }

  GetSelectedElementIndex(): number {
    let index = -1;
    for (let i = 0; i < this.Elements.length; i++) {
      if (this.Elements[i].Selected && this.Elements[i].Visible) {
        index = i;
      }
    }
    for (let i = 0; i < this.Elements.length; i++) {
      if (i != index) {
        this.Elements[i].Selected = false;
      }
    }
    return index;
  }


  UnselectElements() {
    for (let i = 0; i < this.Elements.length; i++) {
      this.Elements[i].Selected = false;
    }
  }

  ScrollToSelected(selectedIndex: number) {
    setTimeout(() => {
      let tracker = document.getElementById("attachedElementsScrollId" + this.idPrefix);
      let element = document.getElementById("attachedElement" + this.idPrefix + selectedIndex);
      if (element.offsetHeight + element.offsetTop > tracker.scrollTop + tracker.offsetHeight) {
        //tracker.scrollTop=element.offsetTop-tracker.offsetHeight + element.offsetHeight;
        element.scrollIntoView();
      }

      if (element.offsetTop < tracker.scrollTop) {
        //tracker.scrollTop=element.offsetTop;
        element.scrollIntoView();
      }

    }, 0);
  }

}


import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 30.11.2016.
 */
import { Injectable } from '@angular/core'; import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Person, PersonLight, PersonFilter, PersonMainMaker } from '../models/model.person';



import { Deleting } from "../models/model.deleting";
import { SingleIdRequest, SingleIdGiver } from "../models/model.http";
import { PersonAddAsker } from "../models/model.Person/Out/model.PersonAddAsker";
import { PersonEditAsker } from "../models/model.Person/Out/model.PersonEditAsker";
import { PersonListAsker, PersonMainPageSearchAsker, PersonOtherPageSearchAsker, PersonProjectPageSearchAsker, PersonClientPageSearchAsker } from "../models/model.Person/Out/model.PersonListAsker";
import { PersonMakeMainAsker } from "../models/model.Person/Out/model.PersonMakeMainAsker";
import { ClientListPossibleAsker } from "../models/model.Client/Out/model.ClientListPossibleAsker";
import { ClientAttachAsker } from "../models/model.Client/Out/model.ClientAttachAsker";

@Injectable()
export class HttpServicePerson {
  route: string = "person/";

  constructor(private http: HttpClient, private param: ServerString) {
  }


  delete_(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'delete', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  makeMain(obj: PersonMakeMainAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'makeMain', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  add(obj: PersonAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'add', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  edit(obj: PersonEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'edit', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  listGet(obj: PersonListAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'listGet', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  attachClient(obj: ClientAttachAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AttachedClients/Attach', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  unfastenClient(obj: ClientAttachAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AttachedClients/Unfasten', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  loadPossibleClientsToAttach(obj: ClientListPossibleAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AttachedClients/GetPossibleList', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  PersonGetListMain(obj: PersonMainPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'GetList/Main', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  PersonGetListClient(obj: PersonClientPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'GetList/Client', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  PersonGetListProject(obj: PersonProjectPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'GetList/Project', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  PersonGetInfo(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'GetInfo', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

}

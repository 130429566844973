
import {fromEvent as observableFromEvent, Observable, Subscription} from 'rxjs';

import {map} from 'rxjs/operators';
/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';
import {ClientInProjectFilterWithUnion} from '../../models/model.ClientInProjectFilter';
import {ListElement} from '../../models/List/model.ListElement';
import {SearchSelectHelper} from '../../Helpers/SearchSelectHelper';
import {ClientLight} from '../../models/model.client';
import {ProjectLight} from '../../models/model.project';
import {Router} from '@angular/router';
import {TagHelper} from '../../Helpers/taghelper';
import { LanguageService } from '../../services/language.service';
import { Common_LangSet, Jobs_LangSet, Search_LangSet, Tags_LangSet } from '../../models/model.language';


declare var require: any;


@Component({
    selector: 'searchResults',
    templateUrl: 'temp.searchResults.html',
    styleUrls: ['searchResults.scss',
        '../../../style/font-awesome.min.scss',],

})
export class SearchResultsComponent implements OnInit {
    @Input() closable = true;
  //  @Input() renderText :boolean;
    @Input() visible: boolean;
    @Input() Clients: ClientLight[] = [];
    @Input() Projects: ProjectLight[] = [];
  @Input() ClientBeginning: boolean;
  @Input() ClientsLength: number;
  @Input() ClientsCount: number;
  @Input() IsLoadingClients: boolean;
  @Input() ProjectBeginning: boolean;
  @Input() ProjectsLength: number;
  @Input() ProjectsCount: number;
  @Input() IsLoadingProjects: boolean;
  @Input() IsLoadingClientsAdd: boolean;
  @Input() NothingToLoadToClients: boolean;
  @Input() IsLoadingProjectsAdd: boolean;
  @Input() NothingToLoadToProjects: boolean;
  @Input() clientId: number=-1;
  @Input() projectId: number=-1;
  @Input() ProjectFilter: string="";
  @Input() ClientFilter: string="";

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() scrollCilents: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() scrollProjects: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    IsFullClientList: boolean=false;
    IsFullProjectList: boolean=false;
    
    search_Langset: Search_LangSet;
    sr_sub: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    jobs_Langset: Jobs_LangSet;
    jb_sub: Subscription=null;




  constructor(
              private localStorageService: LocalStorageService,
              private router: Router,
              private languageService: LanguageService
              ) { }




    ngOnInit() {
      this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
      this.sr_sub=this.languageService.searchLanguage$.subscribe(lang=>this.search_Langset=lang);
      this.jb_sub=this.languageService.jobsLanguage$.subscribe(lang=>this.jobs_Langset=lang);
    }

    close() {
        this.visible = false;
      //  this.renderText=false;
        this.visibleChange.emit(this.visible);
    }







 

 

  ScrollTrackerSubscription() {
    if( this.IsFullClientList)
    {
      let trackerClientSearchList = document.getElementById('clientListSearchScrollTracker');
      console.log(trackerClientSearchList)
      let windowYOffsetObservable = observableFromEvent(trackerClientSearchList, 'scroll').pipe(map(() => {
        return trackerClientSearchList.scrollTop;
      }));

      console.log(windowYOffsetObservable)
      let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
       
        let limit = trackerClientSearchList.scrollHeight - trackerClientSearchList.clientHeight;
        //console.log(scrollPos)
        if (((scrollPos === limit) || (scrollPos > limit - 1)) && this.Clients.length > 0 && trackerClientSearchList.scrollTop != 0 && !this.IsLoadingClients) {
          //alert("loading additional clients");
          //this.LoadClientsAdditional();
          this.scrollCilents.emit(true);


        }
      });
    }//here s clients tracked

    if( this.IsFullProjectList)
    {
      let trackerProjectList = document.getElementById('projectListSearchScrollTracker');

      let windowYOffsetObservable = observableFromEvent(trackerProjectList, 'scroll').pipe(map(() => {
        return trackerProjectList.scrollTop;
      }));

      let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
        let limit = trackerProjectList.scrollHeight - trackerProjectList.clientHeight;
        if (((scrollPos === limit) || (scrollPos > limit - 1)) && this.Projects.length > 0 && trackerProjectList.scrollTop != 0 && !this.IsLoadingProjects) {
          //alert("loading additional projects");
          //this.LoadAdditionalProjectsNew();
          this.scrollProjects.emit(true);
        }
      });
    }

    //here s projects tracked

  }

  ShowClientList()
  {
    this.IsFullProjectList=false;
    this.IsFullClientList=true;
   // this.initScrollBar('client', 'clientListSearchScrollTracker');
      setTimeout(()=>{
    this.ScrollTrackerSubscription();},100);
  }

  ShowProjectList()
  {
    this.IsFullClientList=false;
    this.IsFullProjectList=true;
    // this.initScrollBar('client', 'clientListSearchScrollTracker');
    setTimeout(()=>{
      this.ScrollTrackerSubscription();},100);
  }

  HideList()
  {
    this.IsFullClientList=false;
    this.IsFullProjectList=false;
  }


  goToClient(Id: number, event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/client/', Id];
      this.router.navigate(link);
      return;
    }
  }

  goToProject(Id: number, event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/project/', Id];
      this.router.navigate(link);
    }

  }




  @HostListener('document:keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if(e.keyCode==27)
    {
      this.HideList();
    }
  }


}

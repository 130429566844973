import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Common } from '../../../models/model.common';
import { TagContainerInner, TagFilter, TagFilterToSendExtended, TagContainerGiver, TagAddAsker, TagListGiver, TagFromAngularDeleting, TagEntityTypeEnum, TagFromAngular } from '../../../models/model.tag';
import { TagHelper } from '../../../Helpers/taghelper';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { CommonResponse } from '../../../models/model.http';
import * as _ from 'lodash';
import { CommonPositive } from '../../../models/model.Analytics/model.ProjectAnalytics/model.ProjectSelectionQuery/model.Filters/model.Filters';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { DeleteSession } from '../../../store/actions/session.action';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';
import { Common_LangSet, Tags_LangSet } from '../../../models/model.language';

@Component({
  selector: 'Tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss',
    '../../../../style/font-awesome.min.scss',
    '../../../../style/supportLibrary.scss',
    '../../../../style/control-inputs.scss',
    '../../../../iconfont/material-icons.scss']
})



export class TagListComponent implements OnInit {
  



  sessionKey$: Subscription=null;
  userId$: Subscription=null;


  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private extendedHelper: ExtendedHelper,
    private cookieOptionsMy: CookieOptionsMy,
    private localStorageService: LocalStorageService,
    private httpServiceTag: HttpServiceTag,
    private sessionService: SessionService,
    private languageService: LanguageService) { }

  @Input("data") Tags: Common[];
  @Output("dataChange") TagsChange = new EventEmitter<Common[]>();

  // @Output() dataChange: EventEmitter<Common[]> = new EventEmitter();
  // set data(val: Common[]) {
  //   this.Tags = val;
  //   this.dataChange.emit(this.Tags);
  // }

  @Input("changeable") Changeable: boolean = true;
  @Input("isAdmin") IsAdmin: boolean = false;
  @Input("id") IdItem: number = 0;
  @Input("type") TypeItem: number;
  @Input("btn") WithButton: boolean = true;
  @Input("title") Title: string;
  @Input("saveOnClient") SaveOnClient: boolean = false;
  @Input("access") Access: boolean = false;
  @Input("styleFont") StyleFont: Object = new Object();
  @Input("size") Size: number = 16;
  @Input("withPositivity") withPositivity: boolean = false;
  @Input("prefix") Prefix: string="";

  @Input("analytics") isAanalytics: false;
  @Input("justAllList") justAllList: false;

  @Output() updateSearch: EventEmitter<any> = new EventEmitter();

  @Output() isActive: EventEmitter<any> = new EventEmitter();
  @Output() tagDeleted: EventEmitter<any> = new EventEmitter();

  UserId: number;
  SessionKey: string;


  TagContainer: TagContainerInner;
  TagContainerLoading: boolean = false;
  tagFilter: TagFilter = new TagFilter();

  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();

  Visible: boolean = false;

  tagsLeft: string = "350px";
  tagsTop: string = "100px";
  tagsLeftInput: string = "350px";
  tagsTopInput: string = "100px";
  tagFormPlacement: string = 'down';
  tagIndexContainer: number = -1;
  tags_Langset: Tags_LangSet;
  tg_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;


  ngOnInit() {
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.tg_sub=this.languageService.tagsLanguage$.subscribe(lang=>this.tags_Langset=lang);
    
  }


  

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }


  KeyControllerTags(event: any) {
    if (this.Visible) {
      switch (event.keyCode) {
        case 27: {//ESCAPE
          if (this.Visible) this.TagsFormClose();
          event.stopPropagation();
          break;
        }
        case 13: {
          //enterPressed.
          if (this.Visible) {//если форма тегов открыта
            let selected = TagHelper.FindSelectedTagInContainer(this.TagContainer);
            if (selected.NotNull && selected.Found) {
              //если контейнер подгружен, и какой-то из тегов был выделен с помощью кнопочек.
              let tagList = TagHelper.GetListOfContainer(selected.ListNum, this.TagContainer);
              let tagName = tagList[selected.TagIndex].FullName;
              this.ReNameTag(tagName, tagList[selected.TagIndex].Id);
            }
            else this.AddTag('');
          }
          event.stopPropagation();
          break;
        }
        case 37:
          if (this.Visible) {
            TagHelper.GoLeftInContainer(this.TagContainer, this.tagFormPlacement);
          }
          event.stopPropagation();
          break;
        case 38:
          if (this.Visible) {
            TagHelper.GoUpInContainer(this.TagContainer, this.tagFormPlacement);
          }
          event.stopPropagation();
          break;
        case 39:
          if (this.Visible) {
            TagHelper.GoRightInContainer(this.TagContainer, this.tagFormPlacement);
          }
          event.stopPropagation();
          break;

        case 40:
          if (this.Visible) {
            TagHelper.GoDownInContainer(this.TagContainer, this.tagFormPlacement);
          }
          event.stopPropagation();
          break;
      }
    } else {
      event.stopPropagation();
    }
  }



  LoadTagContainer() {
    this.TagContainerLoading = true;
    this.TagContainer = null;
    let checker = new TagFilterToSendExtended(this.UserId, this.SessionKey, this.tagFilter.EntityType);
    this.httpServiceTag.getTagList(checker).subscribe((data: TagContainerGiver) => {
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      this.TagContainerLoading = false;
      this.TagContainer = new TagContainerInner(data.TagContainer);
    });
  }

  OnTagContainerFilterChanged() {
    if (this.TagContainer != null) {
      TagHelper.getSearchedText(this.TagContainer, this.tagFilter.Name);
      TagHelper.Sort(this.TagContainer, this.tagFilter.Name);
      TagHelper.GoFirstInContainer(this.TagContainer, this.tagFormPlacement);
    }
  }




  TagFormShow(tag?: Common) {
    if (this.Visible) {
      this.TagsFormClose();
    }
    else {
      if (tag != null) {
        this.tagFilter.EditingTagId = tag.Id;
        this.tagFilter.Name = tag.Name;
      }
      else {
        this.tagFilter.EditingTagId = 0;
        this.tagFilter.Name = "";
      }

      if(this.TypeItem!=this.TagEntityTypeEnum.Autojob)
      {this.tagFilter.EntityType = this.TypeItem;}
      else
      {
        this.tagFilter.EntityType = this.TagEntityTypeEnum.Job;
      }
      this.tagFilter.EntityId = this.IdItem;
      this.LoadTagContainer();

      let clientWidth = document.documentElement.clientWidth;
      let clientHeight = document.documentElement.clientHeight;
      let tagElement;
      tagElement = document.getElementById("btn_tag"+this.Prefix+"_" + this.TypeItem + "_" + this.IdItem);
      
      let elementCoordinates = tagElement.getBoundingClientRect();
      if ((clientWidth - elementCoordinates.right) > 455) {
        this.tagsLeft = elementCoordinates.right.toString() + "px";
        this.tagsLeftInput = elementCoordinates.right.toString() + "px";
      }
      else {
        this.tagsLeft = (elementCoordinates.left - 465).toString() + "px";
        this.tagsLeftInput = (elementCoordinates.left - 170).toString() + "px";
      }

      if ((clientHeight - elementCoordinates.bottom) > 405) {
        this.tagsTop = (elementCoordinates.top + 32).toString() + "px";
        this.tagsTopInput = elementCoordinates.top.toString() + "px";
        this.tagFormPlacement = "down";
      }
      else {
        if (elementCoordinates.bottom - 435 > 0) {
          this.tagsTopInput = (elementCoordinates.bottom - 435).toString() + "px";
          this.tagsTop = (elementCoordinates.bottom - 400).toString() + "px";
          this.tagFormPlacement = "down";
        }
        else {
          this.tagsTop = "47px";
          this.tagsTopInput = "15px";
          this.tagFormPlacement = "down";
        }
      }

      this.Visible = true;
      this.isActive.emit(true);
      DOMHelper.FocusElement('searchTagInput', 100);
    }
  }

  TagsFormClose() {
    let tagElemn = document.getElementsByClassName("tagToInput");
    if (tagElemn[0] != null) {

      tagElemn[0].classList.remove("tagToInput");
    }
    this.Visible = false;
    this.isActive.emit(false);
  }

  TagFormOkButtonPressed() {
    this.AddTag('');
  }

  AddTag(name: string, id: number = -1) {
    let newName = name != null && name.length > 0 ? name : this.tagFilter.Name;
    if (this.extendedHelper.DeleteAnySpaces(newName) == null || this.extendedHelper.DeleteAnySpaces(newName).length == 0) {
      alert('Тег не должен быть пустым/содержать только пробелы в названии.');
      return;
    }
    let pos = newName.includes('"');
    if(pos)
    {
      alert('Тег не должен содержать символ ".');
      return;
    }
    let pos1 = newName.includes('`');
    if(pos1)
    {
      alert('Тег не должен содержать символ `.');
      return;
    }
    let pos2 = newName.includes("'");
    if(pos2)
    {
      alert("Тег не должен содержать символ '.");
      return;
    }

    if (this.tagFilter.EntityId > 0) {//добавляем к реально существующему объекту
      let checker = new TagAddAsker(this.UserId, this.SessionKey,
        ExtendedHelper.ReplaceBadThings(ExtendedHelper.DeleteAnySpaces(newName)),
        this.tagFilter.EntityType, this.tagFilter.EntityId, this.tagFilter.EditingTagId);
      this.httpServiceTag.AddTag(checker).subscribe((data: TagListGiver) => {
        if (data.Code == 100) {
          this.unLogin();
          return;
        }
        if (data.Code == 1 || data.Code == 300) {
          console.log(data.Message);
          this.TagsFormClose();
          return;
        }
        if (data.Code == 200) {
          alert(this.tags_Langset.TagAdded);
          // this.TagsFormClose();
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }
        this.Tags = [...data.TagList];
        this.TagsChange.emit([...this.Tags])
        this.TagsFormClose();
      });
    }
    else {
      //добавление в какое-либо поисковое поле.
      if (newName.replace(/ /g, '') == "") {
        alert(this.tags_Langset.TagNameEmpty);
        return;
      }
      if (this.tagFilter.EditingTagId == 0) {//добавляем в поисковую область новый тег.
        let index = this.Tags.map(c => c.Name).findIndex(c => newName.toLowerCase() == c.toLowerCase());
        if (index !== -1) {
          alert(this.tags_Langset.TagAdded);
          return;
        }

        let tag = new Common();
        tag.Id = id;//>0? this.job.Tags[this.job.Tags.length-1].Id+1: 1;
        tag.Name = newName;
        if (this.withPositivity) {
          let tagP = new CommonPositive(tag);
          this.Tags.push(tagP);
          this.TagsChange.emit(this.Tags)
        } else {
          this.Tags.push(tag);
          this.TagsChange.emit(this.Tags)

        }

        this.TagsFormClose();
      }
      else {//переименовываем существующий
        if (this.isAanalytics) {
          for (let i = 0; i < this.Tags.length; i++) {
            if (this.Tags[i].Id != this.tagFilter.EditingTagId && this.Tags[i].Name.toLowerCase() == newName.toLowerCase()) {
              //если во время переименования нашли иной тег с совпадающим именем, скажем об этом.
              alert(this.tags_Langset.TagAdded);
              return;
            }
          }
          let index = this.Tags.map(c => c.Id).findIndex(c => this.tagFilter.EditingTagId == c);
          if (index !== -1) this.Tags[index].Name = newName;
          this.TagsFormClose();

          this.updateSearch.emit();
          return;
        }

        if (this.TagEntityTypeEnum.Job || this.TagEntityTypeEnum.Person || this.TagEntityTypeEnum.ClientFilter || this.TagEntityTypeEnum.ProjectFilter) {
          for (let i = 0; i < this.Tags.length; i++) {
            if (this.Tags[i].Id != this.tagFilter.EditingTagId && this.Tags[i].Name.toLowerCase() == newName.toLowerCase()) {
              alert(this.tags_Langset.TagAdded);
              return;
            }
          }
          let index = this.Tags.map(c => c.Id).findIndex(c => this.tagFilter.EditingTagId == c);
          if (index !== -1) this.Tags[index].Name = newName;
          // this.SearchForClients();//todo emit
          // this.SearchForProjects(); //todo emit
        }
        this.TagsFormClose();
      }
    }
  }

  ReNameTag(name: string, id: number = -1) {
    this.AddTag(name, id);
  }

  DeleteTag(tagId: number) {
    if (this.SaveOnClient) {
      let index = this.Tags.findIndex(c => c.Id == tagId);
      if (index != -1) this.Tags.splice(index, 1);
      this.updateSearch.emit();
      return;
    }

    if (this.isAanalytics) { this.updateSearch.emit(); return }

    if(this.TypeItem==this.TagEntityTypeEnum.Autojob)
    {
      let index = this.Tags.findIndex(c => c.Id == tagId);
      if (index != -1) this.Tags.splice(index, 1);
      this.tagDeleted.emit(tagId);
    }
    else
    {
      let checker = new TagFromAngularDeleting(this.UserId, this.SessionKey, tagId, this.TypeItem, this.IdItem);
    this.httpServiceTag.deleteTag(checker).subscribe((data: CommonResponse) => {
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 300) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      let index = this.Tags.findIndex(c => c.Id == tagId);
      if (index != -1) this.Tags.splice(index, 1);
    });
    }
  }


  ChangePositivity(filter: any) {
    if ('IsPositive' in filter) {
      filter.IsPositive = !filter.IsPositive;
    }
    this.updateSearch.emit();
  }

}

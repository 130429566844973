
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';



import {
    ProfileEditAsker,
    WorkerAddAsker, WorkerInSettingsChangeStatusAsker
} from '../models/model.worker'
import { ParentChecker } from "../models/model.parentChecker";
import { Deleting } from "../models/model.deleting";
import { CommonRequest, SingleIdRequest, SingleStringRequest } from '../models/model.http';
import { WorkerToGroupAddAsker, OwnageEditAsker, EntitiesAndOwnagesAsker, GroupRulesEditAsker, RuleEditAsker, RuleRemoveAsker, GroupAddAsker, GroupsToAddRuleAsker } from '../models/model.AccessRights/In/model.UserAccessRight';
import { OwnageListGiver } from '../models/model.AccessRights/Out/model.WorkersAndAccessGroupsInSettings';
import { MultiIdRequest } from '../models/model.Autojob/model.AutojobTemplate';

@Injectable()
export class HttpWorkersAndAccessGroups {
    constructor(private http: HttpClient, private param: ServerString) {
    }

    getWorkersAndGroups(obj: CommonRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetWorkersAndAccessGroupsInSettings', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    deleteGroup(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/DeleteGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    getUsersToAddGroup(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetWorkersToAddToGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    AddWorkerToGroup(obj: WorkerToGroupAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/AddWorkerToGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    DeleteWorkerToGroup(obj: WorkerToGroupAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/DeleteWorkerFromGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetGroupOwnage(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupOwnage', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    EditGroupOwnage(obj: OwnageEditAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/EditGroupOwnage', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetGroupRules(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupRules', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetClientOwnagesToInherit(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetClientOwnagesToInherit/ExistingGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetProjectOwnagesToInherit(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetProjectOwnagesToInherit/ExistingGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetClientOwnagesToSpecial(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetClientsForSpecialRule/ExistingGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetProjectOwnagesToSpecial(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetProjectsForSpecialRule/ExistingGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    SaveEditGroupRules(obj: GroupRulesEditAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/EditGroupRules', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    NewGroup(obj: CommonRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetNewGroupContainer', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetClientOwnagesToInheritNewGroup(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetClientOwnagesToInherit/NewGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetProjectOwnagesToInheritNewGroup(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetProjectOwnagesToInherit/NewGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetClientsForSpecialRuleNewGroup(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetClientsForSpecialRule/NewGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GetProjectsForSpecialRuleNewGroup(obj: EntitiesAndOwnagesAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetProjectsForSpecialRule/NewGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    CreateNewGroup(obj: GroupAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/AddGroup', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }


    GetClientInfo(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'Client/GetInfo', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetRulesOfClient(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetRulesOfClient', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    GroupsToAddRuleAsker(obj: GroupsToAddRuleAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupsInClient', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    SaveRuleInClient(obj: RuleEditAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/SaveRuleInClient', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    RemoveRuleInClient(obj: RuleRemoveAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/RemoveRuleInClient', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetProjectInfo(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'Project/GetInfo', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetRulesOfProject(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetRulesOfProject', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetGroupsInProject(obj: GroupsToAddRuleAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupsInProject', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    SaveRuleInProject(obj: RuleEditAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/SaveRuleInProject', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    RemoveRuleInProject(obj: RuleRemoveAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/RemoveRuleInProject', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    RemoveSpecialRulesFromClients(obj: MultiIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/RemoveSpecialRulesFromClients', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    RemoveSpecialRulesFromProjects(obj: MultiIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/RemoveSpecialRulesFromProjects', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    GetGroupsToSetFileAccess(obj: CommonRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupsToSetFileAccess', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

}
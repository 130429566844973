
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 13.02.2017.
 */
import {Injectable} from '@angular/core';
import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { Person, PersonLight} from '../models/model.person';



import {Deleting} from "../models/model.deleting";
import {ParentChecker} from "../models/model.parentChecker";
import {IdToId} from "../models/model.idtoid";
import {CommonRequest, CommonResponse} from '../models/model.http';


@Injectable()
export class HttpServiceAccessGiver {

    constructor(private http: HttpClient,
                private param:ServerString) {    }


    /*getAccessJobPersonGiver(obj: IdToId) {
        const body = "'"+JSON.stringify(obj)+"'";
        //let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
      let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AccessJobPersonGiver', body, { headers: headers, withCredentials: true }).pipe(
        //    .map(resp=>resp.json())
            catchError((error:any) =>{return observableThrowError(error);}));
    }*/

    /*getCalendarAccess(obj: ParentChecker) {
        const body = "'"+JSON.stringify(obj)+"'";
        //let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
      let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AccessCalendar', body, { headers: headers, withCredentials: true }).pipe(
          //  .map(resp=>resp.json())
            catchError((error:any) =>{return observableThrowError(error);}));
    }*/

    /*getAnalyticsAccess(obj: ParentChecker) {
        const body = "'"+JSON.stringify(obj)+"'";
        //let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
      let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');

        return this.http.post(this.param.fullServerString()+'AccessAnalytics', body, { headers: headers, withCredentials: true }).pipe(
        //    .map(resp=>resp.json())
            catchError((error:any) =>{return observableThrowError(error);}));
    }*/

  getAccessJobPersonGiverMain(obj: CommonRequest) {
    const body = "'"+JSON.stringify(obj)+"'";
    //let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AccessSystemController/CheckAccessToJobAndPersonOnMain', body, { headers: headers, withCredentials: true }).pipe(
    //    .map(resp=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }
}

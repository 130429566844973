import {Common} from "../model.common";
import { CommonRequest, CommonResponse, SingleIdRequest } from "../model.http";
import { DiagramLite } from "./model.analyticDiagaram";
import { EntityWithRoles, ParameterFilterInstanceToShow, StageFilterInstanceToShow, StringInstanceToShow, DateTimeInstanceToShow, CommonPositive, EntityWithRolesPositive, StringFilterInstance } from "../model.Analytics/model.ProjectAnalytics/model.ProjectSelectionQuery/model.Filters/model.Filters";
import { AnyParameter } from "../model.Analytics/model.ProjectAnalytics/inner/model.AnyParameter";
import { CommonWorkerHolder } from "../model.workerAttached";
import { AddressAutocompleteChainInner } from "../model.Address/Inner/AddressAutocomplete/model.AddressAutocompleteChainInner";
import { ExtendedHelper } from "../../config/ExtendedHelper";
import { MultiIdRequest } from "../model.Autojob/model.AutojobTemplate";
import { AddressInputComponentInner } from "../model.Address/Inner/InputContainer/model.AddressInputComponentInner";
/**
 * Created by MosNeuro on 23.05.2017.
 */

export class QueryFilter
{
    Filters: AnalyticFilter[];
}

export class AnalyticFilter extends Common
{
    Position: number; 
    FilterType: number;
    ValueType: number;
    IsPositive: boolean;
    FilterUseType: number; 
    StringValues: StringFilterInstance[];
    //NumberValues: number[];
    //DecimalValues: number[];
    DateValues: DateTimeInstanceToShow[];
    CommonValues: CommonEntityPositive[];
    EntityWithRoleValues: CommonEntityWithRolesPositive[];
    ParameterValues: ParameterFilterInstance[];
    StageValues: StageFilterInstance[];

    DatePeriodValues:DatePeriodPositive [];
    Workers: WorkerInFilter[];
}


enum FilterUseType
{
    or=0,
    and=1
}

/*export class FilterType //определяем что за фильтр у нас такой, Название, Тэги и т д
{
    Id: number;
    Name: string;
    ValueType: number;
}*/


enum ValueType
{
    String = 0,
            Datetime = 1,
            CommonEntity = 2,
            CommonEntityWithRoles = 3,
            ParameterFilter = 4,
            StageFilter = 5,
            DatePeriod = 6,
            Worker = 7

}

enum FilterType

{
    Name = 0,
            Tags = 1,
            Stages = 2,
            Address = 3,
            Users = 4,
            Parameters = 5,
            Clients = 6,
            Projects = 7,
            Status = 8,
            Info = 9,
            Post = 10,
            CreateTime = 11,
            LastChangeTime = 12


}



export class DatePeriod  {
    StartDate: Date;
    EndDate: Date;
    EndDateIsToday: boolean;
    
  }

export class DatePeriodPositive  extends DatePeriod {
    IsPositive: boolean;
    
  }

  
  


export class CommonEntityWithRolesPositive extends EntityWithRoles {
    Positive: boolean;
    constructor(e: Common) {
      super();
      if (e != null) {
        this.Id = e.Id;
        this.Name = e.Name;
      }
      this.Roles = [];
      this.Positive = true;
    }
  
    
  
    static FromChain(chain: AddressAutocompleteChainInner): CommonEntityWithRolesPositive {
      let newObj = new CommonEntityWithRolesPositive(null);
      newObj.Id = chain.SheetId;
      newObj.Name = "";
      for (let i = 0; i < chain.Components.length; i++) {
        if (i > 0 && newObj.Name != "") {
          newObj.Name += ", ";
        }
        let comp = chain.Components[i];
        if (!ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) {
          newObj.Name += comp.Value;
        }
      }
      return newObj;
    }


    static FromInner(inner: AddressInputComponentInner[]): CommonEntityWithRolesPositive {
      let newObj = new CommonEntityWithRolesPositive(null);
      newObj.Id = 0;
      newObj.Name = "";
      for (let i = 0; i < inner.length; i++) {
        if (i > 0 && newObj.Name != "") {
          newObj.Name += ", ";
        }
        //let comp = chain.Components[i];
        if (!ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(inner[i].Value)) {
          newObj.Name += inner[i].Value;
        }
      }
      return newObj;
    }
  
  }


  export class CommonEntityPositive extends Common
{
    Positive: boolean;
    constructor(e: Common) {
      super();
      if (e != null) {
        this.Id = e.Id;
        this.Name = e.Name;
      }
      this.Positive = true;
    }


      static FromChain(chain: AddressAutocompleteChainInner): CommonEntityPositive {
        let newObj = new CommonEntityPositive(null);
        newObj.Id = chain.SheetId;
        newObj.Name = "";
        for (let i = 0; i < chain.Components.length; i++) {
          if (i > 0 && newObj.Name != "") {
            newObj.Name += ", ";
          }
          let comp = chain.Components[i];
          if (!ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) {
            newObj.Name += comp.Value;
          }
        }
        return newObj;
      }
    


}


export class EditQueryFilterAsker extends SingleIdRequest
{
    ChangedFilter: AnalyticFilter;
}


export class WorkersToAnalyticsFilterGiver extends CommonResponse

{

 ActualWorkers: CommonWorkerHolder[];

 RemovedWorkers: Common[];

}


export class ParameterFilterInstance extends Common
{
    SelectedType: Common;//Тип параметра: строковый, логический и пр.           
    Filtering: boolean;//Задействован ли данный параметр в фильтрах выборки.

    StringFilter: StringInstanceInParameterFilter[];
    BoolFilter: BoolFilterInParameterFilter;
    ValueFilter: ValueInstanceInParameterFilter[];
    NumberFilter: NumberInstanceInParameterFilter[];
}

export class ParameterFilterInstanceContainer extends Common
{
    SelectedType: Common;//Тип параметра: строковый, логический и пр.           
    Filtering: boolean;//Задействован ли данный параметр в фильтрах выборки.

    StringFilter: StringInstanceInParameterFilterContainer[];
    BoolFilter: BoolFilterInParameterFilterContainer;
    ValueFilter: ValueInstanceInParameterFilterContainer[];
    NumberFilter: NumberInstanceInParameterFilterContainer[];
}

export class StringInstanceInParameterFilter
{
    Value: string;
    Positive: boolean;
}

export class StringInstanceInParameterFilterContainer
{
    Instance: StringInstanceInParameterFilter;
    Editing: boolean;
}

export class BoolFilterInParameterFilter
{
    ShowNull: boolean;
    ShowNo: boolean;
    ShowYes: boolean;
}

export class BoolFilterInParameterFilterContainer
{
    Instance: BoolFilterInParameterFilter;
    Editing: boolean;
}

export class ValueInstanceInParameterFilter extends Common
{
    Show: boolean;
    Positive: boolean;
}
export class ValueInstanceInParameterFilterContainer
{
    Instance: ValueInstanceInParameterFilter;
    Editing: boolean;
}

export class NumberInstanceInParameterFilter
{
    IsBasic: boolean;
    Position: number;
    Operation: string;//> < = null
    Connector: string;//AND OR
    Value: number;

    IsPositive: boolean;
}

export class NumberInstanceInParameterFilterContainer
{
    Instance: NumberInstanceInParameterFilter;
    Editing: boolean;
}


export class StageFilterInstance extends CommonEntityPositive
{
    CheckPeriod: boolean;
    StartDate: Date;
    EndDate: Date;
    EndDateIsToday: boolean;
}


export class AddressRolesAsker extends MultiIdRequest
       {
             ElementType: number;
       }


export class WorkerInFilter extends CommonEntityWithRolesPositive
        {
          Removed: boolean;//пометка об уудаленности пользователя

          CanHaveRoles: boolean;//к данному фильтру можно прикреплять роли
        }

export class JobChangeStatusModeEnum
{
  EndByWorkerWithMessage:number;
  SendToReworking:number;
  SendToChecking:number;

  constructor()
  {
    this.EndByWorkerWithMessage = 0;
    this.SendToReworking = 1;
    this.SendToChecking = 2;
  }

}

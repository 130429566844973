import { ParentChecker } from "./model.parentChecker";
import { CommonResponse, SingleIdRequest, CommonRequest } from "./model.http";
import { ExtendedHelper } from "../config/ExtendedHelper";
import { Common } from './model.common';
import { JobLight } from "./model.job";
// import { AutoJobTemplateContainer } from "./model.Autojob/model.AutojobTemplate";
import { OwnageEntity, OwnageEntityProject } from "./model.ownage";
import { AutojobMultyselectElementValue, MultyselectContainer } from "./model.MultyselectContainer";
import { CommonWorkerHolder } from "./model.workerAttached";
import { AutoJobAuthorHolder, AutoJobExectutorHolder } from "./model.Autojob/model.AutojobTemplate";
import * as _ from 'lodash';
import { TripleRules } from "./model.project";
import { StorageContainer } from "./model.storage";
/**
 * Created by MosNeuro on 24.11.2016.
 */


export class Worker extends Common {
  IsBlocked: boolean;
  IsAdmin: boolean;
  SessionCount: number;
  constructor(id: number = 0, name: string = '') {
    super(id, name);
  }
}

export class WorkerWithOwnage {
  Id: number;
  GroupId: number;
  Name: string;
  IsAdmin: boolean;
  IsBlocked: boolean;
  IsBooker: boolean;
  ClientCount: number;
  ProjectCount: number;
}

export class WorkerInSettingsListGiver extends CommonResponse {
  Workers: Worker[];
}


export class WorkerSmall extends Common {
  Status: string;
}

export class WorkerListGiver extends CommonResponse {
  Workers: WorkerSmall[];
}

export class WorkerGiver extends CommonResponse {
  Worker: WorkerProfile;
}

export class WorkerInJobFilterGiver extends CommonResponse {
  Holders: WorkerInJobFilterHolder[];
}

export class WorkerInJobFilterHolder {
  IsWorker: boolean;
  Worker: WorkerSmall;
  Position: number;
  Departament: DepartamentInJobFilter;
}

export class CommonWorkerStatuGroupBy extends Common {
  GroupName: string;
  Status: string;

  constructor(id: number, name: string, status?: string, groupName?: string) {
    super(id, name)
    this.GroupName = groupName;
    this.Status = status || '';
    if (!groupName) delete this.GroupName
  }
}

export class WorkersForSelector {
  List: CommonWorkerStatuGroupBy[] = [];


  addItem(id: number, name: string, status?: string) {
    this.List.splice(0, 0, new CommonWorkerStatuGroupBy(id, name, status))
  }


  setList(workers: WorkerInJobFilterHolder[]) {
    workers.forEach(el => {
      if (el.IsWorker) {
        this.List.push(new CommonWorkerStatuGroupBy(el.Worker.Id, el.Worker.Name, el.Worker.Status))
      } else {
        el.Departament.Workers.forEach(w => {
          this.List.push(new CommonWorkerStatuGroupBy(w.Id, w.Name, w.Status, el.Departament.Name))
        });
      }
    })
  }
}

export class DepartamentInJobFilter extends Common {
  Workers: WorkerSmall[];
}

export class WorkerProfile {
  Id: number;
  Username: string;
  Name: string;
  IsAdmin: boolean;
  IsBooker: boolean;// бухгалтер
  //IsBlocked: boolean;
  Password: string;
  SurePassword: string;
  Mail: string;
  FilePath: string;
  MessageSendingWay: number;
  PhotoLink: string;
  Language: number;
  LanguageArr: Common[];
  Status: string;
  PhotoLiter: string;
  AnalyticsAccess: boolean;
  CalendarAccess: boolean;
  StorageAccess: number;
}

export interface IUser {
  Id: number;
  Name: string;
  IsAdmin: boolean;
  IsBooker: boolean;
  Mail: string;
  PhotoLink: string;
  Language: number;
  Status: string;
  PhotoLiter: string;
  AnalyticsAccess: boolean;
  CalendarAccess: boolean;
}

export class WorkerFullToSend extends WorkerProfile {
  SessionKey: string;
  ChangerId: number;
}

export class WorkerToSend extends Worker {
  SessionKey: string;
  ChangerId: number;
  Password: string;
  SurePassword: string;
}

export class CommonWorkerHolderBL {
  IsWorker: boolean;
  Worker: CommonBL;
  Position: number;
  Departament: CommonDepartment;
  constructor(holder?: CommonWorkerHolder) {
    if (holder != null) {
      this.IsWorker = holder.IsWorker;
      if (holder.IsWorker) {
        this.Worker = new CommonBL(holder.Worker.Id, holder.Worker.Name);
      } else {
        if (holder.Departament.Workers.length > 0) {
          this.Departament = new CommonDepartment();
          this.Departament.Id = holder.Departament.Id;
          this.Departament.Name = holder.Departament.Name;
          this.Departament.Workers = holder.Departament.Workers.map(el => new CommonBL(el.Id, el.Name))
        }
      }
    }
  }
}

export class CommonDepartment extends Common {
  Workers: CommonBL[];
  constructor() {
    super();
    this.Workers = [];
  }
}

export class CommonBL extends Common {
  IsInBusinessLogic: boolean = false;
  constructor(id: number, name: string) {
    super(id, name)

  }
}

export class WorkerToBusinessLogic extends CommonWorkerHolderBL {
  IsInBusinessLogic: boolean = false;
}







export class Time {
  Hours: number;
  Minutes: number;
  //Seconds:number;
}

export class ProfileEditAsker extends CommonRequest {
  Name: string;
  //Login:string;
  Password: string;
  SurePassword: string;
  Mail: string;
  FileFolder: string;
  Language: number;
  MessageSendingWay: number;
  PhotoLink: string;
  OldPassword: string;

  public constructor(userId: number, sessionKey: string, p: WorkerProfile, oldPassword: string) {
    super();
    this.ChangerId = userId;
    this.SessionKey = sessionKey;

    this.Name = ExtendedHelper.ReplaceBadThings(p.Name);
    //this.Login = ExtendedHelper.ReplaceBadThings(p.Username);
    this.Password = ExtendedHelper.ReplaceBadThings(p.Password);
    this.SurePassword = ExtendedHelper.ReplaceBadThings(p.SurePassword);
    this.OldPassword = ExtendedHelper.ReplaceBadThings(oldPassword);
    this.Mail = ExtendedHelper.ReplaceBadThings(p.Mail);
    this.FileFolder = ExtendedHelper.ReplaceBadThings(p.FilePath);
    this.Language = p.Language;
    this.MessageSendingWay = p.MessageSendingWay;
    this.PhotoLink = p.PhotoLink;// ExtendedHelper.ReplaceBadThings(p.PhotoLink);
  }
}

export class WorkerAddAsker extends CommonRequest {
  Name: string;
  Mail: string;
  Password: string;
  SurePassword: string;
  Language: number;
  DepartmentId: number

  constructor(userId: number, sessionKey: string, c: WorkerAddContainer) {
    super(userId, sessionKey);
    this.Name = ExtendedHelper.ReplaceBadThings(c.Name);
    this.Mail = ExtendedHelper.ReplaceBadThings(c.Mail);
    this.Password = ExtendedHelper.ReplaceBadThings(c.Password);
    this.SurePassword = ExtendedHelper.ReplaceBadThings(c.SurePassword);
    this.Language = c.Language;
    this.DepartmentId = c.DepartmentId;
  }
}

export class WorkerAddContainer {
  Name: string;
  Mail: string;
  Password: string;
  SurePassword: string;
  Language: number;
  LanguageSelector: Common[];
  DepartmentId: number;
  DepartmentSelector: Common[];

  constructor() {
    this.Name = "";
    this.Mail = "";
    this.Password = "";
    this.SurePassword = "";
    this.Language = 0;
    this.LanguageSelector = [];
    this.DepartmentSelector=[];
  }

  setLanguageSelector(item: Common) {
    this.LanguageSelector = [item];
    this.Language = item.Id;
  }

  setDepartmentSelector(item: Common) {
    this.DepartmentSelector = [item];
    this.DepartmentId = item.Id;
  }
}

export class WorkerInSettingsChangeStatusTypeEnum {
  /// <summary>
  /// Заблокировать/разблокировать
  /// </summary>
  Block: number;

  /// <summary>
  /// Дать права администратора или лишить оных
  /// </summary>
  Admin: number;
  ReceiveMail: number;

  constructor() {
    this.Block = 0;
    this.Admin = 1;
    this.ReceiveMail = 2;
  }
}

export class WorkerInSettingsChangeStatusAsker extends SingleIdRequest {
  Type: number;
  constructor(userId: number, sessionKey: string, workerId: number, type: number) {
    super(userId, sessionKey, workerId);
    this.Type = type;
  }
}

export class MailPasswordGiver extends CommonResponse {
  Mail: string;
  Password: string;
}

export class MainPasswordContainer {
  Mail: string;
  Password: string;
  constructor(m: MailPasswordGiver) {
    this.Mail = m.Mail;
    this.Password = m.Password;
  }
}

export class AutoJobTemplateContainerToDelete {
  Id: number;
  Stage: Common;
  Name: string;
  Text: string;
  TextLocked: boolean;
  AuthorType: number;
  AuthorId: number;
  AuthorLocked: boolean;
  ExecutorWorkers: number[];
  ExecutorRoles: number[];
  ExecutorLocked: boolean;
  NeedChecking: boolean;
  NeedCheckingLocked: boolean;
  Time: number;
  TimeLocked: boolean;
  IsMandatory: boolean;
  CompleteOnStage: boolean;
  AutoJobType: number;
  Tags: Common[];
  TagsLocked: boolean;
  Workers: Common[];//общие списки для выбора исполнителей и поручателя.
  Roles: Common[];

  // ButtonHide: boolean = false;
  AuthorMultyselect: MultyselectContainer;
  ExecutorsMultyselect: MultyselectContainer;
  Holders: CommonWorkerHolder[];






  multiselectsInit: boolean=false;
  authorMultiselectTitle: string="Поручитель";
  authorMultiselectPlaceholder: string="Выберите вариант";
  authorAdditionalValues: AutojobMultyselectElementValue[]=[];
  authorRolesOuterType: number=0;
  authorUsersOuterType: number=1;
  authorSelectedUsers: Common[]=[];
  authorSelectedRoles: Common[]=[];
  authorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];


  executorMultiselectTitle: string="Исполнитель";
  executorMultiselectPlaceholder: string="Выберите вариант";
  executorAdditionalValues: AutojobMultyselectElementValue[]=[];
  executorRolesOuterType: number=0;
  executorUsersOuterType: number=1;
  executorSelectedUsers: Common[]=[];
  executorSelectedRoles: Common[]=[];
  executorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];


 

}

export class RemovingWorkerObjectsGiver extends CommonResponse {
  Jobs: JobLight[];
  AutoJobTemplates: AutoJobTemplateContainerToDelete[];
  Clients: OwnageEntity[];
  Projects: OwnageEntityProject[];
  Storages: Common[];
  constructor() {
    super();
    this.Jobs = [];
    this.AutoJobTemplates = [];
    this.Clients = [];
    this.Projects = [];
    this.Storages=[];
  }
}

export class RemovingWorkerObjectsContainer extends CommonResponse {
  Jobs: JobLight[];
  AutoJobTemplates: AutoJobTemplateContainerToDelete[];
  Clients: OwnageEntity[];
  Projects: OwnageEntityProject[];
  Storages:  StorageContainer[];
  constructor() {
    super();
    this.Jobs = [];
    this.AutoJobTemplates = [];
    this.Clients = [];
    this.Projects = [];
    this.Storages=[];
  }
}




import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
/**
 * Created by MosNeuro on 24.11.2016.
 */
import { Injectable } from '@angular/core';
import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';



import { SessionKiller } from "../models/model.session";
import { CommonRequest } from '../models/model.http';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceSession {

  constructor(private http: HttpClient, private param: ServerString) { }

  checkSession(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //console.log(this.param.fullServerString()+'sessioncheck');
    return this.http.post(this.param.fullServerString() + 'Session/Check', body, { headers: headers, withCredentials: true }).pipe(
      //  //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

 
  closeSession(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Session/Close', body, { headers: headers, withCredentials: true }).pipe(
      //            //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  

 

}

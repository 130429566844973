/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { CookieService} from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { CommonRequest, SingleStringGiver } from '../../../models/model.http';
import * as _ from 'lodash';
import { Observable, fromEvent as observableFromEvent, Subscription} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { Chats_LangSet, Common_LangSet, LanguageSetCommon } from '../../../models/model.language';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { DeleteSession } from '../../../store/actions/session.action';
import { WorkerForChatHolder, WorkerWithPhoto } from '../../../models/model.chat';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { id } from '@swimlane/ngx-charts';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';
import { LoginGiver } from '../../../models/model.login';

declare var require: any;


@Component({
    selector: 'usersInChatList',
    templateUrl: 'temp.usersInChatList.html',
    styleUrls: ['usersInChatList.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceLanguage, CookieOptionsMy, ExtendedHelper]

})
export class usersInChatListComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    jwtToken: string;
    
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    jwtToken$: Subscription=null;
    userId$: Subscription=null;

    SearchFilter: string="";

    
    @Output() SelectChanged: EventEmitter<any> = new EventEmitter<any>();
    UserList: WorkerForChatHolder[]=[];

    SelectedUserList: WorkerWithPhoto[]=[];

    @Input() UserListBack: WorkerForChatHolder[]=[];
    @Input() SelectedUserListBack: WorkerWithPhoto[]=[];


    //CurrentOpenedChat: 
   
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private httpServiceSession: HttpServiceSession,
    private languageService: LanguageService,
    private sessionService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.jwtToken$=this.sessionService.jwtToken$.subscribe(token => this.jwtToken = token);
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);
                
                
                if (!(this.UserId > 0)) {//куков нет значит не авторизованы значит идем логниться
                  this.unLogin();
                }
                else {//куки есть, чекаем сессию
                  let sessionChecker = new CommonRequest();
                  sessionChecker.SessionKey = this.SessionKey;
                  sessionChecker.ChangerId = this.UserId;
                  sessionChecker.UserAuthToken = this.jwtToken;
                  this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
                    if (data.Code == 1) {//если нужно заменить сессию
                      this.SessionKey = data.SessionKey;
                      this.jwtToken = data.UserAuthToken;
                      sessionChecker.SessionKey = this.SessionKey;
                      sessionChecker.UserAuthToken = this.jwtToken;
                      this.cookieService.put('SessionKey', this.SessionKey, this.cookieOptionsMy.get());
                      this.cookieService.put('UserAuthToken', this.jwtToken, this.cookieOptionsMy.get());
                    }
                    if (data.Code == 301 || data.Code == 302 || data.Code == 303 || data.Code == 304 || data.Code == 500) {
                      console.error(data.Message);
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 306) {
                      alert('The request object is filled incorrectly.');
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
            
                    if (data.Code == 0 || data.Code == 1) {//иначе все ок и продолжаем рабоать
                      
                      this.InitLists();
                        
                      
                    }
                  });
                }

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }



              InitLists()
              {
                this.UserList=_.cloneDeep(this.UserListBack);
                this.SelectedUserList=_.cloneDeep(this.SelectedUserListBack);

              }

              ChangeSelectUser(user: any)
              {
                ////
                let ind =  this.SelectedUserListBack.findIndex(c=>c.Id == user.Id )
                if(ind>-1)
                {
                  this.SelectedUserListBack[ind].Selected=user.Selected;
                }

                ind=-1;

                for(let i=0; i<this.UserListBack.length; i++)
                {
                  if(this.UserListBack[i].IsWorker && user.Id==this.UserListBack[i].Worker.Id)
                  {this.UserListBack[i].Worker.Selected=user.Selected;}
                  if(!this.UserListBack[i].IsWorker )
                  {
                    for(let j=0; j<this.UserListBack[i].Department.Workers.length; j++)
                    {
                      if(this.UserListBack[i].Department.Workers[j].Id==user.Id)
                      {
                        this.UserListBack[i].Department.Workers[j].Selected=user.Selected;
                      }
                    }
                  }
                }
                this.SelectChanged.emit();
              }

              /*SearchInList()
              {
              
                this.UserList=[]; 
                this.SelectedUserList=[];
                if(this.SearchFilter!=undefined && this.SearchFilter!=null && this.SearchFilter!="")
                {
                  let separator = new RegExp(/ *\s/, "gi");
                  let filtersChat = this.SearchFilter.toLowerCase().split(separator);
                  for (let i = 0; i < this.UserListBack.length; i++) {
                    if(this.UserListBack[i].IsWorker)
                      {
                        for (var f = 0; f < filtersChat.length; f++) {
                          if (filtersChat[f] != "") {
              
                            if(this.UserListBack[i].Worker.Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                            {this.UserList.push(_.cloneDeep(this.UserListBack[i]));
                              this.UserList[this.UserList.length-1].Worker.Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.UserList[this.UserList.length-1].Worker.Name);
                              break;
                            }
                          }
                        }
                      }
  
  
                        if(!this.UserListBack[i].IsWorker)
                        {
                          let innerSearchedIndexes=[];
  
                          for(let j=0; j<this.UserListBack[i].Department.Workers.length; j++)
                          {
                            for (var f = 0; f < filtersChat.length; f++) {
                              if (filtersChat[f] != "") {
                  
                                if(this.UserListBack[i].Department.Workers[j].Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                                {
                                  innerSearchedIndexes.push(j);
                                  break;
                                }
                              }
                            }
                          }
  
                          if(innerSearchedIndexes.length>0)
                          {
                            this.UserList.push(_.cloneDeep(this.UserListBack[i]));
                            this.UserList[this.UserList.length-1].Department.Workers=[];
                            let userListIndex=this.UserList.length-1;
                            let workersIndex=0;
                            for(let k=0; k<innerSearchedIndexes.length; k++)
                            {
                              
                              this.UserList[userListIndex].Department.Workers.push(_.cloneDeep(this.UserListBack[i].Department.Workers[innerSearchedIndexes[k]]));
                              this.UserList[userListIndex].Department.Workers[workersIndex].Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.UserList[userListIndex].Department.Workers[workersIndex].Name);
                              workersIndex++;
                            }
                          }
  
                      }
                  
                  }
  
  
  
                  for (let i = 0; i < this.SelectedUserListBack.length; i++) {
                    
                        for (var f = 0; f < filtersChat.length; f++) {
                          if (filtersChat[f] != "") {
              
                            if(this.SelectedUserListBack[i].Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                            {this.SelectedUserList.push(_.cloneDeep(this.SelectedUserListBack[i]));
                              this.SelectedUserList[this.SelectedUserList.length-1].Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.SelectedUserList[this.SelectedUserList.length-1].Name);
                              break;
                            }
                          }
                        }
                      }
          
                }


                else
                {
                  this.UserList=_.cloneDeep(this.UserListBack);
                  this.SelectedUserList=_.cloneDeep(this.SelectedUserListBack);
                }
                
                
    
              }*/


              SearchInList()
              {
              
                this.UserList=[]; 
                this.SelectedUserList=[];
                if(this.SearchFilter!=undefined && this.SearchFilter!=null && this.SearchFilter!="")
                {
                  let separator = new RegExp(/ *\s/, "gi");
                  let filtersChat = this.SearchFilter.toLowerCase().split(separator);
                  for (let i = 0; i < this.UserListBack.length; i++) {
                    if(this.UserListBack[i].IsWorker)
                      {
                        for (var f = 0; f < filtersChat.length; f++) {
                          if (filtersChat[f] != "") {
              
                            if(this.UserListBack[i].Worker.Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                            {this.UserList.push(_.cloneDeep(this.UserListBack[i]));
                              this.UserList[this.UserList.length-1].Worker.Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.UserList[this.UserList.length-1].Worker.Name);
                              break;
                            }
                          }
                        }
                      }
  
  
                        if(!this.UserListBack[i].IsWorker)
                        {
                          let innerSearchedIndexes=[];
  
                          for(let j=0; j<this.UserListBack[i].Department.Workers.length; j++)
                          {
                            for (var f = 0; f < filtersChat.length; f++) {
                              if (filtersChat[f] != "") {
                  
                                if(this.UserListBack[i].Department.Workers[j].Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                                {
                                  innerSearchedIndexes.push(j);
                                  break;
                                }
                              }
                            }
                          }
  
                          if(innerSearchedIndexes.length>0)
                          {
                            this.UserList.push(_.cloneDeep(this.UserListBack[i]));
                            this.UserList[this.UserList.length-1].Department.Workers=[];
                            let userListIndex=this.UserList.length-1;
                            let workersIndex=0;
                            for(let k=0; k<innerSearchedIndexes.length; k++)
                            {
                              
                              this.UserList[userListIndex].Department.Workers.push(_.cloneDeep(this.UserListBack[i].Department.Workers[innerSearchedIndexes[k]]));
                              this.UserList[userListIndex].Department.Workers[workersIndex].Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.UserList[userListIndex].Department.Workers[workersIndex].Name);
                              workersIndex++;
                            }
                          }
  
                      }
                  
                  }
  
  
  
                  for (let i = 0; i < this.SelectedUserListBack.length; i++) {
                    
                        for (var f = 0; f < filtersChat.length; f++) {
                          if (filtersChat[f] != "") {
              
                            if(this.SelectedUserListBack[i].Name.toLowerCase().indexOf(filtersChat[f]) != -1)
                            {this.SelectedUserList.push(_.cloneDeep(this.SelectedUserListBack[i]));
                              this.SelectedUserList[this.SelectedUserList.length-1].Name=SearchSelectHelper.getSearchedText(this.SearchFilter, this.SelectedUserList[this.SelectedUserList.length-1].Name);
                              break;
                            }
                          }
                        }
                      }
          
                }


                else
                {
                  this.UserList=_.cloneDeep(this.UserListBack);
                  this.SelectedUserList=_.cloneDeep(this.SelectedUserListBack);
                }
                
                
    
              }
}

import { SessionSyncService } from './../../services/session-sync.service';
import { ChangeSession, DeleteSession } from './../../store/actions/session.action';
import { ConfirmDoneIcon } from './../../shared/components/svg-icon/svg-icon.data';
import { HttpServiceSession } from './../../httpserv/httpserv.session';
import { Observable, Subject, Subscription, timer as observableTimer } from 'rxjs';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { VersionAsker, VersionGiver } from '../../config/Version';
import { Chats_LangSet, Common_LangSet, Jobs_LangSet, LanguageSetCommon, LanguageSetCommonGiver, Login_LangSet } from '../../models/model.language';
import { CommonRequest, CommonResponse, SingleStringGiver, SingleStringRequest } from '../../models/model.http';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpServiceLanguage } from '../../httpserv/httpserv.language';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { CookieOptionsMy } from '../../config/CookieOptionsMy';
import { CookieService } from 'ngx-cookie';
import { WorkerGiver, WorkerProfile } from '../../models/model.worker';
import { HttpServiceWorker } from '../../httpserv/httpserv.worker';
import {
  ChatNotificationsGiver,
  JobNotificationsGiver,
  NotificationChatContainer,
  NotificationCloseAsker,
  NotificationCounter,
  NotificationJobContainer,
  NotificationsCountGiver
} from '../../models/model.notification';
import { HttpServiceNotifications } from '../../httpserv/httpserv.notification';
import { LangSetLoginGiver, LoginLangSetAsker } from '../../models/model.login';
import { LangSetLogin } from '../../models/model.loginLanguage';
import { AccessGroupsOfWorkerGiver, ActiveAccessGroup } from '../../models/model.accessGroup';
import { Code } from '../../models/model.Code';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HttpServiceAccessGroup } from '../../httpserv/httpserv.accessGroup';
import { SvgIconsRegistry } from '../../shared/components/svg-icon/svg-icon-registry.service';
import { EnvelopeIcon, JobNotificationIcon } from '../../shared/components/svg-icon/svg-icon.data';
import { JobStatusEnum } from '../../models/model.Job/Inner/model.JobStatusEnum';
import { HttpServiceJobNew } from '../../httpserv/httpserv.jobNew';
import { HttpServiceTag } from '../../httpserv/httpserv.tag';
import { HttpServiceLoginNew } from '../../httpserv/httpserv.loginnew';
import { TextAreaComponent } from '../../shared/components/text-area/text-area.component';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { SignalRService } from '../../services/signalRService';
import { JobNotificatorGiver } from '../../models/model.Job/In/model.JobCountGiver';
import { ChatNotificator, ReadMessageNotificator, RemovedMessageNotificator } from '../../models/model.chat';
import { HttpServiceChat } from '../../httpserv/httpserv.chat';
import { HttpServiceFile } from '../../httpserv/httserv.file';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { TransportService } from '../../services/transport.service';

interface languageImage {
    img: string,
    name: string
}

@Component({
    selector: 'navigation-header',
    templateUrl: './header.component.html',
    styleUrls: [
        './header.component.scss',
        '../../../style/supportLibrary.scss',
        '../../../style/control-inputs.scss'
    ],
    providers: [
        HttpServiceSession,
        HttpServiceLanguage,
        CookieOptionsMy,
        HttpServiceWorker,
        HttpServiceNotifications,
        HttpServiceJobNew,
        HttpServiceAccessGroup,
        HttpServiceLoginNew,
        ExtendedHelper,
        HttpServiceTag,
        HttpServiceChat,
        HttpServiceFile
    ]
})


export class HeaderComponent implements OnInit {

    UserId: number = 0;
    SessionKey: string;
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    checkSub: Subscription=null;
    s_lan: Subscription=null;

    @ViewChild(TextAreaComponent) textAreaComponent: TextAreaComponent;

    serverAvailable: boolean;
    SystemVersion: VersionGiver = new VersionGiver();
    //langset: LanguageSetCommon = new LanguageSetCommon();
    enableSound: boolean=true;
    languageSelected: languageImage;
    languageMenu: languageImage[] = [
        {
            img: 'assets/image/ru.png',
            name: 'RU'
        },
        {
            img: 'assets/image/en.png',
            name: 'EN'
        },
        {
            img: 'assets/image/vn.png',
            name: 'VN'
        }
    ]

    OpenChat: Subject<number> = new Subject<number>();
    currentWorker: WorkerProfile = new WorkerProfile();
    workerStatusCookieName: string = "workerStatus";
    statusEdit: boolean = false;

    GettingACI: boolean = false;
    AccessGroupsToMakeActive: ActiveAccessGroup[] = [];
    currentAccessGroup: ActiveAccessGroup[];

    AccessGroupsToMakeActiveId: number[] = [];

    hoverColoroNotificationJob: '#FFFFFF' | '#30AD63' = '#FFFFFF';
    hoverColoroNotificationMessage: '#FFFFFF' | '#E1B237' = '#FFFFFF';
    NotificationCounter: NotificationCounter = new NotificationCounter();
    notificationTimer: any;
    notificationSub: Subscription;
    notificationJobContainer: NotificationJobContainer;
    jobStatus: JobStatusEnum = new JobStatusEnum();

    notificationChatContainer: NotificationChatContainer;

    NotificationsShowDialog: boolean = false;
    notificationsLoading: boolean = false;

    Focused: boolean = false;
    NotificationsMesShowDialog: boolean = false;
    notificationsMesLoading: boolean = false;

    isRefreshingNotificationsCount: boolean = false;


    settsVisible: boolean = false;
    UserOptionsShow: boolean = false;
    loginLangSet: LangSetLogin = new LangSetLogin();


    isLoginPage: boolean;
    isShowActiveGroup: boolean;

    currentLanguage: number=0;

    langset: Common_LangSet;
    ln_sub: Subscription=null;
    jobs_Langset: Jobs_LangSet;
    jb_sub: Subscription=null;
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;

    wr_sub: Subscription=null;

    constructor(
        // private svgIconsRegistry: SvgIconsRegistry
        private store$: Store,
        private router: Router,
        private cookieService: CookieService,
        private localStorageService: LocalStorageService,
        private httpServiceLanguage: HttpServiceLanguage,
        private httpServiceWorker: HttpServiceWorker,
        private httpServiceLoginNew: HttpServiceLoginNew,
        private cookieOptionsMy: CookieOptionsMy,
        private httpServiceSession: HttpServiceSession,
        private httpServiceNotifications: HttpServiceNotifications,
        private httpServiceAccessGroup: HttpServiceAccessGroup,
        private svgIconsRegistry: SvgIconsRegistry,
        private sessionSyncService: SessionSyncService,
        public signalRService: SignalRService,
        private sessionService: SessionService,
        private languageService: LanguageService,
        private transportService: TransportService

    ) {
       // this.sessionSyncService.init();
        this.sessionKey$=sessionService.sessionKey$.subscribe(key => { this.SessionKey = key; this.getHeaderConfig(); });
        this.userId$=sessionService.userId$.subscribe(id => this.UserId = id);
        this.s_lan=sessionService.currentWorker$.subscribe(worker=>{            
            if(worker!=undefined && worker!=null && worker.Language!=undefined && worker.Language!=null)
            {this.currentLanguage=worker.Language}});
            router.events.subscribe((val) => {  
                this.checkSub = sessionService.isSessionChecked$.subscribe((check)=>{
                    if(check)
                        {
                            if (val instanceof NavigationEnd) {
                            
                                this.isLoginPage = Boolean(this.checkIsLoginPageByUrl(val.url));
                                this.isShowActiveGroup = Boolean(this.checkIsShowActiveGroup(val.url));
                                this.UserOptionsShow = false;
                                if (this.NotificationsMesShowDialog) this.NotificationsMesShowDialog = false;
                                if (this.NotificationsShowDialog) this.NotificationsShowDialog = false;

                                if (!this.isLoginPage) {
                                    this.UnsubscribeNotification();
                                    this.getAuth();
                                    }
                            }
                        }
                    });
            });

        this.svgIconsRegistry.registerIcons([
            EnvelopeIcon,
            JobNotificationIcon,
            ConfirmDoneIcon
        ])
    }

    getHeaderConfig() {

    }

    ngOnInit() {
        this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
        this.jb_sub=this.languageService.jobsLanguage$.subscribe(lang=>this.jobs_Langset=lang);
        this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);

        this.wr_sub=this.sessionService.currentWorker$.subscribe(worker=>this.currentWorker=worker);
        this.ChangeLangSet(0);

        this.ServTest();
    }

    closeChat() {
        if (this.NotificationsMesShowDialog) this.NotificationsMesShowDialog = false;
    }

  
    getAuth() {
        // this.GetSessionWorker();


        if (this.UserId && this.UserId != 0 && this.SessionKey) {
            
                    //основаная загрузка
                    //this.LoadCurrentWorker();
                    this.LoadSystemVersion();
                    this.LoadActiveAccessGroups();
                    this.GetSmallNotifications();
                    this.signalRService.JobChanged.subscribe((notification: JobNotificatorGiver)=>
                    {
                        
                        if(notification.List.findIndex(c=>c.Worker==this.UserId)>-1)
                        {
                            this.GetSmallNotifications();
                            
                        }
                        
                    });
                    this.signalRService.NewMessage.subscribe((message: ChatNotificator)=>
                    {
                        if(message.NotifiedWorkers!=undefined && message.NotifiedWorkers!=null && message.NotifiedWorkers.findIndex(c=>c==this.UserId)>-1)
                        {
                            this.GetSmallNotifications();
                        }
                        
                    });

                    this.signalRService.MessageReaded.subscribe((message: ReadMessageNotificator)=>
                    {
                        if(message.Reader==this.UserId)
                        {
                            this.GetSmallNotifications();
                        }
                        
                    });

                    this.signalRService.MessageRemoved.subscribe((message: RemovedMessageNotificator)=>
                    {
                        if(message.WorkerId==this.UserId)
                        {
                            this.GetSmallNotifications();
                        }
                        
                    });
                
                
        }
         else {

        }
    }


    @HostListener('document:keydown', ['$event'])
    public onKeyDown(e: KeyboardEvent): void {
        switch (e.keyCode) {
            case 27: {//ESCAPE


               // console.log(e)
                this.NotificationsShowDialog = false;
                this.NotificationsMesShowDialog = false;
                this.UserOptionsShow = false;
            }
        }
    }

    ServTest() {
        this.httpServiceLoginNew.checkServer().subscribe((data: CommonResponse) => {
            if (data.Code == 0) {
                this.serverAvailable = true;
                console.log("OK");
            }
            if (data.Code != 0) {
                console.log('Что-то пошло не так');
            }
        },
            error => {
                console.log(error["status"]);
                console.log(error["headers"]);
                this.serverAvailable = false;

            });
    }

    LoadSystemVersion() {
        let checker = new VersionAsker();
        checker.SessionKey = this.SessionKey;
        checker.ChangerId = this.UserId;
        checker.ClientVersion = this.SystemVersion.getConfigVersion();
        this.httpServiceWorker.getVersion(checker).subscribe((data: VersionGiver) => {
            if (data.Code == 100) {
                this.Logout();
                return;
            }
            if (data.Code == 300) { console.warn(data.Message); return; }
            if (data.Code == 500) { console.error(data.Message); return; }

            this.SystemVersion.ServerVersion = data.ServerVersion;
            if (data.ClientRefreshRequired) {
                this.SystemVersion.ClientVersion = data.ClientVersion;
            }
        });
    }

    checkIsLoginPage() {
        return this.router.url.match('/login');
    }

    checkIsLoginPageByUrl(url: string) {
        return url.match('/login');
    }

    checkIsShowActiveGroup(url: string) {
        return (url.match('/main')
            || url.match('/client/')
            || url.match('/project/'));
    }

   
    ChangeLangSet(lang: number) {
        this.languageService.RefreshLoginLanguage(lang);
        this.languageSelected = this.languageMenu[lang]
        
    }

   /* LoadCurrentWorker() {
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceWorker.getWorker(checker).subscribe((data: WorkerGiver) => {
            if (data.Code == 1) { this.Logout(); return; }
            if (data.Code == 500) { console.error(data.Message); return; }

            this.currentWorker = data.Worker;
            this.currentWorker.Status = this.currentWorker.Status.replace(/\n/gi, '<br/>');
            this.cookieService.put(this.workerStatusCookieName, data.Worker.Status, this.cookieOptionsMy.get());
        });
    }*/

    ngOnDestroy() {
        this.UnsubscribeNotification();
        if(this.ln_sub)
        {
            this.ln_sub.unsubscribe();
        }
    }

    Logout(message: string = null) {
        if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
        this.UnsubscribeNotification();

        
        this.sessionService.clearSession();
        //this.getLogoutLink();
    }

    getLogoutLink() {
        let link = ['/login'];
        // let id: number;
        // this.route.params.forEach((params: Params) => { { id = params['id']; } });

        if (this.router.url.match('/project')){
            link = [`/login/p-${[this.router.url][0].split('/')[2]}`];
        }
        if(this.router.url.match('/client')){
            link = [`/login/c-${[this.router.url][0].split('/')[2]}`];
        }
        this.router.navigate(link);
    }


    killSession() {
        let sessionChecker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceSession.closeSession(sessionChecker).subscribe((data: CommonResponse) => {
            if (data.Code == 100 || data.Code == 0) this.Logout();
            if (data.Code == 500) { console.error(data.Message); return; }
            this.settsVisible = false;
            this.UserOptionsShow = false;
        });
    }

    NotificationSubscription() {
        this.notificationTimer = observableTimer(1000, 1000);//(2000,5000);
        this.notificationSub = this.notificationTimer.subscribe(() => this.RefreshNotifications());
    }

    UnsubscribeNotification() {
        if (this.notificationSub) this.notificationSub.unsubscribe();
        this.notificationSub = null;
    }

    RefreshNotifications() {
        
        this.GetSmallNotifications();
 
    }


    GetJobNotifications() {
        this.NotificationsShowDialog = true;
        this.notificationsLoading = true;
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceNotifications.getJobNotifications(checker).subscribe((data: JobNotificationsGiver) => {
            this.notificationsLoading = false;
            if (data.Code == 100) {
                this.Logout();
                return
            }
            if (data.Code == 500) { console.error(data.Message); return; }

            this.notificationJobContainer = new NotificationJobContainer(data);
        });
    }


    GetSmallNotifications() {
        if (this.isRefreshingNotificationsCount) return;
        this.isRefreshingNotificationsCount = true;
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceNotifications.getNotificationsCount(checker).subscribe((data: NotificationsCountGiver) => {
            this.isRefreshingNotificationsCount = false;
            if (data.Code == 100) {
                this.Logout();
                return
            }
            if (data.Code == 500) { console.error(data.Message); return; }
            if (this.NotificationCounter.JobCount != data.JobCount && this.NotificationsShowDialog) this.GetJobNotifications();
            if (this.NotificationCounter.MessageCount != data.MessageCount && this.NotificationsMesShowDialog) this.GetChatNotifications();
            this.NotificationCounter = new NotificationCounter(data);
        });
    }



    GetChatNotifications() {
        this.NotificationsMesShowDialog = true;
        this.notificationsMesLoading = true;
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceNotifications.getChatNotifications(checker).subscribe((data: ChatNotificationsGiver) => {
            this.notificationsMesLoading = false;
            if (data.Code == 100) {
                this.Logout();
                return
            }
            if (data.Code == 500) { console.error(data.Message); return; }

            this.notificationChatContainer = new NotificationChatContainer(data);
        });
    }


    SetACIToCookie(): boolean {
        if (this.AccessGroupsToMakeActive.length > 0) {
            if (this.currentAccessGroup != null) {
                this.cookieService.put('activegroup', this.currentAccessGroup[0].Id.toString(), this.cookieOptionsMy.get());
                return true;
            }
        }
        return true;
    }

    GetACIFromCookie() {
        if (this.GettingACI) return;
        this.GettingACI = true;
        let id = +this.cookieService.get('activegroup');
        // let found = false;

        let item = this.AccessGroupsToMakeActive.find(el => el.Id == id);
        console.log(item)
        if (item != undefined) {
            this.currentAccessGroup = [item];
        } else {
            this.currentAccessGroup = [this.AccessGroupsToMakeActive[0]];
        }

        // for (var i = 0; i < this.AccessGroupsToMakeActive.length; i++) {
        //     if (id == this.AccessGroupsToMakeActive[i].Id) {
        //         found = true;
        //         this.currentAccessGroup = this.AccessGroupsToMakeActive[i];
        //         break;
        //     }
        // }

        // if (!found && this.AccessGroupsToMakeActive[0] != null) {
        //     this.currentAccessGroup = this.AccessGroupsToMakeActive[0];
        // }

        if (this.SetACIToCookie()) this.GettingACI = false;
    }


    LoadActiveAccessGroups() {
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.httpServiceAccessGroup.getAccessGroupsOfCurrentWorker(checker).subscribe((data: AccessGroupsOfWorkerGiver) => {
            if (!data.Groups) { this.Logout(); return; }
            if (data.Groups.length > 0) {
                if (data.Groups[0].Id == 0) {
                    this.Logout();
                    return;
                }
            }
            this.AccessGroupsToMakeActive = [...data.Groups];
            this.AccessGroupsToMakeActiveId = this.AccessGroupsToMakeActive.map(el => el.Id);
            this.GetACIFromCookie();
        },
            error => {
                console.error(checker + error["message"]);
            })
    }

    ShowSettingsPanel() {
        let sound = this.localStorageService.get("enableSound");
    
        if (sound!=undefined && sound!=null && sound!= "")
        {
           
        this.enableSound=sound==="true";
        }
        else
        {
            
            this.enableSound=true;
            this.localStorageService.set("enableSound",  "true");
        }
        this.UserOptionsShow = !this.UserOptionsShow;
        if (this.settsVisible) {
            this.settsVisible = false;
        }
        else {
            this.settsVisible = true;
        }
    }


    SoundOff()
    {
        this.enableSound=false;
        this.localStorageService.set("enableSound",  "false");
    }

    SoundOn()
    {
        this.enableSound=true;
        this.localStorageService.set("enableSound",  "true");
    }



    EditStatus() {
        this.statusEdit = true;
        let tags = new RegExp(/<[^>]+>/, "gi");
        this.currentWorker.Status = this.currentWorker.Status.replace(tags, '\n');
        setTimeout(() => {
            this.textAreaComponent.setFocus();
        }, 0);
    }

    SetWorkerStatus() {
        let message = ExtendedHelper.ReplaceBadThings(this.currentWorker.Status);
        // message = message.replace(/\n/gi, '<br/>');
        let tags = new RegExp(/<[^>]+>/, "gi");
        message = this.currentWorker.Status.replace(tags, '\n');
        let checker = new SingleStringRequest(this.UserId, this.SessionKey, message);
        this.httpServiceWorker.setStatus(checker).subscribe((data: SingleStringGiver) => {
            if (data.Code == 100) {
                this.Logout(data.Message);
                return;
            }
            if (data.Code == 300) {
                console.log(data.Message);
                return;
            }
            if (data.Code == 500) { console.error(data.Message); return; }

            this.currentWorker.Status = data.Value.replace(/\n/gi, '<br/>');;
            this.cookieService.put(this.workerStatusCookieName, data.Value, this.cookieOptionsMy.get());
        });
        this.statusEdit = false;
    }


    DeleteMassiveById(massive: any[], id: number) {
        let Index = massive.findIndex(c => c.Id == id);
        if (Index != -1) massive.splice(Index, 1);
    }

    DeleteNotification(id: number, type: number) {
        let checker = new NotificationCloseAsker(this.UserId, this.SessionKey, id, type);
        this.httpServiceNotifications.closeNotification(checker).subscribe((data: Code) => {
            if (data.Id == 0) {
                this.Logout();
                return;
            }
            if (id != 0) {
                if (type == 0) {
                    this.notificationJobContainer.ClearListFromJob(id);
                }
                else {
                    this.notificationChatContainer.ClearListFromChat(id);
                }
            }
            if (id == 0) {
                if (type == 0) this.notificationJobContainer.ClearJobList();
                if (type == 1) this.notificationChatContainer.ClearChatList();
            }
        },
            error => {
                console.error(checker + error["message"]);
            });
    }

}

/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent, of, from } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { Common } from '../../../models/model.common';
import { ParameterInstance, ParameterInstanceAddAsker, ParameterInstanceContainer, ParameterInstanceContainerGiver, ParameterInstanceEditAsker, ParameterValueContainerGiver, ValueOfSetContainer } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { M } from 'angular-mydatepicker';
import { toNumber } from 'lodash';


declare var require: any;


@Component({
    selector: 'parameterInstanceRecordElement',
    templateUrl: 'temp.parameterInstanceRecordElement.html',
    styleUrls: ['parameterInstanceRecordElement.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceRecordElementComponent implements OnInit, AfterViewInit {
    
    
    @Input() elementFields: ParameterInstanceContainer[];
    @Output() elementFieldsChange:  EventEmitter<ParameterInstanceContainer[]> = new EventEmitter<ParameterInstanceContainer[]>();
    @Output() deleteParameter:  EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isList: boolean=false;
    @Input() Name: string="Parameter Name";
    @Input() CanDelete: boolean = true;
    @Input() id: number=-1;
    @Input() editable: boolean=true;
    @Input() inner: boolean=true;
    @Input() showName: boolean = false;

    @Input() recordAxisType: number=0;
    @Input() elementIndex: number=0;
    PossibleValueSetsIds: number[]=[];
    @Input() paramId: any='-1';
    @Input() firstInList: boolean=true;

    test: boolean = true;
    
    InstanceValuesList:any[]=[];
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;
    addingSetInList: boolean=false;
    setLevel: number=0;
    @Input() containerLocation: number=0;

   ValuesType: number=0;
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private sessionService: SessionService,
    private router: Router
              ) { }

            

              // DEBUG
              ngOnChanges(changes: any){

                //current value
                let currentVal= changes.elementFields.currentValue
                // previouse value
                let prev = changes.elementFields.previousValue

                // recalc 
                this.InstanceValuesList=[];
                this.PossibleValueSetsIds=[];
                this.elementFields.forEach(elem=>
                  {
                    if(elem.Type==0 && elem.Instance.ValueType==4)
                    {
                      this.InstanceValuesList.push([{"Id": elem.Instance.Id,"selectedValue":elem.Instance.SelectedValue!=null?[elem.Instance.SelectedValue]:[], "values":[], "valueSetType":0}]);
                      this.PossibleValueSetsIds.push(elem.Instance.ValueSet.Id);
                    }
                    else
                    {
                      if(elem.Type==1 && elem.ListTitle.Instances[0].ValueType==4)
                      {
                        let instList: any[]=[];
                        for(let i=0; i<elem.ListTitle.Instances.length; i++)
                        {
                          instList.push({"Id": elem.ListTitle.Instances[i].Id,"selectedValue":elem.ListTitle.Instances[i].SelectedValue!=null?[elem.ListTitle.Instances[i].SelectedValue]:[], "values":[], "valueSetType":0});
                          
                        }
                        this.InstanceValuesList.push(instList);
                      }
                      else
                      {
                        this.InstanceValuesList.push([]);
                      }
                      
                    }

                   
                  })

               }

              // END DEBUG


    levelSetterSingle(el:ParameterInstanceContainer): boolean
    {
      
      let haveHorizontal=false;
               
                  if(el.Type==0)
                  {
                    if(el.Instance.ValueType!=5)
                    {
                      el.Instance.HorizontalLevel=this.setLevel;
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(el.Instance.RecordAxisType==0)
                      {
                        this.levelSetter(el.Instance);
                        haveHorizontal=true;
                      }
                      if(el.Instance.RecordAxisType==1)
                      {
                        let res=this.levelSetter(el.Instance);
                        if(!res)
                        {
                          this.setLevel++;
                          let fieldsCount=0;
                          el.Instance.RecordFields.forEach(c=>{
                            if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                          {
                            fieldsCount++;
                          }})
                          this.setLevel=this.setLevel-fieldsCount;
                          
                        }
                      }
                    }
                  }
                  else
                  {
                    if(el.ListTitle.Instances[0].ValueType!=5)
                    {
                      el.ListTitle.Instances.forEach(l=>{l.HorizontalLevel=this.setLevel;})
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(el.ListTitle.Instances[0].RecordAxisType==0)
                      {
                        let bufferLevel=this.setLevel;
                        el.ListTitle.Instances.forEach(lv=>{
                          this.setLevel=bufferLevel;
                           this.levelSetter(lv)})
                        //this.levelSetter(l.ListTitle.Instances[0]);
                        haveHorizontal=true;
                      }
                      if(el.ListTitle.Instances[0].RecordAxisType==1)
                      {

                        el.ListTitle.Instances.forEach(lv=>{
                          let res=this.levelSetter(lv);
                          if(!res)
                          {
                            this.setLevel++;
                            let fieldsCount=0;
                            lv.RecordFields.forEach(c=>{
                              if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                            {
                              fieldsCount++;
                            }})
                            this.setLevel=this.setLevel-fieldsCount;
                            
                          }

                        })

                        
                      }
                    }
                  }
                
                return haveHorizontal;
    }



              levelSetter(el:ParameterInstance): boolean
              {
                let haveHorizontal=false;
                el.RecordFields.forEach(l=>{
                  if(l.Type==0)
                  {
                    if(l.Instance.ValueType!=5)
                    {
                      l.Instance.HorizontalLevel=this.setLevel;
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(l.Instance.RecordAxisType==0)
                      {
                        this.levelSetter(l.Instance);
                        haveHorizontal=true;
                      }
                      if(l.Instance.RecordAxisType==1)
                      {
                        let res=this.levelSetter(l.Instance);
                        if(!res)
                        {
                          this.setLevel++;
                          let fieldsCount=0;
                          l.Instance.RecordFields.forEach(c=>{
                            if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                          {
                            fieldsCount++;
                          }})
                          this.setLevel=this.setLevel-fieldsCount;
                          
                        }
                      }
                    }
                  }
                  else
                  {
                    if(l.ListTitle.Instances[0].ValueType!=5)
                    {
                      l.ListTitle.Instances.forEach(l=>{l.HorizontalLevel=this.setLevel;})
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(l.ListTitle.Instances[0].RecordAxisType==0)
                      {
                        let bufferLevel=this.setLevel;
                        l.ListTitle.Instances.forEach(lv=>{
                          this.setLevel=bufferLevel;
                           this.levelSetter(lv)})
                        //this.levelSetter(l.ListTitle.Instances[0]);
                        haveHorizontal=true;
                      }
                      if(l.ListTitle.Instances[0].RecordAxisType==1)
                      {

                        l.ListTitle.Instances.forEach(lv=>{
                          let res=this.levelSetter(lv);
                          if(!res)
                          {
                            this.setLevel++;
                            let fieldsCount=0;
                            lv.RecordFields.forEach(c=>{
                              if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                            {
                              fieldsCount++;
                            }})
                            this.setLevel=this.setLevel-fieldsCount;
                            
                          }

                        })

                        
                      }
                    }
                  }
                }
                  
                )
                return haveHorizontal;
              }


              ngOnDestroy() {
               
              }

              ngAfterViewInit() {
              }

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);


                this.InstanceValuesList=[];
                this.PossibleValueSetsIds=[];
                this.elementFields.forEach(elem=>
                  {
                    if(elem.Type==0 && elem.Instance.ValueType==4)
                    {
                      this.InstanceValuesList.push([{"Id": elem.Instance.Id,"selectedValue":elem.Instance.SelectedValue!=null?[elem.Instance.SelectedValue]:[], "values":[], "valueSetType":0}]);
                      this.PossibleValueSetsIds.push(elem.Instance.ValueSet.Id);
                    }
                    else
                    {
                      if(elem.Type==1 && elem.ListTitle.Instances[0].ValueType==4)
                      {
                        let instList: any[]=[];
                        for(let i=0; i<elem.ListTitle.Instances.length; i++)
                        {
                          instList.push({"Id": elem.ListTitle.Instances[i].Id,"selectedValue":elem.ListTitle.Instances[i].SelectedValue!=null?[elem.ListTitle.Instances[i].SelectedValue]:[], "values":[], "valueSetType":0});
                          
                        }
                        this.InstanceValuesList.push(instList);
                        
                      }
                      else
                      {
                        this.InstanceValuesList.push([]);
                      }
                      
                    }

                   
                  })

        
              }

              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }
              
            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }


              onValueChange()
              {

                
                this.elementFieldsChange.emit(this.elementFields);
              }

              


            
              
              DeleteParameter()
              {
                this.deleteParameter.emit(true);
              }
              



              
              ValueChange(val: any, elem: ParameterInstance)
              {
                elem.BoolValue=val;
                this.EditValue(elem);
              }



              EditValue(value: ParameterInstance, selected:any[]=null)
              {
                console.log('EditValue')
                console.log(value.ValueType)
                
                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=value.Id;
                if(value.ValueType==0)
                {
                  if(value.StringValue!=null && value.StringValue!="" && value.StringValue.trim()=="")
                  {
                    console.log("Нельзя сохранить значение состоящее только из пробелов и знаков табуляции")
                    {
                      value.StringValue=null;
                      //return;
                    }
                  }
                  checker.StringValue=ExtendedHelper.ReplaceBadThings(value.StringValue);

                }

                if(value.ValueType==1)
                {
                  checker.NumberValue=value.NumberValue;
                }

                if(value.ValueType==2)
                {
                  checker.NumberValue=value.NumberValue;
                }

                if(value.ValueType==3)
                {
                  checker.BoolValue=value.BoolValue;
                }

                if(value.ValueType==4)
                {
                  
                  if(selected!=null && selected.length>0)
                  {
                    console.log('selected != null')
                      if(selected[0].Id!=null)
                      {
                        checker.SelectedValueId=selected[0].Id;
                      }
                      else
                      {
                        //checker.NewCreatedValue.NameString=ExtendedHelper.ReplaceBadThings(selected[0].Name);

                        checker.NewCreatedValue= new ValueOfSetContainer();
                        if(value.ValueSet.ValueType==0)
                        {
                          checker.NewCreatedValue.NameString=ExtendedHelper.ReplaceBadThings(selected[0].Name);
                        }
                        if(value.ValueSet.ValueType==1 || value.ValueSet.ValueType==2)
                        {
                          checker.NewCreatedValue.NameNumber= Number(selected[0].Name);
                        }
                        if(value.ValueSet.ValueType==3)
                        {
                          let date=selected[0].Name.split(".");
                          
                          checker.NewCreatedValue.NameDate=new Date();
                          
                          checker.NewCreatedValue.NameDate.setUTCHours(0,0,0,0);
                          
                          checker.NewCreatedValue.NameDate.setUTCFullYear(Number(date[2]));
                          checker.NewCreatedValue.NameDate.setUTCDate(Number(date[0]));
                          checker.NewCreatedValue.NameDate.setUTCMonth(Number(date[1])-1);
                        }
                      }
                      
                    
                    
                  }

                  if(selected==null)
                  {
                    
                      checker.SelectedValueId=null;
                    
                  }
                  
                  
                }


                if(value.ValueType==6)
                {
                  
                  checker.DateValue=value.DateValue;
                }
                
                
                this.httpServiceParameters.parameterInstanceEditRecordFieldValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                      
                      if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }
                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }
                  


                      if(data.Code==303)
                      {
                          alert("параметр был удален");
                      }

                      if(data.Code==304)
                      {
                          alert("поле является записью");
                      }

                      if(data.Code==305)
                      {
                          alert("поле не найдено");
                      }
                      if(data.Code==306)
                      {
                        alert("поле было удалено");
                      }
                      if(data.Code==307)
                      {
                          alert("нельзя добавлять значения в данный набор");
                      }

                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {

                      if (value.ValueType === 4) {
                        let fieldId=this.elementFields.findIndex(c=>c.Type==0 && c.Instance.ValueType==4 && c.Instance.ValueSet.Id==value.ValueSet.Id);
                        if (this.elementFields[fieldId] && this.elementFields[fieldId].Instance) {
                          if(selected && selected[0])
                          {
                            this.elementFields[fieldId].Instance.SelectedValue=new Common();
                            this.elementFields[fieldId].Instance.SelectedValue.Id=selected[0].Id;
                            this.elementFields[fieldId].Instance.SelectedValue.Name=selected[0].Name;
                            this.InstanceValuesList[fieldId][0].selectedValue=[this.elementFields[fieldId].Instance.SelectedValue];
                          }
                          else
                          {
                            this.elementFields[fieldId].Instance.SelectedValue=null;
                            this.InstanceValuesList[fieldId][0].selectedValue=null;
                          }
                        }
                      }

                      this.onValueChange();
                    }

                });  

              }


              DefaultValue()
              {}


              findLev(cont: ParameterInstance): number
              {

                
                let lvl;
                if(cont.ValueType!=5)
                {
                  lvl=cont.HorizontalLevel;
                }
                else
                {
                  if(cont.RecordFields[0].Type==0)
                  {
                    lvl=this.findLev(cont.RecordFields[0].Instance);
                  }
                  else
                  {
                    lvl=this.findLev(cont.RecordFields[0].ListTitle.Instances[0]);
                  }
                }
                return lvl;
              }

              AddValueInList(field: ParameterInstanceContainer, list: any)
              {

                if(this.addingSetInList) return;
                this.addingSetInList=true;
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=field.ListTitle.Id;
                
                this.httpServiceParameters.parameterInstanceAddElementToFieldList(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                   
                      
                      if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }
                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }
                      if(data.Code==303)
                      {
                          alert("тайтл не найден");
                          this.addingSetInList=false;
                      }
                      if(data.Code==304)
                      {
                          alert("тайтл был удален");
                          this.addingSetInList=false;
                      }
                      if(data.Code==305)
                      {
                          alert("параметр не является списком");
                          this.addingSetInList=false;
                      }

                      if(data.Code==305)
                      {
                          alert("корневое вхождение удалено");
                          this.addingSetInList=false;
                      }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {


                      
                      this.addingSetInList=false;
                      let instList:any;
                      data.List.forEach(el=>{

                        if(field.ListTitle.Instances[0].ValueType!=5)
                        {
                          this.setLevel=field.ListTitle.Instances[0].HorizontalLevel;
                        }
                        else
                        {
                          
                          this.setLevel=this.findLev(field.ListTitle.Instances[0]);
                        }
                      this.levelSetterSingle(el);
                        field.ListTitle.Instances.push(el.Instance);
                        
                      instList={"Id": el.Instance.Id,"selectedValue":el.Instance.SelectedValue!=null?[el.Instance.SelectedValue]:[], "values":[], "valueSetType":0};
                         
                      });

                      if(field.ListTitle.Instances[0].ValueType==4)
                      {
                        list.push(instList);
                      }
                      
                      
                      this.onValueChange();
                    }

                });  
              }


              DeleteValueInList(value: ParameterInstance, field: ParameterInstanceContainer, index:number=null)
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=value.Id;
                
                this.httpServiceParameters.parameterInstanceRemoveElementFromFieldList(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                      if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }
                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }

                      if(data.Code==303)
                      {
                        
                          console.log("вхождение не найдено");
                          field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                      }
                      if(data.Code==304)
                      {
                        console.log("вхождение было удалено");
                        field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                      }


                      if(data.Code==304)
                      {
                        console.log("вхождение было удалено");
                        field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                      }


                      if(data.Code==305)
                      {
                        alert("поле не является списком");
                      }

                      if(data.Code==306)
                      {
                        alert("тайтл не найден");
                      }
                      if(data.Code==307)
                      {
                        alert("тайтл был удален");
                      }

                      if(data.Code==308)
                      {
                        alert("последний элемент списка не может быть удален");
                      }
                      if(data.Code==309)
                      {
                        alert("параметр был удален");
                      }
                      
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {

                      
                      field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id );
                      
                      if(index!=null)
                      {
                        
                        this.InstanceValuesList[index]=this.InstanceValuesList[index].filter(c=>c.Id!=value.Id )
                        
                      }
                      this.onValueChange();
                    }

                });  
              

              }


              SelectFieldFromHint(ev: any[],value: ParameterInstance, selected:any[]=null )
              {




                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=value.Id;
                if(value.ValueType==0)
                {
                  checker.StringValue=value.StringValue;
                }

                if(value.ValueType==1)
                {
                  checker.NumberValue=value.NumberValue;
                }

                if(value.ValueType==2)
                {
                  checker.NumberValue=value.NumberValue;
                }

                if(value.ValueType==3)
                {
                  checker.BoolValue=value.BoolValue;
                }

                if(value.ValueType==4)
                {
                  
                  if(selected!=null && selected.length>0)
                  {
                    
                      if(selected[0].Id!=null)
                      {
                        checker.SelectedValueId=selected[0].Id;
                      }
                      else
                      {
                        checker.NewCreatedValue=selected[0].Name;
                      }
                      
                    
                    
                  }

                  if(selected==null)
                  {
                    
                      checker.SelectedValueId=null;
                    
                  }
                  
                  
                }

                if(value.ValueType==6)
                {
                  checker.DateValue=value.DateValue;
                }
                
                
                
                this.httpServiceParameters.parameterInstanceEditRecordFieldValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                      if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }
                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }
                  

                      if(data.Code==303)
                      {
                          alert("Параметр был удален");
                      }

                      if(data.Code==304)
                      {
                        alert("Поле является записью");
                      }
                      if(data.Code==305)
                      {
                        alert("Поле не найдено");
                      }
                      if(data.Code==306)
                      {
                        alert("Поле было удалено");
                      }
                      if(data.Code==307)
                      {
                        alert("Нельзя добавить значение в данный набор");
                      }

                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {


                      
                      if(ev!=null && ev.length>0)
                      {

                        let checks=[];
                        ev.forEach(e=>
                          {
                            let setId=e[0];
                            let valId=e[1];
                            let fieldId=this.elementFields.findIndex(c=>c.Type==0 && c.Instance.ValueType==4 && c.Instance.ValueSet.Id==setId);
                            if(fieldId>-1)
                            {
                              let field=this.elementFields[fieldId];



                              let checker1 = new ParameterInstanceEditAsker();
                            checker1.ChangerId = this.UserId;
                            checker1.SessionKey = this.SessionKey;
                            checker1.Id=field.Instance.Id;

                            
                            if(valId!=null)
                            {
                              checker1.SelectedValueId=valId.Id;
                            }
                            else
                            {
                              checker1.SelectedValueId=null;
                            }
                            checks.push(checker1);
                          }
                          })

                          let obs=of(checks);
                          let ind=0;
                          

                          from(checks).pipe(concatMap(m=>this.httpServiceParameters.parameterInstanceEditRecordFieldValue(m))).subscribe(res=>
                            {
                              if (data.Code == 100) {
                                this.unLogin(data.Message);
                                return;
                            }
                            
                            if(data.Code==300)
                            {
                                alert("клиент или проект не найден");
                                this.goToMain(null);
                            }
                            if(data.Code==301)
                            {
                                alert("клиент или проект был удален");
                                this.goToMain(null);
                            }
                            if(data.Code==302)
                            {
                                alert("нет доступа к клиенту или проекту");
                                this.goToMain(null);
                            }
                        
      
                            if(data.Code==303)
                            {
                                alert("Параметр был удален");
                            }
      
                            if(data.Code==304)
                            {
                              alert("Поле является записью");
                            }
                            if(data.Code==305)
                            {
                              alert("Поле не найдено");
                            }
                            if(data.Code==306)
                            {
                              alert("Поле было удалено");
                            }
                            if(data.Code==307)
                            {
                              alert("Нельзя добавить значение в данный набор");
                            }
                          
                          
                            if (data.Code == 500) { console.error(data.Message); return; }
                            
                            if(data.Code==0)
                            {


                              let setId=ev[ind][0];
                            let valId=ev[ind][1];
                            let fieldId=this.elementFields.findIndex(c=>c.Type==0 && c.Instance.ValueType==4 && c.Instance.ValueSet.Id==setId);
                              if(valId!=null)
                              {
                                this.elementFields[fieldId].Instance.SelectedValue=new Common();
                                this.elementFields[fieldId].Instance.SelectedValue.Id=valId.Id;
                                this.elementFields[fieldId].Instance.SelectedValue.Name=valId.Name;
                                this.InstanceValuesList[fieldId][0].selectedValue=[this.elementFields[fieldId].Instance.SelectedValue];
                                
                              }
                              else
                              {
                                this.elementFields[fieldId].Instance.SelectedValue=null;
                              this.InstanceValuesList[fieldId][0].selectedValue=null;
                              
                              }
                              ind++;

                              console.log('edit value change')
                              console.log(this.elementFields)
                              // this.onValueChange();
                            }
                            })

                      }
                       


                      this.onValueChange();
                    }

                });  

 
                
              }



              ChangeElementSize(size: number, param: number, lvl: number)
              {
               /* let container_outer=document.getElementById("recs_container");
                
                document.head.childNodes.forEach(ch=>{
                  
                  if(ch.nodeName=="STYLE")
                  {
                     
                    let sst=<HTMLStyleElement>ch;
                   if(sst.textContent.includes(".pi"+param+"__lv"+lvl+"{"))
                    { 
                      let mas=sst.textContent.split(" ");
                      let re = new RegExp(/^width:/, "gi");
                      mas.forEach(me=>{
                        if(me.match(re)!=null)
                        {
                          let wid=me.replace(re, '');
                          wid=wid.replace("px;","");
                          wid=wid.replace("}","");


                          
                          if(Number(wid)<size)
                          {
                           
                            let style=document.createElement('style');
                              let ns=size+10;
                              let css=".param_container_"+this.paramId+" .pi"+this.paramId+"__lv"+lvl+"{ max-width:"+container_outer.getBoundingClientRect().width+"px; width:"+ns+"px;";
                              
                             
                              style.innerHTML=css;
                              document.head.append(style);
                              ch.remove();
                              
                          }

                        }
                      })
                    }
                  
                  }
                }
                  )*/
              }




              ChangeValueSetSize(size: number, lvl: number)
              {
                setTimeout(() => {
                  let container_outer=document.getElementById("recs_container"+this.paramId);
                  /*let style=document.createElement('style');
                                
                  let css=".param_container_"+this.paramId+" .pi"+this.paramId+"__lv"+lev+"{ max-width:"+container_outer.getBoundingClientRect().width+"px; width:"+maxW+"px;}";
                  style.innerHTML=css;
                  document.head.append(style);*/

                  document.head.childNodes.forEach(ch=>{
                    
                    if(ch.nodeName=="STYLE")
                    {
                      
                      let sst=<HTMLStyleElement>ch;
                      //let str=sst.textContent.trim();
                      if(sst.textContent.includes(".pi"+this.paramId+"__lv"+lvl+"{"))
                      { 
                        let mas=sst.textContent.split(" ");
                        let re = new RegExp(/^width:/, "gi");
                        mas.forEach(me=>{
                          if(me.match(re)!=null)
                          {
                            let wid=me.replace(re, '');
                            wid=wid.replace("px;","");
                            wid=wid.replace("}","");
                            
                            if(Number(wid)<size)
                            {
                            
                              let style=document.createElement('style');
                                let ns=size+10;
                                let css=".param_container_"+this.paramId+" .pi"+this.paramId+"__lv"+lvl+"{ max-width:"+container_outer.getBoundingClientRect().width+"px; min-width: 140px; width:"+ns+"px;";

                                style.innerHTML=css;
                                document.head.append(style);
                                ch.remove();
                                
                            }

                          }
                        })
                      }
                    
                    }
                  }
                    )
                }, 500);
              }




              parseDate(dateString: string): Date {
                if (dateString) {
            
                  
                    return new Date(dateString);
                }
                return null;
              }
}

import { Component, OnInit, OnDestroy, NgModule, TemplateRef, AfterContentInit, ContentChild, EmbeddedViewRef, OnChanges, ViewContainerRef, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, AfterViewInit, Pipe, PipeTransform, Directive } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'c-item',
    template: ``
})

export class Item {

    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>
    constructor() {
    }

}

@Component({
    selector: 'c-badge',
    template: ``
})

export class Badge {

    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>
    constructor() {
    }

}

@Component({
    selector: 'c-search',
    template: ``
})

export class Search {

    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>
    constructor() {
    }

}

@Component({
    selector: 'c-templateRenderer',
    template: ``
})

export class TemplateRenderer implements OnInit, OnDestroy {

    @Input() data: any
    @Input() item: any
    view: EmbeddedViewRef<any>;

    constructor(public viewContainer: ViewContainerRef) {
    }
    ngOnInit() {
        this.view = this.viewContainer.createEmbeddedView(this.data.template, {
            '\$implicit': this.data,
            'item': this.item
        });
    }

    ngOnDestroy() {
        this.view.destroy();
    }

}

@Component({
    selector: 'c-icon',
    template: `<svg *ngIf="name == 'remove'" width="100%" height="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 47.971 47.971" style="enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
                        <g>
                            <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                                c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                                C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                                s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
                        </g>
                    </svg>
            <svg *ngIf="name == 'angle-down'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" height="100%" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
<g>
	<g id="_x31_0_34_">
		<g>
			<path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782
				c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296
				c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/>
		</g>
	</g>
</g>
</svg>
<svg *ngIf="name == 'angle-up'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" height="100%" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
<g>
	<g id="_x39__30_">
		<g>
			<path d="M604.501,440.509L325.398,134.956c-5.331-5.357-12.423-7.627-19.386-7.27c-6.989-0.357-14.056,1.913-19.387,7.27
				L7.499,440.509c-9.999,10.024-9.999,26.298,0,36.323s26.223,10.024,36.222,0l262.293-287.164L568.28,476.832
				c9.999,10.024,26.222,10.024,36.221,0C614.5,466.809,614.5,450.534,604.501,440.509z"/>
		</g>
	</g>
</g>

</svg>
<svg *ngIf="name == 'search'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" height="100%" viewBox="0 0 615.52 615.52" style="enable-background:new 0 0 615.52 615.52;"
	 xml:space="preserve">
<g>
	<g>
		<g id="Search__x28_and_thou_shall_find_x29_">
			<g>
				<path d="M602.531,549.736l-184.31-185.368c26.679-37.72,42.528-83.729,42.528-133.548C460.75,103.35,357.997,0,231.258,0
					C104.518,0,1.765,103.35,1.765,230.82c0,127.47,102.753,230.82,229.493,230.82c49.53,0,95.271-15.944,132.78-42.777
					l184.31,185.366c7.482,7.521,17.292,11.291,27.102,11.291c9.812,0,19.62-3.77,27.083-11.291
					C617.496,589.188,617.496,564.777,602.531,549.736z M355.9,319.763l-15.042,21.273L319.7,356.174
					c-26.083,18.658-56.667,28.526-88.442,28.526c-84.365,0-152.995-69.035-152.995-153.88c0-84.846,68.63-153.88,152.995-153.88
					s152.996,69.034,152.996,153.88C384.271,262.769,374.462,293.526,355.9,319.763z"/>
			</g>
		</g>
	</g>
</g>

</svg>
<svg *ngIf="name == 'clear'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 51.976 51.976" style="enable-background:new 0 0 51.976 51.976;" xml:space="preserve">
<g>
	<path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0
		C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778
		c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828
		c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828
		l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z"/>
</g>
</svg>`,
    encapsulation: ViewEncapsulation.None,

})

export class CIcon {

    @Input() name: any;

}






@Component({
    selector: 'c-checkbox',
    template: `<div class="checkbox-container">
        <div class="checkbox-background">
            <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                *ngIf="!checked">
                <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="#ABAAAA" />
            </svg>

            <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                *ngIf="checked">
                <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="#499259" />
                <path
                    d="M11.7802 2.82009C11.6618 2.73877 11.5283 2.68114 11.3872 2.6505C11.2461 2.61986 11.1002 2.6168 10.9579 2.6415C10.8157 2.66621 10.6797 2.71819 10.5579 2.79448C10.4361 2.87077 10.3308 2.96987 10.248 3.08612L6.06494 8.9504L3.32225 7.11129C3.10227 6.96358 2.83153 6.90771 2.56959 6.95595C2.30765 7.0042 2.07595 7.15261 1.92548 7.36855C1.77501 7.58449 1.71809 7.85026 1.76724 8.10739C1.81638 8.36453 1.96758 8.59197 2.18755 8.73968L5.86399 11.2047C5.97722 11.2806 6.10518 11.3327 6.23987 11.3578C6.37456 11.3829 6.51307 11.3805 6.64675 11.3506C6.78934 11.3262 6.9256 11.2743 7.04774 11.1981C7.16987 11.1218 7.27546 11.0226 7.35847 10.9062L12.0514 4.32505C12.2187 4.09005 12.284 3.79948 12.2331 3.51719C12.1823 3.2349 12.0195 2.98448 11.7802 2.82009Z"
                    fill="#499259" />
            </svg>
        </div>
    </div>`,
    styles: [`
    :host { display: inline-flex; }
    .checkbox-container {
        display: flex;
        justify-content: center;
    }
    .checkbox-background {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .wrapper:empty { display: none; }
    .wrapper:not(:empty) { margin - left: 4px; }
    }

`],
    encapsulation: ViewEncapsulation.None,
})

export class CCheckbox {

    @Input() name: any;

    @Input() checked: boolean;

    @Input() disabled: boolean;
}



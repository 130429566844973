/**
 * Created by emilg on 21.11.2016.
 */

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';

import { enableProdMode } from '@angular/core';
import { environment } from '../environments/environment';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";


/*Sentry.init({
  dsn: "https://f25e7e524cdd4c5499b85d7a8fd59064@o470663.ingest.sentry.io/5501531",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://test17-6.theiwish.ru/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});*/

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


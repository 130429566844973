import { ConfirmDoneIcon, CloseIcon, ErrorIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { FormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class SearchFieldComponent implements OnInit, AfterViewInit {

    @Input() value: string = '';
 
    @Input() placeholder: string="Поиск";

    @Input() searchBtnText: string="Поиск";
    @Input() clearBtnText: string="Очистить";

    @Input() autoSearch: boolean=true;
    @Input() searchBtn: boolean=true;
    @Input() clearBtn: boolean=true;

    @Input() focus: boolean=false;
    
    @Output()
    valueChange = new EventEmitter<string>();

   
    @Output()
    escKey = new EventEmitter<any>();

    @Output()
    enterKey = new EventEmitter<any>();

    filterInput = new FormControl()
   filterSub: Subscription = null;
   @ViewChild('serachField') serachFieldElem: ElementRef;

    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon,
            ErrorIcon
        ])
    }



    ngOnInit() {
        this.filterSub=this.filterInput.valueChanges.pipe(skip(1)).debounceTime(500)
                .subscribe(newValue => {this.valueChange.emit(this.value);});
    }

    ngAfterViewInit()
    {
        if(this.focus)
        {
            let el= this.serachFieldElem.nativeElement;
            el.focus();
        }
        
    }

    

    search()
    {
        this.valueChange.emit(this.value);
    }

    clear()
    {
        this.value="";
        this.valueChange.emit(this.value);
    }

    blurInput()
    {
        
    }

    checkButton(event: KeyboardEvent)
    {   
        
        if(event.keyCode==27)
        {
            this.clear();
        }
        if(event.keyCode==13)
        {
            this.search();
        }

    }


}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { SessionService } from '../../services/session.service';
import { selectUserId } from '../../store/selectors/session.selectors';
import { IAppState } from '../../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {
  constructor( private sessionService: SessionService) { }

  canActivate(): Observable<boolean> {
    

    return new Observable((observer)=>
    {
      let sub_check = new Subscription();
    let sub_user = new Subscription();
    sub_check=this.sessionService.isSessionChecked$.pipe(first(data=>data===true)).subscribe((data)=>{
      if(data===true)
      {
            sub_user=this.sessionService.userId$.pipe(take(1)).subscribe((id)=>{
              if (id>0)
              {
                
                observer.next(false)}
              else
              {

                
                observer.next(true);
              }
            });

      }




    });

    return {unsubscribe() {}};
     
      })



  }

}

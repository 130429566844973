/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import { LocalStorageService } from 'angular-2-local-storage';
import { ClientInProjectFilterWithUnion } from '../../models/model.ClientInProjectFilter';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientLight } from '../../models/model.client';
import { ProjectLight } from '../../models/model.project';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TagHelper } from '../../Helpers/taghelper';
import { ElementWithVisible } from '../../models/model.elementWithVisible';
import * as _ from 'lodash';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { AutoJobAuthorHolder, AutoJobExectutorHolder, AutoJobTemplateEditAsker, AutoJobTemplateFullContainer, AutoJobTemplateInSettings, MultiIdRequest } from '../../models/model.Autojob/model.AutojobTemplate';
import { LangSetAutojob, LangSetAutoJobGiver } from '../../models/model.autojobLanguage';
import { CommonRequest, CommonResponse } from '../../models/model.http';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { HttpServiceLanguage } from '../../httpserv/httpserv.language';
import { AutojobMultyselectContainer, AutojobMultyselectElement, AutojobMultyselectElementValue, AutojobMultyselectInProjectContainer, AutojobMultyselectInProjectElement, AutojobMultyselectInProjectElementValue, MultyselectContainer, MultyselectElement } from '../../models/model.MultyselectContainer';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { HttpServiceAutojob } from '../../httpserv/httpserv.autojob';
import { CommonWorkerHolder } from '../../models/model.workerAttached';
import { Common } from '../../models/model.common';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { Common_LangSet } from '../../models/model.language';


declare var require: any;


@Component({
  selector: 'autojobProjectMultiselect',
  templateUrl: 'temp.autojobProjectMultiselect.html',
  styleUrls: ['autojobProjectMultiselect.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',],

    providers: [HttpServiceLanguage, HttpServiceAutojob],

})
export class autojobProjectMultiselectComponent implements OnInit {
  
  //@Input() currentTemplate: AutoJobTemplateFullContainer;
  //@Input() currentMultiselect: MultyselectContainer;
  @Input() isMultiselectable: boolean=true;
  @Input() multiselectTitle: string="Выберите вариант";
  @Input() multiselectPlaceholder: string="Не выбран";
  @Input() isAuthor: boolean=false;
  @Input() isExecutor: boolean=false;
  @Input() isChangeable: boolean=true;
  @Input() checkInAdditional: boolean=false;
  
  @Output() ChangeSelected: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() ExecutorHolders: AutoJobExectutorHolder[]=[];
  @Input() AuthorHolders: AutoJobAuthorHolder[]=[];

  @Input() Refresh: Subject<boolean> = new Subject<boolean>();
  @Input() RefreshFull: Subject<boolean> = new Subject<boolean>();
  //@Input() Roles: Common[]=[];
 //@Input() AdditionalValues: AutojobMultyselectElementValue[]=[];
  //@Input() RolesOuterType: number=0;
  //@Input() UsersOuterType: number=1;


  //@Input() SelectedUsers: Common[]=[];
  //@Input() SelectedRoles: Common[]=[];
  
  //@Input() SelectedAdditionalValues: AutojobMultyselectElementValue[]=[];
  
 
  currentAnalyticMultiselect: AutojobMultyselectInProjectContainer;
  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();
  langsetAutojob: LangSetAutojob = new LangSetAutojob();
  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  UserId: number;
  SessionKey: string;
  entityTypeJob: number = 0;
  listVisible: boolean=false;
  isValid:boolean=true;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private store$: Store,
    private httpServiceLanguage: HttpServiceLanguage,
    private languageService: LanguageService,
    private sessionService: SessionService
  ) { }




  ngOnInit() {
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    //this.AutojobLanguageSetGet();
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);

    this.Refresh.subscribe(c => {
      console.log(this.ExecutorHolders)
      this.RefreshList();
    });

    this.RefreshFull.subscribe(c => {
      console.log(this.ExecutorHolders)
      this.RefreshListFull();
    });

    this.currentAnalyticMultiselect = new AutojobMultyselectInProjectContainer();
    this.currentAnalyticMultiselect.Filter="";
    this.currentAnalyticMultiselect.IsMultyselect = this.isMultiselectable;
    this.currentAnalyticMultiselect.Visible=true;
    this.currentAnalyticMultiselect.MultyselectElements=[];
    this.currentAnalyticMultiselect.SelectedElements=[];
    this.currentAnalyticMultiselect.CheckInAdditional=this.checkInAdditional;
    let indexelements=0;

    if(this.isAuthor)
    {



      if(this.AuthorHolders!=undefined && this.AuthorHolders!=null && this.AuthorHolders.length>0)
      {
        for(let i=0; i<this.AuthorHolders.length; i++)
        {
          let elem = new AutojobMultyselectInProjectElement();
          if(this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=true;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Author.Name;
            let val = new AutojobMultyselectInProjectElementValue();
            val.Id=this.AuthorHolders[i].Author.Id;
            val.Name=this.AuthorHolders[i].Author.Name;
            val.ValueOuterType=  this.AuthorHolders[i].Author.Type;
            if(this.AuthorHolders[i].Author.Type<3)
            {elem.ElementType=3;}
            if(this.AuthorHolders[i].Author.Type==3)
            {elem.ElementType=0;
            elem.TextToShow="#"+elem.TextToShow;;
            val.Name="#"+val.Name;}
            if(this.AuthorHolders[i].Author.Type==4)
            {elem.ElementType=1;}
            elem.Element=val;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=false;
            elem.Children=[];
            
            if(this.AuthorHolders[i].Author.Selected)
            {
              elem.Selected=true;
              this.currentAnalyticMultiselect.SelectedElements.push(elem);
            }
            else
            {
              
              if(this.AuthorHolders.length==1 && this.AuthorHolders[i].Author.Type==3)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.push(elem);
              }
            }
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
          }

          if(!this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=false;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Department.Name;
            elem.ElementType=2;
            let val = new AutojobMultyselectInProjectElementValue();
            elem.Element=null;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=true;
            elem.Children=[];
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            if(this.AuthorHolders[i].Department.Authors!=undefined && this.AuthorHolders[i].Department.Authors!=null  && this.AuthorHolders[i].Department.Authors.length>0)
            {
              for(let j=0; j<this.AuthorHolders[i].Department.Authors.length; j++)
              {
                let elemInner = new AutojobMultyselectInProjectElement();
                elemInner.Id=indexelements;
                indexelements++;
                elem.HasAccess=true;
                elem.Additionals=[];
                elemInner.IsSelectable=true;
                elemInner.Visible=true;
                elemInner.TextToShow = this.AuthorHolders[i].Department.Authors[j].Name;
                elemInner.ElementType=1;
                let val = new AutojobMultyselectInProjectElementValue();
                val.Id=this.AuthorHolders[i].Department.Authors[j].Id;
                val.Name=this.AuthorHolders[i].Department.Authors[j].Name;
                val.ValueOuterType= this.AuthorHolders[i].Department.Authors[j].Type;
                elemInner.Element=val;
                elemInner.Focus=false;
                elemInner.Selected=false;
                elemInner.IsHolder=false;
                elemInner.Children=[];
                
                if(this.AuthorHolders[i].Department.Authors[j].Selected)
                {
                  elemInner.Selected=true;
                  this.currentAnalyticMultiselect.SelectedElements.push(elemInner);
                }
                elem.Children.push(elemInner);
              }
            }
            
          }

          
        }
      }


    }
    else
    {
      if(this.isExecutor)
      {
        if(this.ExecutorHolders!=undefined && this.ExecutorHolders!=null && this.ExecutorHolders.length>0)
        {
          for(let i=0; i<this.ExecutorHolders.length; i++)
          {
            let elem = new AutojobMultyselectInProjectElement();
            if(this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=this.ExecutorHolders[i].Executor.HasAccess;
              elem.Additionals=[];
              if(this.ExecutorHolders[i].Executor.Workers!=null)
              {
                elem.Additionals=this.ExecutorHolders[i].Executor.Workers;
              }
              elem.IsSelectable=true;
              if(!this.ExecutorHolders[i].Executor.IsWorker && (this.ExecutorHolders[i].Executor.Workers==null || this.ExecutorHolders[i].Executor.Workers.length==0))
              {
                elem.IsSelectable=false;
              }
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {elem.ElementType=0;
                elem.TextToShow="#"+ elem.TextToShow;}
              else
              {elem.ElementType=1;
                }
              let val = new AutojobMultyselectInProjectElementValue();
              val.Id= this.ExecutorHolders[i].Executor.Id;
              val.Name= this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {val.ValueOuterType=1;
                val.Name="#"+val.Name;}
              else
              {val.ValueOuterType=2;}
              elem.Element=val;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=false;
              elem.Children=[];
              
              if(this.ExecutorHolders[i].Executor.Selected)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.push(elem);
              }
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            }
  
            if(!this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=true;
              elem.Additionals=[];
              elem.IsSelectable=false;
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Department.Name;
              elem.ElementType=2;
              let val = new AutojobMultyselectInProjectElementValue();
              elem.Element=null;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=true;
              elem.Children=[];
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
              if(this.ExecutorHolders[i].Department.Executors!=undefined && this.ExecutorHolders[i].Department.Executors!=null  && this.ExecutorHolders[i].Department.Executors.length>0)
              {
                for(let j=0; j<this.ExecutorHolders[i].Department.Executors.length; j++)
                {
                  let elemInner = new AutojobMultyselectInProjectElement();

                  elem.HasAccess=this.ExecutorHolders[i].Department.Executors[j].HasAccess;
                  elem.Additionals=[];
                  elemInner.Id=indexelements;
                  indexelements++;
                  elem.HasAccess=true;
                  elem.Additionals=[];
                  elemInner.IsSelectable=true;
                  elemInner.Visible=true;
                  elemInner.TextToShow = this.ExecutorHolders[i].Department.Executors[j].Name;
                  elemInner.ElementType=1;
                  let val = new AutojobMultyselectInProjectElementValue();
                  val.Id=this.ExecutorHolders[i].Department.Executors[j].Id;
                  val.Name=this.ExecutorHolders[i].Department.Executors[j].Name;
                  val.ValueOuterType= 2;
                  elemInner.Element=val;
                  elemInner.Focus=false;
                  elemInner.Selected=false;
                  elemInner.IsHolder=false;
                  elemInner.Children=[];
                  
                  if(this.ExecutorHolders[i].Department.Executors[j].Selected)
                  {
                    elemInner.Selected=true;
                    this.currentAnalyticMultiselect.SelectedElements.push(elemInner);
                  }
                  elem.Children.push(elemInner);
                }
              }
              
            }
  
            
          }
        }
  
      }
    }


    this.CheckSelectedValid();
    


    
  }

  close() {

  }


  RefreshList()
  {
    
    this.currentAnalyticMultiselect.MultyselectElements=[];
    let indexelements=0;
    if(this.isAuthor)
    {



      if(this.AuthorHolders!=undefined && this.AuthorHolders!=null && this.AuthorHolders.length>0)
      {
        for(let i=0; i<this.AuthorHolders.length; i++)
        {
          let elem = new AutojobMultyselectInProjectElement();
          if(this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=true;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Author.Name;
            let val = new AutojobMultyselectInProjectElementValue();
            val.Id=this.AuthorHolders[i].Author.Id;
            val.Name=this.AuthorHolders[i].Author.Name;
            val.ValueOuterType=  this.AuthorHolders[i].Author.Type;
            if(this.AuthorHolders[i].Author.Type<3)
            {elem.ElementType=3;}
            if(this.AuthorHolders[i].Author.Type==3)
            {elem.ElementType=0;
            elem.TextToShow="#"+elem.TextToShow;;
            val.Name="#"+val.Name;}
            if(this.AuthorHolders[i].Author.Type==4)
            {elem.ElementType=1;}
            elem.Element=val;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=false;
            elem.Children=[];
            
            let ind=this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Element.Id==elem.Element.Id && c.ElementType==elem.ElementType);
              if(ind>-1)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.splice(ind,1,elem);
              
              }
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
          }

          if(!this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=false;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Department.Name;
            elem.ElementType=2;
            let val = new AutojobMultyselectInProjectElementValue();
            elem.Element=null;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=true;
            elem.Children=[];
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            if(this.AuthorHolders[i].Department.Authors!=undefined && this.AuthorHolders[i].Department.Authors!=null  && this.AuthorHolders[i].Department.Authors.length>0)
            {
              for(let j=0; j<this.AuthorHolders[i].Department.Authors.length; j++)
              {
                let elemInner = new AutojobMultyselectInProjectElement();
                elemInner.Id=indexelements;
                indexelements++;
                elem.HasAccess=true;
                elem.Additionals=[];
                elemInner.IsSelectable=true;
                elemInner.Visible=true;
                elemInner.TextToShow = this.AuthorHolders[i].Department.Authors[j].Name;
                elemInner.ElementType=1;
                let val = new AutojobMultyselectInProjectElementValue();
                val.Id=this.AuthorHolders[i].Department.Authors[j].Id;
                val.Name=this.AuthorHolders[i].Department.Authors[j].Name;
                val.ValueOuterType= this.AuthorHolders[i].Department.Authors[j].Type;
                elemInner.Element=val;
                elemInner.Focus=false;
                elemInner.Selected=false;
                elemInner.IsHolder=false;
                elemInner.Children=[];
                
                let ind=this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Element.Id==elemInner.Element.Id && c.ElementType==elemInner.ElementType);
                if(ind>-1)
                {
                  elemInner.Selected=true;
                  this.currentAnalyticMultiselect.SelectedElements.splice(ind,1,elemInner);
                
                }
                elem.Children.push(elemInner);
              }
            }
            
          }

          
        }
      }


    }
    else
    {
      if(this.isExecutor)
      {
        if(this.ExecutorHolders!=undefined && this.ExecutorHolders!=null && this.ExecutorHolders.length>0)
        {
          for(let i=0; i<this.ExecutorHolders.length; i++)
          {
            let elem = new AutojobMultyselectInProjectElement();
            if(this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=this.ExecutorHolders[i].Executor.HasAccess;
              elem.Additionals=[];
              if(this.ExecutorHolders[i].Executor.Workers!=null)
              {
                elem.Additionals=this.ExecutorHolders[i].Executor.Workers;
              }
              elem.IsSelectable=true;
              if(!this.ExecutorHolders[i].Executor.IsWorker && (this.ExecutorHolders[i].Executor.Workers==null || this.ExecutorHolders[i].Executor.Workers.length==0))
              {
                elem.IsSelectable=false;
              }
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {elem.ElementType=0;
                elem.TextToShow="#"+ elem.TextToShow;}
              else
              {elem.ElementType=1;
                }
              let val = new AutojobMultyselectInProjectElementValue();
              val.Id= this.ExecutorHolders[i].Executor.Id;
              val.Name= this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {val.ValueOuterType=1;
                val.Name="#"+val.Name;}
              else
              {val.ValueOuterType=2;}
              elem.Element=val;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=false;
              elem.Children=[];
              
              let ind=this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Element.Id==elem.Element.Id && c.ElementType==elem.ElementType);
              if(ind>-1)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.splice(ind,1,elem);
              
              }
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            }
  
            if(!this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=true;
              elem.Additionals=[];
              elem.IsSelectable=false;
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Department.Name;
              elem.ElementType=2;
              let val = new AutojobMultyselectInProjectElementValue();
              elem.Element=null;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=true;
              elem.Children=[];
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
              if(this.ExecutorHolders[i].Department.Executors!=undefined && this.ExecutorHolders[i].Department.Executors!=null  && this.ExecutorHolders[i].Department.Executors.length>0)
              {
                for(let j=0; j<this.ExecutorHolders[i].Department.Executors.length; j++)
                {
                  let elemInner = new AutojobMultyselectInProjectElement();

                  elem.HasAccess=this.ExecutorHolders[i].Department.Executors[j].HasAccess;
                  elem.Additionals=[];
                  elemInner.Id=indexelements;
                  indexelements++;
                  elem.HasAccess=true;
                  elem.Additionals=[];
                  elemInner.IsSelectable=true;
                  elemInner.Visible=true;
                  elemInner.TextToShow = this.ExecutorHolders[i].Department.Executors[j].Name;
                  elemInner.ElementType=1;
                  let val = new AutojobMultyselectInProjectElementValue();
                  val.Id=this.ExecutorHolders[i].Department.Executors[j].Id;
                  val.Name=this.ExecutorHolders[i].Department.Executors[j].Name;
                  val.ValueOuterType= 2;
                  elemInner.Element=val;
                  elemInner.Focus=false;
                  elemInner.Selected=false;
                  elemInner.IsHolder=false;
                  elemInner.Children=[];
                  
                  let ind=this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Element.Id==elemInner.Element.Id && c.ElementType==elemInner.ElementType);
                  if(ind>-1)
                  {
                    elemInner.Selected=true;
                    this.currentAnalyticMultiselect.SelectedElements.splice(ind,1,elemInner);
                  
                  }
                  elem.Children.push(elemInner);
                }
              }
              
            }
  
            
          }
        }
  
      }
    }

    this.CheckSelectedValid();
  }


  RefreshListFull()
  {
    
    this.currentAnalyticMultiselect.MultyselectElements=[];
    let indexelements=0;
    if(this.isAuthor)
    {



      if(this.AuthorHolders!=undefined && this.AuthorHolders!=null && this.AuthorHolders.length>0)
      {
        for(let i=0; i<this.AuthorHolders.length; i++)
        {
          let elem = new AutojobMultyselectInProjectElement();
          if(this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=true;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Author.Name;
            let val = new AutojobMultyselectInProjectElementValue();
            val.Id=this.AuthorHolders[i].Author.Id;
            val.Name=this.AuthorHolders[i].Author.Name;
            val.ValueOuterType=  this.AuthorHolders[i].Author.Type;
            if(this.AuthorHolders[i].Author.Type<3)
            {elem.ElementType=3;}
            if(this.AuthorHolders[i].Author.Type==3)
            {elem.ElementType=0;
            elem.TextToShow="#"+elem.TextToShow;;
            val.Name="#"+val.Name;}
            if(this.AuthorHolders[i].Author.Type==4)
            {elem.ElementType=1;}
            elem.Element=val;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=false;
            elem.Children=[];
            
            if(this.AuthorHolders[i].Author.Selected)
            {
              elem.Selected=true;
              this.currentAnalyticMultiselect.SelectedElements.push(elem);
            }
            else
            {
              
              if(this.AuthorHolders.length==1 && this.AuthorHolders[i].Author.Type==3)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.push(elem);
              }
            }
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
          }

          if(!this.AuthorHolders[i].IsAuthor)
          {
            elem.HasAccess=true;
            elem.Additionals=[];
            elem.IsSelectable=false;
            elem.Id=indexelements;
            indexelements++;
            elem.Visible=true;
            elem.TextToShow = this.AuthorHolders[i].Department.Name;
            elem.ElementType=2;
            let val = new AutojobMultyselectInProjectElementValue();
            elem.Element=null;
            elem.Focus=false;
            elem.Selected=false;
            elem.IsHolder=true;
            elem.Children=[];
            this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            if(this.AuthorHolders[i].Department.Authors!=undefined && this.AuthorHolders[i].Department.Authors!=null  && this.AuthorHolders[i].Department.Authors.length>0)
            {
              for(let j=0; j<this.AuthorHolders[i].Department.Authors.length; j++)
              {
                let elemInner = new AutojobMultyselectInProjectElement();
                elemInner.Id=indexelements;
                indexelements++;
                elem.HasAccess=true;
                elem.Additionals=[];
                elemInner.IsSelectable=true;
                elemInner.Visible=true;
                elemInner.TextToShow = this.AuthorHolders[i].Department.Authors[j].Name;
                elemInner.ElementType=1;
                let val = new AutojobMultyselectInProjectElementValue();
                val.Id=this.AuthorHolders[i].Department.Authors[j].Id;
                val.Name=this.AuthorHolders[i].Department.Authors[j].Name;
                val.ValueOuterType= this.AuthorHolders[i].Department.Authors[j].Type;
                elemInner.Element=val;
                elemInner.Focus=false;
                elemInner.Selected=false;
                elemInner.IsHolder=false;
                elemInner.Children=[];
                
                if(this.AuthorHolders[i].Department.Authors[j].Selected)
                {
                  elemInner.Selected=true;
                  this.currentAnalyticMultiselect.SelectedElements.push(elemInner);
                }
                elem.Children.push(elemInner);
              }
            }
            
          }

          
        }
      }


    }
    else
    {
      if(this.isExecutor)
      {
        if(this.ExecutorHolders!=undefined && this.ExecutorHolders!=null && this.ExecutorHolders.length>0)
        {
          for(let i=0; i<this.ExecutorHolders.length; i++)
          {
            let elem = new AutojobMultyselectInProjectElement();
            if(this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=this.ExecutorHolders[i].Executor.HasAccess;
              elem.Additionals=[];
              if(this.ExecutorHolders[i].Executor.Workers!=null)
              {
                elem.Additionals=this.ExecutorHolders[i].Executor.Workers;
              }
              elem.IsSelectable=true;
              if(!this.ExecutorHolders[i].Executor.IsWorker && (this.ExecutorHolders[i].Executor.Workers==null || this.ExecutorHolders[i].Executor.Workers.length==0))
              {
                elem.IsSelectable=false;
              }
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {elem.ElementType=0;
                elem.TextToShow="#"+ elem.TextToShow;}
              else
              {elem.ElementType=1;
                }
              let val = new AutojobMultyselectInProjectElementValue();
              val.Id= this.ExecutorHolders[i].Executor.Id;
              val.Name= this.ExecutorHolders[i].Executor.Name;
              if(!this.ExecutorHolders[i].Executor.IsWorker)
              {val.ValueOuterType=1;
                val.Name="#"+val.Name;}
              else
              {val.ValueOuterType=2;}
              elem.Element=val;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=false;
              elem.Children=[];
              
              if(this.ExecutorHolders[i].Executor.Selected)
              {
                elem.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.push(elem);
              }
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
            }
  
            if(!this.ExecutorHolders[i].IsExecutor)
            {
              elem.HasAccess=true;
              elem.Additionals=[];
              elem.IsSelectable=false;
              elem.Id=indexelements;
              indexelements++;
              elem.Visible=true;
              elem.TextToShow = this.ExecutorHolders[i].Department.Name;
              elem.ElementType=2;
              let val = new AutojobMultyselectInProjectElementValue();
              elem.Element=null;
              elem.Focus=false;
              elem.Selected=false;
              elem.IsHolder=true;
              elem.Children=[];
              this.currentAnalyticMultiselect.MultyselectElements.push(elem);
              if(this.ExecutorHolders[i].Department.Executors!=undefined && this.ExecutorHolders[i].Department.Executors!=null  && this.ExecutorHolders[i].Department.Executors.length>0)
              {
                for(let j=0; j<this.ExecutorHolders[i].Department.Executors.length; j++)
                {
                  let elemInner = new AutojobMultyselectInProjectElement();

                  elem.HasAccess=this.ExecutorHolders[i].Department.Executors[j].HasAccess;
                  elem.Additionals=[];
                  elemInner.Id=indexelements;
                  indexelements++;
                  elem.HasAccess=true;
                  elem.Additionals=[];
                  elemInner.IsSelectable=true;
                  elemInner.Visible=true;
                  elemInner.TextToShow = this.ExecutorHolders[i].Department.Executors[j].Name;
                  elemInner.ElementType=1;
                  let val = new AutojobMultyselectInProjectElementValue();
                  val.Id=this.ExecutorHolders[i].Department.Executors[j].Id;
                  val.Name=this.ExecutorHolders[i].Department.Executors[j].Name;
                  val.ValueOuterType= 2;
                  elemInner.Element=val;
                  elemInner.Focus=false;
                  elemInner.Selected=false;
                  elemInner.IsHolder=false;
                  elemInner.Children=[];
                  
                  if(this.ExecutorHolders[i].Department.Executors[j].Selected)
                  {
                    elemInner.Selected=true;
                    this.currentAnalyticMultiselect.SelectedElements.push(elemInner);
                  }
                  elem.Children.push(elemInner);
                }
              }
              
            }
  
            
          }
        }
  
      }
    }

    this.CheckSelectedValid();
  }

  CheckSelectedValid(): boolean
  {
    let valid=true;
    let rolesCounter=0;
    let rolesInvalidCounter=0;
    if(this.currentAnalyticMultiselect.SelectedElements!=undefined && this.currentAnalyticMultiselect.SelectedElements!=null && this.currentAnalyticMultiselect.SelectedElements.length>0)
    {
      for(let i=0; i<this.currentAnalyticMultiselect.SelectedElements.length; i++)
      {
        if(!this.currentAnalyticMultiselect.SelectedElements[i].HasAccess)
        {
          valid=false;
        }
        if(this.currentAnalyticMultiselect.SelectedElements[i].ElementType==0)
        {
          rolesCounter++;
        }
        if(this.currentAnalyticMultiselect.SelectedElements[i].ElementType==0 && (this.currentAnalyticMultiselect.SelectedElements[i].Additionals==null ||this.currentAnalyticMultiselect.SelectedElements[i].Additionals.length==0))
        {
          rolesInvalidCounter++;
          
        }
      }
      if(rolesCounter==rolesInvalidCounter && rolesCounter>0)
      {
        valid=false;
      }
    }
    else
    {
      valid=false;
    }

    this.isValid=valid;
    this.currentAnalyticMultiselect.IsValid=valid;
    this.ChangeSelected.emit(this.currentAnalyticMultiselect);
    return valid;
    
  }


  ChangeListVisible()
  {
    if(this.listVisible)
      {
        this.listVisible=false;
      }
      else
      {
       // this.currentAnalyticMultiselect.ShowAllElements();
        //this.currentAnalyticMultiselect.Filter="";
        this.listVisible=true;
      }
  }


  ChangeElementSelected(element: AutojobMultyselectInProjectElement)
  {
    if(!element.Selected && (!element.IsSelectable || !element.HasAccess || (element.ElementType==0 && ( element.Additionals==null || element.Additionals.length==0))))
    {
      //пытаемся выбрать то что нельзя выбрать
      return;
    }
    else
    {
      if(element.Selected && (!element.IsSelectable || !element.HasAccess || (element.ElementType==0 && ( element.Additionals==null || element.Additionals.length==0))))
      {
        //снять выделение
        //пытаемся снять выбор с того что нельзя выбрать
        if(this.currentAnalyticMultiselect.IsMultyselect)
        {
          if(this.currentAnalyticMultiselect.SelectedElements.length==1)
          {
            //единственный выбранный вариант в мультиселекте, нельзя оставлять мультиселект пустым
            console.log("Нельзя снять выделение с единственного выбранного варианта")
          }
          else
          {
            if(this.currentAnalyticMultiselect.SelectedElements.length>1)
            {
              //песть еще выбранные варианты, разрешаем снять выделение
              element.Selected=false;
              let ind = this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
              if(ind>-1)
              {
                this.currentAnalyticMultiselect.SelectedElements.splice(ind, 1);
              }
            }
          }
          
        }
        else
        {
          console.log("Нельзя снять выделение с единственного выбранного варианта")
        }
      }
      else
      {
        if(element.Selected && element.IsSelectable && element.HasAccess )
        {
          //снять выделение
          //пытаемся снять выбор с варианта
          if(this.currentAnalyticMultiselect.IsMultyselect)
          {
            if(this.currentAnalyticMultiselect.SelectedElements.length==1)
            {
              //единственный выбранный вариант в мультиселекте, нельзя оставлять мультиселект пустым
              console.log("Нельзя снять выделение с единственного выбранного варианта")
            }
            else
            {
              if(this.currentAnalyticMultiselect.SelectedElements.length>1)
              {
                //песть еще выбранные варианты, разрешаем снять выделение
                element.Selected=false;
               // console.log(element)
                //console.log(this.currentAnalyticMultiselect.SelectedElements)
                let ind = this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
                if(ind>-1)
                {
                  this.currentAnalyticMultiselect.SelectedElements.splice(ind, 1);
                }
              }
            }
            
          }
          else
          {
            console.log("Нельзя снять выделение с единственного выбранного варианта")
          }
        }
        else
        {
          if(!element.Selected && element.IsSelectable && element.HasAccess &&(element.ElementType!=0 || (element.ElementType==0 && ( element.Additionals!=null && element.Additionals.length>0))))
          {
            
            if(this.currentAnalyticMultiselect.IsMultyselect)
            {
    
              
              if(!this.currentAnalyticMultiselect.CheckInAdditional)
              {
                this.currentAnalyticMultiselect.SelectElement(element);
              }
              else
              {
                
                if(element.ElementType==0)
                {
                  
                  for(let i=0; i<element.Additionals.length; i++)
                  {
                    let ind= this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Element.Id==element.Additionals[i].Id && c.ElementType==1)
                    if(ind>-1)
                    {
                      this.currentAnalyticMultiselect.SelectedElements[ind].Selected=false;
                      this.currentAnalyticMultiselect.SelectedElements.splice(ind,1);
    
                    }
                  }
                  this.currentAnalyticMultiselect.SelectElement(element);
                }
                if(element.ElementType==1)
                {
                  
                  let rolesForDel=[];
    
    
                  for(let i=0; i<this.currentAnalyticMultiselect.SelectedElements.length; i++)
                  {
                    
                    if(this.currentAnalyticMultiselect.SelectedElements[i].ElementType==0)
                    {
                      
                      
    
                      let ind= this.currentAnalyticMultiselect.SelectedElements[i].Additionals.findIndex(c=>c.Id==element.Element.Id)
                      if(ind>-1)
                      {
                        
                        rolesForDel.push(i);
    
                      }
                    }
                  }
                  
                  if(rolesForDel.length>0)
                  {
                    for(let i=0; i<rolesForDel.length; i++)
                    {
                      this.currentAnalyticMultiselect.SelectedElements[rolesForDel[i]].Selected=false;
                      this.currentAnalyticMultiselect.SelectedElements.splice(rolesForDel[i],1);
                      for(let j=0; j<rolesForDel.length; j++)
                      {
                        rolesForDel[j]=rolesForDel[j]-1;
                      }
                    }
                    
                  }
                  this.currentAnalyticMultiselect.SelectElement(element);
                }
                if(element.ElementType==3)
                {
                  this.currentAnalyticMultiselect.SelectElement(element);
                }
              }
            }
            else
            {
              this.currentAnalyticMultiselect.UnselectAll();
              this.currentAnalyticMultiselect.SelectElement(element);
            }
          }
        }
  
        
      }
      
    }

    

    this.CheckSelectedValid();
    
    
  }

  DeleteElement(element: AutojobMultyselectInProjectElement)
  {
    if(!element.Selected && (!element.IsSelectable || !element.HasAccess || (element.ElementType==0 && ( element.Additionals==null || element.Additionals.length==0))))
    {
      //пытаемся выбрать то что нельзя выбрать
      return;
    }
    else
    {
      if(element.Selected && (!element.IsSelectable || !element.HasAccess || (element.ElementType==0 && ( element.Additionals==null || element.Additionals.length==0))))
      {
        //снять выделение
        //пытаемся снять выбор с того что нельзя выбрать
        if(this.currentAnalyticMultiselect.IsMultyselect)
        {
          if(this.currentAnalyticMultiselect.SelectedElements.length==1)
          {
            //единственный выбранный вариант в мультиселекте, нельзя оставлять мультиселект пустым
            console.log("Нельзя снять выделение с единственного выбранного варианта")
          }
          else
          {
            if(this.currentAnalyticMultiselect.SelectedElements.length>1)
            {
              //песть еще выбранные варианты, разрешаем снять выделение
              element.Selected=false;
              let ind = this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
              if(ind>-1)
              {
                this.currentAnalyticMultiselect.SelectedElements.splice(ind, 1);
              }
            }
          }
          
        }
        else
        {
          console.log("Нельзя снять выделение с единственного выбранного варианта")
        }
      }
      else
      {
        if(element.Selected && element.IsSelectable && element.HasAccess )
        {
          //снять выделение
          //пытаемся снять выбор с варианта
          if(this.currentAnalyticMultiselect.IsMultyselect)
          {
            if(this.currentAnalyticMultiselect.SelectedElements.length==1)
            {
              //единственный выбранный вариант в мультиселекте, нельзя оставлять мультиселект пустым
              console.log("Нельзя снять выделение с единственного выбранного варианта")
            }
            else
            {
              if(this.currentAnalyticMultiselect.SelectedElements.length>1)
              {
                //песть еще выбранные варианты, разрешаем снять выделение
                element.Selected=false;
               // console.log(element)
                //console.log(this.currentAnalyticMultiselect.SelectedElements)
                let ind = this.currentAnalyticMultiselect.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
                if(ind>-1)
                {
                  this.currentAnalyticMultiselect.SelectedElements.splice(ind, 1);
                }
              }
            }
            
          }
          else
          {
            console.log("Нельзя снять выделение с единственного выбранного варианта")
          }
        }
        
  
        
      }
      
    }

    

    this.CheckSelectedValid();
    
    
  }

  
}

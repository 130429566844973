import { Deleting } from "./model.deleting";
import { SingleIdRequest, CommonResponse, CommonRequest } from "./model.http";
import { Common, CommonSelectable } from "./model.common";
import { ExtendedHelper } from "../config/ExtendedHelper";
import { WorkerProfile } from "./model.worker";
import * as _ from 'lodash';
export class ChatCreateAsker extends CommonRequest {
  //Обязательные параметры.
  Workers: number[];//важно. Если содержит более 1 элемента, но название не указано, вернется ошибка.
  IsPersonal: boolean;
  //параметры только для конференции
  Name: string;
  PhotoLink: string;

  public static CreateAfterPhotoClick(userId: number, sessionKey: string, workerId: number): ChatCreateAsker {
    let c = new ChatCreateAsker();
    c.ChangerId = userId;
    c.SessionKey = sessionKey;
    c.IsPersonal = true;
    c.Workers = [];
    if (userId != workerId) {
      c.Workers.push(workerId);
    }
    return c;
  }

  public static CreateFromContainer(userId: number, sessionKey: string, container: ChatCreateContainer): ChatCreateAsker {
    let c = new ChatCreateAsker();
    c.ChangerId = userId;
    c.SessionKey = sessionKey;
    c.IsPersonal = !container.IsNotPersonal;
    c.Name = ExtendedHelper.ReplaceBadThings(container.Name);
    c.PhotoLink = container.PhotoLink;
    c.Workers = [];
    for (let i = 0; i < container.HoldersAllList.length; i++) {
      let e = container.HoldersAllList[i];
      if (e.IsWorker) {
        if (e.Worker.Selected && !e.Worker.AlwaysSelected) c.Workers.push(e.Worker.Id);
      } else {
        if (e.Department.Workers.length > 0) {
          e.Department.Workers.forEach(inner => {
            if (inner.Selected && !inner.AlwaysSelected) c.Workers.push(inner.Id);
          });
        }
      }
    }
    return c;
  }
}

export class ChatAccessGiver extends CommonResponse {
  Allow: boolean;
}

export class ChatCreateContainer {
  Workers: WorkerWithPhoto[];
  WorkerAllList: WorkerWithPhoto[];
  HoldersAllList: WorkerForChatHolder[];
  Holders: WorkerForChatHolder[];
  Filter: string = "";
  IsNotPersonal: boolean;
  Name: string;
  PhotoLink: string;
  IsAddingGlobalAnyway: boolean = false;
}


export class ChatEditAsker extends SingleIdRequest {
  AddingWorkers: number[];
  DeletingWorkers: number[];
  Name: string;
  PhotoLink: string;
}

export class ChatAfterEditGiver extends CommonResponse {
  ChatName: string;
}

export class ChatFromPersonalToGlobalAsker extends SingleIdRequest {
  Workers: number[];
  Name: string;
  PhotoLink: string;

  public static CreateFromContainer(userId: number, sessionKey: string, chat: ChatOpened): ChatFromPersonalToGlobalAsker {
    let container = chat.Container;
    let c = new ChatFromPersonalToGlobalAsker();
    c.Id = chat.Id;
    c.ChangerId = userId;
    c.SessionKey = sessionKey;
    c.Name = container.Name;
    c.PhotoLink = container.PhotoLink;
    c.Workers = [];
    for (let i = 0; i < container.WorkerAllList.length; i++) {
      let e = container.WorkerAllList[i];
      if (e.Selected && !e.AlwaysSelected)
        c.Workers.splice(0, 0, e.Id);
    }

    return c;
  }
}

export class ChatFromPersonalToGlobalContainer {
  Workers: WorkerWithPhoto[];
  Name: string;
  PhotoLink: string;
  WorkerAllList: WorkerWithPhoto[];
  Filter: string = "";

}

export class ChatWorkersGiver extends CommonResponse {
  WorkerList: WorkerWithPhoto[];
  Holders: WorkerForChatHolder[];
}

export class ChatWorkersGiverNew extends  CommonResponse
        {
          Holders: WorkerForChatHolder[];
        }


export class WorkerForChatHolder {
  IsWorker: boolean;
  Worker: WorkerWithPhoto;
  Position: number;
  Department: DepartmentForChat;
}

class DepartmentForChat extends Common {
  Workers: WorkerWithPhoto[];
}


export class ChatListAsker extends CommonRequest {
  Mode: number;
  Date: Date;
}
//  for Mode
// {
//   Normal = 0,
//   Prev,
//   New
// }


export class ChatListGiver extends CommonResponse {
  ChatList: ChatInList[];
  //PhotoLiter: string;
}

export class ChatInList extends Common {
  IsPersonal: boolean;
  PhotoLink: string;
  PhotoLiter: string;

  ShowSmallPhoto: boolean;
  SmallPhotoLink: string;
  SmallPhotoLiter: string;

  Message: string;
  MessageDate: Date;

  Unreaded: boolean;
  IsSystem: boolean;
  CrossId: number;
  LastMessageCreator: Common;
  UnreadCount: number=0;
}





export class ChatSearchAskerOld extends CommonRequest {
  Value: string;
  Filter: string;
  SearchingAdditional: boolean;
  SearchBefore: Date;
}


export class ChatSearchAsker extends CommonRequest {
  Value: string;
  Mode: number;
}

 enum ChatSearchMode
        {
            Chats = 0,
            Messages = 1
        }

export class ChatSearchContainer {
  Chats: ChatSearched[];
  Messages: MessageSearched[];
  CurrentFilter: string;

  public static CreateFromGiver(giver: ChatSearchGiver, filter: string): ChatSearchContainer {
    let c = new ChatSearchContainer();
    c.Chats = giver.Chats;
    c.Messages = giver.Messages;
    c.CurrentFilter = filter;
    return c;
  }
  public static AddMessagesFromGiver(giver: ChatSearchGiver): ChatSearchContainer {
    let c = new ChatSearchContainer();
    c.Messages = giver.Messages;
    return c;
  }
}

export class ChatSearchGiver extends CommonResponse {
  Chats: ChatSearched[];
  Messages: MessageSearched[];
}

export class ChatSearched extends Common {
  PhotoLink: string;
  PhotoLiter: string;
  IsPotential: boolean;
}

export class MessageSearched extends Common {
  ChatId: number;
  PhotoLink: string;
  PhotoLiter: string;

  ShowSmallPhoto: boolean;
  SmallPhotoLink: string;
  SmallPhotoLiter: string;
  Message: string;
  MessageDate: Date;
  Unreaded: boolean;
}

export class ChatSettingsGiver extends CommonResponse {
  Name: string;
  PhotoLink: string;
  ExistingWorkers: WorkerWithPhoto[];
  PotentialWorkers: WorkerWithPhoto[];
  PotentialWorkerHolders: WorkerForChatHolder[];
  IsAdmin: boolean;
}

export class ChatGiver extends CommonResponse {
  Chat: ChatOpenedSmall;
}


export class ChatOpenedSmall extends Common {
  
  CanWrite: boolean;
  CanEdit: boolean;
  IsPersonal: boolean;
  Messages: MessageInOpenedChat[];
  Updated: Date;
  CanLoadNext: boolean;
  UnreadedCount: number;
  WorkerId: number;
  IsSystem: boolean;//будет иметь значение 0, если чат - конференция. и более, если чат - личка.
  //PhotoLiter: string;
}

export class ChatSettings {
  Name: string;
  PhotoLink: string;
  ExistingWorkers: WorkerWithPhoto[];
  PotentialWorkers: WorkerWithPhoto[];
  PotentialWorkersHolders: WorkerForChatHolder[];
  IsAdmin: boolean;
  WorkerAllList: WorkerWithPhoto[];
  HoldersAllList: WorkerForChatHolder[];
  Filter: string = "";

  public static CreateFromGiver(settings: ChatSettingsGiver): ChatSettings {
    let c = new ChatSettings();
    c.Name = settings.Name;
    c.PhotoLink = settings.PhotoLink;
    c.ExistingWorkers = settings.ExistingWorkers;
    c.WorkerAllList = settings.PotentialWorkers;
    c.PotentialWorkers = c.WorkerAllList;
    // c.PotentialWorkersHolders = settings.PotentialWorkerHolders;
    //c.WorkerAllList = settings.PotentialWorkers;
    c.IsAdmin = settings.IsAdmin;

    return c;
  }


}

export class ChatOpened {
  Id: number;
  Name: string;
  CanWrite: boolean;
  CanEdit: boolean;
  Messages: MessageInOpenedChat[];
  Filter: string;
  SavedFilter: string;
  IsPersonal: boolean;
  Settings: ChatSettings;
  SearchingStage: number;//0,1,2
  GotNewMessage: boolean;
  ScrollPosition: number;
  IsSettingsOpened: boolean;
  TextInput: string;
  CanLoadPrev: boolean;
  CanLoadNext: boolean;
  UpdatedLast: Date;
  IsReloading: boolean;
  IsLoadingUpper: boolean;
  IsLoadingDowner: boolean;
  IsUpdating: boolean;
  Container: ChatFromPersonalToGlobalContainer;
  IsSavingSettings: boolean;
  UnreadedMessagesCount: number;

  IsSendingMessage: boolean;

  WorkerId: number;//будет иметь значение 0, если чат - конференция. и более, если чат - личка.
  IsSystem: boolean;


  loadingMessages: number[] = [];

  addMessageWithCID(text: string, worker: WorkerProfile) {
    let newMes = new MessageInOpenedChat();
    newMes.Text = ExtendedHelper.ReplaceBadThings(text);
    newMes.Id = (Math.floor(Math.random() * 100) * (-1));
    newMes.WorkerName = worker.Name;
    newMes.WorkerId = worker.Id;
    newMes.PhotoLink = worker.PhotoLink;
    newMes.PhotoLiter = worker.PhotoLiter;
    newMes.Date = new Date();
    newMes.Unreaded = true;
    newMes.IsSystem = false;
    newMes.IsLoading = true;
    newMes.AttachedMessages = []
    newMes.Links = [];

    this.Messages.push(newMes)
    this.loadingMessages.push(newMes.Id);
  }


  replaceMessagesLinks() {
    let regexp = new RegExp(/<a [^>]*>|<\/a>/gi);
    if (this.Messages.length) {
      this.Messages.forEach(mes => {
        if (!mes.IsSystem) {
          mes.Text = mes.Text.replace(regexp, '')
          let linkSeparator = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, "gim");

          mes.Text = mes.Text.replace(linkSeparator, (value) => {
            let link = (value.match(new RegExp(`^(http|https)://`))) ? value : ('https://' + value);
            return `<a class="massage-link-${mes.Id}" target="_blank" href="${link}" (click)="$event.stopPropagation();">${value}</a>`
          })
          setTimeout(() => {
            let array = document.getElementsByClassName(`massage-link-${mes.Id}`);
            [].forEach.call(array, (el) => {
              el.addEventListener('click', (event) => event.stopPropagation())
            });
          }, 300)
        } else {
          this.HandleLinks(mes);
        }
      })
    }
  }

  HandleLinks(message: MessageInOpenedChat, type: number = 0) {
    if (type < 2) {
      if (message.Links != null && message.Links.length > 0) {
        for (let j = 0; j < message.Links.length; j++) {
          let link = "";
          if (message.Links[j].Type == 0) {
            link = '<a href="/client/' + message.Links[j].Id + '">' + message.Links[j].Name + '</a>';
          }
          if (message.Links[j].Type == 1) {
            link = '<a href="/project/' + message.Links[j].Id + '">' + message.Links[j].Name + '</a>';
          }
          message.Text = message.Text.replace("{{L_i-n*k$:" + j + "*", link);
        }
      }

      if (type == 0 && (message.AttachedMessages != null && message.AttachedMessages.length > 0)) {
        for (let k = 0; k < message.AttachedMessages.length; k++) {

          if (message.AttachedMessages[k].Links != null && message.AttachedMessages[k].Links.length > 0) {
            for (let j = 0; j < message.AttachedMessages[k].Links.length; j++) {
              let link = "";
              if (message.AttachedMessages[k].Links[j].Type == 0) {
                link = '<a href="/client/' + message.AttachedMessages[k].Links[j].Id + '">' + message.AttachedMessages[k].Links[j].Name + '</a>';
              }
              if (message.Links[j].Type == 1) {
                link = '<a href="/project/' + message.AttachedMessages[k].Links[j].Id + '">' + message.AttachedMessages[k].Links[j].Name + '</a>';
              }
              message.AttachedMessages[k].Text = message.AttachedMessages[k].Text.replace("{{L_i-n*k$:" + j + "*", link);
            }
          }
        }
      }
    }
    else if (type == 2) {
    }
  }

  removeEventListenerFromLinks() {
    this.Messages.forEach(mes => {
      let array = document.getElementsByClassName(`massage-link-${mes.Id}`);
      [].forEach.call(array, (el) => {
        el.removeEventListener('click', (event) => event.stopPropagation())
      });
    });
  }

  refreshEventListenerFromLinks() {
    this.removeEventListenerFromLinks();
    this.replaceMessagesLinks();
  }

  removeAfterSend(messages: MessageInOpenedChat[]) {
    // this.Messages = this.Messages.filter(mainMes => messages.find(sended => (sended.Text === mainMes.Text && mainMes.Id < 0)) == undefined)
    // let count = 0;
    // this.Messages = this.Messages.filter(mes => {
    //   if (this.loadingMessages.find(m => m == mes.Id) != undefined) count++;
    //   return (this.loadingMessages.find(m => m == mes.Id) == undefined) && (count < messages.length)
    // })
    // messages.forEach(() => this.loadingMessages.shift())
    messages.forEach((el, index) => {
      this.Messages = this.Messages.filter(mes => mes.Id != this.loadingMessages[0])
      this.loadingMessages.shift();
    })
    this.Messages = this.Messages
      .filter(el => el.Id > 0)
      .concat(this.Messages.filter(er => er.Id < 0))

    // console.log(this.Messages.filter(el => el.Id > 0))
    // console.log(this.Messages)
  }

  removeAfterSendById(id: number) {
    this.Messages = this.Messages.filter(mainMes => mainMes.Id != id)
  }

  public static CreateFromSmallChat(id: number, chat: ChatOpenedSmall, searchingStage: number): ChatOpened {
    let newChat = new ChatOpened();
    newChat.Id = id;
    newChat.WorkerId = chat.WorkerId;
    newChat.Name = chat.Name;
    newChat.CanEdit = chat.CanEdit;
    newChat.CanWrite = chat.CanWrite;
    newChat.Messages = chat.Messages;
    newChat.Filter = "";
    newChat.IsPersonal = chat.IsPersonal;
    newChat.SearchingStage = searchingStage;
    newChat.GotNewMessage = false;
    newChat.ScrollPosition = 0;
    newChat.IsSettingsOpened = false;
    newChat.TextInput = "";
    newChat.CanLoadNext = chat.CanLoadNext;
    newChat.CanLoadPrev = true;
    newChat.UpdatedLast = chat.Updated;
    newChat.IsReloading = false;
    newChat.IsUpdating = false;
    newChat.IsLoadingUpper = false;
    newChat.IsLoadingDowner = false;
    newChat.IsSavingSettings = false;
    newChat.UnreadedMessagesCount = chat.UnreadedCount;
    newChat.IsSendingMessage = false;
    newChat.IsSystem = chat.IsSystem;
    return newChat;
  }
}



export class MessageInOpenedChat {
  Id: number;
  WorkerName: string;
  WorkerId: number;
  PhotoLink: string;
  PhotoLiter: string;
  Text: string;
  Date: Date;
  Unreaded: boolean;
  IsSystem: boolean;
  AttachedMessages: AttachedMessage[] = [];
  Links: LinkInMessage[];
  CrossId: number;

  IsLoading?: boolean = false;
  Selected?: boolean = false;
}


export class AttachedMessage {
  Id: number;//id сообщенияж
  Text: string;
  Date: Date;
  Sender: Common;//имя автора сообщения;
  Level: number; //уровень вложенности;
  AttachedMessagesCount: number;//количество вложенных сообщений
  Links: LinkInMessage[];


  public CreateFromMessage(message: MessageInOpenedChat)
  {
    this.Id=message.Id;
    this.Text=message.Text;
    this.Text=this.Text.replace('<span class="searchedText">',"");
    this.Text=this.Text.replace('</span>',"");
    this.Date=message.Date;
    this.Sender=new Common(message.WorkerId, message.WorkerName);//имя автора сообщения;
    this.Level=1; //уровень вложенности;
    this.AttachedMessagesCount=message.AttachedMessages.length;//количество вложенных сообщений
    this.Links=_.cloneDeep(message.Links);

  }
  
}

export class MessagesAroundOneAsker extends CommonRequest {
  ChatId: number;
  MessageId: number;
}

export class MessagesGiver extends CommonResponse {
  Messages: MessageInOpenedChat[];
  CanLoadNext: boolean;
  UnreadedCount: number;
  Updated: Date;
}
export class MessagesGiverExtended extends MessagesGiver {

  CanEdit: boolean;
  CanWrite: boolean;
}


export class MessageSearchAsker extends SingleIdRequest {
  Filter: string;
  Mode: number;
  Date: Date;
}

// public enum MessageLoadMode
// {
//   Normal = 0,
//   Prev,
//   Next
// }

export class MessageRefreshAsker extends SingleIdRequest {
  Filter: string;
  Date: Date;
  CanLoadNext: boolean;
}

export class ChatSingleAsker extends SingleIdRequest {
  MessageId: number;
}

export class MessageAddAsker extends CommonRequest {
  ChatId: number;
  Text: string;
  AttachedMessageIds: number[];
}

export class WorkerWithPhoto extends CommonSelectable {
  PhotoLink: string;
  PhotoLiter: string;
  AlwaysSelected: boolean;
}



export class AttachedMessagesGiver extends CommonResponse {
  Messages: AttachedMessage[];
}

export class ChatInReplyList extends Common {
  PhotoLink: string;
  PhotoLiter: string;
  IsPotential: boolean;
}

export class ChatReplyListGiver extends CommonResponse {
  ChatList: ChatInReplyList[];
}



export class LinkInMessage extends Common {
  Type: number;
}

export class LinkType {
  Client: number = 0;
  Project: number = 1;
}


export class LongListGiver extends CommonResponse {
  List: number[];
}



export class ChatLanguageSet {
  Language: number;
  ExitAsk: string = ""; //Вы действительно хотите покинуть чат? Вы сможете вернуться в любой момент.
  DeleteAsk: string = ""; //Вы действительно хотите удалить историю чата? Эту операцию невозможно отменить.
  ChatAdd: string = ""; //Добавить чат
  ChatSearchAll: string = ""; //Поиск чатов
  ChatNoOne: string = ""; //У вас еще нет чатов
  ChatHistoryDelete: string = ""; //Удалить историю чата
  ChatNotFound: string = ""; //Ничего не найдено по вашему запросу
  ChatsFoundHeader: string = ""; //Чаты:
  MessagesFoundHeader: string = ""; //Сообщения:
  ChatNewAdding: string = ""; //Создание нового чата
  ChatGlobalCheck: string = ""; //Создать конференцию
  ChatGlobalName: string = ""; //Название конференции
  ChangePhoto: string = ""; //Сменить фото
  DeletePhoto: string = ""; //Удалить фото
  PossibleWorkers: string = ""; //Список возможных пользователей:
  WorkerFilter: string = ""; //Фильтрация пользователей
  WorkerNoOne: string = ""; // Нет пользователей для добавления в чат
  WorkerNotFound: string = ""; //Ничего не найдено по вашему запросу
  ChatSearchIn: string = ""; //Поиск бесед
  BackToSearching: string = ""; //Назад к поиску
  StartNewSearch: string = ""; //Начать новый поиск
  GetOutFromChat: string = ""; //Выйти из чата
  ToFreshMessages: string = ""; //К свежим сообщениям
  NoMessagesInChat: string = ""; //    В чате еще нет сообщений
  NoMessagesFiltered: string = ""; //Не найдено сообщений по введенному фильтру
  AllMessagesLoaded: string = ""; //Все сообщения загружены
  ChatingForbidden: string = ""; //Вам запрещено писать в этом чате
  ChatTransforming: string = ""; //Создание конференции на основе личного чата
  ChatEditing: string = ""; //Редактирование конференции
  ExistingWorkers: string = ""; //Список пользователей чата:
  NoAccess: string = ""; //Нет доступа в чат у всех групп(ред)

  ChatHistoryLoading: string = "";//История загружается
  ChatHistoryAllLoaded: string = "";//Вся история загружена

  ChatSearchAdditionalLoading: string = "";//Дополнительные сообщения по поисковому фильтру загружаются
  ChatSearchAdditionalAllLoaded: string = "";//Все содержимое по указанному фильтру загружено

  MessageHistoryLoading: string = "";//    История загружается
  ChatName: string = "";//Название - Name
  ChatNewShort: string = "";//Новая - New
  ChatNew: string = "";//Новая беседа - New conversation
  GoToChat: string = "";//Перейти к беседе - Go to the conversation
  ForwardMessage: string = "";//
  ReplyMessage: string = "";//
  ForwardedMessages: string = "";//
  MessageCountMany: string = "";//
  MessageCountSome: string = "";//
  MessageCountOne: string = "";//
  ChooseConversation: string = "";
  ChatingForbiddenSystem: string = "";
}

export class ChatLanguageSetGiver extends CommonResponse {
  Set: ChatLanguageSet;
}


export class GlobalChatAddAsker extends CommonRequest
           {
               Name: string;//название чата
               Photolink: string;//ссылка на фото
               Workers: number[];//пользователи чата
           }



           export class ChatConvertToGlobalAsker extends GlobalChatAddAsker
           {
               ChatId: number;
           }


        export class ChatRefreshAsker extends CommonRequest
        {
             UpdateAfter:  Date;//дата обновления самого свеже-обновленного чата.
        }


        export class AdditionalMessagesAsker extends SingleIdRequest
        {
            MessageDate: Date;
        }


        export class ChatMessagesSearchAsker extends SingleIdRequest
        {
           Filter: string;
        }



        export class ChatNotificator
        {
          Chat: number;//Id чата, в который добавлено сообщение
          NotifiedWorkers: number[];//Пользователи чата, получившие уведомление о данном сообщении.
          UnnotifiedWorkers: number[];//Пользователи чата, не получившие уведомление о данном сообщении.
          SendTime: Date;
        }



        export class ReadMessageNotificator
        {
          MessageCrossId: number;//сквозной идентификатор сообщения.
          Reader: number;//пользователь, прочитавший сообщение. Уменьшается кол-во уведомлений        public long Writer;//пользователь - автор сообщения. Не уменьшается кол-во уведомлений..
          Writer: number;
          SendTime: Date;
        }


        export  class RemovedMessageNotificator
        {
          WorkerId:number;//Id ????????????
          Count: number;//???-?? ????????? ???????????.
        }


        export class ChatMessagesWebGiver extends CommonResponse
          {
            Messages: MessageInOpenedChat[]
          }

        export class ChatMessagesRefreshWebGiver extends ChatMessagesWebGiver
        {
          UserCanEdit: boolean;//возможность редактировать конференцию.
          UserCanWrite: boolean;//возможность писать в чат.
          UnreadCount: number;
        }


      export class FullOpenedChat
      {

        /////from small
        Id: number;
        Name: string;
        CanWrite: boolean;
        CanEdit: boolean;//можно изменять настройки
        IsPersonal: boolean;//личнйы / конференция
        Updated: Date;//дата последненго обновления
        IsSystem: boolean;//является системным
        Messages: MessageInOpenedChat[];//сообщения
        CanLoadNext:boolean;//можно ли загружать более поздние сообщения
        UnreadedCount: number;//кол-во непрочитанных cообщений
        WorkerId: number;

        



        ///additional
        ScrollPosition: number;
        CanLoadPrev: boolean;
        CurrentWorkerId: number;
        TextArea: string;
        SearchMode: boolean=false;
        AttachedMessages:AttachedMessage[]=[];
        SelectedCount:number;
        SelectedMessages:MessageInOpenedChat[]=[];


        public CreateFromSmall(smallChat: ChatOpenedSmall, id: number)
        {
          this.CurrentWorkerId=id;
          this.ScrollPosition=0;
          this.CanLoadPrev=true;
          this.Id=smallChat.Id;
          this.TextArea="";


          this.Name=smallChat.Name;
          this.CanWrite=smallChat.CanWrite;
          this.CanEdit=smallChat.CanEdit;
          this.IsPersonal=smallChat.IsPersonal;
          this.Updated=smallChat.Updated;
          this.IsSystem=smallChat.IsSystem;
          this.Messages=smallChat.Messages;
          this.CanLoadNext=smallChat.CanLoadNext;
          this.UnreadedCount=smallChat.UnreadedCount;
          this.WorkerId=smallChat.WorkerId;
          this.SearchMode=false;
          this.AttachedMessages=[];
          this.SelectedCount=0;
          this.SelectedMessages=[];
        }

      }

           





import { ConfirmDoneIcon, CloseIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { ExtendedHelper } from '../../../config/ExtendedHelper';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'float-number-field',
    templateUrl: './float-number-field.component.html',
    styleUrls: ['./float-number-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class FloatNumberFieldComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() incorrectValue: boolean=false;


    @Input() borderOnHover:boolean=false
    
    @Input('value')
    value: number;
 
    @Input() placeholder: string="Введите текст";

    @Output()
    valueChange = new EventEmitter<number>();

    @Output()
    blurInput = new EventEmitter<number>();

    @Output()
    newSize = new EventEmitter<number>();

    @ViewChild('textInput') el: ElementRef;
    empty: boolean=false;

    @ViewChild('textSizeInput') elS: ElementRef;
    @ViewChild('floatCont') fc: ElementRef;

    @Input() editable: boolean=true;


    valueBuffer: number=null;
    @Input() clearBtnText: string="Очистить";
    @Input() clearBtn: boolean=true;
    @Input() enableNeagtive: boolean=true;
    @Input() enableDouble: boolean=true;
    @Input() disabled: boolean=false;
    @Input() min_size: boolean=false;
    

    inputCorrect:boolean=true;

    errorText: string="";

    @Input() resetIncorrect: boolean=true;
    showWarning: boolean = false;
    @ViewChild('warning', { static: false }) warningElem: ElementRef;
    @ViewChild('field', { static: false }) fieldElem: ElementRef;
    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon
        ])
    }



    ngOnInit() {
        this.valueBuffer=this.value;
       if(this.value==null )
       {
           this.empty=true;
           this.inputCorrect=true;
        this.errorText="";
       }
       else
       {
           this.empty=false;
       }
    }

    ngAfterViewInit()
    {
        if(!this.incorrectValue)
        {
            this.el.nativeElement.innerText=this.value;
        }
        //this.el.nativeElement.innerText=this.value;
    }
    ngOnChanges(changes: SimpleChanges){
        for (let prop in changes) {
            if(prop=="value")
            {
              let chng = changes[prop];

              if (!chng.isFirstChange() && !this.incorrectValue) 
              {
                  if(this.el.nativeElement.innerText!=this.value)
                  {
                      
                    this.el.nativeElement.innerText=this.value;
                  }
                
                if(this.value==null )
                {
                    this.empty=true;
                    this.inputCorrect=true;
        this.errorText="";
                }
                else
                {
                    this.empty=false;
                }
              }
            }
            
          }
    }


    pastText(ev:ClipboardEvent)
    {
        let focus =<HTMLElement>this.el.nativeElement;
        
        
        this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
        console.log(ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText))
        
        setTimeout(()=>{this.el.nativeElement.focus();
            let [r, s] = [document.createRange(), window.getSelection()];
            r.selectNodeContents(<Node>ev.target);
            r.collapse(false);
            s.removeAllRanges();
            s.addRange(r);
        },100);
    }

    textChanged(ev: KeyboardEvent)
    {
      /*

      keyup----->keydown
        if(ev.keyCode!=13 && ev.keyCode!=16 && ev.key!="."  && ev.keyCode!=27 && ev.keyCode!=8 && ev.keyCode!=46 && ev.keyCode!=189 &&!(ev.keyCode>=48 && ev.keyCode<=57))
        {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            return;
        }*/
        setTimeout(() => {



            if(ev.ctrlKey && ev.keyCode==86)
        {
            
            
                let focus =<HTMLElement>this.el.nativeElement;
                
                
                this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
                
                
                setTimeout(()=>{this.el.nativeElement.focus();
                    let [r, s] = [document.createRange(), window.getSelection()];
                    r.selectNodeContents(<Node>ev.target);
                    r.collapse(false);
                    s.removeAllRanges();
                    s.addRange(r);
                    this.value=this.el.nativeElement.innerText;
                    let regs;
                if(this.enableDouble && this.enableNeagtive && this.value!=null && this.el.nativeElement.innerText!=null)
                {
                    regs=new RegExp(/^-?\d+((\.)?\d+)?$/,"gi");
                    if(regs.test(this.el.nativeElement.innerText))
                    {
                        this.inputCorrect=true;
                        this.value=this.el.nativeElement.innerText;
                        this.valueBuffer=this.el.nativeElement.innerText;
                        this.valueChange.emit(this.el.nativeElement.innerText);}
                    else
                    {
                        this.inputCorrect=false;
                        this.errorText="Значение поля должно быть десятичным положительным или отрицательным числом.  Разделитель может быть символом '.'";
                    }
                }
                if(this.enableDouble && !this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
                {
                    regs=new RegExp(/^\d+((\.)?\d+)?$/,"gi");
                    if(regs.test(this.el.nativeElement.innerText))
                    {
                        this.value=this.el.nativeElement.innerText;
                        this.inputCorrect=true;
                        this.valueBuffer=this.el.nativeElement.innerText;
                        this.valueChange.emit(this.el.nativeElement.innerText);}
                    else
                    {
                        this.inputCorrect=false;
                        this.errorText="Значение поля должно быть десятичным положительным числом. Разделитель может быть символом '.'";
                    }
                }
                if(!this.enableDouble && this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
                {
                    regs=new RegExp(/^-?\d+$/,"gi");
                    if(regs.test(this.el.nativeElement.innerText))
                    {
                        this.value=this.el.nativeElement.innerText;
                        this.inputCorrect=true;
                        this.valueBuffer=this.el.nativeElement.innerText;
                        this.valueChange.emit(this.el.nativeElement.innerText);}
                    else
                    {
                        this.inputCorrect=false;
                        this.errorText="Значение поля должно быть целым положительным или отрицательным числом.";
                 
                    }
                }
                if(!this.enableDouble && !this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
                {
                    regs=new RegExp(/^\d+$/,"gi");
                    if(regs.test(this.el.nativeElement.innerText))
                    {
                        this.value=this.el.nativeElement.innerText;
                        this.inputCorrect=true;
                        this.valueBuffer=this.el.nativeElement.innerText;
                        this.valueChange.emit(this.el.nativeElement.innerText);}
                    else
                    {
                        this.inputCorrect=false;
                        this.errorText="Значение поля должно быть целым положительным числом.";
                    }
                }
                if(this.value==null ||  this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
                {
        
                    this.inputCorrect=true;
                    this.valueBuffer=null;
                    this.value=null;
                    this.valueChange.emit(this.el.nativeElement.innerText);
                }
        
                if(this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
               {
                   this.empty=true;
                   this.inputCorrect=true;
                this.errorText="";
               }
               else
               {
                   this.empty=false;
               } 
                
                },100)
            
        }

        else
        {
            
            this.value=this.el.nativeElement.innerText;
            let regs;
        if(this.enableDouble && this.enableNeagtive && this.value!=null && this.el.nativeElement.innerText!=null)
        {
            regs=new RegExp(/^-?\d+((\.)?\d+)?$/,"gi");
            if(regs.test(this.el.nativeElement.innerText))
            {
                this.inputCorrect=true;
                this.value=this.el.nativeElement.innerText;
                this.valueBuffer=this.el.nativeElement.innerText;
                this.valueChange.emit(this.el.nativeElement.innerText);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть десятичным положительным или отрицательным числом.  Разделитель может быть символом '.'";
            }
        }
        if(this.enableDouble && !this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
        {
            regs=new RegExp(/^\d+((\.)?\d+)?$/,"gi");
            if(regs.test(this.el.nativeElement.innerText))
            {
                this.value=this.el.nativeElement.innerText;
                this.inputCorrect=true;
                this.valueBuffer=this.el.nativeElement.innerText;
                this.valueChange.emit(this.el.nativeElement.innerText);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть десятичным положительным числом. Разделитель может быть символом '.'";
            }
        }
        if(!this.enableDouble && this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
        {
            regs=new RegExp(/^-?\d+$/,"gi");
            if(regs.test(this.el.nativeElement.innerText))
            {
                this.value=this.el.nativeElement.innerText;
                this.inputCorrect=true;
                this.valueBuffer=this.el.nativeElement.innerText;
                this.valueChange.emit(this.el.nativeElement.innerText);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть целым положительным или отрицательным числом.";
         
            }
        }
        if(!this.enableDouble && !this.enableNeagtive && this.value!=null  && this.el.nativeElement.innerText!=null)
        {
            regs=new RegExp(/^\d+$/,"gi");
            if(regs.test(this.el.nativeElement.innerText))
            {
                this.value=this.el.nativeElement.innerText;
                this.inputCorrect=true;
                this.valueBuffer=this.el.nativeElement.innerText;
                this.valueChange.emit(this.el.nativeElement.innerText);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть целым положительным числом.";
            }
        }
        if(this.value==null ||  this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
        {

            this.inputCorrect=true;
            this.valueBuffer=null;
            this.value=null;
            this.valueChange.emit(this.el.nativeElement.innerText);
        }

        if(this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
       {
           this.empty=true;
           this.inputCorrect=true;
        this.errorText="";
       }
       else
       {
           this.empty=false;
       } 
        }


            
          
            
        }, 150);
     

        
       // this.valueChange.emit(this.el.nativeElement.innerText);
        //this.value=this.el.nativeElement.innerText;
       
       // this.elS.nativeElement.width;
       //let elemS=<HTMLElement>this.elS.nativeElement;
       // this.newSize.emit(elemS.getBoundingClientRect().width+75);


        let elemS=<HTMLElement>this.elS.nativeElement;
        let fcs=<HTMLElement>this.fc.nativeElement;
        
        
        this.newSize.emit(elemS.getBoundingClientRect().width+30+fcs.getBoundingClientRect().width);
    }

    BlurInput()
    {

        
        if(!this.inputCorrect )
        {
            //this.el.nativeElement.innerText=this.valueBuffer;
            this.value=null;
            this.showWarning=true;
            setTimeout(() => {
                let lf=this.fieldElem.nativeElement.getBoundingClientRect().right+10;
                let tp=this.fieldElem.nativeElement.getBoundingClientRect().top+10;
                if(document.documentElement.clientWidth-lf<this.warningElem.nativeElement.getBoundingClientRect().width)
                {
                    lf=this.fieldElem.nativeElement.getBoundingClientRect().left;
                    tp=this.fieldElem.nativeElement.getBoundingClientRect().top+this.fieldElem.nativeElement.getBoundingClientRect().height+10;
                }
                this.warningElem.nativeElement.style.left=lf+"px";
                this.warningElem.nativeElement.style.top=tp+"px";
                this.warningElem.nativeElement.style.opacity=1;
                setTimeout(()=>{this.showWarning=false;},2000)
                
            }, 100);
           

        }

     //  this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
      
      //  this.valueChange.emit(this.value);
        this.blurInput.emit(this.value);
        if(this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
       {
           this.empty=true;
           this.inputCorrect=true;
        this.errorText="";
       }
       else
       {
           this.empty=false;
       }
    }

    focusText()
    {
        
        this.el.nativeElement.focus();
        this.empty=false;
    }


    clear()
    {
        this.value=null;
        this.valueBuffer=null;
        this.el.nativeElement.innerText=null;
        this.inputCorrect=true;
        this.errorText="";
        this.empty=true;
        this.el.nativeElement.focus();
        this.valueChange.emit(this.value);
        this.BlurInput();
    }



    TextClick(ev: MouseEvent)
    {
        
        this.el.nativeElement.focus();
        if(this.value==null )
        {
            setTimeout(()=>{
                let [r, s] = [document.createRange(), window.getSelection()];
                        r.selectNodeContents(<Node>ev.target);
                        r.collapse(false);
                        s.removeAllRanges();
                        s.addRange(r);
            },10)
        }
       
        
    }

}



/**
 * Created by MosNeuro on 17.11.2017.
 */
import { CookieService} from 'ngx-cookie';
import {CookieOptionsMy} from "./CookieOptionsMy";
import {CookieOptions} from 'ngx-cookie';
import {Injectable} from "@angular/core";
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  constructor(private cookieService: CookieService,
              private cookieOptionsMy: CookieOptionsMy,
              private localStorageService: LocalStorageService) { }
  
  // HaveToMessageAlertCookieName:string = "HaveToMessageAlert";
  // PageIdCookieName = "PageId";

  playAudio(path: string) {
    let audio = new Audio();
    audio.src = path;
    audio.load();
    audio.play();
  }

  playMessageAlert() {

    if(!document.hidden)
    {
      let sound = this.localStorageService.get("enableSound")
     
        if (sound!=undefined && sound!=null && sound!= "")
        {
           if(sound==="true")
          {
            this.playAudio('../../assets/media/messageRing.mp3');
           }
          
        }
        else
        {
          this.playAudio('../../assets/media/messageRing.mp3');
        }
    
    }
      
  }


  playMessageInChatAlert() {
    if(!document.hidden)
    {
      let sound = this.localStorageService.get("enableSound");
    
      if (sound!=undefined && sound!=null && sound!= "")
      {
         if(sound==="true")
         {
          this.playAudio('../../assets/media/messageInChat.mp3');
        }
          
      }
      else
      {
        this.playAudio('../../assets/media/messageInChat.mp3');
      }
    }
  }


  playNotificationAlert() {

    if(!document.hidden)
    {
      let sound = this.localStorageService.get("enableSound");
    
      if (sound!=undefined && sound!=null && sound!= "")
      {
         if(sound==="true")
         {
          this.playAudio('../../assets/media/notification.mp3');
        }
          
      }
      else
      {
        this.playAudio('../../assets/media/notification.mp3');
      }
    }
    
    /*let min = Math.ceil(1);
    let max = Math.floor(100);
    let timerOffset = Math.floor(Math.random() * (max - min + 1)) + min;
    setTimeout(()=>{
      let indentString="";
    if(type==0)
    {
      indentString="lastMessageNotification"
    }
    if(type==1)
    {
      indentString="lastJobNotification"
    }
    let localSt = this.localStorageService.isSupported;
    let notifObject = this.localStorageService.get(indentString);
    let notifString="";
          if (notifObject != undefined && notifObject != null && notifObject != '') {
            
            notifString=notifObject.toString();
            let dateStamp =  JSON.parse(notifString);
            
            if(timeStamp>dateStamp)
            {
              console.log('gotoalert2')
              let newNotifString = JSON.stringify(timeStamp);
            this.localStorageService.remove(indentString);
            this.localStorageService.set(indentString, newNotifString);
              this.playAudio('../../assets/media/notification.mp3');
            }
          }
          else
          {console.log('gotoalert3')
            let newNotifString = JSON.stringify(timeStamp);
          this.localStorageService.remove(indentString);
          this.localStorageService.set(indentString, newNotifString);
            this.playAudio('../../assets/media/notification.mp3');
            console.log('gotoalert3')
          }

    
    },timerOffset)
*/
    
            
  }
}

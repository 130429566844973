import { Optional, Injectable } from "@angular/core";
import { LogErrorAsker } from '../models/model.Service/Out/model.LogErrorAsker';
import { CommonResponse } from '../models/model.http';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { ExtendedHelper } from '../config/ExtendedHelper';
import { HttpServiceService } from '../httpserv/httpService.service';
import { CookieOptionsMy } from '../config/CookieOptionsMy';
import { Router } from '@angular/router';


@Injectable()
export class LogsHelper {
  constructor(private httpServiceService: HttpServiceService, private cookieService: CookieService, private cookieOptionsMy: CookieOptionsMy, private router: Router) { }

  public LogError(checker: any, code: number, message: string, UserId: number, SessionKey: string, ServerString: string) {

    let errorLogger = new LogErrorAsker(UserId, SessionKey, checker, code, message, ServerString);
    this.httpServiceService.logError(errorLogger).subscribe((data: CommonResponse) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300) {
        console.log(data.Message);
        return;
      }
      //alert("Успешно добавлено");
      console.log("СОобщение об ошибке добавлено в базу");
    });
  }


  public unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }

    const cookieOpt: CookieOptions = {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    };

    this.cookieService.remove("Id", this.cookieOptionsMy.get());
    this.cookieService.remove("UserName", this.cookieOptionsMy.get());
    this.cookieService.remove("SessionKey", this.cookieOptionsMy.get());
    let link = ['/login'];
    this.router.navigate(link);
  }

}

import {Injectable} from "@angular/core";

@Injectable()
export class NumberHelper
{
  static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  static getWeightedAverage(values: Map<number, number>, difference:number=2): number[] {
    
    let res=1;
    if(values.size==0)
    {
      return [res,res];
    }
    // difference=50;

    let max=1;
    let min=1;
    let multiSum=0;
    let weigthSum=0;

    values.forEach((value, key, map)=>{
      
      if (key<min)
      {
        min=key;
      }
      if (key>max)
      {
        max=key;
      }
      multiSum=multiSum+(key*value);
      weigthSum=weigthSum+value;
    });
    
 
    if(weigthSum==0 || max<res || (max-min)<difference)
    {
      if((max-min)<difference)
      {
        res=max;
      }
      return [res, max];
    }
    else
    {
      res=multiSum/weigthSum;
      return [res,max];
    }
    
  }



  static calculateMediane(values: Map<number, number>, difference:number=2): number[] {
    
    let res=1;
    if(values.size==0)
    {
      return [res,res];
    }
    // difference=50;

    if(values.size==1)
    {
      values.forEach((value, key, map)=>{
      
       res=key;
      });
      return [res,res];
      
    }

    let max=1;
    let min=1;
    let vals=[];

    values.forEach((value, key, map)=>{
      
      if (vals.length==0)
      {
        vals.push(key);
      }
      else
      {
        if(key<=vals[0])
        {
          vals.unshift(key);
        }
        else
        {
          if(key>=vals[vals.length-1])
          {
            vals.push(key);
          }
          else
          {
            let ind=-1;
            let i=0;
            while(i<vals.length-1 && ind==-1)
            {
              if(vals[i]<=key && vals[i+1]>=key)
              {
                ind=i+1;
              }
            }
            if(ind!=-1)
            {
              vals.splice(ind,0,key);
            }
          }
        }
      }




      if (key<min)
      {
        min=key;
      }
      if (key>max)
      {
        max=key;
      }
    });
    
    if(vals.length%2==1)
    {
      let midInd=Math.floor(vals.length/2);
      res=vals[midInd];
    }
    else
    {
      let midInd=vals.length/2;
      res=(vals[midInd]+vals[midInd-1])/2;
    }
    
 
    if( max<res || (max-min)<difference)
    {
      if((max-min)<difference)
      {
        res=max;
      }
      return [res, max];
    }
    else
    {
      return [res,max];
    }
    
  }




  static calculateMiddleArifmetic(values: Map<number, number>, difference:number=2): number[] {
    
    let res=1;
    if(values.size==0)
    {
      return [res,res];
    }
    if(values.size==1)
    {
      values.forEach((value, key, map)=>{
      
       res=key;
      });
      return [res,res];
      
    }
    // difference=50;

    let max=1;
    let min=1;

    values.forEach((value, key, map)=>{
      
      if (key<min)
      {
        min=key;
      }
      if (key>max)
      {
        max=key;
      }
      
    });
    res=(min+max)/2;
    if( max<res || (max-min)<difference)
    {
      if((max-min)<difference)
      {
        res=max;
      }
      return [res, max];
    }
    else
    {
      return [res, max];
    }
    
  }


}



 

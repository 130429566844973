import { JobAuthorEnum } from './model.JobAuthorEnum';
import { JobExecutorEnum } from './model.JobExecutorEnum';
import { Common } from "../model.common";
import { CommonRequest, CommonResponse, SingleIdRequest } from '../model.http';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { AutojobMultyselectInProjectElement, MultyselectContainer } from '../model.MultyselectContainer';
import { CommonWorkerHolder } from '../model.workerAttached';
import { IMyDateModel } from 'angular-mydatepicker';




export class AutoJobTemplateInSettings extends Common {
  Container: AutoJobTemplateContainer;
}


export class AutoJobTemplateContainer {
  Name: string;
  Text: string;
  TextLocked: boolean;
  AuthorType: number;
  AuthorId: number;
  AuthorLocked: boolean;
  ExecutorWorkers: number[];
  ExecutorRoles: number[];
  ExecutorLocked: boolean;
  NeedChecking: boolean;
  NeedCheckingLocked: boolean;
  Time: number;
  TimeLocked: boolean;
  IsMandatory: boolean;
  CompleteOnStage: boolean;
  AutoJobType: number;
  Tags: Common[];
  TagsLocked: boolean;
  Workers: Common[];//общие списки для выбора исполнителей и поручателя.
  Roles: Common[];
  Holders: CommonWorkerHolder[];
}


export class AutoJobTemplateAddAsker extends SingleIdRequest {
  AutoJobType: number;//тип работы
}


export class AutoJobTemplateInSettingsSingleGiver extends CommonResponse {
  Template: AutoJobTemplateInSettings;
}


export class AutoJobTemplateEditAsker extends SingleIdRequest {
  Text: string;
  TextLocked: boolean;
  AuthorType: number;
  AuthorId: number;
  AuthorLocked: boolean;
  ExecutorWorkers: number[];
  ExecutorRoles: number[];
  ExecutorLocked: boolean;
  NeedChecking: boolean;
  NeedCheckingLocked: boolean;
  Time: number;
  TimeLocked: boolean;
  IsMandatory: boolean;
  CompleteOnStage: boolean;
  AutoJobType: number;
  Tags: Common[];
  TagsLocked: boolean;

}

export class MultiIdRequest extends CommonRequest {
  List: number[] = [];
  constructor(userId?: number, sessionKey?: string, list?: number[]) {
    super(userId || 0, sessionKey || '')
    this.List = list || [];
  }
}

export class AutoJobTemplateContainerGiver extends CommonResponse {
  Container: AutoJobTemplateContainer;
}


export class AutoJobTemplateMoveAsker extends SingleIdRequest {
  Templates: number[];
  Type: number;
}


export class StageToMoveTemplate {
  Stage: Common;
  Selected: boolean;
}

export class AutoJobTemplateInProjectGiver extends CommonResponse {
  TemplatesFrom: AutoJobTemplateInProject[];
  TemplatesTo: AutoJobTemplateInProject[];
}



export class AutoJobTemplateFullContainer {
  Template: AutoJobTemplateInSettings;
  Type: number;
  IsVisible: boolean;
  IsSelected: boolean;
  IsSelectedForMove: boolean;
  AuthorMultyselect: MultyselectContainer;
  ExecutorsMultyselect: MultyselectContainer;
  Styles: TemplateStyles = new TemplateStyles();
}


export class TemplateStyles {
  Height: string = "20px";
  Overflow: string = "auto";
}

/////////////ПРОЕКТ///////////////////////
export class AutoJobFullContainer {
  Template: AutoJobTemplateInProject;
  Type: number;
  IsSelected: boolean;
  TemplateBackup: AutoJobTemplateInProject;
  NeedRefreshAuthor: boolean=false;
  NeedRefreshExecutor: boolean=false;
  InvalidAuthor: boolean=false;
  InvalidExecutor: boolean=false;
  InvalidAuthorText: string="";
  InvalidExecutorText: string="";
  InvalidDate: boolean=false;
  InvalidText: boolean=false;
  InvalidCheck: boolean=false;
  InvalidTags: boolean=false;
  SelectedAuthor: AutojobMultyselectInProjectElement[]=[];
  SelectedExecutors: AutojobMultyselectInProjectElement[]=[];
  ContainerValid: boolean=true;


  CheckValid():boolean
  {
    this.ContainerValid=true;
    this.InvalidAuthorText="";
    this.InvalidExecutorText="";
    this.InvalidAuthor=false;
    this.InvalidExecutor=false;
    this.NeedRefreshAuthor=false;
    this.NeedRefreshExecutor=false;
    this.InvalidText=false;
    this.InvalidDate=false;
    this.InvalidCheck=false;
    this.InvalidTags=false;
    this.ContainerValid=true;
    if(this.SelectedAuthor==null || this.SelectedAuthor.length==0)
    {
      this.InvalidAuthor=true;
      this.InvalidAuthorText=this.InvalidAuthorText+"Необходимо выбрать автора";
    }
    else
    {
      
      let rolesCounter=0;
      let rolesInvalidCounter=0;
      
        for(let i=0; i<this.SelectedAuthor.length; i++)
        {
          if(!this.SelectedAuthor[i].HasAccess)
          {
            this.InvalidAuthor=true;
            this.NeedRefreshAuthor=true;
          }
          if(this.SelectedAuthor[i].ElementType==0)
          {
            rolesCounter++;
          }
          if(this.SelectedAuthor[i].ElementType==0 && (this.SelectedAuthor[i].Additionals==null ||this.SelectedAuthor[i].Additionals.length==0))
          {
            rolesInvalidCounter++;
            
          }
        }
        if(rolesCounter==rolesInvalidCounter && rolesCounter>0)
        {
          this.InvalidAuthor=true;
          this.NeedRefreshAuthor=true;
        }
      
    }


    if(this.SelectedExecutors==null || this.SelectedExecutors.length==0)
    {
      this.InvalidExecutor=true;
      this.InvalidExecutorText=this.InvalidExecutorText+"Необходимо выбрать автора";
    }
    else
    {
      
      let rolesCounter=0;
      let rolesInvalidCounter=0;
      
        for(let i=0; i<this.SelectedExecutors.length; i++)
        {
          if(!this.SelectedExecutors[i].HasAccess)
          {
            this.InvalidExecutor=true;
            this.NeedRefreshExecutor=true;
          }
          if(this.SelectedExecutors[i].ElementType==0)
          {
            rolesCounter++;
          }
          if(this.SelectedExecutors[i].ElementType==0 && (this.SelectedExecutors[i].Additionals==null ||this.SelectedExecutors[i].Additionals.length==0))
          {
            rolesInvalidCounter++;
            
          }
        }
        if(rolesCounter==rolesInvalidCounter && rolesCounter>0)
        {
          this.InvalidExecutor=true;
          this.NeedRefreshExecutor=true;
        }
      
    }

    if(this.Template.Text=="")
    {
      this.InvalidText=true;
    }
    if(this.InvalidText || this.InvalidAuthor ||this.InvalidExecutor)
    {
      this.ContainerValid=false;
    }

    return(this.ContainerValid);

  }

}

export class AutoJobExectutorHolder {
  IsExecutor: boolean;
  Executor: AutoJobExecutorInProject;
  Department: ExecutorDepartment;
  Position: number;
}
class ExecutorDepartment extends Common {
  Executors: AutoJobExecutorInProject[];
}

export class AutoJobAuthorHolder {
  IsAuthor: boolean;
  Author: AutoJobAuthorInProject;
  Department: AuthorDepartment;
  Position: number;
}

class AuthorDepartment extends Common {
  Authors: AutoJobAuthorInProject[];
}

export class AutoJobTemplateInProject {
  Id: number;
  Text: string;
  TextLocked: boolean;
  AuthorHolders: AutoJobAuthorHolder[];
  AuthorLocked: boolean;
  AuthorIsValid: boolean;
  ExectutorHolders: AutoJobExectutorHolder[];
  ExecutorLocked: boolean;
  NeedChecking: boolean;
  NeedCheckingLocked: boolean;
  Date: Date;
  DateLocked: boolean;
  Selected: boolean;
  IsMandatory: boolean;//Если true, создается в любом случае, галочку снять нельзя.
  Tags: Common[];
  LockedTags: Common[];
  DateToObject: IMyDateModel;
  ExecutorIsValid: boolean;
}



export class AutoJobAuthorInProject extends Common {
  Type: number;//тип автора. Валидные типы: менятель этапа, создатель или пользователь.
  //Если стоит роль, то такой вариант служит, чтобы показать,
  //что нет подходящих пользователей в этой роли и нельзя сохранять такую автоработу.
  Selected: boolean;
  Position: number;
}


export class AutoJobExecutorInProject extends Common {
  IsWorker: boolean;
  Selected: boolean;
  Workers: Common[];
  Position: number;
  HasAccess: boolean;
}


export class ChangeStageWithAutoJobsAsker extends SingleIdRequest {
  StageId: number;
  Templates: AutoJobTemplateFilled[];
}

export class AutoJobTemplateFilled {
  Id: number;
  Text: string;
  AuthorType: number;
  AuthorId: number;
  ExecutorRoles: number[];
  ExecutorWorkers: number[];
  NeedChecking: boolean;
  Date: Date;
  Tags: Common[] = [];

  CreateFromTemplateInProject(template: AutoJobTemplateInProject) {

    this.Id = template.Id;
    this.Text = template.Text;
    this.NeedChecking = template.NeedChecking;
    //this.Date=template.Date;
    this.Date = ExtendedHelper.ToDate(template.DateToObject.singleDate.date);
    this.AuthorId = null;
    this.AuthorType = 0;

    for (let i = 0; i < template.AuthorHolders.length; i++) {
      if (template.AuthorHolders[i].IsAuthor) {
        if (template.AuthorHolders[i].Author.Selected && template.AuthorHolders[i].Author.Type == 4) {
          this.AuthorId = template.AuthorHolders[i].Author.Id;
          this.AuthorType = template.AuthorHolders[i].Author.Type;
        }
        if (template.AuthorHolders[i].Author.Selected && (template.AuthorHolders[i].Author.Type == 1 || template.AuthorHolders[i].Author.Type == 2)) {
          this.AuthorType = template.AuthorHolders[0].Author.Type;
        }
      } else {
        if (template.AuthorHolders[i].Department.Authors.length > 0) {
          template.AuthorHolders[i].Department.Authors.forEach(innerDep => {
            if (innerDep.Type == 4 && innerDep.Selected) {
              this.AuthorId = innerDep.Id;
              this.AuthorType = innerDep.Type;
            }
          });
        }
      }
    }
    //   if (template.Authors[i].Selected) {
    //     this.AuthorType = template.Authors[i].Type;
    //     if (template.Authors[i].Type == 4) {
    //       this.AuthorId = template.Authors[i].Id;
    //     }
    //   }
    // if (template.Authors.length == 1) {
    //   this.AuthorId = template.Authors[0].Id;
    //   this.AuthorType = template.Authors[0].Type;
    // }

    this.ExecutorRoles = [];
    this.ExecutorWorkers = [];

    for (let i = 0; i < template.ExectutorHolders.length; i++) {
      if (template.ExectutorHolders[i].IsExecutor) {
        // console.log(template.ExectutorHolders[i].Executor.Name + " - " + template.ExectutorHolders[i].Executor.Selected )
        if (template.ExectutorHolders[i].Executor.IsWorker && template.ExectutorHolders[i].Executor.Selected) {
          this.ExecutorWorkers.push(template.ExectutorHolders[i].Executor.Id);
        }
        if (!template.ExectutorHolders[i].Executor.IsWorker && template.ExectutorHolders[i].Executor.Selected) {
          this.ExecutorRoles.push(template.ExectutorHolders[i].Executor.Id);
        }
      } else {
        if (template.ExectutorHolders[i].Department.Executors.length > 0) {
          template.ExectutorHolders[i].Department.Executors.forEach(innerDep => {
            if (innerDep.IsWorker && innerDep.Selected) {
              this.ExecutorWorkers.push(innerDep.Id);
            }
          });
        }
      }
    }





    //   if (template.Executors[i].Selected) {
    //     if (template.Executors[i].IsWorker) {
    //       this.ExecutorWorkers.push(template.Executors[i].Id);
    //     }
    //     else if (template.Executors[i].Workers != null && template.Executors[i].Workers.length > 0) {
    //       this.ExecutorRoles.push(template.Executors[i].Id);
    //     }
    //   }
    // }

    this.Tags = [];
    if (template.LockedTags != null) {
      for (let i = 0; i < template.LockedTags.length; i++) {
        this.Tags.push(template.LockedTags[i]);
      }
    }
    if (template.Tags != null) {
      for (let i = 0; i < template.Tags.length; i++) {
        this.Tags.push(template.Tags[i]);
      }
    }
  }


  CreateFromContainer(template: AutoJobFullContainer) {

    this.Id = template.Template.Id;
    this.Text = ExtendedHelper.ReplaceBadThings(template.Template.Text);
    this.NeedChecking = template.Template.NeedChecking;
    //this.Date=template.Date;
    this.Date = ExtendedHelper.ToDate(template.Template.DateToObject.singleDate.date);
    this.AuthorId = null;
    this.AuthorType = 0;

    for (let i = 0; i < template.SelectedAuthor.length; i++) {
      if (template.SelectedAuthor[i].ElementType==1) {
        
          this.AuthorId = template.SelectedAuthor[i].Element.Id;
          this.AuthorType = template.SelectedAuthor[i].Element.ValueOuterType;
        }
        if (template.SelectedAuthor[i].ElementType==3) {
          this.AuthorType = template.SelectedAuthor[i].Element.ValueOuterType;
        }
      } 

    this.ExecutorRoles = [];
    this.ExecutorWorkers = [];

    for (let i = 0; i < template.SelectedExecutors.length; i++) {
        if (template.SelectedExecutors[i].ElementType==1) {
          this.ExecutorWorkers.push(template.SelectedExecutors[i].Element.Id);
        }
        if (template.SelectedExecutors[i].ElementType==0) {
          this.ExecutorRoles.push(template.SelectedExecutors[i].Element.Id);
        }
       
    }

    this.Tags = [];
    if (template.Template.LockedTags != null) {
      for (let i = 0; i < template.Template.LockedTags.length; i++) {
        this.Tags.push(template.Template.LockedTags[i]);
      }
    }
    if (template.Template.Tags != null) {
      for (let i = 0; i < template.Template.Tags.length; i++) {
        this.Tags.push(template.Template.Tags[i]);
      }
    }
  }
}



export class AutoJobListResreshAsker extends SingleIdRequest {
  StageId: number; //- этап на который будем потом переключать
  TemplateId: number;
}

export class AutoJobAuthorListGiver extends CommonResponse {
  Authors: AutoJobAuthorInProject[];
  AuthorHolders: AutoJobAuthorHolder[];
  AuthorLocked: boolean;
  AuthorIsValid: boolean;
}


export class AutoJobExecutorListGiver extends CommonResponse {
  Executors: AutoJobExecutorInProject[];
  ExectutorHolders: AutoJobExectutorHolder[];
  ExecutorLocked: boolean;
  // ExecutorIsValid: boolean;
}

/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import {LocalStorageService} from 'angular-2-local-storage';

import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver, SingleIdGiver } from '../../../models/model.http';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';

import { Chats_LangSet, Common_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';

import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';

import { DeleteSession } from '../../../store/actions/session.action';

import {  ChatWorkersGiverNew,  GlobalChatAddAsker,  WorkerForChatHolder } from '../../../models/model.chat';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { cloneDeep } from 'lodash';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { ServerString } from '../../../config/serverstring';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { PhotoUploadAsker } from '../../../models/model.Files/Out/model.FileSystemAsker';
import { HttpServiceFile } from '../../../httpserv/httserv.file';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'addChatContainer',
    templateUrl: 'temp.addChatContainer.html',
    styleUrls: ['addChatContainer.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/control-inputs.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class addChatContainerComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    
  
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    potentialUserList: WorkerForChatHolder[]=[];
    workersLoaded: boolean=false;
    ChatPotentialName="Новая беседа";
    ChatNameBack="Новая беседа";
    ChatPotentialNameLiter="";
    ChatPhoto: string=null;
    SelectedCount: number=0;
    isChatNameEdit: boolean=false;


    @Output() AddedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() CancelChatAdding: EventEmitter<boolean> = new EventEmitter<boolean>();


    isAddingPhotoToChatDialog: boolean=false;
    //CurrentOpenedChat: 
   
    imageChangedEvent: any = '';
  croppedImage: any = '';
  photoLoaded: boolean = false;
  filename: string = '';

  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showUploadFile: boolean = false;
  UploadFilePath: string;
  files: any;
  @ViewChild("fileInput") fileInput: any;

  localeFileString: string;

  chats_Langset: Chats_LangSet;
  ch_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private httpServiceSession: HttpServiceSession,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private serverString: ServerString,
    private httpServiceFile: HttpServiceFile,
    private sessionService: SessionService,
    private languageService: LanguageService
    
              ) { }




            
            
              ngOnDestroy() {
                //
                
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);

                
                if (!(this.UserId > 0)) {//куков нет значит не авторизованы значит идем логниться
                  this.unLogin();
                }
                else {//куки есть, чекаем сессию
                  
                      
                        this.extendedHelper.ResetLastMessageTime();
                        this.LoadPotentialWorker();
                      
                    
                }

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }



              SaveChat()
              {
                let selectedIds=[];
                for(let i=0; i<this.potentialUserList.length; i++)
                {
                  if(this.potentialUserList[i].IsWorker && this.potentialUserList[i].Worker.Selected)
                  {
                    selectedIds.push(this.potentialUserList[i].Worker.Id);
                  }
                  if(!this.potentialUserList[i].IsWorker)
                  {
                    for(let j=0; j<this.potentialUserList[i].Department.Workers.length; j++)
                    { 
                      if(this.potentialUserList[i].Department.Workers[j].Selected )
                      {
                        selectedIds.push(this.potentialUserList[i].Department.Workers[j].Id)
                      }
                    }
                  }
                }

                /*if(selectedIds.length==0)
                {
                  alert("Пользователи не добавлены");
                  return;
                }*/

                if(selectedIds.length==1)
                {
                  
                  let checker = new SingleIdRequest();
                  checker.SessionKey = this.SessionKey;
                  checker.ChangerId = this.UserId;
                  checker.Id=selectedIds[0];
                  this.httpServiceChat.chatAddPersonal(checker).subscribe((data: SingleIdGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }

                    if (data.Code == 300) {
                      alert("Необходимо выбрать пользователядля создания чата")
                      return;
                    }
                   
                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      this.AddedChatId.emit(data.Id)
                    }
                    
                  })
                }

                if(selectedIds.length>1)
                {

                  if(this.ChatPotentialName==undefined || this.ChatPotentialName==null || this.ChatPotentialName=="")
                  {
                    alert("Необходимо ввести название");
                    return;
                  }

                  let checker = new GlobalChatAddAsker();
                  checker.SessionKey = this.SessionKey;
                  checker.ChangerId = this.UserId;
                  checker.Workers=selectedIds;
                  checker.Name=this.extendedHelper.ReplaceBadThings(this.ChatPotentialName);
                  checker.Photolink=this.ChatPhoto;
                  this.httpServiceChat.chatAddConference(checker).subscribe((data: SingleIdGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                   
                    if (data.Code == 300) {
                      alert("Необходимо указать название беседы")
                      return;
                    }
                    if (data.Code == 301) {
                      alert("Необходимо выбрать пользователядля создания чата")
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      this.AddedChatId.emit(data.Id)
                    }
                    
                  })
                }

                if(selectedIds.length==0)
                {
                  
                  let checker = new SingleIdRequest();
                  checker.SessionKey = this.SessionKey;
                  checker.ChangerId = this.UserId;
                  checker.Id=this.UserId;
                  this.httpServiceChat.chatAddPersonal(checker).subscribe((data: SingleIdGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 300) {
                      alert("Необходимо выбрать пользователядля создания чата")
                      return;
                    }

                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      this.AddedChatId.emit(data.Id)
                    }
                    
                  })
                }


              }

              CancelAddChat()
              {
                
                this.CancelChatAdding.emit(true);
              }


              LoadPotentialWorker()
              {
                this.workersLoaded=false;
                let checkerMes = new SingleIdRequest();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=0;
                this.httpServiceChat.chatGetWorkersToCreate(checkerMes).subscribe((data: ChatWorkersGiverNew) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.potentialUserList=_.cloneDeep(data.Holders);
                    this.workersLoaded=true;
                  }
                  
                })
              }

              RecountSelected()
              {
                this.SelectedCount=0;
                for(let i=0; i<this.potentialUserList.length; i++)
                {
                  if(this.potentialUserList[i].IsWorker && this.potentialUserList[i].Worker.Selected)
                  {
                    this.SelectedCount++;
                  }
                  if(!this.potentialUserList[i].IsWorker)
                  {
                    for(let j=0; j<this.potentialUserList[i].Department.Workers.length; j++)
                    { 
                      if(this.potentialUserList[i].Department.Workers[j].Selected )
                      {
                        this.SelectedCount++;
                      }
                    }
                  }
                }
              }

              


              ImageLoaded(link: string)
              {
                this.ChatPhoto= this.serverString.fullServerString()+link;
                this.isAddingPhotoToChatDialog=false;
                
              }


              AddImageOpenDialog()
              {
                this.isAddingPhotoToChatDialog=true;
                DOMHelper.FocusElement('AddingPhotoToChatDialogId', 50);
              }



              ////Для загрузки картинок, почему то не взлетела компонентом?

              imageCropped(event: ImageCroppedEvent) {
                this.croppedImage = event.base64;
              }
              imageLoaded() {
                this.photoLoaded = true;
              }
              loadImageFailed() {
                // show message
              }
            
              fileChangeEvent(event: any): void {
                
                this.imageChangedEvent = event;
                if (event != undefined && event.target.files.length > 0) {
                  this.filename = event.target.files[0].name;
                }
              }
            
              DeleteSelectedPhoto() {
                this.imageChangedEvent = '';
                this.filename = '';
                this.photoLoaded = false;
              }
            
            
              rotateLeft() {
                this.canvasRotation--;
                this.flipAfterRotate();
              }
            
              rotateRight() {
                this.canvasRotation++;
                this.flipAfterRotate();
              }
            
              private flipAfterRotate() {
                const flippedH = this.transform.flipH;
                const flippedV = this.transform.flipV;
                this.transform = {
                  ...this.transform,
                  flipH: flippedV,
                  flipV: flippedH
                };
              }
            
            
              flipHorizontal() {
                this.transform = {
                  ...this.transform,
                  flipH: !this.transform.flipH
                };
              }
            
              flipVertical() {
                this.transform = {
                  ...this.transform,
                  flipV: !this.transform.flipV
                };
              }
            
              resetImage() {
                this.scale = 1;
                this.rotation = 0;
                this.canvasRotation = 0;
                this.transform = {};
              }
            
              zoomOut() {
                this.scale -= .1;
                this.transform = {
                  ...this.transform,
                  scale: this.scale
                };
              }
            
              zoomIn() {
                this.scale += .1;
                this.transform = {
                  ...this.transform,
                  scale: this.scale
                };
              }
            
              updateRotation() {
                setTimeout(() => {
                  this.transform = {
                    ...this.transform,
                    rotate: this.rotation
                  };
                }, 100);
              }
            
            
              UploadImage() {
                let fi = this.fileInput.nativeElement.files;
                if (fi && fi[0]) {
                  let fileToUpload = fi[0];
            
                  let str = new PhotoUploadAsker();
                  str.ChangerId = this.UserId;
                  str.SessionKey = this.SessionKey;
                  str.Filename = fileToUpload.name;
                  str.Type = 0;
            
                  const str2 = JSON.stringify(str);
            
                  var byteString;
                  if (this.croppedImage.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(this.croppedImage.split(',')[1]);
                  else
                    byteString = unescape(this.croppedImage.split(',')[1]);
            
                  // separate out the mime component
                  var mimeString = this.croppedImage.split(',')[0].split(':')[1].split(';')[0];
            
                  // write the bytes of the string to a typed array
                  var ia = new Uint8Array(byteString.length);
                  for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }
            
                  let blb = new Blob([ia], { type: mimeString });
            
            
                  let input = new FormData();
                  input.append("data", blb, str2);
                  this.httpServiceFile.uploadPhoto(input).subscribe((data: SingleStringGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 300) {
                      alert(this.langset.EmptyFileName);
                      return;
                    }
                    if (data.Code == 301) {
                      alert(this.langset.WrongPhotoFormat);
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
            
                    
                    let link = _.cloneDeep(data.Value);
                    fi=[];
                    this.ImageLoaded(link);
                    
                  });
            
                }
              }

              onKeyDown(e: KeyboardEvent){
                switch(e.keyCode){
                  case 27:{
                    this.isAddingPhotoToChatDialog = false;
                  }
                }
              }

              StartEditName()
              {
                this.ChatNameBack=this.ChatPotentialName;
                this.isChatNameEdit=true;
              }

              CancelChangeName()
              {
                this.ChatPotentialName=this.ChatNameBack;
                this.isChatNameEdit=false;
              }


              DeletePhoto()
              {
                this.ChatPhoto=null;
              }
}



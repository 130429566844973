// import { LoginSessionStorage, ChangeSessionStorage } from './../actions/session.action';
import { Action, createReducer, on, State } from '@ngrx/store';
import { ISessionState, initialSessionState } from './../state/session.state';
import * as sessionActions from '../actions/session.action';

const SessionReducer = createReducer(
    initialSessionState,
    on(sessionActions.LoginSession, (state, payload) => ({
        ...state,
        sessionKey: payload.sessionKey,
        id: payload.id,
        isLoaded: true
    })),
    on(sessionActions.GetSession, state => ({
        ...state
    })),
    on(sessionActions.ChangeSession, (state, { payload }) => ({
        ...state,
        sessionKey: payload
    })),
    on(sessionActions.LoadSession, (state, payload) => ({
        ...payload
    })),
    on(sessionActions.DeleteSession, (state) => ({
        ...state,
        id: 0,
        sessionKey: '',
        isLoaded: false
    })),
);

export function sessionReducer(state: ISessionState | undefined, action: Action) {
    return SessionReducer(state, action);
}

import {CommonRequest} from "../../model.http";
import {ExtendedHelper} from "../../../config/ExtendedHelper";

export class LogErrorAsker extends CommonRequest {  /// <summary>
  /// Должен быть упакован в джейсон
  /// </summary>
  RequestObject: string;

  /// <summary>
  /// Код ошибки
  /// </summary>
  ErrorCode: number;

  /// <summary>
  /// Сообщение ошибки
  /// </summary>
  ErrorMessage: string;

  /// <summary>
  /// Адрес сервера
  /// </summary>
  ServerString:string;

  constructor(changerId: number, sessionKey: string, reqObj: any, errorCode: number, errorMessage: string, serverString: string) {
    super(changerId, sessionKey);
    //let obj =  JSON.stringify(reqObj);
    this.RequestObject = ExtendedHelper.ReplaceBadThings(JSON.stringify(reqObj));//obj.slice(1, obj.length-1);
    this.ErrorCode = errorCode;
    this.ErrorMessage = ExtendedHelper.ReplaceBadThings(errorMessage);
    this.ServerString = ExtendedHelper.ReplaceBadThings(serverString);
  }
}

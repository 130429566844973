import { ConfirmDoneIcon, CloseIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class TextFieldComponent implements OnInit {

    @Input('inputModel')
    inputModel: string = '';
    // set value(inputValue: string) {
    //     console.log(inputValue)
    // }

    paddingRight: number;


    @Input()
    placeholder: string;
    @Input()
    autocomplete: 'on' | 'off' = 'off';

    @Input()
    name: string = '';


    @Input()
    focus: boolean = false;


    @Input()
    clearble: boolean = true;

    @Input()
    clearBtnTitle: string = 'Очистить';

    @Input()
    searchBtnTitle: string = 'Поиск';

    @Input()
    cancelBtnTitle: string = 'Отмена';

    @Input()
    saveBtnTitle: string = 'Сохранить';


    @Input()
    maxLength: number = 256;


    @Input()
    label: string;

    @Input()
    error: boolean;

    @Input()
    search: boolean;


    @Input()
    errorContent: string;

    @Input()
    errorArray: string[];


    @Input()
    counter: number;

    @Input()
    counterArray: number[];
    isCounterArrayShow: boolean;

    @Input()
    singleForm: boolean = false;
    haveChenge: boolean = false;

    @Input()
    showCancel: boolean = true;


    @Input()
    type: 'string' | 'number' | 'mail' | 'password' = 'string';


    @Output()
    inputModelChange = new EventEmitter<string>();


    @Output()
    updateData = new EventEmitter<any>();


    @Output()
    ctrlEnter = new EventEmitter<any>();

    @Output()
    escKey = new EventEmitter<any>();


    @Output()
    searchEvent = new EventEmitter<any>();


    @Output()
    enterKey = new EventEmitter<any>();

    @Output()
    confirmBtn = new EventEmitter<any>();

    @Output()
    cancelBtn = new EventEmitter<any>();

    @Output()
    blurInput = new EventEmitter<any>();  


    @ViewChild('templateTextField') el: ElementRef;


    constructor(
        private svgIconsRegistry: SvgIconsRegistry
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon
        ])
    }


    @HostListener('keydown', ['$event'])
    public keyControl(event: KeyboardEvent): void {

        if (event.keyCode == 27) {
            this.escKey.emit();
            return;
        }

        if (event.keyCode == 13 && event.ctrlKey) {
            this.ctrlEnter.emit();
            if (this.singleForm && this.haveChenge) this.confirm();
            return;
        }

        if (event.keyCode == 13) {
            if (this.search) { this.searchClick(); return; }
            this.enterKey.emit();
            return;
        }

        // setTimeout(() => {
        //   this.resizeTextArea();
        // }, 0);
    }


    ngOnInit() {
        if (this.inputModel != undefined && this.inputModel) this.inputModel = this.inputModel.toString();

        if (this.label) {
            this.placeholder = '';
        }
        this.checkPaddingInput();

        if (this.counterArray) {
            this.checkCounterArray();
        }
    }


    // ngAfterViewChecked() {
    //     if (this.focus) {
    //         this.el.nativeElement.focus();
    //     }
    // }

    ngAfterViewInit() {
        if (this.focus) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 0);
        }
    }


    textInputModelChange() {
        this.haveChenge = true;
        this.checkPaddingInput();
        this.inputModelChange.emit(this.inputModel);
        if (this.counterArray) {
            this.checkCounterArray();
        }
    }

    checkCounterArray() {
        this.isCounterArrayShow = Boolean(this.counterArray.find(el => el == this.inputModel.length));
    }


    checkPaddingInput() {
        this.paddingRight = 0;

        if (this.clearble && this.inputModel && this.inputModel.length != 0) { this.paddingRight += 30 } else { this.paddingRight += 0; }
        if (this.counter || this.counterArray) { this.paddingRight += 34 }
        if (this.search) { this.paddingRight += 34 }
    }

    clear() {

        this.inputModel = '';
        this.inputModelChange.emit(this.inputModel);
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 0);
        this.checkPaddingInput();
        if (this.search) { this.searchClick(); return; }
    }

    searchClick() {
        this.searchEvent.emit(this.inputModel);
    }

    confirm() {
        this.confirmBtn.emit(this.inputModel);
    }

    cancel() {
        this.cancelBtn.emit(this.inputModel);
    }


    BlurInput()
    {
        this.blurInput.emit(true);
    }

}

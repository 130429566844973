import { ConfirmDoneIcon, CloseIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet, Common_LangSet } from '../../../models/model.language';
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { CommonWithSelect, CommonWithSelectAndAdditional } from '../../../models/model.commonWithSelect';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { keyBy } from 'lodash';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'select-elements-form',
    templateUrl: './select-elements-form.component.html',
    styleUrls: ['./select-elements-form.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class SelectElementsFormComponent implements OnInit, OnChanges {

    @Input('value')
    value: string = '';
 
    @Input() placeholder: string="поиск";
    @Input() showKeyId: string="showFormId";
    @Input() haveShowButton: boolean = true;
    @Input() multiselect: boolean = true;
    @Input() hideSelected: boolean = false;
    @Input() listTitle: string="Выбор элементов";
    @Output() selectedElements: EventEmitter<any[]> = new EventEmitter<any[]>();
    
    @Input() existedElements: any[]=[];
    @Input() unselectElement: Subject<number>= new Subject<number>();
    @Input() selectElement: Subject<number>= new Subject<number>();
    elementsList: CommonWithSelectAndAdditional[]=[];
    elementsListBuffer: CommonWithSelectAndAdditional[]=[];
    selectedCount: number=0;


    langset: Common_LangSet;
    ln_sub: Subscription=null;
    searchFilter: string="";
    formVisible: boolean=false;
    @ViewChild('SelectElementsFormCotainer', { static: false }) SelectElementsFormCotainerElem: ElementRef;

    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon
        ])
    }


    @HostListener('document:keydown.esc', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
      let elem=<HTMLElement>e.target;
    if(!(elem.nodeName=='INPUT' && elem.parentElement.classList.contains("search_field_container")))
    {
        this.formVisible=false;
    }
  }

  @HostListener('document:click', ['$event'])
              public onClickActive(e: Event): void {

                  

                  if(!this.SelectElementsFormCotainerElem.nativeElement.contains(e.target))
                  {
                    
                    this.formVisible=false;
                  }
                  
              }


    ngOnInit() {
       
        this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>{this.langset=lang;});
        this.searchFilter="";
        this.elementsList=this.existedElements.map(el=>{return new CommonWithSelectAndAdditional(el.Id, el.Name, false, el.Additionals )});
        this.elementsListBuffer=this.existedElements.map(el=>{return new CommonWithSelectAndAdditional(el.Id, el.Name, false, el.Additionals )});
        this.unselectElement.subscribe(id=>{
            let ind=this.elementsList.findIndex(c=>c.Id==id);
            if(ind>-1)
            {
                this.elementsList[ind].Selected=false;
                this.SelectElement(this.elementsList[ind]);
            }
        });

        this.selectElement.subscribe(id=>{
            let ind=this.elementsList.findIndex(c=>c.Id==id);
            if(ind>-1)
            {
                this.elementsList[ind].Selected=true;
                this.SelectElement(this.elementsList[ind]);
            }
        });
    }

    ngOnChanges()
    {

    }
   

    textChanged()
    {
        
    }

    blurInput()
    {
        
    }

    
    SearchInList(val:string){
        this.searchFilter=val;
        this.elementsList = _.cloneDeep(this.elementsListBuffer);
        if (this.searchFilter != null && this.searchFilter != undefined && this.searchFilter != "") {
            this.elementsList = this.elementsList.filter(element => {
                return element.Name!=null && ExtendedHelper.ContainsNoCase(element.Name, this.searchFilter);
            });
            for (let i = 0; i < this.elementsList.length; i++) {
                this.elementsList[i].Name = SearchSelectHelper.getSearchedText(this.searchFilter, this.elementsList[i].Name);
            }
        }
        
    }

    ShowForm()
    {
        if(!this.formVisible)
        {
            this.searchFilter="";
            if(!this.hideSelected)
            {this.elementsList=this.elementsListBuffer.map(el=>el);}
            else
            {this.elementsList=this.elementsListBuffer.filter(el=>!el.Selected);}
            
        }
        this.formVisible=!this.formVisible;
    }

    SelectElement(element: CommonWithSelect)
    {
        if(element.Selected)
        {
            if(this.multiselect)
            {
                let ind=this.elementsListBuffer.findIndex(c=>c.Id==element.Id);
                if(ind!=-1)
                {
                    this.elementsListBuffer[ind].Selected=element.Selected;
                }
            }
            else
            {
                this.elementsList.forEach(el=>{
                    if(el.Id!=element.Id)
                    {
                        el.Selected=false;
                    }
                });
                this.elementsListBuffer.forEach(el=>{
                    if(el.Id!=element.Id)
                    {
                        el.Selected=false;
                    }
                    if(el.Id==element.Id)
                    {
                        el.Selected=true;
                    }
                });
            }
        }
        else
        {
            let ind=this.elementsListBuffer.findIndex(c=>c.Id==element.Id);
                if(ind!=-1)
                {
                    this.elementsListBuffer[ind].Selected=element.Selected;
                }
        }
        this.selectedElements.emit(this.elementsListBuffer.filter(el=>el.Selected));
    }


}

import { Common, CommonSelectable } from "../../../../model.common";
import { StageToCreateFilter } from "../../../../model.projectSelection";
import { AddressAutocompleteChainInner } from "../../../../model.Address/Inner/AddressAutocomplete/model.AddressAutocompleteChainInner";
import { ExtendedHelper } from "../../../../../config/ExtendedHelper";

export class BoolFilter {
  ShowNull: boolean;
  ShowNo: boolean;
  ShowYes: boolean;
}

export class ValueToShow extends Common {
  Show: boolean;
  IsPositive: boolean;
}

export class NumberInstance {
  IsBasic: boolean;
  Position: number;
  Operation: string;//> < = null
  Connector: string;//AND OR
  Value: number;
  IsPositive: boolean;

  ConnectorAndAllowed: boolean;

  constructor(isBasic: boolean, position: number, operation: string, value: number = null) {
    this.IsBasic = isBasic;
    this.Position = position;
    this.Operation = operation;
    this.Connector = 'OR';
    this.Value = value;
    this.IsPositive = true;
    this.ConnectorAndAllowed = this.Operation != 'null';
  }
}

export class ParameterFilterInstanceToShow extends CommonSelectable {
  SelectedType: Common;
  StringFilter: StringInstanceToShow[];
  StringInstanceToAdd: StringInstanceToShow;

  Filtering: boolean;
  BoolFilter: BoolFilter;
  ValueFilter: ValueToShow[];
  NumberFilter: NumberInstance[];

  static RecountNumberConditions(p: ParameterFilterInstanceToShow) {
    if (p.NumberFilter == null) return;
    //console.log("NF passed");
    for (let i = 0; i < p.NumberFilter.length; i++) {
      p.NumberFilter[i].ConnectorAndAllowed = true;
    }
    for (let i = 0; i < p.NumberFilter.length; i++) {
      let filter = p.NumberFilter[i];
      if (filter.Operation == 'null') {
        if (filter.IsPositive) {//если фильтр "не установлен" и он позитивный, то можно сочетать с остальными только через ИЛИ.
          filter.ConnectorAndAllowed = false;
          filter.Connector = 'OR';
          //console.log("True null found");
          if (i < p.NumberFilter.length - 1) {
            //после этого фильтра есть еще фильтр
            let next = p.NumberFilter[i + 1];
            next.ConnectorAndAllowed = false;
            next.Connector = 'OR';
            //console.log("Next to True null found");
          }
        }
        // else {
        //   filter.ConnectorAndAllowed = true;
        //   //console.log("False null found");
        //   if (i < p.NumberFilter.length - 1) {
        //     //после этого фильтра есть еще фильтр
        //     let next = p.NumberFilter[i + 1];
        //     next.ConnectorAndAllowed = true;
        //     //console.log("Next to False null found");
        //   }
        // }
      }
    }
  }
}

export class EntityWithRoles extends Common {
  Roles: Common[];

  // static Create(entity: Common): EntityWithRoles {
  //   let a = new EntityWithRoles();
  //   a.Id = entity.Id;
  //   a.Name = entity.Name;
  //   a.Roles = [];
  //   return a;
  // }
}
export class EntityWithRolesPositive extends EntityWithRoles {
  IsPositive: boolean;
  constructor(e: Common) {
    super();
    if (e != null) {
      this.Id = e.Id;
      this.Name = e.Name;
    }
    this.Roles = [];
    this.IsPositive = true;
  }

  

  static FromChain(chain: AddressAutocompleteChainInner): EntityWithRolesPositive {
    let newObj = new EntityWithRolesPositive(null);
    newObj.Id = chain.SheetId;
    newObj.Name = "";
    for (let i = 0; i < chain.Components.length; i++) {
      if (i > 0 && newObj.Name != "") {
        newObj.Name += ", ";
      }
      let comp = chain.Components[i];
      if (!ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(comp.Value)) {
        newObj.Name += comp.Value;
      }
    }
    return newObj;
  }

}







export class StringInstanceToShow {
  IsPositive: boolean;
  Value: string;
  IsEditing: boolean;
  NewValue: string;

  constructor(val: string = null) {
    if (val != null) {
      this.Value = val;
      this.IsEditing = false;
      this.IsPositive = true;
    }
  }
}

export class StringFilterInstance
        {
            Positive: boolean;
            Value: string;
        }


export class DateTimeInstanceToShow {
  IsPositive: boolean;
  Value: Date;
  FilteringType: number;
  
  constructor(val: Date = null) {
    if (val != null) {
      this.Value = val;
      this.IsPositive = true;
      this.FilteringType=0;
    }
  }
}


enum DateFilteringType {
  Current=0,
  Before=1,
  After=2
}


export class StageFilterInstanceToShow extends Common {
  CheckPeriod: boolean;
  StartDate: Date;
  EndDate: Date;
  IsPositive: boolean;
  constructor(c: StageToCreateFilter) {
    super();
    this.Id = c.Id;
    this.Name = c.Name;
    this.IsPositive = true;
    this.CheckPeriod = c.CheckPeriod;
    if (this.CheckPeriod) {
      this.StartDate = c.StartDate;
      this.EndDate = c.EndDate
    }
  }
}

export class CommonPositive extends Common {
  IsPositive?: boolean;

  constructor(c: Common) {
    super(c.Id, c.Name);
    this.IsPositive = true;
  }
}





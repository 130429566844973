/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import { LocalStorageService } from 'angular-2-local-storage';
import { ClientInProjectFilterWithUnion } from '../../models/model.ClientInProjectFilter';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientLight } from '../../models/model.client';
import { ProjectLight } from '../../models/model.project';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TagHelper } from '../../Helpers/taghelper';
import { ElementWithVisible } from '../../models/model.elementWithVisible';
import * as _ from 'lodash';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { AutoJobTemplateEditAsker, AutoJobTemplateFullContainer, AutoJobTemplateInSettings, MultiIdRequest } from '../../models/model.Autojob/model.AutojobTemplate';
import { LangSetAutojob, LangSetAutoJobGiver } from '../../models/model.autojobLanguage';
import { CommonRequest, CommonResponse } from '../../models/model.http';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { HttpServiceLanguage } from '../../httpserv/httpserv.language';
import { AutojobMultyselectContainer, AutojobMultyselectElement, AutojobMultyselectElementValue, MultyselectContainer, MultyselectElement } from '../../models/model.MultyselectContainer';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { HttpServiceAutojob } from '../../httpserv/httpserv.autojob';
import { CommonWorkerHolder } from '../../models/model.workerAttached';
import { Common } from '../../models/model.common';
import { SessionService } from '../../services/session.service';
import { Common_LangSet } from '../../models/model.language';
import { LanguageService } from '../../services/language.service';


declare var require: any;


@Component({
  selector: 'autojobTemplateMultiselect',
  templateUrl: 'temp.autojobTemplateMultiselect.html',
  styleUrls: ['autojobTemplateMultiselect.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',],

    providers: [HttpServiceLanguage, HttpServiceAutojob],

})
export class autojobTemplateMultiselectComponent implements OnInit {
  
  //@Input() currentTemplate: AutoJobTemplateFullContainer;
 // @Input() currentMultiselect: MultyselectContainer;
  @Input() isMultiselectable: boolean=false;
  @Input() multiselectTitle: string="Выберите вариант";
  @Input() multiselectPlaceholder: string="Выберите вариант";
  @Output() ClearTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() DeleteTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateChangeElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateDeleteElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateAddElement: EventEmitter<any> = new EventEmitter<any>();
  @Input() Holders: CommonWorkerHolder[]=[];
  @Input() Roles: Common[]=[];
  @Input() AdditionalValues: AutojobMultyselectElementValue[]=[];
  @Input() RolesOuterType: number=0;
  @Input() UsersOuterType: number=1;


  @Input() SelectedUsers: Common[]=[];
  @Input() SelectedRoles: Common[]=[];
  
  @Input() SelectedAdditionalValues: AutojobMultyselectElementValue[]=[];
  
 
  currentAnalyticMultiselect: AutojobMultyselectContainer;
  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();
  langsetAutojob: LangSetAutojob = new LangSetAutojob();
  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  UserId: number;
  SessionKey: string;
  entityTypeJob: number = 0;
  listVisible: boolean=false;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private store$: Store,
    private httpServiceLanguage: HttpServiceLanguage,
    private httpServiceAutojob: HttpServiceAutojob,
    private sessionService: SessionService,
    private languageService: LanguageService
  ) { }




  ngOnInit() {
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    //this.AutojobLanguageSetGet();
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.currentAnalyticMultiselect = new AutojobMultyselectContainer();
    this.currentAnalyticMultiselect.Filter="";
    this.currentAnalyticMultiselect.IsMultyselect = this.isMultiselectable;
    this.currentAnalyticMultiselect.Visible=true;
    this.currentAnalyticMultiselect.MultyselectElements=[];
    this.currentAnalyticMultiselect.SelectedElements=[];
    let indexelements=0;

    if(this.AdditionalValues!=undefined && this.AdditionalValues!=null && this.AdditionalValues.length>0)
    {
      for(let i=0; i<this.AdditionalValues.length; i++)
      {
        let elem = new AutojobMultyselectElement();
        elem.IsSelectable=true;
        elem.Id=indexelements;
        indexelements++;
        elem.Visible=true;
        elem.TextToShow = this.AdditionalValues[i].Name;
        elem.ElementType=3;
        elem.Element=this.AdditionalValues[i];
        elem.Focus=false;
        elem.Selected=false;
        elem.IsHolder=false;
        elem.Children=[];
        let selectedInd = this.SelectedAdditionalValues.findIndex(c=>c.Id==this.AdditionalValues[i].Id && c.ValueOuterType==this.AdditionalValues[i].ValueOuterType);
        if(selectedInd>-1)
        {
          elem.Selected=true;
          this.currentAnalyticMultiselect.SelectedElements.push(elem);
        }
        this.currentAnalyticMultiselect.MultyselectElements.push(elem);
      }
    }


    if(this.Roles!=undefined && this.Roles!=null && this.Roles.length>0)
    {
      for(let i=0; i<this.Roles.length; i++)
      {
        let elem = new AutojobMultyselectElement();
        elem.Id=indexelements;
        indexelements++;
        elem.IsSelectable=true;
        elem.Visible=true;
        elem.TextToShow = "#"+this.Roles[i].Name;
        elem.ElementType=0;
        let val = new AutojobMultyselectElementValue();
        val.Id=this.Roles[i].Id;
        val.Name="#"+this.Roles[i].Name;
        val.ValueOuterType=  this.RolesOuterType;
        elem.Element=val;
        elem.Focus=false;
        elem.Selected=false;
        elem.IsHolder=false;
        elem.Children=[];
        let selectedInd = this.SelectedRoles.findIndex(c=>c.Id==this.Roles[i].Id );
        if(selectedInd>-1)
        {
          elem.Selected=true;
          this.currentAnalyticMultiselect.SelectedElements.push(elem);
        }
        this.currentAnalyticMultiselect.MultyselectElements.push(elem);
      }
    }

    if(this.Holders!=undefined && this.Holders!=null && this.Holders.length>0)
    {
      for(let i=0; i<this.Holders.length; i++)
      {
        let elem = new AutojobMultyselectElement();
        if(this.Holders[i].IsWorker)
        {
          elem.IsSelectable=true;
          elem.Id=indexelements;
          indexelements++;
          elem.Visible=true;
          elem.TextToShow = this.Holders[i].Worker.Name;
          elem.ElementType=1;
          let val = new AutojobMultyselectElementValue();
          val.Id=this.Holders[i].Worker.Id;
          val.Name=this.Holders[i].Worker.Name;
          val.ValueOuterType=  this.UsersOuterType;
          elem.Element=val;
          elem.Focus=false;
          elem.Selected=false;
          elem.IsHolder=false;
          elem.Children=[];
          let selectedInd = this.SelectedUsers.findIndex(c=>c.Id==this.Holders[i].Worker.Id );
          if(selectedInd>-1)
          {
            elem.Selected=true;
            this.currentAnalyticMultiselect.SelectedElements.push(elem);
          }
          this.currentAnalyticMultiselect.MultyselectElements.push(elem);
        }

        if(!this.Holders[i].IsWorker)
        {
          elem.IsSelectable=false;
          elem.Id=indexelements;
          indexelements++;
          elem.Visible=true;
          elem.TextToShow = this.Holders[i].Departament.Name;
          elem.ElementType=2;
          let val = new AutojobMultyselectElementValue();
          elem.Element=null;
          elem.Focus=false;
          elem.Selected=false;
          elem.IsHolder=true;
          elem.Children=[];
          this.currentAnalyticMultiselect.MultyselectElements.push(elem);
          if(this.Holders[i].Departament.Workers!=undefined && this.Holders[i].Departament.Workers!=null  && this.Holders[i].Departament.Workers.length>0)
          {
            for(let j=0; j<this.Holders[i].Departament.Workers.length; j++)
            {
              let elemInner = new AutojobMultyselectElement();
              elemInner.Id=indexelements;
              indexelements++;
              elemInner.IsSelectable=true;
              elemInner.Visible=true;
              elemInner.TextToShow = this.Holders[i].Departament.Workers[j].Name;
              elemInner.ElementType=1;
              let val = new AutojobMultyselectElementValue();
              val.Id=this.Holders[i].Departament.Workers[j].Id;
              val.Name=this.Holders[i].Departament.Workers[j].Name;
              val.ValueOuterType= this.UsersOuterType;
              elemInner.Element=val;
              elemInner.Focus=false;
              elemInner.Selected=false;
              elemInner.IsHolder=false;
              elemInner.Children=[];
              let selectedInd = this.SelectedUsers.findIndex(c=>c.Id==this.Holders[i].Departament.Workers[j].Id );
              if(selectedInd>-1)
              {
                elemInner.Selected=true;
                this.currentAnalyticMultiselect.SelectedElements.push(elemInner);
              }
              elem.Children.push(elemInner);
            }
          }
          
        }

        
      }
    }

    
  }

  close() {

  }


 


  
  
   

    
 
    ChangeElementSelected(elementCh: AutojobMultyselectElement)
    {
      if(!elementCh.Selected)
      {
        this.AddElement(elementCh);
      }
      else
      {
        if(this.isMultiselectable)
        {
          this.DeleteElement(elementCh);
        }
      }
    }

    DeleteElement(  elementCh: AutojobMultyselectElement)
    {
      elementCh.Selected = false;
      this.currentAnalyticMultiselect.UnselectElement(elementCh);
      let delegate = [elementCh.Element.ValueOuterType, elementCh.Element.Id];
      this.TemplateDeleteElement.emit(delegate);
    }

    AddElement( elementCh: AutojobMultyselectElement)
    {
      if(!this.isMultiselectable)
      {
        this.currentAnalyticMultiselect.UnselectAll();
      }
      this.currentAnalyticMultiselect.SelectElement(elementCh);
      let delegate = [elementCh.Element.ValueOuterType, elementCh.Element.Id];
      this.TemplateAddElement.emit(delegate);
    }


    ChangeListVisible()
    {
      if(this.listVisible)
      {
        this.listVisible=false;
      }
      else
      {
        this.currentAnalyticMultiselect.ShowAllElements();
        this.currentAnalyticMultiselect.Filter="";
        this.listVisible=true;
      }
    }
  
}

export class JobStatusEnum {
  System: number;
  Working: number;
  Checking: number;
  Completed: number;
  Lost:number;

  constructor() {
    this.System = 0;
    this.Working = 1;
    this.Checking = 2;
    this.Completed = 3;
    this.Lost = 4;
  }
}

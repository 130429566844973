import { TextFieldComponent } from './../../shared/components/text-field/text-field.component';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { PersonInList } from '../../models/model.Person/In/model.PersonInList';
import { SingleIdRequest, CommonResponse } from '../../models/model.http';
import { HttpServicePerson } from '../../httpserv/httpserv.person';
import { PersonInfoGiver, PersonInfo, PersonInListInner } from '../../models/model.Person/Inner/model.PersonInListInner';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { CommonListGiverClients, CommonListGiver } from '../../models/model.CommonListGiver';
import { ClientListPossibleAsker } from '../../models/model.Client/Out/model.ClientListPossibleAsker';
import { DOMHelper } from '../../Helpers/DOMHelper';
import { ClientsToAddContainer } from '../../models/model.Client/Inner/model.ClientsToAddContainer';
import { ClientToAddTypeEnum } from '../../models/model.Client/Inner/model.ClientToAddTypeEnum';
import { CommonSelectable } from '../../models/model.common';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientAttachAsker } from '../../models/model.Client/Out/model.ClientAttachAsker';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { PersonMakeMainAsker } from '../../models/model.Person/Out/model.PersonMakeMainAsker';
import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { DeleteSession } from '../../store/actions/session.action';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { Common_LangSet, Contacts_LangSet } from '../../models/model.language';

@Component({
  selector: 'Person-item',
  templateUrl: './person-item.component.html',
  styleUrls: ['./person-item.component.scss',
    '../../../style/font-awesome.min.scss',
    '../../../style/supportLibrary.scss',
    '../../../style/control-inputs.scss',
  ],
})
export class PersonItemComponent implements OnInit {
  UserId: number;
  UserName: string;
  SessionKey: string;
  PersonInfoShowDialog: boolean = false;
  personInfoLoading: boolean;
  PersonInfo: PersonInfo;

  clientsToAddContainer: ClientsToAddContainer;
  clientToAddType: ClientToAddTypeEnum = new ClientToAddTypeEnum();
  clientsToAddDialogShow: boolean = false;
  clientsToAddLoading: boolean = false;


  

  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();


  @ViewChild('clientsToAdd') clientsToAdd: TextFieldComponent;


  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  contacts_Langset: Contacts_LangSet;
  cn_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  constructor(
    private store$: Store,
    private router: Router,
    private httpServicePerson: HttpServicePerson,
    private localStorageService: LocalStorageService,
    private sessionService: SessionService,
    private languageService: LanguageService
  ) { }

  @Input("person") person: PersonInList;
  @Input("clientId") clientId: number;
  @Input("projectId") projectId: number;
  @Input("personMain") personMain: boolean = false;
  // @Input('context') context: number;

  @Output() deletePersons: EventEmitter<any> = new EventEmitter();
  @Output() editPerson: EventEmitter<any> = new EventEmitter();
  @Output() updateAll: EventEmitter<any> = new EventEmitter();
  @Output() runPersonLoading: EventEmitter<any> = new EventEmitter();
  @Output() stopPersonLoading: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.cn_sub=this.languageService.contactsLanguage$.subscribe(lang=>this.contacts_Langset=lang);
   
  }


 


  @HostListener('document:keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    switch (e.keyCode) {
      case 27: {//ESCAPE
        if (this.PersonInfoShowDialog) {
          this.PersonInfoShowDialog = false;
        }
        if (this.clientsToAddDialogShow) {
          this.clientsToAddDialogShow = false;
        }
        event.stopPropagation();
        break;
      }
    }
  }



  KeyController() {

  }

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }



  OnClientsToAddFilterChanged() {
    if (this.clientsToAddContainer == null) return;
    this.RefreshClientsToAddToPerson();
  }

  AddClientToPerson(clientId: number, event: any = null) {
    if (event != null && event.button == 0 && !event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.clientsToAddContainer.IsAdding) return;
    this.clientsToAddContainer.IsAdding = true;
    let personId = this.clientsToAddContainer.EntityId;
    let checker = new ClientAttachAsker(this.UserId, this.SessionKey, personId, clientId);
    this.httpServicePerson.attachClient(checker).subscribe((data: CommonListGiver) => {
      this.clientsToAddContainer.IsAdding = false;
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 200) {
        alert(data.Message);
        this.clientsToAddDialogShow = false;
        this.clientsToAddContainer = null;
        return;
      }
      if (data.Code == 201) {
        alert(data.Message);
        // let personIndex = this.personList.findIndex(c => c.Id == personId);
        // if (personIndex != -1) this.personList.splice(personIndex, 1);
        return;
      }
      if (data.Code == 300) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      this.clientsToAddDialogShow = false;
      this.clientsToAddContainer = null;

      this.person.Clients = data.List;
      //todo обновление всего клиента
    });
  }

  RefreshClientsToAddToPerson() {
    this.clientsToAddLoading = true;
    let queryNum = this.clientsToAddContainer.StartRefreshing();
    let checker = new ClientListPossibleAsker(this.UserId, this.SessionKey, this.clientsToAddContainer.EntityId, this.clientsToAddContainer.Filter);
    this.httpServicePerson.loadPossibleClientsToAttach(checker).subscribe((data: CommonListGiverClients) => {
    this.clientsToAddLoading = false;
    if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      if (queryNum != this.clientsToAddContainer.RequestCounter) return;
      this.clientsToAddContainer.ReplaceClients(data.Clients);
      if (this.clientsToAddContainer.Filter != "") {
        this.clientsToAddContainer.Clients.forEach(element => {
          element.Name = SearchSelectHelper.getSearchedText(this.clientsToAddContainer.Filter, element.Name);
        });
      }
    });
  }

  PersonGetInfo(id: number) {
    this.PersonInfoShowDialog = true;
    this.personInfoLoading = true;
    let checker = new SingleIdRequest(this.UserId, this.SessionKey, id);
    this.httpServicePerson.PersonGetInfo(checker).subscribe((data: PersonInfoGiver) => {
      this.personInfoLoading = false;
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300) {
        console.error(data.Message);
        return;
      }
      if (data.Code == 301) {
        alert("Нет прав на просмотр данного контакта");
        console.error(data.Message);
        return;
      }
      if (data.Code == 302) {
        console.error(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.PersonInfo = new PersonInfo();
      this.PersonInfo = data.Info;
      if (this.PersonInfo.Address != null) {
        let result: string = '';
        for (let i = 0; i < this.PersonInfo.Address.length; i++) {
          const element = this.PersonInfo.Address[i];
          if (i == 0) {
            result = result + element;
          } else {
            result = result + ", " + element;
          }
        }
        this.PersonInfo.AddressString = result;
      }
      for (let i = 0; i < this.PersonInfo.Changelog.length; i++) {
        let mass = [];
        const element = this.PersonInfo.Changelog[i];
        if (element.NameChanged) {
          mass.push('Имя контакта');
        }
        if (element.PostChanged) {
          mass.push('Должность контакта');
        }
        if (element.ContactsChanged) {
          mass.push('Контактная информация о контакте');
        }
        if (element.AddressChanged) {
          mass.push('Адрес контакта');
        }
        if (element.TagsChanged) {
          mass.push('Теги контакта');
        }
        if (element.ClientsChanged) {
          mass.push('Прикрепленные клиенты к контакту');
        }
        element.ResultMessage = mass.map((elem, index) => {
          return (index != 0) ? elem.toLowerCase() : elem;
        }).join(', ');
      }
    });
  }

  replaceTagsPerson(person: PersonInListInner) {
    let tags = new RegExp(/<[^>]+>/, "gi");
    person.Name = person.Name.replace(tags, '');
    person.Contacts = person.Contacts.replace(tags, '');
    person.Post = person.Post.replace(tags, '');
    if (person.AddressChain != null) {
      for (let i = 0; i < person.AddressChain.length; i++) {
        person.AddressChain[i].Name = person.AddressChain[i].Name.replace(tags, '');
      }
    }
  }

  PersonEditShowDialog(person: PersonInListInner) {
    this.editPerson.emit(person);
  }

  deletePerson(personId: number) {
    if (confirm(this.contacts_Langset.PersonRemoveConfirm)) {
      let checker = new SingleIdRequest(this.UserId, this.SessionKey, personId);
      this.httpServicePerson.delete_(checker).subscribe((data: CommonResponse) => {
        if (data.Code == 100) {
          this.unLogin(data.Message);
          return;
        }
        if (data.Code == 300) {
          console.log(data.Message);
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }
        this.deletePersons.emit(personId);
      });
    }
  }

  MakePersonMain(personId: number) {
    this.runPersonLoading.emit();
    let checker = new PersonMakeMainAsker(this.UserId, this.SessionKey, this.projectId, personId);
    this.httpServicePerson.makeMain(checker).subscribe((data: CommonResponse) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 500) {
        console.error(data.Message);
        this.updateAll.emit();
        return;
      }
      this.stopPersonLoading.emit();
      this.updateAll.emit();
    });
  }

  LoadClientsToAddToPerson(personId: number) {
    this.clientsToAddContainer = new ClientsToAddContainer([], this.clientToAddType.Person, personId);
    this.clientsToAddDialogShow = true;
    this.clientsToAddLoading = true;
    let checker = new ClientListPossibleAsker(this.UserId, this.SessionKey, personId, null);
    this.httpServicePerson.loadPossibleClientsToAttach(checker).subscribe((data: CommonListGiverClients) => {
      this.clientsToAddLoading = false;
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      this.clientsToAddContainer.SetClients(data.Clients);
      this.ClientsToAddPersonScrollSubscribe();
    });
  }

  ClientsToAddPersonScrollSubscribe() {
    if (this.clientsToAddDialogShow) {
      let trackerClientToAddList = document.getElementById('clientToAddPersonScrollTracker');
      let windowYOffsetObservable = observableFromEvent(trackerClientToAddList, 'scroll').pipe(map(() => {
        return trackerClientToAddList.scrollTop;
      }));
      let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
        let limit = trackerClientToAddList.scrollHeight - trackerClientToAddList.clientHeight;
        if (((scrollPos === limit) || (scrollPos > limit - 1))) {
          this.LoadClientsToAddToPersonAdditional(this.person.Id);
        }
      });
    }
  }

  LoadClientsToAddToPersonAdditional(personId: number) {
    let checker = new ClientListPossibleAsker(this.UserId, this.SessionKey, personId, this.clientsToAddContainer.Filter, 50, this.clientsToAddContainer.Clients.length);
    this.httpServicePerson.loadPossibleClientsToAttach(checker).subscribe((data: CommonListGiverClients) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      this.clientsToAddContainer.Clients = this.clientsToAddContainer.Clients.concat(data.Clients.map(c => {
        if (this.clientsToAddContainer.Filter != "" && this.clientsToAddContainer.Filter != null && this.clientsToAddContainer.Filter != undefined) {
          return new CommonSelectable(c.Id, SearchSelectHelper.getSearchedText(this.clientsToAddContainer.Filter, c.Name));
        }
        else {
          return new CommonSelectable(c.Id, c.Name);
        }
      }));
      //  this.clientsToAddDialogShow = true;
    });
  }

  goToClient(Id: number, event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/client/', Id];
      this.router.navigate(link);
      // this.LoadFullClient(isNew);

    }
  }

  deleteClientFromPerson(clientId: number) {
    if (!confirm(this.langset.RemovePersonFromClientConfirm)) return;
    let checker = new ClientAttachAsker(this.UserId, this.SessionKey, this.person.Id, clientId);
    this.httpServicePerson.unfastenClient(checker).subscribe((data: CommonListGiver) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 200) {
        alert(data.Message);
        return;
      }
      if (data.Code == 201) {
        alert(data.Message);
        // let personIndex = this.personList.findIndex(c => c.Id == person.Id);
        // if (personIndex != -1) this.personList.splice(personIndex, 1);
        return;
      }
      if (data.Code == 300) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.person.Clients = data.List;

      //todo: - Функция обновления персоны в других группах
    });
  }

}

import {AddressAutocompleteChainInner} from "./model.AddressAutocompleteChainInner";
import {AddressAutocompleteListGiver} from "../../In/Any/Autocomplete/model.AddressAutocompleteListGiver";
import {DOMHelper} from "../../../../Helpers/DOMHelper";

export class AddressAutocompleteContainer
{
  Chains:AddressAutocompleteChainInner[];

  constructor(c:AddressAutocompleteListGiver)
  {
    this.Chains = c.Chains.map(cc=> new AddressAutocompleteChainInner(cc));
  }

  Clear()
  {
    this.Chains = [];
  }

  Refill(c:AddressAutocompleteListGiver)
  {
    this.Chains = c.Chains.map(cc=> new AddressAutocompleteChainInner(cc));
  }

  GoDownInContainer(chainElementId:string, chainListId:string) {
    if (this.Chains.length == 0) return;
    let index = this.FindSelectedIndex();
    if (index == -1) {
      this.Chains[0].Selected = true;
    }
    else {
      if (index + 1 == this.Chains.length) return;
      this.Chains[index].Selected = false;
      this.Chains[index + 1].Selected = true;
      let visible = this.IsChainVisible(chainElementId, this.Chains[index + 1].SheetId, chainListId);
      if (!visible)
        DOMHelper.ScrollElementToView(chainElementId + this.Chains[index + 1].SheetId, 20);
    }
  }

  GoUpInContainer(chainElementId:string, chainListId:string) {
    if (this.Chains.length == 0) return;
    let index = this.FindSelectedIndex();
    if (index != -1) {
      this.Chains[index].Selected = false;
      if (index > 0) {
        this.Chains[index - 1].Selected = true;
        let visible = this.IsChainVisible(chainElementId, this.Chains[index - 1].SheetId, chainListId);
        if (!visible)
          DOMHelper.ScrollElementToView(chainElementId + this.Chains[index - 1].SheetId, 20);
      }
    }
  }

  FindSelectedIndex():number
  {
    return this.Chains.findIndex(c=>c.Selected);
  }

  FindSelectedChain():AddressAutocompleteChainInner
  {
    return this.Chains.find(c=>c.Selected);
  }

  IsChainVisible(chainElementId:string, sheetId:number, chainListId:string):boolean {
    let chainId = chainElementId + sheetId;
    let chain = document.getElementById(chainId);
    let list = document.getElementById(chainListId);
    if (chain == null || list == null) return true;
    let chainCoordinates = chain.getBoundingClientRect();
    let listCoordinates = list.getBoundingClientRect();


    let visible = chainCoordinates.top >= listCoordinates.top
    && chainCoordinates.bottom < listCoordinates.bottom;

    return visible;
  }

}

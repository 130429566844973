import { Common } from "./model.common";
import { Deleting } from "./model.deleting";
import { ParentChecker } from "./model.parentChecker";
import { AdditionToJobOut } from "./model.job";
import { CommonResponse, SingleIdRequest } from './model.http';
/**
 * Created by MosNeuro on 28.04.2017.
 */

export class NotificationsToBack {
  Id: number;
  JobNotifications: JobNotification[] = [];
  ChatNotifications: ChatNotification[] = [];
  PossibleFilters: Common[];
}

export class JobNotificationsGiver extends CommonResponse {
  JobNotifications: JobNotification[];
  PossibleFilters: Common[];
}

export class ChatNotificationsGiver extends CommonResponse {
  ChatNotifications: ChatNotification[];
}


export class NotificationContainer {
  BackJobNotifications: JobNotification[];
  RelevantJobNotifications: JobNotification[];
  IrrelevantJobNotifications: JobNotification[];

  ChatNotifications: ChatNotification[];

  SelectedFilter: number;
  PossibleFilters: Common[];

  constructor(n: NotificationsToBack) {
    this.BackJobNotifications = n.JobNotifications;
    this.ChatNotifications = n.ChatNotifications;
    this.PossibleFilters = n.PossibleFilters;
    this.SelectedFilter = this.PossibleFilters[0].Id;
    this.Sort();
  }

  Sort() {
    //this.RelevantJobNotifications = this.BackJobNotifications.filter(c => c.Range <= this.SelectedFilter);
    //this.IrrelevantJobNotifications = this.BackJobNotifications.filter(c => c.Range > this.SelectedFilter);
    this.RelevantJobNotifications = [];
    this.IrrelevantJobNotifications = [];
    for (let i = 0; i < this.BackJobNotifications.length; i++) {
      let n = this.BackJobNotifications[i];
      if (this.SelectedFilter >= n.Range) this.RelevantJobNotifications.push(n);
      else this.IrrelevantJobNotifications.push(n);
    }

    //console.log("sorted");
  }

  ClearListFromJob(notificationId: number) {
    let index = this.BackJobNotifications.findIndex(c => c.Id == notificationId);
    if (index != -1) this.BackJobNotifications.splice(index, 1);
    this.Sort();
    // index = this.RelevantJobNotifications.findIndex(c => c.Id == notificationId);
    // if (index != -1) {
    //   this.RelevantJobNotifications.splice(index, 1);
    // }
    // else {
    //   index = this.IrrelevantJobNotifications.findIndex(c => c.Id == notificationId);
    //   if (index != -1) this.IrrelevantJobNotifications.splice(index, 1);
    // }
  }

  ClearListFromChat(notificationId: number) {
    let index = this.ChatNotifications.findIndex(c => c.ChatId == notificationId);
    if (index != -1) this.ChatNotifications.splice(index, 1);
  }

  ClearJobList() {
    this.BackJobNotifications = [];
    this.RelevantJobNotifications = [];
    this.IrrelevantJobNotifications = [];
  }

  ClearChatList() {
    this.ChatNotifications = [];
  }

}

export class NotificationJobContainer {
  BackJobNotifications: JobNotification[];
  RelevantJobNotifications: JobNotification[];
  RelevantLoading: boolean = false;
  IrrelevantJobNotifications: JobNotification[];
  IrrelevantLoading: boolean = false;
  SelectedFilter: number;
  PossibleFilters: Common[];

  constructor(n: JobNotificationsGiver) {
    this.BackJobNotifications = n.JobNotifications;
    this.PossibleFilters = n.PossibleFilters;
    this.SelectedFilter = this.PossibleFilters[0].Id;
    this.RelevantLoading = false;
    this.IrrelevantLoading = false;

    this.Sort();
  }

  ChangeLoadingIrrelevant(visible: boolean) {
    this.IrrelevantLoading = visible;
  }
  ChangeLoadingRelevant(visible: boolean) {
    this.RelevantLoading = visible;
  }

  Sort() {
    this.RelevantJobNotifications = [];
    this.IrrelevantJobNotifications = [];
    for (let i = 0; i < this.BackJobNotifications.length; i++) {
      let n = this.BackJobNotifications[i];
      if (this.SelectedFilter >= n.Range) this.RelevantJobNotifications.push(n);
      else this.IrrelevantJobNotifications.push(n);
    }

  }

  ClearListFromJob(notificationId: number) {
    let index = this.BackJobNotifications.findIndex(c => c.Id == notificationId);
    if (index != -1) this.BackJobNotifications.splice(index, 1);
    this.Sort();

  }

  ClearJobList() {
    this.BackJobNotifications = [];
    this.RelevantJobNotifications = [];
    this.IrrelevantJobNotifications = [];
  }

}


export class NotificationChatContainer {
  ChatNotifications: ChatNotification[];

  constructor(n: ChatNotificationsGiver) {
    this.ChatNotifications = n.ChatNotifications;
  }


  ClearListFromChat(notificationId: number) {
    let index = this.ChatNotifications.findIndex(c => c.ChatId == notificationId);
    if (index != -1) this.ChatNotifications.splice(index, 1);
  }


  ClearChatList() {
    this.ChatNotifications = [];
  }

}

export class JobNotification {
  Id: number;
  JobId: number;
  Creator: Common;
  Worker: Common;
  ToDoDate: Date;
  CreateTime: Date;
  Message: string;
  Clients: Common[];
  Project: Common;
  Lost: boolean;
  Status: number;
  NeedChecking: boolean;
  ClientsExpanded: boolean;
  Range: number;
  Additions: AdditionToJobOut[];

  static ExpandClients(job: JobNotification) {
    job.ClientsExpanded = true;
  }
}
export class ChatNotification {
  Name: string;
  LastUpdated: Date;
  ChatId: number;
  IsChatPersonal: boolean;
  LastMessage: string;
  Count: number;

  ChatPhotoLink: string;
  ChatPhotoLiter: string;

  LastMessagePhotoLink: string;
  LastMessagePhotoLiter: string;
  IsMessageSystem: boolean;

}

export class NotificationCloseAsker extends SingleIdRequest {
  Type: number; //0 job 1 message
  constructor(changerId: number, sessionKey: string, notificationId: number, type: number) {
    super(changerId, sessionKey, notificationId);
    this.Type = type;
  }
}

export class NotificationAsker extends ParentChecker {
  NotificationsAskingType: number;//0 small 1 full
}

export class NotificationCounter {
  Id: number;
  MessageCount: number = 0;
  JobCount: number = 0;

  constructor(giver: NotificationsCountGiver = null) {
    if (giver != null) {
      this.MessageCount = giver.MessageCount;
      this.JobCount = giver.JobCount;
    }
  }
}

export class NotificationsCountGiver extends CommonResponse {
  JobCount: number;
  MessageCount: number;
}


export class NewMessageAlertGiver {
  Id: number;
  LastMessageDate: Date;
}

export class NewMessageAlertAsker extends ParentChecker {
  LastMessageDate: Date;
}


import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ServerString } from "../config/serverstring";
import { LogErrorAsker } from "../models/model.Service/Out/model.LogErrorAsker";

@Injectable()
export class HttpServiceService {

  private route: string = "service/";
  constructor(private http: HttpClient,
    private param: ServerString) {
  }

  logError(obj: LogErrorAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'LogServerError', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }
}

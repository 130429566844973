import {AddressComponentInPerson} from "../In/model.AddressComponentInPerson";

export class AddressComponentInPersonInner {
  Level: number;
  Name: string;
  InBriefDisplay: boolean;

  constructor(a:AddressComponentInPerson)
  {
    this.Level = a.Level;
    this.Name = a.Name;
    this.InBriefDisplay = a.InBriefDisplay;
  }
}

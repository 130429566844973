import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from './services/language.service';
import { SessionService } from './services/session.service';
import { SignalRService } from './services/signalRService';

import {Location} from '@angular/common';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styles: [
        `:host {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }`
    ]

})
export class AppComponent  {
  s_sc: Subscription = null;

  constructor(
    public sessionService: SessionService,
    public signalRService: SignalRService,
    public languageService: LanguageService, public router: Router) 
    {
      this.s_sc=sessionService.isSessionChecked$.subscribe(flag=>{
        if(flag)
        {
          languageService.InitLanguages();
        }
      });

      sessionService.CheckSessionInit();
      this.signalRService.startConnection();
      this.signalRService.addDataListener();
    }
    
  
  
}

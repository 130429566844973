/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { Common } from '../../../models/model.common';
import { ParameterInstance, ParameterInstanceAddAsker, ParameterInstanceContainerGiver, ParameterInstanceEditAsker, ParameterValueContainerGiver } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { ParameterInstanceContainerFilled, ParameterInstanceContainerFilledFull, ParameterInstanceCreateContainer, ParameterInstanceCreateContainerFilled, StorageItemNameInstanceContainer } from '../../../models/model.storage';


declare var require: any;


@Component({
    selector: 'parameterInstanceShowContainer',
    templateUrl: 'temp.parameterInstanceShowContainer.html',
    styleUrls: ['parameterInstanceShowContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceShowContainerComponent implements OnInit, AfterViewInit {
    
    

  @Input() Container: StorageItemNameInstanceContainer;
  @Input() editable: boolean=true;
  @Input() canBeList: boolean=true;
  @Input() titleText: string="Наименование";
  @Input() showRecordTitle: boolean=true;
  @Input() showOuterBorder: boolean=true;
  @Input() type: number=0;
  loadingContainer:boolean=true; 

  @Input() lvl: number=0;

  @Input() needIndType: boolean=true;

  @Input() firstlvl: boolean=true;


  filledRecordEmpty:any;

  PossibleValueSetsIds:number[]=[];

  
    /*@Input() value?: boolean;
    @Input() valuesList: ParameterInstance[]=[];
    @Output() valueChange:  EventEmitter<any> = new EventEmitter<any>();
    @Output() valuesListChange:  EventEmitter<ParameterInstance[]> = new EventEmitter<ParameterInstance[]>();
    @Output() deleteParameter:  EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isList: boolean=false;
    @Input() Name: string="Parameter Name";
    @Input() CanDelete: boolean = true;
    @Input() id: number=-1;
    
    @Input() canRemove: boolean=true;*/
    
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;

    @Output() SelectedFieldFromHint: EventEmitter<any[]> = new EventEmitter<any[]>();
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private sessionService: SessionService,
    private router: Router
              ) { }

            
              ngOnDestroy() {
               
              }

              

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                if(!this.firstlvl)
                {
                  this.lvl=this.lvl+1;
                }
                
              
           }

              ngAfterViewInit() 
              {
                
                
                
              }


             


            unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }
              
            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }





}

/**
 * Created by MosNeuro on 11.09.2017.
 */

import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie";
import { CookieOptionsMy } from "./CookieOptionsMy";
import { DateExtended } from "../models/model.date";
import { IMyDate } from 'mydatepicker';


@Injectable()
export class ExtendedHelper {
  constructor(private cookieService: CookieService,
    private cookieOptions: CookieOptionsMy) {
  }

  // PageIdCookieName = "PageId";
  // MaxPageIdCookieName = "MaxPageId";

  static ReplaceHtmlTags(string: string): string {
    let separator = new RegExp(/<[^>]+>/, "gi");
    string = string.replace(separator, '');
    return string;
  }

  static ReplaceHtmlTagsWithSpace(string: string): string {
    let separator1 = new RegExp(/<div>/, "gi");
    string = string.replace(separator1, '\\n');
    
    let separator2 = new RegExp(/<p>/, "gi");
    string = string.replace(separator2, '\\n');
    
    let separator = new RegExp(/<[^>]+>/, "gi");
    string = string.replace(separator, '');
    return string;
  }

  static ReplaceBadThings(str: string): string {
    return (str != null) ? str.replace(/\\/g, "\\\\").replace(/\"/g, "\\\"").replace(/\'/g, "`") : "";
  }

  ReplaceBadThings(str: string): string {
    return (str != null) ? str.replace(/\\/g, "\\\\").replace(/\"/g, "\\\"").replace(/\'/g, "`") : "";
  }

  static DeleteAnySpaces(str: string): string {
    return (str != null) ? str.replace(/\s{2,}/g, " ").replace(/([.!?]+)(?=\S)/g, "$1 ").replace(/(^\s*)|(\s*)$/g, '') : "";
  }

  DeleteAnySpaces(str: string): string {
    return (str != null) ? str.replace(/\s{2,}/g, " ").replace(/([.!?]+)(?=\S)/g, "$1 ").replace(/(^\s*)|(\s*)$/g, '') : "";
  }

  ReplaceAll(stringObj: string, toReplace: string, replaceWith: string): string {
    while (stringObj.indexOf(toReplace) !== 1) {
      stringObj = stringObj.replace(toReplace, replaceWith);
    }
    return stringObj;
  }

  GetLastAlertQueryTime(): Date {
    var str = this.cookieService.get("LastAlertQueryTime");
    if (str != null && str != "") {
      return new Date(str);
    }
    var date = new Date();
    this.cookieService.put("LastAlertQueryTime", date.toString(), this.cookieOptions.get());
    return date;
  }

  SetLastAlertQueryTime() {
    var date = new Date();
    this.cookieService.put("LastAlertQueryTime", date.toString(), this.cookieOptions.get());
  }


  GetLastMessageTime(): Date {
    var str = this.cookieService.get("LastMessageTime");
    if (str != null && str != "") {
      return new Date(str);
    }
    return new Date();
  }

  SetLastMessageTime(date: Date) {
    this.cookieService.put("LastMessageTime", date.toString(), this.cookieOptions.get());
  }

  ResetLastMessageTime() {
    var dateNow = new Date();
    var dateToResetFrom = new Date(dateNow.getTime() - 10 * 1000);//это на 10 секунд в прошлое. если время с кук меньше чем это переписываем
    var str = this.cookieService.get("LastMessageTime");
    if (str != null && str != "") {
      var dateFromCookie = new Date(str);
      if (dateFromCookie > dateToResetFrom)
        return;
    }
    this.cookieService.put("LastMessageTime", dateNow.toString(), this.cookieOptions.get());
  }

  alertDate(date: Date, dateName: string) {
    alert(dateName + ": " + date.getFullYear() + "/" + date.getMonth() + "/" + date.getDate() + "  " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
  }

  public static IsNullOrEmpty(str: string): boolean {
    return (str == null || str == "");
  }

  public static IsNullOrEmptyOrContainsOnlySeparators(str: string): boolean {
    if (str == null) return true;
    let str2 = str.replace(/\n/g, "").replace(/\r/g, "").replace(/ /g, "");
    return this.IsNullOrEmpty(str2);
  }

  public static isNumber(obj): boolean {
    return !isNaN(obj);
  }

  public static ContainsNoCase(containerString: string, foundingString: string) {
    containerString = containerString.replace(/[ё]/gi,"е");
    foundingString = foundingString.replace(/[ё]/gi,"е");
    let filters=foundingString.split(" ");
    let contain=false;
    //console.log(filters)
    filters.forEach(c=>{
      if(c!=null && c!='')
      {
        if(containerString.toLowerCase().indexOf(c.toLowerCase()) != -1)
        {
          contain=true;
        }
      }
      
    })
    return contain;
    //return (containerString.toLowerCase().indexOf(foundingString.toLowerCase()) != -1);
  }



  public static ContainsNoCaseStrict(containerString: string, foundingString: string) {
    containerString = containerString.replace(/[ё]/gi,"е");
    foundingString = foundingString.replace(/[ё]/gi,"е");
    let filters=foundingString.split(" ");
    let contain=true;
    //console.log(filters)
    filters.forEach(c=>{
      if(c!=null && c!='')
      {
        if(containerString.toLowerCase().indexOf(c.toLowerCase()) == -1)
        {
          contain=false;
        }
        
      }
      
    })
   
    return contain;
    //return (containerString.toLowerCase().indexOf(foundingString.toLowerCase()) != -1);
  }

  public static EqualsNoCase(first: string, second: string) {
    return first.toLowerCase().localeCompare(second.toLowerCase()) == 0;
  }

  public static ToDate(dateObj: IMyDate): Date {
    if (dateObj != null) {
        let date = new Date();
        date.setFullYear(dateObj.year, dateObj.month - 1, dateObj.day);
        return date;
    } else {
      return null;
    }
  }

}

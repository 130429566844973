
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 28.04.2017.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';




import {NotificationCloseAsker, NotificationAsker, NewMessageAlertAsker} from "../models/model.notification";
import {CommonRequest} from '../models/model.http';

@Injectable()
export class HttpServiceNotifications {

    constructor(private http: HttpClient, private param: ServerString) {
    }


    closeNotification(obj: NotificationCloseAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'notifications/close', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));

    }


  askIfNeedToAlert(obj: NewMessageAlertAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessageNewAskForAlert', body, {headers: headers, withCredentials: true}).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));

  }

  getNotificationsCount(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'notifications/count', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getJobNotifications(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'notifications/job', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getChatNotifications(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'notifications/chat', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


}

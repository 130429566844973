import { HeaderComponent } from './containers/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ServerString } from "./config/serverstring";
import { SoundService } from "./config/SoundService";
import { APP_INITIALIZER, ErrorHandler,NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { LocalStorageModule } from 'angular-2-local-storage';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './store/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { sessionReducer } from './store/reducers/session.reducer';
import { SessionEffects } from './store/effects/session.effects';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { SignalRService } from './services/signalRService';
import { LanguageService } from './services/language.service';
import { SessionService } from './services/session.service';
import { TransportService } from './services/transport.service';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    CookieModule.forRoot(),
    LocalStorageModule.forRoot({
      prefix: 'my-app',
      storageType: 'localStorage'
    }),
    // StoreModule.forRoot(appReducer)
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([SessionEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
  ],
  declarations: [AppComponent, HeaderComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  /*  {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },*/
    ServerString, SoundService, SignalRService,  SessionService, LanguageService, TransportService]
})

export class AppModule { }

import { IUser } from './../../models/model.worker';

export interface IUserState {
    currectUser: IUser;
}


export const initialUserState: IUserState = {
    currectUser: null,
}

import { Component, OnInit, Input, Output, EventEmitter, ElementRef, Optional, Inject, Renderer2, ViewChild, HostListener, SimpleChange, DoCheck } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import * as _ from 'lodash';


type Changes = {
  inputModel: SimpleChange;
};

@Component({
  selector: 'app-textarea',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() @Inject(DOCUMENT) private document: any,
    public platform: Platform) {
    this._document = document;
    this.measuringClass = (this.platform.FIREFOX) ? 'textarea-autosize-measuring-firefox' : 'textarea-autosize-measuring';
  }

  @Input('inputModel')
  inputModel: string = '';

  inputModelBack: string = '';
  // set inputModel(model: string) {
  //   let element = this.elementRef.nativeElement.querySelector('textarea');
  //   element.value = model || '';
  // }

  @Output()
  inputModelChange = new EventEmitter<string>();

  @Output()
  ctrlEnter = new EventEmitter<any>();

  @Output()
  escKey = new EventEmitter<any>();

  @Output()
  enterKey = new EventEmitter<any>();

  @Output()
  shiftEnter = new EventEmitter<any>();

  @ViewChild('templateTextArea') el: ElementRef;


  @Input()
  placeholder: string;

  @Input()
  focus: boolean = false;

  @Input()
  preventEnter: boolean = false;

  @Input()
  preventShiftEnter: boolean = false;

  @Input()
  preventCtrlEnter: boolean = false;

  @Input()
  minRows: number = 1;

  @Input()
  maxRows: number = 20;

  @Input()
  saveText: string = "Сохранить";


  @Input() needResize:  Subject<any> =new Subject<any>();

  private readonly _destroyed = new Subject<void>();





  private measuringClass: string;

  ngOnInit(): void {
    // this.textareaElement = this.elementRef.nativeElement.querySelector('textarea');
    // this.resizeTextArea();
    this.inputModelBack=_.cloneDeep(this.inputModel);
    this.needResize.subscribe((c)=>{
      this.resizeTextArea();
    })
  }



  ngOnChanges(changes: Changes) {
    if (changes.inputModel?.firstChange === false) {
      if (this.inputModel && this.inputModel.length == 0) {
        setTimeout(() => {
          this.resizeTextArea();
        }, 0);
      }
    }

    
  }

  

  // @HostListener("focus", ["$event.target"])
  // onFocus(target) {
  //   console.log(target.value)
  // }

  // @HostListener("blur", ["$event.target"])
  // onBlur(target) {
  //   console.log(target.value)
  // }

  @HostListener('keydown', ['$event'])
  public resizeTextAreaListener(event: KeyboardEvent): void {
    if (event.keyCode == 13 && event.shiftKey) {
      this.shiftEnter.emit();
     // event.stopPropagation();
      return;
    }

    if (event.keyCode == 13 && event.ctrlKey) {
      if (this.preventCtrlEnter) 
      {event.preventDefault()}
      this.ctrlEnter.emit();
       setTimeout(() => {
        
      this.resizeTextArea();
       }, 100);
      event.stopPropagation();
      return;
    }

    if (event.keyCode == 13) {
      if (this.preventEnter) 
      {event.preventDefault()
      console.log("prev")}
      this.enterKey.emit();

       setTimeout(() => {
      this.resizeTextArea();
     }, 100);
      event.stopPropagation();
      return;
    }

    if (event.keyCode == 27) {
      setTimeout(() => {
        this.resizeTextArea();
      }, 0);
      this.escKey.emit();
      event.stopPropagation();
      return;
    }

  }

  

  // keyDownTextarea(event: any) {
  //   this.resizeTextArea();

  //   console.log(event);
  //   console.log(this.inputModel)

  // }


  changeInput() {
    this.resizeTextArea();
    this.inputModelChange.emit(this.inputModel);
  }



  ngAfterViewInit() {
    setTimeout(() => {
      this.resizeTextArea();
    }, 100);


    if (this.focus) this.el.nativeElement.focus();
  }

  public setFocus() {
    this.el.nativeElement.focus()
  }


  resizeTextArea() {

    
    this._cacheTextareaLineHeight();
    if (!this._cachedLineHeight) {
      
      return;
    }

    // const value = textarea.value;

    // const placeholderText = textarea.placeholder;

    // console.time('ResizeTextArea');
    
    let element = this.elementRef.nativeElement.querySelector('textarea');

    element.classList.add(this.measuringClass);


    const height = this.el.nativeElement.scrollHeight;
    this.renderer.setStyle(element, 'height', `${height + 1}px`);

    element.classList.remove(this.measuringClass);
    // console.timeEnd('ResizeTextArea');

  }



  private _cachedLineHeight: number;

  setMinHeight(): void {
    const minHeight = this.minRows && this._cachedLineHeight ?
      `${this.minRows * this._cachedLineHeight}px` : null;

    if (minHeight) {
      this.el.nativeElement.style.minHeight = minHeight;
    }
  }

  setMaxHeight(): void {
    const maxHeight = this.maxRows && this._cachedLineHeight ?
      `${this.maxRows * this._cachedLineHeight}px` : null;

    if (maxHeight) {
      this.el.nativeElement.style.maxHeight = maxHeight;
    }
  }

  protected _document?: Document;


  private _cacheTextareaLineHeight(): void {
    if (this._cachedLineHeight) {
      return;
    }

    let textareaClone = this.el.nativeElement.cloneNode(false) as HTMLTextAreaElement;
    textareaClone.rows = 1;

    textareaClone.style.position = 'absolute';
    textareaClone.style.visibility = 'hidden';
    textareaClone.style.border = 'none';
    textareaClone.style.padding = '0';
    textareaClone.style.height = '';
    textareaClone.style.minHeight = '';
    textareaClone.style.maxHeight = '';
    textareaClone.style.overflow = 'hidden';
    this.el.nativeElement.parentNode!.appendChild(textareaClone);
    this._cachedLineHeight = textareaClone.clientHeight;
    this.el.nativeElement.parentNode!.removeChild(textareaClone);

    this.setMinHeight();
    this.setMaxHeight();
  }


  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }


}

import { ConfirmDoneIcon, CloseIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'text-field-autocomplete',
    templateUrl: './text-field-autocomplete.component.html',
    styleUrls: ['./text-field-autocomplete.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class TextFieldAutocompleteComponent implements OnInit {

    @Input('value')
    value: string = '';
 
    @Input() placeholder: string="поиск";

    @Input() autocompleteOptions: any[]=[];

    autocompleteWithFilter: any[]=[];
    
    @Output()
    valueChange = new EventEmitter<string>();

    @Output()
    selectOption = new EventEmitter<any>();

    @Output()
    escKey = new EventEmitter<any>();

    @Output()
    enterKey = new EventEmitter<any>();

    showAutocomplete:boolean=false;

    selectedFromAutocomplete:any;
    address_Langset: Address_LangSet;
    ad_sub: Subscription=null;

    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon
        ])
    }



    ngOnInit() {
        this.autocompleteWithFilter=_.cloneDeep(this.autocompleteOptions);
        this.ad_sub=this.languageService.addressLanguage$.subscribe(lang=>{this.address_Langset=lang;})
    }

    selectFromAutocomplete(value:any)
    {
        this.value=value.Name;
        this.valueChange.emit(this.value);
        this.showAutocomplete=false;
        //this.selectOption.emit(value);

    }

    textChanged()
    {
        this.valueChange.emit(this.value);
        this.autocompleteWithFilter=this.autocompleteOptions.filter(c=>c.Name.toLowerCase().includes(this.value.toLowerCase()));
        //this.selectedFromAutocomplete=null;
        this.showAutocomplete=true;
    }

    blurInput()
    {
        setTimeout(()=>{this.showAutocomplete=false},200)
    }


}

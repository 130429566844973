import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckLoginGuard } from './shared/guards/check-login.guard';
import { CheckSessionGuard } from './shared/guards/check-session.guard';
// import { LoginNewComponent } from './pages/login/comp.login';
//import { AnalyticMainComponent } from './components/analytic/analyticMain/comp.analyticMain';

const routes: Routes = [
  { path: 'login/:pageToGo', canActivate: [CheckLoginGuard], loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'login', redirectTo: 'login/main', pathMatch: 'full', canActivate: [CheckLoginGuard] },
  {
    path: 'main', canActivate: [CheckSessionGuard],
    // component: MainComponent,
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)
  },
  { path: 'client/:id',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule) },
  { path: 'project/:id',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule) },

  { path: 'calendar',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule) },

  // DOCEDITOR
  { path: 'docedit/:id',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/docedit/docedit.module').then(m => m.DocEditModule) },

  // VUE-ANALYTICS
  { path: 'analyticsvue',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/analyticsvue/analyticsvue.module').then(m => m.AnalyticsVueModule) },


  //{ path: 'analytics', loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule) },
 // { path: 'analyticNew', loadChildren: () => import('./pages/analytic/analytic.module').then(m => m.AnalyticModule) },
 { path: 'analytics',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/analytic/analytic.module').then(m => m.AnalyticModule) },

 { path: 'storage',canActivate: [CheckSessionGuard], loadChildren: () => import('./pages/storage/storage.module').then(m => m.StorageModule) },
  

  //{ path: 'analyticNew', component: AnalyticMainComponent },
  {
    path: 'settings',canActivate: [CheckSessionGuard],
    // component: SettingsComponent,
    loadChildren: () => import('./pages/settings/setting.module').then(m => m.SettingModule),
    // children: [
    //     {
    //         path: 'profile',
    //         // children: [
    //         //     {
    //         // path: '',
    //         loadChildren: () => import('./containers/settings/profile/profile.module').then(m => m.ProfileSettingSettingModule)
    //         //     }
    //         // ]
    //     },
    //     {
    //         path: 'users',
    //         // children: [
    //         //     {
    //         // path: '',
    //         loadChildren: () => import('./containers/settings/group-users/group-users.module').then(m => m.GroupUsersSettingModule)
    //         //     }
    //         // ]
    //     },
    //     {
    //         path: 'parameters',
    //         // children: [
    //         //     {
    //         // path: '',
    //         loadChildren: () => import('./containers/settings/parameters/parameters.module').then(m => m.ParametersSettingModule)
    //         //     }
    //         // ]
    //     }
    // ]
  },

  //{ path: 'analyticNew', component: AnalyticMainComponent },
  { path: '**', redirectTo: 'main', pathMatch: 'full' }
];

// const routes: Routes = [
//     { path: 'login/:pageToGo', component: LoginNewComponent },
//     { path: 'login', redirectTo: 'login/main', pathMatch: 'full' },
//     { path: 'main', component: MainComponent },
//     { path: 'client/:id', component: ClientComponent },
//     { path: 'project/:id', component: ProjectComponent },
//     {
//         path: 'settings', component: SettingsComponent,
//         children: [{
//             path: 'profile',
//             component: ProfileSettingComponent
//         },
//         {
//             path: 'users',
//             component: GroupUsersSettingComponent
//         },
//         {
//             path: 'parameters',
//             component: ParametersSettingComponent
//         }
//         ]
//     },
//     { path: 'calendar', component: CalendarComponent },
//     { path: 'analytics', component: AnalyticsComponent },
//     { path: '**', redirectTo: 'main', pathMatch: 'full' }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

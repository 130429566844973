/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { Common } from '../../../models/model.common';
import { ParameterInstance, ParameterInstanceAddAsker, ParameterInstanceContainerGiver, ParameterInstanceEditAsker, ParameterValueContainerGiver, ValueSetSmall } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { ParameterInstanceContainerFilled, ParameterInstanceContainerFilledFull, ParameterInstanceCreateContainer, ParameterInstanceCreateContainerFilled } from '../../../models/model.storage';


declare var require: any;


@Component({
    selector: 'parameterInstanceSearchContainer',
    templateUrl: 'temp.parameterInstanceSearchContainer.html',
    styleUrls: ['parameterInstanceSearchContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceSearchContainerComponent implements OnInit, AfterViewInit {
    
    

  @Input() Container: ParameterInstanceCreateContainer;
  @Input() editable: boolean=true;
  @Input() canBeList: boolean=true;
  @Input() titleText: string="Наименование";
  @Input() ContainersFilled: ParameterInstanceCreateContainerFilled;
  @Output() ContainersFilledChange:  EventEmitter<any> = new EventEmitter<any>();
  @Input() ParentContainer: ParameterInstanceContainerFilledFull=null;

  @Input() selectWithoutAdding: boolean=false;
  loadingContainer:boolean=true; 
  filledRecordEmpty:any;
  PossibleValueSetsIds:number[]=[];
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;

    lvlMap=new Map();

    @Input() id: number=-1;

    @Output() SelectedFieldFromHint: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input() outerId: string="0";
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private sessionService: SessionService,
    private router: Router
              ) { }

            
              ngOnDestroy() {
               
              }

              

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                //this.ContainersFilled.push(new ParameterInstanceContainerFilledFull());
                
                if(this.ContainersFilled.ValueType==5)
                {

                  
                  this.filledRecordEmpty=_.cloneDeep(this.ContainersFilled.RecordFields[0]);
                }
                
                this.InitPossibleIds();
           }




           ChangeElementSize(size: number, param: number, lvl: number)
           {
            
          
             let container_outer=document.getElementById("outer_container_"+this.outerId);
             /*let style=document.createElement('style');
                           
             let css=".param_container_"+this.paramId+" .pi"+this.paramId+"__lv"+lev+"{ max-width:"+container_outer.getBoundingClientRect().width+"px; width:"+maxW+"px;}";
             style.innerHTML=css;
             document.head.append(style);*/
             let fullmax=container_outer.getBoundingClientRect().width;
             if(this.Container.IsList)
             {
               fullmax=fullmax-35;
             }
             
             document.head.childNodes.forEach(ch=>{
               
               if(ch.nodeName=="STYLE")
               {
                
                 let sst=<HTMLStyleElement>ch;
                 //let str=sst.textContent.trim();
                 if(sst.textContent.includes(".pi"+param+"ou"+this.outerId+"lvl"+lvl+"{"))
                 { 
                  
                   let mas=sst.textContent.split(" ");
                   let re = new RegExp(/^width:/, "gi");
                   mas.forEach(me=>{
                     if(me.match(re)!=null)
                     {
                       let wid=me.replace(re, '');
                       wid=wid.replace("px;","");
                       wid=wid.replace("}","");

                       
                       if(Number(wid)<size)
                       {
                        
                         let style=document.createElement('style');
                           let ns=size+10;
                           let css=".outer_param_"+this.outerId+"_"+this.id+" .pi"+this.id+"ou"+this.outerId+"lvl"+lvl+"{ max-width:"+fullmax+"px; width:"+ns+"px;";
                           
                          
                           style.innerHTML=css;
                           document.head.append(style);
                           ch.remove();
                           
                       }

                     }
                   })
                 }
               
               }
             }
               )
           }


           ChangeValueSetSize(size: number, lvl: number)
           {
            
             let container_outer=document.getElementById("outer_container_"+this.outerId);
             let fullmax=container_outer.getBoundingClientRect().width;
             if(this.Container.IsList)
             {
               fullmax=fullmax-41;
             }
           
             document.head.childNodes.forEach(ch=>{
               
               if(ch.nodeName=="STYLE")
               {
                  
                 let sst=<HTMLStyleElement>ch;
                 //let str=sst.textContent.trim();
                 if(sst.textContent.includes(".pi"+this.id+"ou"+this.outerId+"lvl"+lvl+"{"))
                 { 
                  
                   let mas=sst.textContent.split(" ");
                   let re = new RegExp(/^width:/, "gi");
                   mas.forEach(me=>{
                     if(me.match(re)!=null)
                     {
                       let wid=me.replace(re, '');
                       wid=wid.replace("px;","");
                       wid=wid.replace("}","");


                      
                       if(Number(wid)<size)
                       {
                        
                         let style=document.createElement('style');
                           let ns=size+10;
                           let css=".outer_param_"+this.outerId+"_"+this.id+" .pi"+this.id+"ou"+this.outerId+"lvl"+lvl+"{ max-width:"+fullmax+"px; width:"+ns+"px;";
                           
                          
                           style.innerHTML=css;
                           document.head.append(style);
                           ch.remove();
                           
                       }

                     }
                   })
                 }
               
               }
             }
               )
           }




              ngAfterViewInit() 
              {
                //this.ContainersFilled[0].TemplateId=this.Container.Template.Id;

                //this.loadingContainer=false;
                
                
              }


              SelectFieldFromHint(ev:any[])
              {

                this.SelectedFieldFromHint.emit(ev);
               
              }

              SelectField(ev:any[], container: ParameterInstanceCreateContainerFilled[])
              {
                
                ev.forEach(e=>
                  {
                    let setId=e[0];
                    let valId=e[1];


                    let fieldInd=container.findIndex(c=>c.ValueType==4 && c.ValueSetId==setId && c.IsList==false)
                    if(fieldInd>-1)
                    {
                      let vs=new ValueSetSmall(valId.Id, valId.Name);
                      vs.ValueType=-1;
                      container[fieldInd].ValueSetSelected=[[vs]];
                      
                    }
                    })
              }


              InitPossibleIds()
              {
                if(this.Container.ValueType==4 && this.ParentContainer!=null)
                {
                  if(this.ParentContainer.RecordFields!=undefined && this.ParentContainer.RecordFields!=null && this.ParentContainer.RecordFields.length>0)
                  {
                    if(this.ParentContainer.RecordFields[0]!=undefined && this.ParentContainer.RecordFields[0]!=null && this.ParentContainer.RecordFields[0].length>0)
                    {
                      this.ParentContainer.RecordFields[0].forEach(v=>{
                        if(v.ValueSetId!=null)
                        {
                          if(!v.IsList && v.ValueSetId!=this.Container.ValueSet.Id && this.PossibleValueSetsIds.findIndex(c=>c==v.ValueSetId)==-1)
                          {
                            this.PossibleValueSetsIds.push(v.ValueSetId)
                          }
                        }
                      })

                      
                    }
                  
                  }
                }
              }

              getParent(val: any):any
              {


                let rec=this.ContainersFilled.RecordFields[val];
                let cont=_.cloneDeep(this.ContainersFilled);
                cont.RecordFields=[]
                cont.RecordFields.push(rec)
                return cont;
              }

              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }
              
            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }




            EditValue()
            {
              this.ContainersFilledChange.emit(this.ContainersFilled);
            }

            EditValueInList(val:any)
            {
              //this.ContainersFilledChange.emit(this.ContainersFilled);
            }

            setDayValue(day:string)
            {
              
              if(this.Container.ValueType==6)
              {
                if(this.ContainersFilled.StringValues!=null)
                {
                  if(this.ContainersFilled.StringValues.length==0)
                  {
                    this.ContainersFilled.StringValues=[""]
                  }
                }
                else
                {
                  this.ContainersFilled.StringValues=[""]
                }

                
              }
              if(this.ContainersFilled.StringValues[0]==null || this.ContainersFilled.StringValues[0]=="")
              {
                let dst=day!=null?day:"--";
                let dateStr=dst+".--.----";
                this.ContainersFilled.StringValues[0]=dateStr;
              }
              else
              {
                let dateParts=this.ContainersFilled.StringValues[0].split(".");
                
                if(dateParts.length==3)
                {
                  let dst=day!=null?day:"--";
                  let dateStr=dst+"."+dateParts[1]+"."+dateParts[2];
                this.ContainersFilled.StringValues[0]=dateStr;
                }
              }

             
            }

            setMonthValue(month:string)
            {
              if(this.Container.ValueType==6)
              {
                if(this.ContainersFilled.StringValues!=null)
                {
                  if(this.ContainersFilled.StringValues.length==0)
                  {
                    this.ContainersFilled.StringValues=[""]
                  }
                }
                else
                {
                  this.ContainersFilled.StringValues=[""]
                }

                
              }
              if(this.ContainersFilled.StringValues[0]==null || this.ContainersFilled.StringValues[0]=="")
              {
                let mst=month!=null?month:"--";
                let dateStr="--."+mst+".----";
                this.ContainersFilled.StringValues[0]=dateStr;
              }
              else
              {
                let dateParts=this.ContainersFilled.StringValues[0].split(".");
                if(dateParts.length==3)
                {
                  let mst=month!=null?month:"--";
                  let dateStr=dateParts[0]+"."+mst+"."+dateParts[2];
                this.ContainersFilled.StringValues[0]=dateStr;
                }
              }

              
            }

            setYearValue(year:string)
            {
              if(this.Container.ValueType==6)
              {
                if(this.ContainersFilled.StringValues!=null)
                {
                  if(this.ContainersFilled.StringValues.length==0)
                  {
                    this.ContainersFilled.StringValues=[""]
                  }
                }
                else
                {
                  this.ContainersFilled.StringValues=[""]
                }

                
              }
              if(this.ContainersFilled.StringValues[0]==null || this.ContainersFilled.StringValues[0]=="")
              {
                let yst=year!=null?year:"----";
                let dateStr="--.--."+yst;
                this.ContainersFilled.StringValues[0]=dateStr;
              }
              else
              {
                let dateParts=this.ContainersFilled.StringValues[0].split(".");
                if(dateParts.length==3)
                {
                  let yst=year!=null?year:"----";
                  let dateStr=dateParts[0]+"."+dateParts[1]+"."+yst;
                this.ContainersFilled.StringValues[0]=dateStr;
                }
              }
            }


            ClearValue()
            {
              if(this.Container.ValueType==0)
              {
                this.ContainersFilled.StringValues[0]="";
                this.ContainersFilledChange.emit(this.ContainersFilled);
              }
            }

            ClearValueInList(stringVal:string)
            {
              stringVal="";
            }


            AddValueInList()
            {
              if(this.Container.ValueType==0)
              {
                this.ContainersFilled.StringValues.push("");
                this.ContainersFilled.StringValuesName.push("");
              }
              if(this.Container.ValueType==1)
              {
                this.ContainersFilled.NumberValues.push(null);
                this.ContainersFilled.NumberValuesName.push(null);
              }
              if(this.Container.ValueType==2)
              {
                this.ContainersFilled.NumberValues.push(null);
                this.ContainersFilled.NumberValuesName.push(null)
              }
              if(this.Container.ValueType==3)
              {
                this.ContainersFilled.BooleanValues.push(null);
                this.ContainersFilled.BooleanValuesName.push(null);
              }

              if(this.Container.ValueType==6)
              {
                this.ContainersFilled.DateValues.push(null);
                this.ContainersFilled.DateValuesName.push(null);
              }

              
              if(this.Container.ValueType==7)
              {
                this.ContainersFilled.FormulaValues.push(null);
                this.ContainersFilled.FormulaValuesName.push(null);
              }

              if(this.Container.ValueType==4)
              {
                this.ContainersFilled.ValueSetSelected.push([]);
              }
              if(this.Container.ValueType==5)
              { 

                this.ContainersFilled.RecordFields.push(_.cloneDeep(this.filledRecordEmpty));

                


                /*this.Container.RecordFields.forEach(f=>{
                  let fl = [new ParameterInstanceCreateContainerFilled()];
                  this.ContainersFilled.RecordFields.push(fl);
                  this.RecordFieldsFilledInit(f,fl)
                })*/
              }
              this.ContainersFilledChange.emit(this.ContainersFilled);

            }


            RecordFieldsFilledInit(container: ParameterInstanceCreateContainer, containerFilled: ParameterInstanceCreateContainerFilled[])
            {
              containerFilled[0].Template=_.cloneDeep(container.Template);
              containerFilled[0].IsList=container.IsList;
              containerFilled[0].BooleanValues=[null];
              containerFilled[0].StringValues=[null];
              containerFilled[0].NumberValues=[null];
              containerFilled[0].DateValues=[null];
              containerFilled[0].FormulaValues=[null];
              containerFilled[0].ValueSetSelected=[[]];
              containerFilled[0].RecordFields=[];
              containerFilled[0].ValueType=container.ValueType;
              if(container.ValueType==5)
                  {
                    container.RecordFields.forEach(f=>{
                      let fl = [new ParameterInstanceCreateContainerFilled()];
                      containerFilled[0].RecordFields.push(fl);
                      this.RecordFieldsFilledInit(f,fl)
                    })
                  }
            }
              


            ValueChange(value: any)
            {}


            ValueChangeInList(value: any, container:ParameterInstanceContainerFilledFull)
            {
              container.BoolValue
            }



            EditValueVS()
            {
              //this.ContainersFilled.;
              this.ContainersFilledChange.emit(this.ContainersFilled);
            }
            EditValueInListVS()
            {
             // container.ValueOfSet=container.SelectedValue[0].Id;
             this.ContainersFilledChange.emit(this.ContainersFilled);
            }


            DeleteValueInList(index:number)
            {
              if(this.Container.ValueType==0)
              {
                this.ContainersFilled.StringValues.splice(index, 1);
                this.ContainersFilled.StringValuesName.splice(index, 1);
              }
              if(this.Container.ValueType==1 || this.Container.ValueType==2)
              {
                this.ContainersFilled.NumberValues.splice(index, 1);
                this.ContainersFilled.NumberValuesName.splice(index, 1);
              }
              if(this.Container.ValueType==3)
              {
                this.ContainersFilled.BooleanValuesName.splice(index, 1);
                this.ContainersFilled.BooleanValues.splice(index, 1);
              }
              if(this.Container.ValueType==6)
              {
                this.ContainersFilled.DateValuesName.splice(index, 1);
                this.ContainersFilled.DateValues.splice(index, 1);
              }
              if(this.Container.ValueType==7)
              {
                this.ContainersFilled.FormulaValuesName.splice(index, 1);
                this.ContainersFilled.FormulaValues.splice(index, 1);
              }
              if(this.Container.ValueType==4)
              {
                this.ContainersFilled.ValueSetSelected.splice(index, 1);
              }
              if(this.Container.ValueType==5)
              {
                this.ContainersFilled.RecordFields.splice(index, 1);
              }
            }


            parseDate(dateString: string): Date {
              if (dateString) {
          
                
                  return new Date(dateString);
              }
              return null;
            }
}

import { GetSession, LoadSession } from './../store/actions/session.action';
import { ISessionState } from './../store/state/session.state';
import {throwError as observableThrowError, Observable, BehaviorSubject, Subject} from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';



import { select, Store } from '@ngrx/store';
import { selectSession } from '../store/selectors/session.selectors';
import { Address_LangSet, Analytic_LangSet, Autojobs_LangSet, C1_LangSet, Calendar_LangSet, Chats_LangSet, Common_LangSet, Contacts_LangSet, Doubles_LangSet, Files_LangSet, Groups_LangSet, Jobs_LangSet, LangSets_Types, LanguageSetCommon, LanguageSetCommonGiver, Login_LangSet, Parameters_LangSet, Profile_LangSet, Roles_LangSet, Search_LangSet, Stages_LangSet, Tags_LangSet } from '../models/model.language';
import { LocalStorageService } from 'angular-2-local-storage';
import { selectUserId, selectSessionKey } from '../store/selectors/session.selectors';
import { CommonRequest, SingleStringGiver } from '../models/model.http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerString } from '../config/serverstring';
import * as _ from 'lodash';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { IAppState } from '../store/state/app.state';
import { HttpServiceSession } from '../httpserv/httpserv.session';
import { Router } from '@angular/router';
import { WorkerGiver, WorkerProfile } from '../models/model.worker';
import { HttpServiceWorker } from '../httpserv/httpserv.worker';


@Injectable({
    providedIn: 'root'
})
export class TransportService {

  private UpdatedJobId: BehaviorSubject<number> = new BehaviorSubject(0);
  updatedJobId$: Observable<number> = this.UpdatedJobId.asObservable();


  private UpdateJobById(id: number)
  {
    this.UpdatedJobId.next(id);
  }


    
    constructor(private router: Router) { 
     }


     updateJobItemById(id: number)
     {
      console.log("sendtoupdate")
      console.log(id)
      this.UpdateJobById(id);
     }

   

}


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css',
    '../../../style/clientProjectAreaStyle.scss',
    '../../../style/communicationAreaStyle.scss',
    '../../../style/jobAreaStyle.scss',
    '../../../style/personAreaStyle.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',
    '../../../style/clientRedactAreaStyle.scss',
    '../../../style/calendarStyle.scss',
    '../../../style/control-inputs.scss',
    '../../../style/supportLibrary.scss',
    '../../../iconfont/material-icons.scss',
    '../../../style/font-styles.scss']
})
export class ProjectCardComponent implements OnInit {

  @Input() project: any
  @Input() textFilter: string="";
  @Input() projectId: number
  constructor() { }

  ngOnInit(): void {
  }

}

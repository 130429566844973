import {ParentChecker} from "./model.parentChecker";
import {CommonResponse} from "./model.http";
import {Common} from "./model.common";
/**
 * Created by MosNeuro on 08.09.2017.
 */
export class LanguageSetCommon {

  Language: number = -1;
//навигации панель
  MainLink: string = ""; //ссылка на главную
  CalendarLink: string = "";//ссылка на календарь
  AnalyticsLink: string = "";//ссылка на аналитику
  SettingsLink: string = "";//ссылка на настройки
  CancelStep: string = "";//на шаг назад
  LastSteps: string = "";//последние действия
  ReturnStep: string = "";//на шаг вперед


//фразы на темплейтах
  Mosta: string = ""; //Название црм
  ClientAllCount: string = "";//колво клиентов в базе
  ProjectAllCount: string = "";//кол-во проектов в базе
  ProjectAllClientSearch: string = "";//искать по всем клиентам
  RefreshingData: string = "";//обновление данных
  NeedChecking: string = "";//нужна проверка работы
  WorkerAllJobFilter: string = "";//"Все" в списке фильтра по работе

  ClientNameOne: string = "";//клиент
  ClientNameMany: string = "";//клиенты
  ProjectNameOne: string = "";//проект
  ProjectNameMany: string = "";//проекты
  JobName: string = "";//работа
  ContactNameMany: string = "";//контакты
  TagNameMany: string = "";//Теги

  FilterJobWorking: string = "";//В работе фильтр
  FilterJobQuick: string = "";//срочно фильтр
  JobStatusCompleted: string = "";//Выполнена
  JobStatusWorking: string = ""; //выполняется
  JobStatusChecking: string = "";//на проверке
  JobStatusSystem: string = "";//системная
  AccessGroupActive: string = "";//активная группа
  AccessGroupCanCreateClients: string = "";//может создать клиенты
  AccessGroupCanCreateProjects: string = "";//может содать проекты

  ClientAttachedToProject: string = "";//Клиенты(подключенные)
  ClientHolding: string = "";//Клиенты(холдинг)
  NameLong: string = "";//Название
  Comment: string = "";//Комментарий
  Stage: string = "";//Этап
  City: string = "";//Город
  Requisites: string = "";//Реквизиты
  Files: string = "";//Файлы
  CreateDateString: string = "";//Дата создания

  JobToday: string = "";// Работа сегодня
  JobTomorrow: string = "";//Работа завтра
  JobOther: string = "";//Остальная работа
  UserString: string = "";//Пользователь
  ToWeekBefore: string = "";//На неделю назад
  ToWeekAfter: string = "";//На неделю вперед
  ToWeekCurrent: string = "";// Сброс до текущей
  ToDayBefore: string = "";//На день назад
  ToWeekBack: string = "";//Назад к неделе
  ToDayAfter: string = "";//На день вперед
  ToDayCurrent: string = "";//Сброс до текущего дня
//дни недели сокращенно
  DayNameShortMonday: string = "";
  DayNameShortTuesday: string = "";
  DayNameShortWednesday: string = "";
  DayNameShortThursday: string = "";
  DayNameShortFriday: string = "";
  DayNameShortSaturday: string = "";
  DayNameShortSunday: string = "";
//дни недели полно
  DayNameLongMonday: string = "";
  DayNameLongTuesday: string = "";
  DayNameLongWednesday: string = "";
  DayNameLongThursday: string = "";
  DayNameLongFriday: string = "";
  DayNameLongSaturday: string = "";
  DayNameLongSunday: string = "";
//месяцы
  MonthNameJanuary: string = "";
  MonthNameFebriary: string = "";
  MonthNameMarch: string = "";
  MonthNameApril: string = "";
  MonthNameMay: string = "";
  MonthNameJune: string = "";
  MonthNameJuly: string = "";
  MonthNameAugust: string = "";
  MonthNameSeptember: string = "";
  MonthNameOctober: string = "";
  MonthNameNovember: string = "";
  MonthNameDecember: string = "";

  ClientAllLoaded: string = "";//все клиенты загружены
  ProjectAllLoaded: string = "";//все проекты загружены
  JobAllLoaded: string = "";//Вся работа загружена
  PersonAllLoaded: string = "";//Все контакты загружены
  MessageAllLoaded: string = "";//Все сообщения загружены


  SelectionFilters: string = ""; //Фильтры(выборка)
  SelectionNames: string = "";//Названия(выборка)
  SelectionTags: string = "";//Теги(выборка)
  SelectionStages: string = "";//Этапы(выборка)
  SelectionCreators: string = "";//Создатели(выборка)

  Creator: string = ""; // Создатель
  LastJobDate: string = ""; //Дата последней работы
  StageChangeDate: string = ""; //Дата смены этапа
  JobCount: string = ""; //Кол-во работ
  JobWorkerCount: string = ""; //Кол-во участников работы
  Duration: string = ""; //Продолжительность

  Save: string = ""; // Сохранить
  SaveAsNew: string = ""; // Сохранить как новую
  SelectionList: string = ""; // Список выборок
  SelectionAdd: string = ""; // Добавить выборку
  SelectionResultRefresh: string = ""; // Обновить анализ
  SelectionColumnsEdit: string = ""; // Редактировать столбцы

  Selection: string = ""; //Выборка
  SelectionRowsCount: string = "";//записей.  (выборка А - 100 записей)
  SelectionNoResult: string = "";//Записей не обнаружено по выборке

  SettingsProfile: string = ""; //Настройки профиля
  SettingsWorker: string = ""; //Настройки пользователей
  SettingsCity: string = ""; //Настройки городов
  SettingsStage: string = ""; //Настройки этапов проекта
  SettingsRoles: string = ""; //Настройки ролей
  SettingsAccessGroups: string = ""; //Настройки групп доступа
  SettingsMerge: string = ""; //Слияние дублей


  Photo: string = ""; //Фотография
  Change: string = ""; //Сменить
  NameShort: string = ""; //Имя
  Login: string = ""; //Логин
  Passwod: string = ""; //Пароль
  SurePassword: string = ""; //Подтвердите пароль
  Mail: string = ""; //Эл.почта
  FileFolder: string = ""; //Хранилище файлов

  MessageSendingWaySet: string = ""; //Выберите способ отправки сообщений
  MessageSendingWayCtrlEnter: string = ""; //Способ отправки сообщений Ctrl+Enter — отправка сообщения, Enter — перенос строки
  MessageSendingWayEnter: string = ""; //Способ отправки сообщений Enter — отправка сообщения, Shift+Enter — перенос строки
//CloseSessionSelfNoCurrent:string = ""; //Закрыть все сессии кроме текущей
  SaveTimeTable: string = ""; //Сохранить расписание

  WorkerAddNew: string = ""; //Добавить пользователь
  WorkerAdmin: string = ""; //Админ
  WorkerNoAdmin: string = ""; //Не админ
  WorkerBlock: string = ""; //Заблокировать вход
  WorkerUnBlock: string = ""; //Разрешить вход
  WorkerSessionClose: string = ""; //Завершить все сессии
  WorkerStatusCommon: string = ""; //Нет особенностей
  WorkerStatusBlocked: string = ""; //Заблокирован
  WorkerStatusAdmin: string = ""; //Админ

  CityAddNew: string = ""; //Добавить город
  CityDefaultMake: string = ""; //Сделать городом по умолчанию
  CityDefaultUnmake: string = ""; //убрать город по умолчанию
  CityDefaultIs: string = ""; //является городом по умолчанию

  StageAddNew: string = ""; //Добавить этап проекта
  StageFinal: string = ""; //Финальный
  StageFinalNot: string = ""; //Не финальный
  StageMoveUp: string = ""; //Переместить вверх в списке
  StageMoveDown: string = ""; //Переместить вниз в списке

  StageSetAdd: string = ""; //Добавить набор этапов

  AccessGroupAddNew: string = ""; //    Добавить группа доступа
  AccessGroupName: string = ""; //Группа пользователей
  AccessGroupWorkers: string = ""; //Пользователи


  MergeClient: string = ""; //Слияние клиентов
  MergeTag: string = ""; //Слияние тегов
  MergeCity: string = ""; //Слияние городов

  MergeCanNotCancel: string = ""; //Данное действие невозможно отменить.
  MergeSuccess: string = ""; //Слияние успешно.
  MergeClientDesc: string = ""; //Все данные по дублирующему клиенту будут перенесены в конечный.
  MergeClientAddButton: string = ""; //Добавить
  MergeClientEnding: string = ""; //Добавить
  MergeClientReducing: string = ""; //Добавить
  MergeSure: string = ""; //Вы уверены?

  MergeTagDesc: string = ""; //Все теги из списка будут заменены на конечный.
  MergeTagAddButton: string = ""; //Добавить тег
  MergeTagEnding: string = ""; //Конечное название тега

  MergeCityDesc: string = ""; //Все города из списка будут заменены на конечный.
  MergeCityAddButton: string = ""; //Добавить город
  MergeCityEnding: string = ""; //Конечное название города


//тайтлы

  TagClientSearch: string = ""; //Поиск по тегам клиентов
  TagProjectSearch: string = ""; //Поиск по тегам проектов
  TagJob: string = ""; //Теги работы
  TagPerson: string = ""; //Теги контактов
  Search: string = ""; //Поиск
//Save{ get; set; } //Сохранить
  Cancel: string = ""; // Отменить
  Clear: string = ""; //  Очистить
  SortByYourSelf: string = ""; //Отсортировать работу по себе
  SearchClear: string = ""; //Очистить поиск
  MessageSendingWayEnterTitle: string = ""; //Способ отправки сообщений Enter
  MessageSendingWayCtrlEnterTitle: string = ""; //Способ отправки сообщений Ctrl+Enter

  TagAdd: string = ""; //Добавить тег
  TagDelete: string = ""; //Удалить тег
  ClientAdd: string = ""; //Добавить клиента
  ClientDelete: string = ""; //Удалить клиента
  ClientDeleteFromPerson: string = ""; //Удалить клиента из прикрепленных к данному контакту
  ClientDeleteFromProject: string = ""; //Удалить клиента из прикрепленных к проекту
  ClientDeleteFromUnion: string = ""; //Удалить клиента {{langset.fromunion}}
  DeleteProject: string = ""; //Удалить проект
  DeleteJob: string = ""; //Удалить работу
  DeletePerson: string = ""; //Удалить контакт
  DeleteWorker: string = ""; //Удалить пользователя
  DeleteStage: string = ""; //Удалить этап проекта
  DeleteStageSet: string = ""; //Удалить набор этапов проекта
  DeleteAccesGroup: string = ""; //Удалить группу доступа
  DeleteWorkerFromAccessGroup: string = ""; //Удалить пользователя из группы доступа

  DetalInfo: string = ""; //Детальная информация
  ToFavoritesAdd: string = ""; //Добавить в избранное
  ToFavoritesDelete: string = ""; //Удаление из избранного
  Saved: string = ""; //Сохранено (для зеленых огоньков на кп)
  NotSaved: string = ""; //Произошла ошибка при сохранении
  FileUpload: string = ""; //Загрузить файл
  FileDownload: string = ""; //Скачать файл
  FileDelete: string = ""; //Удалить файл
  CatalogCreate: string = ""; //Создать каталог
  CatalogDelete: string = ""; //Удалить каталог
  CatalogOpen: string = ""; //Открыть каталог
  CatalogClose: string = ""; //Закрыть каталог

//плейсхолдеры
  ClientSearchPh: string = ""; //Поиск по клиентам плейсхолдер
  ProjectSearchPh: string = ""; //Поиск по проектам плейсхолдер
  JobTextPh: string = ""; //Введите содержание работы

  MessageSearchPh: string = ""; //Поиск по сообщениям
  FavoritesSearchPh: string = ""; //Поиск избранного
  PersonNameShortPh: string = ""; //Имя(к)
  PersonPostPh: string = ""; //Должность(к)
  PersonCityPh: string = ""; //Город(к)
  PersonContactsPh: string = ""; //Контактная информация(к)
  JobEndingTextPh: string = ""; //Введите текст дополнения к работе


//всплывающие окна, темплейт
  NotificationJob: string = ""; //Уведомления о работе
  NotificationJobEmpty: string = ""; //Новых уведомлений о работе нет
  NotificationMessage: string = ""; //Уведомления о сообщениях
  NotificationMessageEmpty: string = ""; //   Новых сообщений нет

  SelectPhotoForLoading: string = ""; //    Выберите фото для загрузки


  JobEnding: string = ""; //Завершение работы
  JobToChecking: string = ""; //Отправка работы на переделку
  JobTypeMessage: string = ""; //Введите сообщение
  TagAddClient: string = ""; //Добавить тег к клиенту
  TagAddProject: string = ""; //Добавить тег к проекту
  TagAddJob: string = ""; //Добавить тег к работе
  TagAddJobNew: string = ""; //Добавить тег для поиска по работе/добавлению к новой работе
  TagAddPerson: string = ""; //Добавить тег к контакту
  TagAddPersonNew: string = ""; //Добавить тег для поиска по контактам/добавлению к новому контакту

  StepBackButton: string = ""; //Отменить до данного действия
  StepForwardButton: string = ""; //Вернуть до данного действия
  StepBackAsk: string = ""; //Отменить до данного действия
  StepForwardAsk: string = ""; //Вернуть до данного действия

  NameFilter: string = ""; //Фильтр по названию

  UserName: string = ""; //    Юзернейм
  Password: string = ""; //Пароль

  // InvalidLoginNotFound: string = ""; //ВВедены неверные юнейм/пароль
  // InvalidLoginBlocked: string = ""; //Ваша учетка заблочена
  // InvalidLoginExpired: string = ""; //Срок лицензии Истек
  // GetAccess: string = ""; //Получить доступ (на логине, вместо Проверки сервера"
  // AccessGranted: string = ""; //Вы можете зайти

  ClientDetalInfo: string = ""; //Информация о клиенте
  ClientAccessGroups: string = ""; //Группы имеющие доступ к клиенту
  ClientAccessGroupsSelectNew: string = ""; //Выберите новую группу доступа для подключения к клиенту
  ProjectDetalInfo: string = ""; //Информация о проекте
  ProjectAccessGroups: string = ""; //Группы имеющие доступ к проекту
  ProjectAccessGroupsSelectNew: string = ""; //Выберите новую группу доступа для подключения к проекту

  AccessGroupRulesAdd: string = ""; //Добавление прав доступа группы
  AccessGroupRulesEdit: string = "";//Редактирование прав доступа группы
  AccessGroupNewOwnerChoose: string = ""; //Выбор новой групы-владельца
  AccessGroupNewOwnerRulesSet: string = "";//Установка новых прав доступа для владения группы
  Inherited: string = ""; //Наследуемые
  InheritedNot: string = ""; // Ненаследуемые

  InheritAsk: string = ""; //Наследовать?

//Cancel:string = ""; //Отмена
  BackToGroupList: string = ""; //Назад к списку групп
  CanBeInherited: string = ""; //Ненаследуемые, могут быть унаследованы
  CanNotBeInherited: string = ""; //Ненаследуемые, не могут быть унаследованы
//Для всех связок прав названия - типа разрешено/запрещено

  AccessForbidden: string = "";//запрещено
  AccessRead: string = "";//чтение
  AccessFull: string = "";//полный доступ
  AccessAllowed: string = "";//разрешено

  ClientToPersonSelect: string = "";//Выбор клиента для прикрепления контакта

  StageSetNew: string = "";//Новый набор этапов
  StageSetRedacting: string = "";//Редактируемый набор этапов
  StageSetName: string = "";//Название набора этапов
  StageSetStagesAdded: string = "";// Этапы, добавленные в набор
  StageSetStagesToAdd: string = "";//Этвпы для добавления в набор
  StageSetStageNewAdd: string = "";//Добавить новый этап к данному набору
  StageSetStageNewAdded: string = "";//Новые этапы, добавленные в набор:
  StageSetStageNewAdding: string = "";//Добавление нового этапа к набору этапов
  Color: string = "";//Цвет
  Back: string = "";//Назад
//это для слияния дублей

  EnterSearchFilter: string = "";//Введите поисковой фильтр (поле)
  MergeClientEndingAdding: string = "";//Добавление Конечного клиента
  MergeClientDoubleAdding: string = "";//Добавление дублирующего
  MergeTagAdding: string = "";//Добавить тег в список для слияния
  MergeCityAdding: string = "";//Добавить город в список для слияния

  WorkerAdding: string = "";//Добавление нового пользователя
  CityAdding: string = "";//Добавление нового города
  StageAdding: string = "";//Добавление нового этапа
  WorkerRedacting: string = "";//редактирование пользователя
  CityRedacting: string = "";//редактирование города
  StageRedacting: string = "";//редактирование этапа


  StageWhileDeletingChoose: string = "";//Выберите новый этап для проектов

  StageWhileDeletingManyInThisStage: string = "";//N проектов на данном этапе, ъотите удалить? переместите на новый этап

  AccessGroupEditing: string = "";//Редактирование группы доступа
  AccessGroupAdding: string = "";//Добавление группы доступа
  AccessGroupIsAdmin: string = "";//Группа админов?
  AccessGroupToAdminRules: string = "";//Перейти к правам администраторов
  AccessGroupToSelfRules: string = "";// Перейти к собственным правам группы
  AccessGroupSelfRules: string = "";//Собственные права группы
  AccessGroupAdminRules: string = "";//Админ. права группы
  SearchingFilterName: string = "";//    ПоискПоНазванию
  Blocked: string = "";//(Заблокирован)

  FilterAddName: string = "";//Добавить в список новый фильтр по названию
  FilterAddStage: string = "";//Добавить в список новый фильтр по этапу проекта
  FilterAddCreator: string = "";//Добавить в список новый фильтр по создателю
  FilterAddTag: string = "";//Добавить в список новый фильтр по тегу
  CalendarFaq: string = "";//Руководство по календарю маленькому че как.
  SelectionColumnsEditing: string = "";//Возможные колонки результирующей таблицы
  DateFormat: string = "";//Формат даты (ru - date: 'dd.MM.yyyy')

// текст из компонентов
  TitleCrm: string = "";//Тайтл | Mosta CRM
  TitleMain: string = "";//Тайтл главной

  TitleSettingsWorkers: string = "";//Тайтл настроек(пользователи)
  TitleSettingsCity: string = "";//Тайтл настроек(города)
  TitleSettingsStage: string = "";//Тайтл настроек(этапы)
  TitleSettingsRoles: string = "";//Тайтл настроек(Роли)
  TitleSettingsAccessGroups: string = "";//Тайтл настроек(группы доступа)
  TitleSettingsMerge: string = "";//Тайтл настроек(дубли)
  TitleSettingsProfile: string = "";//Тайтл настроек(профиль)

  TitleCalendar: string = "";//Тайтл календаря
  TitleAnalytics: string = "";//Тайтл аналитики


  TagAddedAlready: string = "";//Данный тег уже находится в списке подключенных тегов
  TagBadThings: string = "";//Тег не должен содержать иных символов, кроме строчных и заглавных букв, а так же знака нижнего подчеркивания
  TagEmpty: string = "";//Тег не может быть пустым


  StepBackNothing: string = "";//Нечего отменить
  StepForwardNothing: string = "";//Нечего вернуть
  StepLastBackAsk: string = "";//Действительно вернуть 1 дейсвтие
  StepLastForwardAsk: string = "";// Действительно отменить 1 действие
  JobMessageEmpty: string = "";//Текст работы не мб пуст
  JobDateInvalid: string = "";//Дата некорректна
  AccessGroupCanNotAddClients: string = "";//Данной группе запрещено добавление новых клиентoB
  AccessGroupCanNotAddProjects: string = "";//Данной группе запрещено добавление новых проектов

  AccessGroupNoOne: string = "";//Вы не состоите ни в одной группе, и не можете добавлять новые проекты/клиенты
  CityCreateNewAsk: string = "";//Нет города в базе, создать новый?
  NameShortEmpty: string = "";//Имя не может быть пустым
  NameLongEmpty: string = "";//название не может быть пустым

  ClientFromPersonDeleteAsk: string = "";//Отвязать контакт от клиента?
  PersonDeleteAsk: string = "";//Удалить контакт??
  JobDeleteAsk: string = "";//Удалить работу?
  ClientDeleteAsk: string = "";//Удалить клиента?
  ProjectDeleteAsk: string = "";//Удалить проект?

  AccessGroupConnectOwnerDeleteAskClient: string = "";//Вы не можете удалить данную группу.Сначала выберите нового владельца для клиента/.
  AccessGroupConnectOwnerDeleteAskProject: string = "";//Вы не можете удалить данную группу.Сначала выберите нового владельца для /проекта.
  AccessGroupConnectDeleteAskClient: string = "";// Вы действительно хотите лишить данную группу прав доступа к данному /клиенту/
  AccessGroupConnectDeleteAskProject: string = "";// Вы действительно хотите лишить данную группу прав доступа к данному //проекту
  AccessGroupConnectDeleteAskOwnage: string = "";// Вы действительно хотите лишить данную группу прав доступа к данному владению//
  AccessGroupNewOwnerSetAsk: string = "";//Вы действительно хотите передать данное владение новому владельцу? Вы не сможете отменить данное действие.


  FolderDeleteAsk: string = "";//Действительно удалить папку? Вы не сможете ее восстановить.
  FolderNameType: string = "";//Введите название новой папки:
  FileLoadChoose: string = "";//Выберите файл для загрузки:
  FolderNameEmptyOrLarge: string = "";//    Название папки не пустое/не больше 100 симв
  FolderNameExists: string = "";//    Папка с указанным именем уже существует!
  FolderBadThings: string = "";//В названии папки не должно быть запрещенных символов: . \" / \\ [ , ] : ; | = ? < > * |
  FileDeleteAsk: string = "";//Действительно удалить файл? Вы не сможете его восстановить.
  ClientReNameForbidden: string = "";//   Папка данного клиента/проекта занята. В переименовании отказано.Попробуйте попозже.
  ProjectReNameForbidden: string = "";//   Папка данного /проекта занята. В переименовании отказано.Попробуйте попозже.
  ClientToPersonAddForbidden: string = "";//У вас нет прав для добавления контакта к данному клиенту

  ClientToProjectDeleteAsk: string = "";//"Вы действительно хотите удалить привязку данного проекта к клиенту?
  SelectionDeleteAsk: string = "";//Действительно удалить эту выборку? Это действие невозможно отменить.
  SelectionFilterAddedAlready: string = "";//   Даный фильтр уже был добавлен.
  StageNameEmpty: string = "";//   Название этапа не может быть пустым.
  StageNewNameExists: string = "";//   Выбранное вами название этапа только что было вами использовано для этапа, созданного ранее. Введите уникальное название.
  StageNewDeleteAsk: string = "";//   Действительно удалить данный новый этап из набора этапов?
  StageSetNameEmpty: string = "";//Название набора этапов не может быть пустым.

  StageSetNoStagesAdded: string = "";//    Вы не выбрали ни одного этапа для данного набора.Вы действительно хотите добавить пустой набор?
  StageSetDeleteAsk: string = "";//Вы действительно хотите удалить набор этапов
  WorkerDeleteAsk: string = "";//Вы действительно хотите удалить пользователя

  SessionCloseSelfSafe: string = "";//   Удалить все свои сессии, не включая текущую
  SessionCloseOtherAll: string = "";//   Удалить все сессии данного пользователя

  SessionCloseSelfAllAsk: string = "";//   Удалить все свои сессии, включая текущую?
  SessionCloseSelfSafeAsk: string = "";//   Удалить все свои сессии, не включая текущую?
  SessionCloseOtherAllAsk: string = "";//   Удалить все сессии данного пользователя?

  CityDeleteAsk: string = "";//Удалить город?
  CityDefaultSetAsk: string = "";//Вы действительно сделать этот город городом по умолчанию?
//В таком случае ко bсем новым клиентам при создании будет привязан этот город.

  CityDefaultReSetAsk: string = "";//   Вы действительно отменить привязку города по умолчанию? В таком случае город у новых клиентов будет не выбран.
  CityNameExists: string = "";//    В базе уже имеется город с таким названием
  CityNameEmpty: string = "";//"Название города не может быть пустым"
  CityNameReserved: string = "";//Данное имя недоступно для города
  StageDeleteAsk: string = "";//    Вы действительно хотите удалить этап проекта?

  StageProjectsMoveAsk: string = "";//   Переместить проекты на этап ""?
  AccessGroupDeleteAsk: string = "";//Вы действительно хотите удалить данную группу пользователей
  WorkerFromAccessGroupDeleteAsk: string = "";//    Действительно удалить пользователя из группы доступа?
  CalendarDataRefreshingAfterBackUp: string = "";//    Обновление информации после операций возврата
  CalendarDayRefresh: string = "";// Обновление дня
  CalendarWeekRefresh: string = "";// Обновление недели

  NotificationDelete: string = "";//Удалить уведомление
  NotificationDeleteAll: string = "";//удалить все уведомления

  //todo: добавлено 15.09.17
  JobSendToCheckButton: string = "";//Отправить на проверку
  JobSendToEndButton: string = "";//Завершить
  JobSendToReWorkButton: string = "";//Переделать
  JobToReworking: string = "";//Отправка работы на переделку

  NotificationMessageCountInChat: string = "";//сообщений в этой беседе(про сообщения в уведомлении)

  PhotoDelete: string = "";//удалить фото
  LoggedAs: string = "";//Вы(Logged As)
  LogOut: string = "";// Выход
  ClientNotFound: string = "";//Клиентов не найдено
  ProjectNotFound: string = "";///проектов не найдено
  JobNotFound: string = "";//работы не найдено
  PersonNotFound: string = "";//контактов не найдено
  TagAddClientForDialog: string = "";//Добавить тег для поиска по клиентам
  TagAddProjectForDialog: string = "";//Добавить тег для поиска по проектам
  TagAddJobToRedacting: string = "";//добавить тег к редактируемой работе
  TagAddPersonToRedacting: string = "";//Добавить тег к редактируемому контакту
  JobDeadLine: string = "";//срок выполнения работы
  NotificationOpenButton: string = "";//Новые поручения и сообщения
  StartNewSearch: string = "";//начать новый поиск

  ToLastMessages: string = "";//к последним сообщениям
  Favorites: string = "";//избранное
  FavoritesNotFound: string = "";//Вы не добавляли ничего в избранное
  FavoritesAllLoaded: string = "";//Все избранное загружено
  PersonClientNoAccess: string = "";// Нет доступа ни к одному из привязанных клиентов
  PersonEdit: string = "";//Редактировать контакт
  JobEdit: string = "";//Редактировать работу

  TitleNewMessagesOne: string = "";//Новые сообщения(тайтл);  1 сообщение
  TitleNewMessagesSome: string = "";//Новые сообщения(тайтл);  2-4 новых сообщения
  TitleNewMessagesMany: string = "";//Новые сообщения(тайтл);  >4 новых сообщений

  StepThisBackAsk: string = ""; //отменить до данного шага?
  StepThisForwardAsk: string = "";//вернуться на данный шаг?
  //todo: added 18.09.17
  ClientNameEmpty: string = "";//Название клиента не может быть пустым/
  ProjectNameEmpty: string = "";//Название проекта не может быть пустым/
  ProjectNameBadThings: string = "";//В названии проекта не должны быть запрещенных символов: . \" / \\ [ , ] : ; |  = ?   < > * |"
  ClientNameBadThings: string = "";//В названии клиента не должны быть запрещенных символов: . \" / \\ [ , ] : ; |  = ?   < > * |"
  ClientHoldingDeleteAsk: string = "";//    Действительно удалить клиент из холдингп?
  ClientAttachedDeleteAsk: string = "";//    Действительно удалить клиент из подключенных к данному проекту?
  ClientNameNew: string = "";//    Новый клиент - для названий новых сущностей
  ProjectNameNew: string = "";//   новый проект.- для названий новых сущностей
  //todo: added 19.09 v2
  CalendarLoadingDay: string = "";//загрузка дня
  CalendarLoadingWeek: string = "";//загрука недели

  SettingsProfileButton: string = "";//Профиль
  SettingsWorkersButton: string = "";//Пользователи
  SettingsCitiesButton: string = "";//Города
  SettingsStagesButton: string = "";//Этапы
  SettingsRolesButton: string = "";//Наборы этапов
  SettingsAccessGroupsButton: string = "";//Группы доступа
  SettingsMergeButton: string = "";//Дубли

  StageNameExists: string = "";//Указанное название этапа проекта не уникально.Введите уникальное название.

  //добавлен 19.09
  AccessGroupConnectNew: string = "";//Новая группа
  AccessGroupNewOwnerSetButton: string = "";//Выбрать нового владельца
  Owner: string = "";//Владелец
  Edit: string = "";//Редактировать
  AccessGroupConnectEdit: string = "";//Удалить права доступа
  AccessGroupConnectDelete: string = "";//Редактировать права доступа
  InheritedAtThisMoment: string = "";//наследуемые в данный момент

  CanBeInheritedDesc: string = "";//Может быть унаследована(?)
  CanNotBeInheritedDesc: string = "";//Не может быть унаследована(?)

  AccessGroupNewOwnerClientAuto: string = "";//Есть клиенты во владении, права будут унаследованы с прочего владения
  AccessGroupNewOwnerClientEdit: string = "";//Нет клиентов во владении, Права вводятся пользователем самостоятельно
  AccessGroupNewOwnerProjectAuto: string = "";//Есть проекты во владении, права будут унаследованы с прочего владения
  AccessGroupNewOwnerProjectEdit: string = "";//Нет проектов во владении, Права вводятся пользователем самостоятельно

  //todo: added 20.09
  ClientUnionExit: string = "";//выйти {{langset.fromunion}}
  NotChoosen: string = "";//Не выбран

  //todo: 21.09
  CalendarFaqH1: string = "";//руководство по использованию календаря
  NavigateToWeekPrev: string = "";//(Переход на предыдущую неделю)
  NavigateToWeekCurrent: string = "";//(Сброс на текущую неделю)
  NavigateToWeekNext: string = "";//(Переход на следующую неделю)
  NavigateToDayChoosen: string = "";//(Переход на выбранный день)

  NavigateToDayPrev: string = "";//(Переход на предыдущий день)
  NavigateToWeek: string = "";//(Переход на неделю)
  NavigateToDayCurrent: string = "";//(Сброс на текущий день)
  NavigateToDayNext: string = "";//(Переход на следующий день)

  NavigateButtonToDayPrev: string = "";//На день назад
  NavigateButtonToWeek: string = "";//Назад к неделе
  NavigateButtonToDayCurrent: string = "";//Сброс до текущего дня
  NavigateButtonToDayNext: string = "";//На день вперед

  NavigateButtonToWeekPrev: string = "";//На неделю назад
  NavigateButtonToWeekCurrent: string = "";//Сброс до текущей
  NavigateButtonToWeekNext: string = "";//На неделю вперед

  SelectionParameters: string = "";//Параметры выборки
  Add: string = "";//Добавить

  //todo: 22.09
  LanguageChoose: string = "";// язык
  ProfileSaved: string = "";//Профиль успешно сохранен
  ProfileNotSaved: string = "";//Во время сохранения профиля произошла ошибка
  SendMailAsk: string = "";//отправлять почту?
  Hours: string = "";//Часы
  Minutes: string = "";//минуты

  SheduleSave: string = "";//Сохранить расписание
  SheduleSaved: string = "";//Расписание успешно соххранено
  SheduleNotSaved: string = "";//Во время сохранения расписания произошла ошибка


  WorkerStatusTab: string = "";//статус
  WorkerStatusChangeTab: string = "";//Смена статуса
  WorkerBlockTab: string = "";//Блокировка
  WorkerSessionEndTab: string = "";//Завершить сессии
  Delete: string = "";//Удалить
  DoMerge: string = "";//выполнить слияние
  Week: string = "";
  TitleSettingsOwnage: string = "";


  SettingsOwnageButton: string = "";// - Владения
  SettingsOwnage: string = "";// - настройки владений
  OwnageGroupName: string = "";// - Владение группы

  SelectAll: string = "";//. Выделить все
  UnselectAll: string = "";// Снять все выделение
  ShowAll: string = "";// Показать все
  OwnageRulesSettingProjects: string = "";// Установка прав на проекты владения группы
  OwnageRulesSettingClients: string = "";// Установка прав на Клиенты владения группы
  Apply: string = "";// Применить
  OwnageOwnerSettingClients: string = "";//Выбор нового владельца для клиентов группы
  OwnageOwnerSettingProjects: string = "";//Выбор нового владельца для проектов группы
  OwnageOptionsClientsPart1: string = "";//Выбор дополнительных опций для перемещения клиентов из владения группы
  OwnageOptionsProjectsPart1: string = "";//Выбор дополнительных опций для перемещения проектов из владения группы
  OwnageOptionsPart2: string = "";//во владение группы

  IncludeAttachedProjects: string = "";//Включить связанные проекты
  IncludeAttachedClients: string = "";//Включить связанные клиенты
  LeaveOwnerRules: string = "";//Оставить права прежнего владельца как ненаследуемые
  LeaveInheritedRules: string = "";// Оставить прочие наследуемые права как ненаследуемые
  LeaveNotInheritedRules: string = "";//Оставить ненаследуемые права

  Loading: string = "";// Загрузка...
  OwnageRulesResetClients: string = "";//Редактировать права владения на клиентов
  OwnageRulesResetProjects: string = "";//Редактировать права владения на проекты
  OwnageChangeButton: string = "";// Переместить в другое владение.
  AccessGroupSureDeleteAndSetNewOwner: string = "";// Вы действительно хотите переместить клиенты/проекты из владения группы {0} во владение группы {1} и удалить группу {0}?
  LeaveInheritedNotOwnerRules: string = "";//Оставить наследуемые права как ненаследуемые
  OwnageOwnerSettingAll: string = "";// Выбор нового владельца для клиентов и проектов группы
  OwnageOptionsAllPart1: string = "";//Выбор дополнительных опций для перемещения клиентов и проектов из владения группы

  ResetLanguage: string = "";//Обновить настройки языка.
  SortByYourSelfButton: string = "";//"Я".
  NoTags: string = "";//Нет тегов.

  SelectionResultMany: string = "";//записей
  SelectionResultOne: string = "";//запись
  SelectionResultSome: string = "";//записи
  SelectionDurabilityDays: string = "";//дней

  OwnageShown1: string = "";//показано
  OwnageShown2: string = "";//из. Полная фраза - Проекты: показано 1 из 20.

  AskAddPersonWithout: string = "";//Действительно хотите добавить контакт без
  AskAddPersonWithoutCity: string = "";//города
  AskAddPersonWithoutContacts: string = "";//контактных данных. Эта фраза и 2 предыдущие действуют в связке.


//todo: 24.01
  ProjectAdd: string = "";//Добавить проект
  NeedCheckingTitle: string = "";//Данная работа потребует проверки для завершения.

  MainInProjectTitle: string = "";//Главный в проекте
  MoveUp: string = "";//Переместить выше в списке.
  MoveDown: string = "";//Переместить ниже в списке
  AddWorkerToAg: string = "";//Добавить пользователя в группу доступа
  DeleteWorkerFromAg: string = "";//Удалить пользователя из группы доступа

  //todo 06 02 18

  TopSystemTags: string = "";//Топ всех тегов
  TopUserTags: string = "";//Топ ваших тегов

  TopClientTags: string = "";//Топ тегов по клиентам
  TopProjectTags: string = "";//Топ тегов по проектам
  TopPersonTags: string = "";//Топ тегов по контактам
  TopJobTags: string = "";//Топ тегов по работе
  TagSearch: string = "";//Поиск по тегам.

  ClientNamePlaceholder: string = "";//Название клиента
  ProjectNamePlaceholder: string = "";//Название проекта

  //todo 30 07
  ConnectedWorkerList: string = "";// //Сотрудники
  ConnectedWorkerAdd: string = "";// //";//Прикрепить нового сотрудника
  AddRoleToConnectedWorker: string = "";// //Добавить роль
  SettingsParametersButton: string = "";// //– "Параметры"
  ParameterAdding: string = "";// //– "Добавление параметра"
  RefreshAnalysis: string = "";// //– "Обновить Анализ"
  EditSelectionColumns: string = "";// //– "Редактировать столбцы"
  InnerSelectionQueryTable: string = "";// //–  "Таблица вложенной выборки " (?)
  LinearGraphTitle: string = "";// //– "Временной график"
  Minimize: string = "";// //–  "Свернуть"
  Expand:string=""; // Развернуть
  InnerSelectionQuery: string = "";// //– "Вложенная выборка"
  Clone: string = "";// //– "Клонировать"
  AddSelectionQuery: string = "";// //– "Добавить выборку"

  LimitsSelectionQuery: string = "";// // – "Ограничивает выборку"
  // NoOccurences: string = "";//нет совпадений

  TitleSettingsAddress: string = ""; //Тайтл настроек(адреса)
  TitleSettingsParameters: string = ""; //Тайтл настроек(Параметры)]

  NoRolesFound: string = "";//"Не найдено ролей для добавления"
  ClientRoleDeleteAsk: string = "";//Действительно удалить данную роль клиента?
  WorkerRoleDeleteAsk: string = "";//Действительно удалить данную роль пользователя?
  AddRole: string = "";//Добавить роль
  DeleteRole: string = "";//Удалить роль
  AddClientRole: string = "";//Добавить роль клиента
  NoClientRoles: string = "";//Вы еще не добавили роли клиента
  AddWorkerRole: string = "";//Добавить роль пользователя
  NoWorkerRoles: string = "";// Вы еще не добавили роли пользователя

  ClientRoleCreating: string = ""; //Создание новой роли клиента
  ClientRoleNameNew: string = ""; //Название новой роли клиента
  ClientRoleEditing: string = ""; //Изменение роли клиента
  ClientRoleNameEditing: string = ""; //Новое название роли клиента
  WorkerRoleCreating: string = ""; //Создание новой роли пользователя
  WorkerRoleNameNew: string = ""; //Название новой роли пользователя
  WorkerRoleEditing: string = ""; //Изменение роли пользователя
  WorkerRoleNameEditing: string = ""; //Новое название роли клиента

  WorkerAttachingInProcess: string = "";//Прикрепление пользователя в процессе.
  WorkerFromClientUnfastenAsk: string = "";//    Действительно открепить пользователя от клиента?
  WorkerFromProjectUnfastenAsk: string = "";//Действительно открепить пользователя от проекта?

  Employees: string = "";//Сотрудники:
  AddEmployee: string = "";//Прикрепить нового сотрудника
  UnfastenEmployee: string = "";//Открепить сотрудника

  WorkerAttachingToClient: string = "";//Прикрепление нового пользователя к клиенту
  WorkerFilter: string = "";//Фильтрация пользователей
  WorkerAttachingToProject: string = "";//Прикрепление нового пользователя к проекту
  Address: string = "";
  ClientForPersonSelector: string = ""; //1) Выбор клиента для прикрепления
  PersonConnectToClient: string = ""; // 2) Прикрепить к клиенту
  ClientForPersonSelectorHide: string = ""; //3) Свернуть список клиентов для прикрепления
  ClientRequestCount: string = ""; // 4) По запросу найдено клиентов
  ProjectRequestCount: string = ""; // 5) По запросу найдено проектов
  ResetPassword: string = "";////Сбросить пароль
  DatePlaceholder: string = "";////дд.мм.гг
  BusinessLogic: string = "";//Бизнес-логика
  BusinessLogicMain: string = "";//ОСновное
  UsersToStage: string = ""; //Пользователи, которые могут переводить на данный этап
  UsersFromStage: string = "";  //Пользователи, которые могут снимать с данного этапа
  StagesToStage: string = "";//На какие этапы можно перейти с данного
  StagesFromStage: string = "";//С каких этапов можно перейти на данный
  Close: string = "";//Закрыть
  WorkerAdd: string = "";//Добавить пользователя
  StageAdd: string = ""; //Добавить этап
  UserMail: string = "";//Почта пользователя
  NewPassword: string = "";//Новый пароль
  Shown: string = "";//Показано
  From: string = "";//Из
  New: string = "";//Новое
  RushJob: string = "";//Срочная работа
  Other: string = "";//Остальное
  Ready: string = "";//Готово
  Saving: string = "";
  SearchFavoritesNotFound: string = "";//Не найдено избранного по указанному фильтру
  OldPassword: string = "";//Текущий пароль
  AddStageToSet: string = "";//Добавить этап
  StandartSet: string = "";//Стандартный
  Next: string = "";

  JobCreateTime: string = "";//дата создания работы

  JobEndTimeOk: string = "";//работа завершена вовремя
  JobEndTimeLost: string = "";//работа просрочена
  NewAccountData: string = "";

  ParameterValueInvalid1: string = "";
  ParameterValueNotInt: string = "";
  ParameterValueNotDecimal: string = "";

  NotificationJobTitle: string = "";
  NotificationMessageTitle: string = "";
}

export class  LanguageSetCommonGiver extends CommonResponse
{
  Set: LanguageSetCommon;
}

export class PossibleLanguageListGiver extends CommonResponse
{
  List:Common[];
}


export abstract class LangSets_Types
{
  public static Common: Common = new Common(0,"Common");
  public static Search: Common = new Common(1,"Search");
  public static Jobs: Common = new Common(2,"Jobs");
  public static Contscts: Common = new Common(3,"Contscts");
  public static Chats: Common = new Common(4,"Chats");
  public static Calendar: Common = new Common(5,"Calendar");
  public static Analytic: Common = new Common(6,"Analytic");
  public static Parameters: Common = new Common(7,"Parameters");
  public static Stages: Common = new Common(8,"Stages");
  public static Autojobs: Common = new Common(9,"Autojobs");
  public static Address: Common = new Common(10,"Address");
  public static Groups: Common = new Common(11,"Groups");
  public static Profile: Common = new Common(12,"Profile");
  public static Files: Common = new Common(13,"Files");
  public static C1: Common = new Common(14,"C1");
  public static Doubles: Common = new Common(15,"Doubles");
  public static Tags: Common = new Common(16,"Tags");
  public static Roles: Common = new Common(17,"Roles");
  public static Login: Common = new Common(18,"Login");
  public static Settings: Common = new Common(19,"Settings");
  public static Storage: Common = new Common(20,"Storage");
}


///////новые сеты языков

export class Common_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

  Minimize: string = ""; //Свернуть
  Maximize: string = ""; //Развернуть
  Add: string = ""; //Добавить
  Remove: string = ""; //Удалить
  Edit: string = ""; //Редактировать
  Clear: string = ""; //Очистить
  Search: string = ""; //Поиск
  Save: string = ""; //Сохранить
  Cancel: string = ""; //Отменить
  Close: string = ""; //Закрыть
  AccessGroups: string = ""; //Группы доступа
  GeneralGroup: string = ""; //Стандартная группа
   General: string = ""; //Стандартный
   GeneralPage: string = ""; //Главная
   Client: string = ""; //Клиент
   Project: string = ""; //Проект
   Settings: string = ""; //Настройки
   Analytics: string = ""; //Аналитика
   Calendar: string = ""; //Календарь
   Notifications: string = ""; //Уведомления
   Messages: string = ""; //Сообщения
   Profile: string = ""; //Профиль
   Status: string = ""; //Статус
   User: string = ""; //Пользователь
   Unlogin: string = ""; //Выйти из профиля
   AddToFavorites: string = ""; //Добавить в избранное
   RemoveFromFavorites: string = ""; //Удалить из избранного
   Info: string = ""; //Подробная информация
   Clients: string = ""; //Клиенты
   Projects: string = ""; //Проекты
   Workers: string = ""; //Сотрудники
   Stage: string = ""; //Этап
   Parameters: string = ""; //Параметры
   Files: string = ""; //Файлы
   BindingsToCounterparties1C: string = ""; //Привязка к контрагентам в 1С
   Union: string = ""; //Холдинг
   Address: string = ""; //Адрес
   BindingsToBills1C: string = ""; //Привязка к счетам 1С
   AddRole: string = ""; //Добавить роль
   RemoveRole: string = ""; //Открепить роль
   AddTag: string = ""; //Добавить тэг
   RemoveTag: string = ""; //Открепить тэг
   DetachClientFromProject: string = ""; //Открепить клиент от проекта
   Attach: string = ""; //Прикрепить
   Detach: string = ""; //Открепить
   FavoritesSearch: string = ""; //Поиск избранного
   FavoritesAllLoaded: string = ""; //Все избранное загружено
   FavoritesNoFilterMatches: string = ""; //Не найдено избранного по указанному фильтру
   Create: string = ""; //Создать
   ProjectInfo: string = ""; //Информация о проекте
   ClientInfo: string = ""; //Информация о клиенте
   ProjectAccess: string = ""; //Доступ к проекту
   ClientAccess: string = ""; //Доступ к клиенту
   CreationDate: string = ""; //Дата создания
   CreatorName: string = ""; //Создатель
   LastChangingDate: string = ""; //Время последнего редактирования
   StageChanging: string = ""; //Смена этапов
   AttachedUsers: string = ""; //Прикрепленные пользователи
   Persons: string = ""; //Контакты
   TransferedToStage: string = ""; //переведен на этап
   ByWorker: string = ""; //пользователем
   CreatedByWorker: string = ""; //добавлен пользователем
   ClientInfoAndRules: string = ""; //{{langset.ClientInfoAndRules}}
   ProjectInfoAndRules: string = ""; //Информация о клиенте и его права доступа
   Users: string = ""; //Пользователи
   CreatedClientUnion: string = ""; //создал холдинг клиентов
   DisbandedClientUnion: string = ""; //{{langset.disbandedclientunion}}
   AddedClient: string = ""; //{{langset.addedclient}}
   ToUnion: string = ""; //{{langset.tounion}}
   RemovedClient: string = ""; //исключил клиента
   FromUnion: string = ""; //{{langset.fromunion}}
   UserSearch: string = ""; //Поиск по пользователям
   Removed: string = ""; //Удален
   ModifiedMg: string = ""; //Изменен
   ModifiedNg: string = ""; //Изменено
   PersonsAllLoaded: string = ""; //Все контакты загружены
   PersonsNotFound: string = ""; //Контактов не найдено
   UserPick: string = ""; //Выбор пользователя
   PickFile: string = ""; //Выберите файл
   RotateLeft: string = ""; //Повернуть налево
   RotateRight: string = ""; //Повернуть направо
   FlipVertical: string = ""; //Отразить по вертикали
   FlipHorizontal: string = ""; //Отразить по горизонтали
   Decrease: string = ""; //Уменьшить
   Increase: string = ""; //Увеличить
   Discard: string = ""; //Сбросить
   DiscardImage: string = ""; //Сбросить изображение
   All: string = ""; //Все
   Jobs: string = ""; //Работы
   Rename: string = ""; //Переименовать
   Clone: string = ""; //Клонировать
   Pick: string = ""; //Выбрать
   MoveDown: string = ""; //Переместить ниже
   MoveUp: string = ""; //Переместить выше
   SelectAll: string = ""; //Выбрать все
   RemoveSelection: string = ""; //Снять выделение
   Move: string = ""; //Переместить
   NotSelectedNg: string = ""; //Не выбрано
   False: string = ""; //ложь
   True: string = ""; //истина
   NotSelectedMg: string = ""; //Не выбран
   AddAll: string = ""; //Добавить всех
   ExcludeAll: string = ""; //Исключить всех
   Back: string = ""; //Назад
   NoResultsForSearch: string = ""; //По запросу ничего не найдено
   FoundOnSearch: string = ""; //По запросу найдено
   SeachResultsAllLoaded: string = ""; //Все результаты запроса загружены
   Next: string = ""; //Далее
   Yes: string = ""; //Да
   No: string = ""; //Нет

   MostaTitle: string = ""; //MOSTA CRM
   Addresses: string = ""; //Адреса
   EmptyFileName: string = ""; //Пустое название файла
   WrongPhotoFormat: string = ""; //Файл не соответствует формату фото(jpg, jpeg, png)
   EmptyList: string = ""; //Список пуст
   AddUser: string = ""; //Добавить пользователя
   NoMatches: string = ""; //Нет совпадений
   DataRefreshing: string = ""; //Обновление данных
   Open: string = ""; //Открыть
   RemovedUsers: string = ""; //Удаленные пользователи
   ShowAll: string = ""; //Показать все
   UserAttachInProcess: string = ""; //Прикрепление пользователя в процессе.
   NoRolesToAdd: string = ""; //Не найдено ролей для добавления
   RemoveClientFromProjectConfirm: string = ""; //Вы действителньо хотите удалить клиент из подключенных к данному проекту?
   RemovePersonFromClientConfirm: string = ""; //Действительно отвязать контакт от клиента?
   AttachedUsers2: string = ""; //Подключенные пользователи
   Added: string = ""; //добавлен
   RemoveUserFromClientConfirm: string = ""; //Действительно открепить пользователя от клиента?
   ForbidRulesToClientConfirm: string = ""; //Вы действительно хотите лишить данную группу прав доступа к данному клиенту?
   ChangeOwnageOwnerConfirm: string = ""; //Вы действительно хотите передать данное владение новому владельцу? Вы не сможете отменить данное действие.
   RemoveClientFromUnionConfirm: string = ""; //Вы действительно хотите удалить клиент из холдинга?
   NewClient: string = ""; //Новый клиент
   NewProject: string = ""; //Новый проект
   EmptyClientName: string = ""; //Название клиента не может быть пустым
   EmptyProjectName: string = ""; //Название проекта не может быть пустым
   NoAccessToAddProjects: string = ""; //Данной группе запрещено добавление новых проектов
    NotAccessGroupsOfUser: string = ""; //Вы не состоите ни в одной группе, и не можете добавлять новые клиенты или проекты
    NoAccessToAddClients: string = ""; //Данной группе запрещено добавление новых клиентoв
    RemoveClientConfirm: string = ""; //Действительно удалить данного клиента?
    RemoveClientFromProjectConfirm2: string = ""; //Вы действительно хотите удалить привязку данного клиента к проекту?
    PickFolderForLoad: string = ""; //Выберите папку для загрузки
    Load: string = ""; //Загрузить
    ForbidRulesToProjectConfirm: string = ""; //Вы действительно хотите лишить данную группу прав доступа к данному проекту?
    Storage: string = ""; //склад

    NoFavoritesAdded: string = ""; //Вы не добавили ничего в избранное
    SelectValue: string = ""; //Выберите значение
    JobNotifications: string = ""; //Уведомления о работе
    NoJobNotifications: string = ""; //Нет уведомлений о работе
    RemoveAllNotifications: string = ""; //Удалить все уведомления
    New: string = ""; //Новое
    Other: string = ""; //Остальное


}



export class Search_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   ClientsOverallCount: string = ""; //Всего клиентов в базе
   ProjectOverallCount: string = ""; //Всего проектов в базе
   ClientOnRequestCount: string = ""; //По запросу найдено клиентов
   ProjectOnRequestCount: string = ""; //По запросу найдено проектов
   RefreshingData: string = ""; //Обновление данных
   NoClientsFound: string = ""; //Клиентов не найдено
   NoProjectsFound: string = ""; //Проектов не найдено
   AddClient: string = ""; //Добавить клиента
   AddProject: string = ""; //Добавить проект
   ClientSearch: string = ""; //Поиск по клиентам
   ProjectSearch: string = ""; //Поиск по проектам
   ClientTagSearch: string = ""; //Поиск по тегам клиента
   ProjectTagSearch: string = ""; //Поиск по тегам проекта
   DbSearch: string = ""; //По базе
   ProjectSearchFilters: string = ""; //Фильтры поиска по проектам

   WithUnion: string = ""; //с холдингом
   WithoutUnion: string = ""; //без холдинга
   NoClientsToPick: string = ""; //Нет клиентов, доступных для выбора
}


export class Jobs_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   NeedChecking: string = ""; //Чек
   DataInputPh: string = ""; //дд.мм.гг
   JobTextPh: string = ""; //Введите содержание работы
   AllSearch: string = ""; //Поиск по всем
   AllUnionClientSearch: string = ""; //Поиск по всем клиентам холдинга
   InWork: string = ""; //В работе
   Rush: string = ""; //Срочно
   NoJobs: string = ""; //Нет работ для загрузки
   Finish: string = ""; //Завершить
   FinishAsAdmin: string = ""; //Завершить как администратор
   JobCreationDate: string = ""; //Дата создания работы
   JobFinishingDate: string = ""; //Дата завершения работы
   FinishedInTime: string = ""; //Завершена вовремя
   FinishedByAdmin: string = ""; //Завершено администратором
   Finished: string = ""; //Завершено
   JobInWorking: string = ""; //Работы выполняется
   JobLost: string = ""; //Работа просрочена
   SendToChecking: string = ""; //Отправить на проверку
   AdditionText: string = ""; //Текст дополнения
   SendReport: string = ""; //Отправить отчет
   Redo: string = ""; //Переделать
   Done: string = ""; //Выполнена
   InWorking: string = ""; //Выполняется
   System: string = ""; //Системная
   JobEnding: string = ""; //Завершение работы
   JobLoading: string = ""; //Загрузка работ
   NoAccessToJobs: string = ""; //У вас нет доступа к работам
   NoAccessToClientJobs: string = ""; //У вас нет доступа к работам клиента
   NoAccessToProjectJobs: string = ""; //У вас нет доступа к работам проекта


   All: string = "";//Все
   EmptyJobText: string = "";//Работа не может быть сохранена без содержания
   EmptyJobDate: string = "";//Вы не можете создать поручение, не указав даты
   JobFinished: string = "";//Работа была завершена
   JobRemoveConfirm: string = "";//Действительно удалить данную работу?
   AtChecking: string = "";//на проверке
   Lost: string = "";//Просрочена
   NoJobsToLoad: string = "";//Нет работ для загрузки
   AllJobsLoaded: string = "";//Все работы загружены
   JobSearch: string = "";//Поиск работ
   JobNeedChecking: string = "";//Данная работа потребует проверки для завершения


   
   NoAccessToWatchProjectJobs: string = ""; //Вы не можете просматривать работы этого проекта
   NoAccessToWatchClientJobs: string = ""; //Вы не можете просматривать работы этого клиента
   Deadline: string = ""; //Срок

}




export class Contacts_LangSet
{

  ChangeTime: Date = new Date() //время последнего редактирования сета

   Name: string = ""; //Имя
   Post: string = ""; //Должность
   ContactInfo: string = ""; //Контактная информация
   InputName: string = ""; //Введите имя
   InputAddress: string = ""; //Введите адрес
   PersonInfo: string = ""; //Информация о контакте
   AttachedProjects: string = ""; //Проекты, к которым прикреплен контакт:
   MoPersonsForLoading: string = ""; //Нет контактов для загрузки
   PersonsLoading: string = ""; //Загрузка контактов
   UseUnion: string = ""; //С учетом холдинга
   AllSystemPersons: string = ""; //Все контакты в системе
   AllPersons: string = ""; //Все контакты
   PickClientToAttach: string = ""; //{{contacts_Langset.PickClientToAttach}}
   AttachedToProject: string = ""; //Связанные с проектом: 
   NoAccessToPersons: string = ""; //У вас нет доступа к контактам
   NoAccessToProjectPersons: string = ""; //У вас нет доступа к контактам проекта
   NoAccessToClientPersons: string = ""; //У нас нет доступа к контактам клиента


   PersonRemoveConfirm: string = "";// Действительно удалить данный контакт?
    RemovePersonFromClientConfirm: string = "";// Действительно отвязать контакт от клиента?
    WithoutUnion: string = "";// Теги
    Created: string = "";// Создан
    Changed: string = "";// Изменен
    ChangedNg: string = "";// Изменено
    MainInProject: string = "";// Главный в проекте
    NoAccessToAttachedClients: string = "";// Нет доступа ни к одному из привязанных клиентов


    
    PersonName: string = ""; //Имя контакта
    PersonPost: string = ""; //Должность контакта
    PersonContactInfo: string = ""; //Контактная информация о контакте
    PersonAddress: string = ""; //Адрес контакта
    PersonTags: string = ""; //Теги контакта
    PersonAttachedClients: string = ""; //Прикрепленные клиенты к контакту
    AllProjectPersons: string = ""; //Все контакты проекта


}


export class Chats_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   NoAccessToChat: string = ""; //У вас нет доступа к чату
   AddChat: string = ""; //Добавить чат
   ClearHistory: string = ""; //Очистить историю
   Reply: string = ""; //Ответить
   Forward: string = ""; //Переслать
   MessagesSelected: string = ""; //Выбрано сообщений
   Send: string = ""; //Отправить
   PickChatToForward: string = ""; //Выберите чат для пересылки
   CloseChat: string = ""; //Закрыть чат
   ToChatList: string = ""; //К списку чатов
   Settings: string = ""; //Настройки
   CantSendEmptyMessage: string = ""; //Нельзя отправить пустое сообщение
   ForwardedMessages: string = ""; //Пересланные сообщения
   MessagesLc: string = ""; //сообщений
   AllMessagesLoaded: string = ""; //Все сообщения загружены
   ToLastMessages: string = ""; //К последним сообщениям
   GoToChat: string = ""; //Перейти к беседе
   CreateGlobalChat: string = ""; //Создать конференцию
   NewChat: string = ""; //Новая беседа
   ChatPhoto: string = ""; //Фото беседы
   LoadingChatAvatar: string = ""; //Загрузка аватара чата
   ConvertToGlobal: string = ""; //Преобразовать в конференцию
   MessagesLoading: string = ""; //Загрузка сообщений
   ExitChat: string = ""; //Выйти из беседы

   ChangeChatName: string = "";//Изменить название конференции
   ChatName: string = "";//Название конференции
   EnterName: string = "";//Введите название
   Chats: string = "";//Чаты
   Messages: string = "";//Сообщения
   ShowAllMessages: string = "";//Показать все сообщения
   ChatUsers: string = "";//Участники беседы
   UserPicking: string = "";//Выбор пользователя
   CantRemoveUSer: string = "";//Нельзя исключить пользователя
   MessageText: string = "";//Текст сообщения
   CantSendMessageToSystemChat: string = "";

}


export class Calendar_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   MonthCalendar: string = ""; //Календарь на месяц
   MonthSh: string = ""; //м
   MondaySh: string = ""; //пн
   TuesdaySh: string = ""; //вт
   WednesdaySh: string = ""; //ср
   ThursdaySh: string = ""; //чт
   FridaySh: string = ""; //пт
   SaturdaySh: string = ""; //сб
   SundaySh: string = ""; //вс
   Monday: string = ""; //Понедельник
   Tuesday: string = ""; //Вторник
   Wednesday: string = ""; //Среда
   Thursday: string = ""; //Четверг
   Friday: string = ""; //Пятница
   Saturday: string = ""; //Суббота
   Sunday: string = ""; //Воскресенье
   December: string = ""; //Декабрь
   Janiary: string = ""; //Январь
   Febriary: string = ""; //Февраль
   March: string = ""; //Март
   April: string = ""; //Апрель
   May: string = ""; //Май
   June: string = ""; //Июнь
   July: string = ""; //Июль
   August: string = ""; //Август
   September: string = ""; //Сентябрь
   October: string = ""; //Октябрь
   November: string = ""; //Ноябрь
   JobSearching: string = ""; //Поиск работ
   Today: string = ""; //Сегодня
   Tomorrow: string = ""; //Завтра
   Other: string = ""; //Остальное
   JobToday: string = ""; //Работа сегодня
   JobTomorrow: string = ""; //Работа завтра
   JobOther: string = ""; //Остальная работа
   PreviousWeek: string = ""; //Предыдущая неделя
   NextWeek: string = ""; //Следующая неделя
   ToCurrentWeek: string = ""; //К текущей неделе
   PreviousDay: string = ""; //Предыдущий день
   NextDay: string = ""; //Следующий день
   AllWeek: string = ""; //Вся неделя

  LastMonth: string = "";//Предыдущий месяц
  NextMonth: string = "";//Следующий месяц
  CurrentMonth: string = "";//Текущий месяц

}


export class Analytic_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   Reports: string = ""; //Отчеты
   Selections: string = ""; //Выборки
   AddSelection: string = ""; //Добавить выборку
   ShowChildSelections: string = ""; //Показать вложенные выборки
   HideChildSelections: string = ""; //Скрыть вложенные выборки
   LinearChart: string = ""; //Временной график
   PieChart: string = ""; //Круговая диаграмма
   BarChart: string = ""; //Столбиковая диаграмма
   Projection: string = ""; //Проекция
   ChildSelections: string = ""; //Вложенная выборка
   AddChildSelections: string = ""; //Добавить вложенную выборку
   AddLinearChart: string = ""; //Добавить временной график
   AddPieChart: string = ""; //Добавить круговую диаграмму
   AddBarChart: string = ""; //Добавить столбиковую диаграмму
   AddProjection: string = ""; //Добавить проекцию
   ToProjection: string = ""; //К проекции
   ToOriginalSelection: string = ""; //К исходной выборке
   LastTableUpdate: string = ""; //Последнее обновление таблицы
   Filters: string = ""; //Фильтры
   Columns: string = ""; //Столбцы
   AddFilter: string = ""; //Добавить фильтр
   AndOr: string = ""; //И / ИЛИ
   Name: string = ""; //Название
   Tags: string = ""; //Теги
   Stages: string = ""; //Этапы
   Workers: string = ""; //Пользователи
   Clients: string = ""; //Клиенты
   Addresses: string = ""; //Адреса
   Parameters: string = ""; //Параметры
   CreationDate: string = ""; //Дата создания
   ChangeWidth: string = ""; //Изменить ширину
   ScreenWide: string = ""; //По ширине экрана
   RefreshChart: string = ""; //{{analytic_Langset.RefreshChart}}
   RefreshTable: string = ""; //Обновить таблицу
   DownloadXlsTable: string = ""; //Скачать таблицу xls
   TableRowsCount: string = ""; //Записей в таблице
   PageRowsCount: string = ""; //Запиcей на странице
   PageSh: string = ""; //Стр.
   PreviousPage: string = ""; //Предыдущая страница
   NextPage: string = ""; //Следующая страница
   ByAscending: string = ""; //По возрастанию
   ByDescending: string = ""; //По убыванию
   ColumnSettings: string = ""; //Настройки колонок
   DiagramSettings: string = ""; //Настройки диаграмм
   AddNewCurve: string = ""; //Добавить новую кривую
   Function: string = ""; //Функция
   FunctionCalculation: string = ""; //Расчет функции
   XAxisStep: string = ""; //Шаг по оси X
   DateRange: string = ""; //Диапазон дат
   DateFrom: string = ""; //с
   DateTo: string = ""; //по
   Today: string = ""; //Сегодня
   ChartIsEmpty: string = ""; //График пуст
   TableIsEmpty: string = ""; //В таблице отсутствуют записи
   AnalyzedSelection: string = ""; //Анализируемая выборка
   CurveColor: string = ""; //Цвет кривой
   Legend: string = ""; //Легенда
   SplitType: string = ""; //Способ разделения
   ColumnOrder: string = ""; //Сортировка столбцов
   Round: string = ""; //Округлять
   AddNewBarGroup: string = ""; //Добавить новую группу столбцов
   AddToReport: string = ""; //Добавить в отчет
   ChartDisplayingType: string = ""; //Способ отображения графиков
   AddNewPieChart: string = ""; //Добавить новый круг
   PieOrder: string = ""; //Сортировка секторов
   ProjectionChain: string = ""; //Цепочка проекций
   OriginalSelectionSettings: string = ""; //Настройки исходной выборки
   InspectReport: string = ""; //Посмотреть отчет
   RefreshReport: string = ""; //Обновить отчет
   AddBreakBefore: string = ""; //Добавить разрыв перед
   AddBreakAfter: string = ""; //Добавить разрыв после
   ToFullScreen: string = ""; //На полный экран
   Status: string = ""; //Статус
   PersonName: string = ""; //Имя контакта
   PersonInfo: string = ""; //Информация контакта
   PersonPost: string = ""; //Должность
   CreatorName: string = ""; //Создатель
   LastChangingDate: string = ""; //Дата последнего изменения
   AttachedTo: string = ""; //Прикреплен


  NoAccessToAnalytics: string = ""; //Нет прав для просмотра аналитики
  CantAddChartToInnerSelection: string = ""; //Нельзя добавить график к вложенной выборке
  ParentSelectionRemoved: string = ""; //Родительская выборка была удалена
  NowAtStage: string = ""; //Сейчас на этапе
  MovedToStage: string = ""; //Переведен на этап
  ParameterFilterSettings: string = ""; //Настройки параметров фильтра
  StageFilterSettings: string = ""; //Настройки этапов фильтра
  After: string = ""; //после
  Before: string = ""; //до
  DuplicatingElements: string = ""; //Повторяющихся элементов
  GetReportResult: string = ""; //Получить результат отчета
  ParentElement: string = ""; //Родительский элемент
  CurrentPage: string = ""; //Текущая страница
  ElementWidth: string = ""; //Ширина элемента
  Load: string = ""; //Загрузить
  InDiffColumns: string = ""; //в разных колонках
  InSingleColumn: string = ""; //в одной колонке
  Show: string = ""; //Выводить
  Projections: string = ""; //Проекции
  ProjectionSettings: string = ""; //Настройки проекции
  Roles: string = ""; //Роли
  AddReport: string = ""; //Добавить отчет
  ShowInnerProjections: string = ""; //Показать вложенные проеции
  CalculatingFunction: string = ""; //Функция расчета
  BorderValues: string = ""; //Граничные значения
  Dates: string = ""; //Даты

  And: string = ""; //И
  Or: string = ""; //ИЛИ
  Including: string = ""; //Включая
  Excluding: string = ""; //Исключая



}


export class Parameters_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   ProjectParameters: string = ""; //Параметры проектов
   ClientParameters: string = ""; //Параметры клиентов
   ProjectParametersSearch: string = ""; //Поиск по параметрам проектов
   ClientParametersSearch: string = ""; //Поиск по параметрам клиентов
   NewParameterCreation: string = ""; //Создание нового параметра
   ParameterName: string = ""; //Название параметра
   Type: string = ""; //Тип
   String: string = ""; //Строковый
   Long: string = ""; //Целочисленный
   Decimal: string = ""; //Десятичный
   Bool: string = ""; //Булево
   ValueSet: string = ""; //Множество значений
   AlwaysShownInProject: string = ""; //Отображать всегда на странице проекта
   UsedInProjectSearch: string = ""; //Значение параметра участвует в поиске по названию проекта
   AlwaysShownInClient: string = ""; //Отображать всегда на странице клиента
   UsedInClientSearch: string = ""; //Значение параметра участвует в поиске по названию клиента
   ShownInSearchResults: string = ""; //Параметр отображается в результатах поиска, избранном
   DefaultValue: string = ""; //Значение по умолчанию
   RoundToP1: string = ""; //округлять до
   RoundToP2: string = ""; //знаков
   SetsForValueSet: string = ""; //Значения для набора
   NewValue: string = ""; //Новое значение
   ParameterEdition: string = ""; //Редактирование параметра
   ParameterAddition: string = ""; //Добавление параметра
   ResetToDefault: string = ""; //Сбросить на значение по умолчанию

   NotSelected: string = ""; // Не выбран
   RemoveParameterConfirm: string = ""; // Вы действительно хотите удалить этот параметр?
   RemoveUsedParameterConfirm: string = ""; // Данный параметр установлен в
   RemoveUsedParameterClientsConfirm: string = ""; // проектах.Вы уверенны, что хотите его удалить?
   RemoveUsedParameterProjectsConfirm: string = ""; // клиентах.Вы уверенны, что хотите его удалить?
   NoParameters: string = ""; // Параметры не найдены
   NullNg: string = ""; // Не определено
   ParameterValue: string = ""; // Значение параметра
   MustBeInteger: string = ""; // должно быть целым числом
   MustBeDecimal: string = ""; // должно быть десятичным числом

}


export class Stages_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   Stages: string = ""; //Этапы
   StageSets: string = ""; //Наборы этапов
   General: string = ""; //Основное
   BusinessLogic: string = ""; //Бизнес-логика
   StageToSetters: string = ""; //Пользователи, которые могут переводить на данный этап
   StageFromSetters: string = ""; //Пользователи, которые могут снимать с данного этапа
   NextStages: string = ""; //Следующие этапы
   PreviousStages: string = ""; //Предыдущие этапы
   StageEditor: string = ""; //Редактор шаблонов
   ToStage: string = ""; //При переводе на этап
   FromStage: string = ""; //При снятии с этапа
   NoTemplatedAdded: string = ""; //Шаблоны не добавлены
   StageColor: string = ""; //Цвет этапа
   PickSetForStage: string = ""; //Выберите набор для добавления этапа
   NewStageSet: string = ""; //Новый набор этапов
   PickStagesToAdd: string = ""; //Выберите этапы для добавления
   StageSet: string = ""; //Набор этапов
   StageSetCreation: string = ""; //Создание набора этапов
   StageCreation: string = ""; //Создание нового этапа
   StageSetEdition: string = ""; //Редактирование набора этапов
   StageEdition: string = ""; //Редактирование этапа
   AddStage: string = ""; //Добавить этап


   StageRemoving: string = ""; //Удаление этапа
   StageRemovingMoveProjects: string = "";//{{stages_Langset.StageRemovingMoveProjects}}
   NewStage: string = ""; //Новый этап
   TemplateMoving: string = ""; //Перемещение шаблонов
   StageSearch: string = ""; //Поиск по этапам

   ProjectsAtStage: string = ""; //Проектов на этапе


}


export class Autojobs_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   ToStage: string = ""; //При переводе на этап
   FromStage: string = ""; //При снятии с этапа
   Template: string = ""; //Шаблон
   AutojobText: string = ""; //Текст автоработы
   Author: string = ""; //Поручитель
   StageChanger: string = ""; //Пользователь, изменяющий этап
   ProjectCreator: string = ""; //Пользователь, создавший проект
   Executor: string = ""; //Исполнитель
   NeedChecking: string = ""; //Чек
   MandatoryJob: string = ""; //Обязательная работа
   LimitDate: string = ""; //Срок
   CreationAtStageSet: string = ""; //Создание при переводе на этап
   CreationAtStageUnset: string = ""; //Создание при снятии с этапа
   CompleteAtStage: string = ""; //Выполнять в рамках этапа
   Block: string = ""; //Заблокировать
   Unblock: string = ""; //Разблокировать
   CantBlockEmptyText: string = ""; //Нельзя заблокировать пустое поле текста
   CantBlockEmptyExecutor: string = ""; //Нельзя заблокировать пустое поле исполнителя
   CantBlockEmptyTags: string = ""; //Нельзя заблокировать пустой список тэгов
   AutojobsCreateToChangeStage: string = ""; //Для изменения этапа необходимо создать следующие автоработы
   NoExecutor: string = ""; //Исполнитель не выбран
   StageChangeFinishing: string = ""; //Завершение изменения этапа
   NoAutojobText: string = ""; //Необходимо ввести текст автоработы
   NoWorkersInRoleInProject: string = ""; //В проект не добавлен ни один пользователь в выбранной роли
   SomeWorkersHasNoAccess: string = "";//"У одного или нескольких выбранных пользователей нет прав доступа к данному проекту
   NoAuthor: string = ""; //Поручитель не выбран
   CreateJobsAndChangeStage: string = ""; //Создать автоработы, сменить этап
   IncorrectFilltedTemplates: string = ""; //Некорректно заполнены шаблоны
   RefreshList: string = ""; //Обновить список

  NoAccessToProject: string = "";//У пользователя нет прав доступа к проекту
  Pick: string = "";//Выбрать
  NoWorkersInRoleInProject2: string = "";//В проект не добавлены пользователи в выбранной роли
  FieldReadonly: string = "";//Поле недоступно для изменения
  FieldReadonlyEdited: string = "";//Было изменено значение поля, недоступного к изменению
  DateIncorrect: string = "";//Некорректно указана дата. Дата не может быть раньше сегодняшней.
  MandatoryTags: string = "";//Обязательные теги
  IncorrectTags: string = "";//Указаны некорректные теги

  
  TemplateRemoveConfirm: string = ""; //Вы действительно хотите удалить этот шаблон
  DeadlineTooLarge: string = ""; //Срок не может превышать 999 дней


}


export class Address_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   MergeCopies: string = ""; //Объединить дубли
   ChangeParentNode: string = ""; //Сменить родительскй узел
   SetMinimalAddresLength: string = ""; //Установить минимальную длину адреса в клиенте
   ShownInShortAddress: string = ""; //Входит в краткое отображение адреса в контактах, аналитике
   AddressLevelEdition: string = ""; //Редактирование уровня адреса
   LevelName: string = ""; //Название уровня
   ChildNodes: string = ""; //Дочерние узлы
   Clients: string = ""; //Клиентов
   Persons: string = ""; //Контактов
   AddAddressLevel: string = ""; //Добавить уровень адреса
   AddressLevelAddition: string = ""; //Добавление уровня адреса
   NewAddressLevelPlacePicking: string = ""; //Выбор расположения нового уровня адреса

   AddressLevelRemoveConfirm: string = "";//Подтвердите удаление уровня адреса
   AddressCreating: string = "";//Создание нового адреса
   AddingNewElements: string = "";//Вы добавляете новые элементы:
   NotSpecified: string = "";//Не указано
   MergeWithDpulicate: string = "";//Объединить с дублем
   ParentNodeChanging: string = "";//Смена родительского узла
   ElementsBeforeMerging: string = "";//Элементы до объединения:
   ElementsAfterMerging: string = "";//Элементы после объединения:
   TableRefreshing: string = "";//Таблица обновляется
   PickFinalElement: string = "";//Выберите конечный элемент
   PickNewParent: string = "";//Выберите нового родителя
   NoMatchesFound: string = "";//Совпадений не найдено


}


export class Groups_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   WorkersAndAccessSettingsTitle: string = ""; // Пользователи, группы, права доступа
   Workers: string = ""; // Пользователи
   Departments: string = ""; // Отделы
   AccessGroups: string = ""; // Группы пользователей
   WorkerSearch: string = ""; // Поиск по пользователям
   WorkerAndGroupSearch: string = ""; // Поиск по пользователям и группам
   Admin: string = ""; // Администратор
   Blocked: string = ""; // Заблокирован
   PickDepartment: string = ""; // Выберите отдел
   ToGeneralList: string = ""; // Поместить в общий список

  
      FullAccessEdition: string = ""; //"Редактирование прав доступа к владению, наследований, глобальных прав, специальных прав

   SystemFunctionRules: string = ""; // Права на использование функций системы
   GlobalRules: string = ""; // Глобальные права
   Inheritages: string = ""; // Наследования
   SpecialRules: string = ""; // Cпециальные права
   ForbidAll: string = ""; // Запретить все
   AllowAll: string = ""; // Разрешить все
   Allowed: string = ""; // Разрешено
   FullAccess: string = ""; // Полный доступ
   Forbidden: string = ""; // Запрещено
   Readonly: string = ""; // Чтение
   GroupPicking: string = ""; // Выбор группы
   GroupSearch: string = ""; // Поиск по группам
   ClientProjectPicking: string = ""; // Выбор клиента / проекта
   NameSearch: string = ""; // Поиск по названию
   OwnageEdition: string = ""; // Редактирование владения пользователя
   Clients: string = ""; // Клиенты
   Projects: string = ""; // Проекты
   ClientOwnageSearch: string = ""; // Поиск по клиентным владениям
   ProjectOwnageSearch: string = ""; // Поиск по проектным владениям
   ExtendedSearch: string = ""; // Расширенный поиск
   Name: string = ""; // Название
   CreatorName: string = ""; // Создатель
   Tags: string = ""; // Теги
   Addesses: string = ""; // Адреса
   HasInheritedRules: string = ""; // Наличие наследуемых прав
   HasSpecialRules: string = ""; // Наличие специальных прав
   NoInherited: string = ""; // Нет наследуемых
   HasInherited: string = ""; // Есть наследуемые
   NoSpecial: string = ""; // Нет специальных
   HasSpecial: string = ""; // Есть специальные
   SelectAll: string = ""; // Выделить все
   UnselectAll: string = ""; // Снять все выделенное
   Found: string = ""; // Найдено
   Selected: string = ""; // Выделено
   ClearSelected: string = ""; // Очистить выделенное
   ChangeOwner: string = ""; // Сменить владельца
   RemoveSpecialRulesOfSelected: string = ""; // Удалить спецправа у выделенных
   ClientRoles: string = ""; // Роли клиентов
   GroupEdition: string = ""; // Редактирование группы
   GroupName: string = ""; // Название группы
   MakeStandart: string = ""; // Сделать стандартной
   HasSpecRules: string = ""; // Есть спецправа
   HasOwner: string = ""; // Есть владелец
   FromGroup: string = ""; // От группы
   Owner: string = ""; // Владелец
   ListIsEmpty: string = ""; // Список пуст

  NewUserAddition: string = ""; //Добавление нового пользователя
  NameSh: string = ""; //Имя
  Mail: string = ""; //Почта
  Password: string = ""; //Пароль
  ConfirmPassword: string = ""; //Повторите пароль
  LanguageString: string = ""; //Язык
  AddingWorkersToGroup: string = ""; //Добавление пользователей в группу
  NoWorkersFound: string = ""; //Работники не найдены
  NewUserData: string = ""; //Новые данные учетной записи
  Worker: string = ""; //Почта пользователя
  NewPassword: string = ""; //Новый пароль
  Done: string = ""; //Готово
  WorkerEdition: string = ""; //Редактирование пользователя
  GroupCreation: string = ""; //Создание группы
  SystemAccess: string = ""; //Доступ к системе
  OwnageRules: string = ""; //Права на владение

  PickClientOwnerOnRemove: string = ""; //Вы не можете удалить данную группу. Сначала выберите нового владельца для клиента.

  RemoveClientRulesConfirm: string = ""; //Вы действительно хотите лишить данную группу прав доступа к данному клиенту?

  RemoveOwnageRulesConfirm: string = ""; //Вы действительно хотите лишить данную группу прав доступа к данному владению?

  MoveOwnageConfirm: string = ""; //Вы действительно хотите передать данное владение новому владельцу? Вы не сможете отменить данное действие.

  NoAccessToAddClients: string = ""; //Данной группе запрещено добавление новых проектов

  NotAccessGroupsOfUser: string = ""; //Вы не состоите ни в одной группе, и не можете добавлять новые клиенты или проекты

  NewProject: string = ""; //Новый проект
  ProjectNameEmpty: string = ""; //Название проекта не может быть пустым
  HasGlobalRules: string = ""; //Имеет глобальные права
  InheritedFrom: string = ""; //Наследуется от
  RuleReduced: string = ""; //Право ограничено
  RuleExtended: string = ""; //Право расширено
  RuleGiven: string = ""; //Правило предоставлено
  PickGroup: string = ""; //Выбрать группу
  SaveChanges: string = ""; //Сохранить изменения
  InheritedRules: string = ""; //Наследуемые права

  ExcludeFromSpecialRules: string = ""; //Исключить из специальных прав
  ExcludeFromInheritedRules: string = ""; //Исключить группу из наследуемых прав


}


export class Profile_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   Profile: string = ""; //Профиль
   Name: string = ""; //Имя
   Email: string = ""; //Email
   Password: string = ""; //Пароль
   ConfirmPassword: string = ""; //Подтвердите пароль
   CurrentPassword: string = ""; //Текущий пароль
   PickMessageSendingWay: string = ""; //Выберите способ отправки сообщений
   MessageSedingWayCtrl: string = ""; //Ctrl + Enter — отправка сообщения, Enter — перенос строки
   MessageSedingWayShift: string = ""; //Enter — отправка сообщения, Shift + Enter — перенос строки
   PickLanguage: string = ""; //Выберите язык
   CloseAllSessionsExceptCurrent: string = ""; //Закрыть все сессии, не включая текущую
   InOwnage: string = ""; //Во владении
   ChangeOwnages: string = ""; //Изменить владения
   SystemAccess: string = ""; //Доступ к системе
   Refresh: string = ""; //Сбросить пароль пользователя
   CloseSessions: string = ""; //Завершить сессии
   Blocking: string = ""; //Блокировка


   AuthProblemLetter: string = "";// Письма о проблемах с авторизацией
   PickPhoto: string = ""// Выберите фото для загрузки
   ProfileSaved: string = "";// Профиль успешно сохранен
   ProfileNotSaved: string = "";// Профиль не был сохранен, пожалуйста попробуйте позже


}


export class Files_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   ExtendedMode: string = ""; //Расширенный режим
   BasicMode: string = ""; //Стандартный режим
   FileSearching: string = ""; //Поиск по файлам
   NewFolderCreation: string = ""; //Создание нового каталога
   InputFolderName: string = ""; //Введите название каталога
   UploadFile: string = ""; //Загрузить файл
   DownloadFile: string = ""; //Скачать файл
   CreateFolder: string = ""; //Создать каталог
   FileInfo: string = ""; //Информация о файле
   Access: string = ""; //Доступ
   All: string = ""; //Все
   Name: string = ""; //Имя
   Size: string = ""; //Размер
   Added: string = ""; //Добавлен
   TagChanges: string = ""; //Изменения тегов
   Tags: string = ""; //Теги
   TagAdded: string = ""; //Добавлен тег
   TagRemoved: string = ""; //Удален тег


   FileFolderRemoved: string = "";//Папка с файлом удалена
   CantRemoveFileOnFolder: string = "";// Нет прав удалять файл в этой папке
   RemoveFileConfirm: string = "";//Действительно удалить данный файл?
   NoAccessDonloadFiles: string = "";//Нет прав скачивать файлы
   FileRemoved: string = "";//Файл удален
   Changed: string = "";//Изменен
   AccessGroupsEdition: string = "";//Изменения прав доступа
   FolderNameIncorrect: string = "";//Имя папки не должно быть пустым или превышать 100 символов
   FolderNameInvalidSymbols: string = "";//Недопустимые символы в названии папки
   ParentFolderRemoved: string = "";//Родительская папка уже удалена
   NoAccessAddFolder: string = "";//Нет прав на добавление папки
   RemoveFolderConfirm: string = "";//Действительно удалить данную папку?
   NoAccessRemoveFolder: string = "";//Нет прав на удаление папки
   NoAccessRemoveFile: string = "";//Нет прав на чистку файлов
   MoveOneFile: string = "";//Перенесите один файл
   NameAlreadyExists: string = "";//Такое название уже существует
   FileReplacement: string = "";//Замена файла
   WithDiffFormats: string = "";//с разными форматами с
   On: string = "";//на
   NameLong: string = "";//Название
   ExName: string = "";//Предыдущее название
   NewName: string = "";//Новое название
   SaveTags: string = "";//Сохранить теги
   ReplaceFile: string = "";//Заменить файл

}


export class C1_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

     LinkToCounterparties1C: string = ""; // Привязка к контрагентам в 1С
     AttachingCpToClient: string = ""; // Прикрепление контрагента к клиенту
     InputNameItn: string = ""; // Введите ИНН / название для поиска КА или ИНН для создания нового
     CreatingCpForClient: string = ""; // Создание нового контрагента для прикрепления к клиенту
     NoCpFound: string = ""; // КА не найден
     MakeCorrectItnSearch: string = ""; // {{c1_Langset.MakeCorrectItnSearch}}
     CreateNewCpWithItn: string = ""; // Создать новый КА с ИНН
     AllResultsLoaded: string = ""; // Все результаты запроса загружены
     Status: string = ""; // Статус
     Address: string = ""; // Адрес
     Draft: string = ""; // Черновик
     Attached: string = ""; // привязан
     InputCpName: string = ""; // Введите название КА
     Name: string = ""; // Название
     TaxpayerNumber: string = ""; // ИНН
     ReasonCode: string = ""; // КПП
     PersonalAccount: string = ""; // Лицевой счет
     BankAccount: string = ""; // Банковский счет или расчетный счет
     BankName: string = ""; // Название банка
     BankIdCode: string = ""; // БИК
     CorrespondentBankAccount: string = ""; // Корреспондентский счет банка
     LegalAddress: string = ""; // Юридический адрес
     LegalForm: string = ""; // Правовая форма
     Note: string = ""; // Комментарий
     AttachCp: string = ""; // Привязать КА
     CpInfo: string = ""; // Полная информация по КА
     CreationDate: string = ""; // Дата создания
     CreatedInCrm: string = ""; // создан в CRM
     CreatedIn1C: string = ""; // создан в 1С
     CreatorName: string = ""; // Создатель
     AttachedToClient: string = ""; // Привязан к клиенту
     NotAttachedToClient: string = ""; // Не привязан к клиенту
     IncomingBills: string = ""; // Входящие счета
     Canceled: string = ""; // Отменен
     NotPayed: string = ""; // Не оплачен
     PartlyPayed: string = ""; // Оплачен частично
     Payed: string = ""; // Оплачен
     Unknown: string = ""; // Неизвестно
     OutcomingBills: string = ""; // Исходящие счета
     ClientChanging: string = ""; // Изменение клиента
     CpSh: string = ""; // КА
     CurrentClient: string = ""; // Текущий клиент
     CpNotAttachedToClient: string = ""; // КА не привязан к клиенту
     NewClientPicking: string = ""; // Выбор нового клиента
     ChangeClient: string = ""; // Сменить клиента
     BillInfo: string = ""; // Полная информация по счету
     Number: string = ""; // Номер
     ApprovedByAccountant: string = ""; // Утвержден бухгалтером
     Sum: string = ""; // Сумма
     Cp: string = ""; // Контрагент
     Incoming: string = ""; // Входящий
     Outgoing: string = ""; // Исходящий
     PayedNg: string = ""; // Оплачено
     Payments: string = ""; // Платежи
     Rubles: string = ""; // руб
     AttachedToProject: string = ""; // Привязан к проекту
     File: string = ""; // Файл
     AddingBillToProject: string = ""; // Добавление счета к проекту
     NotAttachedBillsAndPayments: string = ""; // Счета / платежки из неприкрепленных
     BillCreating: string = ""; // Создание счета
     FoundOnReques: string = ""; // По запросу найдено
     Bill: string = ""; // Счет
     Payment: string = ""; // Платежка
     NoCp: string = ""; // {{c1_Langset.NoCp}}
     BillType: string = ""; // Тип счета
     BillNumber: string = ""; // Номер счета
     InputBillNumber: string = ""; // Введите номер счета
     InputNumber: string = ""; // Введите номер
     InputSum: string = ""; // Введите сумму
     BillFile: string = ""; // Файл со счетом
     UploadOrDndHere: string = ""; // Загрузить или перетащить сюда
     BillNote: string = ""; // Комментарий к счету
     PickingCpForBill: string = ""; // Выбор КА для счета
     Cp1C: string = ""; // Контрагент 1С
     AlreadyAttachedToClient: string = ""; // уже привязан к клиенту
     PickClientForCp: string = ""; // Выберите клиента для привязки КА
     ClientSearch: string = ""; // Поиск по клиентам
     CreateCp: string = ""; // Создать КА
     PinPayment: string = ""; // Прикрепить платежку
     LinkCpToClientAddBillToProject: string = ""; // Связать КА и Клиент и добавить счет к проекту
     CreateCpWithItn: string = ""; // Создать новый КА с ИНН
     ChangeFile: string = ""; // Заменить файл
     AddFilev; // Добавить файл
     SaveBill: string = ""; // Сохранить счет
     EditBill: string = ""; // Редактировать счет
     Accounter: string = ""; // Бухгалтер


     Creation: string = ""; //Создание
    ClientPicking: string = ""; //Выбор клиента
    ChangePinnedClient: string = ""; //Изменить привязанного клиента
    AddingWorkerToProject: string = ""; //Прикрепление нового пользователя к проекту
    BalanceRealPlanned: string = ""; //Сальдо факт / план
    IncomingPl: string = ""; //Входящие
    OutgoingPl: string = ""; //Исходящие
    RevenueFactPlan: string = ""; //Выручка факт / план


    NameNumberBillSearch: string = ""; //Поиск по номеру / названию счета
    RevenueDebt: string = ""; //Выручка / Долг


}

export class Doubles_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   ClientMerging: string = ""; //Слияние клиентов
   TagMerging: string = ""; //Слияние тегов
   ActionCantBeUndone: string = ""; //Данное действие невозможно отменить.
   DuplicateClient: string = ""; //Дублирующий клиент
   FinalClient: string = ""; //Конечный клиент
   PickClientForMerging: string = ""; //Выбор клиента для слияния
   AllClientDataMovingToFinal: string = ""; //Все данные по дублирующему клиенту будут перенесены в конечный.
   AllTagsChangedToFinal: string = ""; //Все теги из списка будут заменены на конечный.
   DuplicateTags: string = ""; //Дублирующие теги
   FinalTag: string = ""; //Конечный тег
   DoMerge: string = ""; //Выполнить слияние


   DataRefresing: string = "";//Обновление данных
   FinalClientRemoved: string = "";//Конечный клиент был удален
   DuplicateClientRemoved: string = "";//Дублирующий клиент был удален
   CantMergeSingleClient: string = "";//Нельзя слить клиент с самим собой
   FinalTagRemoved: string = "";//Конечный тег был удален
   CantMergeSingleTag: string = "";//Нельзя слить тег с самим собой
   ClientSearch: string = "";//Поиск по клиентам
   ClientPickingToMerge: string = "";//Выбор клиента для слияния
   MergeDone: string = "";//Слияние выполнено успешно


}

export class Tags_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   AddTag: string = ""; //Добавить тег
   TagSearch: string = ""; //Поиск по тегам
   TopUserTags: string = ""; //Топ ваших тегов
   TopClientTags: string = ""; //Топ тегов по клиентам
   TopAllTags: string = ""; //Топ всех тегов
   CreateAndPinTag: string = ""; //Создать и выбрать тег
   TopProjectTags: string = ""; //Топ тегов по проектам
   TopJobTags: string = ""; //Топ тегов по работам
   TopPersonTags: string = ""; //Топ тегов по контактам
   TopFileTags: string = ""; //Топ тегов по файлам


   TagAdded: string = ""; //Тег уже был добавлен
   TagNameEmpty: string = ""; //Пустое название тега
   NoTags: string = ""; //Нет тегов

}


export class Roles_LangSet
{
  ChangeTime: Date = new Date; //время последнего редактирования сета

   ClientRoles: string = ""; //Роли клиентов
   WorkerRoles: string = ""; //Роли пользователей
   AddingCLientRole: string = ""; //Создание роли клиентов
   AddingWorkerRole: string = ""; //Создание роли пользователей
   NewRole: string = ""; //Новая роль

   ClientRoleUnremovable: string = "";// Удаление роли клиента невозможно
   ClientRoleUnremovableReplace: string = "";// Невозможно удалить роль. Замените роль в следующих местах:
   ProjectSelections: string = "";// Выборки по проектам
   ClientSelections: string = "";// Выборки по клиентам
   JobSelections: string = "";// Выборки по работам
   FileSelections: string = "";// Выборки по файлам
   ClientToProjectProjections: string = "";// Проекции клиентов на проекты
   ProjectToClientProjections: string = "";// Проекции проектов на клиенты
   ProjectPieCharts: string = "";// Круговые диаграммы проектов
   JobPieCharts: string = "";// Круговые диаграммы работ
   FilePieCharts: string = "";// Круговые диаграммы файлов
   ProjectBarCharts: string = "";// Столбиковые диаграммы проектов
   JobBarCharts: string = "";// Столбиковые диаграммы работ
   FileBarCharts: string = "";// Столбиковые диаграммы файлов
   WorkerRoleUnremovable: string = "";// Удаление роли пользователя невозможно
   AutojobTemplates: string = "";// Шаблоны авторабот
   ClientPieCharts: string = "";// Круговые диаграммы клиентов
   ClientBarCharts: string = "";// Столбиковые диаграммы клиентов
   RoleEdition: string = "";// Редактирование роли
   RoleNewName: string = "";// Новое название роли
   ClientRoleRemoveConfirm: string = "";// Действительно удалить данную роль клиента?
   WorkerRoleRemoveConfirm: string = "";// Действительно удалить данную роль пользователя?


}


export class Login_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета

   Email: string = ""; //Email
   Password: string = ""; //Пароль
   Login: string = ""; //Войти
   ForgotPassword: string = ""; //Забыли пароль?
   CompanyNervousSystem: string = ""; //Нервная система вашей компании
   EmptyEmail: string = ""; //Email не указан
   PasswordEmpty: string = ""; //Пароль не указан
   MailPasswordInvalid: string = ""; //Неверно указан email или пароль
   PasswordRecovering: string = ""; //Восстановление пароля
   InputUsedEmail: string = ""; //Пожалуйста, введите Email, который вы использовали для входа на сайт
   Next: string = ""; //Далее
   RestoringAccessToAccount: string = ""; //Восстановление доступа к аккаунту

  
      LoginProblemNotificationDescription: string = ""; //"Уведомление о проблемах с авторизацией будет отправлено администраторам...

   FullName: string = ""; //ФИО
   GetPasswordReminder: string = ""; //Получить напоминание о пароле
   FullNameEmpty: string = ""; //ФИО не указано
   LoginProblemNotificationSentToAdmins: string = ""; //Уведомление о проблемах с авторизацией было отправлено.
   SendAgain: string = ""; //Отправить повторно
   PasswordSentToMail: string = ""; //На ваш email был отправлен новый пароль для восстановления доступа в систему... 
   EmailInvalid: string = ""; //Почтовый адрес указан неверно
   EmailEmpty: string = ""; //Почтовый адрес не указан
   NoWorkerWithEmailFound: string = ""; //Пользователь с указанной почтой не найден

}


export class Settings_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета
  Profile: string = ""; //Профиль
  Stages: string = ""; //Этапы
  RulesAndWorkersPage: string = ""; //Пользователи, группы, права доступа
  Roles: string = ""; //Роли
  DuplicatesMerging: string = ""; //Слияние дублей
  Parameters: string = ""; //Параметры
  Addresses: string = ""; //Адреса
  Integration1C: string = ""; //1С-интеграция

}

export class Storage_LangSet
{
  ChangeTime: Date = new Date(); //время последнего редактирования сета
  Storage: string = ""; //Склад
  Subgroups: string = ""; //Подгруппы
  Groups: string = ""; //Группы
  GroupListEmpty: string = ""; //Список групп пуст
  ItemNameSearch: string = ""; //Поиск по названию позиции
  NoItemsFound: string = ""; //Позиций не найдено
  AddItem: string = ""; //Добавить позицию
  NameCantBeEmpty: string = ""; //Название не может быть пустым
  GroupNameCantBeEmpty: string = ""; //Название группы не может быть пустым
  SubgroupNameCantBeEmpty: string = ""; //Название подгруппы не может быть пустым
  ItemInfo: string = ""; //Информация о позиции
  Name: string = ""; //Название
  Group: string = ""; //Группа
  Subgroup: string = ""; //Подгруппа
  ItemMovements: string = ""; //Движения по позиции
  ItemReserve: string = ""; //Бронь позиции
  ItemIncome: string = ""; //Приход позиции
  ItemOutcome: string = ""; //Расход позиции
  ItemUnreserve: string = ""; //Снятие брони позиции
  ItemUnreserveToOutcome: string = ""; //Снятие брони и расход позиции
  From: string = ""; //от
  OnProject: string = ""; //по проекту
  Removed: string = ""; //Удален
  Bill: string = ""; //Счет
  BillFile: string = ""; //Файл счета
  Bill1C: string = ""; //Счет 1С
  BillString: string = ""; //Строка счета
  State: string = ""; //Состояние
  StorageCell: string = ""; //Ячейка хранения
  Date: string = ""; //Срок
  Unreserve: string = ""; //Снять бронь
  ToOutcome: string = ""; //В расход
  NoItemMovementDone: string = ""; //Движения по данной позиции еще не совершались
  ItemMovementAdding: string = ""; //Добавление движения по позиции
  Type: string = ""; //Тип
  ItemMovementType: string = ""; //Тип движения
  Count: string = ""; //Количество
  BillType: string = ""; //Тип счета
  Project: string = ""; //Проект
  AddMovement: string = ""; //Добавить движение
  ProjectAdding: string = ""; //Добавление проекта
  ProjectSearch: string = ""; //Поиск проекта
  NoRequestResults: string = ""; //По данному запросу ничего не найдено.Введите другой запрос для поиска.
  BillAdding: string = ""; //Добавление счета
  BillSearch: string = ""; //Поиск счета
  Unreserving: string = ""; //Снятие брони
  UnreservingToOutcome: string = ""; //Перевод брони в расход
  Available: string = ""; //Наличие
  Reserved: string = ""; //Забронированно
  Free: string = ""; //Свободно
  AddItemMovement: string = ""; //Добавить движение по позиции
  ItemNotFound: string = ""; //Позиция не найдена
  StorageCellNotSpecified: string = ""; //Не указана ячейка хранилища
  ProjectNotSpecified: string = ""; //Не указан проект
  CountInvalidBig: string = ""; //Количество не может быть больше свободного
  CountInvalidNegative: string = ""; //Количество не может быть отрицательным
  ItemGroups: string = ""; //Группы позиций
  ItemNameNotUnique: string = ""; //Название позиции должно быть уникальным
  ProjectRemoved: string = ""; //Проект был удален
  ProjectNoAccess: string = ""; //У вас нет доступа к выбранному проекту
  MovementTypeInvalid: string = ""; //Неверно указан тип движения
  BillNotSpecified: string = ""; //Счет не указан
  MovementIsUnreversable: string = ""; //Движение не является бронью или уже отменено
  MandatoryField: string = ""; //Обязательно для заполнения
  NoAccessToReadStorage: string = ""; //У вас нет прав для просмотра склада
  NoAccessToEditStorage: string = "";//У вас нет прав для редактирования склада

  GroupNameNotUnique: string = ""; //  Название группы должно быть уникально
  GroupNotFound: string = ""; //  Группа не найдена
  GroupRemoved: string = ""; //  Группа была удалена
  SubgroupNameNotUnique: string = ""; //  Название подгруппы должно быть уникально
  MovingSubgroup: string = ""; //  При перемещении подгруппы
  ToGroup: string = ""; //  в группу
  SubgroupsWillBeMerged: string = ""; //  подгруппа будет объединена с уже имеющейся в группе подгруппой
  MoveConfirm: string = ""; //  Переместить?
  SubgroupNotFound: string = ""; //  Подгруппа не найдена
  SubgroupRemoved: string = ""; //  Подгруппа была удалена
  GroupEdition: string = ""; //  Редактирование группы
  SubgroupEdition: string = ""; //  Редактирование подгруппы
  ItemEdition: string = ""; //  Редактирование позиции
  ItemRemoved: string = ""; //  Позиция была удалена

  AddNewItem: string = ""; //  Добавить новую позицию
  EditStorages: string = ""; //  Редактировать склады
  OnScreenWidth: string = ""; //  По ширине экрана
  Article: string = ""; //  Артикул
  Comment: string = ""; //  Комментарий
  Select: string = ""; //  Выбрать
  ApplyFilters: string = ""; //  Применить фильтры
  ClearFilters: string = ""; //  Очистить фильтры
  NotesOnPage: string = ""; //  Записей на странице
  Page: string = ""; //  Стр.
  Placement: string = ""; //  Где лежит
  QuantityShort: string = ""; //  Кол-во
  UnitShort: string = ""; //  Ед. изм
  InOutcomes: string = ""; //  Приходы расходы
  Movements: string = ""; //  Перемещения
  LastChangeDate: string = ""; //  Дата последнего изменения
  At: string = ""; //  На
  NoPositionAtStorage: string = ""; //  На складе нет ни одной позиции соответствующей выбранным фильтрам
  ItemAddition: string = ""; //  Добавление позиции
  UnitFull: string = ""; //  Единица измерения
  Files: string = ""; //  Файлы
  StorageEdition: string = ""; //  Редактирование складов
  AddStorage: string = ""; //  Добавить склад
  Responsible: string = ""; //  Ответственный
  Close: string = ""; //  Закрыть
  NoStoragesCreated: string = ""; //  В системе не создано ни одного склада.
  ItemAvailability: string = ""; //  Наличие позиции
  Date0: string = ""; //  Дата
  FromTo: string = ""; //  Откуда/Куда
  IncomeAddition: string = ""; //  Добавление прихода позиции
  ItemName: string = ""; //  Наименование позиции
  ArrivedTo: string = ""; //  Куда пришло
  Bills: string = ""; //  Счета
  OutcomeAddition: string = ""; //  Добавление расхода позиции
  SentFrom: string = ""; //  Откуда ушло
  Search: string = ""; //  Поиск
  Quantity: string = ""; //  Количество
  AtStorage: string = ""; // На складе
  MovementAddition: string = ""; //  Добавление движения позиции
  MovementInfo: string = ""; //  Информация о движения позиции
  CreateDate: string = ""; //  Дата создания
  AdditionInfo: string = ""; //  Информация о приходе позиции
  OutcomeInfo: string = ""; //  Информация о расходе позиции
  BillsAttachment: string = ""; //  Прикрепление счетов
  EmptyRequestResult: string = ""; //  По запросу ничего не найдено
  Attach: string = ""; //  Прикрепить
  ProjectAttachment: string = ""; //  Прикрепление проектов
  AddIncome: string = ""; //  Добавить приход
  AddOutcome: string = ""; //  Добавить расход
  DownloadFile: string = ""; //  Скачать файл
  Save: string = ""; //  Сохранить
  PrevPage: string = ""; //  Предыдущая страница
  SelectedPage: string = ""; //  Выбранная страница
  NextPage: string = ""; //  Следующая страница
  AttachFile: string = ""; //  Прикрепить файл
  ToggleCtrlClick: string = ""; //  Зажмите Ctrl + левую кнопку мыши для скролла с перетаскиванием
  AddBill: string = ""; //  Добавить счет
  AddProject: string = ""; //  Добавить проект
  Clear: string = ""; //  Очистить
  ValueMustBePositiveInt: string = ""; //  Значение поля должно быть целым положительным числом
  InvalidValue: string = ""; //  В поле введено некорректное значение
  StorageWithGoodsUnremovable: string = ""; //  Невозможно удалить склад в котором находятся товары
  EmptyUnit: string = ""; //  Необходимо указать единицу измерения позиции
  EmptyStorageName: string = ""; //  Название склада не может быть пустым
  EmptyItemCount: string = ""; //  Необходимо указать количество единиц позиции
  EmptySelectedStorage: string = ""; //  Необходимо выбрать склад

  //3108
  FileRemoved: string = ""; // Файл был удален
  FileFolderRemoved: string = ""; // Файл с папкой удалена
  StorageNameNotUnique: string = ""; // Склад с таким названием уже существует
  StorageNotFound: string = ""; // Склад не найден
  StorageRemoved: string = ""; // Склад был удален
  StorageNotRemovable: string = ""; // Склад содержит позиции и не может быть удален
  ResponsibleNotFound: string = ""; // Выбранный ответственный пользователь не найден
  ResponsibleRemoved: string = ""; // Пользователь удален / заблокирован и не может стать ответственным
  FileNameInvalid: string = ""; // Некорректное название файла
  FileNotAttachable: string = ""; // Невозможно прикрепить файл
  FileNotRemovable: string = ""; // Невозможно удалить файл
  UnitNotFound: string = ""; // Единица измерения не найдена
  UnitRemoved: string = ""; // Единица измерения удалена
  CommentUpdated: string = ""; // Комментарий обновлен
  ItemUnremovable: string = ""; // Позиция находится на складе и не может быть удалена
  NameTemplateIsNotList: string = ""; // Шаблон названия не является списком
  OnlyListElementUnremovable: string = ""; // Единственный элемент списка не может быть удален
  MovementNotFound: string = ""; // Движение не найдено
  MovementRemoved: string = ""; // Движение было удалено
  BillCountInvalid: string = ""; // Количество счетов не соответствует типу движения
  StorageSourceNotFound: string = ""; // Склад источник не найден
  StorageSourceRemoved: string = ""; // Склад источник был удален
  StorageSourceLowQuantity: string = ""; // Склад источник не имеет нужного количества единиц позиции
  StorageReceiverNotFound: string = ""; // Склад приемник не найден
  StorageReceiverRemoved: string = ""; // Склад приемник был удален
  StorageSourceReceiverMustDiffer: string = ""; // Склад источник и склад приемник должны быть разными
  InvalidItemQuantity: string = ""; // Неверно указано количество единиц позиции
  StorageName: string = ""; // Название склада
  ItemComment: string = ""; // Комментарий к позиции
  StorageChoosing: string = ""; // Выбор склада
  MaxQuantityAtStorage: string = ""; // Максимальное количество на складе
  ProjectNumberSearchTitle: string = ""; // Для поиска по номеру проекта введите символ # и часть номера проекта, например #123
  AddListElement: string = ""; // Добавить элемент списка
  ResetToDefaultValue: string = ""; // Сбросить до значения по умолчанию
  MoreMovements: string = ""; // еще движений
}




export class Common_LangSetGiver extends CommonResponse
{
     Set: Common_LangSet;
}

export class Search_LangSetGiver extends CommonResponse
{
     Set: Search_LangSet;
}

export class Jobs_LangSetGiver extends CommonResponse
{
     Set: Jobs_LangSet;
}

export class Contacts_LangSetGiver extends CommonResponse
{
     Set: Contacts_LangSet;
}

export class Chats_LangSetGiver extends CommonResponse
{
     Set: Chats_LangSet;
}

export class Calendar_LangSetGiver extends CommonResponse
{
     Set: Calendar_LangSet;
}

export class Analytic_LangSetGiver extends CommonResponse
{
     Set: Analytic_LangSet;
}

export class Parameters_LangSetGiver extends CommonResponse
{
     Set: Parameters_LangSet;
}

export class Stages_LangSetGiver extends CommonResponse
{
     Set: Stages_LangSet;
}

export class Autojobs_LangSetGiver extends CommonResponse
{
     Set: Autojobs_LangSet;
}

export class Address_LangSetGiver extends CommonResponse
{
     Set: Address_LangSet;
}

export class Groups_LangSetGiver extends CommonResponse
{
     Set: Groups_LangSet;
}

export class Profile_LangSetGiver extends CommonResponse
{
     Set: Profile_LangSet;
}

export class Files_LangSetGiver extends CommonResponse
{
     Set: Files_LangSet;
}

export class C1_LangSetGiver extends CommonResponse
{
     Set: C1_LangSet;
}

export class Doubles_LangSetGiver extends CommonResponse
{
     Set: Doubles_LangSet;
}

export class Tags_LangSetGiver extends CommonResponse
{
     Set: Tags_LangSet;
}

export class Roles_LangSetGiver extends CommonResponse
{
     Set: Roles_LangSet;
}


export class Login_LangSetGiver extends CommonResponse
{
     Set: Login_LangSet;
}


export class LangSetLoginAsker
{
  Language: number;
}


export class Settings_LangSetGiver extends CommonResponse
{
     Set: Settings_LangSet;
}


export class Storage_LangSetGiver extends CommonResponse
{
     Set: Storage_LangSet;
}



 enum Language
{
    Russian = 0,
    English = 1,
    Vietnam = 2
}


import {CookieOptions} from "ngx-cookie";
import { Injectable } from "@angular/core";
/**
 * Created by MosNeuro on 13.11.2017.
 */
 @Injectable({
  providedIn: 'root'
})
export class CookieOptionsMy
{
  public get():CookieOptions
  {
    let cookieOpt = {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    };
    return cookieOpt;
  }
}

import { createAction, props } from "@ngrx/store";
import { IUser } from "../../models/model.worker";

interface Action {
  type: string
}

export enum EUserAction {
  GetUser = '[User] Get User',
  SetUser = '[User] Save User'
}


export const GetUser = createAction(EUserAction.GetUser);

export const SetUser = createAction(EUserAction.SetUser, props<IUser>());

import { Injectable } from "@angular/core";

@Injectable()
export class SearchSelectHelper {

    // public static getSearchedTextWithoutE(filterText: string, searchedText: string) {
    //     let resultMassive = [];
    //     let resultText = "";
    //     if (filterText != null && filterText != undefined && filterText != "") {
    //         let filtersName = filterText.split(RegExp(/ *\s/, "gi"));

    //         for (var f = 0; f < filtersName.length; f++) {
    //             if (filtersName[f] != "") {
    //                 searchedText.replace(RegExp(filtersName[f], "gi"),
    //                     function replacer(str, offset, s) {
    //                         for (let z = offset; z < str.length + offset; z++) {
    //                             resultMassive.push(z);
    //                         }
    //                         return str;
    //                     });
    //             }
    //         }

    //         resultMassive = resultMassive.filter(function (value, index, self) {
    //             return self.indexOf(value) === index;
    //         });
    //         resultMassive.sort(function (a, b) {
    //             return a - b
    //         });

    //         let word = "";
    //         let wordMassive = [];

    //         resultMassive.forEach((currentValue, index) => {
    //             if ((resultMassive[index] == (resultMassive[index + 1] - 1) || resultMassive[index - 1] == (resultMassive[index] - 1) || index == (resultMassive.length - 1))) {
    //                 if (resultMassive[index] == (resultMassive[index + 1] - 1) && resultMassive[index - 1] != (resultMassive[index] - 1) && index != 0) {
    //                     wordMassive.push(word);
    //                     word = "";
    //                 }
    //                 word = word.concat(searchedText[currentValue]);
    //                 if (index == (resultMassive.length - 1)) {
    //                     wordMassive.push(word);
    //                     word = "";
    //                 }
    //             }
    //             else {
    //                 wordMassive.push(word);
    //                 word = "";
    //             }
    //         });

    //         for (var x = 0; x < searchedText.length; x++) {
    //             if (resultMassive.indexOf(x) >= 0) {
    //                 word = word.concat(searchedText[x]);
    //                 if (x == searchedText.length - 1) {
    //                     resultText = resultText.concat(`<span class="searchedText">${word}</span>`);
    //                     word = "";
    //                 }
    //             }
    //             else {
    //                 if (word != "") {
    //                     resultText = resultText.concat(`<span class="searchedText">${word}</span>`);
    //                 }
    //                 word = "";
    //                 resultText = resultText.concat(searchedText[x]);
    //             }
    //         }

    //         // wordMassive.forEach(element => {
    //         //     let regexp = new RegExp(element, "gi");
    //         //     searchedText = searchedText.replace(regexp, '<span class="searchedText">' + "$&" + '</span>');
    //         // });
    //         wordMassive = [];
    //         resultMassive = [];
    //     }
    //     return resultText;
    // }


    public static getSearchedText(filterText: string, searchedText: string) {
        if (searchedText == null) return;
        let resultMassive: any[] = [];
        let resultText = "";
        filterText = filterText.replace(/ё/gi, "е");
        let searchedTextWithoutE = searchedText.replace(/ё/gi, "е");
        


        if (filterText != null && filterText != undefined && filterText != "") {
            let filtersName = filterText.split(RegExp(/ *[\s,.\+\(\)]/, "gi"));

            for (var f = 0; f < filtersName.length; f++) {
                if (filtersName[f] != "") {
                    let symbols = ".()*$^+,<>/?[]{}':;!#№&";
                    if (symbols.includes(filtersName[f])) {
                        searchedTextWithoutE.replace(RegExp(`\\${filtersName[f]}`, "gi"),
                        function replacer(str, offset, s) {
                            for (let z = offset; z < str.length + offset; z++) {
                                resultMassive.push(z);
                            }
                            return str;
                        });
                    }
                    else{
                        
                        if (filtersName[f].includes("+")) {
                            filtersName[f] =filtersName[f].replace("+","")
                        }
                        
                        
                        searchedTextWithoutE.replace(RegExp(filtersName[f], "gi"),
                            function replacer(str, offset, s) {
                                for (let z = offset; z < str.length + offset; z++) {
                                    resultMassive.push(z);
                                }
                                return str;
                            });
                            }
                }
            }
            resultMassive = resultMassive.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
            resultMassive.sort(function (a, b) {
                return a - b
            });

            let word = "";
            let wordMassive = [];
            
            

            resultMassive.forEach((currentValue, index) => {
                if ((resultMassive[index] == (resultMassive[index + 1] - 1) || resultMassive[index - 1] == (resultMassive[index] - 1) || index == (resultMassive.length - 1))) {
                    if (resultMassive[index] == (resultMassive[index + 1] - 1) && resultMassive[index - 1] != (resultMassive[index] - 1) && index != 0) {
                        wordMassive.push(word);
                        word = "";
                    }
                    word = word.concat(searchedText[currentValue]);
                    if (index == (resultMassive.length - 1)) {
                        wordMassive.push(word);
                        word = "";
                    }
                }
                else {
                    wordMassive.push(word);
                    word = "";
                }
            });

            for (var x = 0; x < searchedText.length; x++) {
                if (resultMassive.indexOf(x) >= 0) {
                    word = word.concat(searchedText[x]);
                    if (x == searchedText.length - 1) {
                        resultText = resultText.concat(`<span class="searchedText">${word}</span>`);
                        word = "";
                    }
                }
                else {
                    if (word != "") {
                        resultText = resultText.concat(`<span class="searchedText">${word}</span>`);
                    }
                    word = "";
                    resultText = resultText.concat(searchedText[x]);
                }
            }

            // wordMassive.forEach(element => {
            //     let regexp = new RegExp(element, "gi");
            //     searchedText = searchedText.replace(regexp, '<span class="searchedText">' + "$&" + '</span>');
            // });
            wordMassive = [];
            resultMassive = [];
        }
        //console.log(resultText);
        return resultText;
    }


}
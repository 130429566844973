/**
 * Created by MosNeuro on 08.09.2017.
 */
import {CommonResponse} from './model.http';


export class LangSetAutojob

{
  Language:number;
  AutoJob:string=""; //Авторабота
  AutoJobTemplate:string=""; //Шаблон
  AutoJobTemplateEditor:string=""; //Редактор шаблонов авторабот этапа
  AutoJobAuthor:string=""; //Поручатель
  StageChanger:string=""; //Пользователь, изменяющий этап
  ProjectCreator:string=""; //Пользователь, создавший проект
  AutoJobAuthorSelection:string=""; //Выбор поручателя
  Executors:string=""; //Исполнители
  NeedChecking:string=""; //Чек
  DueDate:string=""; //Срок
  MandatoryJob:string=""; //Обязательная работа
  CreateWhen:string=""; //Создание при
  CreateToStage:string=""; //переводе на этап
  CreateFromStage:string=""; //снятии с этапа
  PerformOnStage:string=""; //Выполнять в рамках этапа
  RefreshList:string=""; //Обновить список
  TemplatesToStage:string=""; //Шаблоны авторабот перевода на этап
  TemplatesFromStage:string=""; //Шаблоны авторабот снятия с этапа
  MovingTemplates:string=""; //Перемещение шаблонов
  //MovingTemplatesFromStage:string=""; //Перемещение шаблонов из списка снятия с этапа
  //MovingTemplatesToStage:string=""; //Перемещение шаблонов из списка перевода на этап
  AutoJobEnterText:string=""; //Введите содержание автоработы
  SelectAll:string=""; //Выделить все
  DeselectAll:string=""; //Снять все выделения
  MoveToAnotherStage:string=""; //Переместить в другой этап
  MoveToStage:string=""; //Переместить на этап
  Move:string=""; //Переместить
  ChangeCompletion:string=""; //Завершение изменения
  CreateAutoJobsToChangeStage:string=""; //Для изменения этапа необходимо создать следующие автоработы
  FinishStageChange:string=""; //Создать автоработы, сменить этап
  NotAssingedMany:string=""; //Не назначены
  NotAssingedOne:string=""; //Не выбран / (Не назначен)
  AddTemplate:string=""; //Добавить шаблон работы
  BlockChangesWhenChangingStage:string=""; //Запретить изменения при смене этапа
  ChangesBlockedWhenChangingStage:string=""; //Запрещено изменять при смене этапа
  NoWorkerInRole:string=""; //В проекте отсутствует сотрудник в роли
  NeedAddWorkerInRole:string=""; //Для смены этапа и создания автоработы необходимо добавить хотя бы одного сотрудника в выбранной роли.
  CantBlockEmptyExecutor:string=""; //Нельзя заблокировать пустое поле исполнителя.
  CantBlockEmptyText:string=""; //Нельзя заблокировать пустое поле текста.
  CantBlockEmptyTags:string=""; //Нельзя заблокировать пустой список тэгов.
  ToChangeStageEnterMandatoryFields:string=""; //Для перевода этапа и создания авторабот требуется заполнить все обязательные поля в следующих автоработах:
  NeedAddWorkerInRoles:string=""; //В проект необходимо добавить пользователя в одной из указанных ролей
  WhileSettingToStage:string="";//При переводе на этап
  WhileSettingFromStage:string="";//При снятии с этапа

}

export class LangSetAutoJobGiver extends CommonResponse
{
  Set: LangSetAutojob;
}

import { ConfirmDoneIcon, CloseIcon, ErrorIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { FormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'date-field',
    templateUrl: './date-field.component.html',
    styleUrls: ['./date-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class DateFieldComponent implements OnInit {


    @Input() borderOnHover:boolean=false
    
    @Input() value: Date = null;
    valueBuffer: number=null;
    @Input() placeholder: string="Значение";
    @Input() clearBtnText: string="Очистить";
    @Input() clearBtn: boolean=true;
    @Input() disabled: boolean=false;
    @Input() min_size: boolean=false;
    
    @Output()
    valueChange = new EventEmitter<Date>();
    @Output()
    cleared = new EventEmitter<boolean>();
   
    @Output()
    escKey = new EventEmitter<any>();

    @Output()
    enterKey = new EventEmitter<any>();
    @Output()
    blurInput = new EventEmitter<any>();

    inputCorrect:boolean=true;

    errorText: string="";


    showWarning: boolean = false;
    @ViewChild('dayInput', { static: false }) dayInputElem: ElementRef;
    @ViewChild('monthInput', { static: false }) monthInputElem: ElementRef;
    @ViewChild('yearInput', { static: false }) yearInputElem: ElementRef;
    @ViewChild('dateInput', { static: false }) dateInputElem: ElementRef;
   // value:Date=null;

   @ViewChild('dateContainer', { static: false }) dateContainerElem: ElementRef;
   @Input() filterValueDay: string=null;
   @Input() filterValueMonth: string=null;
   @Input() filterValueYear: string=null;


    lastDateField: number=-1;
    isFocused: boolean=false;



    @Output()
    filterValueDayChange = new EventEmitter<string>();
    @Output()
    filterValueMonthChange = new EventEmitter<string>();
    @Output()
    filterValueYearChange = new EventEmitter<string>();
    @Output()
    keyUp = new EventEmitter<KeyboardEvent>();
    @Output()
    keyDown = new EventEmitter<KeyboardEvent>();
    @Input() selectInput: Subject<number> = new Subject();
    @Input() valueChanged: Subject<any[]> = new Subject();
    @Input() valueCleared: Subject<any[]> = new Subject();

    @Input() blurInputEv: Subject<boolean> = new Subject();
    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon,
            ErrorIcon
        ])
    }




    @HostListener('document:click', ['$event'])
              public onClickActive(e: Event): void {
                  if(this.isFocused && !this.dateContainerElem.nativeElement.contains(e.target))
                  {
                   this.fieldBlur();
                   this.isFocused=false;
                  }
              }

    ngOnInit() {
        
        if(typeof(this.value)=="string")
        {
            let dt = new Date(this.value);
            this.value=dt;
        }
        //this.valueBuffer=this.value;
        if(this.value!=null)
        {
            
            this.filterValueDay=this.value.getDate()<10?"0"+this.value.getDate().toString():this.value.getDate().toString();
            this.filterValueMonth=this.value.getMonth()+1<10? "0"+(this.value.getMonth()+1).toString():(this.value.getMonth()+1).toString();
            
            let yearstring=this.value.getFullYear().toString();
            while(yearstring.length<4)
            {
              yearstring="0"+yearstring;
            }
            this.filterValueYear=yearstring;
        }
        else
        {
            
            //this.filterValueDay=null;
             //   this.filterValueMonth=null;
              //  this.filterValueYear=null; 
        }

        this.selectInput.subscribe(c => {
            this.selectDateFilterElement(c);
          });

          this.blurInputEv.subscribe(c => {
            
                   this.fieldBlur();
                   this.isFocused=false;
                  
          });

          this.valueCleared.subscribe(c => {
            this.value=null;
            this.filterValueDay=null;
            this.filterValueMonth=null;
            this.filterValueYear=null;
          })

          this.valueChanged.subscribe(c => {
            
            
            this.value=c[0];
            if(typeof(this.value)=="string")
            {
                let dt = new Date(this.value);
                this.value=dt;
            }
            if(this.value!=null)
            {
              
                this.filterValueDay=this.value.getDate()<10?"0"+this.value.getDate().toString():this.value.getDate().toString();
                this.filterValueMonth=this.value.getMonth()+1<10? "0"+(this.value.getMonth()+1).toString():(this.value.getMonth()+1).toString();
               // this.filterValueYear=this.value.getFullYear().toString();

                let yearstring=this.value.getFullYear().toString();
            while(yearstring.length<4)
            {
              yearstring="0"+yearstring;
            }
            this.filterValueYear=yearstring;
            }
            else
            {
                
                this.filterValueDay=c[1];
                this.filterValueMonth=c[2];
                this.filterValueYear=c[3]; 
            }
            
          });
    }


    fieldBlur()
    {
      
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      
      if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
      {
        
        if(Number(this.filterValueYear)!=0 && Number(this.filterValueDay)!=0 && Number(this.filterValueMonth)!=0)
        {
          
          if((Number(this.filterValueMonth)==4 || Number(this.filterValueMonth)==6 || Number(this.filterValueMonth)==9 || Number(this.filterValueMonth)==11 ) && Number(this.filterValueDay)>30)
          {
            container.classList.add("error_date")
            setTimeout(()=>{
              this.filterValueDay="30";
              this.filterValueDayChange.emit(this.filterValueDay);
             
                let newDate= new Date();
                newDate.setUTCHours(0);
                newDate.setUTCMinutes(0);
                newDate.setUTCFullYear(Number(this.filterValueYear));
                newDate.setUTCDate(Number(this.filterValueDay));
                newDate.setUTCMonth(Number(this.filterValueMonth)-1);
               this.value=newDate;
               //this.value.setUTCDate(Number(this.filterValueDay));
                //this.filterValueYearChange.emit(this.filterValueYear);
                this.valueChange.emit(this.value);
                container.classList.remove("error_date")
                
              
      
          },350)
          }
          if(Number(this.filterValueMonth)==2 && (Number(this.filterValueDay)>29 ||(Number(this.filterValueDay)>28 && Number(this.filterValueYear)%4!=0)))
          {
            container.classList.add("error_date")

            setTimeout(()=>{
              this.filterValueDay="29";
              if(Number(this.filterValueYear)%4!=0)
              {
                this.filterValueDay="28";
              }
              this.filterValueMonth="02";
              this.filterValueDayChange.emit(this.filterValueDay);
              this.filterValueMonthChange.emit(this.filterValueMonth);
                
                /*console.log(Number(this.filterValueYear))
              console.log(Number(this.filterValueDay))
              console.log(Number(this.filterValueMonth)-1)
              let newDate= new Date(Number(this.filterValueYear),(Number(this.filterValueMonth)-1),Number(this.filterValueDay),0,0,0,0);
              console.log(newDate)
                //newDate.setUTCHours(0,0,0,0);
                //newDate.setUTCHours(0,0,0)
                //newDate.setMinutes(0);
                //newDate.setUTCFullYear(Number(this.filterValueYear),Number(this.filterValueMonth)-1,Number(this.filterValueDay));
               // newDate.setUTCMonth(Number(this.filterValueMonth)-1);
               // newDate.setUTCDate(Number(this.filterValueDay));


                console.log(this.value)
                console.log(newDate)
                this.value=newDate;

                //this.value.setUTCDate(Number(this.filterValueDay));
                //this.value.setUTCMonth(Number(this.filterValueMonth)-1,Number(this.filterValueDay));
                //this.filterValueYearChange.emit(this.filterValueYear);
                console.log(this.value)*/

                
                let newDate= new Date();
                newDate.setUTCHours(0,0,0,0);
                newDate.setUTCFullYear(Number(this.filterValueYear));
                newDate.setUTCDate(Number(this.filterValueDay));
                newDate.setUTCMonth(Number(this.filterValueMonth)-1);
                this.value=newDate;
                this.valueChange.emit(this.value);
                container.classList.remove("error_date")
              
      
          },350)
          }
        }
        else
        {
          container.classList.add("error_date")
        }
      }



        this.blurInput.emit(true);
    }
   
    onDateFilterChange(event: Event= null)
    {
      this.isFocused=true;
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
     //if(this.value!=null)
    // {
      this.filterValueDay=this.value.getDate()<10?"0"+this.value.getDate().toString():this.value.getDate().toString();
      this.filterValueMonth=this.value.getMonth()+1<10? "0"+(this.value.getMonth()+1).toString():(this.value.getMonth()+1).toString();
      //this.filterValueYear=this.value.getFullYear().toString();
      let yearstring=this.value.getFullYear().toString();
            while(yearstring.length<4)
            {
              yearstring="0"+yearstring;
            }
            this.filterValueYear=yearstring;
      this.valueChange.emit(this.value)
      this.filterValueDayChange.emit(this.filterValueDay)
      this.filterValueMonthChange.emit(this.filterValueMonth)
      this.filterValueYearChange.emit(this.filterValueYear)
     //}
     

      //this.search();
    }


    clear()
    {
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
        this.value=null;
        this.filterValueDay=null;
        this.filterValueMonth=null;
        this.filterValueYear=null;
        this.filterValueDayChange.emit(this.filterValueDay);
        this.filterValueYearChange.emit(this.filterValueYear);
        this.filterValueMonthChange.emit(this.filterValueMonth);
        this.valueChange.emit(this.value);
       this.cleared.emit(true);
       this.selectDateFilterElement(0);
    }

    selectDateFilterElement(type: number=0)
    {
      this.isFocused=true;
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
        let inp;
        if(type==0)
        {
         inp =<HTMLElement>this.dayInputElem.nativeElement;
         this.dayInputElem.nativeElement.focus();
        }
        if(type==1)
        {
          inp =<HTMLElement>this.monthInputElem.nativeElement;
          this.monthInputElem.nativeElement.focus();
        }
        if(type==2)
        {
          inp =<HTMLElement>this.yearInputElem.nativeElement;
          this.yearInputElem.nativeElement.focus();
        }
        
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
        this.lastDateField=-1;
    }

    checkButtonDay(ev:KeyboardEvent)
    {
      this.isFocused=true;
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
      ev.preventDefault();
      if(ev.keyCode==39)
      {
        this.monthInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.monthInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
        
      }


      if(ev.keyCode==8 || ev.keyCode==46)
      {
        this.value=null;
        this.filterValueDay=null;
        this.lastDateField=-1;
        this.dayInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.dayInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
        this.filterValueDayChange.emit(this.filterValueDay);
        this.valueChange.emit(this.value);
      }
      
      if(ev.key.match(/^\d$/))
      {
      if(this.lastDateField==0)
      {
        //добавляем к уже имеющейся
        if(this.filterValueDay.toString().length==2 && this.filterValueDay[0]!="0")
        {
          this.selectDateFilterElement(1);
          this.checkButtonMonth(ev);
        }
        else
        {
          let newVal=Number(this.filterValueDay.toString()+ev.key);
          if (newVal>31)
          {
            newVal=31;
            
          }
          let datestring=newVal.toString();
          if(datestring.length==1)
          {
            datestring="0"+datestring;
          }
          this.filterValueDay=datestring;
       // this.filterValueDay=newVal.toString();
        this.filterValueDayChange.emit(this.filterValueDay);
        //this.valueChange.emit(this.value);
        this.selectDateFilterElement(1);
        }
      }
      else
      {
        //затираем старое значение
       
        this.filterValueDay="0"+ev.key;

        this.lastDateField=0;
        if(Number(this.filterValueDay)>3)
        {
          this.selectDateFilterElement(1);
        }
        this.filterValueDayChange.emit(this.filterValueDay);
        //this.valueChange.emit(this.value);
       
      }
      if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
      {
        if(Number(this.filterValueYear)!=0 && Number(this.filterValueDay)!=0 && Number(this.filterValueMonth)!=0)
        {
          let newDate= new Date();
          newDate.setUTCHours(0);
                newDate.setUTCMinutes(0);
          newDate.setUTCFullYear(Number(this.filterValueYear));
          newDate.setUTCDate(Number(this.filterValueDay));
          newDate.setUTCMonth(Number(this.filterValueMonth)-1);
          this.value=newDate;
          //this.filterValueYearChange.emit(this.filterValueYear);
          this.valueChange.emit(this.value);
        }
      }
      else
      {
        this.value=null;
        this.valueChange.emit(this.value);
      }
      
    }
      this.keyDown.emit(ev);
    }

    checkButtonMonth(ev:KeyboardEvent)
    {
      this.isFocused=true;
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
      ev.preventDefault()

      
     


      if(ev.keyCode==37)
      {
        this.dayInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.dayInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);

      }
      if(ev.keyCode==39)
      {
        this.yearInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.yearInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
      }


      

      if(ev.keyCode==8 || ev.keyCode==46)
      {
        this.value=null;
        this.filterValueMonth=null;
        this.lastDateField=-1;
        this.monthInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.monthInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
        this.filterValueMonthChange.emit(this.filterValueMonth);
        this.valueChange.emit(this.value);
      }

      if(ev.key.match(/^\d$/))
      {
      if(this.lastDateField==1)
      {
        //добавляем к уже имеющейся
        
        if(this.filterValueMonth.toString().length==2 && this.filterValueMonth[0]!="0")
        {
          this.selectDateFilterElement(2);
          this.checkButtonYear(ev);
        }
        else
        {
          let newVal=Number(this.filterValueMonth.toString()+ev.key);
          if (newVal>12)
          {
            newVal=12;
            
          }

          let datestring=newVal.toString();
          if(datestring.length==1)
          {
            datestring="0"+datestring;
          }
          this.filterValueMonth=datestring;
       // this.filterValueMonth=newVal.toString();
        this.selectDateFilterElement(2);
        this.filterValueMonthChange.emit(this.filterValueMonth);
        //this.valueChange.emit(this.value);
        }
          
        
      }
      else
      {
        //затираем старое значение
        
        this.filterValueMonth="0"+ev.key;
        this.lastDateField=1;
        if(Number(this.filterValueMonth)>1)
        {
          this.selectDateFilterElement(2);
        }
        this.filterValueMonthChange.emit(this.filterValueMonth);
        //this.valueChange.emit(this.value);
        
      }

      if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
      {
        if(Number(this.filterValueYear)!=0 && Number(this.filterValueDay)!=0 && Number(this.filterValueMonth)!=0)
        {
          let newDate= new Date();
          newDate.setUTCHours(0);
                newDate.setUTCMinutes(0);
          newDate.setUTCFullYear(Number(this.filterValueYear));
          newDate.setUTCDate(Number(this.filterValueDay));
          newDate.setUTCMonth(Number(this.filterValueMonth)-1);
          this.value=newDate;
          //this.filterValueYearChange.emit(this.filterValueYear);
          this.valueChange.emit(this.value);
        }
      }
      else
      {
        this.value=null;
        //this.filterValueMonthChange.emit(this.filterValueMonth);
        this.valueChange.emit(this.value);
      }
      
    }

    this.keyDown.emit(ev);
    }

    checkButtonYear(ev:KeyboardEvent)
    {
      this.isFocused=true;
      let container=<HTMLElement>this.dateContainerElem.nativeElement;
      container.classList.remove("error_date");
      ev.preventDefault()

     

      
      if(ev.keyCode==37)
      {
        this.monthInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.monthInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
      }


      
      if(ev.keyCode==8 || ev.keyCode==46)
      {
        this.value=null;
        this.filterValueYear=null;
        this.lastDateField=-1;
        this.yearInputElem.nativeElement.focus();
        let inp =<HTMLElement>this.yearInputElem.nativeElement;
        let selection= document.getSelection();
        selection.removeAllRanges();
        let range = document.createRange();
        range.selectNode(inp)
        selection.addRange(range);
        this.filterValueYearChange.emit(this.filterValueYear);
        this.valueChange.emit(this.value);
      }

      if(ev.key.match(/^\d$/))
        {
      if(this.lastDateField==2)
      {
        //добавляем к уже имеющейся
        //добавляем к уже имеющейся
        if(this.filterValueYear.toString().length<4 ||this.filterValueYear.toString()[0]=='0')
        {
          this.filterValueYear=this.filterValueYear.substring(1)+ev.key;
          this.filterValueYearChange.emit(this.filterValueYear);
       // this.valueChange.emit(this.value);
        }
        
      }
      else
      {
        //затираем старое значение
        
        this.filterValueYear="000"+ev.key;
        this.filterValueYearChange.emit(this.filterValueYear);
        //this.valueChange.emit(this.value);
        this.lastDateField=2
        
      }

      if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
      {
        if(Number(this.filterValueYear)!=0 && Number(this.filterValueDay)!=0 && Number(this.filterValueMonth)!=0)
        {
          let newDate= new Date();
          newDate.setUTCHours(0);
                newDate.setUTCMinutes(0);
          newDate.setUTCFullYear(Number(this.filterValueYear));
          newDate.setUTCDate(Number(this.filterValueDay));
          newDate.setUTCMonth(Number(this.filterValueMonth)-1);
          this.value=newDate;

          //this.filterValueYearChange.emit(this.filterValueYear);
          this.valueChange.emit(this.value);
        }
        
      }
      else
      {
        this.value=null;
       // this.filterValueYearChange.emit(this.filterValueYear);
        this.valueChange.emit(this.value);
      }
      
    }

    this.keyDown.emit(ev);
    }
    





    parseDate(dateString: string): Date {
 
        if (dateString) {
            return new Date(dateString);
        }
        return null;
      }


}

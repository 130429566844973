import { GetSession, LoadSession } from './../store/actions/session.action';
import { ISessionState } from './../store/state/session.state';
import {throwError as observableThrowError, Observable, BehaviorSubject, Subject} from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';



import { select, Store } from '@ngrx/store';
import { selectSession } from '../store/selectors/session.selectors';
import { Address_LangSet, Analytic_LangSet, Autojobs_LangSet, C1_LangSet, Calendar_LangSet, Chats_LangSet, Common_LangSet, Contacts_LangSet, Doubles_LangSet, Files_LangSet, Groups_LangSet, Jobs_LangSet, LangSets_Types, LanguageSetCommon, LanguageSetCommonGiver, Login_LangSet, Parameters_LangSet, Profile_LangSet, Roles_LangSet, Search_LangSet, Stages_LangSet, Tags_LangSet } from '../models/model.language';
import { LocalStorageService } from 'angular-2-local-storage';
import { selectUserId, selectSessionKey } from '../store/selectors/session.selectors';
import { CommonRequest } from '../models/model.http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerString } from '../config/serverstring';
import * as _ from 'lodash';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { IAppState } from '../store/state/app.state';
import { HttpServiceSession } from '../httpserv/httpserv.session';
import { Router } from '@angular/router';
import { WorkerGiver, WorkerProfile } from '../models/model.worker';
import { HttpServiceWorker } from '../httpserv/httpserv.worker';
import { LoginGiver } from '../models/model.login';


@Injectable({
    providedIn: 'root'
})
export class SessionService {

  private IsSessionChecked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSessionChecked$: Observable<boolean> = this.IsSessionChecked.asObservable();
  private UserId: BehaviorSubject<number> = new BehaviorSubject(0);
  userId$: Observable<number> = this.UserId.asObservable();
  private SessionKey: BehaviorSubject<string> = new BehaviorSubject('');
  sessionKey$: Observable<string> = this.SessionKey.asObservable();
  private jwtToken: BehaviorSubject<string> = new BehaviorSubject('');
  jwtToken$: Observable<string> = this.jwtToken.asObservable();


  private RefreshUser: Subject<boolean> = new Subject();
  refreshUser$: Observable<boolean> = this.RefreshUser.asObservable();

  private SessionDeleted: Subject<boolean> = new Subject();
  sessionDeleted$: Observable<boolean> = this.SessionDeleted.asObservable();


  checedOnServer: Subject<boolean> = new Subject();
  checedOnServer$: Observable<boolean> = this.checedOnServer.asObservable();


  



  CurrentWorker: BehaviorSubject<WorkerProfile> = new BehaviorSubject(null);
  currentWorker$: Observable<WorkerProfile> = this.CurrentWorker.asObservable();

  private sessionChecking: boolean=false;

  private setCurrentWorker(newValue: WorkerProfile) {
    this.CurrentWorker.next(newValue);
  }


  private setSessionKey(newValue: string) {
    this.SessionKey.next(newValue);
  }

  private setJWT(newValue: string) {
    this.jwtToken.next(newValue);
  }

  private setUserId(newValue: number) {
    this.UserId.next(newValue);
  }


  private setSessionChecked(newValue: boolean) {
    this.IsSessionChecked.next(newValue);
  }

  private setRefreshUser(newValue: boolean)
  {
    this.RefreshUser.next(newValue);
  }

  private  setSessionDeleted(newValue: boolean)
  {
    this.SessionDeleted.next(newValue);
  }



  private LoadCurrentWorker() {
    let checker = new CommonRequest();
    checker.SessionKey = this.SessionKey.value;
    checker.ChangerId = this.UserId.value;
    checker.UserAuthToken = this.jwtToken.value;
    this.httpServiceWorker.getWorker(checker).subscribe((data: WorkerGiver) => {
      if (data.Code == 1) {
        this.unLogin();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      if(data.Code==0)
      {this.setCurrentWorker(data.Worker);}
      
      //this.cookieService.put(this.workerStatusCookieName, data.Worker.Status, this.cookieOptionsMy.get());
    });
  }
    
    constructor(private localStorageService: LocalStorageService,private router: Router,  private http: HttpClient, private param: ServerString, private store$: Store<IAppState>, private httpServiceSession: HttpServiceSession, private httpServiceWorker: HttpServiceWorker) { 
     
      
     // this.CheckSessionInit(); 
     window.addEventListener("storage",(ev)=>{

      if(ev.key.includes("currentSession") || ev.key=="currentSession")
      {


        let ses=JSON.parse(JSON.parse(ev.newValue));
        if(ses!=null && ses.id!=undefined && ses.sessionKey!=undefined && ses.sessionKey!=null && ses.id!=undefined && ses.id!=null
          /* && ses.jwtToken!=undefined && ses.jwtToken!=null */)
        {
          this.setSession(ses.id, ses.sessionKey, ses.jwtToken)
        }
        else
        {
          this.deleteSession();
          let link = ['/login'];
          

            if (location.href.match('/project')){
              link = [`/login/p-${location.href.split('/')[4]}`];
            }
            if(location.href.match('/client')){
              link = [`/login/c-${location.href.split('/')[4]}`];
            }
            
          this.router.navigate(link);
        }
        
      }
      })
    }



   

    setSession(id: number, key: string, jwt: string)
    {

      this.setUserId(id);
      this.setSessionKey(key);
      this.setJWT(jwt);
      this.localStorageService.set("currentSession", JSON.stringify({"id": id, "sessionKey": key, "jwtToken": jwt}));

      this.currentWorker$.pipe(first(worker=>worker!=null)).subscribe((worker)=>{
        if(worker!=null)
        {this.setRefreshUser(true);}
      })
          this.LoadCurrentWorker();
      
    }


    deleteSession()
    {
      this.setSessionKey("");
      this.setUserId(0);
      this.setJWT("");
      this.localStorageService.remove("currentSession");
      this.setCurrentWorker(null);
      this.setSessionDeleted(true);
    }



    /*CheckSessionInit()
    {

      //////для переноса сессиии из стора (для первого старта, потом можно убрать)
      let sessionInStorageCheck =JSON.parse(this.localStorageService.get("sessionFromStore"));
      if(sessionInStorageCheck==undefined || sessionInStorageCheck==null || sessionInStorageCheck=="")
      {
        const storageState = JSON.parse(localStorage.getItem("session"));
        if(storageState!=undefined && storageState !=null && storageState.id!=undefined && storageState.id!=null && storageState.id!=0)
        {
          let curSes= {"id": storageState.id, "sessionKey": storageState.sessionKey, "jwtToken": storageState.jwtToken};
          this.localStorageService.set("sessionFromStore", true);
          this.localStorageService.set("currentSession", JSON.stringify(curSes));
        }
        else
        {
          this.unLogin();
        }
        
      }



      let sessionInStorage =JSON.parse(this.localStorageService.get("currentSession"));
      if(sessionInStorage!=undefined && sessionInStorage!=null)
      {
        //this.setUserId(sessionInStorage.id);
        //this.setUserId(sessionInStorage.id)

        this.checkSessionOnServer(sessionInStorage)
      }
      else
      {
        this.setSessionChecked(true);
        ////////////redirect to login
        this.unLogin();
      }
    }


    checkSessionOnServer(session: any)
    {
      console.log("sdsdf")
      let sessionChecker = new CommonRequest();
      sessionChecker.SessionKey = session.sessionKey;
      sessionChecker.ChangerId = session.id;
      this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
        if (data.Code == 1) {//если нужно заменить сессию
          this.setSessionKey(data.Value);
          this.setUserId(session.id);
          sessionChecker.SessionKey = data.Value;
          this.localStorageService.set("currentSession", JSON.stringify({"id": session.id, "sessionKey": data.Value}));
          this.setSessionChecked(true);
        }
        if (data.Code == 301 || data.Code == 302 || data.Code == 303 || data.Code == 304 || data.Code == 500) {
          console.error(data.Message);
          this.setSessionChecked(true);
          this.unLogin();//если все хуево и наша сессия закончена закрываем сессию идем логиниться
          return;
        }
        if (data.Code == 306) {
          this.setSessionChecked(false);
          console.log('The request object is filled incorrectly.');
          return;
        }
        if (data.Code == 0 || data.Code == 1) {//иначе все ок и продолжаем рабоать
          this.setSessionKey(session.sessionKey);
          this.setUserId(session.id);
          this.setSessionChecked(true);
        }
      });

      
    }*/




    CheckSession()
    {

    }


    CheckSessionInit()
    {

     //console.log("cheeeeeeeeeeeeeeeeeeeeeeeck", location.href)
      //////для переноса сессиии из стора (для первого старта, потом можно убрать)
     /* let sessionInStorageCheck =JSON.parse(this.localStorageService.get("sessionFromStore"));
      if(sessionInStorageCheck==undefined || sessionInStorageCheck==null || sessionInStorageCheck=="")
      {
        const storageState = JSON.parse(localStorage.getItem("session"));
        if(storageState!=undefined && storageState !=null && storageState.id!=undefined && storageState.id!=null && storageState.id!=0)
        {
          let curSes= {"id": storageState.id, "sessionKey": storageState.sessionKey};
          this.localStorageService.set("sessionFromStore", true);
          this.localStorageService.set("currentSession", JSON.stringify(curSes));
        }
        else
        {
          this.clearSession();
        }
        
      }*/



      let sessionInStorage =JSON.parse(this.localStorageService.get("currentSession"));
      if(sessionInStorage!=undefined && sessionInStorage!=null)
      {
        //this.setUserId(sessionInStorage.id);
        //this.setUserId(sessionInStorage.id)
      this.checedOnServer$.subscribe((data)=>{
        if(data===true)
        {

          this.currentWorker$.pipe(first(worker=>worker!=null)).subscribe((worker)=>{if(worker!=null)
            {this.setSessionChecked(true);}
          });
          this.LoadCurrentWorker();
          //this.setSessionChecked(true);
        }
        
        
      });
        this.checkSessionOnServer(sessionInStorage);
      }
      else
      {
        this.setSessionChecked(true);
        ////////////redirect to login
        this.clearSession();
      }
    }


    checkSessionOnServer(session: any)
    {
      if(this.sessionChecking)
      {return}
      let sessionChecker = new CommonRequest();
      sessionChecker.SessionKey = session.sessionKey;
      sessionChecker.ChangerId = session.id;
      sessionChecker.UserAuthToken = session.jwtToken;

      this.sessionChecking=true;
      this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
        if (data.Code == 1) {//если нужно заменить сессию
          this.setSessionKey(data.SessionKey);
          this.setUserId(session.id);
          this.setJWT(session.jwtToken);
          sessionChecker.SessionKey = data.SessionKey;
          sessionChecker.UserAuthToken = data.UserAuthToken;
          this.localStorageService.set("currentSession", JSON.stringify({"id": session.id, "sessionKey": data.SessionKey, "jwtToken": data.UserAuthToken}));
          this.sessionChecking=false;
          //this.setSessionChecked(true);
        }
        if (data.Code == 301 || data.Code == 302 || data.Code == 303 || data.Code == 304 || data.Code == 500) {
          console.error(data.Message);
          this.setSessionChecked(true);
         this.sessionChecking=false;
          this.clearSession();//если все хуево и наша сессия закончена закрываем сессию идем логиниться
         // return;
         // return;
        }
        if (data.Code == 306) {
         // this.setSessionChecked(false);
         this.sessionChecking=false;
          console.log('The request object is filled incorrectly.');
          return;
        }
        if (data.Code == 305) {
          alert("Истек срок использования CRM системы МОСТА. Для продления лицензии свяжитесь с вашим администратором.")
           return;
         }

        if (data.Code == 0) {//иначе все ок и продолжаем рабоать
          this.setSessionKey(session.sessionKey);
          this.setUserId(session.id);
          this.setJWT(session.jwtToken);
          this.sessionChecking=false;
          //this.setSessionChecked(true);


          this.checedOnServer.next(true);
        }

        //this.checedOnServer.next(true);
      });

      
    }





    unLogin(pageLink: string="/login")
    {

      if(this.sessionChecking)
      {return}

      this.sessionChecking=true;
      let currentSessionInStorage=JSON.parse(this.localStorageService.get("currentSession"));
      if(currentSessionInStorage==undefined || currentSessionInStorage==null ||currentSessionInStorage=="")
      {
        this.deleteSession();
        this.sessionChecking=false;
          let link = ['/login'];
          

            if (location.href.match('/project')){
              link = [`/login/p-${location.href.split('/')[4]}`];
            }
            if(location.href.match('/client')){
              link = [`/login/c-${location.href.split('/')[4]}`];
            }
            
          this.router.navigate(link);
      }

      else
      {


        
        let SessionKeyInStorage = currentSessionInStorage.sessionKey;
        let UserIdInStorage = currentSessionInStorage.id;
        let JWTInStorage = currentSessionInStorage.jwtToken;

        if(SessionKeyInStorage==undefined || SessionKeyInStorage==null || SessionKeyInStorage=="" || UserIdInStorage==undefined || UserIdInStorage==null
        /*  || JWTInStorage==null || JWTInStorage=="" */)
        {
          this.deleteSession();
          this.sessionChecking=false;
          let link = ['/login'];
          

            if (location.href.match('/project')){
              link = [`/login/p-${location.href.split('/')[4]}`];
            }
            if(location.href.match('/client')){
              link = [`/login/c-${location.href.split('/')[4]}`];
            }
            
          this.router.navigate(link);
        }


        else
        {
          let sessionChecker = new CommonRequest();
          if(SessionKeyInStorage==this.SessionKey.value && UserIdInStorage==this.UserId.value)
          {
            sessionChecker.SessionKey =this.SessionKey.value;
            sessionChecker.ChangerId = this.UserId.value;
            sessionChecker.UserAuthToken = this.jwtToken.value;
            this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
              if (data.Code == 1) {//если нужно заменить сессию
                this.setSessionKey(data.SessionKey);
                this.setUserId(this.UserId.value);
                this.setJWT(data.UserAuthToken);
                //sessionChecker.SessionKey = data.Value;
                this.localStorageService.set("currentSession", JSON.stringify({"id": this.UserId.value, "sessionKey": data.SessionKey, "jwtToken": data.UserAuthToken}));
                //this.setSessionChecked(true);
                this.sessionChecking=false;
              }
              
              
              if (data.Code == 0 ) {//иначе все ок и продолжаем рабоать
                this.sessionChecking=false;
              }
  
              if(data.Code!=0 && data.Code!=1)
              {
                this.deleteSession();
                this.sessionChecking=false;
                let link = ['/login'];
                
  
                  if (location.href.match('/project')){
                    link = [`/login/p-${location.href.split('/')[4]}`];
                  }
                  if(location.href.match('/client')){
                    link = [`/login/c-${location.href.split('/')[4]}`];
                  }
                  
                this.router.navigate(link);
                }
  
            });
          }

          


          else
          {
            sessionChecker.SessionKey =SessionKeyInStorage;
            sessionChecker.ChangerId = UserIdInStorage;
            sessionChecker.UserAuthToken = JWTInStorage;
            this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
              if (data.Code == 1) {//если нужно заменить сессию
                this.setSessionKey(data.SessionKey);
                this.setUserId(this.UserId.value);
                this.setJWT(data.UserAuthToken);
                //sessionChecker.SessionKey = data.Value;
                this.localStorageService.set("currentSession", JSON.stringify({"id": this.UserId.value, "sessionKey": data.SessionKey, "jwtToken": data.UserAuthToken}));
                //this.setSessionChecked(true);
                this.sessionChecking=false;
              }
              
              
              if (data.Code == 0 ) {//иначе все ок и продолжаем рабоать
                this.setSessionKey(SessionKeyInStorage);
                this.setUserId(UserIdInStorage);
                this.setJWT(JWTInStorage);
                //sessionChecker.SessionKey = data.Value;
                this.localStorageService.set("currentSession", JSON.stringify({"id": UserIdInStorage, "sessionKey": SessionKeyInStorage, "jwtToken": JWTInStorage}));
                this.sessionChecking=false;
              }
  
              if(data.Code!=0 && data.Code!=1)
              {
                this.deleteSession();
                this.sessionChecking=false;
                let link = ['/login'];
                
  
                  if (location.href.match('/project')){
                    link = [`/login/p-${location.href.split('/')[4]}`];
                  }
                  if(location.href.match('/client')){
                    link = [`/login/c-${location.href.split('/')[4]}`];
                  }
                  
                this.router.navigate(link);
                }
  
            });
          }
        }
      


       

          
        
          

        
      }


      
      




      
    }



    clearSession()
    {
      //this.deleteSession();
     // setTimeout(()=>{
        this.setSessionKey("");
      this.setUserId(0);
      this.setJWT("");
      this.localStorageService.remove("currentSession");
      this.setCurrentWorker(null);
      this.setSessionDeleted(true);
        let link = ['/login'];
      
        if (location.href.match('/project')){
          link = [`/login/p-${location.href.split('/')[4]}`];
        }
        if(location.href.match('/client')){
          link = [`/login/c-${location.href.split('/')[4]}`];
        }
        

      console.log("gotologin"+link)
      this.router.navigate(link);
     // },300)
      
    }


    RefreshWorker()
    {
      this.LoadCurrentWorker();
    }
}


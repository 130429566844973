
import {AddressAutocompleteElementInner} from "./model.AddressAutocompleteElementInner";
import {AddressAutocompleteChain} from "../../In/Any/Autocomplete/model.AddressAutocompleteChain";

export class AddressAutocompleteChainInner {
  SheetId:number;
  Selected:boolean;
  Components: AddressAutocompleteElementInner[];


  constructor(c:AddressAutocompleteChain)
  {
    this.SheetId = c.SheetId;
    this.Components = c.Components.map(c=> new AddressAutocompleteElementInner(c));
  }


}

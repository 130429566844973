/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import { LocalStorageService } from 'angular-2-local-storage';
import { ClientInProjectFilterWithUnion } from '../../models/model.ClientInProjectFilter';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientLight } from '../../models/model.client';
import { ProjectLight } from '../../models/model.project';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TagHelper } from '../../Helpers/taghelper';
import { ElementWithVisible } from '../../models/model.elementWithVisible';
import * as _ from 'lodash';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { AutoJobTemplateEditAsker, AutoJobTemplateFullContainer, AutoJobTemplateInSettings, MultiIdRequest } from '../../models/model.Autojob/model.AutojobTemplate';
import { LangSetAutojob, LangSetAutoJobGiver } from '../../models/model.autojobLanguage';
import { CommonRequest, CommonResponse } from '../../models/model.http';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { HttpServiceLanguage } from '../../httpserv/httpserv.language';
import { AutojobMultyselectElementValue, MultyselectElement } from '../../models/model.MultyselectContainer';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { HttpServiceAutojob } from '../../httpserv/httpserv.autojob';
import { CommonWorkerHolder } from '../../models/model.workerAttached';
import { Common } from '../../models/model.common';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { Autojobs_LangSet, Common_LangSet, Jobs_LangSet } from '../../models/model.language';


declare var require: any;


@Component({
  selector: 'autojobInSettingsContainer',
  templateUrl: 'temp.autojobInSettingsContainer.html',
  styleUrls: ['autojobInSettingsContainer.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',],

    providers: [HttpServiceLanguage, HttpServiceAutojob],

})
export class AutojobInSettingsContainerComponent implements OnInit {
  
  @Input() currentTemplate: AutoJobTemplateFullContainer;
  @Output() ClearTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() DeleteTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateChangeAuthor: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateDeleteExecutor: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateAddExecutor: EventEmitter<any> = new EventEmitter<any>();
  
 
  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();
  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  UserId: number;
  SessionKey: string;
  entityTypeJob: number = 0;
  isMultiselectsInit: boolean=false;

  authorMultiselectTitle: string="Поручитель";
  authorMultiselectPlaceholder: string="исполнитель";
  authorHolders: CommonWorkerHolder[]=[];
  authorRoles: Common[]=[];
  authorAdditionalValues: AutojobMultyselectElementValue[]=[];
  authorRolesOuterType: number=0;
  authorUsersOuterType: number=1;
  authorSelectedUsers: Common[]=[];
  authorSelectedRoles: Common[]=[];
  authorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];


  executorMultiselectTitle: string="Поручитель";
  executorMultiselectPlaceholder: string="исполнитель";
  executorHolders: CommonWorkerHolder[]=[];
  executorRoles: Common[]=[];
  executorAdditionalValues: AutojobMultyselectElementValue[]=[];
  executorRolesOuterType: number=0;
  executorUsersOuterType: number=1;
  executorSelectedUsers: Common[]=[];
  executorSelectedRoles: Common[]=[];
  executorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];
  langset: Common_LangSet;
  ln_sub: Subscription=null;
  autojobs_Langset: Autojobs_LangSet;
  aj_sub: Subscription=null;
  jobs_Langset: Jobs_LangSet;
  jb_sub: Subscription=null;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private store$: Store,
    private httpServiceLanguage: HttpServiceLanguage,
    private httpServiceAutojob: HttpServiceAutojob,
    private sessionService: SessionService,
    private languageService: LanguageService
  ) { }




  ngOnInit() {
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.aj_sub=this.languageService.autojobsLanguage$.subscribe(lang=>this.autojobs_Langset=lang);
    this.jb_sub=this.languageService.jobsLanguage$.subscribe(lang=>this.jobs_Langset=lang);
    this.InitMultiselects();
    
  }

  close() {

  }


  InitMultiselects()
  {
    this.isMultiselectsInit=false;

    this.authorMultiselectTitle=this.autojobs_Langset.Author;
    this.authorMultiselectPlaceholder=this.langset.SelectValue//"Выберите значение";
    this.authorHolders=this.currentTemplate.Template.Container.Holders;
    this.authorRoles=this.currentTemplate.Template.Container.Roles;
    this.authorAdditionalValues=[];
    let adval = new AutojobMultyselectElementValue();
    adval.Id=1;
    adval.ValueOuterType=1;
    adval.Name=this.autojobs_Langset.StageChanger;
    this.authorAdditionalValues.push(_.cloneDeep(adval));
    adval.Id=2;
    adval.ValueOuterType=2;
    adval.Name= this.autojobs_Langset.ProjectCreator ;
    this.authorAdditionalValues.push(_.cloneDeep(adval));
    this.authorRolesOuterType=3;
    this.authorUsersOuterType=4;
    this.authorSelectedUsers = [];
    this.authorSelectedRoles=[];
    this.authorSelectedAdditionalValues=[];

    if(this.currentTemplate.Template.Container.AuthorType==1)
    {
      this.authorSelectedAdditionalValues.push(this.authorAdditionalValues[0]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==2)
    {
      this.authorSelectedAdditionalValues.push(this.authorAdditionalValues[1]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==3)
    {
      let indR = this.currentTemplate.Template.Container.Roles.findIndex(c=>c.Id==this.currentTemplate.Template.Container.AuthorId);
      this.authorSelectedRoles.push(this.currentTemplate.Template.Container.Roles[indR]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==4)
    {
      

      for(let i=0; i<this.currentTemplate.Template.Container.Holders.length; i++)
      {
        if(this.currentTemplate.Template.Container.Holders[i].IsWorker && this.currentTemplate.Template.Container.Holders[i].Worker.Id==this.currentTemplate.Template.Container.AuthorId)
        {
          this.authorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Worker);
        }
        if(!this.currentTemplate.Template.Container.Holders[i].IsWorker)
        {
          for(let j=0; j<this.currentTemplate.Template.Container.Holders[i].Departament.Workers.length; j++)
          {
            if(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j].Id==this.currentTemplate.Template.Container.AuthorId)
            {
              this.authorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j]);
            }
          }
        }
      }
    }






    this.executorMultiselectTitle=this.autojobs_Langset.Executor;
    this.executorMultiselectPlaceholder=this.langset.SelectValue//"Выберите значение";
    this.executorHolders=this.currentTemplate.Template.Container.Holders;
    this.executorRoles=this.currentTemplate.Template.Container.Roles;
    this.executorAdditionalValues=[];
    
    this.executorRolesOuterType=1;
    this.executorUsersOuterType=2;
    this.executorSelectedUsers = [];
    this.executorSelectedRoles=[];
    this.executorSelectedAdditionalValues=[];

    
    if(this.currentTemplate.Template.Container.ExecutorRoles!=undefined && this.currentTemplate.Template.Container.ExecutorRoles!=null && this.currentTemplate.Template.Container.ExecutorRoles.length>0)
    {
      for(let j=0; j<this.currentTemplate.Template.Container.ExecutorRoles.length; j++)
      {
        let indR = this.currentTemplate.Template.Container.Roles.findIndex(c=>c.Id==this.currentTemplate.Template.Container.ExecutorRoles[j]);
        if(indR>-1)
        {
          this.executorSelectedRoles.push(this.currentTemplate.Template.Container.Roles[indR]);
        }
        
      }
      
    }




    if(this.currentTemplate.Template.Container.ExecutorWorkers!=undefined && this.currentTemplate.Template.Container.ExecutorWorkers!=null && this.currentTemplate.Template.Container.ExecutorWorkers.length>0)
    {
      
      for(let k=0; k<this.currentTemplate.Template.Container.ExecutorWorkers.length; k++)
      {
        for(let i=0; i<this.currentTemplate.Template.Container.Holders.length; i++)
        {
          if(this.currentTemplate.Template.Container.Holders[i].IsWorker && this.currentTemplate.Template.Container.Holders[i].Worker.Id==this.currentTemplate.Template.Container.ExecutorWorkers[k])
          {
            this.executorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Worker);
          }
          if(!this.currentTemplate.Template.Container.Holders[i].IsWorker)
          {
            for(let j=0; j<this.currentTemplate.Template.Container.Holders[i].Departament.Workers.length; j++)
            {
              if(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j].Id==this.currentTemplate.Template.Container.ExecutorWorkers[k])
              {
                this.executorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j]);
              }
            }
          }
        }
      }

      
    }






    this.isMultiselectsInit=true;
  }


  
    
    ClearCurrentTemplate()
    { 
      this.currentTemplate.Template.Container.AuthorId = null;
      this.currentTemplate.Template.Container.AuthorLocked = false;
      this.currentTemplate.Template.Container.AuthorType = 2;
      //template.Template.Container.AutoJobType=type;
      this.currentTemplate.Template.Container.CompleteOnStage = true;
      this.currentTemplate.Template.Container.ExecutorLocked = false;
      this.currentTemplate.Template.Container.ExecutorRoles = [];
      this.currentTemplate.Template.Container.ExecutorWorkers = [];
      this.currentTemplate.Template.Container.IsMandatory = false;
      this.currentTemplate.Template.Container.NeedChecking = false;
      this.currentTemplate.Template.Container.NeedCheckingLocked = false;
      this.currentTemplate.Template.Container.Tags = [];
      this.currentTemplate.Template.Container.Text = "";
      this.currentTemplate.Template.Name = "";
      this.currentTemplate.Template.Container.Name = "";
      this.currentTemplate.Template.Container.TimeLocked = false;
      this.currentTemplate.Template.Container.Time = 1;
      this.currentTemplate.Template.Container.TagsLocked = false;
      this.currentTemplate.ExecutorsMultyselect.UnselectAll();
      this.currentTemplate.AuthorMultyselect.UnselectAll();
      this.EditCurrentTemplate();
    }

    DeleteCurrentTemplate()
    {
      
      if (!confirm('Вы действительно хотите удалить этот шаблон?')) return;
      let checker = new MultiIdRequest();
      checker.ChangerId = this.UserId;
      checker.SessionKey = this.SessionKey;
      checker.List = [];
      checker.List.push(this.currentTemplate.Template.Id);
      this.httpServiceAutojob.DeleteAutojobTemplate(checker).subscribe((data: CommonResponse) => {
        if (data.Code == 100) {
          //this.unLogin(data.Message);
          return;
        }
        if (data.Code == 300) {
          console.log(data.Message);
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        if (data.Code == 0)
        { this.DeleteTemplate.emit(this.currentTemplate.Template.Id);}

      });
    }


    EditCurrentTemplate( indicatorId = "")
    {

      let checker = new AutoJobTemplateEditAsker();
      checker.ChangerId = this.UserId;
      checker.SessionKey = this.SessionKey;
      checker.Id = this.currentTemplate.Template.Id;


      checker.AuthorId = this.currentTemplate.Template.Container.AuthorId;
      checker.AuthorLocked = this.currentTemplate.Template.Container.AuthorLocked;
      checker.AuthorType = this.currentTemplate.Template.Container.AuthorType;
      checker.AutoJobType = this.currentTemplate.Template.Container.AutoJobType;
      checker.CompleteOnStage = this.currentTemplate.Template.Container.CompleteOnStage;
      checker.ExecutorLocked = this.currentTemplate.Template.Container.ExecutorLocked;
      checker.ExecutorRoles = this.currentTemplate.Template.Container.ExecutorRoles;
      checker.ExecutorWorkers = this.currentTemplate.Template.Container.ExecutorWorkers;
      if ((this.currentTemplate.Template.Container.ExecutorWorkers == null || this.currentTemplate.Template.Container.ExecutorWorkers == []) && (this.currentTemplate.Template.Container.ExecutorRoles == null || this.currentTemplate.Template.Container.ExecutorRoles == [])) {
        this.currentTemplate.Template.Container.ExecutorLocked = false;
        checker.ExecutorLocked = false;
      }
      checker.IsMandatory = this.currentTemplate.Template.Container.IsMandatory;
      checker.NeedChecking = this.currentTemplate.Template.Container.NeedChecking;
      checker.NeedCheckingLocked = this.currentTemplate.Template.Container.NeedCheckingLocked;
      checker.Tags = this.currentTemplate.Template.Container.Tags;
      checker.Text = this.currentTemplate.Template.Container.Text;

      checker.TextLocked = this.currentTemplate.Template.Container.TextLocked;
      if (this.currentTemplate.Template.Container.Text == null || this.currentTemplate.Template.Container.Text == '') {
        checker.TextLocked = false;
        this.currentTemplate.Template.Container.TextLocked = false;
      }
      checker.TimeLocked = this.currentTemplate.Template.Container.TimeLocked;
      if (this.currentTemplate.Template.Container.Time > 999) {
        this.currentTemplate.Template.Container.Time = 999;
        alert("Срок не может превышать 999 дней");
      }
      if (this.currentTemplate.Template.Container.Time < 1) {
        this.currentTemplate.Template.Container.Time = 1;
        alert("Срок не может быть меньше 1 дня");
      }
      checker.Time = this.currentTemplate.Template.Container.Time;
      checker.TagsLocked = this.currentTemplate.Template.Container.TagsLocked;

      this.httpServiceAutojob.EditAutojobTemplateContainer(checker).subscribe((data: CommonResponse) => {
        if (data.Code == 100) {
          //this.unLogin(data.Message);
          return;
        }
        if (data.Code == 300 || data.Code == 301 || data.Code == 302) {
          console.log(data.Message);
          return;
        }
        if (data.Code == 200) {
          alert(data.Message);
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        if (indicatorId != "" && indicatorId != null) {
          let indicator = document.getElementById(indicatorId);
          indicator.style.opacity = "1";
          setTimeout(() => { indicator.style.opacity = "0" }, 1200)
        }
      });
    }


    CurrentTemplateChangeAuthor( delegate: number[])
    {
      
      this.currentTemplate.Template.Container.AuthorType =delegate[0];
      if(this.currentTemplate.Template.Container.AuthorType<3)
      {
        this.currentTemplate.Template.Container.AuthorId=-1;
      }
      else
      {
        this.currentTemplate.Template.Container.AuthorId=delegate[1];
      }
      let indicatorId = "";
      indicatorId = "currentTemplateAuthor" + this.currentTemplate.Template.Id;
      this.EditCurrentTemplate( indicatorId);
    }
 

    CurrentTemplateDeleteExecutor( delegate: number[])
    {
      if (delegate[0] == 1) {
        let execInd = this.currentTemplate.Template.Container.ExecutorRoles.indexOf(delegate[1]);
        if (execInd > -1)
        this.currentTemplate.Template.Container.ExecutorRoles.splice(execInd, 1);
      }
      if (delegate[0] == 2) {
        let execInd = this.currentTemplate.Template.Container.ExecutorWorkers.indexOf(delegate[1]);
        if (execInd > -1)
        this.currentTemplate.Template.Container.ExecutorWorkers.splice(execInd, 1);
      }
  
      let indicatorId = "";
      indicatorId = "currentTemplateExecutor" +this.currentTemplate.Template.Id; 
      this.EditCurrentTemplate(indicatorId);
    }

    CurrentTemplateAddExecutor( delegate: number[])
    {
      if (delegate[0] == 1) {
        this.currentTemplate.Template.Container.ExecutorRoles.push(delegate[1]);
      }
      if (delegate[0] == 2) {
        this.currentTemplate.Template.Container.ExecutorWorkers.push(delegate[1]);
      }
      
  
      let indicatorId = "";
      indicatorId = "currentTemplateExecutor" +this.currentTemplate.Template.Id; 
      this.EditCurrentTemplate(indicatorId);
    }




    DeleteTagFromTemplate(tagId: number) {
      
        for (let i = 0; i < this.currentTemplate.Template.Container.Tags.length; i++) {
          if (this.currentTemplate.Template.Container.Tags[i].Id == tagId) {
  
            this.currentTemplate.Template.Container.Tags.splice(i, 1);
            break;
  
          }
        }
  
        for (let i = 0; i < this.currentTemplate.Template.Container.Tags.length; i++) {
          this.currentTemplate.Template.Container.Tags[i].Id = i + 1;
        }
  
  
        let indicatorId = "";
        indicatorId = "currentTemplateTags" + this.currentTemplate.Template.Id; 
        this.EditCurrentTemplate(indicatorId);
      
    }
  
  

    unLogin()
    {
      this.sessionService.unLogin();
    }
}

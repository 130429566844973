
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 04.05.2017.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';





import {ParentChecker} from "../models/model.parentChecker";
import {Deleting} from "../models/model.deleting";
import {IdToId} from "../models/model.idtoid";
import {MonthAsker, SideJobsAsker, WeekDayAsker, WeekAddJobAsker, DayJobsAdditionalAsker, CalendarSideJobsAsker, CalendarMonthAsker} from "../models/model.calendar";

@Injectable()
export class HttpServiceCalendar {

  constructor(private http: HttpClient,private param:ServerString) {
  }

  getWeek(obj: WeekDayAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'calendarweekget', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getDay(obj: WeekDayAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'calendardayget', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getMonth(obj: MonthAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'calendarmonthget', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getSideJobs(obj: SideJobsAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'CalendarSideJobsGet', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getAdditionalWeekOrDayJobs(obj: WeekAddJobAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'CalendarAdditionalJobsGet', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }









  ////////////////////////////NEW/////////////////////////

  calendarGetWeekJobs(obj: WeekDayAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'Calendar/GetWeekJobs', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  calendarGetDayJobs(obj: WeekDayAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'Calendar/GetDayJobs', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  calendarGetAdditionalJobs(obj: DayJobsAdditionalAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'Calendar/GetAdditionalJobs', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }


  calendarGetSideJobs(obj:  CalendarSideJobsAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'Calendar/GeSideJobs', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }


  calendarGetMonth(obj:  CalendarMonthAsker)
  {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'Calendar/GetMonth', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }


}

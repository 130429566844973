/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent, of } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { Common } from '../../../models/model.common';
import { CommonListGiver, ParameterInstance, ParameterInstanceAddAsker, ParameterInstanceContainerGiver, ParameterInstanceEditAsker, ParameterValueContainerGiver, ValueOfSetContainer, ValueSetOfInstanceAsker } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { HttpServiceStorage } from '../../../httpserv/httpserv.storage';


declare var require: any;


@Component({
    selector: 'parameterInstanceValueSetContainer',
    templateUrl: 'temp.parameterInstanceValueSetContainer.html',
    styleUrls: ['parameterInstanceValueSetContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceValueSetContainerComponent implements OnInit {
    
    
    @Input() selectedValueInit: Common;
    SelectedValue: Common[];
    @Input() values: Common[];
    @Input() ValueSetType: number=0;
    @Input() valuesList: ParameterInstance[]=[];
    @Input() ValuesType: number=0;
    InstanceValuesList:any[]=[];
    opDi:boolean=false;

    @Output() valueChange:  EventEmitter<Common> = new EventEmitter<Common>();
    @Output() valuesListChange:  EventEmitter<ParameterInstance[]> = new EventEmitter<ParameterInstance[]>();
    @Output() deleteParameter:  EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isList: boolean=false;
    @Input() Name: string="Parameter Name";
    @Input() CanDelete: boolean = true;
    @Input() id: number=-1;
    @Input() editable: boolean=true;
    @Input() canRemove: boolean=true;


    
    
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;
    init: boolean=true;
    @Input() containerLocation: number=0;// 0 параметры к,п 1 - наименование склада

    @Input() containerId: number=0;
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private sessionService: SessionService,
    private httpServiceStorage: HttpServiceStorage,
    private router: Router
              ) { }

            
              ngOnDestroy() {
               
              }

              

              ngOnInit() 
              {
                this.init=true;
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);

                if(!this.isList)
                {
                  this.SelectedValue=[];
                  this.SelectedValue=this.selectedValueInit!=null?[this.selectedValueInit]:[];
                }
                else
                {
                  this.InstanceValuesList=[];
                  for(let i=0; i<this.valuesList.length; i++)
                  {
                    this.InstanceValuesList.push({"Id": this.valuesList[i].Id,"selectedValue":this.valuesList[i].SelectedValue!=null?[this.valuesList[i].SelectedValue]:[], "values":[], "valueSetType":0});
                    
                  }
                }
                this.init=false;
        
              }

              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }
              
            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }


              onValueChange()
              {
                this.valueChange.emit(this.selectedValueInit);
              }

              onValuesListChange()
              {
                this.valuesListChange.emit(this.valuesList);
              }


              EditValueInList(val: any)
              {
               
                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                if(val.selectedValue==null)
                {
                  checker.SelectedValueId=null;
                }
                else
                {
                  if(val.selectedValue[0].Id!=null)
                  {
                    checker.SelectedValueId=val.selectedValue[0].Id;
                  }
                  else
                  {
                    checker.NewCreatedValue= new ValueOfSetContainer();
                    if(this.ValuesType==0)
                    {
                      checker.NewCreatedValue.NameString=ExtendedHelper.ReplaceBadThings(val.selectedValue[0].Name);
                    }
                    if(this.ValuesType==1 || this.ValuesType==2)
                    {
                      checker.NewCreatedValue.NameNumber= Number(val.selectedValue[0].Name);
                    }
                    if(this.ValuesType==3)
                    {
                      let date=val.selectedValue[0].Name.split(".");
                      
                      checker.NewCreatedValue.NameDate=new Date();
                      checker.NewCreatedValue.NameDate.setUTCHours(0,0,0,0);
                      
                      checker.NewCreatedValue.NameDate.setUTCMonth(Number(date[1])-1);
                      checker.NewCreatedValue.NameDate.setUTCFullYear(Number(date[2]));
                      checker.NewCreatedValue.NameDate.setUTCDate(Number(date[0]));
                    }
                    
                    
                  }
                }
                
                
                
                this.httpServiceParameters.parameterInstanceEditValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }

                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }

                    if(data.Code==303)
                      {
                        
                          alert("вхождение параметра не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }
                      if(data.Code==304)
                      {
                          alert("вхождение параметра было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }
                      
                      if(data.Code==305)
                      {
                          console.log("параметр является записью");
                      }
                      
                      if(data.Code==306)
                      {
                        console.log("сет не является расширяемым");
                        /*let ind=this.valuesList.findIndex(c=>c.Id==val.Id);
                        if(ind>-1)
                        {
                          val.selectedValue[0]=this.valuesList[ind];
                        }*/
                        
                      }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      let ind=this.valuesList.findIndex(c=>c.Id==val.Id);
                      if(ind>-1)
                      {
                       
                        if(val.selectedValue==null || val.selectedValue[0].Id!=null)
                        {
                          if(val.selectedValue!=null)
                          {
                            this.valuesList[ind].SelectedValue=new Common();
                            this.valuesList[ind].SelectedValue.Id=val.selectedValue[0].Id;
                            this.valuesList[ind].SelectedValue.Name=val.selectedValue[0].Name;
                          }
                          else
                          {
                            this.valuesList[ind].SelectedValue=null;
                          }
                          this.onValuesListChange();
                        }
                        else
                        {

                          let checker2 = new ValueSetOfInstanceAsker();
                          checker2.ChangerId = this.UserId;
                          checker2.SessionKey = this.SessionKey;
                          checker2.InstanceId=val.Id;
                          checker2.InstanceOfParameter=true;
                          
                          
                          this.httpServiceParameters.parameterInstanceGetValueSet(checker2).subscribe((data: CommonListGiver) => {
                              if (data.Code == 100) {
                                  this.unLogin(data.Message);
                                  return;
                              }
                              
                              if(data.Code==303)
                              {
                                
                                  alert("вхождение параметра не найдено");
                                
                              }
                              if(data.Code==304)
                              {
                                  alert("вхождение параметра было удалено");
                              }
                              
                              
                              if(data.Code==300)
                              {
                                  alert("клиент или проект не найден");
                                  this.goToMain(null);
                              }
                              if(data.Code==301)
                              {
                                  alert("клиент или проект был удален");
                                  this.goToMain(null);
                              }
                              if(data.Code==302)
                              {
                                  alert("нет доступа к клиенту или проекту");
                                  this.goToMain(null);
                              }
                              if(data.Code==305)
                              {
                                console.log("вхождение не является набором");
                              }
                          
                            
                            
                              if (data.Code == 500) { console.error(data.Message); return; }
                              
                              if(data.Code==0)
                              {   
                                val.values=data.List;
                              this.onValuesListChange();
                              }
          
                          });  

  
                        
  
                        }


                      }
                      
                    }

                });  


                
              }

              AddValueInList()
              {
                if(this.containerLocation==0)
                {
                  this.AddValueInListParameters() 
                }
                if(this.containerLocation==1)
                {
                  this.AddValueInListStorage() 
                }
              }

              DeleteValueInList(val:any)
              {
                if(this.containerLocation==0)
                {
                  this.DeleteValueInListParameters(val) 
                }
                if(this.containerLocation==1)
                {
                  this.DeleteValueInListStorage(val) 
                }
              }


              AddValueInListStorage()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.containerId;
                
                this.httpServiceStorage.itemAddNameElement(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                      
                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>{
                        this.valuesList.push(el.Instance);
                        this.InstanceValuesList.push({"Id": el.Instance.Id,"selectedValue":el.Instance.SelectedValue!=null?[el.Instance.SelectedValue]:[], "values":[], "valueSetType":0});
                    
                      });
                     
                      this.onValuesListChange();
                    }

                });  
              }
            
              DeleteValueInListStorage(val:any)
              {
               
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceStorage.itemRemoveNameElement(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    

                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }

                    if(data.Code==303)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                      if(data.Code==304)
                      {
                        alert("Элемент списка не найден");
                        this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                      }


                    
                      
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.InstanceValuesList=this.InstanceValuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

              AddValueInListParameters()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                
                this.httpServiceParameters.parameterInstanceAddElementToList(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }
                  
                    if(data.Code==303)
                    {
                        alert("Тайтл не найден");
                    }
                    if(data.Code==304)
                    {
                        alert("Тайтл был удален");
                    }
                    if(data.Code==305)
                    {
                        alert("Параметр не является списком");
                        this.goToMain(null);
                    }
                   
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>{
                        this.valuesList.push(el.Instance);
                        this.InstanceValuesList.push({"Id": el.Instance.Id,"selectedValue":el.Instance.SelectedValue!=null?[el.Instance.SelectedValue]:[], "values":[], "valueSetType":0});
                    
                      });
                      this.onValuesListChange();
                    }

                });  
              }
            
              DeleteValueInListParameters(val:any)
              {
                
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceParameters.parameterInstanceRemoveElementFromList(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          console.log("вхождение не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==304)
                      {
                        
                          console.log("вхождение было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==305)
                      {
                          alert("параметр не является списком и не может быть удален данным методом");
                      }

                      if(data.Code==306)
                      {
                        alert("Тайтл не найден");
                      }

                      if(data.Code==307)
                      {
                        alert("Тайтл был удален");
                      }

                      if(data.Code==308)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                      
                      
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.InstanceValuesList=this.InstanceValuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

              DefaultValueInList(val:any)
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceParameters.parameterInstanceSetToDefaultValue(checker).subscribe((data: ParameterValueContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          alert("вхождение не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==304)
                      {
                        
                          alert("вхождение было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==305)
                      {
                        
                          console.log("вхождение параметра является записью и не может быть отредактированно данным методом");
                      }
                  
                  
                  
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      if(data.Container!=null)
                      {

                        
                        let ind=this.valuesList.findIndex(c=>c.Id==val.Id);
                        if(ind>-1)
                        {
                          if(data.Container.SelectedValue!=null)
                          {
                            this.valuesList[ind].SelectedValue.Id=data.Container.SelectedValue.Id;
                            this.valuesList[ind].SelectedValue.Name=data.Container.SelectedValue.Name;
                            val.selectedValue=[data.Container.SelectedValue];
                          }
                          else
                          {
                            this.valuesList[ind].SelectedValue=null;
                            val.selectedValue=null;
                          }
                          
                        }


                      
                      }
                      this.onValuesListChange();
                    }

                });  
              }





              EditValue()
              {
               
                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;

                if(this.SelectedValue==null)
                {
                  checker.SelectedValueId=null;
                }
                else
                {
                  if(this.SelectedValue[0].Id!=null)
                  {
                    checker.SelectedValueId=this.SelectedValue[0].Id;
                  }
                  else
                  {
                    checker.NewCreatedValue= new ValueOfSetContainer();
                    if(this.ValuesType==0)
                    {
                      checker.NewCreatedValue.NameString=ExtendedHelper.ReplaceBadThings(this.SelectedValue[0].Name);
                    }
                    if(this.ValuesType==1 || this.ValuesType==2)
                    {
                      checker.NewCreatedValue.NameNumber= Number(this.SelectedValue[0].Name);
                    }
                    if(this.ValuesType==3)
                    {
                      //checker.NewCreatedValue.NameDate=new Date(this.SelectedValue[0].Name);
                      let date=this.SelectedValue[0].Name.split(".");
                      
                      checker.NewCreatedValue.NameDate=new Date();
                      
                      checker.NewCreatedValue.NameDate.setUTCHours(0,0,0,0);
                      checker.NewCreatedValue.NameDate.setUTCFullYear(Number(date[2]));
                      checker.NewCreatedValue.NameDate.setUTCDate(Number(date[0]));
                      checker.NewCreatedValue.NameDate.setUTCMonth(Number(date[1])-1);
                    }
                  }
                }
                
                
                this.httpServiceParameters.parameterInstanceEditValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }

                  if(data.Code==303)
                    {
                      
                        alert("вхождение параметра не найдено");
                    }
                    if(data.Code==304)
                    {
                        alert("вхождение параметра было удалено");
                    }
                    
                    if(data.Code==305)
                    {
                        console.log("параметр является записью");
                    }
                    
                    if(data.Code==306)
                    {
                      console.log("сет не является расширяемым");

                    }
                
                
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      

                      if(this.SelectedValue==null || this.SelectedValue[0].Id!=null)
                      {
                        if(this.SelectedValue!=null)
                          {
                            this.selectedValueInit=this.SelectedValue[0];
                          }
                          else
                          {
                            this.selectedValueInit=null;
                          }
                        // this.onValueChange();
                      }
                      else
                      {
                        



                        let checker2 = new ValueSetOfInstanceAsker();
                        checker2.ChangerId = this.UserId;
                        checker2.SessionKey = this.SessionKey;
                        checker2.InstanceId=this.id;
                        checker2.InstanceOfParameter=true;
                        
                        
                        this.httpServiceParameters.parameterInstanceGetValueSet(checker2).subscribe((data: CommonListGiver) => {
                            if (data.Code == 100) {
                                this.unLogin(data.Message);
                                return;
                            }
                            
                            if(data.Code==303)
                            {
                              
                                alert("вхождение параметра не найдено");
                              
                            }
                            if(data.Code==304)
                            {
                                alert("вхождение параметра было удалено");
                            }
                            
                            
                            if(data.Code==300)
                            {
                                alert("клиент или проект не найден");
                                this.goToMain(null);
                            }
                            if(data.Code==301)
                            {
                                alert("клиент или проект был удален");
                                this.goToMain(null);
                            }
                            if(data.Code==302)
                            {
                                alert("нет доступа к клиенту или проекту");
                                this.goToMain(null);
                            }
                            if(data.Code==305)
                            {
                              console.log("вхождение не является набором");
                            }
                        
                          
                          
                            if (data.Code == 500) { console.error(data.Message); return; }
                            
                            if(data.Code==0)
                            {
                              
                            this.values=data.List;
                            // this.onValueChange();
                            }
        
                        });  







                      this.onValueChange();

                      }
                    }

                });  


                
              }

              DefaultValue()
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                
                this.httpServiceParameters.parameterInstanceSetToDefaultValue(checker).subscribe((data: ParameterValueContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          alert("вхождение не найдено");
                      }

                      if(data.Code==304)
                      {
                        
                          alert("вхождение было удалено");
                      }

                      if(data.Code==305)
                      {
                        
                          console.log("вхождение параметра является записью и не может быть отредактированно данным методом");
                      }
                  
                  
                  
                  
                  
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      if(data.Container!=null)
                      {
                        

                        if(data.Container.SelectedValue!=null)
                          {
                            this.selectedValueInit=data.Container.SelectedValue;
                            this.SelectedValue=[data.Container.SelectedValue];
                          }
                          else
                          {
                            this.selectedValueInit=null;
                            this.SelectedValue=null;
                          }
                      }
                      this.onValueChange();
                    }

                });  
              }

              DeleteParameter()
              {
               
                this.deleteParameter.emit(true);
              }



             
}

/**
 * Created by MosNeuro on 27.09.2017.
 */
export class ElementWithVisible{
    Element:any;
    Visible:boolean;
}

export class ElementWithSelect extends ElementWithVisible{
    
    Selected:boolean;
}

export class MultyElementWithSelect extends ElementWithSelect{
    
    Selected:boolean;
    CanBeMultyselected: boolean;
}

export class ElementWithAdditional extends ElementWithSelect{
    
    AdditionalText:string;
}


export class ElementWithInner extends ElementWithVisible{
    
    InnerElements:ElementWithVisible[]=[];
}

export class ElementWithInnerSelectable extends ElementWithSelect{
    
    InnerElements:ElementWithSelect[]=[];
    Selectable: boolean;
}
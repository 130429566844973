
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 15.03.2017.
 */
import { Injectable } from '@angular/core'; import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';





import { HttpServiceUrl } from './httpserv.url';
import { ParentChecker } from "../models/model.parentChecker";
import { Deleting } from "../models/model.deleting";
import { IdToId } from "../models/model.idtoid";
import { ConnectionToSmth } from "../models/model.connection";
import { DirectoryAddAsker, FileAccessEditAsker } from '../models/model.Files/Out/model.FileSystemAsker';
import { SingleIdRequest } from '../models/model.http';

@Injectable({
    providedIn: 'root',
  })
export class HttpServiceFile {

    constructor(private http: HttpClient, private param: ServerString) {
    }
    createDirectory(obj: DirectoryAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/CreateDirectory', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    deleteDirectory(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/RemoveDirectory', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    downloadFile(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');//'application/text');//
        //let redonce="";
        console.log(obj);

        headers.append('Content-Transfer-Encoding', 'binary');
        // let responce=new HttpResponse().
        //let params = new HttpParams().set('responseType','2'); // create params object
        //       responseType: 2   аналог того, когда у аджакса пишут bytesArray
        return this.http.post(this.param.fullServerString() + 'FileSystem/DownloadFile', body,
            {
                headers: headers, responseType: 'blob'
                //    params:params
            }).pipe(catchError((error: any) => {
                console.log(error);
                return observableThrowError(error);
            }));; //magic
        //магия уходит
    }

    getFileInfo(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/GetFileInfo', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    EditFileAccess(obj: FileAccessEditAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/EditFileAccess', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    getFileListClient(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/ShowFiles/Client', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    getFileListProject(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/ShowFiles/Project', body, { headers: headers, withCredentials: true }).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    replaceFile(FormData: FormData) {
        return this.http
            .post(this.param.fullServerString() + 'FileSystem/ReplaceFile', FormData).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    uploadFile(FormData: FormData) {
        return this.http
            .post(this.param.fullServerString() + 'FileSystem/UploadFile', FormData).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }


    deleteFile(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/RemoveFile', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    uploadPhoto(FormData: FormData) {
        return this.http
            .post(this.param.fullServerString() + 'FileSystem/UploadPhoto', FormData).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }


    // getFilesOld(obj: ParentChecker) {
    //     const body = "'" + JSON.stringify(obj) + "'";
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //     return this.http.post(this.param.fullServerString() + 'fileshow', body, { headers: headers, withCredentials: true })
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }

    // showList(obj: FileShowAsker) {
    //     const body = "'" + JSON.stringify(obj) + "'";
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //     return this.http.post(this.param.fullServerString() + 'File/ShowList', body, { headers: headers, withCredentials: true })
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }





    // uploadFile(FormData: FormData) {
    //     return this.http
    //         .post(this.param.fullServerString() + 'File/Upload', FormData)
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }



    // uploadPhoto(FormData: FormData) {
    //     // let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //     return this.http
    //         .post(this.param.fullServerString() + 'File/UploadPhoto', FormData)
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }

    // checkPhoto(obj: FormDataStringSmall) {
    //     const body = "'" + JSON.stringify(obj) + "'";
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //     return this.http.post(this.param.fullServerString() + 'FilePhotoCheck', body, { headers: headers, withCredentials: true })
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }





    // getBrowserIdAndTime(obj: ParentChecker) {
    //     const body = "'" + JSON.stringify(obj) + "'";
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    //     return this.http.post(this.param.fullServerString() + 'cookie', body, { headers: headers, withCredentials: true })
    //         //.map((resp: Response)=>resp.json())
    //         .catch((error: any) => {
    //             return Observable.throw(error);
    //         });
    // }



    suspendedFilesAdd(FormData: FormData) {
        return this.http
            .post(this.param.fullServerString() + 'FileSystem/SuspendedFiles/Add ', FormData).pipe(
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    suspendedFilesRemove(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'FileSystem/SuspendedFiles/Remove', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
}

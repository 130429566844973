import { Common } from "./model.common";

/**
 * Created by MosNeuro on 27.09.2017.
 */
export class CommonWithSelect extends Common{
    Selected:boolean;
    constructor(id: number=0, name: string="", selected:boolean=false)
  {
    super(id, name);
    this.Selected = selected;
  }
}

export class CommonWithSelectAndBack extends Common{
  Selected:boolean;
  BackName: string;
  constructor(id: number=0, name: string="", selected:boolean=false, back:string="")
{
  super(id, name);
  this.Selected = selected;
  this.BackName=back;
}
}

export class CommonWithSelectAndAdditional extends CommonWithSelect{
  Additionals:any[];
  constructor(id: number=0, name: string="", selected:boolean=false, additionals:any[]=[])
{
  super(id, name, selected);
  this.Additionals = additionals;
}
}




export class AnyWithSelectAndNameBack {
  Selected:boolean;
  Back: any;
  Element: any;
  constructor(element: any, selected:boolean=false, back: any)
{
  this.Element=element
  this.Selected = selected;
  this.Back=back;
}
}





import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 01.03.2017.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';



import {
  TagFilter, TagFromAngular, TagFromAngularDeleting,
  TagFilterToSendExtended, TagAddAsker
} from "../models/model.tag";
import {EntityRefresher} from "../models/model.entityrefresher";


@Injectable()
export class HttpServiceTag {

    constructor(private http: HttpClient,private param:ServerString) {
    }


  getTagList(obj: TagFilterToSendExtended) {
    const body = "'" + JSON.stringify(obj) + "'";
     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'tag/listGet', body, {headers: headers, withCredentials: true}).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

    AddTag(obj: TagAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'tag/add', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    deleteTag(obj: TagFromAngularDeleting) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'tag/delete', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    /*loadSomeElementToList(obj: EntityRefresher) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'LoadSomeEntityToPutIntoList', body, {headers: headers, withCredentials: true}).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }*/
}

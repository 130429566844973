import { CommonRequest, CommonResponse } from "../models/model.http";


const config: Version = {
    Global: 0,
    Component: 1,
    Update: 26,
    Fix: 7
}


export class VersionAsker extends CommonRequest {
    ClientVersion: Version;
}

export class VersionGiver extends CommonResponse {
    ServerVersion: Version;
    ClientVersion: Version;
    ClientRefreshRequired: Boolean;
    constructor() {
        super();
        this.ClientVersion = new Version(config.Global, config.Component, config.Update, config.Fix);
    }

    getConfigVersion(): Version {
        return new Version(config.Global, config.Component, config.Update, config.Fix);
    }

    getConfigVersionString(): string {
        return config.Global + "." + config.Component + "." + config.Update + "." + config.Fix;
    }

    gerCurrectVersion(): string {
        return `${this.getConfigVersionString()}${!this.checkForUpdate() ? ' (Need update)' : ''}`;
    }

    checkForUpdate(): boolean {
        return this.getConfigVersionString() === this.getClientVersion()
    }

    getClientVersion(): string {
        return this.ClientVersion.Global + "." + this.ClientVersion.Component + "." + this.ClientVersion.Update + "." + this.ClientVersion.Fix;
    }
    getServerVersion(): string {
        return (this.ServerVersion) ? this.ServerVersion.Global + "." + this.ServerVersion.Component + "." + this.ServerVersion.Update + "." + this.ServerVersion.Fix : 'No data';
    }
}

export class Version {
    Global: number;//глобальная версия
    Component: number;//при каждом вводе нового изменения
    Update: number;//при каждом добавлении функционала изменения
    Fix: number;//при каждом фиксе
    constructor(g: number, c: number, u: number, f: number) {
        this.Global = g;
        this.Component = c;
        this.Update = u;
        this.Fix = f;
    }
}

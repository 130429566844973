import { SvgIconsModule } from './../svg-icon/svg-icon.module';
import { CommonModule } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ContentChild,
  forwardRef,
  AfterViewChecked,
  OnInit,
  NgModule
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { VirtualScrollerComponent, VirtualScrollerModule } from 'ngx-virtual-scroller';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ClickOutsideDirective, styleDirective, setPosition } from './dropdown.directive';
import { DropdownSettings } from './dropdown.interface';
import { DataService } from './dropdown.service';
import { ListFilterPipe } from './list-filter';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { Item, Badge, Search, CIcon, TemplateRenderer, CCheckbox } from './menu-item';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { SharedModule } from '../../shared.module';


export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};
export const DROPDOWN_CONTROL_VALIDATION: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true,
}

const noop = () => {
};


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [
    './dropdown.component.scss',
    '../../../../style/supportLibrary.scss',
    '../../../../style/font-styles.scss',
  ],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR, DROPDOWN_CONTROL_VALIDATION],
  // encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, ControlValueAccessor, OnChanges, Validator, AfterViewChecked, OnDestroy {

  @Input()
  data: Array<any>;

  @Input()
  settings: DropdownSettings;

  @Input()
  loading: boolean;

  @Output('onSelect')
  onSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onDeSelect')
  onDeSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onSelectAll')
  onSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onDeSelectAll')
  onDeSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onOpen')
  onOpen: EventEmitter<any> = new EventEmitter<any>();

  @Output('onClose')
  onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output('onScrollToEnd')
  onScrollToEnd: EventEmitter<any> = new EventEmitter<any>();

  @Output('onFilterSelectAll')
  onFilterSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onFilterDeSelectAll')
  onFilterDeSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onAddFilterNewItem')
  onAddFilterNewItem: EventEmitter<any> = new EventEmitter<any>();

  @Output('onGroupSelect')
  onGroupSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onGroupDeSelect')
  onGroupDeSelect: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(Item, { static: false }) itemTempl: Item;
  @ContentChild(Badge, { static: false }) badgeTempl: Badge;
  @ContentChild(Search, { static: false }) searchTempl: Search;


  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('selectedList', { static: false }) selectedListElem: ElementRef;
  @ViewChild('dropdownList', { static: false }) dropdownListElem: ElementRef;
  @ViewChild('listFilter', { static: false }) listFilterElem: ElementRef;

  
  langset: Common_LangSet;
  ln_sub: Subscription=null;


  onEscapeDown(event: KeyboardEvent) {
    if (this.settings.escapeToClose &&event.keyCode == 27) {
      this.closeDropdown();
      event.stopPropagation();
    }
  }

  virtualdata: any = [];
  searchTerm$ = new Subject<string>();
  isInvisible: boolean=true;
  filterPipe: ListFilterPipe;
  public selectedItems: Array<any>;
  public isActive: boolean = false;
  public isSelectAll: boolean = false;
  public isFilterSelectAll: boolean = false;
  public isInfiniteFilterSelectAll: boolean = false;
  public groupedData: Array<any>;
  filter: any;
  public chunkArray: any[];
  public scrollTop: any;
  public chunkIndex: any[] = [];
  public cachedItems: any[] = [];
  public groupCachedItems: any[] = [];
  public totalRows: any;
  public itemHeight: any = 41.6;
  public screenItemsLen: any;
  public cachedItemsLen: any;
  public totalHeight: any;
  public scroller: any;
  public maxBuffer: any;
  public lastScrolled: any;
  public lastRepaintY: any;
  public selectedListHeight: any;
  public filterLength: any = 0;
  public infiniteFilterLength: any = 0;
  public viewPortItems: any;
  public item: any;
  public dropdownListYOffset: number = 0;
  public dropdownListXOffset: number = 0;
  public  dropdownListYTopOffset: number = 0;
  subscription: Subscription;
  defaultSettings: DropdownSettings = {
    multiSelection: false,
    text: 'Выбрать',
    enableCheckAll: true,
    selectAllText: 'Выбрать все',
    unSelectAllText: 'Исключить все',
    filterSelectAllText: 'Выбрать все отфильтрованные',
    filterUnSelectAllText: 'Исключить все отфильтрованные',
    enableSearchFilter: false,
    searchBy: [],
    maxHeight: 300,
    badgeShowLimit: 999999999999,
    classes: '',
    disabled: false,
    searchPlaceholderText: 'Поиск',
    showCheckbox: false,
    noDataLabel: 'Нет данных',
    searchAutofocus: true,
    lazyLoading: false,
    labelKey: 'Name',
    inBrackets: false,
    inBracketsKey: 'Status',
    primaryKey: 'Id',
    disabledKey: 'Disabled',
    position: 'bottom',
    autoPosition: true,
    enableFilterSelectAll: true,
    selectGroup: false,
    addNewItemOnFilter: false,
    addNewButtonText: "Add",
    escapeToClose: true,
    clearAll: true,
    disableEmptyValue: true,
    width: 'auto',
    theme: 'gray',
    ClearText: "Очистить",
    noWrapValue: false,
    selectedAtTheTop: true
  }
  randomSize: boolean = true;
  public parseError: boolean;
  public filteredList: any = [];
  virtualScroollInit: boolean = false;
  @ViewChild(VirtualScrollerComponent, { static: false })
  private virtualScroller: VirtualScrollerComponent;
  public isDisabledItemPresent = false;
  

  constructor(public _elementRef: ElementRef, private cdr: ChangeDetectorRef, private ds: DataService, private languageService: LanguageService) {
    this.searchTerm$.asObservable().pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(term => term)
    ).subscribe(val => {
      this.filterInfiniteList(val);
    });
  }

  public filterInfiniteList(evt: any) {
    let filteredElems: Array<any> = [];
    if (this.settings.groupBy) {
      this.groupedData = this.groupCachedItems.slice();
    }
    else {
      this.data = this.cachedItems.slice();
      this.virtualdata = this.cachedItems.slice();
    }

    if ((evt != null || evt != '') && !this.settings.groupBy) {
      if (this.settings.searchBy.length > 0) {
        for (let t = 0; t < this.settings.searchBy.length; t++) {

          this.virtualdata.filter((el: any) => {
            if (el[this.settings.searchBy[t].toString()].toString().toLowerCase().indexOf(evt.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
            }
          });
        }

      }
      else {
        this.virtualdata.filter(function (el: any) {
          for (let prop in el) {
            if (el[prop].toString().toLowerCase().indexOf(evt.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
              break;
            }
          }
        });
      }
      this.virtualdata = [];
      this.virtualdata = filteredElems;
      this.infiniteFilterLength = this.virtualdata.length;
    }
    if (evt.toString() != '' && this.settings.groupBy) {
      this.groupedData.filter(function (el: any) {
        if (el.hasOwnProperty('grpTitle')) {
          filteredElems.push(el);
        }
        else {
          for (let prop in el) {
            if (el[prop].toString().toLowerCase().indexOf(evt.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
              break;
            }
          }
        }
      });
      this.groupedData = [];
      this.groupedData = filteredElems;
      this.infiniteFilterLength = this.groupedData.length;
    }
    else if (evt.toString() == '' && this.cachedItems.length > 0) {
      this.virtualdata = [];
      this.virtualdata = this.cachedItems;
      this.infiniteFilterLength = 0;
    }
    this.virtualScroller.refresh();
    
  }

  ngOnInit() {
    this.settings = Object.assign(this.defaultSettings, this.settings);

    this.cachedItems = this.cloneArray(this.data);
    if (this.settings.position == 'top') {
      setTimeout(() => {
        this.selectedListHeight = { val: 0 };
        this.selectedListHeight.val = this.selectedListElem.nativeElement.clientHeight;
      });
    }
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=> {
      this.langset=lang
      this.defaultSettings.text = this.langset.Pick;
      this.defaultSettings.selectAllText = this.langset.SelectAll;
      this.defaultSettings.unSelectAllText = this.langset.ExcludeAll;
      this.defaultSettings.searchPlaceholderText = this.langset.Search;
      this.defaultSettings.ClearText = this.langset.Clear;

      this.settings = Object.assign(this.defaultSettings, this.settings);
    });

    this.subscription = this.ds.getData().subscribe(data => {
      if (data) {
        let len = 0;
        data.forEach((obj: any, i: any) => {
          if (obj.disabled) {
            this.isDisabledItemPresent = true;
          }
          if (!obj.hasOwnProperty('grpTitle')) {
            len++;
          }
        });
        this.filterLength = len;
        this.onFilterChange(data);
      }

    });
    setTimeout(() => {
      this.calculateDropdownDirection();
      this.calculateDropdownMaxHeight();
    }, 100);

    // wait until top and height coordinates are recalculated and rendered
    setTimeout(() => {
      this.calculateDropdownMaxHeight();
    }, 1000);
    this.virtualScroollInit = false;


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      if (this.settings.groupBy) {
        this.groupedData = this.transformData(this.data, this.settings.groupBy, this.settings.labelKey);
        if (this.data.length == 0) {
          this.selectedItems = [];
        }
        this.groupCachedItems = this.cloneArray(this.groupedData);
      }
      this.cachedItems = this.cloneArray(this.data);
    }
    if (changes.settings && !changes.settings.firstChange) {
      this.settings = Object.assign(this.defaultSettings, this.settings);
    }
    if (changes.loading) {
    }
    if (this.settings && this.settings.lazyLoading && this.virtualScroollInit && changes.data) {
      this.virtualdata = changes.data.currentValue;
    }

    
  }

  ngDoCheck() {
    if (this.selectedItems) {
      if (this.selectedItems.length == 0 || this.data.length == 0 || this.selectedItems.length < this.data.length) {
        this.isSelectAll = false;
      }
    }
  }
  ngAfterViewInit() {
    
    if (this.settings.lazyLoading) {
      // this._elementRef.nativeElement.getElementsByClassName("lazyContainer")[0].addEventListener('scroll', this.onScroll.bind(this));
    }
  }
  ngAfterViewChecked() {
    if (this.selectedListElem.nativeElement.clientHeight && this.settings.position == 'top' && this.selectedListHeight) {
      this.selectedListHeight.val = this.selectedListElem.nativeElement.clientHeight;
      this.cdr.detectChanges();
    }
  }

  addSelectedOuter(item: any)
  {
    this.addSelected(item);
    this.onSelect.emit(item);
  }
  onItemClick(item: any, index: number, evt: Event) {
    item = this.data.find(el => el[this.settings.primaryKey] == item[this.settings.primaryKey])
    if (item.disabled) {
      return false;
    }

    if (this.settings.disabled) {
      return false;
    }

    let found = this.isSelected(item);
    let limit = this.selectedItems.length < this.settings.limitSelection ? true : false;

    if (!found) {
      if (this.settings.limitSelection) {
        if (limit) {
          this.addSelected(item);
          this.onSelect.emit(item);
          if(this.settings.multiSelection)
          {
            this.closeDropdown();
          }
        }
      }
      else {
        this.addSelected(item);
        this.onSelect.emit(item);
        //this.closeDropdown();
        if(this.settings.multiSelection)
          {
            this.closeDropdown();
          }
      }

    }
    else {
      if (this.settings.disableEmptyValue && this.selectedItems.length == 1) return false;
      this.removeSelected(item);
      this.onDeSelect.emit(item);
      if(this.settings.multiSelection)
          {
            this.closeDropdown();
          }
     // this.closeDropdown();
    }
    if (this.isSelectAll || this.data.length > this.selectedItems.length) {
      this.isSelectAll = false;
    }

    if (this.data.length == this.selectedItems.length) {
      this.isSelectAll = true;
    }
    if (this.settings.groupBy) {
      this.updateGroupInfo(item);
    }
    this.sortBySelected();
  }
  public validate(c: FormControl): any {
    return null;
  }
  private onTouchedCallback: (_: any) => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  writeValue(value: any) {
    
    if (value !== undefined && value !== null && value !== '') {
      if (!this.settings.multiSelection) {
        if (this.settings.groupBy) {
          this.groupedData = this.transformData(this.data, this.settings.groupBy, this.settings.labelKey);
          this.groupCachedItems = this.cloneArray(this.groupedData);
          this.selectedItems = [value[0]];
        } else {
          try {

            if (value.length > 1) {
              this.selectedItems = [value[0]];
              throw new DOMException("Single Selection Mode, Selected Items cannot have more than one item.");
            }
            else {
              this.selectedItems = value;
            }
          }
          catch (e) {
            console.error(e.body.msg);
          }
        }
        
      }
      else {
        
        if (this.settings.limitSelection) {
          this.selectedItems = value.slice(0, this.settings.limitSelection);
        }
        else {
          this.selectedItems = value;
        }
        if (this.selectedItems.length === this.data.length && this.data.length > 0) {
          this.isSelectAll = true;
        }
        if (this.settings.groupBy) {
          this.groupedData = this.transformData(this.data, this.settings.groupBy, this.settings.labelKey);
          this.groupCachedItems = this.cloneArray(this.groupedData);
        }

      }
    } else {
      this.selectedItems = [];
    }
    
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  trackByFn(index: number, item: any) {
    return item && item[this.settings.primaryKey];
  }
  isSelected(clickedItem: any) {

    if (clickedItem==undefined || clickedItem==null || clickedItem.disabled) {
      return false;
    }
    let found = false;
    this.selectedItems && this.selectedItems.forEach(item => {
      if (item && clickedItem[this.settings.primaryKey] === item[this.settings.primaryKey]) {
        found = true;
      }
    });
    return found;
  }
  addSelected(item: any) {
    if (item.disabled) {
      return;
    }

    
    if(this.selectedItems.findIndex(c=>c!=undefined && c.Id==item.Id)==-1)
    {
      item[this.settings.labelKey]=item[this.settings.labelKey].replace('<span class="searchedText">',"");
      item[this.settings.labelKey]=item[this.settings.labelKey].replace('</span>',"");
  
      if (!this.settings.multiSelection) {
        this.selectedItems = [];
        this.selectedItems.push(item);
        this.closeDropdown();
      }
      else
        {this.selectedItems.push(item);}
      this.onChangeCallback(this.selectedItems);
      this.onTouchedCallback(this.selectedItems);
    }
    else
    {
      if (this.settings.disableEmptyValue && this.selectedItems.length == 1) return false;
      this.removeSelected(item);
    }

    
  }
  removeSelected(clickedItem: any) {
    this.selectedItems && this.selectedItems.forEach(item => {
      if (clickedItem[this.settings.primaryKey] === item[this.settings.primaryKey]) {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    });
    this.onChangeCallback(this.selectedItems);
    this.onTouchedCallback(this.selectedItems);
  }
  toggleDropdown(evt: any) {
    
    this.isInvisible=true;
    if (this.settings.disabled) {
      return false;
    }

    let selected=<HTMLElement>this.selectedListElem.nativeElement;
      let width=selected.parentElement && 
        selected.parentElement.parentElement && 
        selected.parentElement.parentElement.parentElement &&
        selected.parentElement.parentElement.parentElement.parentElement && 
        selected.parentElement.parentElement.parentElement.parentElement.className === 'filterContainer' ? 
        selected.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().width : 
        selected.getBoundingClientRect().width; //selected.getBoundingClientRect().width;
      let drop = <HTMLElement>this.dropdownListElem.nativeElement;
      drop.style.width=width+"px";
    this.isActive = !this.isActive;
    if (this.isActive) {
      if (this.settings.searchAutofocus && this.searchInput && this.settings.enableSearchFilter && !this.searchTempl) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        }, 0);
      }
      this.onOpen.emit(true);
    }
    else {
      this.clearSearch();
      this.onClose.emit(false);
    }
    setTimeout(() => {
      
     
      this.calculateDropdownDirection();
    }, 0);
    if (this.settings.lazyLoading) {
      this.virtualdata = this.data;
      this.virtualScroollInit = true;
    }
    evt.preventDefault();
    

  }
  public openDropdown() {
    if (this.settings.disabled) {
      return false;
    }
    this.isActive = true;
    if (this.settings.searchAutofocus && this.searchInput && this.settings.enableSearchFilter && !this.searchTempl) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
    this.onOpen.emit(true);
  }
  public closeDropdown() {
    if (this.searchInput && this.settings.lazyLoading) {
      this.searchInput.nativeElement.value = "";
    }
    if (this.searchInput) {
      this.searchInput.nativeElement.value = "";
    }
    this.filter = "";
    this.isActive = false;
    this.clearSearch();
    this.onClose.emit(false);
  }
  public closeDropdownOnClickOut() {
    if (this.isActive) {
      if (this.searchInput && this.settings.lazyLoading) {
        this.searchInput.nativeElement.value = "";
      }
      if (this.searchInput) {
        this.searchInput.nativeElement.value = "";
      }
      this.filter = "";
      this.isActive = false;
      this.clearSearch();

      this.onClose.emit(false);
    }
  }
  toggleSelectAll(event) {
    if (!this.isSelectAll) {
      this.selectedItems = [];
      if (this.settings.groupBy) {
        this.groupedData.forEach((obj) => {
          obj.selected = !obj.disabled;
        })
        this.groupCachedItems.forEach((obj) => {
          obj.selected = !obj.disabled;
        })
      }
      // this.selectedItems = this.data.slice();
      this.selectedItems = this.data.filter((individualData) => !individualData.disabled);
      this.isSelectAll = true;
      this.onChangeCallback(this.selectedItems);
      this.onTouchedCallback(this.selectedItems);

      this.onSelectAll.emit(this.selectedItems);
    }
    else {
      if (this.settings.groupBy) {
        this.groupedData.forEach((obj) => {
          obj.selected = false;
        });
        this.groupCachedItems.forEach((obj) => {
          obj.selected = false;
        })
      }
      this.selectedItems = [];
      this.isSelectAll = false;
      this.onChangeCallback(this.selectedItems);
      this.onTouchedCallback(this.selectedItems);

      this.onDeSelectAll.emit(this.selectedItems);
    }
    event.stopPropagation();
  }

  filterGroupedList() {
    if (this.filter == "" || this.filter == null) {
      this.clearSearch();
      return;
    }
    this.groupedData = this.cloneArray(this.groupCachedItems);
    this.groupedData = this.groupedData.filter(obj => {
      let arr = [];
      let oldList = this.cloneArray(obj.list);
      if (obj.withoutGroup) {
        if (obj.item[this.settings.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
          obj.item[this.settings.labelKey] = SearchSelectHelper.getSearchedText(this.filter, obj.item[this.settings.labelKey])
          return obj;
        }
      } else {
        if (obj[this.settings.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
          arr = obj.list;
        } else {
          arr = obj.list.filter(t => {
            // t[this.settings.labelKey] = SearchSelectHelper.getSearchedText(this.filter, t[this.settings.labelKey])
            return t[this.settings.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
          });
        }

        obj.list = arr;

        obj.list.forEach(t => {
          t[this.settings.labelKey] = SearchSelectHelper.getSearchedText(this.filter, t[this.settings.labelKey])
        });
       
        if (obj[this.settings.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
          obj[this.settings.labelKey] = SearchSelectHelper.getSearchedText(this.filter, obj[this.settings.labelKey])
          return arr;
        } else {       
          return oldList.some(cat => {
            // cat[this.settings.labelKey] = SearchSelectHelper.getSearchedText(this.filter, cat[this.settings.labelKey])
            return cat[this.settings.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
          })
        }


      }

    });
  }

  toggleFilterSelectAll() {
    if (!this.isFilterSelectAll) {
      let added = [];
      if (this.settings.groupBy) {
        /*                 this.groupedData.forEach((item: any) => {
                            if (item.list) {
                                item.list.forEach((el: any) => {
                                    if (!this.isSelected(el)) {
                                        this.addSelected(el);
                                        added.push(el);
                                    }
                                });
                            }
                            this.updateGroupInfo(item);

                        }); */

        this.ds.getFilteredData().forEach((el: any) => {
          if (!this.isSelected(el) && !el.hasOwnProperty('grpTitle')) {
            this.addSelected(el);
            added.push(el);
          }
        });

      }
      else {
        this.ds.getFilteredData().forEach((item: any) => {
          if (!this.isSelected(item)) {
            this.addSelected(item);
            added.push(item);
          }

        });
      }

      this.isFilterSelectAll = true;
      this.onFilterSelectAll.emit(added);
    }
    else {
      let removed = [];
      if (this.settings.groupBy) {
        /*                 this.groupedData.forEach((item: any) => {
                            if (item.list) {
                                item.list.forEach((el: any) => {
                                    if (this.isSelected(el)) {
                                        this.removeSelected(el);
                                        removed.push(el);
                                    }
                                });
                            }
                        }); */
        this.ds.getFilteredData().forEach((el: any) => {
          if (this.isSelected(el)) {
            this.removeSelected(el);
            removed.push(el);
          }
        });
      }
      else {
        this.ds.getFilteredData().forEach((item: any) => {
          if (this.isSelected(item)) {
            this.removeSelected(item);
            removed.push(item);
          }

        });
      }
      this.isFilterSelectAll = false;
      this.onFilterDeSelectAll.emit(removed);
    }
  }
  toggleInfiniteFilterSelectAll() {
    if (!this.isInfiniteFilterSelectAll) {
      this.virtualdata.forEach((item: any) => {
        if (!this.isSelected(item)) {
          this.addSelected(item);
        }
      });
      this.isInfiniteFilterSelectAll = true;
    }
    else {
      this.virtualdata.forEach((item: any) => {
        if (this.isSelected(item)) {
          this.removeSelected(item);
        }

      });
      this.isInfiniteFilterSelectAll = false;
    }
  }
  clearSearch() {
    if (this.settings.groupBy) {
      this.groupedData = [];
      this.groupedData = this.cloneArray(this.groupCachedItems);
    }
    this.filter = "";
    this.isFilterSelectAll = false;

  }
  onFilterChange(data: any) {
    
    if (this.filter && this.filter == "" || data.length == 0) {
      this.isFilterSelectAll = false;
    }
    let cnt = 0;
    data.forEach((item: any) => {

      if (!item.hasOwnProperty('grpTitle') && this.isSelected(item)) {
        cnt++;
      }
    });

    if (cnt > 0 && this.filterLength == cnt) {
      this.isFilterSelectAll = true;
    }
    else if (cnt > 0 && this.filterLength != cnt) {
      this.isFilterSelectAll = false;
    }
    this.cdr.detectChanges();
  }
  cloneArray(arr: any) {
    let i, copy;

    if (Array.isArray(arr)) {
      return JSON.parse(JSON.stringify(arr));
    } else if (typeof arr === 'object') {
      throw 'Cannot clone array containing an object!';
    } else {
      return arr;
    }
  }
  updateGroupInfo(item: any) {
    if (item.disabled) {
      return false;
    }
    let key = this.settings.groupBy;
    this.groupedData.forEach((obj: any) => {
      let cnt = 0;
      if (obj.grpTitle && (item[key] == obj[key])) {
        if (obj.list) {
          obj.list.forEach((el: any) => {
            if (this.isSelected(el)) {
              cnt++;
            }
          });
        }
      }
      if (obj.list && (cnt === obj.list.length) && (item[key] == obj[key])) {
        obj.selected = true;
      }
      else if (obj.list && (cnt != obj.list.length) && (item[key] == obj[key])) {
        obj.selected = false;
      }
    });

    this.groupCachedItems.forEach((obj: any) => {
      let cnt = 0;
      if (obj.grpTitle && (item[key] == obj[key])) {
        if (obj.list) {
          obj.list.forEach((el: any) => {
            if (this.isSelected(el)) {
              cnt++;
            }
          });
        }
      }
      if (obj.list && (cnt === obj.list.length) && (item[key] == obj[key])) {
        obj.selected = true;
      }
      else if (obj.list && (cnt != obj.list.length) && (item[key] == obj[key])) {
        obj.selected = false;
      }
    });
  }

  transformData(arr: Array<any>, field: any, labelKey): Array<any> {
    const copygroupedObj: any = arr.reduce((prev: any, cur: any) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
        // prev[cur[labelKey]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});


    const groupedObj: any = arr.reduce((prev: any, cur: any) => {
      if (!cur[field]) {
        prev[cur[labelKey]] = cur;
      } else {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur];
        } else {
          prev[cur[field]].push(cur);
        }
      }

      return prev;
    }, {});


    const tempArr: any = [];
    Object.keys(groupedObj).map((x: any) => {
      let obj: any = {};
      obj['selected'] = false;

      if (!groupedObj[x].length) {
        obj['item'] = groupedObj[x];
        obj['withoutGroup'] = true;
        // console.warn(groupedObj[x])
        let cnt = 0;
        if (this.isSelected(groupedObj[x])) {
          obj.item.selected = true;
          // cnt++;
        } else {
          obj.item.selected = false;
        }
        // if (groupedObj[x][this.settings.disabledKey] != undefined) obj[this.settings.disabledKey] = groupedObj[x][this.settings.disabledKey];
      } else {
        let disabledChildrens = [];
        obj["grpTitle"] = true;
        obj[this.settings.labelKey] = x;
        obj['withoutGroup'] = false;
        obj[this.settings.groupBy] = x;
        obj['list'] = [];
        let cnt = 0;
        groupedObj[x].forEach((item: any) => {
          item['list'] = [];
          if (item.disabled) {
            this.isDisabledItemPresent = true;
            disabledChildrens.push(item);
          }
          obj.list.push(item);
          if (this.isSelected(item)) {
            cnt++;
          }
        });
        if (cnt == obj.list.length) {
          obj.selected = true;
        }
        else {
          obj.selected = false;
        }
        obj['disabled'] = disabledChildrens.length === groupedObj[x].length;
      }

      tempArr.push(obj);

      // obj.list.forEach((item: any) => {
      //     tempArr.push(item);
      // });

    });

    this.sortBySelected();

    return tempArr;
  }


  sortBySelected() {
    if (this.settings.selectedAtTheTop) {
      this.data = this.data.sort((a, b) => {
        return Number(this.isSelected(b)) - Number(this.isSelected(a))
      })
    }
  }



  onScrollEnd(e: any) {
    if (e.endIndex === this.data.length - 1 || e.startIndex === 0) {

    }
    this.onScrollToEnd.emit(e);

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  resetInfiniteSearch() {
    this.filter = "";
    this.isInfiniteFilterSelectAll = false;
    this.virtualdata = [];
    this.virtualdata = this.cachedItems;
    this.groupedData = this.groupCachedItems;
    this.infiniteFilterLength = 0;
  }



  selectGroup(item: any) {
    if (item.disabled) {
      return false;
    }
    if (item.selected) {
      item.selected = false;
      item.list.forEach((obj: any) => {
        this.removeSelected(obj);
      });

      this.onGroupDeSelect.emit(item);
      this.updateGroupInfo(item);

    }
    else {
      item.selected = true;
      item.list.forEach((obj: any) => {
        if (!this.isSelected(obj)) {
          this.addSelected(obj);
        }

      });
      this.onGroupSelect.emit(item);
      this.updateGroupInfo(item);

    }
  }

  addFilterNewItem() {
    this.onAddFilterNewItem.emit(this.filter);
    this.filterPipe = new ListFilterPipe(this.ds);
    this.filterPipe.transform(this.data, this.filter, this.settings.searchBy);
  }
  calculateDropdownDirection() {
    let shouldOpenTowardsTop = this.settings.position == 'top';
    if (this.settings.autoPosition) {
      const dropdownHeight = this.dropdownListElem.nativeElement.clientHeight;
      const viewportHeight = document.documentElement.clientHeight;
      const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();

      const spaceOnTop: number = selectedListBounds.top;
      const spaceOnBottom: number = viewportHeight - selectedListBounds.top;
      if (spaceOnBottom < spaceOnTop && dropdownHeight < spaceOnTop) {
        this.openTowardsTop(true);
      }
      else {
        this.openTowardsTop(false);
      }
      // Keep preference if there is not enough space on either the top or bottom
      /* 			if (spaceOnTop || spaceOnBottom) {
                      if (shouldOpenTowardsTop) {
                          shouldOpenTowardsTop = spaceOnTop;
                      } else {
                          shouldOpenTowardsTop = !spaceOnBottom;
                      }
                  } */
    }

  }

  calculateDropdownMaxHeight() {
    if (this.settings.autoPosition) {
      const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();
      const body = document.body
      const html = document.documentElement;

      const screen_height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight )
      this.settings.maxHeight = screen_height - selectedListBounds.top - 78;

      // console.log('screen height: '+screen_height)
      // console.log('list top: '+selectedListBounds.top)
      // console.log('result lish height: '+(screen_height - selectedListBounds.top - 100))
    }
  }

  openTowardsTop(value: boolean) {
    
   // if (value && this.selectedListElem.nativeElement.clientHeight) {
    //  this.dropdownListYOffset = 15 + this.selectedListElem.nativeElement.clientHeight;
      if (value && this.selectedListElem.nativeElement.getBoundingClientRect().y) {
        this.dropdownListYOffset = document.documentElement.clientHeight - this.selectedListElem.nativeElement.getBoundingClientRect().y;
        this.dropdownListYTopOffset=null;
      if(this.listFilterElem!=undefined && this.listFilterElem!=null)
      {
        this.listFilterElem.nativeElement.classList.add("first_order");
      }
      
    } else {
      
      //this.dropdownListYOffset = document.documentElement.clientHeight - (this.selectedListElem.nativeElement.getBoundingClientRect().y + this.selectedListElem.nativeElement.getBoundingClientRect().height) - this.dropdownListElem.nativeElement.getBoundingClientRect().height;
      this.dropdownListYOffset =null;
      this.dropdownListYTopOffset=this.selectedListElem.nativeElement.getBoundingClientRect().y + this.selectedListElem.nativeElement.getBoundingClientRect().height;
      if(this.listFilterElem!=undefined && this.listFilterElem!=null)
      {
      this.listFilterElem.nativeElement.classList.remove("first_order");
    }
    

    const selected = this.selectedListElem.nativeElement;
    if (selected.parentElement.parentElement && 
        selected.parentElement.parentElement.parentElement &&
        selected.parentElement.parentElement.parentElement.parentElement && 
        selected.parentElement.parentElement.parentElement.parentElement.className === 'filterContainer') {
          this.dropdownListXOffset=selected.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().x;
        }
    }
    this.isInvisible=false;
  }
  clearSelection(e: any) {
    if (this.settings.groupBy) {
      this.groupCachedItems.forEach((obj) => {
        obj.selected = false;
      })
    }
    this.clearSearch();
    this.selectedItems = [];
    /*         this.isSelectAll = false;
            this.onChangeCallback(this.selectedItems);
            this.onTouchedCallback(this.selectedItems); */
    this.onDeSelectAll.emit(this.selectedItems);
  }

}

@NgModule({
  imports: [CommonModule, FormsModule, VirtualScrollerModule, SvgIconsModule],
  declarations: [DropdownComponent, ClickOutsideDirective, styleDirective, ListFilterPipe, Item, TemplateRenderer, Badge, Search, setPosition, CIcon, CCheckbox],
  exports: [DropdownComponent, ClickOutsideDirective, styleDirective, ListFilterPipe, Item, TemplateRenderer, Badge, Search, setPosition, CIcon, CCheckbox],
  providers: [DataService]
})
export class DropdownModule { }

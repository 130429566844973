import { Common, CommonSelectable } from "../../model.common";
import { CommonWithAddress } from "../../model.CommonListGiver";

export class ClientsToAddContainer {
  //строковый фильтр
  Filter: string;
  //ид элемента в темплейте, в которой вводим фильтр.
  // FilterInputId:string;
  Clients: CommonSelectable[];

  EntityType: number;
  EntityId: number;//Id сущности (проекта клиента контакта) к которой прикрепляем

  RequestCounter: number;//для синхронизации. будем показыватьь пользователю только актуальный последний запрос

  IsRefreshing: boolean;

  IsAdding: boolean;

  constructor(clients: CommonWithAddress[], type: number, id: number) {
    this.Clients = clients.map(c => new CommonSelectable(c.Id, c.Name, null, c.Address));
    this.EntityType = type;
    this.EntityId = id;
    this.IsRefreshing = false;
    this.RequestCounter = -1;
    this.IsAdding = false;
    this.Filter = '';
  }

  SetClients(clients: CommonWithAddress[]) {
    this.Clients = clients.map(c => new CommonSelectable(c.Id, c.Name, null, c.Address));
  }

  StartRefreshing(): number {
    this.IsRefreshing = true;
    this.Clients = [];
    if (this.RequestCounter == 50) this.RequestCounter = 0;
    else this.RequestCounter++;

    return this.RequestCounter;
  }

  ReplaceClients(clients: CommonWithAddress[]) {
    this.Clients = clients.map(c => new CommonSelectable(c.Id, c.Name, null, c.Address));
    this.IsRefreshing = false;
  }

  public FindSelected(): CommonSelectable {
    return this.Clients.find(c => c.Selected);
  }

  public FindSelectedIndex(): number {
    return this.Clients.findIndex(c => c.Selected);
  }

  public GoDownInContainer() {
    if (this.IsRefreshing) return;
    let index = this.FindSelectedIndex();//ищем выбранный
    if (index == -1)//не нашли, выбираем первый из списка
      this.Clients[0].Selected = true;
    else {
      if (index == this.Clients.length - 1) return;//если последний ничего не делаем
      this.Clients[index].Selected = false;
      this.Clients[index + 1].Selected = true;
    }
  }

  public GoUpInContainer() {
    if (this.IsRefreshing) return;
    let index = this.FindSelectedIndex();//ищем выбранный
    if (index != -1) {//если есть откуда смещаться
      this.Clients[index].Selected = false;
      if (index > 0)//если есть сверху элемент который можно выбрать, выберем
        this.Clients[index - 1].Selected = true;
    }
  }


}

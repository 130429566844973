import { ConfirmDoneIcon, CloseIcon, ErrorIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { FormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'number-field',
    templateUrl: './number-field.component.html',
    styleUrls: ['./number-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class NumberFieldComponent implements OnInit {


    @Input() borderOnHover:boolean=false
    
    @Input() value: number = null;
    valueBuffer: number=null;
    @Input() placeholder: string="Значение";
    @Input() clearBtnText: string="Очистить";
    @Input() clearBtn: boolean=true;
    @Input() enableNeagtive: boolean=true;
    @Input() enableDouble: boolean=true;
    @Input() disabled: boolean=false;
    @Input() min_size: boolean=false;
    
    @Output()
    valueChange = new EventEmitter<number>();

   
    @Output()
    escKey = new EventEmitter<any>();

    @Output()
    enterKey = new EventEmitter<any>();
    @Output()
    blurInput = new EventEmitter<any>();

    inputCorrect:boolean=true;

    errorText: string="";


    showWarning: boolean = false;
    @ViewChild('warning', { static: false }) warningElem: ElementRef;
    @ViewChild('field', { static: false }) fieldElem: ElementRef;
    @ViewChild('input_field', { static: false }) inputFieldElem: ElementRef;

    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon,
            ErrorIcon
        ])
    }



    ngOnInit() {
        this.valueBuffer=this.value;
    }

    BlurInput()
    {
        if(!this.inputCorrect)
        {
            //this.el.nativeElement.innerText=this.valueBuffer;
            //this.value=this.valueBuffer;
            this.showWarning=true;
            setTimeout(() => {
                
                let lf=this.fieldElem.nativeElement.getBoundingClientRect().right+10;
                let tp=this.fieldElem.nativeElement.getBoundingClientRect().top+10;
                
                if(document.documentElement.clientWidth-lf<this.warningElem.nativeElement.getBoundingClientRect().width)
                {
                    lf=this.fieldElem.nativeElement.getBoundingClientRect().left;
                    tp=this.fieldElem.nativeElement.getBoundingClientRect().top+this.fieldElem.nativeElement.getBoundingClientRect().height+10;
                }
                this.warningElem.nativeElement.style.left=lf+"px";
                this.warningElem.nativeElement.style.top=tp+"px";
                this.warningElem.nativeElement.style.opacity=1;
                setTimeout(()=>{this.showWarning=false;},2000)
                
            }, 100);
           

        }


        this.blurInput.emit(true);
    }
    

  

    clear()
    {
        this.value=null;
        this.valueBuffer=null;
        this.inputCorrect=true;
        this.valueChange.emit(this.value);
        this.BlurInput();
        this.inputFieldElem.nativeElement.focus();
    }

    checkButton(event: KeyboardEvent)
    {    
        /*
        keyup----->keydown
        if(event.keyCode!=13 && event.keyCode!=16 && event.key!="."  && event.keyCode!=27 && event.keyCode!=8 && event.keyCode!=46 && event.keyCode!=189 &&!(event.keyCode>=48 && event.keyCode<=57))
        {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }*/
        
        if(event.keyCode==27)
        {
            this.clear();
            this.escKey.emit(true);
        }
        if(event.keyCode==13)
        {
            this.enterKey.emit(true);
        }
        
    }

    onValueChange(event: any)
    {
        
        let regs;
        if(this.enableDouble && this.enableNeagtive && this.value!=null && event!="")
        {
            regs=new RegExp(/^-?\d+((\.)?\d+)?$/,"gi");
            if(regs.test(this.value))
            {
                this.inputCorrect=true;
                this.valueBuffer=this.value;
                this.valueChange.emit(this.value);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть десятичным положительным или отрицательным числом.  Разделитель может быть символом '.'";
            }
        }
        if(this.enableDouble && !this.enableNeagtive && this.value!=null  && event!="")
        {
            regs=new RegExp(/^\d+((\.)?\d+)?$/,"gi");
            if(regs.test(this.value))
            {
                this.inputCorrect=true;
                this.valueBuffer=this.value;
                this.valueChange.emit(this.value);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть десятичным положительным числом. Разделитель может быть символом '.'";
            }
        }
        if(!this.enableDouble && this.enableNeagtive && this.value!=null  && event!="")
        {
            regs=new RegExp(/^-?\d+$/,"gi");
            if(regs.test(this.value))
            {
                this.inputCorrect=true;
                this.valueBuffer=this.value;
                this.valueChange.emit(this.value);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть целым положительным или отрицательным числом.";
         
            }
        }
        if(!this.enableDouble && !this.enableNeagtive && this.value!=null  && event!="")
        {
            regs=new RegExp(/^\d+$/,"gi");
            if(regs.test(this.value))
            {
                this.inputCorrect=true;
                this.valueBuffer=this.value;
                this.valueChange.emit(this.value);}
            else
            {
                this.inputCorrect=false;
                this.errorText="Значение поля должно быть целым положительным числом.";
            }
        }
        if(this.value==null || event=="")
        {
            this.inputCorrect=true;
            this.valueBuffer=null;
            this.value=null;
            this.valueChange.emit(this.value);
        }
    }


}

import {
  Component,
  OnDestroy,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  SimpleChanges,
  InjectionToken,
  Attribute} from '@angular/core';



@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnDestroy, OnChanges, AfterViewInit {


  @Input("model") model: any;
  @Output() modelChange = new EventEmitter<any>();

  @Input() theme: 'gray' | 'white' | 'dark' = 'gray';

  @Input() bindLabel: string;
  @Input() disabled: boolean;

  // output events
  @Output('change') changeEvent = new EventEmitter();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  element: HTMLElement;
  focused: boolean;
  escapeHTML = true;
  useDefaultClass = true;

  visibleOptions: boolean = false;
  filterValue: string = '';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.visibleOptions) return;
    let x_click = event.x;
    let y_click = event.y;
    let container = this.element.querySelector('.selector-option-container').getBoundingClientRect();
    let selector = this.element.querySelector('.input-selector').getBoundingClientRect();


    // console.log(x_click < container.x || x_click > container.x + container.width)
    // console.log(y_click < container.y || y_click > container.y + container.height)
    if (x_click < container.x || x_click > container.x + container.width) {
      this.close();
    }

    if (y_click < selector.y || y_click > container.y + container.height) {
      this.close();
    }
  }


  constructor(
    @Attribute('class') public classes: string,
    // @Inject(SELECTION_MODEL_FACTORY) newSelectionModel: SelectionModelFactory,
    _elementRef: ElementRef<HTMLElement>,
  ) {
    // this.itemsList = new ItemsList(this, newSelectionModel());
    this.element = _elementRef.nativeElement;
  }


  public selectItem(ev: any, label: string) {
    this.model = ev;
    this.bindLabel = label;
    this.modelChange.emit(ev);
    this.changeEvent.emit(ev);
    this.close();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.model) {
      // this.selectItem();
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }


  close() {
    if (!this.visibleOptions) {
      return;
    }
    this.visibleOptions = false;
  }

  openOptions() {
    this.visibleOptions = !this.visibleOptions;
  }

  getSelectedValue(value: any) {
    console.log(value)
  }

}

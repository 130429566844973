import { Common } from "../../model.common";
import { AddressComponentInPerson } from "../In/model.AddressComponentInPerson";
import { AddressComponentInPersonInner } from "./model.AddressComponentInPersonInner";
import { PersonInList } from "../In/model.PersonInList";
import { CommonRequest, CommonResponse } from "../../model.http";

export class PersonInListInner extends Common {
  Post: string;
  Contacts: string;
  Clients: Common[];
  Tags: Common[];
  Changeable: boolean;
  MainInProject: boolean;
  AddressChain: AddressComponentInPersonInner[];
  CreateTime: Date;
  isEditing: boolean = false;

  constructor(p: PersonInList) {
    super(p.Id, p.Name);
    this.Post = p.Post;
    this.Contacts = p.Contacts;
    this.Clients = p.Clients;
    this.Tags = p.Tags;
    this.Changeable = p.Changeable;
    this.MainInProject = p.MainInProject;
    this.CreateTime = p.CreateTime;
    this.AddressChain = p.AddressChain == null ? null : p.AddressChain.map(c => new AddressComponentInPersonInner(c));
  }

  RefreshAfterEdit(p: PersonInList) {
    this.isEditing = p.isEditing;
    this.Name = p.Name;
    this.Post = p.Post;
    this.Contacts = p.Contacts;
    this.Tags = p.Tags;
    this.AddressChain = p.AddressChain == null ? null : p.AddressChain.map(c => new AddressComponentInPersonInner(c));
  }
}

export class PersonInfoGiver extends CommonResponse {
  Info: PersonInfo;
}

export class PersonInfo extends Common {
  Post: string;
  Address: AddressComponentInPersonInner[];
  AddressString: string = '';
  Contacts: string;
  Tags: Common[];

  CreateTime: Date;
  Creator: string;

  Changelog: PersonChangeOut[];
  ConnectedClients: Common[];
  ConnectedProjects: Common[];
}


export class PersonChangeOut {
  Time: Date;
  Worker: string;
  NameChanged: boolean;
  PostChanged: boolean;
  ContactsChanged: boolean;
  AddressChanged: boolean;
  TagsChanged: boolean;
  ClientsChanged: boolean;
  ResultMessage: string;
}

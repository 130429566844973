
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 19.04.2017.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';



import {
  ChatCreateAsker, ChatEditAsker, ChatFromPersonalToGlobalAsker, ChatListAsker,
  ChatSearchAsker, ChatSingleAsker, MessagesAroundOneAsker, MessageRefreshAsker, MessageSearchAsker, MessageAddAsker, GlobalChatAddAsker, ChatConvertToGlobalAsker, ChatSearchAskerOld, ChatRefreshAsker, AdditionalMessagesAsker, ChatMessagesSearchAsker
} from "../models/model.chat";
import {SingleIdRequest, CommonRequest, SingleIdGiver, CommonResponse} from "../models/model.http";

@Injectable()
export class HttpServiceChat {

    constructor(private http: HttpClient, private param: ServerString) {
    }



 /* chatCreate(obj: ChatCreateAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatCreate', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatDelete(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatDelete', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatEdit(obj: ChatEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatEdit', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatFromPersonalToGlobal(obj: ChatFromPersonalToGlobalAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatFromPersonalToGlobal', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatListLoad(obj: ChatListAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatListLoadTest', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  chatSearch(obj: ChatSearchAskerOld) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatSearch/Chats', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }
  chatSearchMessages(obj: ChatSearchAskerOld) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatSearch/Messages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatSettingsLoad(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatSettingsLoad', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatSingleLoad(obj: ChatSingleAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatSingleLoad', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatExitOld(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatExit', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  messagesAroundOneLoad(obj: MessagesAroundOneAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessagesAroundOneLoad', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  messagesRefresh(obj: MessageRefreshAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessagesRefresh', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }
  messagesSearch(obj: MessageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessagesSearch', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  messageAdd(obj: MessageAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessageAdd', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  messageRead(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessageRead', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  loadWorkersToNewChat(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatLoadWorkerList', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  loadChatAccessGiver(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatLoadAccessGiver', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }



  getAttachedMessages(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessageAttachedShowTree', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatReplyListGet(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ChatReplyListGet', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  refreshUnreadedMessages(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'MessagesReadedUpdate', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

*/



  ////////////NEW CHATS/////////////////

  chatGetAccesss(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/GetAccess', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatAddConference(obj: GlobalChatAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/Add/Global', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatAddPersonal(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/Add/Personal', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatConvertToGlobal(obj: ChatConvertToGlobalAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/ConvertToGlobal', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }



  chatExit(obj: SingleIdRequest ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/Exit', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetChatsList(obj: CommonRequest ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/ListGet', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetChatsListToReply(obj: CommonRequest ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/GetListToReply', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatSearchCommon(obj: ChatSearchAsker ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/Search', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatListRefresh(obj: ChatRefreshAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/ListRefresh', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatClearHistory(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/ClearHistory', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  chatGetSettings(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/GetSettings', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatEditChat(obj: ChatEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/Edit', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetWorkersToCreate(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/GetWorkersToCreateChat', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  chatAddMessage(obj: MessageAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/AddMessage', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatReadMessage(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/ReadMessage', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetReadedMessages(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Common/GetReadedMessages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGet(obj: ChatSingleAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/Get', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetNextMessages(obj: AdditionalMessagesAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/GetNextMessages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatGetPreviousMessages(obj: AdditionalMessagesAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/GetPreviousMessages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  chatJumpToMessage(obj: ChatSingleAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/JumpToMessage', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  chatRefreshMessages(obj: AdditionalMessagesAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/RefreshMessages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatSearchMessagesInChat(obj: ChatMessagesSearchAsker ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/SearchMessages', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  chatShowAttachedMessagesTree(obj: SingleIdRequest ) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Chat/Web/ShowAttachedMessagesTree', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


}

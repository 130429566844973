import { CommonPositive } from './model.Analytics/model.ProjectAnalytics/model.ProjectSelectionQuery/model.Filters/model.Filters';
/**
 * Created by MosNeuro on 02.12.2016.
 */
import { CommonResponse } from "./model.http";

export class Common {
  Id: number;
  Name: string;

  constructor(id: number = 0, name: string = "") {
    this.Id = id;
    this.Name = name;
  }
  static CreateCommon(id: number, name: string) {
    let a = new Common();
    a.Id = id;
    a.Name = name;
    return a;
  }
}


export class CommonEntityWithOldName extends Common {
  OldName: string;
}



export class CommonSelectable extends Common {
  Selected: boolean;
  Address: string[];
  constructor(id: number = 0, name: string = "", selected: boolean = false, address: string[] = null) {
    super(id, name);
    this.Address = address;
    this.Selected = selected;
  }
}

export class CommonColored extends Common {
  Color: string
}

export class CommonIndexed extends Common {
  Index: string;
  constructor(id: number, name: string, index: string) {
    super(id, name);
    this.Index = index;
  }
}

export class CommonSelectableForContainer extends CommonSelectable {
  SelectedInContainer: boolean;
  constructor(id: number = 0, name: string = "", selected: boolean = false) {
    super(id, name, selected);
    this.SelectedInContainer = false;
  }
}

export class CommonSelectableListGiver extends CommonResponse {
  List: CommonSelectable[];
}

export class KeyValueStrings {
  Key: string;
  Value: string;
}


export class StringListGiver extends CommonResponse
        {
            List: string[];
        }     
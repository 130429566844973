// import { HttpSessionService } from '../../services/session-sync.service';
import { Injectable } from "@angular/core";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { fromEvent, of } from "rxjs";
import { mergeMap, map, catchError, tap, filter } from "rxjs/operators";
import { ESessionAction } from '../actions/session.action';

@Injectable()
export class SessionEffects {
    @Effect({ dispatch: false })
    storeActions$ = this.actions$.pipe(
        // intercepts actions for adding, checking off, or removing items
        ofType(
            ESessionAction.LoginSession,
            ESessionAction.ChangeSession
        ),
        tap(action => {
            // get the current actions from local storage
            const storedActions = window.localStorage.getItem('__bus');
            // parse the actions as an array
            const actions = storedActions ? JSON.parse(storedActions) : [];
            // add the new action as first item to the actions array
            const newActions = [action, ...actions];
            // serialize the new array and update local storage
            window.localStorage.setItem(
                '__bus',
                JSON.stringify(newActions)
            );
        }),


    );

    // @Effect()
    // onChange$ = fromEvent<StorageEvent>(window, 'storage').pipe(
    //     // listen to our storage key
    //     filter(evt => evt.key === '__bus'),
    //     filter(evt => evt.newValue !== null),
    //     // deserialize the stored actions
    //     // get the last stored action from the actions array
    //     map(evt => {
    //         const [{ type, payload }] = JSON.parse(evt.newValue);
    //         switch (type) {
    //             case ESessionAction.LoginSession:
    //                 return {
    //                     type: ESessionAction.LoginSessionStorage,
    //                     payload
    //                 };
    //             case ESessionAction.ChangeSession:
    //                 return {
    //                     type: ESessionAction.ChangeSessionStorage,
    //                     payload
    //                 };
    //             default:
    //                 return ESessionAction.GetSession;
    //         }
    //         // JSON.parse(evt.newValue)[0]
    //     }),
    // );

    constructor(
        private actions$: Actions,
        // private httpSessionService: HttpSessionService
    ) { }
}

/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { Common } from '../../../models/model.common';
import { ParameterInstance, ParameterInstanceAddAsker, ParameterInstanceContainerGiver, ParameterInstanceEditAsker, ParameterValueContainerGiver } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { HttpServiceStorage } from '../../../httpserv/httpserv.storage';


declare var require: any;


@Component({
    selector: 'parameterInstanceDateContainer',
    templateUrl: 'temp.parameterInstanceDateContainer.html',
    styleUrls: ['parameterInstanceDateContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceDateContainerComponent implements OnInit {
    
    
    @Input() value: Date=null;
    @Input() valuesList: ParameterInstance[]=[];
    @Output() valueChange:  EventEmitter<Date> = new EventEmitter<Date>();
    @Output() valuesListChange:  EventEmitter<ParameterInstance[]> = new EventEmitter<ParameterInstance[]>();
    @Output() deleteParameter:  EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isList: boolean=false;
    @Input() Name: string="Parameter Name";
    @Input() CanDelete: boolean = true;
    @Input() id: number=-1;
    @Input() editable: boolean=true;
    @Input() canRemove: boolean=true;
    
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;
    @Input() containerLocation: number=0;// 0 параметры к,п 1 - наименование склада

    @Input() containerId: number=0;
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private sessionService: SessionService,
    private httpServiceStorage: HttpServiceStorage,
    private router: Router
              ) { }

            
              ngOnDestroy() {
               
              }

              

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
        
              }

              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }
              
            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }


              onValueChange()
              {
                this.valueChange.emit(this.value);
              }

              onValuesListChange()
              {
                this.valuesListChange.emit(this.valuesList);
              }


              EditValueInList(val: any)
              {
               
                
                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                checker.DateValue=val.DateValue;
                
                this.httpServiceParameters.parameterInstanceEditValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }

                  if(data.Code==303)
                    {
                      
                        alert("вхождение параметра не найдено");
                        this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                    }
                    if(data.Code==304)
                    {
                        alert("вхождение параметра было удалено");
                        this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                    }
                    
                    if(data.Code==305)
                    {
                        console.log("параметр является записью");
                    }
                    
                    if(data.Code==306)
                    {
                      console.log("сет не является расширяемым");
                    }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      
                      this.onValuesListChange();
                    }

                });  


                
              }


              AddValueInList()
              {
                if(this.containerLocation==0)
                {
                  this.AddValueInListParameters() 
                }
                if(this.containerLocation==1)
                {
                  this.AddValueInListStorage() 
                }
              }

              DeleteValueInList(val:any)
              {
                if(this.containerLocation==0)
                {
                  this.DeleteValueInListParameters(val) 
                }
                if(this.containerLocation==1)
                {
                  this.DeleteValueInListStorage(val) 
                }
              }


              AddValueInListStorage()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.containerId;
                
                this.httpServiceStorage.itemAddNameElement(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                      
                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>this.valuesList.push(el.Instance));
                     
                      this.onValuesListChange();
                    }

                });  
              }
            
              DeleteValueInListStorage(val:any)
              {
               
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceStorage.itemRemoveNameElement(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    

                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }

                    if(data.Code==303)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                      if(data.Code==304)
                      {
                        alert("Элемент списка не найден");
                        this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                      }


                    
                      
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

              AddValueInListParameters()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                
                this.httpServiceParameters.parameterInstanceAddElementToList(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }

                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }
                    
                      if(data.Code==303)
                      {
                          alert("Тайтл не найден");
                      }
                      if(data.Code==304)
                      {
                          alert("Тайтл был удален");
                      }
                      if(data.Code==305)
                      {
                          alert("Параметр не является списком");
                          this.goToMain(null);
                      }
                     
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>this.valuesList.push(el.Instance));
                      this.onValuesListChange();
                    }

                });  
              }
            
              DeleteValueInListParameters(val:any)
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceParameters.parameterInstanceRemoveElementFromList(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          console.log("вхождение не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==304)
                      {
                        
                          console.log("вхождение было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==305)
                      {
                          alert("параметр не является списком и не может быть удален данным методом");
                      }

                      if(data.Code==306)
                      {
                        alert("Тайтл не найден");
                      }

                      if(data.Code==307)
                      {
                        alert("Тайтл был удален");
                      }

                      if(data.Code==308)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                      
                      
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

             /* DefaultValueInList(val:any)
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceParameters.parameterInstanceSetToDefaultValue(checker).subscribe((data: ParameterValueContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          alert("вхождение не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==304)
                      {
                        
                          alert("вхождение было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==305)
                      {
                        
                          console.log("вхождение параметра является записью и не может быть отредактированно данным методом");
                      }
                  
                  
                  
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      if(data.Container!=null)
                      {
                        val.DateValue=data.Container.DateValue;
                        
                      }
                      this.onValuesListChange();
                    }

                });  
              }*/


              ClearValue()
              {
                this.value=null;
                this.EditValue();
              }

              ClearValueInList(val: any)
              {
                val.DateValue=null;
                this.EditValueInList(val);
              }


              EditValue()
              {
               
                let checker = new ParameterInstanceEditAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                checker.DateValue=this.value;
                
                this.httpServiceParameters.parameterInstanceEditValue(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                      {
                          alert("клиент или проект не найден");
                          this.goToMain(null);
                      }
                      if(data.Code==301)
                      {
                          alert("клиент или проект был удален");
                          this.goToMain(null);
                      }

                      if(data.Code==302)
                      {
                          alert("нет доступа к клиенту или проекту");
                          this.goToMain(null);
                      }

                    if(data.Code==303)
                      {
                        
                          alert("вхождение параметра не найдено");
                      }
                      if(data.Code==304)
                      {
                          alert("вхождение параметра было удалено");
                      }
                      
                      if(data.Code==305)
                      {
                          console.log("параметр является записью");
                      }
                      
                      if(data.Code==306)
                      {
                        console.log("сет не является расширяемым");
                      }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      
                      this.onValueChange();
                    }

                });  


                
              }

             /* DefaultValue()
              {
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                
                this.httpServiceParameters.parameterInstanceSetToDefaultValue(checker).subscribe((data: ParameterValueContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          alert("вхождение не найдено");
                      }

                      if(data.Code==304)
                      {
                        
                          alert("вхождение было удалено");
                      }

                      if(data.Code==305)
                      {
                        
                          console.log("вхождение параметра является записью и не может быть отредактированно данным методом");
                      }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      if(data.Container!=null)
                      {
                        this.value=data.Container.StringValue;
                      }
                      this.onValueChange();
                    }

                });  
              }*/

              DeleteParameter()
              {
                
                this.deleteParameter.emit(true);
              }



              parseDate(dateString: string): Date {
                if (dateString) {
            
                  
                    return new Date(dateString);
                }
                return null;
              }
              
              
}

import { ISessionState } from "../state/session.state";
import { createAction, props } from '@ngrx/store';

interface Action {
    type: string
}

export enum ESessionAction {
    GetSession = '[Session] Get Session',
    ChangeSession = '[Session] Change Session',
    LoginSession = '[Session] Login Session',
    LoadSession = "[Session] Load Session",
    DeleteSession = "[Session] Delete Session"
}


export const GetSession = createAction(ESessionAction.GetSession);

export const ChangeSession = createAction(ESessionAction.ChangeSession, props<{ payload: string }>());

export const LoginSession = createAction(ESessionAction.LoginSession, props<ISessionState>());

export const LoadSession = createAction(ESessionAction.LoadSession, props<ISessionState>());

export const DeleteSession = createAction(ESessionAction.DeleteSession);




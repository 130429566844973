
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ServerString } from "../../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';



import { IdToId } from "../../models/model.idtoid";
import { AddressFilterAsker } from "../../models/model.Address/Out/Any/model.AddressFilterAsker";
import { CommonRequest } from "../../models/model.http";
import { AddressInstanceAddAsker } from '../../models/model.Address/Out/Any/model.AddressInstanceAddAsker';


@Injectable()
export class HttpServiceAddressAny {
  route: string = "address/any/";

  constructor(private http: HttpClient,
    private param: ServerString) { }


  autocompleteListGet(obj: AddressFilterAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'autocompleteListGet', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => { return observableThrowError(error); }));
  }

  precheckAddressAdd(obj: AddressInstanceAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AddressInstancePrecheck', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => { return observableThrowError(error); }));
  }

  inputContainerGet(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'inputContainerGet', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => { return observableThrowError(error); }));
  }


  checkChanges(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AddressChangeTimeGet', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => { return Observable.throw(error); }));
  }
}

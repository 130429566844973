import { EventEmitter, Injectable, Output } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { ServerString } from "../config/serverstring";
import { HttpTransportType } from "@microsoft/signalr";  // or from "@microsoft/signalr" if you are using a new library
import { ChatNotificator, ReadMessageNotificator, RemovedMessageNotificator } from '../models/model.chat';
import { SoundService } from '../config/SoundService';
import { JobNotificatorGiver } from '../models/model.Job/In/model.JobCountGiver';
import { SessionService } from './session.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data: string;
  public notificator: ChatNotificator;
  UserId: number = 0;
  s_ui: Subscription = null;
  @Output() NewMessage: EventEmitter<ChatNotificator> = new EventEmitter<ChatNotificator>();
  @Output() MessageReaded: EventEmitter<ReadMessageNotificator> = new EventEmitter<ReadMessageNotificator>();
  @Output() MessageRemoved: EventEmitter<RemovedMessageNotificator> = new EventEmitter<RemovedMessageNotificator>();
  @Output() JobChanged: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();
  @Output() JobAdded: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();
  @Output() JobRemoved: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();
  //Must crete new Interface for Emmiter
  @Output() UserAdded: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();
  @Output() UserRemoved: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();
  @Output() UserChanged: EventEmitter<JobNotificatorGiver> = new EventEmitter<JobNotificatorGiver>();


  private hubConnection: signalR.HubConnection;
  private hubConnectionJob: signalR.HubConnection;
  private hubConnectionUser: signalR.HubConnection;
  constructor(private soundService: SoundService, private sessionService: SessionService) {
    this.s_ui = sessionService.userId$.subscribe(id => this.UserId = id);
  }
  static getUrl(): string {
    var ss = new ServerString();
    let url = 'https://' + ss.clientStringPrimery() + ':44327/chatHub';

    return url;
  }
  static getUrlJob(): string {
    var ss = new ServerString();
    let url = 'https://' + ss.clientStringPrimery() + ':44327/jobNotificationHub';

    return url;
  }

  static getUrlUser(): string {
    var ss = new ServerString();
    let url = 'https://' + ss.clientStringPrimery() + ':44327/userNotificationHub';

    return url;
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(SignalRService.getUrl(), { transport: (HttpTransportType.WebSockets), skipNegotiation: true })
      .withAutomaticReconnect()
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));

    this.hubConnectionJob = new signalR.HubConnectionBuilder()
      .withUrl(SignalRService.getUrlJob(), { transport: (HttpTransportType.WebSockets), skipNegotiation: true })
      .withAutomaticReconnect()
      .build();
    this.hubConnectionJob
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))

    this.hubConnectionUser = new signalR.HubConnectionBuilder()
      .withUrl(SignalRService.getUrlUser(), { transport: (HttpTransportType.WebSockets), skipNegotiation: true })
      .build();
    this.hubConnectionUser
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  };

  public checkConnection = () => {
    if (this.hubConnection != undefined && this.hubConnection != null) {
      //console.log(this.hubConnection.state);
    }
    else {
      console.log("nonesignalr");
    }


    if (this.hubConnectionJob != undefined && this.hubConnectionJob != null) {
      //console.log(this.hubConnectionJob.state);
    }
    else {
      console.log("nonesignalr");
    }

    //if(this.hubConnection.state)
  }

  public addDataListener = () => {

    this.hubConnection.on('ReceiveMessage', (data) => {
      this.data = data;
    });


    this.hubConnection.on('MessageSent', (data) => {

      let notificator = JSON.parse(data);
      this.NewMessage.emit(notificator);

      if (notificator.NotifiedWorkers.findIndex(c => c == this.UserId) > -1) {
        this.soundService.playMessageAlert();
      }


    });

    this.hubConnection.on('MessageRead', (data) => {
      let notificator = JSON.parse(data);
      this.MessageReaded.emit(notificator);
    });


    this.hubConnectionJob.on('JobChanged', (data) => {

      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.JobChanged.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }

    });

    this.hubConnectionJob.on("AddNewJob", (data) => {
      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.JobAdded.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }
    })

    this.hubConnectionJob.on("RemoveJob", (data) => {
      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.JobRemoved.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }
    })


    this.hubConnection.on('NotificationRemoved', (data) => {
      let notificator = JSON.parse(data);
      this.MessageRemoved.emit(notificator);
    });

    this.hubConnectionUser.on("AddNewUser",(data)=>{
      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.UserAdded.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }
    })

    this.hubConnectionUser.on("RemoveJob",(data)=>{
      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.UserRemoved.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }
    })

    this.hubConnectionUser.on("ChangeUser",(data)=>{
      let notificator = JSON.parse(data);
      let notif = false;
      notificator.List.forEach(c => {
        if (c.Worker == this.UserId) { notif = true; }
      })

      this.UserChanged.emit(notificator);
      if (notif) {
        this.soundService.playNotificationAlert();
      }
    })


  }
}

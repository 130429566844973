import {Injectable} from "@angular/core";
/**
 * Created by MosNeuro on 02.02.2018.
 */
@Injectable()
export class DOMHelper {
  public static FocusElement(elementId: string, timeOut: number) {
    setTimeout(()=> {
      let searchElement = document.getElementById(elementId);
      if (searchElement != null) searchElement.focus();
    }, timeOut);
  }

  public static BlurElement(elementId: string, timeOut: number) {
    setTimeout(()=> {
      let searchElement = document.getElementById(elementId);
      if (searchElement != null) searchElement.blur();
    }, timeOut);
  }

  public static ScrollElementToView(elementId: string, timeOut: number) {
    setTimeout(()=> {
      let searchElement = document.getElementById(elementId);
      if (searchElement != null) 
      {searchElement.scrollIntoView(); 
      }
    }, timeOut);
  }

  public static ScrollToTop(trackerId: string, timeOut: number) {
    setTimeout(()=> {
      let tracker = document.getElementById(trackerId);
      if (tracker!=null) tracker.scrollTop = 0;
    }, timeOut);
  }

  public static ScrollToBottom(trackerId: string, timeOut: number) {
    setTimeout(()=> {
      let tracker = document.getElementById(trackerId);
      if (tracker!=null) tracker.scrollTop = tracker.scrollHeight - tracker.clientHeight;
    }, timeOut);
  }

  public static ScrollToPosition(trackerId:string, timeOut:number, position: number) {
    setTimeout(()=> {
      let tracker = document.getElementById(trackerId);
      if (tracker!=null && position != tracker.scrollTop)tracker.scrollTop = position;
    }, timeOut);
  }

  public static IsScrollAtTheBottom(trackerId:string, timeOut:number) {
    let tracker = document.getElementById(trackerId);
    if (tracker!=null) return tracker.scrollTop>=(tracker.scrollHeight - tracker.clientHeight - 1);
  }

  public static IsScrollAtTheTop(trackerId:string, timeOut:number) {
    let tracker = document.getElementById(trackerId);
    if (tracker!=null) return tracker.scrollTop==0;
  }

}


/**
 * Created by MosNeuro on 08.09.2017.
 */

export class LangSetLogin

{
  Language:number;

  Login: string = ""; //Войти

  ForgotPassword: string = ""; //Забыли пароль?

  MobileMosta: string = ""; //MOSTA для мобильных устройств

  Desc1: string = ""; // Чтобы всегда оставаться на связи с клиентами, не обязательно находиться за компьютером.

  Desc2: string = ""; //Установите официальное мобильное приложение MOSTA и продолжайте работу над Вашими проектами, где бы Вы ни находились.

  MobileMostaInstall: string = ""; //Установить приложение на телефон

  PasswordRecovering: string = ""; //Восстановление пароля

  EnterEmail: string = ""; //Пожалуйста, введите Email, который вы использовали для входа на сайт

  AccessRecovering: string = ""; //Восстановление доступа к странице

  ForgotAnything: string = ""; // Если вы не помните данных, нажмите сюда

  ForgotAnythingDesc: string = ""; //Уведомление о проблемах с регистрацией будет отправлено администраторам, которые помогут восстановить доступ к системе.

  ForgotAnythingSent: string = ""; //Уведомление о проблемах с регистрацией было отправлено.

  Fio: string = ""; //ФИО

  SendAgain: string = ""; //Отправить повторно

  Authorization: string = ""; // Авторизация

  FioEmpty: string = ""; //ФИО не указано

  EmailEmpty: string = ""; //Почтовый адрес указан неверно

  LoginEmpty: string = ""; //Логин не указан

  PasswordResetButton: string = ""; //Получить напоминание о пароле

  PasswordResetSent: string = ""; //На Ваш email была отправлена новый пароль для восстановления доступа в систему.

  NextStep:string = "";//Далее
PasswordEmpty:string = "";//Пароль не указан
Email:string = "";// Email
Password:string = "";//Пароль
  //В случае неполучения пароля в течение 15 минут, можно отправить запрос повторно. Рекомендуется немедленно сменить пароль.

  NervousSystem:string = "";
  IpBlocked1:string = "";
  IpBlocked2:string = "";
}

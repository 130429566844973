/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { CommonWithSelectAndAdditional } from '../../../models/model.commonWithSelect';
import { ParameterInstanceContainerLight } from '../../../models/model.parameters';


declare var require: any;


@Component({
    selector: 'parameterInSearchView',
    templateUrl: 'temp.parameterInSearchView.html',
    styleUrls: ['parameterInSearchView.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInSearchViewComponent implements OnInit, OnChanges {

    @Input() parameter: ParameterInstanceContainerLight;
    @Input() searchText: string="";
    @Input() showInstanceName: boolean=false;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    
  constructor(
    private languageService: LanguageService
              ) { }

            
              ngOnDestroy() {
              
              
              }

              

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.selectSearched();
               
              }

              ngOnChanges(changes: SimpleChanges)
              {
                for (let prop in changes) {
                  if(prop=="parameter")
                  {
                    let chng = changes[prop];

                    if (!chng.isFirstChange()) 
                    {
                      this.selectSearched();
                    }
                  }
                  
                }
              }

          
              selectSearched()
              {

                if (this.searchText != null && this.searchText != undefined && this.searchText != "")
                {
                  if(this.parameter.Type==0 && this.parameter.UsedInSearch && !this.parameter.Instance.IsRecord)
                  {
                    
                      this.parameter.Instance.Value = SearchSelectHelper.getSearchedText(this.searchText, this.parameter.Instance.Value);
                  }
                  if(this.parameter.Type==1 && this.parameter.UsedInSearch)
                  {
                    this.parameter.ListTitle.Instances.forEach(p=>
                      {
                        if(!p.IsRecord)
                        {
                          p.Value= SearchSelectHelper.getSearchedText(this.searchText, p.Value);
                        }
                      })
                  }
                }
                

              }
              
}

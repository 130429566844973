import { ParentChecker } from "./model.parentChecker";
import { Common, CommonSelectable } from "./model.common";
import { CommonRequest, CommonResponse, SingleIdRequest } from "./model.http";
/**
 * Created by MosNeuro on 01.03.2017.
 */
export class TagFilter// extends ParentChecker
{
  Name: string = '';
  EntityType: number;
  EntityId: number;
  EditingTagId: number;

  constructor() {
    this.Name = '';
  }
}

export class TagFilterToSendExtended extends CommonRequest {
  EntityType: number;
  // NoTagsIncluded: boolean;

  constructor(userId: number, sessionKey: string, entityType: number, noTagsIncluded: boolean = false) {
    super(userId, sessionKey);
    this.EntityType = entityType;
    // this.NoTagsIncluded = noTagsIncluded;
  }

}

export class TagContainerGiver extends CommonResponse {
  TagContainer: TagContainer;
}

export class TagWithFrequency extends CommonSelectable {
  Frequency: number;
  FullName: string;
}


export class SelectedTag {
  TagIndex: number;
  ListNum: number;//0 верхний левый, 1 нижний левый, 2 правый
  Found: boolean;
  NotNull: boolean;

  constructor(notNull: boolean, found?: boolean, tagIndex?: number, listNum?: number) {
    this.NotNull = notNull;

    this.Found = found;
    if (found != null) {
      this.Found = found;
    }
    if (tagIndex != null) {
      this.TagIndex = tagIndex;
    }
    if (listNum != null) {
      this.ListNum = listNum;
    }
  }
}

export class AfterTagFormKeyDownAction {
  DoAction: boolean;
  Action: string;

  constructor(doAction: boolean, action?: string) {
    this.DoAction = doAction;
    if (action != null) {
      this.Action = action;
    }
  }

}

export class ClosestTag {
  ListNum: number;
  TagIndex: number;

  constructor(tagIndex: number, listNum?: number) {
    this.TagIndex = tagIndex;
    if (listNum != null) this.ListNum = listNum;
  }
}

export class TagContainer {
  AllSystemTags: TagWithFrequency[] = [];//0
  OftenUsedForEntityTags: TagWithFrequency[] = [];//1
  OftenUsedByUserTags: TagWithFrequency[] = [];//2

}

export class TagContainerInner {
  AllSystemTags: TagWithFrequency[] = [];//0
  OftenUsedForEntityTags: TagWithFrequency[] = [];//1
  OftenUsedByUserTags: TagWithFrequency[] = [];//2

  AllSystemTagsBack: TagWithFrequency[] = [];//0
  OftenUsedForEntityTagsBack: TagWithFrequency[] = [];//1
  OftenUsedByUserTagsBack: TagWithFrequency[] = [];//2

  constructor(cont: TagContainer) {
    this.AllSystemTagsBack = cont.AllSystemTags;
    this.AllSystemTags = cont.AllSystemTags;

    this.OftenUsedForEntityTags = cont.OftenUsedForEntityTags;
    this.OftenUsedForEntityTagsBack = cont.OftenUsedForEntityTags;

    this.OftenUsedByUserTags = cont.OftenUsedByUserTags;
    this.OftenUsedByUserTagsBack = cont.OftenUsedByUserTags;
  }
}



export class TagAddAsker extends CommonRequest {
  Name: string;
  EntityType: number;
  EntityId: number;
  EditingTagId: number;

  constructor(changerId: number, sessionKey: string, name: string, entityType: number, entityId: number, editingTagId: number) {
    super(changerId, sessionKey);
    //super(changerId, sessionKey);
    this.Name = name;
    this.EntityType = entityType;
    this.EntityId = entityId;
    this.EditingTagId = editingTagId;
  }
}

export class TagListGiver extends CommonResponse {
  TagList: Common[];
}

export class TagFromAngular extends CommonRequest {
  Name: string;
  EntityType: number;
  EntityId: number;
}
export class TagFromAngularDeleting extends SingleIdRequest {
  EntityType: number;
  EntityId: number;
  constructor(changerId: number = 0, sessionKey: string = "", tagId: number, type: number, entityId: number) {
    super(changerId, sessionKey, tagId)
    this.EntityType = type;
    this.EntityId = entityId;
  }
}

export class TagEntityTypeEnum {
  Job: number = 0;
  Person: number = 1;
  Client: number = 2;
  Project: number = 3;
  ClientFilter: number = 4;
  ProjectFilter: number = 5;
  File: number = 6;
  Autojob: number = 7;

  constructor() {
    this.Job = 0;
    this.Person = 1;
    this.Client = 2;
    this.Project = 3;
    this.ClientFilter = 4;
    this.ProjectFilter = 5;
    this.File = 6;
    this.Autojob = 7;
  }
}

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Icon } from "../../../models/icon";
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { ErrorIcon } from '../svg-icon/svg-icon.data';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input()
  icon: 'error' | 'warn';

  @Input('content') content: string;
  @Input('contentArray') contentArray: string[];
  @Input() visible: boolean;
  @Input() reverse: boolean = false;

  posLeft: string;
  posTop: string;
  messageVisibility: boolean = false;

  constructor(private svgIconsRegistry: SvgIconsRegistry) {
    this.svgIconsRegistry.registerIcons([
      ErrorIcon
    ])
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.getPositionMessage();
  }

  getPositionMessage(event: any) {
    console.log(event)
    let clientWidth = document.documentElement.clientWidth;
    let clientHeight = document.documentElement.clientHeight;
    let h = window.innerHeight;
    // let iconDoc = document.getElementById('tooltipid')
    let iconDoc = event.target;
    console.log(iconDoc);
    // if (iconDoc.length > 0) {
      // console.log(iconDoc[0]);

      let ElementCoordinate = iconDoc.getBoundingClientRect();
      console.log(ElementCoordinate);

      this.posLeft = ElementCoordinate.right + 25 + "px";
      this.posTop = ElementCoordinate.top - ElementCoordinate.height / 2 + "px";

      // this.posLeft = ElementCoordinate.right  + "px";
      // this.posTop = ElementCoordinate.top - ElementCoordinate.height / 4 - 40 + "px";
    // }

    this.messageVisibility = true;
  }

  getIconType(type: string) {
    // if (type != null) {
    //   var pos = this.icons.map(function (e) { return e.name; }).indexOf(type);
    //   return this.icons[pos].path;
    // }
    switch (this.icon) {
      case 'error':
        return 'error'
        break;
      case 'warn':
        return 'warn'
        break;
      default:
        return null;
        break;
    }
  }

}

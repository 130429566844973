import { sessionReducer } from './reducers/session.reducer';
import { userReducer } from './reducers/user.reducer';
import { IAppState } from './state/app.state';
import { ActionReducerMap } from "@ngrx/store";
import { routerReducer } from '@ngrx/router-store';


export const appReducer: ActionReducerMap<IAppState> = {
    router: routerReducer,
    user: userReducer,
    session: sessionReducer
}
/**
 * Created by MosNeuro on 20.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  ElementRef,
  ViewChild,
  SimpleChanges,
  HostListener,
  AfterContentInit,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { FormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';

import { FieldLinkPartOut, FormulaTokenOut, FormulFieldsContainer,  PastedValues, RecordFieldCreateContainer } from '../../../models/model.parameters';



declare var require: any;


@Component({
    selector: 'formulField',
    templateUrl: 'temp.formulField.html',
    styleUrls: ['formulField.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class FormulFieldComponent implements OnInit, OnChanges, AfterViewInit, AfterContentInit {
    
  @Input() isInnerField: boolean=false;
  formulIncorrect:boolean=false;
  @Input() FieldId: Number=0;
  @Input() FormulTokens:FormulaTokenOut[]=[];
  @Input() FormulPossibleFields:RecordFieldCreateContainer[]=[];
  @Input() possibleFieldsChanged: Subject<any[]> = new Subject();

  @Input() checkFormulBeforeSave: Subject<any[]> = new Subject();
    //value="<span>2</span><span>+</span><span>2</span><span>+</span><span (click)='setInnerFocus($event)'>ddd</span>"
   // values=[{type:1, id:0, value:"2", isList:false, sum:false, parents:null},{type:0, id:1, value:"+", isList:false, sum:false, parents:null},{type:1, id:2, value:"2", isList:false, sum:false, parents:null},{type:0, id:3, value:"+", isList:false, sum:false, parents:null},{type:2, id:4, value:"ddd", isList:false, sum:false, parents:null},{type:0, id:1, value:"+", isList:false, sum:false, parents:null},{type:1, id:2, value:"2", isList:false, sum:false, parents:null}]
   values=[]; 
   langset: Common_LangSet;
    ln_sub: Subscription=null;
    elementListIsLoading: boolean=false;

    filterInput = new FormControl()
   filterSub: Subscription = null;
   filterText: string="";
   @ViewChild('textInput') el: ElementRef;
   @ViewChild('searchInputVS', { static: false }) searchInputVSElem: ElementRef;



   fields:any[]=[];
   curSel:Selection;
   curFocusOffSet: number;
   curAnchorOffSet: number;
   curFocusNode: Node;
   curAnchorNode: Node;
   curRange:any;
   curFocusType:number=-1;  //0-текст формулы 1-поле в формуле

   showPasteOptions: boolean=false;
   invisibleOptions: boolean=true;

   //valuesForPaste=[{type:2, id:10, value:"first", isList:true, sum:false, parents:null},{type:2, id:11, value:"second", isList:false, sum:false,  parents:null},{type:2, id:12, value:"third", isList:false, sum:false, parents:null},{type:2, id:13, value:"fourth", isList:true, sum:false, parents:[{type:2, id:14, value:"parent1", isList:true, sum:false, parents:null}, {type:2, id:15, value:"parent2", isList:true, sum:false, parents:null}]}]
  
  valuesForPaste:FormulFieldsContainer[]=[];
  valuesForPasteShow:FormulFieldsContainer[]=[];
  FormulTokensInput:FormulaTokenOut[]=[];
  NUMS=["0","1","2","3","4","5","6","7","8","9",".",","];
  NOT_TXT=["0","1","2","3","4","5","6","7","8","9"," ","+","-","*","/","(",")"];
  OPERATORS=[" ","+","-","*","/","(",")"];
  FUNCTIONAL_KEY_CODES=[9,19,20,33,34,35,36,44,45,91,92,93,112,113,114,115,116,117,118,119,120,121,122,123,144, 145,176,177,179];
  isFocused:boolean=false;
  @ViewChild('fieldContainer', { static: false }) fieldContainer: ElementRef;
  @ViewChild('optionsContainer', { static: false }) optionsContainer: ElementRef;
  hoveredFieldId: number=null;

  @Output() FormulTokensChange:  EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() FieldSaved:  EventEmitter<boolean> = new EventEmitter<boolean>();//FormulaTokenOut[] -0, correct -1
   constructor(
    private languageService: LanguageService
              ) { }



             @HostListener('document:click', ['$event'])
              public onClickActive(e: Event): void {
                  if(this.isFocused && !this.fieldContainer.nativeElement.contains(e.target))
                  {
                    this.isFocused=false;
                    this.hoveredFieldId=null;
                    this.showPasteOptions=false;
                    this.clearFormul();
                    this.checkFormul();
                    this.clearSelection();
                  }
              }

              @HostListener('window:resize', ['$event'])
              public onResizePage(e: Event): void {
                  this.resizeField();
                  
              }


              sumHover(ev: MouseEvent)
              {
                let element=<HTMLElement>ev.target;
                element.style.color="#23844c";
                let parent = element.parentElement;
                parent.style.backgroundColor="#f2f2f2";
              }

              sumUnhover(ev: MouseEvent)
              {
                let element=<HTMLElement>ev.target;
                element.style.color="#2c20af";
                let parent = element.parentElement;
                parent.style.backgroundColor="transparent";
              }


              resizeField()
              {
                let elem = <HTMLElement>this.el.nativeElement;
                if(elem!=undefined && elem!=null)
                {
                  let last=<HTMLElement>elem.lastChild;
                  if(last!=undefined && last!=null)
                    {
                      let height=last.offsetTop+last.offsetHeight+10;
                      elem.style.height=height+"px";
                    }
                }
                  
              }

              setRangeNodeContents(element: any)
              {
                let di1= document.getSelection();
                  di1.removeAllRanges();
                  let range = document.createRange();
                  range.selectNodeContents(element)
                  range.collapse(false)
                  di1.addRange(range);
              }

              setRangeNodeContentsCollapse(element: any)
              {
                let di1= document.getSelection();
                  di1.removeAllRanges();
                  let range = document.createRange();
                  range.selectNodeContents(element)
                  range.collapse(true)
                  di1.addRange(range);
              }

              setRangeStartAfter(element: any)
              {
                 
                let di1= document.getSelection();
                di1.removeAllRanges();
                let range = document.createRange();
                range.setStartAfter(element);
                range.collapse(true)
                di1.addRange(range);
              }


              setRangeStartAfterCollapseFalse(element: any)
              {
                 
                let di1= document.getSelection();
                di1.removeAllRanges();
                let range = document.createRange();
                range.setStartAfter(element);
                range.collapse(false)
                di1.addRange(range);
              }


              clearFormulField()
              {
                let elem = <HTMLElement>this.el.nativeElement;
                elem.innerHTML="";
                  let empty=document.createElement("span");
                  empty.innerHTML="&#8291;"
                  elem.appendChild(empty);
                  this.setRangeNodeContents(empty);
                  this.resizeField();
                  this.setRangeCurrent();
                
              }


              setFieldCorrect()
              {
                let field=<HTMLElement>this.el.nativeElement;
                field.classList.add("correct-field");
                setTimeout(()=>{field.classList.remove("correct-field");},700)
              }

              clearFormul()
              {
                let elem = <HTMLElement>this.el.nativeElement;
                elem.childNodes.forEach(c=>{
                  let cE=<HTMLElement>c;
                  if(cE.classList.contains("current_focused_node"))
                  {
                    cE.classList.remove("current_focused_node");
                    cE.style.backgroundColor="transparent";
                  }
                })
                this.FormulTokensInput=[];
                let children=elem.childNodes;
                let fieldIndex=0;
                while(fieldIndex<children.length)
                {
                  //удаляем парные пустые
                  let childElem=<HTMLElement>children[fieldIndex];
                  //while(childElem.innerText.length>1 && childElem.innerText[0]==String.fromCharCode(8291))
                  //{
                  //  childElem.innerText=childElem.innerText.substring(1);
                 // }

                  if(childElem.innerText==String.fromCharCode(8291))
                  {
                    let nextNotEmpty=false;
                    while(fieldIndex<children.length-1 && !nextNotEmpty)
                    {
                      let nextElem=<HTMLElement>children[fieldIndex+1];
                      if(nextElem.innerText!=String.fromCharCode(8291))
                      {
                        nextNotEmpty=true;
                      }
                      else
                      {
                        elem.removeChild(nextElem)
                      }
                    }
                  }
                  
                  

                  fieldIndex++;
                }






                children=elem.childNodes;
                 fieldIndex=0;
                while(fieldIndex<children.length)
                {
                  //удаляем  пустые где нет соседних переменных
                  let childElem=<HTMLElement>children[fieldIndex];
                  
                  if(childElem.innerText==String.fromCharCode(8291))
                  {
                    let withValueField=false;
                    if(fieldIndex==0)
                    {
                      if(children.length>1)
                      {
                        let nextch=<HTMLElement>children[fieldIndex+1];
                        if(!nextch.classList.contains("value_wrap"))
                        {
                          elem.removeChild(childElem)
                        }
                        else
                        {
                          fieldIndex++;
                        }
                      }
                      else
                      {
                        fieldIndex++;
                      }
                      
                    }
                    else
                    {
                      if(fieldIndex==children.length-1)
                      {
                        if(children.length>1)
                        {
                          let prevch=<HTMLElement>children[fieldIndex-1];
                          if(!prevch.classList.contains("value_wrap"))
                          {
                            elem.removeChild(childElem)
                          }
                          else
                          {
                            fieldIndex++;
                          }
                        }
                        else
                        {
                          fieldIndex++;
                        }
                      }
                      else
                      {
                        if(fieldIndex!=children.length-1 && fieldIndex!=0)
                        {
                          let prevch=<HTMLElement>children[fieldIndex-1];
                          let nextch=<HTMLElement>children[fieldIndex+1];
                          if(!prevch.classList.contains("value_wrap") && !nextch.classList.contains("value_wrap"))
                          {
                            elem.removeChild(childElem)
                          }
                          else
                          {
                            fieldIndex++;
                          }
                        }
                      }
                    }
                  }
                  else
                  {
                    fieldIndex++;
                  }
                  
                }


                children=elem.childNodes;
                 fieldIndex=0;
                while(children.length>1 && fieldIndex<children.length-1)
                {
                  //склеиваем соседние числа
                  let childElem=<HTMLElement>children[fieldIndex];
                  if(!childElem.classList.contains("value_wrap"))
                  {
                    if(children.length>1 && fieldIndex<children.length-1)
                    {
                      let next=<HTMLElement>children[fieldIndex+1];
                      if(!next.classList.contains("value_wrap") && next.textContent!=String.fromCharCode(8291))
                      {
                        let lastNotTxt=this.NOT_TXT.includes(childElem.textContent[childElem.textContent.length-1]);
                        let firstNotTxt=(next.textContent[0]!=String.fromCharCode(8291) && this.NOT_TXT.includes(next.textContent[0]))||(next.textContent[0]==String.fromCharCode(8291) && this.NOT_TXT.includes(next.textContent[1]));
                        if(lastNotTxt && firstNotTxt)
                        {
                          childElem.textContent=childElem.textContent+next.textContent.replace(String.fromCharCode(8291),"");
                          elem.removeChild(next);
                        }
                        else
                        {
                          fieldIndex++;
                        }
                      }
                      else
                      {
                        fieldIndex++;
                      }
                      
                    }
                  }
                  else
                      {
                        fieldIndex++;
                      }
                  
                }
                
              }

              clearFormulSaveFocus()
              {


                
                let elem = <HTMLElement>this.el.nativeElement;
                this.FormulTokensInput=[];
                let children=elem.childNodes;
                let fieldIndex=0;
                while(fieldIndex<children.length)
                {
                  
                  let childElem=<HTMLElement>children[fieldIndex];
                //  while(childElem.innerText.length>1 && childElem.innerText[0]==String.fromCharCode(8291))
                //  {
                   
                //    childElem.innerText=childElem.innerText.substring(1);
                   
                 // }

                  if(childElem.innerText==String.fromCharCode(8291))
                  {
                    let nextNotEmpty=false;
                    while(fieldIndex<children.length-1 && !nextNotEmpty)
                    {
                      let nextElem=<HTMLElement>children[fieldIndex+1];
                      if(nextElem.innerText!=String.fromCharCode(8291))
                      {
                        nextNotEmpty=true;
                      }
                      else
                      {
                        elem.removeChild(nextElem)
                      }
                    }
                  }
                  

                  fieldIndex++;
                }
                
              }

              setFieldFocused()
              {
              
                this.isFocused=true;
              }

              

              
            
              ngOnDestroy() {
               
               
              }

              setFormulTokens()
              {
                this.FormulTokensChange.emit([this.FormulTokensInput, !this.formulIncorrect]);
              }


              createField(field:RecordFieldCreateContainer, parents:FormulFieldsContainer[]=[])
              {
                
                if(field.ValueType>0 &&  field.ValueType<4)
                {
                  let newF= new FormulFieldsContainer();
                  newF.Id=field.Id;
                  newF.Type=2;
                  newF.IsFormul=false;
                  newF.IsList=field.IsList;
                  newF.LinkOnCurrent=false;
                  newF.IsSum=false;
                  newF.Parents=parents;
                  newF.Value=field.Name;
                  this.valuesForPaste.push(newF);
                }
                if(field.ValueType==4 &&  (field.ValueSetType==1 || field.ValueSetType==2))
                {
                  let newF= new FormulFieldsContainer();
                  newF.Id=field.Id;
                  newF.Type=2;
                  newF.IsFormul=false;
                  newF.IsList=field.IsList;
                  newF.LinkOnCurrent=false;
                  newF.IsSum=false;
                  newF.Parents=parents;
                  newF.Value=field.Name;
                  this.valuesForPaste.push(newF);
                }
                if(field.ValueType==7)
                {
                  let newF= new FormulFieldsContainer();
                  newF.Id=field.Id;
                  newF.Type=2;
                  newF.IsFormul=true;
                  newF.IsList=field.IsList;
                  newF.LinkOnCurrent=this.GetFormulLinks(field);
                  newF.IsSum=false;
                  newF.Parents=parents;
                  newF.Value=field.Name;
                  if(!newF.LinkOnCurrent && field.Id!=this.FieldId)
                  {
                    this.valuesForPaste.push(newF);
                  }
                }

                if(field.ValueType==5)
                {
                  let newF= new FormulFieldsContainer();
                  newF.Id=field.Id;
                  newF.Type=2;
                  newF.IsFormul=false;
                  newF.IsList=field.IsList;
                  newF.LinkOnCurrent=false;
                  newF.IsSum=false;
                  newF.Parents=parents;
                  newF.Value=field.Name;
                  let newPar=_.cloneDeep(parents);
                  newPar.push(newF);
                  field.Fields.forEach(c=>this.createField(c, newPar))
                  
                }
              }
              


              GetFormulLinks(field: RecordFieldCreateContainer):boolean
              {
                let res=false;
                field.Formula.forEach(f=>{
                  if(f.Type==1)
                  {
                    if(f.FieldLink[f.FieldLink.length-1].FieldTemplateId==this.FieldId)
                    {
                      res=true;
                    }
                    else
                    {
                      let haveLink=false;

                      haveLink=this.findLinkOnField(f.FieldLink[f.FieldLink.length-1].FieldTemplateId);
                      if(haveLink)
                      {
                        res=true;
                      }

                    }
                  }
                })

                return res;
              }


              findLinkOnField(id:number):boolean
              {
                let res=false;
                let fieldIndex=this.FormulPossibleFields.findIndex(c=>c.Id==id);
                if(fieldIndex>-1)
                {
                  let curField=this.FormulPossibleFields[fieldIndex];
                  if(curField.ValueType!=7)
                  {
                    return false;
                  }
                  else
                  {
                    if(curField.Id==this.FieldId)
                    {
                      return true;
                    }
                    else
                    {
                      curField.Formula.forEach(c=>{
                        
                        if(c.Type==1)
                        {
                          let haveLink=false;
                          if(c.FieldLink[c.FieldLink.length-1].FieldTemplateId==this.FieldId)
                          {
                            haveLink=true;
                            res=true;
                            return true;
                          }
                          else
                          {
                            haveLink=this.findLinkOnField(c.FieldLink[c.FieldLink.length-1].FieldTemplateId);
                            if(haveLink)
                            {
                              haveLink=true;
                              res=true;
                              return true;
                            }
                          }
                        }
                      })
                    }
                  }
                }


                return res;
              }

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                
                this.FormulPossibleFields.forEach(c=>this.createField(c, [])); 
                this.valuesForPasteShow=_.cloneDeep(this.valuesForPaste);

                this.filterSub=this.filterInput.valueChanges.pipe(skip(1)).debounceTime(500)
                .subscribe(newValue => {this.CheckFilterInput();});


                this.checkFormulBeforeSave.subscribe(c=>{
                  this.isFocused=false;
                    this.hoveredFieldId=null;
                    this.showPasteOptions=false;
                    this.clearFormul();
                    this.checkFormul();
                    this.FieldSaved.emit(true);
                });

                this.possibleFieldsChanged.subscribe(c=>{
                 
                  this.valuesForPaste=[];
                      this.valuesForPasteShow=[];
                      this.FormulPossibleFields.forEach(c=>this.createField(c, [])); 
                      this.valuesForPasteShow=_.cloneDeep(this.valuesForPaste);
                      this.checkCurrentFormul();
                })
                 }

                 ngAfterViewInit()
                 {
                  if(this.el!=undefined)
                  {
                     let val="";
                     
                     if(this.FormulTokens!=null && this.FormulTokens.length>0)
                     {
                      this.FormulTokens.forEach(( cur:FormulaTokenOut)=>{
                         if(cur.Type!=1)
                         {
  
                          if(cur.Type==0)
                          {
                            val=val+cur.Value;
                          }
                          if(cur.Type==2)
                          {
                            val=val+"+";
                          }
                          if(cur.Type==3)
                          {
                            val=val+"-";
                          }
                          if(cur.Type==4)
                          {
                            val=val+"*";
                          }
                          if(cur.Type==5)
                          {
                            val=val+"/";
                          }
                          if(cur.Type==6)
                          {
                            val=val+"(";
                          }
                          if(cur.Type==7)
                          {
                            val=val+")";
                          }
                           
                         }
                         else
                         {
  
                          let childText=document.createElement("span");
                        // childText.textContent=val;
                        childText.innerHTML="&#8291;"+val;
                         this.el.nativeElement.appendChild(childText);
                         let fi=this.valuesForPaste.findIndex(c=>c.Id==cur.FieldLink[cur.FieldLink.length-1].FieldTemplateId);
                         let curF=_.cloneDeep(this.valuesForPaste[fi]);
                         curF.IsSum=cur.FieldLink[cur.FieldLink.length-1].IsList;

                         
                         if(curF.Parents!=undefined && curF.Parents!=null && curF.Parents.length>0 )
                         {
                          for(let i=0; i<cur.FieldLink.length && i<curF.Parents.length; i++)
                          {
                            
                            curF.Parents[i].IsSum=cur.FieldLink[i].IsList;
                            if(i==0 && this.isInnerField)
                            {
                              
                              curF.Parents[i].IsSum=false;
                              curF.Parents[i].IsList=false;
                            }
                          }
                         }
                         let child=this.createChildList(curF);
                         
                         this.el.nativeElement.appendChild(child);
                         
                         val="";
                         this.fields.push(curF);
                         }
                       })
                       
                       
                       if(val!="")
                       {
                       let childText=document.createElement("span");
                       //childText.textContent=val;
                       childText.innerHTML="&#8291;"+val;
                       this.el.nativeElement.appendChild(childText);
                       }

                       let last=<HTMLElement>this.el.nativeElement.lastChild;
                       if(last.classList.contains("value_wrap"))
                       {
                        let empty=document.createElement("span");
                          empty.innerHTML="&#8291;"
                          this.el.nativeElement.appendChild(empty);
                       }
                       this.FormulTokensInput=_.cloneDeep(this.FormulTokens);
                       
                       this.checkFormulCorrect(true);
                       setTimeout(()=>{
                        this.resizeField();
                       },500)
                       
                      
                     }
                     else
                     {
                      let childText=document.createElement("span");
                      childText.innerHTML="&#8291;"
                      this.el.nativeElement.appendChild(childText);
                     }
                  }
                  
                     
                 }


                 ngAfterContentInit()
                 {
                  
                 }


              ngOnChanges(changes: SimpleChanges){

               

              }



              clearSelection()
              {
                this.curSel=null;
                this.curFocusNode=null;
                this.curAnchorOffSet=null;
                this.curAnchorNode=null;
                this.curFocusOffSet=null;
                this.curRange=null;
                this.curFocusType=-1;
              }




              DeleteFocusedField()
              {
                let focused=document.getSelection().focusNode;
                while(focused.parentNode.nodeName!="DIV")
                    {
                      focused=focused.parentNode;
                    }
                    let focusedEl=<HTMLElement>focused;
                  
                if(focusedEl.classList.contains("value_wrap"))
                {
                  let elem = <HTMLElement>this.el.nativeElement;
                  let pasteValuePosition=0;
                  let pasteValuePositionCounter=-1;

                let i=0;
                while(elem.childNodes!=undefined &&elem.childNodes!=null && i<elem.childNodes.length)
                {

                  let node=elem.children[i];
                  if (node.classList.contains("value_wrap"))
                  {
                    
                    pasteValuePositionCounter++;
                  }

                  if(elem.childNodes[i].isSameNode(focused))
                  {
                    pasteValuePosition=pasteValuePositionCounter;
                  }
                  i++;
                }

                this.fields.splice(pasteValuePosition,1);
               let prev=<HTMLElement>focused.previousSibling;
              //let prev=focused.previousSibling;
                elem.removeChild(focusedEl);
                if(prev!=undefined && prev!=null)
                {
                  this.setRangeNodeContents(prev.lastChild);
                 // this.setRangeStartAfter(prev);
                  
                }
                }

                
                this.setRangeCurrent();
              }


              DeleteFocusedFieldRange(focused:Node=null, anchor: Node=null, focusOffset: number=null, anchorOffset: number=null)
              {

                if(focusOffset==null)
                {
                  focusOffset=getSelection().focusOffset;
                }
                if(anchorOffset==null)
                {
                  anchorOffset=getSelection().anchorOffset;
                }
                if(focused==null)
                {
                  focused=document.getSelection().focusNode;
                }
                 
                while(focused.parentNode.nodeName!="DIV")
                    {
                      focused=focused.parentNode;
                    }
                    //let focusedEl=<HTMLElement>focused;
                  
                    if(anchor==null)
                    {
                      anchor=document.getSelection().anchorNode;
                    }
                while(anchor.parentNode.nodeName!="DIV")
                    {
                      anchor=anchor.parentNode;
                    }
                    let anchorEl=<HTMLElement>anchor;


                    let elem = <HTMLElement>this.el.nativeElement;
                  
                let i=0;
                let start=-1;
                let end=-1;
                let fields_start=-1;
                let fields_end=-1;
                let fields_counter=-1;
                elem.childNodes.forEach(ch=>{
                  let child=<HTMLElement>ch;
                  if(child.classList.contains("value_wrap") && end==-1)
                  {
                    fields_counter++;
                  }
                  if(ch.isSameNode(focused) || ch.isSameNode(anchor) )
                  {
                    if (start==-1)
                    {
                      start=i;
                      end=i
                      //
                    }
                    else
                    {
                      end=i;
                      //fields_end=fields_counter;
                    }
                  }


                  if(child.classList.contains("value_wrap"))
                  {
                    if(start>-1 && fields_start==-1 && (end==-1 || end==i))
                    {
                      fields_start=fields_counter;
                     // fields_end=fields_counter;
                    }
                    if(start>-1 && fields_start!=-1 && (end==-1 || end<=i))
                    {
                      fields_end=fields_counter;
                    }
                  }
                  
                  i++
                })

               

                
                if(end==-1)
                {
                  end=elem.childNodes.length-1;
                }
                if(fields_end==-1)
                {
                  fields_end=fields_counter;
                }

                if(start!=-1)
                {
                  let prev=null;
                  if(start!=0)
                  {
                    prev=elem.childNodes[start-1]; 
                  }



                  if(fields_start!=-1 && fields_end!=-1)
                  {
                    for(let i=fields_end; i>=fields_start; i--)
                    {
                      this.fields.splice(i,1);
                    }
                  }

                  let needPrev=true;

                  let prevind=0;
                 

                  for(let i=end; i>=start; i--)
                  {
                    
                    if(i==start || i==end)
                    {
                      let elementCur=elem.children[i];
                      if(elementCur.classList.contains("value_wrap"))
                      {
                        if(i==start)
                        {
                          prev=elem.previousSibling;
                          if(prev==undefined || prev==null)
                          {
                            let aft=document.createElement("span");
                                aft.innerHTML="&#8291;"
                                elementCur.insertAdjacentElement("beforebegin",aft);
                                prev=aft;
                          }
                          //else
                        }
                        
                        elem.removeChild(elementCur);
                      }
                      else
                      {
                        let ofs=-1;
                        if(i==start && start!=end)
                        {
                         // needPrev=false;
                        
                        
                          let ele=elem.childNodes[i];
                          prev=ele;
                          prevind=i;
                          if(ele.isSameNode(focused))
                          {
                            ofs=focusOffset;
                          }
                          if(ele.isSameNode(anchor))
                          {
                            ofs=anchorOffset;
                          }
                          if(ofs>-1)
                          {
                            ele.textContent=ele.textContent.substring(0,ofs)
                          }
                        }
                        else{
                          if(i==end && start!=end)
                          {
                            
                            let ele=elem.childNodes[i];
                            if(ele.isSameNode(focused))
                            {
                              ofs=focusOffset;
                            }
                            if(ele.isSameNode(anchor))
                            {
                              ofs=anchorOffset;
                            }
                            if(ofs>-1)
                            {
                              ele.textContent=ele.textContent.substring(ofs)
                            }
                          }
                          else
                          {
                            if(i==start && i==end)
                            {
                             
                              let ele=elem.childNodes[i];
                              
                              prev=ele;
                              
                              prevind=i;
                              
                              if(focusOffset>anchorOffset)
                              {
                                
                              let bef=document.createElement("span");
                              bef.textContent=ele.textContent.substring(0,anchorOffset);
                             // prev=bef;
                              elem.replaceChild(bef,ele)
                              //if(ele.textContent.substring(focusOffset)!=null && ele.textContent.substring(focusOffset)!='')
                              //{
                                let aft=document.createElement("span");
                                aft.innerHTML="&#8291;"+ele.textContent.substring(focusOffset);
                                bef.insertAdjacentElement("afterend", aft);
                             // }
                              
                              prev=bef;
                               
                              }
                              else
                              {
                                let bef=document.createElement("span");
                              bef.textContent=ele.textContent.substring(0,focusOffset);
                              //prev=bef;
                              elem.replaceChild(bef,ele)
                             // if(ele.textContent.substring(anchorOffset)!=null && ele.textContent.substring(anchorOffset)!='')
                            //  {
                                let aft=document.createElement("span");
                               // aft.innerHTML="&#8291;"+ele.textContent.substring(anchorOffset);
                               aft.textContent=ele.textContent.substring(anchorOffset);
                                bef.insertAdjacentElement("afterend", aft);
                                prev=bef;
                             // }
                              
                              }
                            }
                          }

                        }
                      }
                    }
                    else
                    {
                      let elementCur=elem.children[i];
                      elem.removeChild(elementCur);
                    }
                  }


                  if(needPrev)
                  {
                    if(prev!=undefined && prev!=null)
                    {
                      



                      let di1= document.getSelection();
                      di1.removeAllRanges();
                      let range = document.createRange();
                      let prevel=<HTMLElement>prev.nextSibling;
                      if(prev.firstChild==undefined || prev.firstChild==null || prev.firstChild.length==0)
                      {
                        prev.innerHTML="&#8291;";
                      }
                      
                       range.selectNodeContents(prev.firstChild)
                     
                      range.collapse(false)
                      di1.addRange(range);
                      

                    
                    }
                  }

                }

                this.setRangeCurrent();

              }



              checkempty(ev:KeyboardEvent)
              {
                
                let elem = <HTMLElement>this.el.nativeElement;
                if(elem.childNodes.length==0 ||(elem.childNodes.length==1 && elem.children[0].nodeName=="BR"))
                {

                  if(elem.childNodes.length==1 && elem.children[0].nodeName=="BR")
                  {
                    elem.childNodes[0].remove();
                  }
                  let empty=document.createElement("span");
                  empty.innerHTML="&#8291;"
                  elem.appendChild(empty);
                  this.setRangeNodeContents(empty);
                  this.setRangeCurrent();
                }



                this.setRangeCurrent();
               this.clearFormulSaveFocus()
                
               this.resizeField();

                this.searchInValuesForm();
              }



              parseData(input:string):PastedValues
              {
                let data=[];
                let fields=[];
                let result = new PastedValues();

                let elems = new DOMParser().parseFromString(input, "text/html");
                
                let content=elems.querySelector("body").childNodes;
                content.forEach(c=>{
                  let child=<HTMLElement>c;
                 
                  if(child.innerHTML!=undefined && child.innerHTML!=null && child.innerHTML!='')
                  {
                    
                    let txtInContent=false;
                    let regs=new RegExp(/[a-zA-Zа-яА-Я]/,"gi");
                    if(regs.test(ExtendedHelper.ReplaceHtmlTags(child.textContent)))
                    {
                      txtInContent=true;
                    }
                    
                    if(!child.classList.contains("value_wrap") && ! txtInContent)
                    {
                      let elem = document.createElement("SPAN");
                      elem.textContent=ExtendedHelper.ReplaceHtmlTags(child.textContent);
                      data.push(elem); 
                    }
                    else
                    {
                      
                      
                      let val=null;
                      let parents=[];
                      let valTXT=ExtendedHelper.ReplaceHtmlTags(child.textContent);

                      if(child.children.length>0 &&child.children[0].textContent!=String.fromCharCode(8721))
                      {
                        this.fieldChain(child, parents)
                        val=parents[parents.length-1];
                        parents.splice(parents.length-1,1)
                      }
                      else
                      {
                        if(child.children.length>0 &&child.children[0].textContent==String.fromCharCode(8721))
                        {child.removeChild(child.children[0]);}
                        val=ExtendedHelper.ReplaceHtmlTags(child.innerText);
                      }

                      
                      let ind=-1;
                      let valInd=0;
                      this.valuesForPaste.forEach(v=>{
                        if(v.Value.toLowerCase()==val.toLowerCase())
                        {
                          let parentsIdentity=true;
                          if(parents.length==v.Parents.length)
                          {
                            for(let i=0; i<parents.length; i++)
                            {
                              if(parents[i].toLowerCase()!=v.Parents[i].Value.toLowerCase())
                              {
                                parentsIdentity=false;
                              }
                            }
                          }
                          else
                          {
                            parentsIdentity=false;
                          }
                          if(parentsIdentity)
                          {
                            ind=valInd;
                          }
                        }
                        valInd++;
                      });

                      if(ind!=-1)
                      {
                        let ch=this.createChildList(this.valuesForPaste[ind]);
                        data.push(ch);
                        fields.push(this.valuesForPaste[ind]);
                      }
                      else
                      { 
                        let elem = document.createElement("SPAN");
                      elem.textContent=valTXT;
                      data.push(elem); 

                      }
                      
                    }
                  }
                    
                    
                  
                })

                result.tags=data;
                result.fields=fields;

                return result;
              }

              fieldChain(currentElem: HTMLElement, chain:any[])
              {
                if(currentElem.children.length>0 &&currentElem.children[0].textContent!=String.fromCharCode(8721))
                {
                  this.fieldChain(<HTMLElement>currentElem.children[0], chain)
                  currentElem.removeChild(currentElem.children[0]);
                  if(currentElem.innerText[currentElem.innerText.length-1]==".")
                  {
                    currentElem.innerText=currentElem.innerText.substring(0,currentElem.innerText.length-1)
                  }
                  chain.unshift(ExtendedHelper.ReplaceHtmlTags(currentElem.innerText));
                }
                else
                {
                  if(currentElem.children.length>0 &&currentElem.children[0].textContent==String.fromCharCode(8721))
                  {currentElem.removeChild(currentElem.children[0]);}
                  
                  chain.unshift(ExtendedHelper.ReplaceHtmlTags(currentElem.innerText));
                }

                
              }

              pastText(ev:ClipboardEvent)
              {
                ev.preventDefault();
                
                let textNodes=this.parseData(ev.clipboardData.getData('text/html'));
               // let txt=ExtendedHelper.ReplaceHtmlTags(ev.clipboardData.getData('text/plain'));
                if(textNodes!=undefined && textNodes!=null && textNodes.tags!=undefined && textNodes.tags!=null && textNodes.tags.length>0)
                {
                  let childText=textNodes.tags[textNodes.tags.length-1];
                 // childText.textContent=txt;
                  let focused=document.getSelection().focusNode;
                  while(focused.parentNode.nodeName!="DIV")
                  {
                     focused=focused.parentNode;
                   }
                  let focusedEl=<HTMLElement>focused;
                  let elem = <HTMLElement>this.el.nativeElement;
  
                  if(document.getSelection().focusNode.isSameNode(document.getSelection().anchorNode))
                  {


      
                    let pasteValuePosition=0;
                    let i=0;
                    while(elem.childNodes!=undefined &&elem.childNodes!=null && i<elem.childNodes.length && !elem.childNodes[i].isSameNode(focused))
                    {
                      let node=elem.children[i];
                      if (node.classList.contains("value_wrap"))
                      {
                        pasteValuePosition++;
                      }
                      i++;
                    }
                    



                    let start_ofs=-1;
                    let end_ofs=-1;
                    if(document.getSelection().focusOffset>document.getSelection().anchorOffset)
                    {
                      start_ofs=document.getSelection().anchorOffset;
                      end_ofs=document.getSelection().focusOffset;
                    }
                    else
                    {
                      end_ofs=document.getSelection().anchorOffset;
                      start_ofs=document.getSelection().focusOffset;
                    }
                    if(!focusedEl.classList.contains("value_wrap"))
                    {
                      let isprev=false;
                      let isnext=false;
                      let prev = document.createElement("span");
                      let next = document.createElement("span");
                      if(document.getSelection().focusOffset>0)
                      {
                        
                        prev.textContent=focusedEl.textContent.substring(0, start_ofs);
                        isprev=true;
                        
                      }
                      if(document.getSelection().focusOffset<focusedEl.textContent.length)
                      {
                        
                        next.textContent=focusedEl.textContent.substring( end_ofs);
                        isnext=true;
                        
                      }
                      
                      //let els=<Node>childText
                      elem.replaceChild(childText, focusedEl);
                      if(isprev)
                      {
                        childText.insertAdjacentElement("beforebegin",prev);
                      }
                      if(isnext)
                      {
                        childText.insertAdjacentElement("afterend",next);
                      }

                      let emptyIsLast=false;
                      let empty=document.createElement("span");
                      if(childText.classList.contains("value_wrap"))
                      {
                        //let empty=document.createElement("span");
                                empty.innerHTML="&#8291;"
                                childText.insertAdjacentElement("afterend", empty);
                                emptyIsLast=true;
                      }

                      if(textNodes.tags.length>1)
                      for(let i=0; i<textNodes.tags.length-1; i++)
                      {
                        childText.insertAdjacentElement("beforebegin",textNodes.tags[i]);
                      }
                      if(!emptyIsLast)
                      {
                        this.setRangeNodeContents(childText.lastChild);
                      }
                      else
                      {
                        this.setRangeNodeContents(empty.lastChild);
                      }

                      if(textNodes.fields!=undefined && textNodes.fields!=null && textNodes.fields.length>0)
                      {

                        
                        if(pasteValuePosition>this.fields.length-1)
                        {
                          this.fields=this.fields.concat(_.cloneDeep(textNodes.fields))
                          
                        }
                        else
                        {
                          this.fields.splice(pasteValuePosition,0,..._.cloneDeep(textNodes.fields))
                          
                        }

                      }
                      
                      this.setRangeCurrent();
                    }
                  }
                  else
                  {
                    //////разные узлы, удалить лишнее
                    this.DeleteFocusedFieldRange();

                    
                    let di1= document.getSelection();
                    let currentFocus=di1.focusNode;
                    while(currentFocus.parentNode.nodeName!="DIV")
                    {
                      currentFocus=currentFocus.parentNode;
                     }

                     let pasteValuePosition=0;
                    let i=0;
                    while(elem.childNodes!=undefined &&elem.childNodes!=null && i<elem.childNodes.length && !elem.childNodes[i].isSameNode(currentFocus))
                    {
                      let node=elem.children[i];
                      if (node.classList.contains("value_wrap"))
                      {
                        pasteValuePosition++;
                      }
                      i++;
                    }
                    
                    
                    let currentFocusEl=<HTMLElement>currentFocus;
                    currentFocusEl.insertAdjacentElement("afterend", childText);


                    let emptyIsLast=false;
                    let empty=document.createElement("span");
                    if(childText.classList.contains("value_wrap"))
                    {
                      //let empty=document.createElement("span");
                              empty.innerHTML="&#8291;"
                              childText.insertAdjacentElement("afterend", empty);
                              emptyIsLast=true;
                    }
                    if(textNodes.tags.length>1)
                    for(let i=0; i<textNodes.tags.length-1; i++)
                    {
                      childText.insertAdjacentElement("beforebegin",textNodes.tags[i]);
                    }
                    if(!emptyIsLast)
                    {
                      this.setRangeNodeContents(childText.lastChild);
                    }
                    else
                    {
                      this.setRangeNodeContents(empty.lastChild);
                    }
                      
                      if(textNodes.fields!=undefined && textNodes.fields!=null && textNodes.fields.length>0)
                      {

                        
                        if(pasteValuePosition>this.fields.length-1)
                        {
                          this.fields=this.fields.concat(_.cloneDeep(textNodes.fields))
                          
                        }
                        else
                        {
                          this.fields.splice(pasteValuePosition,0,..._.cloneDeep(textNodes.fields))
                          
                        }

                      }
                      this.setRangeCurrent();
                  }
                  
                }
                ev.stopPropagation();

              }

             

              checkButton(ev:KeyboardEvent)
              {


                let functionalkey=this.FUNCTIONAL_KEY_CODES.includes(ev.keyCode);
                if(functionalkey)
                {
                  ev.preventDefault();
                }
                else
                {
                  this.formulIncorrect=false;
                  let elem = <HTMLElement>this.el.nativeElement;
                  if(elem.childNodes.length==0)
                  {
                    let empty=document.createElement("span");
                    empty.innerHTML="&#8291;"
                    elem.appendChild(empty);
                    this.setRangeNodeContents(empty);
                  }

                  let code=ev.keyCode;
                  if (ev.keyCode == 70 && ev.shiftKey)
                  {
                    code=10070;
                  }

                  switch (code)
                      {
                        
                        case 27:
                            {
                              
                              ev.preventDefault();
                              ev.stopPropagation();
                              if(this.showPasteOptions)
                              {
                                this.showPasteOptions=false;
                                this.hoveredFieldId=null;
                              }
                              else
                              {
                                this.clearFormulField();
                              }
                              break;
                            }

                        case 46:
                            {
                              
                              if(elem.childNodes.length==1 && elem.children[0].nodeName=="SPAN" && elem.children[0].textContent==String.fromCharCode(8291))
                              {
                                
                                ev.preventDefault();
                                return;
                              }

                              let focused=document.getSelection().focusNode;
                              let ofs=document.getSelection().focusOffset;
                              while(focused.parentNode.nodeName!="DIV")
                              {
                                focused=focused.parentNode;
                              }
                              let focusedEl=<HTMLElement>focused;
                              if(document.getSelection().focusNode.isSameNode(document.getSelection().anchorNode))
                                {
                                  if(focusedEl.classList.contains("value_wrap"))
                                  {
                                  
                                    ev.preventDefault();
                                    this.DeleteFocusedField();
                                  }
                                  else
                                  {
                                    
                                    if(document.getSelection().focusOffset==document.getSelection().focusNode.textContent.length)
                                    {
                                      
                                      let next=focused.nextSibling;
                                      if(next!=undefined && next!=null)
                                      {

                                        let nextEl=<HTMLElement>next;
                                        if(nextEl.classList.contains("value_wrap"))
                                        {
                                          ev.preventDefault();
                                          
                                          this.setRangeNodeContents(next);
                                          this.DeleteFocusedField();
                                        }
                                        
                                      }
                                    }
                                  }
                                }
                                else
                                {
                                    ev.preventDefault();
                                    this.DeleteFocusedFieldRange();
                                }
                                break;
                            }

                        case 8:
                          {
                            if(elem.childNodes.length==1 && elem.children[0].nodeName=="SPAN" && elem.children[0].textContent==String.fromCharCode(8291))
                            {
                              
                              ev.preventDefault();
                              return;
                            }
          
                            let focused=document.getSelection().focusNode;
                            let ofs=document.getSelection().focusOffset;

                            
                            let needBack=false;
                            if(focused.nodeName=="#text" && ofs==1 && focused.textContent.charCodeAt(0)==8291)
                            {
                              needBack=true;
                            }
          
                            while(focused.parentNode.nodeName!="DIV")
                            {
                              focused=focused.parentNode;
                            }
                            let focusedEl=<HTMLElement>focused;


                            let selectionInFOcus=true;
                            selectionInFOcus =!(document.getSelection().focusNode.isSameNode(document.getSelection().anchorNode) && document.getSelection().focusOffset==document.getSelection().anchorOffset);
          
                            if(document.getSelection().focusNode.isSameNode(document.getSelection().anchorNode))
                            {
                              if(document.getSelection().focusOffset!=0 && focusedEl.classList.contains("value_wrap"))
                              {
                              
                                ev.preventDefault();
                                this.DeleteFocusedField();

                                
                              }
                            }
                            else
                            {
                                ev.preventDefault();
                                this.DeleteFocusedFieldRange();
                            }
                            
          
          
          
          
                            if(!selectionInFOcus && (focusedEl.textContent==String.fromCharCode(8291) || needBack))
                                {
                                  
                                  
                                  let prev=focused.previousSibling;
                                  
                                  if(prev!=undefined && prev!=null)
          
                                  {
                                  
                                    ev.preventDefault();
                                    while(prev!=undefined && prev!=null && prev.textContent==String.fromCharCode(8291))
                                    {
                                      prev=prev.previousSibling;
                                      
                                    }
            
                                    if(prev!=undefined && prev!=null)
                                    {
                                      
                                      
                                      this.setRangeNodeContents(prev);
                                      this.checkButton(ev);
            
                                    }
                                  }
                                
          
          
                                  return;
                                }
          
                            break;
                          }

                        case 13:
                          {
                            
                            ev.preventDefault();
                            if(this.showPasteOptions && this.hoveredFieldId!=null)
                            {
                              let index=this.valuesForPasteShow.findIndex(c=>c.Id==this.hoveredFieldId)
                              {
                                if(index>-1)
                                {
                                  this.addFieldToFormul(this.valuesForPasteShow[index]);
                                }
                              }
                            }
                            break;
                          }

                        case 10070:
                          {
                            
                            ev.preventDefault();
                            if(!this.showPasteOptions)
                            {
                              
                              this.openValuesForm();
                            }
                            else
                            {
                              
                            
                            }  
                            break;
                          }

                        case 37:
                          {
                            let focused=document.getSelection().focusNode;
                            let ofs=document.getSelection().focusOffset;
                            
                            let needBack=false;
                            if(focused.nodeName=="#text" && ofs==1 && focused.textContent.charCodeAt(0)==8291)
                            {
                              needBack=true;
                            }
          
                            while(focused.parentNode.nodeName!="DIV")
                            {
                               focused=focused.parentNode;
                             }
                             let focusedEl=<HTMLElement>focused;
          
                            if(focusedEl.textContent==String.fromCharCode(8291) || needBack)
                                {
                                 
                                  let prev=focused.previousSibling;
                                  
                                  if(prev!=undefined && prev!=null)
          
                                  {
                                   
                                    ev.preventDefault();
                                    while(prev!=undefined && prev!=null && prev.textContent==String.fromCharCode(8291))
                                    {
                                      prev=prev.previousSibling;
                                      
                                    }
            
                                    if(prev!=undefined && prev!=null)
                                    {
                                    
                                      
                                      this.setRangeNodeContents(prev);
                                      this.checkButton(ev);
            
                                    }
                                  }
                                 
          
          
                                  return;
                                }
          
                          
                          
                          this.showPasteOptions=false;
                          this.hoveredFieldId=null;
                          break;
                          }

                        case 39:
                          {
                            
                            this.showPasteOptions=false;
                            this.hoveredFieldId=null;
                            break;
                          }

                        case 38:
                          {
                            ev.preventDefault()
                            if( this.showPasteOptions)
                            {
                              
                                if(this.hoveredFieldId==null && this.valuesForPasteShow.length>0)
                                {
                                  this.hoveredFieldId=this.valuesForPasteShow[0].Id;
                                }
                                else
                                {
                                  if(this.hoveredFieldId!=null && this.valuesForPasteShow.length>0)
                                  {
                                    let ind=this.valuesForPasteShow.findIndex(c=>c.Id==this.hoveredFieldId);
                                    if(ind!=-1&&ind>0)
                                    {
                                      this.hoveredFieldId=this.valuesForPasteShow[ind-1].Id;
                                    }
                                  }
                                }
                              }
                              break; 
                          }

                        case 40:
                          {
                            
                              ev.preventDefault()
                              if( this.showPasteOptions)
                              {
                               
                                  if(this.hoveredFieldId==null && this.valuesForPasteShow.length>0)
                                  {
                                    this.hoveredFieldId=this.valuesForPasteShow[0].Id;
                                  }
                                  else
                                  {
                                    if(this.hoveredFieldId!=null && this.valuesForPasteShow.length>0)
                                    {
                                      let ind=this.valuesForPasteShow.findIndex(c=>c.Id==this.hoveredFieldId);
                                      if(ind!=-1&&ind<this.valuesForPasteShow.length-1)
                                      {
                                        this.hoveredFieldId=this.valuesForPasteShow[ind+1].Id;
                                      }
                                    }
                                  
                                  }
                              }
                              break;
                          }

                        default:
                          {

                            if(!ev.shiftKey&& !ev.altKey && ev.keyCode!=20  && ev.keyCode!=18)
                            {
                              let focused=document.getSelection().focusNode;
                              while(focused.parentNode.nodeName!="DIV")
                              {
                                focused=focused.parentNode;
                              }
                              let focusedEl=<HTMLElement>focused;
      
      
                              if(!ev.ctrlKey && !document.getSelection().focusNode.isSameNode(document.getSelection().anchorNode))
                                {
                                    this.DeleteFocusedFieldRange();
                                    focused=document.getSelection().focusNode;
                                    while(focused.parentNode.nodeName!="DIV")
                                    {
                                      focused=focused.parentNode;
                                    }
                                    focusedEl=<HTMLElement>focused;
                                }
      
                              if(!this.NOT_TXT.includes(ev.key) && !focusedEl.classList.contains("value_wrap"))
                              {
                                this.setRangeCurrent();
                                if(!this.showPasteOptions && !ev.ctrlKey && !ev.altKey && ev.keyCode!=20 && ev.keyCode!=18)
                                {
                                  
                                  this.openValuesForm();
                                  
                                }
        
                              }
                              else
                              {
                                if(this.OPERATORS.includes(ev.key)&& !focusedEl.classList.contains("value_wrap"))
                                {
                                  
                                  this.showPasteOptions=false;
                                  this.hoveredFieldId=null;
                                  
                                }
                                else
                                {
                                  if(!ev.ctrlKey && focusedEl.classList.contains("value_wrap"))
                                  {
                                    
                                    ev.preventDefault();
                                    //если край элеента
                                    //если следующий не пустой то добавить пустой
                                    //перевести фокус на след элемент и снова запустить checkbutton
                                    if(document.getSelection().focusNode.textContent.length==document.getSelection().focusOffset)
                                    {
                                      
                                      
                                      let next=focused.nextSibling;
                                      
                                      let empty=document.createElement("span");
                                      empty.innerHTML="&#8291;"+ev.key;
                                      if(next!=undefined && next!=null)
                                      {
                                        
                                        if(next.textContent!=String.fromCharCode(8291))
                                        {
                                          
                                          focusedEl.insertAdjacentElement("afterend", empty);
                                          
                                          this.setRangeNodeContents(empty);
                                          //this.checkButton(ev);
                                          //return;
                                        }
                                        else
                                        {
                                          
                                          let nextEl=<HTMLElement>next;
                                          //focusedEl.insertAdjacentElement("afterend", empty);
                                          
                                          this.setRangeNodeContents(nextEl);
                                          //this.checkButton(ev);
                                          //return;
                                        }
                                      }
                                      else
                                      {
                                        
                                        focusedEl.insertAdjacentElement("afterend", empty);
                                          
                                          this.setRangeNodeContents(empty);
                                          this.checkButton(ev);
                                          return;
                                      }
                                    
        
                                    }
                                  }
                                }
                              }
                            
                            }
                            else
                            {
                              let focused=document.getSelection().focusNode;
                              while(focused.parentNode.nodeName!="DIV")
                              {
                                focused=focused.parentNode;
                              }
                              let focusedEl=<HTMLElement>focused;
                              if(ev.shiftKey && this.OPERATORS.includes(ev.key)&& !focusedEl.classList.contains("value_wrap"))
                              {
                                
                                this.showPasteOptions=false;
                                this.hoveredFieldId=null;
                                
                              }
                            }

                            break;
                          }


                      }


                     
                }

            
               
              }

              changeElemSum(ev:MouseEvent, elem:any)
              {
                
                ev.stopPropagation();
                elem.IsSum=!elem.IsSum;
                let tar= <HTMLElement>ev.target;
                if(elem.IsSum)
                {
                  tar.style.color="#23844c";
                  tar.style.fontWeight="600";
                }
                else
                {
                  tar.style.color="#2c20af";
                  tar.style.fontWeight="400";
                }
                
              }


              createChildList(field:FormulFieldsContainer ):HTMLElement
              {
                
                let ch=document.createElement("span");
                
                
                if(field.Parents==null)
                  {
                    ch.classList.add("value_wrap");
                    ch.style.color="#2c20af";
                    
                    ch.textContent=field.Value;
                    if(field.IsList)
                    {
                      let sum = document.createElement("span");
                      sum.innerHTML="&#8721;";
                      if(field.IsSum)
                      {
                        sum.style.color="#23844c";
                        sum.style.fontWeight="600";
                      }
                      sum.style.cursor="pointer";
                      sum.title="Сумма элементов списка";
                      sum.addEventListener("click", (event)=>this.changeElemSum(event, field));
                      sum.addEventListener("mouseover", (event)=>this.sumHover(event));
                      sum.addEventListener("mouseout", (event)=>this.sumUnhover(event));
                      ch.appendChild(sum);
                    }
                    return ch;
                  }
                  else
                  {
                    ch.textContent=field.Value;
                    if(field.IsList)
                    {
                      let sum = document.createElement("span");
                      sum.innerHTML="&#8721;";
                      if(field.IsSum)
                      {
                        sum.style.color="#23844c";
                        sum.style.fontWeight="600";
                      }
                      sum.style.cursor="pointer";
                      sum.title="Сумма элементов списка";
                      sum.addEventListener("click", (event)=>this.changeElemSum(event, field));
                      sum.addEventListener("mouseover", (event)=>this.sumHover(event));
                      sum.addEventListener("mouseout", (event)=>this.sumUnhover(event));
                      ch.appendChild(sum);
                    }
                    for(let i=field.Parents.length-1; i>=0; i--)
                    {
                      let chOut=document.createElement("span");
                      chOut.textContent=field.Parents[i].Value+".";
                      chOut.appendChild(ch);
                      if(field.Parents[i].IsList)
                      {
                        let sum = document.createElement("span");
                        sum.innerHTML="&#8721;";
                        if(field.Parents[i].IsSum)
                          {
                            sum.style.color="#23844c";
                            sum.style.fontWeight="600";
                          }
                        sum.style.cursor="pointer";
                        sum.title="Сумма элементов списка";
                        sum.addEventListener("click", (event)=>this.changeElemSum(event, field.Parents[i]));
                        sum.addEventListener("mouseover", (event)=>this.sumHover(event));
                      sum.addEventListener("mouseout", (event)=>this.sumUnhover(event));
                        
                        chOut.appendChild(sum);
                      }
                      ch=chOut;
                    }

                    ch.classList.add("value_wrap");
                    ch.style.color="#2c20af";
                    return ch;
                  }
              }


              addFieldToFormul(fieldShow: FormulFieldsContainer)
              {
               let fieldIndex=this.valuesForPaste.findIndex(c=>c.Id==fieldShow.Id);
               if(fieldIndex>-1)
               {
                let field=_.cloneDeep(this.valuesForPaste[fieldIndex]);
                this.showPasteOptions=false;
                this.hoveredFieldId=null;


                if(this.curFocusNode.isSameNode(this.curAnchorNode) && this.curFocusOffSet ==this.curAnchorOffSet  && this.curFocusType==0)
                {
                  let focusedF=this.curFocusNode;
                  let ofsF=this.curFocusOffSet;
                  let filterText="";
                  
                  if(ofsF>0)
                  {
                    let nottxt=false;
                    let ind=ofsF-1;
                    while(ind>0 && !nottxt)
                    {
                      
                      if(!this.NOT_TXT.includes(focusedF.textContent[ind]) && focusedF.textContent[ind]!=String.fromCharCode(8291))
                      {
                        filterText=focusedF.textContent[ind]+filterText;
                      }
                      else
                      {
                        nottxt=true;
                      }
                      ind--;
                    }
                    
                    
                    if(filterText!="")
                    {

                      
                      let focusedFEL=<HTMLElement>focusedF;
                      
                      let txtCont=focusedFEL.textContent.substring(0,ofsF-filterText.length)+focusedFEL.textContent.substring(ofsF);
                      focusedFEL.textContent=txtCont;

                      let di1= document.getSelection();
                               
      
                                di1.removeAllRanges();
                                
                                let range = document.createRange();
                                
                                range.setStart(focusedFEL,ofsF-filterText.length);
                               
                                range.collapse(false)
                                  
                                di1.addRange(range);
                                this.setRangeCurrent();
                    }
                }
              }

                


               
                if(!this.curFocusNode.isSameNode(this.curAnchorNode) ||
                ((this.curFocusNode.isSameNode(this.curAnchorNode) && this.curFocusOffSet !=this.curAnchorOffSet )))
                {
                 

                    this.DeleteFocusedFieldRange(this.curFocusNode, this.curAnchorNode, this.curFocusOffSet,this.curAnchorOffSet);

                    
                    
                }

                if(this.curFocusType==0)
                {
                  
                  //Добавляем в текст формулы
                  let last=false;
                  let first=false;
                  let di= document.getSelection();
                  di.removeAllRanges();
                  di.addRange(this.curRange);
                 
                  let focusedNode=this.curFocusNode;
                 
                  while(focusedNode.parentNode.nodeName!="DIV")
                  {
                    focusedNode=focusedNode.parentNode;
                  }
                  
                 
  
                let elem = <HTMLElement>this.el.nativeElement;
                let offset=this.curFocusOffSet;
                let nodeLength=focusedNode.textContent.length;
  
                let pasteValuePosition=0;
                

                let i=0;
                while(elem.childNodes!=undefined &&elem.childNodes!=null && i<elem.childNodes.length && !elem.childNodes[i].isSameNode(focusedNode))
                {

                  
                  let node=elem.children[i];
                  if (node.classList.contains("value_wrap"))
                  {
                    
                    pasteValuePosition++;
                  }
                  i++;
                }
                
  
                if(elem.lastElementChild.isSameNode(focusedNode) || elem.isSameNode(focusedNode))
                  {
                    last=true;
                  }

                  if(elem.firstElementChild.isSameNode(focusedNode) )
                  {
                    first=true;
                  }
                
                            let bef="";
                            let aft="";
                            if(offset>0)
                            {bef=focusedNode.textContent.substring(0,offset);}
                            if(offset<nodeLength)
                            {aft=focusedNode.textContent.substring(offset);}

                            let ch=this.createChildList(field);
                              
                              elem.replaceChild(ch, focusedNode);
    
                              let childTextB=document.createElement("span");
                              childTextB.textContent=bef;
                              let childTextA=document.createElement("span");
                              childTextA.textContent=aft;
                              let empty=document.createElement("span");
                              empty.innerHTML="&#8291;"
    
                              if(offset>0)
                              {
                                
                                ch.insertAdjacentElement('beforebegin',childTextB);
                              }
                              else
                              {
                                if(offset==0 && first)
                                {
                                  let emptyB=document.createElement("span");
                              emptyB.innerHTML="&#8291;"
                                  ch.insertAdjacentElement('beforebegin',emptyB);
                                }
                              }
                             
                              /*ch.insertAdjacentElement('afterend',empty);
  
                              if(offset<nodeLength)
                              {
                                empty.insertAdjacentElement('afterend',childTextA);
                               
                              }*/

                              if(offset<nodeLength)
                              {
                                //после переменной остается текст
                                if(childTextA.textContent!=String.fromCharCode(8291))
                                  {
                                    ch.insertAdjacentElement('afterend',empty);
                                    empty.insertAdjacentElement('afterend',childTextA);
                                  }
                                  else
                                  {
                                    ch.insertAdjacentElement('afterend',childTextA);
                                  }
                                
                               
                              }

                              else
                              {
                                //в узле нет текста после переменной

                                //переменная-крайний элемент - вставляем empty
                                if(last)
                                {
                                  ch.insertAdjacentElement('afterend',empty);
                                }
                                else
                                {
                                  let fnex=<HTMLElement>ch.nextSibling;
                                  if(fnex.textContent!=String.fromCharCode(8291))
                                  {
                                    ch.insertAdjacentElement('afterend',empty);
                                  }
                                }

                                //переменная не крайний элемент и следующий элемент тоже переменная  - вставляем empty

                              }
                              
                              
                              if(offset<nodeLength)
                              {
                                
                                
                                //setTimeout(() => {
                                let fnext=<HTMLElement>ch.nextSibling;
                                this.setRangeStartAfter(fnext);
                              
                              //});
                              }
  
                              else
                              {
                                if(last)
                                {

                                  
                                  let fnext=<HTMLElement>ch.nextSibling;
                                  this.setRangeStartAfter(fnext);

                                  
                                  
                                }
                                else
                                {
                                  
                                  let fnext=<HTMLElement>ch.nextSibling;
                                  this.setRangeStartAfter(fnext);

                                  
                                }
                              }
                   
                              

                              
                              if(pasteValuePosition>this.fields.length)
                              {
                                this.fields.push(field)
                              }
                              else
                              {
                                this.fields.splice(pasteValuePosition,0,field)
                              }

                              
                }
                
                else
                {
                  if(this.curFocusType==1)
                  {
  
                    //Добавляем в текст формулы
                    let last=false;
                    let di= document.getSelection();
                    di.removeAllRanges();
                    di.addRange(this.curRange);
                    
              
                    let selection = document.getSelection()
                    let focusedNode=selection.focusNode;
    
                    
                    while(focusedNode.parentNode.nodeName!="DIV")
                    {
                      focusedNode=focusedNode.parentNode;
                    }
                    
    
                  let elem = <HTMLElement>this.el.nativeElement;
                 
                  let pasteValuePosition=0;
                  let pasteValuePositionCounter=-1;
  
                  let i=0;
                  while(elem.childNodes!=undefined &&elem.childNodes!=null && i<elem.childNodes.length)
                  {
  
                    
                    let node=elem.children[i];
                    if (node.classList.contains("value_wrap"))
                    {
                      
                      pasteValuePositionCounter++;
                    }

                    if(elem.childNodes[i].isSameNode(focusedNode))
                    {
                      pasteValuePosition=pasteValuePositionCounter;
                    }
                    i++;
                  }
                  
    
                  if(elem.lastElementChild.isSameNode(focusedNode) || elem.isSameNode(focusedNode))
                    {
                      last=true;
                    }
                  
                    let ch=this.createChildList(field);
                              
                                elem.replaceChild(ch, focusedNode);
                                
                                
                                let empty=document.createElement("span");
                                empty.innerHTML="&#8291;"
                                
                               
                                ch.insertAdjacentElement('afterend',empty);
    
                                ////ПРОВЕРИТЬ НЕ ВМТАВЛЕН ЛИ УЖЕ СЗАДИ EMPTY
                                
                               
    
                                
                                  if(last)
                                  {
                                    let di1= document.getSelection();
                                    di1.removeAllRanges();
                                    let range = document.createRange();
                                    range.setStart(elem, elem.childNodes.length);
                                   
                                    range.collapse(true)
                                      
                                    di.addRange(range);
                                  }
                                  else
                                  {
                                    
                                    let fnext=<HTMLElement>ch.nextSibling;
                                    this.setRangeStartAfter(fnext);
                                  }
                                
                     
                                
                                if(pasteValuePosition>this.fields.length)
                                {
                                  this.fields.push(field)
                                }
                                else
                                {
                                  this.fields.splice(pasteValuePosition,1,field)
                                }
                  }
                }

                this.setRangeCurrent()    
               }
                
                
               this.resizeField();
              }
              
             

              alf(val: any=null)
              {
                alert(val.value)
              }


              consoleVals()
              {
                console.log(this.fields)
              }



              setRangeCurrent()
              {
                let elemCont = <HTMLElement>this.el.nativeElement;
                if(elemCont.childNodes.length==0)
                {
                  let empty=document.createElement("span");
                  empty.innerHTML="&#8291;"
                  elemCont.appendChild(empty);
                  this.setRangeNodeContents(empty);
                }
                let sel=document.getSelection();
                this.curFocusNode=sel.focusNode;
                this.curAnchorNode=sel.anchorNode;
                let ofs=sel.focusOffset;
                if(sel.focusNode.nodeName=="DIV")
                {let focusedEl=<HTMLElement>sel.focusNode
                  let el=focusedEl.childNodes[ofs-1];
                    
                    this.setRangeNodeContents(el);
                    let elH=<HTMLElement>el;
                    if(elH.classList.contains("value_wrap"))
                    {
                      this.curFocusType=1;
                    }
                    else
                    {
                      this.curFocusType=0;
                    }

                    sel=document.getSelection();
                    this.curFocusNode=sel.focusNode;
                    this.curAnchorNode=sel.anchorNode;
                }
                this.curFocusOffSet=sel.focusOffset;
                this.curAnchorOffSet=sel.anchorOffset;
                
                let nodeF=sel.focusNode;
                while(nodeF.parentNode.nodeName!="DIV")
                {
                  nodeF=nodeF.parentNode;
                }


                let elem = <HTMLElement>this.el.nativeElement;
                elem.childNodes.forEach(c=>{
                  let cE=<HTMLElement>c;
                  if(cE.classList.contains("current_focused_node"))
                  {
                    cE.classList.remove("current_focused_node");
                    cE.style.backgroundColor="transparent";
                  }
                })


                let fElem=<HTMLElement>nodeF;
                if(fElem.classList.contains("value_wrap"))
                    {
                      this.curFocusType=1;
                      fElem.classList.add("current_focused_node");
                      fElem.style.backgroundColor="#b9b5e4";

                    }
                    else
                    {
                      this.curFocusType=0;
                    }
                  this.curRange=sel.getRangeAt(0);

              }


              setFocus(ev: MouseEvent)
              {
                this.isFocused=true;
                let trg=<HTMLElement>ev.target;
                
                  ////////если таргет див то выделить крайний элемент 
                  this.setRangeCurrent();
                  let focused=document.getSelection().focusNode;
                  
                  let same=focused.isSameNode(document.getSelection().anchorNode)
                  while(focused.parentNode.nodeName!="DIV")
                      {
                        focused=focused.parentNode;
                      }
                    let elem = <HTMLElement>this.el.nativeElement;
                    let index=0;
                    for(let i=0; i<elem.childNodes.length; i++)
                    {

                      if(focused.isSameNode(elem.childNodes[i]))
                      {

                        index=i;
                      }
                    }
                    
                  if(elem.children[index].classList.contains("value_wrap"))
                  {
                    
                    
                    if(trg.nodeName=="DIV" && same && this.curFocusOffSet==this.curAnchorOffSet)
                    {
                      
                      
                      let di1= document.getSelection();
                                    di1.removeAllRanges();
                                    let range = document.createRange();
                                    
                                  let empty2=document.createElement("span");
                                  empty2.innerHTML="&#8291;"
                                  let focusedEl=<HTMLElement>focused;
                                  if(focusedEl.classList.contains("value_wrap"))
                                  {
                                    
                                    if(focused.nextSibling==undefined || focused.nextSibling==null)
                                    {
                                      focusedEl.insertAdjacentElement('afterend',empty2);
                                      range.setStartAfter(empty2)
                                      range.collapse(true) 
                                    }
                                    else
                                    {
                                      let fnn=<HTMLElement>focused.nextSibling;
                                      if(fnn.textContent==String.fromCharCode(8291))
                                      {
                                        range.setStartAfter(fnn)
                                        range.collapse(true) 
                                      }
                                      else
                                      {
                                        focusedEl.insertAdjacentElement('afterend',empty2);
                                      range.setStartAfter(empty2)
                                      range.collapse(true) 
                                      }
                                    }
                                    
                                  }
                                  else
                                  {
                                    
                                    range.setStartAfter(focused)
                                    range.collapse(false)
                                  }
                                 
                                    di1.addRange(range);
                                   this.setRangeCurrent();
                  this.curFocusType=0;
                  this.closeValuesForm();
                    }
                    else
                    {
                      this.curFocusType=1;
                      
                      this.openValuesForm();
                    }



                    
                  }
                  else
                  {
                    this.curFocusType=0;
                    this.closeValuesForm();
                  }
               // }

              
              }


              openFieldListOnButton()
              {
                if(this.showPasteOptions==false)
                {
                  if(this.curFocusNode!=null)
                  {
                   
                   let di1= document.getSelection();
                   di1.removeAllRanges();
                   di1.addRange(this.curRange);
                   this.openValuesForm()
                   
                  }
                  else
                  {
                    let elem  = <HTMLElement>this.el.nativeElement;
                    if(elem.childNodes.length>0)
                    {
                      
                      
                      let focused=<HTMLElement>elem.lastChild;
                      this.setRangeNodeContents(focused.lastChild);
                     
                    }
                    else
                    {
                      let empty=document.createElement("span");
                    empty.innerHTML="&#8291;"
                    elem.appendChild(empty);
                    this.setRangeNodeContents(empty);
                    }
                    this.setRangeCurrent();
                    this.openValuesForm()
                    
                    
  
                  }
                }
                else
                {
                  if(this.curFocusNode==null)
                  {
                    let elem  = <HTMLElement>this.el.nativeElement;
                    if(elem.childNodes.length>0)
                    {
                      let focused=<HTMLElement>elem.lastChild;
                      this.setRangeNodeContents(focused.lastChild);
                    }
                    else
                    {
                      let empty=document.createElement("span");
                    empty.innerHTML="&#8291;"
                    elem.appendChild(empty);
                    this.setRangeNodeContents(empty);
                    }
                    this.setRangeCurrent();
                  }
                  else
                  {
                    let di1= document.getSelection();
                    di1.removeAllRanges();
                    di1.addRange(this.curRange);
                  }
                  
                  
                  this.showPasteOptions=false;
                this.hoveredFieldId=null;
                  
                }
               
              }
             
              openValuesForm()
              {
                //открыть форму для выбора полей
                //this.searchInValuesForm();
                
                this.invisibleOptions=true;
                this.showPasteOptions=true;
                setTimeout(()=>{
                  let options=<HTMLElement>this.optionsContainer.nativeElement;
                options.style.width=this.el.nativeElement.getBoundingClientRect().width+"px";
                options.style.left=this.el.nativeElement.getBoundingClientRect().left+"px";
                let position=document.documentElement.clientHeight>this.el.nativeElement.getBoundingClientRect().y+this.el.nativeElement.getBoundingClientRect().height + this.optionsContainer.nativeElement.getBoundingClientRect().height +50;
                
                if(position)
                {
                  options.style.top=(this.el.nativeElement.getBoundingClientRect().y+this.el.nativeElement.getBoundingClientRect().height)+"px";
                }
                else
                {
                  options.style.top=(this.el.nativeElement.getBoundingClientRect().y-options.getBoundingClientRect().height)+"px";
              
                }
                
                this.hoveredFieldId=null;
                this.invisibleOptions=false;
                },100)
                
                
              }

              closeValuesForm()
              {
                //открыть форму для выбора полей
                
                this.showPasteOptions=false;
                this.hoveredFieldId=null;
                
              }


              searchInValuesForm()
              {
                if(this.showPasteOptions)
                {
                  let focused=document.getSelection().focusNode;
                  let ofs=document.getSelection().focusOffset;
                  let anchor=document.getSelection().anchorNode;
                  let an_ofs=document.getSelection().anchorOffset;
                  let focusedC=document.getSelection().focusNode;
                  while(focusedC.parentNode.nodeName!="DIV")
                  {
                    focusedC=focusedC.parentNode;
                  }
                  let focusedCElem=<HTMLElement>focusedC;
                  if(focused.isSameNode(anchor)&& ofs==an_ofs  && !focusedCElem.classList.contains("value_wrap"))
                  {
                    let filterText="";
                  if(ofs>0)
                  {
                    let nottxt=false;
                    let ind=ofs-1;
                    while(ind>0 && !nottxt)
                    {
                      
                      if(!this.NOT_TXT.includes(focused.textContent[ind]) && focused.textContent[ind]!=String.fromCharCode(8291))
                      {
                        filterText=focused.textContent[ind]+filterText;
                      }
                      else
                      {
                        nottxt=true;
                      }
                      ind--;
                    }
                    
                    if(filterText!="")
                    {
                      let filtered=[];
                      let unfiltered=[];
                      
                      let vals=_.cloneDeep(this.valuesForPaste);
                      
                      filtered = vals.filter(element => {
                            return element.Value!=null && ExtendedHelper.ContainsNoCase(element.Value, filterText);
                        });


                        for (let i = 0; i < filtered.length; i++) {
                          filtered[i].Value= SearchSelectHelper.getSearchedText(filterText, filtered[i].Value);
                      }
                        unfiltered = vals.filter(element => {
                          return element.Value!=null && !ExtendedHelper.ContainsNoCase(element.Value, filterText);
                      });
                       
                        
                        this.valuesForPasteShow=filtered.concat(unfiltered);
                    }
                    else
                    {
                      this.valuesForPasteShow=_.cloneDeep(this.valuesForPaste);
                    }
                  }
                  }
                  else
                    {
                      this.valuesForPasteShow=_.cloneDeep(this.valuesForPaste);
                    }
                  
                }
                
                
              }


              closeValuesFormWithoutSelect()
              {
                //закрыть форму для выбора полей без выбора поля
                //проверить если фокус на поле то сместить его на начало соседнего элемента 
              }


            
            CheckFilterInput()
            {
              
             

            }




            checkFormul(withoutIndicator: boolean=false)
            {

              
              
              let elem = <HTMLElement>this.el.nativeElement;
              this.FormulTokensInput=[];
              let children=elem.childNodes;
              let fieldIndex=0;
              children.forEach(child=>{
                let childElem=<HTMLElement>child;
                if(childElem.classList.contains("value_wrap"))
                {

                  
                  let field=this.fields[fieldIndex];
                  
                  let token = new FormulaTokenOut();
                  token.FieldLink=[];
                  if(field.Parents!=null && field.Parents.length>0)
                  {
                    field.Parents.forEach(p=>{
                      let link = new FieldLinkPartOut();
                      link.FieldTemplateId=p.Id;
                      link.IsList=p.IsSum; 
                      token.FieldLink.push(link)
                    })
                  }

                  let link = new FieldLinkPartOut();
                      link.FieldTemplateId=field.Id;
                      link.IsList=field.IsSum; 
                      token.FieldLink.push(link)
                  token.Type=1;
                  token.Value=0;
                  this.FormulTokensInput.push(token);
                  fieldIndex++;
                }
                else
                {
                  
                  let txt=childElem.innerText;
                  while(txt.length>0)
                  {
                    let atom=txt[0];
                    
                    if(atom==String.fromCharCode(8291))
                    {
                      txt=txt.substring(1)
                    }
                    else
                    {
                      
                      if(atom.charCodeAt(0)==32 || atom.charCodeAt(0)==160)
                      {
                        atom=" "
                      }
                      switch (atom)
                      {
                        
                        case "+":
                            {
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=2;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                        case "-":
                            {
                              
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=3;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                        case "*":
                            {
                              
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=4;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                        case "/":
                            {
                              
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=5;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                        case "(":
                            {
                              
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=6;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                        case ")":
                            {
                              
                              let token = new FormulaTokenOut();
                              token.FieldLink=null;
                              token.Type=7;
                              token.Value=0;
                              this.FormulTokensInput.push(token);
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }

                            case " ":
                            {
                              
                              
                              atom="";
                              txt=txt.substring(1);
                              break;
                            }
                            case String.fromCharCode(8291):
                              {
                                
                                
                                atom="";
                                txt=txt.substring(1);
                                break;
                              }
                        default:
                            {

                              if(this.NUMS.includes(atom) && atom!='.' && atom!=',')
                              {
                                let nextNonNumber=false;
                                while(txt.length>1 && !nextNonNumber)
                                {
                                  if(this.NUMS.includes(txt[1]) || txt[1]==String.fromCharCode(8291))
                                  {
                                    if(txt[1]!=String.fromCharCode(8291))
                                    {
                                      if(txt[1]!=",")
                                      {
                                        atom=atom+txt[1];
                                      }
                                      else
                                      {
                                        atom=atom+".";
                                      }
                                      
                                    }
                                    
                                    txt=txt.substring(1);
                                  }
                                  else
                                  {
                                    nextNonNumber=true;
                                  }
                                }
    
                                let token = new FormulaTokenOut();
                                token.FieldLink=null;
                                token.Type=0;
                                token.Value=Number(atom);
                                this.FormulTokensInput.push(token);
                                atom="";
                                txt=txt.substring(1);
                                break;
                              }
                              else
                              {
                                let nextNonText=false;
                                while(txt.length>1 && !nextNonText)
                                {
                                  if(!this.NOT_TXT.includes(txt[1]))
                                  {
                                    if(txt[1]!=String.fromCharCode(8291))
                                    {
                                      
                                        atom=atom+txt[1];
                                      
                                      
                                    }
                                    
                                    txt=txt.substring(1);
                                  }
                                  else
                                  {
                                    nextNonText=true;
                                  }
                                }
    
                                let token = new FormulaTokenOut();
                                token.FieldLink=null;
                                token.Type=-1;
                                token.Value=0;
                                this.FormulTokensInput.push(token);
                                atom="";
                                txt=txt.substring(1);
                                break;
                              }

                              
                            }
                      }
                    }
                    
                   
                  }
                }
              })
            
            this.checkFormulCorrect(withoutIndicator);
            }

            checkFormulCorrect(withoutIndicator: boolean=false)
            {
              
              let correct=true;
              if(this.FormulTokensInput!=undefined&& this.FormulTokensInput!=null && this.FormulTokensInput.length>0)
              {
                if(this.FormulTokensInput.length==1 && this.FormulTokensInput[0].Type>1)
                {
                  correct=false;
                }
                if(this.FormulTokensInput.length==2 && this.FormulTokensInput[0].Type!=2 && this.FormulTokensInput[0].Type!=3 && this.FormulTokensInput[1].Type>1)
                {
                  correct=false;
                }
  
                if(this.FormulTokensInput.length>2)
                { 
                  if(this.FormulTokensInput[0].Type==4 || this.FormulTokensInput[0].Type==5 || this.FormulTokensInput[0].Type==7)
                  {
                    correct=false;
                  }
                  if(this.FormulTokensInput[this.FormulTokensInput.length-1].Type>1 &&  this.FormulTokensInput[this.FormulTokensInput.length-1].Type<7)
                  {
                    correct=false;
                  }

                  
                  for(let i=0; i<this.FormulTokensInput.length; i++)
                  {
                    if(i<this.FormulTokensInput.length-1)
                    {
                      if(this.FormulTokensInput[i].Type<=1 && this.FormulTokensInput[i+1].Type<=1)
                      {
                        correct=false;
                      }
                      
                      if(this.FormulTokensInput[i].Type>1)
                      {
                        if(this.FormulTokensInput[i].Type!=7 && this.FormulTokensInput[i+1].Type>1 && this.FormulTokensInput[i+1].Type!=3 && this.FormulTokensInput[i+1].Type!=6)
                        {
                          correct=false;
                        }
                        if(this.FormulTokensInput[i].Type==7 && this.FormulTokensInput[i+1].Type<=1)
                        {
                          correct=false;
                        }
                        if(this.FormulTokensInput[i].Type==5 && this.FormulTokensInput[i+1].Type==0 && this.FormulTokensInput[i+1].Value==0)
                        {
                          correct=false;
                        }
                      }
                    }
                    
                  }
                  

                }





                let counter=0;
                  for(let i=0; i<this.FormulTokensInput.length; i++)
                  {
                    if(this.FormulTokensInput[i].Type==6)
                    {
                      counter=counter-1;
                    }
                    if(this.FormulTokensInput[i].Type==7)
                    {
                      counter=counter+1;
                    }
                    if(counter>0)
                    {
                      correct=false;
                    }
                    

                    if(this.FormulTokensInput[i].Type==-1)
                      {
                        correct=false;
                      }

                      if(this.FormulTokensInput[i].Type==0 && isNaN(this.FormulTokensInput[i].Value))
                      {
                        correct=false;
                      }
                    
                  }
                  if(counter!=0)
                    {
                      correct=false;
                    }
                
              }
              this.formulIncorrect=!correct;
              if(this.FormulTokensInput==undefined || this.FormulTokensInput==null || this.FormulTokensInput.length==0)
              {
                this.formulIncorrect=true;
              }

              

              
              if(!this.formulIncorrect && !withoutIndicator)
              {
                this.setFieldCorrect();
              }

              this.setFormulTokens();
            }
         

            checkCurrentFormul()
            {
              
              let index=0;
              if(this.fields!=undefined && this.fields!=null && this.fields.length>0)
              {
                while(index<this.fields.length)
                {
                  let field_exist=this.valuesForPaste.findIndex(c=>c.Id==this.fields[index].Id);
                  if(field_exist>-1)
                  {
                    //Поле есть в потенциально возможных
                    if(this.valuesForPaste[field_exist].Value!=this.fields[index].Value || this.valuesForPaste[field_exist].IsList!=this.fields[index].IsList
                      || (this.valuesForPaste[field_exist].Parents.length>0 && (this.valuesForPaste[field_exist].Parents[0].Value!=this.fields[index].Parents[0].Value || this.valuesForPaste[field_exist].Parents[0].IsList!=this.fields[index].Parents[0].IsList)))
                    {
                      //Не совпадают названия
                      this.fields[index].Value=this.valuesForPaste[field_exist].Value;
                      if(this.valuesForPaste[field_exist].IsList!=this.fields[index].IsList)
                      {
                        this.fields[index].IsSum=false;
                        
                      }
                      this.fields[index].IsList=this.valuesForPaste[field_exist].IsList;


                      if(this.valuesForPaste[field_exist].Parents.length>0)
                      {
                        this.fields[index].Parents[0].Value=this.valuesForPaste[field_exist].Parents[0].Value;
                        if(this.valuesForPaste[field_exist].Parents[0].IsList!=this.fields[index].Parents[0].IsList)
                        {
                          this.fields[index].Parents[0].IsSum=false;
                          
                        }
                        this.fields[index].Parents[0].IsList=this.valuesForPaste[field_exist].Parents[0].IsList;
                        }
                      let focusedNode=null;
                      let elIndex=-1;
                      let elem = <HTMLElement>this.el.nativeElement;
                      elem.childNodes.forEach(chn=>{
                        let el=<HTMLElement>chn;
                        if(el.classList.contains("value_wrap"))
                        {
                          elIndex++;
                          if(elIndex==index)
                          {
                            focusedNode=chn;
                          }
                        }
                        
                      })
                      let ch=this.createChildList(this.fields[index]);
                              if(focusedNode!=null)
                              {
                                
                                elem.replaceChild(ch, focusedNode);
                              }
                                

                    }
                    index++;
                  }
                  else
                  {
                    //Поля нет в списке доступных для вставки
                    let focusedNode=null;
                      let elIndex=-1;
                      let elem = <HTMLElement>this.el.nativeElement;
                      elem.childNodes.forEach(chn=>{
                        let el=<HTMLElement>chn;
                        if(el.classList.contains("value_wrap"))
                        {
                          elIndex++;
                          if(elIndex==index)
                          {
                            focusedNode=chn;
                          }
                        }
                        
                      })
                      if(focusedNode!=null)
                              {
                                
                                let elemNode=<HTMLElement>focusedNode;
                                elemNode.classList.remove("value_wrap");
                                elemNode.style.color="#6b6a6a"
                                elemNode.innerHTML=elemNode.innerText;
                                
                              }
                              this.fields.splice(index, 1);
                              this.checkFormul();
                  }
                }
              }
             
            }
}

import { CommonRequest, SingleIdRequest } from "../../model.http";

// export class FileElement {
//     Id: number;
//     Name: string;
//     FullName: string;
//     Path: string;
//     Type: number;
//     ParentId: number;
//     Level: number;
//     Visible: boolean;
//     ChildsVisible: boolean;
//     Margin: string;
//     LoadingFile: string;
//     Size: number;
//     SizeName: string;
// }

// export class DirectoryDirective extends ParentChecker {
//     Type: number;
//     Path: string;
//     Name: string;
// }


// export class DirectoryRemoveAsker extends ParentChecker {
//     Id: number;
//     Type: number;
//     Path: string;
// }
export class FileUploadAsker extends CommonRequest {
    FolderId: number;//ид папки
    Filename: string;//название файла
    Type: number;//тип загрузки FileUploadType
}
class FileUploadType {
    New = 0;//стандарт
    Replace = 1;//С заменой
    Copy = 2;//как копию
}

export class FileReplaceAsker extends CommonRequest {
    FileId: number;//ИД заменяемого файла
    FolderId: number;//ид папки с файлом
    Filename: string;//название файла с расширением
    KeepTags: boolean;//сохранять ли теги
    Type: number;//какое имя будет иметь файл тип FileReplaceType
    constructor(changerId: number = 0, sessionKey: string = '') {
        super(changerId, sessionKey);
        this.KeepTags = true;
        this.Type = -1;
    }
}

class FileReplaceType {
    OldName = 0;
    NewName = 1;
}

export class PhotoUploadAsker extends CommonRequest {
    Filename: string;
    Type: number;
}

class PhotoUploadType {
    Chat = 0;
    User = 1;
}


export class DirectoryAddAsker extends CommonRequest {
    ParentDirectoryId: number;
    Name: string;
}

export class FileAccessEditAsker extends SingleIdRequest {
    GroupsWithAccess: number[];
    ClientId: number;
    ProjectId: number;
}

// export class FileDeleteDirective extends ParentChecker {
//     Path: string;
// }


// export class FormDataStringSmall extends ParentChecker {
//     FileName: string;
// }

// export class FormDataString extends FormDataStringSmall {
//     Path: string;
// }

// export class FormDataStringNoCheck {
//     FileName: string;
//     Path: string;
// }

// export class FormDataStringRuleCheck extends FormDataString {
//     Id: number;
//     Type: number;
// }




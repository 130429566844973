import {ParentChecker} from "./model.parentChecker";
import {JobLight} from "./model.job";
import {Common} from "./model.common";
import { CommonRequest, CommonResponse, SingleIdRequest } from "./model.http";
import { BillInList } from "./model.1C/In/model.BillInList";
import { CommonWorkerStatuGroupBy, WorkerInJobFilterHolder } from "./model.worker";
import { CommonEntityWithCount, ParameterInstanceContainer, ValueOfSetContainer, ValueSetSmall } from "./model.parameters";
/**
 * Created by MosNeuro on 04.05.2017.
 */

/*
    export class StorageItemAddAsker extends CommonRequest
    {
      Name: string; //название новой позиции
      Group: string; //название группы 
      Subgroup: string; //название подгруппы
    }


    export class StorageItemMovementAddAsker extends CommonRequest
    {
      ItemId: number;//Движимая позиция.

      Type: number;//тип движения. Создать можно только приход / расход / бронь.
      ProjectId: number;//проект, по которому совершается движение. Необязательно для брони.
      StorageCell: string;//Ячейка хранения.
      Count: number;//количество товара.

      BillType: number;//тип счета. в соотв с типом счета заполняется 1 из 2 полей счета.
      BillString: string;//счет - строка.
      BillId: number;//Id счета - файла или счета из 1С.

      ExpirationDate: Date;//срок годности. Необязательно.
      State: string;//состояние. Необязательно.
    }



    export class StorageItemInfoGiver extends CommonResponse
    {
      InfoContainer: StorageItemInfo;
    }

    export class StorageItemInfo extends Common
    {
      Group: string;
      Subgroup: string;

      Movements: StorageItemMovement[];
    }

    export class StorageItemMovement
    {
      Id: number;
      Date: Date;//время движения.
      Type: number;//тип движения.
      Project: Common;//проект
      ProjectRemoved;//пометка, если проект удален.
      StorageCell: string;//ячейка хранения.

      BillType: number;//тип счета.

      Bill: Common;//счет.
      Creator: Common;
      Count: Number;
      IsUnremovedReserve: boolean;//является неснятой бронью. если true, можно снять бронь и перевести в расход.
      ExpirationDate: Date;//срок годности
      State: string;//состояние
    }


    export class AddItemMovementContainer
    {
      Id: number;
      Item: Common;
      Type: Common[];//тип движения.
      Project: Common;//проект
      StorageCell: string;//ячейка хранения.
      BillType:Common[];//тип счета.
      Bill: BillInList;//счет.
      BillString: string;
      BillFile: Common;//счет.
      ExpirationDate: Date;//срок годности
      State: string;//состояние
      Count: number;
      TypeEnum: Common[];
      BillTypeEnum: Common[];
      FreeCount: number;

      constructor(item: Common=null)
      {
        this.Id=-1;
        this.Item=item;
        this.Type=[new Common(0, "Броь позиции")];
        this.Project=null;
        this.StorageCell="";
        this.BillType=[new Common(0, "Строка счета")];
        this.Bill=null;
        this.BillFile=null;
        this.BillString="";
        this.ExpirationDate=new Date();
        this.State="";
        this.Count=1;

        this.TypeEnum=[new Common(0, "Броь позиции"), new Common(1, "Приход позиции"), new Common(2, "Расход позиции")];
        
        this.BillTypeEnum=[new Common(0, "Строка счета"), new Common(1, "Файл"), new Common(2, "Счет 1с")];
        this.FreeCount=0;
      }
    }

    export class ReserveItemMovementContainer
    {
      MovementId: number;
      Project: Common;//проект
      BillType:Common[];//тип счета.
      Bill: Common;//счет.
      BillFile: Common;//счет.
      BillString: string;
      BillTypeEnum: Common[];
      CanChangeBill: boolean;
      CanChangeProject: boolean;

      constructor()
      {
        
        this.Project=null;
        this.BillType=[new Common(0, "Строка счета")];
        this.Bill=null;
        this.BillFile=null;
        this.BillString="";
        this.BillTypeEnum=[new Common(0, "Строка счета"), new Common(1, "Файл"), new Common(2, "Счет 1с")];
        this.CanChangeBill=true;
        this.CanChangeProject=true;
      }
    }




    
    export class StorageItemSearchAsker extends CommonRequest
    {
      NameFilter: string; //строковый фильтр по названию
      Group?: number; //группа
      Subgroup?: number; //подгруппа
    }


    
    export class StorageItemReserveReleaseAsker extends SingleIdRequest
    {
      ProjectId: number;//проект, по которому совершается движение.

      BillType: number;
      BillString: string;//счет - строка.
      BillId: number;//Id счета - файла или счета из 1С.

      UnreserveToOutcome: boolean;//бронь снимается для одновременного расхода этого товара.
    }



    export class StorageItemGroupListGiver extends  CommonResponse
    {
      Groups: StorageItemGroup[];
    }

    export class StorageItemGroup extends Common
    {
      Subgroups: StorageItemSubgroup[];
    }

    export class StorageItemSubgroup extends Common
    {
         Items: Common[];
    }



    export class StorageItemListGiver extends CommonResponse
{
    Items: StorageItem[];
}

export class StorageItem extends Common
{
   CountAvailable: number;//Количество товара в наличии

   CountReserved: number;//Количество забронированного товара

   CountFree: number;//Количество свободного товара.
}

export class AddStorageItemContainer
{
  Name: string;
  Group: string;
  Subgroup: string;


  constructor()
  {
    this.Name="";
    this.Group="";
    this.Subgroup="";
  }
}




export class StorageItemGroupEditAsker extends SingleIdRequest
{
    Name: string;
}


export class StorageItemSubgroupEditAsker extends  StorageItemGroupEditAsker
    {
      GroupId: number;
    }


export class StorageItemEditAsker extends StorageItemSubgroupEditAsker
    {
      SubgroupId: number;
    }


*/





    export class PositionAddContainer
    {
      NameContainer: ParameterInstanceCreateContainer;
      NameContainerFilled: ParameterInstanceCreateContainerFilled;
      Comment: string;
     
      MeasureSelected: Common[];
      PossibleMeasures: Common[];
      Files: Common[];


      Template:  Common;
      isAdding:boolean=false;
    
    }


    export class PositionEditContainer
    {
      Name: any;
      NameParameters:ParameterInstanceContainer[];
      VendorCode: string;
      MeasureUnitSetId:number;
      Note: string;
      NotesList: string[];
      MeasureUnit: Common[];
      PossibleMeasures: Common[];
      Files: Common[];
      Moves:any[];
      MovesBack:any[];
      SelectedFilter:number=-1;

      IncomingFilter: boolean=true;
      OutgoingFilter: boolean=true;
      MovementFilter: boolean=true;

      Storages:ItemAtStorage[]=[];
      Id: number;
    }

    export class  PositionLocation
    {
      Storage:string;
      Responsible: string;
      Count: number;
      constructor(storage: string, responsible:string, count:number)
      {
        this.Storage=storage;
        this.Responsible=responsible;
        this.Count=count;
      }
    }

    export class StorageComment
    {
      Text: string;
      User: Common;
      Date: Date;

      constructor(text: string, user:Common, date:Date)
      {
        this.Text=text;
        this.User=user;
        this.Date=date;
      }
    }


    export class MovementsContainer
    {
      Id: number;
      ItemId: number;
      Name: any;
      NameParameters: StorageItemNameInstanceContainer[];
      Creator: Common;
      VendorCode: string;
      MovementType: number;
      Note: string;
      NotesList: String[];
      MeasureUnit: Common;
      SourceStorageSelected: any[];
      ReceiverStorageSelected: any[];
      PossibleStorages: any[];
      PossibleSourceStorages: any[];
      Files: any[];
      AddedFiles: any[];
      Date: Date;
      Projects: Common[];
      Bills: BillInList[];
      Count:number;
      isAdding:boolean=false;
    }


    export class StorageListGiver extends CommonResponse
    {
       List: Storage[];
    }

   export class Storage extends Common
    {
      ResponsibleWorker: Common;//ответственный пользователь.
      Removeable: boolean;//склад может быть удален.
    }

    export class StorageContainer extends Common
    {
      ResponsibleWorker: Common[];//ответственный пользователь.
      PossibleWorkers: CommonWorkerStatuGroupBy[];
      Removeable: boolean;//склад может быть удален.
    }
    
    export class StorageResponsibleEditAsker extends SingleIdRequest
    {
      ResponsibleWorkerId: number;//Id ответственного пользователя.
    }



    export class ParameterInstanceCreateContainer

    {
      //швблон параметра / поля записи.
      Template: Common;
      ValueType: number; //тип значения
      IsList: boolean; //допускается создание нескольких вхождений в одном списке.
      //ValueSetId?: number; //Id набора значений, если тип - "Набор значений"
      RecordAxisType: number; //расположение полей записи
      RecordFields: ParameterInstanceCreateContainer[]; //контейнеры полей записи.
       ValueSet: ValueSetSmall;
  }



  export class ParameterInstanceCreateContainerFilled

    {
      //швблон параметра / поля записи.
      Template: Common=null;
      ValueType: number; //тип значения
      ValueSetId:number;
      IsList: boolean; //допускается создание нескольких вхождений в одном списке.
      StringValues: string []=[];
      StringValuesName: any []=[];
      BooleanValues: boolean []=[];
      BooleanValuesName: any []=[];
      NumberValues: number []=[];
      NumberValuesName: any []=[];
      DateValues: Date []=[];
      DateValuesName: any []=[];
      FormulaValues: number []=[];
      FormulaValuesName: any []=[];
      ValueSetSelected: ValueSetSmall[][]=[[]];
      RecordFields: ParameterInstanceCreateContainerFilled[][]=[[]]; //контейнеры полей записи.
      HorizontalLevel?:number=0;
  }



  
 export class ParameterInstanceCreateContainerGiver extends CommonResponse
  {
      Container: ParameterInstanceCreateContainer;
  }


  export class StorageItemAddAsker extends CommonRequest
    {
        //список заполненных контейнеров в т.ч. для создания заполненного вхождения параметров.
        //один для одиночного вхождения, несколько - для параметра списка.
        Containers: ParameterInstanceContainerFilled[];
        MeasureUnitId: number;//выбранная единица измерения
        Note: string;//Комментарий к позиции.
        Files: number[];
    }

    export class ParameterInstanceContainerFilled
    {
        TemplateId:number;//Id шаблона параметра / записи.

        //поля для возможных элементарных значений вхождения.
        StringValue: string="";//строковое значение параметра.так же используется при поиске при неполном вводе значения набора в поле.
        NumberValue: number;
        BoolValue: boolean;
        ValueOfSet: number;
       // FormulaValue: number;
        NewCreatedValue:ValueOfSetContainer;//для открытых множеств, если было создано и выбрано новое значение.


        //общий список со всеми полями данной записи, если это запись (только лишь дочерние, не рекурсивно).
        //если какое-либо из полей записи само является списком, поместить весь список в данный общий список.
        RecordFields: ParameterInstanceContainerFilled[]=[];
        DateValue:Date=null;

    }


    export class ParameterInstanceContainerFilledFull
    {
        TemplateId:number;//Id шаблона параметра / записи.

        //поля для возможных элементарных значений вхождения.
        StringValue: string;//строковое значение параметра.так же используется при поиске при неполном вводе значения набора в поле.
        NumberValue: number;
        BoolValue: boolean;
        ValueOfSet: number;
        NewCreatedValue:string;//для открытых множеств, если было создано и выбрано новое значение.


        //общий список со всеми полями данной записи, если это запись (только лишь дочерние, не рекурсивно).
        //если какое-либо из полей записи само является списком, поместить весь список в данный общий список.
        RecordFields: any[];
        SelectedValue: Common[];
        ValuesForSet: Common[];
        ValueSetId: number;
        DateValue:Date=null;

        constructor()
        {
          this.TemplateId=-1;
          this.StringValue="";
          this.NumberValue=-1;
          this.BoolValue=false;
          this.ValueOfSet=-1;
          this.NewCreatedValue="";
          this.RecordFields=[];
          this.SelectedValue=[];
          this.ValuesForSet=[];
          this.ValueSetId=null;
          this.DateValue=null;
        }
    }



export class StorageItemListGiver extends CommonResponse
    {
        List: StorageItem[];
    }

    export class StorageItem
  {
      Id: number;

      VendorCode: string; //артикул

      NameParameters: StorageItemNameInstanceContainer[]; //параметрическое название позиции

      Storages: Common[]; //склады, где лежат товары позиции

      Count: number; //кол-во товара

      MeasureUnit: Common; //единица измерения

      Note: string; //Комментарий к позиции.

      LastMovementDate:Date; //Дата последней операции.
      Files: Common[];

    ExternalMovements: StorageItemMovementShort[]; //Приходы, уходы 
    InternalMovements: StorageItemMovementShort[]; //Перемещения

    ExternalMovementsHiddenCount: number; //кол-во приходов и уходов, не отображенных в таблице.
    InternalMovementsHiddenCount: number; 
    isLocked:boolean=false;
  }

export class StorageItemNameInstanceContainer
{
    Single: boolean; //true Для одиночного, false для списка.

    Instance: StorageItemNameInstance; //одиночный параметр
    ListTitle: StorageItemNameInstanceTitle; //список параметров
}

export class KeyValueStrings
{
    Key: string;
    Value: string;
}
export class StorageItemNameInstance extends KeyValueStrings
{
    IsRecord: boolean;
    RecordFields: StorageItemNameInstanceContainer[];
    TemplateId: number; //Id шаблона данного поля.
    RecordAxisType: number; //ориентация полей записи.
    ValueDisplayType: number;
}



export class StorageItemNameInstanceTitle
{
    Name: string;
    Instances: StorageItemNameInstance[];
}


export class StorageItemSearchAsker extends CommonRequest
    {
        VendorCodeFilter: string; //фильтр по артикулу

        NameFilter: ParameterInstanceContainerFilled; //фильтр по названию

        StorageFilter: number[]; //фильтр по складу

        NoteFilter: string; //фильтр по комментарию
    }


    export class ParameterValueListGiver extends CommonResponse
    {
        List: ParameterValue[];
    }
    export class ParameterValue extends Common
    {
        UsageCount: number; //кол-во использований.
    }


    export class StorageItemInfoGiver extends CommonResponse
    {
        InfoContainer: StorageItemInfo;
    }
    export class StorageItemInfo
    {
        Id:number;
        VendorCode: string;//артикул

        NameParameters: ParameterInstanceContainer[];//параметры названия

        MeasureUnitSetId: number;//Id набора "Единица измерения"
        MeasureUnit: Common;//выбранная единица измерения

        Note: string;//комментарий

        Storages: ItemAtStorage[];//сколько и где лежит.
        Files: Common[];
         Movements: StorageItemMovementInfo[];//
         NameTemplateIsList: boolean;
    }
    export class ItemAtStorage
    {
        Storage: string;//название склада
        ResponsibleWorker: string;//ответственный
        Count: number;//кол-во на складе
    }



    export class MeasureUnitEditAsker extends SingleIdRequest
    {
        MeasureUnitId: number;//Id выбранной единицы измерения
    }


    export class ParameterInstanceFilledHintAsker extends SingleIdRequest
    { 
        Container: ParameterInstanceContainerFilled;//заполненный контейнер параметра, на основании которого будет собрана подсказка.
    }
 


    export class FileToStorageItemAddAsker extends SingleIdRequest
    {
      Filename: string;
    }
    
    export class StorageItemMovementShort
{
    Id: number;
    Count: number; //кол-во
    Date: Date; //дата движения
    MovementType: number; //тип движения.
    SourceStorage: Common; //склад - источник. только для расхода / перемещения.
    ReceiverStorage: Common; //склад - приемник. только для прихода / перемещения.
}

 enum V2StorageItemMovementType// Тип движения товара. 
{
    In = 0,// Приход товара.
    Out=1,// Расход товара.
    Inner=2// Перемещение товара (между складами системы)
}


export class StorageItemMovementInfo
{
    Id: number;
    Date: Date;//дата создания
    Creator: Common;//создатель.
    VendorCode: string;//артикул
    MovementType: number; //тип движения.
    Count: number; //кол-во

    NameParameters: StorageItemNameInstanceContainer[];//параметры названия позиции движения.

    MeasureUnit: Common;//выбранная единица измерения

    Projects: Common[];//проекты движения
    Bills: BillInList[];//счета движения

    Note: string;//комментарий
    Files: Common[];//файлы, прикрепленные к комментарию.

    SourceStorage: Common[]; //склад - источник. только для расхода / перемещения.
    ReceiverStorage: Common[]; //склад - приемник. только для прихода / перемещения.
}

export class StorageItemMovementAddAsker extends CommonRequest
{
    Item: number; //Позиция
    Count: number; //кол-во
    MovementType: number; //тип движения.

    Bills: number[]; //прикрепленные счета. только 1 для расхода / перемещения. Может и не быть вовсе.
    Projects: number[]; //прикрепленные проекты. от 0 до сколько угодно.

    Note: string; //Комментарий к движению.

    Files: number[]; //прикрепленные файлы 

    SourceStorage?: number; //склад - источник. только для расхода / перемещения.
    ReceiverStorage?: number; //склад - приемник. только для прихода / перемещения.
}



export class StorageItemMovementInfoGiver extends CommonResponse
{
    Info: StorageItemMovementInfo;
}



export class StoragesForMovementCreationAsker extends CommonRequest
    {
        ItemId: number;//Id движимой позиции
        NewMovementType: number;//тип создаваемого движения
    }


export class StoragesForMovementCreationGiver extends CommonResponse
    {
      Storages: CommonEntityWithCount[]; //общий список складов.
      PreselectedReceiverStorage: number; //предустановленный для данного перемещения склад.
    }

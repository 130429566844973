import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ElementRef, Inject, Optional, Input, ChangeDetectionStrategy, Injectable, ViewEncapsulation, SimpleChange, isDevMode, HostListener } from '@angular/core';
import { SvgIconsRegistry } from './svg-icon-registry.service';
import { SVG_CONFIG } from './types';


type Changes = {
    color: SimpleChange;
    name: SimpleChange;
};

@Injectable({
    providedIn: 'root',
})


@Component({
    selector: 'svg-icon',
    template: `
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./svg-icon.component.scss'],
    encapsulation: ViewEncapsulation.None,
})



export class SvgIconComponent implements OnInit {

    private svgIcon: SVGElement;

    private mergedConfig: SVG_CONFIG;

    @Input()
    size: 'lg' | 'md' | 'sm' | 'xs' = 'md';

    @Input()
    name: string;
    // set name(iconName: string) {
    //     if (this.svgIcon) {
    //         this.host.nativeElement.removeChild(this.svgIcon)
    //     }
    //     const svgData = this.svgIconsRegistry.getIcon(iconName);
    //     this.svgIcon = this.svgElementFromString(svgData);
    //     this.host.nativeElement.appendChild(this.svgIcon);
    // }

    get element() {
        return this.host.nativeElement;
    }

    ngOnChanges(changes: Changes) {
        if (changes.color?.firstChange === false) {
            this.element.style.fill = this.color;
            this.render();
        }
        if (changes.name?.firstChange === false) {
            this.render();
        }
    }

    private render() {
        if (this.name && this.svgIconsRegistry.getIcon(this.name)) {
            if (this.svgIcon) {
                this.host.nativeElement.removeChild(this.svgIcon)
            }
            this.mergedConfig.color = this.color;
            const svgData = this.svgIconsRegistry.getIcon(this.name);
            this.svgIcon = this.svgElementFromString(svgData);
            this.host.nativeElement.appendChild(this.svgIcon);
        } else if (isDevMode()) {
            console.warn(`⚠️ ${this.name} is missing!`);
        }
    }

    @Input()
    pixels: number;


    @Input()
    height: number;





    @Input()
    hover: 'green' | 'dark-green' | 'yellow' | 'white' | 'none' | 'red' = 'none';

    @Input()
    strokeFill: boolean = false;


    @Input()
    color: string = '#979797';
    // set color(colorName: string) {
    //     if (!colorName) colorName = '#979797';
    //     console.log(colorName)
    //     if (this.svgIcon) {
    //         this.host.nativeElement.removeChild(this.svgIcon)
    //     }
    //     const svgData = this.svgIconsRegistry.getIcon(this.name);
    //     this.svgIcon = this.svgElementFromString(svgData);
    //     this.host.nativeElement.appendChild(this.svgIcon);
    // }

    animationShow: boolean = false;

    @HostListener('click', ['$event'])
    click() {
        if (this.clickAnimation && !this.animationShow) {
            this.animationShow = true;
            this.svgIcon.classList.add(this.clickAnimation)
            setTimeout(() => {
                this.svgIcon.classList.remove(this.clickAnimation)
                this.animationShow = false;
            }, 500);
        }
    }


    @Input()
    animation: string;

    @Input()
    clickAnimation: string;

    constructor(
        private host: ElementRef,
        private svgIconsRegistry: SvgIconsRegistry,
        @Optional() @Inject(DOCUMENT) private document: any,
    ) {
        this.mergedConfig = this.createConfig();
    }

    ngOnInit() {
        if (this.svgIcon) {
            this.host.nativeElement.removeChild(this.svgIcon)
        }
        this.mergedConfig.color = this.color;
        const svgData = this.svgIconsRegistry.getIcon(this.name);
        this.svgIcon = this.svgElementFromString(svgData);
        this.host.nativeElement.appendChild(this.svgIcon);
    }



    private createConfig() {
        const defaults: SVG_CONFIG = {
            sizes: {
                xs: '12px',
                sm: '24px',
                md: '36px',
                lg: '48px'
            }
        };
        return defaults;
    }

    private svgElementFromString(svgContent: string): SVGElement {
        const div = this.document.createElement('DIV');
        div.innerHTML = svgContent;
        const svg = div.querySelector('svg');

        if (this.pixels || this.height) {
            if (this.height) {
                svg.style.height = `${this.height}px`;
            }
            if (this.pixels) {
                svg.style.width = `${this.pixels}px`;
                svg.style.height = `${this.pixels}px`;
            }
        } else {
            svg.style.width = this.mergedConfig.sizes[this.size];
            svg.style.height = this.mergedConfig.sizes[this.size];
        }

        // if (this.animation) {
        //     svg.classList.add(this.animation)
        // }

        svg.classList.add(`hover-${this.hover}-color${(this.strokeFill) ? "-stroke" : "" }`)


        svg.style.display = 'flex';
        svg.style.alignItems = 'center';
        svg.style.justifyContent = 'center';
        if (this.strokeFill) {
            svg.style.stroke = this.mergedConfig.color;
        } else {
            svg.style.fill = this.mergedConfig.color;
        }

        return div.querySelector('svg')
            || this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    }


}
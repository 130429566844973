/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  ViewChild,
  ElementRef,
  SimpleChanges,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { ParameterInstance,  ParameterInstanceContainer, ParameterInstanceContainerGiver } from '../../../models/model.parameters';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SessionService } from '../../../services/session.service';
import { CommonResponse, SingleIdRequest } from '../../../models/model.http';
import { Router } from '@angular/router';
import { HttpServiceStorage } from '../../../httpserv/httpserv.storage';
import { NumberHelper } from '../../../Helpers/NumberHelper';


declare var require: any;


@Component({
    selector: 'parameterInstanceRecordContainer',
    templateUrl: 'temp.parameterInstanceRecordContainer.html',
    styleUrls: ['parameterInstanceRecordContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ParameterInstanceRecordContainerComponent implements OnInit, AfterContentInit {
    
    
    @Input() value: ParameterInstance;
    @Input() valuesList: ParameterInstance[]=[];
    @Output() valueChange:  EventEmitter<ParameterInstance> = new EventEmitter<ParameterInstance>();
    @Output() valuesListChange:  EventEmitter<ParameterInstance[]> = new EventEmitter<ParameterInstance[]>();
    @Output() deleteParameter:  EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() recalculateEv:  EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isList: boolean=false;
    @Input() Name: string="Parameter Name";
    @Input() CanDelete: boolean = true;
    @Input() id: number=-1;
    @Input() editable: boolean=true;
    @Input() canRemove: boolean=true;

    @Input() paramId: any='-1';
    paramIdFull: any='-1';
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    UserId: number;
    SessionKey: string;
    level:number=0;
    setLevel:number=0;
    levelLengthsInSymbols:Map<number, number[]>;


    fullScreenMode: boolean=false;

    @Input() containerLocation: number=0;// 0 параметры к,п 1 - наименование склада

    @Input() containerId: number=0;
    @Output() resizeFinished:  EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() fullScreen: boolean=true;
    @Input() refreshed: Subject<number> = new Subject();;
    @ViewChild('recsContainer', { static: false }) recsContainerrElem: ElementRef;
    @ViewChild('recsContainerFull', { static: false }) recsContainerFullElem: ElementRef;
  constructor(
    private languageService: LanguageService,
    private httpServiceParameters: HttpServiceParameters,
    private httpServiceStorage: HttpServiceStorage,
    private sessionService: SessionService,
    private router: Router
              ) { }

            
              ngOnDestroy() {
              }

              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
              }
              
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }

              ngOnInit() 
              {
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.refreshed.subscribe(id=>{
                  if(id==this.paramId)
                  {
                    this.paramIdFull="_full"+this.paramId;
                    if(this.fullScreenMode)
                    {
                      this.updateFullScreenFieldsSizes();
                      this.recalculate();
                    }
                    else
                    {
                      this.recalculate();
                    }
                  }
                })
        
              }

              ngAfterContentInit() {
                console.log('after init')

                this.paramIdFull="_full"+this.paramId;
                this.InitLevels();
                // this.clearContainerStyles(this.paramId);
                const that = this
                setTimeout(()=>{
                  // that.clearContainerStyles(this.paramId);
                  that.calculateCssWidthRulesForFields("recs_container"+that.paramId, that.paramId);
                  that.resizeFinished.emit(true);
                },1000);
              }

              recalculate()
              {
                console.log('recalculate')

                let container_outer=this.recsContainerrElem.nativeElement;
                container_outer.classList.add("preload");
                this.InitLevels();
                // this.clearContainerStyles(this.paramId);
                const that = this
                setTimeout(()=>{
                  // that.clearContainerStyles(this.paramId);
                  that.calculateCssWidthRulesForFields("recs_container"+that.paramId, that.paramId);
                  that.resizeFinished.emit(true);
                },1000);
              }

              showFullScreenParameter()
              {
                this.fullScreenMode=true;
                this.updateFullScreenFieldsSizes();
                
              }

              updateFullScreenFieldsSizes()
              {
                this.clearContainerStyles(this.paramIdFull);
                setTimeout(()=>{
                      this.calculateCssWidthRulesForFields("recs_container"+this.paramIdFull, this.paramIdFull); // recs_container_full
                          },300);
              }


              InitLevels()
              {
                this.level=0;
                this.levelLengthsInSymbols=new Map();
                if(!this.isList)
                    {
                      
                      this.levelCounter(this.value);
                      this.setLevel=0;
                      this.levelSetter(this.value);

                    }
                    else
                    {
                      
                      this.levelCounter(this.valuesList[0]);
                      this.valuesList.forEach(l=>{
                        this.setLevel=0;
                      this.levelSetter(l);
                      })

                    }
              }

              levelCounter(el:ParameterInstance): boolean
              {
                let haveHorizontal=false;
                el.RecordFields.forEach(l=>{
                  if(l.Type==0)
                  {
                    if(l.Instance.ValueType!=5)
                    {
                      this.level++;
                      
                    }
                    else
                    {
                      if(l.Instance.RecordAxisType==0)
                      {
                        this.levelCounter(l.Instance);
                        haveHorizontal=true;
                      }
                      if(l.Instance.RecordAxisType==1)
                      {
                        let res=this.levelCounter(l.Instance);
                        if(!res)
                        {
                          this.level++;
                          let fieldsCount=0;
                          l.Instance.RecordFields.forEach(c=>{
                            if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                          {
                            fieldsCount++;
                          }})
                          this.level=this.level-fieldsCount;
                          
                        }
                      }
                    }
                  }
                  else
                  {
                    if(l.ListTitle.Instances[0].ValueType!=5)
                    {
                      this.level++;
                      
                    }
                    else
                    {
                      if(l.ListTitle.Instances[0].RecordAxisType==0)
                      {
                        this.levelCounter(l.ListTitle.Instances[0]);
                        haveHorizontal=true;
                      }
                      if(l.ListTitle.Instances[0].RecordAxisType==1)
                      {
                        let res=this.levelCounter(l.ListTitle.Instances[0]);
                        if(!res)
                        {
                          this.level++;
                          let fieldsCount=0;
                          l.ListTitle.Instances[0].RecordFields.forEach(c=>{
                            if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                          {
                            fieldsCount++;
                          }})
                          this.level=this.level-fieldsCount;
                          
                        }
                      }
                    }
                  }
                }
                  
                )
                return haveHorizontal;
              }

              levelSetter(el:ParameterInstance): boolean
              {
                let haveHorizontal=false;
                el.RecordFields.forEach(l=>{
                  if(l.Type==0)
                  {
                    if(l.Instance.ValueType!=5)
                    {

                      
                      let len=0;
                      if(l.Instance.ValueType==0)
                      {
                        len=l.Instance.StringValue!=null && l.Instance.StringValue != '' ? l.Instance.StringValue.length : l.Instance.Name.length+2;
                      }
                      if(l.Instance.ValueType==1 || l.Instance.ValueType==2 || l.Instance.ValueType==7)
                      {
                        len=l.Instance.NumberValue!=null?l.Instance.NumberValue.toString().length:l.Instance.Name.length;
                      }
                      if(l.Instance.ValueType==3)
                      {
                        len=15;
                      }
                      if(l.Instance.ValueType==4)
                      {
                        
                        len=l.Instance.SelectedValue!=null?l.Instance.SelectedValue.Name.length:l.Instance.Name.length;
                      }
                      if(l.Instance.ValueType==6)
                      {
                        len=12;
                      }
                      if(this.levelLengthsInSymbols.has(this.setLevel))
                      {
                        let old=this.levelLengthsInSymbols.get(this.setLevel);
                        old.push(len)
                        this.levelLengthsInSymbols.set(this.setLevel,old);
                      }
                      else
                      {
                        this.levelLengthsInSymbols.set(this.setLevel,[len])
                      }
                      l.Instance.HorizontalLevel=this.setLevel;
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(l.Instance.RecordAxisType==0)
                      {
                        this.levelSetter(l.Instance);
                        haveHorizontal=true;
                      }
                      if(l.Instance.RecordAxisType==1)
                      {
                        let res=this.levelSetter(l.Instance);
                        if(!res)
                        {
                          this.setLevel++;
                          let fieldsCount=0;
                          l.Instance.RecordFields.forEach(c=>{
                            if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                          {
                            fieldsCount++;
                          }})
                          this.setLevel=this.setLevel-fieldsCount;
                          
                        }
                      }
                    }
                  }
                  else
                  {
                    if(l.ListTitle.Instances[0].ValueType!=5)
                    {
                      l.ListTitle.Instances.forEach(l=>{
                        
                        
                        
                        let len=0;
                      if(l.ValueType==0)
                      {
                        len=l.StringValue!=null && l.StringValue != '' ? l.StringValue.length : l.Name.length+2;
                      }
                      if(l.ValueType==1 || l.ValueType==2 || l.ValueType==7)
                      {
                        len=l.NumberValue!=null?l.NumberValue.toString().length:l.Name.length;
                      }
                      if(l.ValueType==3)
                      {
                        len=15;
                      }
                      if(l.ValueType==4)
                      {
                        
                        len=l.SelectedValue!=null?l.SelectedValue.Name.length:l.Name.length;
                      }
                      if(l.ValueType==6)
                      {
                        len=12;
                      }
                      if(this.levelLengthsInSymbols.has(this.setLevel))
                      {
                        let old=this.levelLengthsInSymbols.get(this.setLevel);
                        old.push(len)
                        this.levelLengthsInSymbols.set(this.setLevel,old);
                      }
                      else
                      {
                        this.levelLengthsInSymbols.set(this.setLevel,[len])
                      }
                        l.HorizontalLevel=this.setLevel;})
                      this.setLevel++;
                      
                    }
                    else
                    {
                      if(l.ListTitle.Instances[0].RecordAxisType==0)
                      {
                        let bufferLevel=this.setLevel;
                        l.ListTitle.Instances.forEach(lv=>{
                          this.setLevel=bufferLevel;
                           this.levelSetter(lv)})
                        //this.levelSetter(l.ListTitle.Instances[0]);
                        haveHorizontal=true;
                      }
                      if(l.ListTitle.Instances[0].RecordAxisType==1)
                      {

                        l.ListTitle.Instances.forEach(lv=>{
                          let res=this.levelSetter(lv);
                          if(!res)
                          {
                            this.setLevel++;
                            let fieldsCount=0;
                            lv.RecordFields.forEach(c=>{
                              if(c.Type==0 && c.Instance.ValueType!=5 || c.Type==1 && c.ListTitle.Instances[0].ValueType!=5 || c.Type==0 && c.Instance.ValueType==5 && c.Instance.RecordAxisType==1 || c.Type==1 && c.ListTitle.Instances[0].ValueType==5 && c.ListTitle.Instances[0].RecordAxisType==1 )
                            {
                              fieldsCount++;
                            }})
                            this.setLevel=this.setLevel-fieldsCount;
                            
                          }

                        })

                        
                      }
                    }
                  }
                }
                  
                )
                return haveHorizontal;
              }


              clearContainerStyles(containerId: string="")
              {
                for(let lev=0; lev<this.level; lev++)
                {
                  document.head.childNodes.forEach(child=>{
                    if(child.nodeName=="STYLE")
                    {
                      let childStyleElement=<HTMLStyleElement>child;
                      if(childStyleElement.textContent.includes(".param_container_"+containerId+" .pi"+containerId+"__lv"+lev+"{"))
                      { 
                        child.remove();
                      }
                    }
                  });
                }
              }

              checkElementIsList(element: HTMLElement): boolean
              {
                if(element.classList!=undefined && element.classList!=null && element.classList.contains("order__cnt"))
                  {
                    return(true);
                  }
                  else
                  {
                    return(false);
                  }
              }

              checkElementHasClearButon(element: HTMLElement):boolean
              {
                if(element.classList!=undefined && element.classList!=null && element.classList.contains("inner-clear"))
                  {
                    return(true);
                  }
                  else
                  {
                    return(false);
                  }
              }

              appendStyleRuleToDocument(styleHtml: any)
              {
                let styleElement=document.createElement('style');
                styleElement.innerHTML=styleHtml;
                document.head.append(styleElement);

                console.log('append styles')
                console.log(styleElement)
              }

              calculateCssWidthRulesForFields(containerId: string, parameterId: string)
              {
                console.log('change css rules '+containerId)

                let outerRecordContainer=document.getElementById(containerId);
                let cssRuleForLevel="";
                let cssRulesForRecordContainer=[];
                  for(let lev=0; lev<this.level; lev++)
                  {
                    console.log(lev)
                    let levelClassName="pi"+parameterId+"__lv"+lev;
                    console.log('getting elements by class name '+levelClassName)
                    let currentLevelElements=document.getElementsByClassName(levelClassName);
                    console.log(currentLevelElements)
                    let initialElementsMaxWidth=outerRecordContainer?.getBoundingClientRect().width / this.level + 30;
                    let elementLengthsWithCount = new Map();
                    let isList=false;
                    let hasInnerClearButton=false;
                    if(currentLevelElements!=undefined && currentLevelElements!=null && currentLevelElements.length>0)
                    {
                      for(let i=0; i<currentLevelElements.length; i++)
                          {
                            if(i==0)
                            {
                              let levelChildElements=currentLevelElements[0].childNodes;
                              levelChildElements.forEach(c=>{
                                let child=<HTMLElement>c;
                                if(this.checkElementIsList(child))
                                {
                                  isList=true;
                                }
                                if(this.checkElementHasClearButon(child))
                                {
                                  hasInnerClearButton=true;
                                }
                              })
                            }
                            
                            if(currentLevelElements[i]?.getBoundingClientRect().width>initialElementsMaxWidth)
                            {
                              initialElementsMaxWidth=currentLevelElements[i]?.getBoundingClientRect().width;
                            }
                          }

                          this.levelLengthsInSymbols.forEach((value, key, map)=>{
                            if(key==lev)
                            {
                              value.forEach(v=>{
                                if(elementLengthsWithCount.has(v))
                                {
                                  elementLengthsWithCount.set(v, elementLengthsWithCount.get(v)+1);
                                }
                                else
                                {
                                  elementLengthsWithCount.set(v,1);
                                }
                              })
                            }
                          })

                          if(initialElementsMaxWidth>outerRecordContainer?.getBoundingClientRect().width)
                          {
                            initialElementsMaxWidth=outerRecordContainer?.getBoundingClientRect().width;
                          }
                          
                        let weightedAverageAndMaxOfLengths=NumberHelper.getWeightedAverage(elementLengthsWithCount, 80);
                        let averageToMaxRatio = Math.ceil(weightedAverageAndMaxOfLengths[0])/weightedAverageAndMaxOfLengths[1];
                        let currentElementsWidthForLevel=initialElementsMaxWidth*averageToMaxRatio;
                        let calculatedElementsWidthForLevelInPixels=10*weightedAverageAndMaxOfLengths[0]+18;
                        
                        if(isList)
                        {
                          calculatedElementsWidthForLevelInPixels=calculatedElementsWidthForLevelInPixels+35;
                        }
                        if(hasInnerClearButton)
                        {
                          calculatedElementsWidthForLevelInPixels=calculatedElementsWidthForLevelInPixels+22;
                        }
                        if(calculatedElementsWidthForLevelInPixels<currentElementsWidthForLevel)
                        {
                          currentElementsWidthForLevel=calculatedElementsWidthForLevelInPixels;
                        }

                        cssRuleForLevel=cssRuleForLevel+".param_container_"+parameterId+" .pi"+parameterId+"__lv"+lev+"{ max-width:"+outerRecordContainer.getBoundingClientRect().width+"px; min-width: 140px; width:"+currentElementsWidthForLevel+"px;}   ";
                        cssRulesForRecordContainer.push(_.cloneDeep(cssRuleForLevel));
                        cssRuleForLevel="";
                    }
                  }

                  this.clearContainerStyles(parameterId);

                  cssRulesForRecordContainer.forEach(c=>{this.appendStyleRuleToDocument(c)});
                  outerRecordContainer?.classList.remove('preload');
              }

              UpdateRecord()
              {
                let container_outer=this.recsContainerrElem.nativeElement;
                  container_outer.classList.add("preload");
                  if(this.fullScreenMode)
                  {
                    let outerRecordContainer=document.getElementById("recs_container"+this.paramIdFull); // recs_container_full
                    outerRecordContainer?.classList.add("preload");
                  }
                  setTimeout(()=>{ this.recalculateEv.emit(true);},500)
              
              }

              onValueChange()
              {
                this.valueChange.emit(this.value);
              }

              findFormuls(field:ParameterInstanceContainer):boolean
              {
                let res=false;
                if(field.Type==0 && field.Instance.ValueType==7 || field.Type==1 && field.ListTitle.Instances[0].ValueType==7)
                {
                  res=true;
                }
                if(field.Type==0 && field.Instance.ValueType==5 )
                    {
                      field.Instance.RecordFields.forEach(c=>{
                        let formuls=this.findFormuls(c);
                        if(formuls)
                        {
                          res=true;
                        }
                        
                      })
                      
                    }
                    if(field.Type==1 && field.ListTitle.Instances[0].ValueType==5 )
                    {
                      field.ListTitle.Instances[0].RecordFields.forEach(c=>{
                        let formuls=this.findFormuls(c);
                        if(formuls)
                        {
                          res=true;
                        }
                        
                      })
                      
                    }
                return res;

              }

              onValuesListChange()
              {
                this.valuesListChange.emit(this.valuesList);
              }

              AddValueInList()
              {
                if(this.containerLocation==0)
                {
                  this.AddValueInListParameters() 
                }
                if(this.containerLocation==1)
                {
                  this.AddValueInListStorage() 
                }
              }

              DeleteValueInList(val:any)
              {
                if(this.containerLocation==0)
                {
                  this.DeleteValueInListParameters(val) 
                }
                if(this.containerLocation==1)
                {
                  this.DeleteValueInListStorage(val) 
                }
              }

              AddValueInListStorage()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.containerId;
                
                this.httpServiceStorage.itemAddNameElement(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                      
                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }
                  
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>{
                        
                        this.valuesList.push(_.cloneDeep(el.Instance));
                        this.setLevel=0;
                        this.levelSetter(this.valuesList[this.valuesList.length-1])
                      });
                     
                      this.onValuesListChange();
                      this.recalculate();
                    }

                });  
              }
            
              DeleteValueInListStorage(val:any)
              {
               
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceStorage.itemRemoveNameElement(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    

                    if(data.Code==300)
                    {
                        alert("позиция не найдена");
                        this.deleteParameter.emit(true);
                    }
                    if(data.Code==301)
                    {
                        alert("позиция была удалена");
                        this.deleteParameter.emit(true);
                    }

                    if(data.Code==302)
                    {
                        alert("шаблон не является списочным");
                        return;
                    }

                    if(data.Code==303)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                      if(data.Code==304)
                      {
                        alert("Элемент списка не найден");
                        this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                      }


                    
                      
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

              AddValueInListParameters()
              {   
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.id;
                
                this.httpServiceParameters.parameterInstanceAddElementToList(checker).subscribe((data: ParameterInstanceContainerGiver) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    
                    
                      
                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }
                  
                    if(data.Code==303)
                    {
                        alert("Тайтл не найден");
                    }
                    if(data.Code==304)
                    {
                        alert("Тайтл был удален");
                    }
                    if(data.Code==305)
                    {
                        alert("Параметр не является списком");
                        this.goToMain(null);
                    }
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      data.List.forEach(el=>{
                        
                        this.valuesList.push(_.cloneDeep(el.Instance));
                        this.setLevel=0;
                        this.levelSetter(this.valuesList[this.valuesList.length-1])
                      });
                     
                      this.onValuesListChange();
                    }

                });  
              }

              DeleteValueInListParameters(val:any)
              {
               
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=val.Id;
                
                this.httpServiceParameters.parameterInstanceRemoveElementFromList(checker).subscribe((data: CommonResponse) => {
                    if (data.Code == 100) {
                        this.unLogin(data.Message);
                        return;
                    }
                    

                    if(data.Code==300)
                    {
                        alert("клиент или проект не найден");
                        this.goToMain(null);
                    }
                    if(data.Code==301)
                    {
                        alert("клиент или проект был удален");
                        this.goToMain(null);
                    }

                    if(data.Code==302)
                    {
                        alert("нет доступа к клиенту или проекту");
                        this.goToMain(null);
                    }


                    if(data.Code==303)
                      {
                        
                          console.log("вхождение не найдено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==304)
                      {
                        
                          console.log("вхождение было удалено");
                          this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      }

                      if(data.Code==305)
                      {
                          alert("параметр не является списком и не может быть удален данным методом");
                      }

                      if(data.Code==306)
                      {
                        alert("Тайтл не найден");
                      }

                      if(data.Code==307)
                      {
                        alert("Тайтл был удален");
                      }

                      if(data.Code==308)
                      {
                        alert("Последний элемент списка не может быть удален");
                      }
                  
                    if (data.Code == 500) { console.error(data.Message); return; }
                    
                    if(data.Code==0)
                    {
                      this.valuesList=this.valuesList.filter(c=>c.Id!=val.Id);
                      this.onValuesListChange();
                    }

                });  
              }

              DeleteParameter()
              {
                
                this.deleteParameter.emit(true);
              }

}

/**
 * Created by MosNeuro on 31.01.2018.
 */
import { Injectable } from "@angular/core";
import {
  SelectedTag, TagContainer, AfterTagFormKeyDownAction, ClosestTag, TagWithFrequency,
  TagContainerInner
} from "../models/model.tag";
import { DOMHelper } from "./DOMHelper";
import { Common } from "../models/model.common";
import { SearchSelectHelper } from "./SearchSelectHelper";
import { ExtendedHelper } from "../config/ExtendedHelper";
import * as _ from 'lodash';
@Injectable()
export class TagHelper {

  static TagNotFound(): number {
    return -1;
  }

  public static GetListOfContainer(listNum: number, tagContainer: TagContainerInner): TagWithFrequency[] {
    //0 - top left. 1- bottom left. 2 - right
    if (listNum == 0)
      //return tagContainer.AllSystemTags;
      return tagContainer.OftenUsedForEntityTags;
    if (listNum == 1)
      //return tagContainer.OftenUsedForEntityTags;
      return tagContainer.AllSystemTags;
    if (listNum == 2)
      return tagContainer.OftenUsedByUserTags;
  }

  public static Sort(tagContainer: TagContainerInner, filter: string) {
    tagContainer.AllSystemTags = tagContainer.AllSystemTags.filter(c => ExtendedHelper.ContainsNoCase(c.Name.toLowerCase(), filter));//[];
    tagContainer.OftenUsedByUserTags =  tagContainer.OftenUsedByUserTags.filter(c => ExtendedHelper.ContainsNoCase(c.Name.toLowerCase(), filter));//[];
    tagContainer.OftenUsedForEntityTags =  tagContainer.OftenUsedForEntityTags.filter(c => ExtendedHelper.ContainsNoCase(c.Name.toLowerCase(), filter));//[];
  }

  public static getSearchedText(tagContainer: TagContainerInner, filter: string) {
    this.clearHtmlTags(tagContainer);
    if (filter != "" && filter.length != 0) {
      tagContainer.AllSystemTags.forEach(c => c.Name = SearchSelectHelper.getSearchedText(filter, c.Name));
      tagContainer.OftenUsedByUserTags.forEach(c => c.Name = SearchSelectHelper.getSearchedText(filter, c.Name));
      tagContainer.OftenUsedForEntityTags.forEach(c => c.Name = SearchSelectHelper.getSearchedText(filter, c.Name));
    }
  }

  public static clearHtmlTags(tagContainer: TagContainerInner) {
    tagContainer.AllSystemTags =  _.cloneDeep(tagContainer.AllSystemTagsBack);
    tagContainer.OftenUsedByUserTags =  _.cloneDeep(tagContainer.OftenUsedByUserTagsBack);
    tagContainer.OftenUsedForEntityTags =  _.cloneDeep(tagContainer.OftenUsedForEntityTagsBack);
    // tagContainer.AllSystemTags.forEach(c => c.Name = ExtendedHelper.ReplaceHtmlTags(c.Name));
    // tagContainer.OftenUsedByUserTags.forEach(c => c.Name = ExtendedHelper.ReplaceHtmlTags(c.Name));
    // tagContainer.OftenUsedForEntityTags.forEach(c => c.Name = ExtendedHelper.ReplaceHtmlTags(c.Name));
  }

  public static FindSelectedTagInContainer(tagContainer: TagContainerInner): SelectedTag {
    if (tagContainer != null) {
      let list = TagHelper.GetListOfContainer(0, tagContainer);
      if (list.length > 0)//0
      {
        let index = list.map(c => c.Selected).findIndex(c => c);
        if (index !== -1)
          return new SelectedTag(true, true, index, 0);
      }
      list = TagHelper.GetListOfContainer(1, tagContainer);
      if (list.length > 0)//1
      {
        let index = list.map(c => c.Selected).findIndex(c => c);
        if (index !== -1)
          return new SelectedTag(true, true, index, 1);
      }
      list = TagHelper.GetListOfContainer(2, tagContainer);
      if (list.length > 0)//2
      {
        let index = list.map(c => c.Selected).findIndex(c => c);
        if (index !== -1)
          return new SelectedTag(true, true, index, 2);
      }
      return new SelectedTag(true, false);
    }
    return new SelectedTag(false);
  }

  public static GoFirstInContainer(tagContainer: TagContainerInner, tagFormPlacement: string) {
    let selected = TagHelper.FindSelectedTagInContainer(tagContainer);
    if (selected.NotNull) {
      if (!selected.Found) {
        //ни один не выбран, по идее фокус в поле ввода.
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        if (topLeftList.length > 0 && tagFormPlacement == 'down') {
          topLeftList[0].Selected = true;
          //DOMHelper.BlurElement('searchTagInput', 20);
          TagHelper.FocusOnTag(topLeftList[0].Id, 100);
        }
      }
    } else {

    }
  }

  public static GoDownInContainer(tagContainer: TagContainerInner, tagFormPlacement: string) {
    let selected = TagHelper.FindSelectedTagInContainer(tagContainer);
    if (selected.NotNull) {
      if (!selected.Found) {
        //ни один не выбран, по идее фокус в поле ввода.
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        if (topLeftList.length > 0 && tagFormPlacement == 'down') {
          topLeftList[0].Selected = true;
          //DOMHelper.BlurElement('searchTagInput', 20);
          TagHelper.FocusOnTag(topLeftList[0].Id, 0);
        }


      }
      else if (selected.ListNum == 0) {
        //выбранный в списке левом верхнем
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        if (topLeftList.length == selected.TagIndex + 1) {
          //в этом списке вниз не переместишься, переходим в список левый нижний.
          let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
          if (botLeftList.length > 0) {//если есть куда переместиться
            topLeftList[selected.TagIndex].Selected = false;
            botLeftList[0].Selected = true;
            TagHelper.FocusOnTag(botLeftList[0].Id, 1);
          }
        }
        else {
          //переходим вниз по списку
          topLeftList[selected.TagIndex].Selected = false;
          topLeftList[selected.TagIndex + 1].Selected = true;
          TagHelper.FocusOnTag(topLeftList[selected.TagIndex + 1].Id, 0);
        }

      }
      else if (selected.ListNum == 1) {
        //в нижнем левом списке.
        let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
        if (botLeftList.length > selected.TagIndex + 1)//если есть куда перейти ниже.
        {
          //переходим вниз по списку
          botLeftList[selected.TagIndex].Selected = false;
          botLeftList[selected.TagIndex + 1].Selected = true;
          TagHelper.FocusOnTag(botLeftList[selected.TagIndex + 1].Id, 1);
        }
        else if (tagFormPlacement == 'up') {
          botLeftList[selected.TagIndex].Selected = false;
          //DOMHelper.FocusElement('searchTagInput', 20);
        }

      }
      else if (selected.ListNum == 2) {
        let rightList = TagHelper.GetListOfContainer(2, tagContainer);
        if (rightList.length > selected.TagIndex + 1)//если есть куда перейти ниже.
        {
          //переходим вниз по списку
          rightList[selected.TagIndex].Selected = false;
          rightList[selected.TagIndex + 1].Selected = true;
          //return new AfterTagFormKeyDownAction(true, 'focusTag');
          TagHelper.FocusOnTag(rightList[selected.TagIndex + 1].Id, 2);
        }
        else if (tagFormPlacement == 'up') {
          rightList[selected.TagIndex].Selected = false;
          //DOMHelper.FocusElement('searchTagInput', 20);
        }
      }
    }
  }

  public static GoUpInContainer(tagContainer: TagContainerInner, tagFormPlacement: string) {
    let selected = TagHelper.FindSelectedTagInContainer(tagContainer);
    if (selected.NotNull) {
      if (!selected.Found) {
        //ни один не выбран, по идее фокус в поле ввода.
        let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
        if (botLeftList.length > 0 && tagFormPlacement == 'up') {
          botLeftList[botLeftList.length - 1].Selected = true;
          //DOMHelper.BlurElement('searchTagInput',20);
          TagHelper.FocusOnTag(botLeftList[botLeftList.length - 1].Id, 1);
        }


      }
      else if (selected.ListNum == 0) {
        //выбранный в списке левом верхнем
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        if (selected.TagIndex == 0) {
          if (tagFormPlacement == 'down') {
            //в этом списке вверх не переместишься, переходим в инпут
            topLeftList[selected.TagIndex].Selected = false;
            //DOMHelper.FocusElement('searchTagInput', 20);
          }
        }
        else {
          //переходим вверх по списку
          topLeftList[selected.TagIndex].Selected = false;
          topLeftList[selected.TagIndex - 1].Selected = true;
          TagHelper.FocusOnTag(topLeftList[selected.TagIndex - 1].Id, 0);
        }

      }
      else if (selected.ListNum == 1) {
        //в нижнем левом списке.
        let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
        if (selected.TagIndex > 0)//если есть куда перейти выше.
        {
          //переходим вниз по списку
          botLeftList[selected.TagIndex].Selected = false;
          botLeftList[selected.TagIndex - 1].Selected = true;
          TagHelper.FocusOnTag(botLeftList[selected.TagIndex - 1].Id, 1);
        }
        else {
          let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
          if (topLeftList.length > 0) {
            botLeftList[selected.TagIndex].Selected = false;
            topLeftList[topLeftList.length - 1].Selected = true;
            TagHelper.FocusOnTag(topLeftList[topLeftList.length - 1].Id, 0);
          }

        }


      }
      else if (selected.ListNum == 2) {
        let rightList = TagHelper.GetListOfContainer(2, tagContainer);
        if (selected.TagIndex > 0)//если есть куда перейти выше.
        {
          //переходим ввезр по списку
          rightList[selected.TagIndex].Selected = false;
          rightList[selected.TagIndex - 1].Selected = true;
          TagHelper.FocusOnTag(rightList[selected.TagIndex - 1].Id, 2);
        }
        else {
          //переходим в инпут
          if (tagFormPlacement == 'down') {
            //в этом списке вверх не переместишься, переходим в инпут
            rightList[selected.TagIndex].Selected = false;
            //DOMHelper.FocusElement('searchTagInput', 20);
          }
        }

      }
    }

    //return new AfterTagFormKeyDownAction(false);
  }

  public static GoLeftInContainer(tagContainer: TagContainerInner, tagFormPlacement: string) {
    let selected = TagHelper.FindSelectedTagInContainer(tagContainer);
    //console.log(selected.NotNull + ", " + selected.Found + ", " + selected.ListNum + ", " + tagFormPlacement);
    if (selected.NotNull) {

      if (!selected.Found) {
        let rightList = TagHelper.GetListOfContainer(2, tagContainer);
        //переходим из формы в список, если форма ЛЕВЕЕ
        if (tagFormPlacement == 'left' && rightList.length > 0) {
          rightList[0].Selected = true;
          //DOMHelper.BlurElement('searchTagInput', 20);
          TagHelper.FocusOnTag(rightList[0].Id, 2);
        }
      }
      else if (selected.ListNum == 0 && tagFormPlacement == 'right') {
        //переход в инпут из 0 списка если форма ПРАВЕЕ инпута
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        topLeftList[selected.TagIndex].Selected = false;
        //DOMHelper.FocusElement('searchTagInput', 20);
      }
      else if (selected.ListNum == 1 && tagFormPlacement == 'right') {
        //переход в инпут из 1 списка если форма ПРАВЕЕ инпута
        let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
        botLeftList[selected.TagIndex].Selected = false;
        //DOMHelper.BlurElement('searchTagInput',20);
      }
      else if (selected.ListNum == 2) {
        let rightList = TagHelper.GetListOfContainer(2, tagContainer);
        let closestTag = TagHelper.FindTheClosestTagInContainer(rightList[selected.TagIndex].Id, 2, tagContainer);
        if (closestTag.TagIndex > TagHelper.TagNotFound()) {
          rightList[selected.TagIndex].Selected = false;
          let leftList = TagHelper.GetListOfContainer(closestTag.ListNum, tagContainer);
          leftList[closestTag.TagIndex].Selected = true;
        }
      }
    }
  }

  public static GoRightInContainer(tagContainer: TagContainerInner, tagFormPlacement: string) {
    let selected = TagHelper.FindSelectedTagInContainer(tagContainer);
    if (selected.NotNull) {
      if (!selected.Found) {
        //переходим из инпута в список, если список ПРАВЕЕ
        let leftTopList = TagHelper.GetListOfContainer(0, tagContainer);
        if (tagFormPlacement == 'right' && leftTopList.length > 0) {
          leftTopList[0].Selected = true;
          // DOMHelper.BlurElement('searchTagInput', 20);
          TagHelper.FocusOnTag(leftTopList[0].Id, 0);
        }
      }
      else if (selected.ListNum == 0) {
        let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
        let closestTag = TagHelper.FindTheClosestTagInContainer(topLeftList[selected.TagIndex].Id, 0, tagContainer);
        if (closestTag.TagIndex > TagHelper.TagNotFound()) {
          topLeftList[selected.TagIndex].Selected = false;
          let rightList = TagHelper.GetListOfContainer(2, tagContainer);
          rightList[closestTag.TagIndex].Selected = true;
        }
      }
      else if (selected.ListNum == 1) {
        let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
        let closestTag = TagHelper.FindTheClosestTagInContainer(botLeftList[selected.TagIndex].Id, 1, tagContainer);
        if (closestTag.TagIndex > TagHelper.TagNotFound()) {
          botLeftList[selected.TagIndex].Selected = false;
          let rightList = TagHelper.GetListOfContainer(2, tagContainer);
          rightList[closestTag.TagIndex].Selected = true;
        }
      }
      else if (selected.ListNum == 2 && tagFormPlacement == 'left') {
        //переход в инпут из списка если форма ЛЕВЕЕ
        let rightList = TagHelper.GetListOfContainer(2, tagContainer);
        rightList[selected.TagIndex].Selected = false;
        // DOMHelper.FocusElement('searchTagInput', 20);
      }
    }
  }


  static FocusOnTag(tagId: number, listNum: number) {
    let visible = TagHelper.IsTagVisible(tagId, listNum);
    if (visible == 1 || visible == -1) {//тег ниже или выше списка
      let tagName = '' + listNum + '-' + tagId;
      let tag = document.getElementById(tagName);
      if (tag != null) {
        tag.scrollIntoView();

      }
    }
  }

  static IsTagVisible(tagId: number, listNum: number): number {
    //Функция определеяет видимость тега для пользователя. возвращает 0, если тег видимый. возвращает 1, если тег выше списка.
    // возвращает -1, если тег ниже списка. возвращает 2, если введенные данные ошибочны.
    let tagName = '' + listNum + '-' + tagId;
    let listName = 'tagList' + listNum;
    let tag = document.getElementById(tagName);
    let list = document.getElementById(listName);
    if (tag != null && list != null) {
      let tagCoordinates = tag.getBoundingClientRect();
      let listCoordinates = list.getBoundingClientRect();

      if (tagCoordinates.top < listCoordinates.top) {
        return 1;
      }
      if (tagCoordinates.bottom > listCoordinates.bottom) {
        return -1;
      }
      return 0;
    }
    return 2;
  }

  static FindTheClosestTagInContainer(currentTagId: number, currentList: number, tagContainer: TagContainerInner): ClosestTag {
    let currentTag = document.getElementById(currentList + "-" + currentTagId);
    let currentY = currentTag.getBoundingClientRect().top;
    let maxLength = 10000;
    if (currentList == 2) {//поиск близжайшего тега в левых списках
      let topLeftList = TagHelper.GetListOfContainer(0, tagContainer);
      let botLeftList = TagHelper.GetListOfContainer(1, tagContainer);
      if (topLeftList.length > 0 || botLeftList.length > 0) {
        let closestTag = new ClosestTag(-1);

        for (let i = 0; i < topLeftList.length; i++) {
          let visible = TagHelper.IsTagVisible(topLeftList[i].Id, 0);
          if (visible == 0) {
            let checkingTag = document.getElementById(0 + "-" + topLeftList[i].Id);

            let checkingY = checkingTag.getBoundingClientRect().top;
            let length = Math.abs(currentY - checkingY);

            if (length < maxLength) {
              maxLength = length;
              closestTag.TagIndex = i;
              closestTag.ListNum = 0;
            }
          }
        }

        for (let i = 0; i < botLeftList.length; i++) {
          let visible = TagHelper.IsTagVisible(botLeftList[i].Id, 1);
          if (visible == 0) {
            let checkingTag = document.getElementById(1 + "-" + botLeftList[i].Id);
            let checkingY = checkingTag.getBoundingClientRect().top;
            let length = Math.abs(currentY - checkingY);

            if (length < maxLength) {
              maxLength = length;
              closestTag.TagIndex = i;
              closestTag.ListNum = 1;
            }
          }
        }

        return closestTag;
      }
    }
    else {
      //поиск близжайшего тега в правом списке
      let rightList = TagHelper.GetListOfContainer(2, tagContainer);
      if (rightList.length > 0) {
        let closestTag = new ClosestTag(-1);

        for (let i = 0; i < rightList.length; i++) {
          let visible = TagHelper.IsTagVisible(rightList[i].Id, 2);
          if (visible == 0) {
            let checkingTag = document.getElementById(2 + "-" + rightList[i].Id);
            let checkingY = checkingTag.getBoundingClientRect().top;
            let length = Math.abs(currentY - checkingY);

            if (length < maxLength) {
              maxLength = length;
              closestTag.TagIndex = i;
            }
          }
        }

        return closestTag;
      }
    }

    return new ClosestTag(-1);
  }

  public static CheckTagName(name: string): boolean {
    let badThings: string[] = [" ", "`", "~", "!", "@", "\"", "#", "№", ";", "%", ":", "^", "?", "&", "*", "(", ")", "-", "=", "\\", "|", "/", ",", ".", ">", "<", "'"];
    for (var i = 0; i < badThings.length; i++) {
      if (name.indexOf(badThings[i]) > -1) {
        return false;
      }
    }
    return true;
  }

  public static FindTagWithTheSameName(tagContainer: TagContainerInner, name: string): TagWithFrequency {
    return tagContainer.AllSystemTags.find(c => c.Name.toLowerCase() == name.toLowerCase());
  }

}

import { AdditionToJobOut, JobLight } from '../../model.job';
import { Common } from '../../model.common';

export class JobChangeStatusContainer {
  JobId: number;
  JobText: string;

  Mode: number;
  Message: string;
  Worker: Common;
  Creator: Common;
  CreateTime: Date;
  ToDoDate: Date;

  Additions: AdditionToJobOut[];


  constructor(job: JobLight, mode: number) {
    this.JobId = job.Id;
    this.JobText = job.Message;
    this.Mode = mode;
    this.Message = "";
    this.Worker = job.Worker;
    this.Creator = job.Creator;
    this.ToDoDate = job.ToDoDate;
    this.CreateTime = job.CreateTime;
    this.Additions = job.Additions;
  }
}


import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
/**
 * Created by MosNeuro on 24.11.2016.
 */
import { Injectable } from '@angular/core'; import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';



import {
  ProfileEditAsker,
  WorkerAddAsker, WorkerInSettingsChangeStatusAsker
} from '../models/model.worker'
import { ParentChecker } from "../models/model.parentChecker";
import { Deleting } from "../models/model.deleting";
import { CommonRequest, SingleIdRequest, SingleStringRequest } from '../models/model.http';
import { JobCloseAsker } from '../models/model.job';
import { VersionAsker } from '../config/Version';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceWorker {

  constructor(private http: HttpClient, private param: ServerString) {
  }

  /* getWorkers(obj: Deleting) {
     const body = "'" + JSON.stringify(obj) + "'";
     let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
     return this.http.post(this.param.fullServerString() + 'worker', body, {headers: headers, withCredentials: true})
     //.map((resp: Response)=>resp.json())
       .catch((error: any) => {
         return Observable.throw(error);
       });
   }*/

  /*getWorkers(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/ListGet', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }*/

  getDepartmentsWorkers(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/ListGetWithDepartaments', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  getDepartamentsWorkerWithBlocked(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/ListGetWithDepartamentsAndBlockedWorkers', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }



  getWorkersForJobFilter(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Job/GetWorkers/Filter', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  /*getWorkerWithBlocked(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/ListGetWithBlocked', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }*/

  getVersion(obj: VersionAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Version/Get', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  updateFileSystemController(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'FileSystem/ConvertOldFiles', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  getWorker(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/Profile/Get', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  // redactWorker(obj: WorkerProfile) {
  //     const body = "'" + JSON.stringify(obj) + "'";
  //      let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  //     return this.http.post(this.param.fullServerString()+'workerredact', body, {headers: headers, withCredentials: true})
  //         //.map((resp: Response)=>resp.json())
  //         .catch((error: any) => {return Observable.throw(error);});
  // }
  editProfile(obj: ProfileEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/Profile/Edit', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }



  setStatus(obj: SingleStringRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/StatusSet', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  inSettingsAdd(obj: WorkerAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/settings/add', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  inSettingsListGet(obj: ParentChecker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/settings/listGet', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  GetStatusWorker(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'worker/settings/getStatus', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  inSettingsChangeStatus(obj: WorkerInSettingsChangeStatusAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/settings/changeStatus', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  CheckAndBlockDeleteWorker(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/Remove/CheckAndBlock', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  CloseJobDeleteWorker(obj: JobCloseAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/Remove/CloseJob', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  FinishDeleteWorker(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/Remove/Finish', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  // inSettingsDelete(obj: SingleIdRequest) {
  //   const body = "'" + JSON.stringify(obj) + "'";
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  //   return this.http.post(this.param.fullServerString() + 'Worker/settings/delete', body, {headers: headers, withCredentials: true})
  //   //.map((resp: Response)=>resp.json())
  //     .catch((error: any) => {
  //       return Observable.throw(error);
  //     });
  // }

  inSettingsResetPassword(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/settings/resetPassword', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }
  killSession(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'worker/settings/killSessions', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  setBooker(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Worker/SetBooker', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }







}

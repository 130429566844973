
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MouseDragable]'
})
export class MouseDragable {
  mouseX:number;
  mouseY: number;
  drag: boolean;
  mouseDown: boolean=false;
  ctrlDown: boolean=false;
  constructor(private el: ElementRef) {
    //let title=this.el.nativeElement.getAttribute("title")!=null?this.el.nativeElement.getAttribute("title")+" Зажмите Ctrl + левую кнопка мыши для скролла перетаскиванием":" Зажмите Ctrl + левую кнопка мыши для скролла перетаскиванием";
    //this.el.nativeElement.setAttribute("title", title);
   }



  @HostListener('mousedown', ['$event']) onMouseDown(event:MouseEvent) {
    this.mouseDown=true;
    this.mouseX=event.clientX;
    this.mouseY=event.clientY;
    if(this.ctrlDown)
    {
      this.el.nativeElement.style.userSelect="none";
      this.el.nativeElement.style.cursor="grabbing";
    }
    
}

@HostListener('mouseup', ['$event']) onMouseUp(event:MouseEvent) {
  this.mouseDown=false;
  this.drag=false;
  this.el.nativeElement.style.userSelect="initial";
  this.el.nativeElement.style.cursor="initial"
  
}


@HostListener('document:keydown.control', ['$event']) onCtrlDown(event:KeyboardEvent) {
  this.ctrlDown=true;
  if(!this.mouseDown)
  {
    this.el.nativeElement.style.cursor="grab";
  }
  
  this.el.nativeElement.style.userSelect="none";
  
}

@HostListener('document:keyup.control', ['$event']) onCtrlUp(event:KeyboardEvent) {
this.ctrlDown=false;
this.drag=false;
this.el.nativeElement.style.userSelect="initial";
  this.el.nativeElement.style.cursor="initial"

}


@HostListener('document:mousemove', ['$event']) onMouseMove(event:MouseEvent) {
  
  if(this.ctrlDown && this.mouseDown)
  {
    
    this.drag=true;
    this.el.nativeElement.scrollBy((this.mouseX - event.pageX)/15, (this.mouseY - event.pageY)/15);
    
  }
  
  }

}

import { ISessionState } from './../state/session.state';
import { createSelector } from '@ngrx/store';
import { IAppState } from './../state/app.state';

export const selectSession = (state: IAppState) => state.session

export const selectSessionKey = createSelector(
  selectSession,
  (state: ISessionState) => state.sessionKey
)

export const selectUserId = createSelector(
  selectSession,
  (state: ISessionState) => state.id
)

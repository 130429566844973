import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InfoCircleIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-styles.scss']
})
export class AppListComponent implements OnInit {

    @Input()
    loadedText: string;

    @Input()
    findedText: string = 'По запросу найдено:';

    @Input() 
    massive: any[];

    @Input() 
    loading: boolean = false;

    constructor(
    ) {
    }

    ngOnInit() {
        console.log(this.massive)
    }

}

import { CommonResponse } from "../../model.http";
import { Common } from "../../model.common";

export class FolderSystemSetting {
    AdvancedMode: boolean = false;
    FileSearch: string = '';
}

export class FolderOut extends Common {
    Visible: boolean = true;
    ChildsVisible: boolean = false;
    isSelected: boolean = false;
    Files: FileOut[];//файлы
    Folders: FolderOut[];//внутренние папки
    Editable: boolean;//Можно удалять папку/добавлять дочерние папки
}
export class FileOut extends Common {
    Visible: boolean = true;
    isSelected: boolean = false;
    Tags: TagSignable[];
    GroupsWithAccess: number[];
    Signed: boolean;//Подписан ли хотя бы одним тегом
    Editable: boolean;//удаление/замена разрешена
}

export class TagSignable extends Common {
    Signed: boolean;//Подписан ли хотя бы одним тегом
    SignedBy: string;//кем подписан
    SignedAt: Date;//когда подписан
}

export class FileInfoGiver extends CommonResponse {
    Info: FileInfoContainer;
}
export class FileInfoContainer extends Common {
    Size: string;//размер файла
    Creator: string;//создатель
    CreateTime: Date;//когда создан
    FileChanges: FileChangeOut[];//изменения файла
    TagChanges: FileChangeOut[];//изменения тегов файла
    GroupChanges: FileChangeOut[];//изменения групп файла
    Tags: TagSignable[];
}

export class FileChangeOut {
    Changer: string;//кто
    Time: Date;//когда
    Text: string;//что менял
}

export class FileGiver extends CommonResponse {
    File: FileOut;
}

export class SingleStringGiver extends CommonResponse {
    Value: string;
}

export class ReplaceOldFile {
    Files: FileList;
    FileId: number;
    OldName: string;
    KeepTags: boolean;//сохранять ли теги
    Type: number;//какое имя будет иметь файл тип FileReplaceType
    constructor() {
        this.KeepTags = true;
        this.Type = 0;
    }
}

export class FileAccessUsersSelectableVisible extends Common {
    Selected: boolean;
    Visible: boolean;
    TextToShow: string;

    constructor(id: number, name: string, sel: boolean, vis: boolean) {
      super(id, name);
      this.Selected = sel;
      this.Visible = vis;
      this.TextToShow = name;
    }

  }
import {AddressInputComponentInner} from "../../Inner/InputContainer/model.AddressInputComponentInner";
import {ExtendedHelper} from "../../../../config/ExtendedHelper";

export class AddressInputComponentIn {
  AddressNodeId: number;
  Level: number;
  Value: string;

  constructor(c:AddressInputComponentInner)
  {
    this.AddressNodeId = c.AddressNodeId;
    this.Level = c.Level;
    this.Value = ExtendedHelper.ReplaceBadThings(c.Value);

    if(this.Value[this.Value.length-1]!=undefined && this.Value[this.Value.length-1].search(/\s/)>-1)
    {
      this.Value=this.Value.substring(0,this.Value.length-2);
    }

  }
}


import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 25.11.2016.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';



import {Stage, StageBusinessLogic, StageNewFromAngular} from "../models/model.stage";
import {ParentChecker} from "../models/model.parentChecker";
import {Deleting} from "../models/model.deleting";
import {IdToId} from "../models/model.idtoid";
import { StageBusinessLogicSet} from "../models/model.stageSet";
import {CommonRequest, RenameAsker, SingleIdRequest, SingleStringRequest} from "../models/model.http";
import {StageAddAsker} from "../models/model.Stage/Out/model.StageAddAsker";
import {StageEditAsker} from "../models/model.Stage/Out/model.StageEditAsker";
import {StageMoveAsker} from "../models/model.Stage/Out/model.StageMoveAsker";
import {StageDeleteAsker} from "../models/model.Stage/Out/model.StageDeleteAsker";
import { MeasureUnitEditAsker, StorageItemAddAsker,   StorageItemMovementAddAsker,   StorageItemSearchAsker, StorageResponsibleEditAsker, StoragesForMovementCreationAsker } from '../models/model.storage';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceStorage {

  route: string = "Storage/";

  route2: string = "StorageV2/";

  constructor(private http: HttpClient, private param: ServerString) {
  }


 /* addItem(obj: StorageItemAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'AddItem ', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }



  addMovement(obj: StorageItemMovementAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'AddMovement', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getItemInfo(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'GetItemInfo', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getItemList(obj: StorageItemSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'GetItemList', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getProjectList(obj: SingleStringRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'GetProjectList', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getBillList(obj: SingleStringRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'GetBillList', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  releaseReservedItems(obj: StorageItemReserveReleaseAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerStringAdditional() + this.route + 'ReleaseReservedItems', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }
  


  uploadBillFile(FormData: FormData) {
    return this.http
        .post(this.param.fullServerStringAdditional() + this.route + 'UploadBillFile', FormData).pipe(
        catchError((error: any) => {
            return observableThrowError(error);
        }));
}


getGroups(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'GetGroups', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


deleteGroup(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'RemoveGroup', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


deleteSubgroup(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'RemoveSubgroup', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}




editGroup(obj: StorageItemGroupEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'EditGroup', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


editSubgroup(obj: StorageItemSubgroupEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'EditSubgroup', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}

editItem(obj: StorageItemEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route + 'EditItem', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



*/
















/***************************************НОВЫЙ СКЛАД 31.05.22*********************************************/


/********************СКЛАДЫ*********************/


storageGetList(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


storageGetListForFilter(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/GetListForFilter', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}

storageAdd(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}

storageRename(obj: RenameAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/Rename', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


storageEditResponsibleWorker(obj: StorageResponsibleEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/EditResponsibleWorker', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


storageRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Storage/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}






/*******************************ПОЗИЦИИ*************************************/

itemGetNameContainer(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/GetNameContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



itemAdd(obj: StorageItemAddAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemGetTable(obj: StorageItemSearchAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/GetTable', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemGetMeasureUnits(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/GetMeasureUnits', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}

itemGetInfo(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/GetInfo', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemEditMeasureUnit(obj: MeasureUnitEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/EditMeasureUnit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



itemEditNote(obj: RenameAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/EditNote', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemAddNameElement(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/AddNameElement', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}




itemRemoveNameElement(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/RemoveNameElement', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}




itemAddFile(FormData: FormData) {
  return this.http
      .post(this.param.fullServerStringAdditional() + this.route2 + 'Item/AddFile', FormData).pipe(
      catchError((error: any) => {
          return observableThrowError(error);
      }));
}

itemRemoveFile(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/RemoveFile', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


itemGetSingle(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Item/GetSingle', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


movementGetStoragesForCreation (obj: StoragesForMovementCreationAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/GetStoragesForCreation', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}

movementGetProjectsForCreation (obj: SingleStringRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/GetProjectsForCreation', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



movementGetBillsForCreation (obj: SingleStringRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/GetBillsForCreation', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


movementAdd (obj: StorageItemMovementAddAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


movementGetInfo (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/GetInfo', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



movementAddFile(FormData: FormData) {
  return this.http
      .post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/AddFile', FormData).pipe(
      catchError((error: any) => {
          return observableThrowError(error);
      }));
}


movementRemoveFile (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/RemoveFile', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}


movementAppendNote (obj: RenameAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional() + this.route2 + 'Movement/AppendNote', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {
      return observableThrowError(error);
    }));
}



}

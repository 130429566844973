import { GetSession, LoadSession } from './../store/actions/session.action';
import { ISessionState } from './../store/state/session.state';
import {throwError as observableThrowError, Observable, Subscriber, Subscription, BehaviorSubject} from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';



import { select, Store } from '@ngrx/store';
import { selectSession } from '../store/selectors/session.selectors';
import { Address_LangSet, Analytic_LangSet, Autojobs_LangSet, C1_LangSet, Calendar_LangSet, Chats_LangSet, Common_LangSet, Contacts_LangSet, Doubles_LangSet, Files_LangSet, Groups_LangSet, Jobs_LangSet, LangSetLoginAsker, LangSets_Types, LanguageSetCommon, LanguageSetCommonGiver, Login_LangSet, Login_LangSetGiver, Parameters_LangSet, Profile_LangSet, Roles_LangSet, Search_LangSet, Settings_LangSet, Stages_LangSet, Storage_LangSet, Tags_LangSet } from '../models/model.language';
import { LocalStorageService } from 'angular-2-local-storage';
import { selectUserId, selectSessionKey } from '../store/selectors/session.selectors';
import { CommonRequest, SingleStringGiver } from '../models/model.http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerString } from '../config/serverstring';
import * as _ from 'lodash';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { DateTimeGiver } from '../models/model.Address/Inner/model.AddressChangeTime';
import { SessionService } from './session.service';


@Injectable({
    providedIn: 'root'
})
export class LanguageService  {
    private isInit = false;
    
    private CommonLanguage: BehaviorSubject<Common_LangSet> = new BehaviorSubject(new Common_LangSet());
    commonLanguage$: Observable<Common_LangSet> = this.CommonLanguage.asObservable();

    private SearchLanguage: BehaviorSubject<Search_LangSet> = new BehaviorSubject(new Search_LangSet());
    searchLanguage$: Observable<Search_LangSet> = this.SearchLanguage.asObservable();

    private JobsLanguage: BehaviorSubject<Jobs_LangSet> = new BehaviorSubject(new Jobs_LangSet());
    jobsLanguage$: Observable<Jobs_LangSet> = this.JobsLanguage.asObservable();

    private ContactsLanguage: BehaviorSubject<Contacts_LangSet> = new BehaviorSubject(new Contacts_LangSet());
    contactsLanguage$: Observable<Contacts_LangSet> = this.ContactsLanguage.asObservable();

    private ChatsLanguage: BehaviorSubject<Chats_LangSet> = new BehaviorSubject(new Chats_LangSet());
    chatsLanguage$: Observable<Chats_LangSet> = this.ChatsLanguage.asObservable();

    private CalendarLanguage: BehaviorSubject<Calendar_LangSet> = new BehaviorSubject(new Calendar_LangSet());
    calendarLanguage$: Observable<Calendar_LangSet> = this.CalendarLanguage.asObservable();

    private AnalyticLanguage: BehaviorSubject<Analytic_LangSet> = new BehaviorSubject(new Analytic_LangSet());
    analyticLanguage$: Observable<Analytic_LangSet> = this.AnalyticLanguage.asObservable();

    private ParametersLanguage: BehaviorSubject<Parameters_LangSet> = new BehaviorSubject(new Parameters_LangSet());
    parametersLanguage$: Observable<Parameters_LangSet> = this.ParametersLanguage.asObservable();

    private StagesLanguage: BehaviorSubject<Stages_LangSet> = new BehaviorSubject(new Stages_LangSet());
    stagesLanguage$: Observable<Stages_LangSet> = this.StagesLanguage.asObservable();

    private AutojobsLanguage: BehaviorSubject<Autojobs_LangSet> = new BehaviorSubject(new Autojobs_LangSet());
    autojobsLanguage$: Observable<Autojobs_LangSet> = this.AutojobsLanguage.asObservable();

    private AddressLanguage: BehaviorSubject<Address_LangSet> = new BehaviorSubject(new Address_LangSet());
    addressLanguage$: Observable<Address_LangSet> = this.AddressLanguage.asObservable();

    private GroupsLanguage: BehaviorSubject<Groups_LangSet> = new BehaviorSubject(new Groups_LangSet());
    groupsLanguage$: Observable<Groups_LangSet> = this.GroupsLanguage.asObservable();

    private ProfileLanguage: BehaviorSubject<Profile_LangSet> = new BehaviorSubject(new Profile_LangSet());
    profileLanguage$: Observable<Profile_LangSet> = this.ProfileLanguage.asObservable();

    private FilesLanguage: BehaviorSubject<Files_LangSet> = new BehaviorSubject(new Files_LangSet());
    filesLanguage$: Observable<Files_LangSet> = this.FilesLanguage.asObservable();

    private C1Language: BehaviorSubject<C1_LangSet> = new BehaviorSubject(new C1_LangSet());
    c1Language$: Observable<C1_LangSet> = this.C1Language.asObservable();

    private DoublesLanguage: BehaviorSubject<Doubles_LangSet> = new BehaviorSubject(new Doubles_LangSet());
    doublesLanguage$: Observable<Doubles_LangSet> = this.DoublesLanguage.asObservable();

    private TagsLanguage: BehaviorSubject<Tags_LangSet> = new BehaviorSubject(new Tags_LangSet());
    tagsLanguage$: Observable<Tags_LangSet> = this.TagsLanguage.asObservable();

    private RolesLanguage: BehaviorSubject<Roles_LangSet> = new BehaviorSubject(new Roles_LangSet());
    rolesLanguage$: Observable<Roles_LangSet> = this.RolesLanguage.asObservable();

    private LoginLanguage: BehaviorSubject<Login_LangSet> = new BehaviorSubject(new Login_LangSet());
    loginLanguage$: Observable<Login_LangSet> = this.LoginLanguage.asObservable();

    private SettingsLanguage: BehaviorSubject<Settings_LangSet> = new BehaviorSubject(new Settings_LangSet());
    settingsLanguage$: Observable<Settings_LangSet> = this.SettingsLanguage.asObservable();

    private StorageLanguage: BehaviorSubject<Storage_LangSet> = new BehaviorSubject(new Storage_LangSet());
    storageLanguage$: Observable<Storage_LangSet> = this.StorageLanguage.asObservable();

    UserId: number = 0;
    SessionKey: string;
    SessionChecked: boolean=false;
    isFirstCheck: boolean = false;
    currentLanguage: number=0;
   // userId$: Observable<number> = this.store$.pipe(select(selectUserId));
    //sessionKey$: Observable<string> = this.store$.pipe(select(selectSessionKey));
    s_ui: Subscription = null;
    s_sk: Subscription = null;
    s_sc: Subscription = null;

    s_ru: Subscription = null;
    s_lan: Subscription = null;



    constructor(private localStorageService: LocalStorageService, private store$: Store, private http: HttpClient, private param: ServerString, private sessionService: SessionService) { 
       this.s_ui=sessionService.userId$.subscribe(id=>this.UserId=id);
       this.s_sk=sessionService.sessionKey$.subscribe(key=>this.SessionKey=key);
       /*this.s_sc=sessionService.isSessionChecked$.pipe(take(2)).subscribe(flag=>{
         if(flag && !this.SessionChecked)
         {
           this.SessionChecked=true;
           this.InitLanguages();
         }
       });*/

       this.s_ru=sessionService.refreshUser$.subscribe(flag=>{
      this.RefreshLanguages();
      });

      this.s_lan=sessionService.currentWorker$.subscribe(worker=>{
        if(worker!=undefined && worker!=null && worker.Language!=undefined && worker.Language!=null)
        {
          this.currentLanguage=this.localStorageService.get("Current_LangSet_Type");
        if(this.currentLanguage!=worker.Language)
        {
          this.currentLanguage=worker.Language;
          this.localStorageService.set("Current_LangSet_Type", this.currentLanguage)
          this.RefreshLanguages();
        }
      }});
        

    }


   

    setCommonLanguage(newValue: Common_LangSet) {
      this.CommonLanguage.next(newValue);
    }

    setSearchLanguage(newValue: Search_LangSet) {
      this.SearchLanguage.next(newValue);
    }

    setJobsLanguage(newValue: Jobs_LangSet) {
      this.JobsLanguage.next(newValue);
    }

    setContactsLanguage(newValue: Contacts_LangSet) {
      this.ContactsLanguage.next(newValue);
    }

    setChatsLanguage(newValue: Chats_LangSet) {
      this.ChatsLanguage.next(newValue);
    }

    setCalendarLanguage(newValue: Calendar_LangSet) {
      this.CalendarLanguage.next(newValue);
    }
    
    setAnalyticLanguage(newValue: Analytic_LangSet) {
      this.AnalyticLanguage.next(newValue);
    }

    setParametersLanguage(newValue: Parameters_LangSet) {
      this.ParametersLanguage.next(newValue);
    }

    setStagesLanguage(newValue: Stages_LangSet) {
      this.StagesLanguage.next(newValue);
    }

    setAutojobsLanguage(newValue: Autojobs_LangSet) {
      this.AutojobsLanguage.next(newValue);
    }

    setAddressLanguage(newValue: Address_LangSet) {
      this.AddressLanguage.next(newValue);
    }

    setGroupsLanguage(newValue: Groups_LangSet) {
      this.GroupsLanguage.next(newValue);
    }

    setProfileLanguage(newValue: Profile_LangSet) {
      this.ProfileLanguage.next(newValue);
    }

    setFilesLanguage(newValue: Files_LangSet) {
      this.FilesLanguage.next(newValue);
    }

    setC1Language(newValue: C1_LangSet) {
      this.C1Language.next(newValue);
    }

    setDoublesLanguage(newValue: Doubles_LangSet) {
      this.DoublesLanguage.next(newValue);
    }

    setTagsLanguage(newValue: Tags_LangSet) {
      this.TagsLanguage.next(newValue);
    }

    setRolesLanguage(newValue: Roles_LangSet) {
      this.RolesLanguage.next(newValue);
    }

    setLoginLanguage(newValue: Login_LangSet) {
      this.LoginLanguage.next(newValue);
    }


    setSettingsLanguage(newValue: Settings_LangSet) {
      this.SettingsLanguage.next(newValue);
    }

    setStorageLanguage(newValue: Storage_LangSet) {
      this.StorageLanguage.next(newValue);
    }



    public RefreshLanguagesInStorage()
    {
      this.InitLanguages();
    }


    public RefreshLoginLanguage(lang: number=0)
    {
      let checker = new LangSetLoginAsker();
      this.currentLanguage=lang;
      checker.Language=lang;
      this.LoadLoginLanguage(checker).subscribe((data: Login_LangSetGiver) => {
        if (data.Code == 100) {
          //this.unLogin();
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        
        
            this.setLoginLanguage(data.Set);
           
        this.localStorageService.set('Login_LangSet', JSON.stringify(data.Set))
        this.localStorageService.set('Login_LangSet_Type', JSON.stringify(lang))
        this.localStorageService.set('Login_LangSet_Version', JSON.stringify(data.Set.ChangeTime))
      });






    }


     InitLanguages()
    {
        
        Object.keys(LangSets_Types).forEach(key=>{
          if(this.UserId!=0 && key!="Login")
          {this.InitLanguage(LangSets_Types[key].Name, LangSets_Types[key].Id);}
          if(key=="Login")
          {this.InitLoginLanguage();}
        });
        
        
    }


     RefreshLanguages()
    {
      Object.keys(LangSets_Types).forEach(key=>{
        this.RefreshLanguageFromServer(LangSets_Types[key].Name, LangSets_Types[key].Id);
        
      });
    }

    private InitLanguage(prefix: string="Common", type: number=0)
    {
        let localSt = this.localStorageService.isSupported;
        if (localSt) {

          let languageVersion = JSON.parse(this.localStorageService.get(prefix+"_LangSet_Version"));
          
          let languageType = JSON.parse(this.localStorageService.get(prefix+"_LangSet_Type"));
        
            let checker = new CommonRequest(this.UserId, this.SessionKey);
            this.GetLanguageVersion(checker, type).subscribe((data: DateTimeGiver) => {
            if (data.Code == 100) {
              //this.unLogin();
              return;
            }
            if (data.Code == 500) { console.error(data.Message); return; }

            if(data.Code==0)
            {
              if(languageVersion==undefined || languageVersion==null || languageVersion=="")
              {
                this.localStorageService.set(prefix+'_LangSet_Version', JSON.stringify(data.DateTime))
                
                this.GetLanguageFromServer(prefix, type);
              }
              else
              {
              
                if(data.DateTime.toString()==languageVersion)
                {
                  if(languageType!=undefined && languageType!=null && languageType==this.currentLanguage)
                  {

                    
                    this.GetLanguageFromStorage(prefix, type);
                  }
                  else
                  {
                   
                    this.GetLanguageFromServer(prefix, type);
                  }
                  
                }
                else
                {
                  this.localStorageService.set(prefix+'_LangSet_Version', JSON.stringify(data.DateTime))
                  
                  this.GetLanguageFromServer(prefix, type);
                }
              }
            }
  
            
          });
          
        }
        else {
          //////////если нет доступа к локал сорэдж
          
          this.GetLanguageFromServer(prefix, type);
        }
    }

    private GetLanguageFromStorage(prefix: string="Common", type: number=0)
    {

      console.log("fromstorage")
      switch(type)
      {
        case 0: 
        {
          //this.CommonLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setCommonLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }
        case 1: 
        {
          //this.SearchLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setSearchLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 2: 
        {
          //this.JobsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setJobsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 3: 
        {
          //this.ContactsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setContactsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 4: 
        {
          //this.ChatsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setChatsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 5: 
        {
          //this.CalendarLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setCalendarLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 6: 
        {
         //this.AnalyticLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setAnalyticLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 7: 
        {
          //this.ParametersLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setParametersLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 8: 
        {
          //this.StagesLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setStagesLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 9: 
        {
          //this.AutojobsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setAutojobsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 10: 
        {
          //this.AddressLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setAddressLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 11: 
        {
          //this.GroupsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setGroupsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 12: 
        {
          //this.ProfileLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setProfileLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 13: 
        {
         // this.FilesLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setFilesLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 14: 
        {
          //this.C1Language=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setC1Language(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 15: 
        {
          //this.DoublesLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setDoublesLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 16: 
        {
          //this.TagsLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setTagsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 17: 
        {
          //this.RolesLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setRolesLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 18: 
        {
          //this.LoginLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setLoginLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 19: 
        {
          //this.LoginLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setSettingsLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }

        case 20: 
        {
          //this.LoginLanguage=JSON.parse(this.localStorageService.get(prefix+'_LangSet'));
          this.setStorageLanguage(JSON.parse(this.localStorageService.get(prefix+'_LangSet')));
          break;
        }
      }

            
    }

    private GetLanguageFromServer(prefix: string="Common", type: number=0)
    {
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.LoadLanguage(checker, type).subscribe((data: any) => {
          if (data.Code == 100) {
            //this.unLogin();
            return;
          }
          if (data.Code == 500) { console.error(data.Message); return; }

          
          switch(type)
          {
            case 0: 
            {

              this.setCommonLanguage(data.Set);
              break;
            }
            case 1: 
            {
              this.setSearchLanguage(data.Set);
              break;
            }

            case 2: 
            {
              this.setJobsLanguage(data.Set);
              break;
            }

            case 3: 
            {
              this.setContactsLanguage(data.Set);
              break;
            }

            case 4: 
            {
              this.setChatsLanguage(data.Set);
              break;
            }

            case 5: 
            {
              this.setCalendarLanguage(data.Set);
              break;
            }

            case 6: 
            {
              this.setAnalyticLanguage(data.Set);
              break;
            }

            case 7: 
            {
              this.setParametersLanguage(data.Set);
              break;
            }

            case 8: 
            {
              this.setStagesLanguage(data.Set);
              break;
            }

            case 9: 
            {
              this.setAutojobsLanguage(data.Set);
              break;
            }

            case 10: 
            {
              this.setAddressLanguage(data.Set);
              break;
            }

            case 11: 
            {
              this.setGroupsLanguage(data.Set);
              break;
            }

            case 12: 
            {
              this.setProfileLanguage(data.Set);
              break;
            }

            case 13: 
            {
              this.setFilesLanguage(data.Set);
              break;
            }

            case 14: 
            {
              this.setC1Language(data.Set);
              break;
            }

            case 15: 
            {
              this.setDoublesLanguage(data.Set);
              break;
            }

            case 16: 
            {
              this.setTagsLanguage(data.Set);
              break;
            }

            case 17: 
            {
              this.setRolesLanguage(data.Set);
              break;
            }

            case 18: 
            {
              this.setLoginLanguage(data.Set);
              break;
            }

            case 19: 
            {
              this.setSettingsLanguage(data.Set);
              break;
            }

            case 20: 
            {
              this.setStorageLanguage(data.Set);
              break;
            }
          } 
          this.localStorageService.set(prefix+'_LangSet', JSON.stringify(data.Set))

          this.localStorageService.set(prefix+'_LangSet_Type', JSON.stringify(this.currentLanguage))
        });
    }

    private LoadLanguage(obj: CommonRequest, type: number=0)
    {
      let routeLink="LanguageSet/General/Get";
        switch(type)
        {
          case 0: 
          {
            routeLink="LanguageSet/General/Get";
            break;
          }
          case 1: 
          {
            routeLink="LanguageSet/SearchArea/Get";
            break;
          }

          case 2: 
          {
            routeLink="LanguageSet/Job/Get";
            break;
          }

          case 3: 
          {
            routeLink="LanguageSet/Person/Get";
            break;
          }

          case 4: 
          {
            routeLink="LanguageSet/Chat/Get";
            break;
          }

          case 5: 
          {
            routeLink="LanguageSet/Calendar/Get";
            break;
          }

          case 6: 
          {
            routeLink="LanguageSet/Analytics/Get";
            break;
          }

          case 7: 
          {
            routeLink="LanguageSet/Parameter/Get";
            break;
          }

          case 8: 
          {
            routeLink="LanguageSet/Stage/Get";
            break;
          }

          case 9: 
          {
            routeLink="LanguageSet/Autojob/Get";
            break;
          }

          case 10: 
          {
            routeLink="LanguageSet/Address/Get";
            break;
          }

          case 11: 
          {
            routeLink="LanguageSet/AccessGroup/Get";
            break;
          }

          case 12: 
          {
            routeLink="LanguageSet/Profile/Get";
            break;
          }

          case 13: 
          {
            routeLink="LanguageSet/File/Get";
            break;
          }

          case 14: 
          {
            routeLink="LanguageSet/1C/Get";
            break;
          }

          case 15: 
          {
            routeLink="LanguageSet/Duplicates/Get";
            break;
          }

          case 16: 
          {
            routeLink="LanguageSet/Tag/Get";
            break;
          }

          case 17: 
          {
            routeLink="LanguageSet/Role/Get";
            break;
          }

          case 18: 
          {
            routeLink="LanguageSet/Login/Get";
            break;
          }

          case 19: 
          {
            routeLink="LanguageSet/SettingsNavigation/Get";
            break;
          }

          case 20: 
          {
            routeLink="LanguageSet/Storage/Get";
            break;
          }
        }
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() +  routeLink, body, {headers: headers, withCredentials: true}).pipe(
        //map((resp:Response)=>resp.json())
          catchError((error: any) => {
            return observableThrowError(error);
          }));
        
    }

    private GetLanguageVersion(obj: CommonRequest, type: number=0)
    {
        let routeLink="LanguageSet/General/LastChangeTime";
        switch(type)
        {
          case 0: 
          {
            routeLink="LanguageSet/General/LastChangeTime";
            break;
          }
          case 1: 
          {
            routeLink="LanguageSet/SearchArea/LastChangeTime";
            break;
          }

          case 2: 
          {
            routeLink="LanguageSet/Job/LastChangeTime";
            break;
          }

          case 3: 
          {
            routeLink="LanguageSet/Person/LastChangeTime";
            break;
          }

          case 4: 
          {
            routeLink="LanguageSet/Chat/LastChangeTime";
            break;
          }

          case 5: 
          {
            routeLink="LanguageSet/Calendar/LastChangeTime";
            break;
          }

          case 6: 
          {
            routeLink="LanguageSet/Analytics/LastChangeTime";
            break;
          }

          case 7: 
          {
            routeLink="LanguageSet/Parameter/LastChangeTime";
            break;
          }

          case 8: 
          {
            routeLink="LanguageSet/Stage/LastChangeTime";
            break;
          }

          case 9: 
          {
            routeLink="LanguageSet/Autojob/LastChangeTime";
            break;
          }

          case 10: 
          {
            routeLink="LanguageSet/Address/LastChangeTime";
            break;
          }

          case 11: 
          {
            routeLink="LanguageSet/AccessGroup/LastChangeTime";
            break;
          }

          case 12: 
          {
            routeLink="LanguageSet/Profile/LastChangeTime";
            break;
          }

          case 13: 
          {
            routeLink="LanguageSet/File/LastChangeTime";
            break;
          }

          case 14: 
          {
            routeLink="LanguageSet/1C/LastChangeTime";
            break;
          }

          case 15: 
          {
            routeLink="LanguageSet/Duplicates/LastChangeTime";
            break;
          }

          case 16: 
          {
            routeLink="LanguageSet/Tag/LastChangeTime";
            break;
          }

          case 17: 
          {
            routeLink="LanguageSet/Role/LastChangeTime";
            break;
          }

          case 18: 
          {
            routeLink="LanguageSet/Login/LastChangeTime";
            break;
          }

          case 19: 
          {
            routeLink="LanguageSet/SettingsNavigation/LastChangeTime";
            break;
          }
          case 20: 
          {
            routeLink="LanguageSet/Storage/LastChangeTime";
            break;
          }
        }
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() +  routeLink, body, {headers: headers, withCredentials: true}).pipe(
        //map((resp:Response)=>resp.json())
          catchError((error: any) => {
            return observableThrowError(error);
          }));
        
    }

    private RefreshLanguageFromServer(prefix: string="Common", type: number=0)
    {
        let checker = new CommonRequest(this.UserId, this.SessionKey);
        this.LoadLanguage(checker, type).subscribe((data: any) => {
          if (data.Code == 100) {
            //this.unLogin();
            return;
          }
          if (data.Code == 500) { console.error(data.Message); return; }

          
          switch(type)
          {
            case 0: 
            {

              this.setCommonLanguage(data.Set);
              break;
            }
            case 1: 
            {
              this.setSearchLanguage(data.Set);
              break;
            }

            case 2: 
            {
              this.setJobsLanguage(data.Set);
              break;
            }

            case 3: 
            {
              this.setContactsLanguage(data.Set);
              break;
            }

            case 4: 
            {
              this.setChatsLanguage(data.Set);
              break;
            }

            case 5: 
            {
              this.setCalendarLanguage(data.Set);
              break;
            }

            case 6: 
            {
              this.setAnalyticLanguage(data.Set);
              break;
            }

            case 7: 
            {
              this.setParametersLanguage(data.Set);
              break;
            }

            case 8: 
            {
              this.setStagesLanguage(data.Set);
              break;
            }

            case 9: 
            {
              this.setAutojobsLanguage(data.Set);
              break;
            }

            case 10: 
            {
              this.setAddressLanguage(data.Set);
              break;
            }

            case 11: 
            {
              this.setGroupsLanguage(data.Set);
              break;
            }

            case 12: 
            {
              this.setProfileLanguage(data.Set);
              break;
            }

            case 13: 
            {
              this.setFilesLanguage(data.Set);
              break;
            }

            case 14: 
            {
              this.setC1Language(data.Set);
              break;
            }

            case 15: 
            {
              this.setDoublesLanguage(data.Set);
              break;
            }

            case 16: 
            {
              this.setTagsLanguage(data.Set);
              break;
            }

            case 17: 
            {
              this.setRolesLanguage(data.Set);
              break;
            }

            case 18: 
            {
              this.setLoginLanguage(data.Set);
              break;
            }
            case 19: 
            {
              this.setSettingsLanguage(data.Set);
              break;
            }
            case 20: 
            {
              this.setStorageLanguage(data.Set);
              break;
            }
          } 
          this.localStorageService.set(prefix+'_LangSet', JSON.stringify(data.Set))
          this.localStorageService.set(prefix+'_LangSet_Type', JSON.stringify(this.currentLanguage))
        });
    }

    


    private InitLoginLanguage()
    {

          let localSt = this.localStorageService.isSupported;
          if (localSt) {
  
            let languageVersion = JSON.parse(this.localStorageService.get("Login_LangSet_Version"));
            let languageType = JSON.parse(this.localStorageService.get("Login_LangSet_Type"));
              let checker = new LangSetLoginAsker();
              checker.Language=this.currentLanguage;
              this.GetLoginLanguageVersion(checker).subscribe((data: DateTimeGiver) => {
              if (data.Code == 100) {
                //this.unLogin();
                return;
              }
              if (data.Code == 500) { console.error(data.Message); return; }
  
              if(data.Code==0)
              {
                if(languageVersion==undefined || languageVersion==null || languageVersion=="")
                {
                  this.localStorageService.set('Login_LangSet_Version', JSON.stringify(data.DateTime))
                  this.GetLoginLanguageFromServer();
                }
                else
                {
                  if(data.DateTime.toString()==languageVersion)
                  {

                    if(languageType!=undefined && languageType!=null && languageType==this.currentLanguage)
                  {

                    
                    this.GetLoginLanguageFromStorage();
                  }
                  else
                  {
                   
                    this.GetLoginLanguageFromServer();
                  }

                    //this.GetLoginLanguageFromStorage();
                  }
                  else
                  {
                    this.localStorageService.set('Login_LangSet_Version', JSON.stringify(data.DateTime))
                    this.GetLoginLanguageFromServer();
                  }
                }
              }
    
              
            });
            
          }
          else {
            //////////если нет доступа к локал сорэдж
            this.GetLoginLanguageFromServer();
          }
      }
  
      private GetLoginLanguageFromStorage()
      {
  
        
            this.setLoginLanguage(JSON.parse(this.localStorageService.get('Login_LangSet')));
            
              
      }
  
      private GetLoginLanguageFromServer()
      {
          let checker = new LangSetLoginAsker();
          checker.Language=this.currentLanguage;
          this.LoadLoginLanguage(checker).subscribe((data: Login_LangSetGiver) => {
            if (data.Code == 100) {
              //this.unLogin();
              return;
            }
            if (data.Code == 500) { console.error(data.Message); return; }
  
            
            
                this.setLoginLanguage(data.Set);
               
            this.localStorageService.set('Login_LangSet', JSON.stringify(data.Set))
            this.localStorageService.set('Login_LangSet_Type', JSON.stringify(this.currentLanguage))
          });
      }
  
      private LoadLoginLanguage(obj: LangSetLoginAsker)
      {
            let routeLink="LanguageSet/Login/Get";
            
          const body = "'" + JSON.stringify(obj) + "'";
          let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
          return this.http.post(this.param.fullServerString() + routeLink, body, {headers: headers, withCredentials: true}).pipe(
          //map((resp:Response)=>resp.json())
            catchError((error: any) => {
              return observableThrowError(error);
            }));
          
      }
  
      private GetLoginLanguageVersion(obj: LangSetLoginAsker)
      {
          let routeLink="LanguageSet/Login/LastChangeTime";
          
          const body = "'" + JSON.stringify(obj) + "'";
          let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
          return this.http.post(this.param.fullServerString() +  routeLink, body, {headers: headers, withCredentials: true}).pipe(
          //map((resp:Response)=>resp.json())
            catchError((error: any) => {
              return observableThrowError(error);
            }));
          
      }

}


/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver, SingleIdGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { Chats_LangSet, Common_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { AdditionalMessagesAsker, ChatConvertToGlobalAsker, ChatCreateAsker, ChatEditAsker, ChatInList, ChatListGiver, ChatMessagesRefreshWebGiver, ChatMessagesWebGiver, ChatNotificator, ChatRefreshAsker, ChatSearchAsker, ChatSearched, ChatSearchGiver, ChatSettingsGiver, ChatWorkersGiver, ChatWorkersGiverNew, FullOpenedChat, GlobalChatAddAsker, MessageAddAsker, MessageInOpenedChat, MessageSearched, WorkerForChatHolder, WorkerWithPhoto } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { cloneDeep } from 'lodash';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { ServerString } from '../../../config/serverstring';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { PhotoUploadAsker } from '../../../models/model.Files/Out/model.FileSystemAsker';
import { HttpServiceFile } from '../../../httpserv/httserv.file';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'editChatContainer',
    templateUrl: 'temp.editChatContainer.html',
    styleUrls: ['editChatContainer.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/control-inputs.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class editChatContainerComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    potentialUserList: WorkerForChatHolder[]=[];
    currentUserList: WorkerWithPhoto[]=[];
    workersLoaded: boolean=false;
    ChatPotentialName="Новая беседа";
    ChatNameBack="Новая беседа";
    ChatPotentialNameLiter="";
    ChatPhoto: string=null;
    SelectedCount: number=0;
    isChatNameEdit: boolean=false;
    isUserChatAdmin: boolean=false;


    @Output() EditedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() CreatedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() BackToChat: EventEmitter<number> = new EventEmitter<number>();
    @Output() CancelChatEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() ExitChat: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() ChatId: number;
    @Input() IsPersonal: boolean=true;
    @Input() PersonalWorkerId: number=0;

    ExistingWorkersIds: number[]=[];
    PotentialWorkersIds: number[]=[];


    isAddingPhotoToChatDialog: boolean=false;
    //CurrentOpenedChat: 
   
    imageChangedEvent: any = '';
    croppedImage: any = '';
    photoLoaded: boolean = false;
    filename: string = '';

    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    showUploadFile: boolean = false;
    UploadFilePath: string;
    files: any;
    @ViewChild("fileInput") fileInput: any;

    localeFileString: string;
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private serverString: ServerString,
    private languageService: LanguageService,
    private httpServiceFile: HttpServiceFile,
    private sessionService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);
                
                if(this.IsPersonal)
                {
                  this.LoadPotentialWorker();
                }
                else
                {
                  this.LoadChatSettings();
                }
                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }



              SaveChat()
              {
                
                let selectedIds=[];
                for(let i=0; i<this.potentialUserList.length; i++)
                {
                  if(this.potentialUserList[i].IsWorker && this.potentialUserList[i].Worker.Selected)
                  {
                    selectedIds.push(this.potentialUserList[i].Worker.Id);
                  }
                  if(!this.potentialUserList[i].IsWorker)
                  {
                    for(let j=0; j<this.potentialUserList[i].Department.Workers.length; j++)
                    { 
                      if(this.potentialUserList[i].Department.Workers[j].Selected )
                      {
                        selectedIds.push(this.potentialUserList[i].Department.Workers[j].Id)
                      }
                    }
                  }
                }


                for(let i=0; i<this.currentUserList.length; i++)
                    { 
                      if(this.currentUserList[i].Selected )
                      {
                        selectedIds.push(this.currentUserList[i].Id)
                      }
                    }

                    
                    if(this.IsPersonal)
                    {

                      if((selectedIds.length==1 && this.ExistingWorkersIds.length==1) || 
                      (selectedIds.length==2 && this.ExistingWorkersIds.length==2 && selectedIds.findIndex(c=>c==this.ExistingWorkersIds[0])>-1 && selectedIds.findIndex(c=>c==this.ExistingWorkersIds[1])>-1) )

                      {
                        this.CancelAddChat();
                      }
                      if(selectedIds.length==0 )
                      {
                        alert("Пользователи не выбраны");
                        return;
                      }
                      

                      if(selectedIds.length==1 && (this.ExistingWorkersIds.length!=1 || selectedIds[0]!=this.UserId) )
                      {
                        alert("Нельзя изменить пользователя  личного чата");
                        return;
                      }
                      
                      if(selectedIds.length==2 && this.ExistingWorkersIds.length==2 && (this.ExistingWorkersIds.findIndex(c=>c==selectedIds[0])==-1 || this.ExistingWorkersIds.findIndex(c=>c==selectedIds[1])==-1))
                      {
                        alert("Нельзя изменить участников личного");
                        return;
                      }

                      if(selectedIds.length>2 || (selectedIds.length==2 && this.ExistingWorkersIds.length==1))
                      {

                        if(selectedIds.findIndex(c=>c==this.UserId)==-1)
                        {
                          alert("Нельзя выйти из личного чата");
                        return;
                        }

                        if(this.ExistingWorkersIds.length==2 && (selectedIds.findIndex(c=>c==this.ExistingWorkersIds[0])==-1 ||selectedIds.findIndex(c=>c==this.ExistingWorkersIds[1])==-1 ))
                        {
                          alert("Нельзя исключить участников личного чата");
                            return;
                        }


                        if(this.ChatPotentialName==undefined || this.ChatPotentialName==null || this.ChatPotentialName=="")
                      {
                        alert("Необходимо ввести название");
                        return;
                      }

                        let checker = new ChatConvertToGlobalAsker();
                        checker.SessionKey = this.SessionKey;
                        checker.ChangerId = this.UserId;
                        checker.Workers=selectedIds;
                        checker.Name=this.extendedHelper.ReplaceBadThings(this.ChatPotentialName);
                        checker.Photolink=this.ChatPhoto;
                        checker.ChatId=this.ChatId;
                        this.httpServiceChat.chatConvertToGlobal(checker).subscribe((data: SingleIdGiver) => {
                          if (data.Code == 100) {
                            this.unLogin();
                            return;
                          }
                        
                          if (data.Code == 300) {
                            alert("Необходимо указать название беседы")
                            return;
                          }
                          if (data.Code == 301) {
                            alert("Необходимо выбрать пользователей")
                            return;
                          }
                          if (data.Code == 302) {
                            console.log(data.Message)
                            return;
                          }
                          if (data.Code == 303) {
                            console.log(data.Message)
                            return;
                          }
                          if (data.Code == 500) { console.error(data.Message); return; }
        
                          if (data.Code==0)
                          {                           
                            this.CreatedChatId.emit(data.Id)
                          }
                          
                        })
                      }
                    }
                    else
                    {

                      if(selectedIds.length==0)
                      {
                        alert("Нельзя исключить всех пользователей из беседы");
                        return;
                      }
                      if(selectedIds.findIndex(c=>c==this.UserId)==-1)
                      {
                        alert("Нельзя исключить себя беседы");
                        return;
                      }
                      if(selectedIds.length>0)
                      {
                        let deletedIds=[];
                        let addedIds=[];

                        for(let i=0; i<selectedIds.length; i++)
                        {
                          if(this.ExistingWorkersIds.findIndex(c=>c==selectedIds[i])==-1)
                          {
                            addedIds.push(selectedIds[i])
                          }
                        }

                        for(let i=0; i<this.ExistingWorkersIds.length; i++)
                        {
                          if(selectedIds.findIndex(c=>c==this.ExistingWorkersIds[i])==-1)
                          {
                            deletedIds.push(this.ExistingWorkersIds[i])
                          }
                        }

                        let checker = new ChatEditAsker();
                        checker.SessionKey = this.SessionKey;
                        checker.ChangerId = this.UserId;
                        checker.AddingWorkers=addedIds;
                        checker.DeletingWorkers=deletedIds;
                        checker.Name=this.extendedHelper.ReplaceBadThings(this.ChatPotentialName);
                        checker.PhotoLink=this.ChatPhoto;
                        checker.Id=this.ChatId;
                        this.httpServiceChat.chatEditChat(checker).subscribe((data: CommonResponse) => {
                          if (data.Code == 100) {
                            this.unLogin();
                            return;
                          }

                          if (data.Code == 200) {
                            alert("Необходимо указать название беседы")
                            return;
                          }
                          if (data.Code == 300) {
                            console.log(data.Message)
                            return;
                          }

                          if (data.Code == 301) {
                            console.log(data.Message)
                            return;
                          }
                          if (data.Code == 302) {
                            console.log(data.Message)
                            return;
                          }
                        
                          if (data.Code == 500) { console.error(data.Message); return; }
        
                          if (data.Code==0)
                          {
                            this.EditedChatId.emit(this.ChatId);
                          }
                          
                        })


                      }
                    }
                
                
              }

              CancelAddChat()
              {
                
                this.CancelChatEditing.emit(true);
              }


              LoadPotentialWorker()
              {
                this.workersLoaded=false;
                let checkerMes = new SingleIdRequest();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.ChatId;
                this.httpServiceChat.chatGetWorkersToCreate(checkerMes).subscribe((data: ChatWorkersGiverNew) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.PotentialWorkersIds=[];
                    this.ExistingWorkersIds=[];
                    this.potentialUserList=[];
                    this.currentUserList=[];
                    for(let i=0; i<data.Holders.length; i++)
                    {
                      if(data.Holders[i].IsWorker)
                      {
                        if(!data.Holders[i].Worker.Selected && !data.Holders[i].Worker.AlwaysSelected)
                        {
                          this.potentialUserList.push(data.Holders[i]);
                          this.PotentialWorkersIds.push(data.Holders[i].Worker.Id)
                        }
                        else
                        {
                          this.currentUserList.push(data.Holders[i].Worker);
                          this.currentUserList[this.currentUserList.length-1].Selected=true;
                          this.ExistingWorkersIds.push(data.Holders[i].Worker.Id)
                        }
                      }
                      else
                      {
                        this.potentialUserList.push(_.cloneDeep(data.Holders[i]));
                        this.potentialUserList[this.potentialUserList.length-1].Department.Workers=[];
                        for(let j=0; j<data.Holders[i].Department.Workers.length; j++)
                        {

                          if(!data.Holders[i].Department.Workers[j].Selected && !data.Holders[i].Department.Workers[j].AlwaysSelected)
                          {
                            this.potentialUserList[this.potentialUserList.length-1].Department.Workers.push(data.Holders[i].Department.Workers[j]);
                            this.PotentialWorkersIds.push(data.Holders[i].Department.Workers[j].Id)
                          }
                          else
                          {
                            this.currentUserList.push(data.Holders[i].Department.Workers[j]);
                            this.currentUserList[this.currentUserList.length-1].Selected=true;
                            this.ExistingWorkersIds.push(data.Holders[i].Department.Workers[j].Id)
                          }
                        }
                      }
                    }
                    this.workersLoaded=true;
                  }
                  
                })
              }

              LoadChatSettings()
              {
                this.workersLoaded=false;
                let checkerMes = new SingleIdRequest();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.ChatId;
                this.httpServiceChat.chatGetSettings(checkerMes).subscribe((data: ChatSettingsGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }

                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    return;
                  }

                  if (data.Code == 302) {
                    console.log(data.Message)
                    return;
                  }
                  
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  { 
                    this.PotentialWorkersIds=[];
                    this.ExistingWorkersIds=[];
                    this.potentialUserList=_.cloneDeep(data.PotentialWorkerHolders);
                    this.currentUserList=_.cloneDeep(data.ExistingWorkers);
                    for(let i=0; i<this.currentUserList.length; i++)
                    {
                      this.currentUserList[i].Selected=true;
                      this.ExistingWorkersIds.push(this.currentUserList[i].Id);
                      if(!data.IsAdmin || (data.IsAdmin && this.currentUserList[i].Id==this.UserId))
                      {
                        this.currentUserList[i].AlwaysSelected=true;
                      }
                    }

                    for(let i=0; i<this.potentialUserList.length; i++)
                    {
                      if(this.potentialUserList[i].IsWorker)
                      {
                        this.PotentialWorkersIds.push(this.potentialUserList[i].Worker.Id)
                      }
                      else
                      {
                        for(let j=0; j<this.potentialUserList[i].Department.Workers.length; j++)
                        {
                          this.PotentialWorkersIds.push(this.potentialUserList[i].Department.Workers[j].Id)
                        }
                      }
                    }

                    this.ChatPhoto=data.PhotoLink;
                    this.ChatPotentialName=data.Name;
                    this.ChatNameBack=data.Name;
                    this.isUserChatAdmin=data.IsAdmin;
                    this.workersLoaded=true;
                    this.ChatPotentialNameLiter = data.Name.split(" ").map(s => s[0]).join("")
                  }
                  
                })
              }

              RecountSelected()
              {
                this.SelectedCount=0;
               
                for(let i=0; i<this.potentialUserList.length; i++)
                {
                  if(this.potentialUserList[i].IsWorker && this.potentialUserList[i].Worker.Selected)
                  {
                    this.SelectedCount++;
                  }
                  if(!this.potentialUserList[i].IsWorker)
                  {
                    for(let j=0; j<this.potentialUserList[i].Department.Workers.length; j++)
                    { 
                      if(this.potentialUserList[i].Department.Workers[j].Selected )
                      {
                        this.SelectedCount++;
                      }
                    }
                  }
                }


                for(let i=0; i<this.currentUserList.length; i++)
                    { 
                      if(this.currentUserList[i].Selected )
                      {
                        this.SelectedCount++;
                      }
                    }

              }


              ExitFromChat()
              {
                this.workersLoaded=false;
                let checkerMes = new SingleIdRequest();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.ChatId;
                this.httpServiceChat.chatExit(checkerMes).subscribe((data: CommonResponse) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }

                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  { 
                    this.ExitChat.emit(true);
                  }
                  
                })
              }

              


              ImageLoaded(link: string)
              {
                this.ChatPhoto= this.serverString.fullServerString()+link;
                this.isAddingPhotoToChatDialog=false;
                
              }


              AddImageOpenDialog()
              {
                this.isAddingPhotoToChatDialog=true;
                DOMHelper.FocusElement('EditingPhotoToChatDialogId', 50);
              }



              ////Для загрузки картинок, почему то не взлетела компонентом?

              imageCropped(event: ImageCroppedEvent) {
                this.croppedImage = event.base64;
              }
              imageLoaded() {
                this.photoLoaded = true;
              }
              loadImageFailed() {
                // show message
              }
            
              fileChangeEvent(event: any): void {
                this.imageChangedEvent = event;
                if (event != undefined && event.target.files.length > 0) {
                  this.filename = event.target.files[0].name;
                }
              }
            
              DeleteSelectedPhoto() {
                this.imageChangedEvent = '';
                this.filename = '';
                this.photoLoaded = false;
              }
            
            
              rotateLeft() {
                this.canvasRotation--;
                this.flipAfterRotate();
              }
            
              rotateRight() {
                this.canvasRotation++;
                this.flipAfterRotate();
              }
            
              private flipAfterRotate() {
                const flippedH = this.transform.flipH;
                const flippedV = this.transform.flipV;
                this.transform = {
                  ...this.transform,
                  flipH: flippedV,
                  flipV: flippedH
                };
              }
            
            
              flipHorizontal() {
                this.transform = {
                  ...this.transform,
                  flipH: !this.transform.flipH
                };
              }
            
              flipVertical() {
                this.transform = {
                  ...this.transform,
                  flipV: !this.transform.flipV
                };
              }
            
              resetImage() {
                this.scale = 1;
                this.rotation = 0;
                this.canvasRotation = 0;
                this.transform = {};
              }
            
              zoomOut() {
                this.scale -= .1;
                this.transform = {
                  ...this.transform,
                  scale: this.scale
                };
              }
            
              zoomIn() {
                this.scale += .1;
                this.transform = {
                  ...this.transform,
                  scale: this.scale
                };
              }
            
              updateRotation() {
                setTimeout(() => {
                  this.transform = {
                    ...this.transform,
                    rotate: this.rotation
                  };
                }, 100);
              }
            
            
              UploadImage() {
                let fi = this.fileInput.nativeElement.files;
                if (fi && fi[0]) {
                  let fileToUpload = fi[0];
            
                  let str = new PhotoUploadAsker();
                  str.ChangerId = this.UserId;
                  str.SessionKey = this.SessionKey;
                  str.Filename = fileToUpload.name;
                  str.Type = 0;
            
                  const str2 = JSON.stringify(str);
            
                  var byteString;
                  if (this.croppedImage.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(this.croppedImage.split(',')[1]);
                  else
                    byteString = unescape(this.croppedImage.split(',')[1]);
            
                  // separate out the mime component
                  var mimeString = this.croppedImage.split(',')[0].split(':')[1].split(';')[0];
            
                  // write the bytes of the string to a typed array
                  var ia = new Uint8Array(byteString.length);
                  for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }
            
                  let blb = new Blob([ia], { type: mimeString });
            
            
                  let input = new FormData();
                  input.append("data", blb, str2);
                  this.httpServiceFile.uploadPhoto(input).subscribe((data: SingleStringGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 300) {
                      alert(this.langset.EmptyFileName);
                      return;
                    }
                    if (data.Code == 301) {
                      alert(this.langset.WrongPhotoFormat);
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
            
                    
                    let link = _.cloneDeep(data.Value);
                    fi=[];
                    this.ImageLoaded(link);
                    
                  });
            
                }
              }

              onKeyDown(e: KeyboardEvent){
                switch(e.keyCode){
                  case 27:{
                    this.isAddingPhotoToChatDialog = false;
                  }
                }
              }

              StartEditName()
              {
                this.ChatNameBack=this.ChatPotentialName;
                this.isChatNameEdit=true;
              }

              CancelChangeName()
              {
                this.ChatPotentialName=this.ChatNameBack;
                this.isChatNameEdit=false;
              }


              DeletePhoto()
              {
                this.ChatPhoto=null;
              }
}



/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { LocalStorageService } from 'angular-2-local-storage';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { PhotoUploadAsker } from '../../models/model.Files/Out/model.FileSystemAsker';
import { VersionGiver } from '../../config/Version';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { SingleStringGiver } from '../../models/model.http';
import { HttpServiceFile } from '../../httpserv/httserv.file';
import { DeleteSession } from '../../store/actions/session.action';
import { SessionService } from '../../services/session.service';
import { Common_LangSet } from '../../models/model.language';
import { LanguageService } from '../../services/language.service';


declare var require: any;

@Component({
  selector: 'imageAddForm',
  templateUrl: 'temp.imageAddForm.html',
  styleUrls: ['imageAddForm.scss',
    '../../../style/control-inputs.scss',
    '../../../style/supportLibrary.scss',
    '../../../style/font-styles.scss'],

    providers: [HttpServiceFile, ExtendedHelper]

})
export class ImageAddFormComponent implements OnInit {
  
  UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    
   
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;

  CloseText: string="";
  imageChangedEvent: any = '';
  croppedImage: any = '';
  photoLoaded: boolean = false;
  filename: string = '';

  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showUploadFile: boolean = false;
  UploadFilePath: string;
  files: any;
  @ViewChild("fileInput") fileInput: any;

  localeFileString: string;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  @Output() LoadedImageLink: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private store$: Store,
    private router: Router,
    private localStorageService: LocalStorageService,
    private httpServiceFile: HttpServiceFile,
    private sessionServie: SessionService,
    private languageService: LanguageService
  ) { }




  ngOnInit() {
    this.sessionKey$=this.sessionServie.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionServie.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
  }


  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionServie.unLogin();
  }
  




  LanguageSetGet() {
    let localSt = this.localStorageService.isSupported;
    if (localSt) {
      this.CloseText = this.localStorageService.get("Close");
    }

  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.photoLoaded = true;
  }
  loadImageFailed() {
    // show message
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    this.imageChangedEvent = event;
    if (event != undefined && event.target.files.length > 0) {
      this.filename = event.target.files[0].name;
    }
  }

  DeleteSelectedPhoto() {
    this.imageChangedEvent = '';
    this.filename = '';
    this.photoLoaded = false;
  }


  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  updateRotation() {
    setTimeout(() => {
      this.transform = {
        ...this.transform,
        rotate: this.rotation
      };
    }, 100);
  }


  UploadImage() {
    let fi = this.fileInput.nativeElement.files;
    if (fi && fi[0]) {
      let fileToUpload = fi[0];

      let str = new PhotoUploadAsker();
      str.ChangerId = this.UserId;
      str.SessionKey = this.SessionKey;
      str.Filename = fileToUpload.name;
      str.Type = 0;

      const str2 = JSON.stringify(str);

      var byteString;
      if (this.croppedImage.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(this.croppedImage.split(',')[1]);
      else
        byteString = unescape(this.croppedImage.split(',')[1]);

      // separate out the mime component
      var mimeString = this.croppedImage.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      let blb = new Blob([ia], { type: mimeString });


      let input = new FormData();
      input.append("data", blb, str2);
      this.httpServiceFile.uploadPhoto(input).subscribe((data: SingleStringGiver) => {
        if (data.Code == 100) {
          this.unLogin();
          return;
        }
        if (data.Code == 300) {
          alert(this.langset.EmptyFileName);
          return;
        }
        if (data.Code == 301) {
          alert(this.langset.WrongPhotoFormat);
          return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        
        let link = _.cloneDeep(data.Value);
        
      });

    }
  }
  
}

import { TrashIcon, InfoCircleIcon } from './../../../shared/components/svg-icon/svg-icon.data';
import { CommonRequest, ListIdResponse } from './../../../models/model.http';
import { Component, OnInit, Input, HostListener, EventEmitter, Output, SimpleChanges, ɵExtraLocaleDataIndex } from '@angular/core';
import { FileOut, FileInfoGiver, FileInfoContainer, ReplaceOldFile, TagSignable, FolderSystemSetting, FileAccessUsersSelectableVisible } from '../../../models/model.Files/Inner/model.FileSystemInner';
import { Common_LangSet, Files_LangSet, Jobs_LangSet, LanguageSetCommon } from '../../../models/model.language';
import { SingleIdRequest, CommonResponse } from '../../../models/model.http';
import { HttpServiceFile } from "../../../httpserv/httserv.file";
import { CookieOptions, CookieService } from 'ngx-cookie';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { Router } from '@angular/router';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { Common } from '../../../models/model.common';
import { TagFilter, TagContainerInner, TagFilterToSendExtended, TagContainerGiver, TagListGiver, TagAddAsker, TagFromAngularDeleting, TagEntityTypeEnum } from '../../../models/model.tag';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { TagHelper } from '../../../Helpers/taghelper';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { HttpWorkersAndAccessGroups } from '../../../httpserv/httpserv.WorkersAndAccessGroups';
import { CommonListGiver } from '../../../models/model.CommonListGiver';
import { FileAccessEditAsker } from '../../../models/model.Files/Out/model.FileSystemAsker';
import { SvgIconsRegistry } from '../../../shared/components/svg-icon/svg-icon-registry.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { DeleteSession } from '../../../store/actions/session.action';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'file-element',
  templateUrl: './file-element.component.html',
  styleUrls: ['./file-element.component.scss',
    '../../../../style/font-awesome.min.scss',
    '../../../../style/supportLibrary.scss',
    '../../../../style/font-styles.scss',
    '../../../../style/tagForm.scss',
    '../../../../iconfont/material-icons.scss'
  ],
  providers: [HttpServiceFile]
})
export class FileElementComponent implements OnInit {
  @Input("file") File: FileOut;
  @Output('fileChange') FileChange = new EventEmitter<FileOut>();


  @Input("access") FileAccess: number;//права на конкретный файл
  @Input("rule") RuleAccess: number;//права на файлы клиента или проекта
  @Input("admin") AdminAccess: boolean;

  @Input("level") Level: number;
  @Input("clientId") ClientId: number;
  @Input("projectId") ProjectId: number;
  @Input("groupUserId") GroupUserId: number;
  @Input("groupId") GroupId: number[] = [];
  @Input("setting") FileSystemSetting: FolderSystemSetting = new FolderSystemSetting();
  @Input("setting-search") searchInput: string;

  @Output() updateFileParentFolder: EventEmitter<any> = new EventEmitter();
  @Output() deleteFileUpdate: EventEmitter<any> = new EventEmitter();
  @Output() runFileLoading: EventEmitter<any> = new EventEmitter();
  @Output() stopFileLoading: EventEmitter<any> = new EventEmitter();
  @Output() makeFolderVisible: EventEmitter<any> = new EventEmitter();

  SessionKey: string;
  UserId: number;

  FileInfo: FileInfoContainer;
  IsFileLoading: boolean;
  FileInfoShowDialog: boolean = false;
  FileInfoShowDialogHidden: boolean = false;

  infoLeft: string = "350px";
  infoTop: string = "-600px";

  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();
  UsersListToAccess: FileAccessUsersSelectableVisible[] = [];
  fileAccessSelectVisible: boolean = false;
  ListToAccessFilter = '';

  isActiveTag: boolean = false;

  AccessForFile: boolean;

  sessionKey$: Subscription=null;
  userId$: Subscription=null;

  files_Langset: Files_LangSet;
  fl_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  constructor(
    private store$: Store,
    private cookieService: CookieService,
    private httpServiceFile: HttpServiceFile,
    private router: Router,
    private cookieOptionsMy: CookieOptionsMy,
    private httpServiceTag: HttpServiceTag,
    private extendedHelper: ExtendedHelper,
    private httpWorkersAndAccessGroups: HttpWorkersAndAccessGroups,
    private sessionService: SessionService,
    private languageService: LanguageService,
    private svgIconsRegistry: SvgIconsRegistry
  ) {
    this.svgIconsRegistry.registerIcons([
      TrashIcon,
      InfoCircleIcon
    ])
  }


  @HostListener('document:keydown.esc', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    switch (e.keyCode) {
      case 27: {//ESCAPE
        if (this.FileInfoShowDialog) {
          this.FileInfoShowDialog = false;
        }
      }
    }
  }


  public keyControl(e: any) {
    switch (e.keyCode) {
      case 27: {//ESCAPE
        if (this.FileInfoShowDialog) {
          this.FileInfoShowDialog = false;
          this.FileInfoShowDialogHidden = false;
        }
        break;
      }
    }
  }

  ngOnInit() {
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.fl_sub=this.languageService.filesLanguage$.subscribe(lang=>this.files_Langset=lang);

    this.AccessForFile = this.GetAccessForFile();
    this.File.Visible = true;
    // console.log('Право на проект: ' + this.RuleAccess)
    // console.log('Персональное право: ' + this.AccessForFile)
    // console.log('Admin: ' + this.AdminAccess)
    // console.log(this.File.GroupsWithAccess)
  }

  ngAfterViewInit(): void {
  }

  setPriorityTag(isActive: boolean) {
    this.isActiveTag = isActive;
    // console.log(isActive);
  }

  ReplaceHtmlTags(string: string) {
    let separator = new RegExp(/<[^>]+>/, "gi");
    string = string.replace(separator, '');
    return string;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      if (!chng.isFirstChange()) {
        this.File.Name = this.ReplaceHtmlTags(this.File.Name);
        if (this.File.Tags != null && this.File.Tags.length > 0) {
          this.File.Tags.forEach(element => {
            element.Name = this.ReplaceHtmlTags(element.Name);
          });
        }
        if (this.FileSystemSetting.FileSearch != '' || this.FileSystemSetting.FileSearch.length != 0) {
          let visibleCheck = 0;
          let filter = new RegExp(this.FileSystemSetting.FileSearch, 'gi')
          if (this.File.Name.match(filter)) {
            visibleCheck++;
            this.File.Name = SearchSelectHelper.getSearchedText(this.FileSystemSetting.FileSearch, this.File.Name);
          }
          if (this.File.Tags != null && this.File.Tags.length > 0) {
            this.File.Tags.forEach(element => {
              if (element.Name.match(filter)) {
                element.Name = SearchSelectHelper.getSearchedText(this.FileSystemSetting.FileSearch, element.Name);
                visibleCheck++;
              }
            });
          }
          if (visibleCheck > 0) {
            this.File.Visible = true;
            this.makeFolderVisible.emit();
          } else {
            this.File.Visible = false;
          }
        } else {
          this.File.Visible = true;
        }

      }
    }
  }

  MakeSelected() {
    this.File.isSelected = true;
  }
  MakeNotSelected() {
    this.File.isSelected = false;
  }

  updateFilesEmit(event: any) {
    let files = event.dataTransfer.files;
    let object = new ReplaceOldFile();
    object.Files = files,
      object.FileId = this.File.Id,
      object.OldName = this.File.Name
    this.updateFileParentFolder.emit(object);
  }

  runFileLoadingEmit() {
    this.runFileLoading.emit();
  }

  stopFileLoadingEmit() {
    this.stopFileLoading.emit();
  }

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }


  DownloadFile() {
    
    this.runFileLoadingEmit();
    let str = new SingleIdRequest();
    str.Id = this.File.Id;
    str.ChangerId = this.UserId;
    str.SessionKey = this.SessionKey;
    // str.Path = this.extendedHelper.ReplaceBadThings(path);
    // str.FileName = fileName;// file;
    // str.Type = FileShowTypeEnum.Client();
    this.httpServiceFile.downloadFile(str).subscribe((data: any) => {
      this.stopFileLoadingEmit();
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 200) {
        console.error(this.files_Langset.NoAccessDonloadFiles);
        return;
      }
      if (data.Code == 201) {
        console.error(this.files_Langset.FileRemoved);
        return;
      }
      if (data.Code == 202) {
        console.error('Файл с папкой удалена');
        return;
      }
      if (data.Code == 300) {
        console.error('Invalid ID');
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      var blob = new Blob([data], { type: 'text' });
      var url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.File.Name);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  DeleteFile() {
    if (confirm("Удалить файл?")) {
      this.runFileLoadingEmit();
      let str = new SingleIdRequest(this.UserId, this.SessionKey, this.File.Id);
      this.httpServiceFile.deleteFile(str).subscribe((data: FileInfoGiver) => {
        this.stopFileLoadingEmit();
        if (data.Code == 100) {
          this.unLogin();
          return;
        }
        if (data.Code == 200) {
          console.error(this.files_Langset.FileFolderRemoved);
          return;
        }
        if (data.Code == 201) {
          console.error(this.files_Langset.FileFolderRemoved);
          return;
        }
        if (data.Code == 300) {
          console.error('Invalid ID file');
        this.stopFileLoadingEmit();
        return;
        }
        if (data.Code == 301) {
          console.error('Invalid ID directory');
        this.stopFileLoadingEmit();
        return;
        }
        if (data.Code == 500) { console.error(data.Message); return; }

        this.deleteFileUpdate.emit(this.File.Id);
      });
    }
  }

  EditFile() {
    let link = ['/docedit/', this.File.Id];
    this.router.navigate(link);
  }

  closeFileInfoShowDialog() {
    this.FileInfoShowDialog = false;
    this.FileInfoShowDialogHidden = false;
    this.infoTop = "-600px";
  }

  GetInfoFile(event: any) {
    this.runFileLoadingEmit();
    let str = new SingleIdRequest(this.UserId, this.SessionKey, this.File.Id);
    this.httpServiceFile.getFileInfo(str).subscribe((data: FileInfoGiver) => {
      this.stopFileLoadingEmit();
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 200) {
        console.error(this.files_Langset.NoAccessDonloadFiles);
        return;
      }
      if (data.Code == 201) {
        console.error(this.files_Langset.FileRemoved);
        return;
      }
      if (data.Code == 202) {
        console.error('Файл с папкой удалена');
        return;
      }
      if (data.Code == 300) {
        console.error('Invalid ID');
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.FileInfo = data.Info;
      this.FileInfoShowDialog = true;
      this.GetUserAccessList();
      let h = window.innerHeight;
      setTimeout(() => {
        let infoElementCoordinates = document.getElementById('fileInfo_' + this.File.Id).getBoundingClientRect();
        this.infoLeft = event.clientX + 20 + "px";
        if (event.clientY + infoElementCoordinates.height > h) {
          this.infoTop = h - infoElementCoordinates.height - 20 + "px";
        } else {
          this.infoTop = event.clientY - infoElementCoordinates.height / 2 + "px";
        }
      }, 100);
      this.FileInfoShowDialogHidden = true;
    });
  }



  CheckAccessBool(): boolean {
    return (this.File.GroupsWithAccess.find(us => us == this.UserId)) ? true : false;
  }

  GetAllSelected() {
    let list: FileAccessUsersSelectableVisible[] = [];
    for (let i = 0; i < this.UsersListToAccess.length; i++) {
      if (this.UsersListToAccess[i].Selected) list.push(this.UsersListToAccess[i]);
    }
    return list;
  }

  updateTagsFile(ev: Common[]) {
    console.log('updatefile')

    this.File.Tags = [...ev.map(el => new TagSignable(el.Id, el.Name))]
    this.FileChange.emit(this.File);
    console.log(this.File)
  }

  AccessSearchByFilter() {
    for (let i = 0; i < this.UsersListToAccess.length; i++) {
      this.UsersListToAccess[i].Visible = false;
    }

    if (this.ListToAccessFilter != null && this.ListToAccessFilter != undefined && this.ListToAccessFilter != "") {
      let separator = new RegExp(/ *\s/, "gi");
      for (let i = 0; i < this.UsersListToAccess.length; i++) {
        let filters = this.ListToAccessFilter.split(separator);
        for (let f = 0; f < filters.length; f++) {
          if (filters[f] != "") {
            if (ExtendedHelper.ContainsNoCase(this.UsersListToAccess[i].Name, filters[f])) {
              this.UsersListToAccess[i].TextToShow = SearchSelectHelper.getSearchedText(this.ListToAccessFilter, this.UsersListToAccess[i].Name);
              this.UsersListToAccess[i].Visible = true;
            }
          }
        }
      }
    }

    if (this.ListToAccessFilter == "") {
      for (let i = 0; i < this.UsersListToAccess.length; i++) {
        this.UsersListToAccess[i].TextToShow = this.UsersListToAccess[i].Name;
        this.UsersListToAccess[i].Visible = true;
      }
    }
  }

  GetAccessForFile(): boolean {
    if (this.File.GroupsWithAccess != null && this.File.GroupsWithAccess.length != 0) {
      return (this.File.GroupsWithAccess.find(el => el == this.GroupId.find(id => id == el)) != undefined) ? true : false;
    } else {
      return true;
    }
  }

  OpenAccessMultiselects() {
    if (this.FileAccess != 2) return;
    for (let i = 0; i < this.UsersListToAccess.length; i++) {
      this.UsersListToAccess[i].Visible = true;
    }
    this.fileAccessSelectVisible = true;
  }

  GetUserAccessList() {
    let checker = new CommonRequest(this.UserId, this.SessionKey);
    this.httpWorkersAndAccessGroups.GetGroupsToSetFileAccess(checker).subscribe((data: CommonListGiver) => {
      if (data.Code == 100) {
        this.unLogin();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.UsersListToAccess = data.List.map(el => {
        let selected = false;
        if (this.File.GroupsWithAccess.find(us => us == el.Id)) selected = true;
        let newItem = new FileAccessUsersSelectableVisible(el.Id, el.Name, selected, true);
        return newItem;
      })
      this.AccessForFile = this.GetAccessForFile();
    });
  }

  CloseAccessMultiselects() {
    for (let i = 0; i < this.UsersListToAccess.length; i++) {
      this.UsersListToAccess[i].Visible = false;
    }
    this.fileAccessSelectVisible = false;
    this.ListToAccessFilter = '';
  }

  ChangeFileAccess(user: FileAccessUsersSelectableVisible) {
    let checker = new FileAccessEditAsker(this.UserId, this.SessionKey);
    if (this.ClientId != null) checker.ClientId = this.ClientId;
    if (this.ProjectId != null) checker.ProjectId = this.ProjectId;
    user.Selected = !user.Selected;
    this.File.GroupsWithAccess = this.GetAllSelected().map(el => el.Id);

    // if (this.File.GroupsWithAccess.find(el => el == this.GroupId.find(id => id == this.UserId)) != undefined) {
    // if (this.File.GroupsWithAccess.find(el => el == this.UserId) == undefined) {
    //   console.log(this.UsersListToAccess.find(el => el.Id == this.GroupUserId));
    //   // this.UsersListToAccess.find(el => el.Id == this.GroupUserId).Selected = true;
    //   this.File.GroupsWithAccess.push(this.UserId);
    // }

    checker.Id = this.File.Id;
    checker.GroupsWithAccess = this.File.GroupsWithAccess;
    this.httpServiceFile.EditFileAccess(checker).subscribe((data: ListIdResponse) => {
      if (data.Code == 100) { this.unLogin(); return; }
      if (data.Code == 300 || data.Code == 301 || data.Code == 302) {
        console.error(data.Message);
        user.Selected = false;
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.File.GroupsWithAccess = data.List;
      // console.log(this.File.GroupsWithAccess)
      // console.log(data.List)

      let selfItem = this.UsersListToAccess.find(el => this.File.GroupsWithAccess.find(elIn => elIn == el.Id && !el.Selected) != undefined)
      if (selfItem) selfItem.Selected = true;
      this.AccessForFile = this.GetAccessForFile();
    });
  }



}

import { KeyboardArrowLeftIcon, SuccessIcon } from './../../shared/components/svg-icon/svg-icon.data';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { CookieOptionsMy } from '../../config/CookieOptionsMy';
import { LocalStorageService } from 'angular-2-local-storage';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { Router } from '@angular/router';
import { JobLight, JobGiver, JobLocalStorage } from '../../models/model.job';
import { DOMHelper } from '../../Helpers/DOMHelper';
import { HttpServiceJobNew } from '../../httpserv/httpserv.jobNew';
import { CommonResponse, SingleIdRequest } from '../../models/model.http';
import { JobChangeStatusAsker } from '../../models/model.Job/Out/model.JobChangeStatusAsker';
import { JobChangeStatusContainer } from '../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../models/model.Job/Inner/model.JobCrudContainer';
import { JobStatusEnum } from '../../models/model.Job/Inner/model.JobStatusEnum';
import { JobChangeStatusModeEnum } from '../../models/model.Job/Inner/model.JobChangeStatusModeEnum';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { SvgIconsRegistry } from '../../shared/components/svg-icon/svg-icon-registry.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { DeleteSession } from '../../store/actions/session.action';
import { SessionService } from '../../services/session.service';
import { LanguageService } from '../../services/language.service';
import { Common_LangSet, Jobs_LangSet } from '../../models/model.language';
import { TransportService } from '../../services/transport.service';

enum ContextJob {
  Main,
  Client,
  Project,
  Notification
}

@Component({
  selector: 'Job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss',
    '../../../style/font-awesome.min.scss',
    '../../../style/supportLibrary.scss',
    '../../../iconfont/material-icons.scss']
})
export class JobItemComponent implements OnInit {
  UserId: number;
  SessionKey: string;

  jobCrudContainer: JobCrudContainer;
  jobStatus: JobStatusEnum = new JobStatusEnum();
  jobChangeStatusMode: JobChangeStatusModeEnum = new JobChangeStatusModeEnum();
  jobChangeStatusContainer: JobChangeStatusContainer;
  JobsLocalStorage: JobLocalStorage[] = [];

  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();

  JobCanBeEdited: boolean = true;
  IsChangingJobStatus: boolean = false;
  jobChangeStatusShowDialog: boolean = false;

  IsEdited: boolean=false;

  isNotification: boolean = false;

  @Input('job')
  job: JobLight;

  @Input("clientId") clientId: number;
  @Input("projectId") projectId: number;
  @Input("isAdmin") IsAdmin: boolean = false;
  @Input("closeAsAdmin") closeAsAdmin: boolean = false
  @Input("tagsPrefix") Prefix: string="";

  @Input()
  context: ContextJob = 0;
  ContextJobEnum = ContextJob;

  @Output() deleteJob: EventEmitter<any> = new EventEmitter();
  @Output() editJob: EventEmitter<any> = new EventEmitter();
  @Output() updateAll: EventEmitter<any> = new EventEmitter();
  @Output() updateCounter: EventEmitter<any> = new EventEmitter();
  @Output() changeFilter: EventEmitter<any> = new EventEmitter();
  @Output() runJobLoading: EventEmitter<any> = new EventEmitter();
  @Output() stopJobLoading: EventEmitter<any> = new EventEmitter();
  @Output() deleteNotification: EventEmitter<any> = new EventEmitter();

  @Output() closeJobAsAdmin: EventEmitter<any> = new EventEmitter();

  @Output() statusChanged: EventEmitter<any> = new EventEmitter();

  @Output() tagsChanged: EventEmitter<any> = new EventEmitter();



  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  jobs_Langset: Jobs_LangSet;
  jb_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;

  update_sub: Subscription=null;

  constructor(
    private store$: Store,
    private router: Router,
    private localStorageService: LocalStorageService,
    private httpServiceJobNew: HttpServiceJobNew,
    private sessionService: SessionService,
    private svgIconsRegistry: SvgIconsRegistry,
    private languageService: LanguageService,
    private transportService: TransportService
  ) {
    this.svgIconsRegistry.registerIcons([
      SuccessIcon,
      KeyboardArrowLeftIcon
    ])
  }

  ngOnInit(): void {
    this.isNotification = (this.context == this.ContextJobEnum.Notification) ? true : false;

    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.jb_sub=this.languageService.jobsLanguage$.subscribe(lang=>this.jobs_Langset=lang);
    this.update_sub=this.transportService.updatedJobId$.subscribe(id=>{
      if(id==this.job.Id)
      {
        this.refreshSingleJob();
        console.log("updated")
      }
    })
  }

  

  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }

  WorkerClickJobFilterChange(workerId: number) {
    this.changeFilter.emit(workerId);
  }

  EditJobShowDialog(job: JobLight) {
    if (!job.Changeable) return;
    this.refreshSingleJob();//////////////////////////тут то это нахера????? разобраться потом и выпилить к чертям
    setTimeout(() => {
      if(this.JobCanBeEdited){
        let jobUnActiveOld = document.getElementsByClassName("unActive");
        if (jobUnActiveOld[0] != null) {
          for (let i = 0; i < jobUnActiveOld.length; i++) {
            jobUnActiveOld[i].classList.remove("unActive");
          }
        }
        let jobRedact = document.getElementById("jobRedact");
        if (jobRedact != null) {
          jobRedact.classList.add("isJobRedact");
        }
        let jobUnActive = document.getElementById("jobId" + job.Id);
        if (jobUnActive != null) {
    
          jobUnActive.classList.add("unActive");
        }
        this.editJob.emit();
      }
      else{
        alert(this.jobs_Langset.JobFinished);
        return;
      }
    }, 100)
  }


  DeleteJob(job: JobLight) {
    if (!confirm(this.jobs_Langset.JobRemoveConfirm)) return;
    let checker = new SingleIdRequest();
    checker.SessionKey = this.SessionKey;
    checker.ChangerId = this.UserId;
    checker.Id = job.Id;

    this.httpServiceJobNew.remove(checker).subscribe((data: CommonResponse) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 302) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      this.deleteJob.emit();
    });
  }


  GetJobCounter() {
    //todo update emit
  }

  goToClient(Id: number, event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/client/', Id];
      this.router.navigate(link);
      return;
    }
  }

  goToProject(Id: number, event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/project/', Id];
      this.router.navigate(link);
    }
  }

  ExpandJobClients(job: JobLight) {
    JobLight.ExpandClients(job);
  }

  EndJobDecide(job: JobLight) {
    //метод решатель
    if (job.Creator.Id == this.UserId && job.Creator.Id != job.Worker.Id && job.Status == this.jobStatus.Checking) {
      //работа создана тобой, выполнена пользователем и проверяется
      this.EndJobByCreator(job);
    }
    else if (this.UserId == job.Worker.Id && (job.Status == this.jobStatus.Working || job.Status == this.jobStatus.Lost)) {
      if (job.NeedChecking)
        this.EndByWorkerWithMessageShowDialog(job);
      else
        this.EndByWorkerWithoutMessage(job);
    }
  }

  EndJobByCreator(job: JobLight) {
    //метод без дописывания, работа принята
    let checker;
    if (this.context == this.ContextJobEnum.Notification) {
      checker = new SingleIdRequest(this.UserId, this.SessionKey, job.JobId);
    } else {
      checker = new SingleIdRequest(this.UserId, this.SessionKey, job.Id);
    }
    this.runJobLoading.emit();
    this.httpServiceJobNew.endByCreator(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 302) {
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
        this.transportService.updateJobItemById(checker.Id);
      } else {
        this.job = data.Jobs[0];
        this.updateCounter.emit();
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
        this.transportService.updateJobItemById(checker.Id);
      }
    });
  }

  EndByWorkerWithMessageShowDialog(job: JobLight) {
    //открываем окно дописывания работы
    this.ChangeJobStatusShowDialog(job, this.jobChangeStatusMode.EndByWorkerWithMessage);
  }

  ChangeJobStatusShowDialog(job: JobLight, mode: number) {
    this.jobChangeStatusContainer = new JobChangeStatusContainer(job, mode);
    if (this.context == this.ContextJobEnum.Notification) this.jobChangeStatusContainer.JobId = job.JobId;
    // this.jobChangeStatusShowDialog = true;

    job.VisibleEndJob = true;
    job.ModeToSend = mode;
    DOMHelper.FocusElement("changeJobStatusInput" + job.Id, 50);
  }

  SendJobToReworkingShowDialog(job: JobLight) {
    if (job.Worker.Id == job.Creator.Id || job.Creator.Id != this.UserId) return;
    this.ChangeJobStatusShowDialog(job, this.jobChangeStatusMode.SendToReworking);
  }

  SendJobToCheckingShowDialog(job: JobLight) {
    if (job.Worker.Id == job.Creator.Id || job.Creator.Id == this.UserId) return;
    this.ChangeJobStatusShowDialog(job, this.jobChangeStatusMode.SendToChecking);
  }

  EndByWorkerWithoutMessage(job: JobLight) {
    //метод завершения работы без дописки

    let checker = new JobChangeStatusAsker(this.UserId, this.SessionKey, job.Id);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = job.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.endByWorker(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
        this.transportService.updateJobItemById(checker.Id);
      } else {
        this.job = data.Jobs[0];
        this.updateCounter.emit();
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
        this.transportService.updateJobItemById(checker.Id);
      }
    });
  }

  refreshSingleJob(){

    console.log("refresh")
    // Обновление одной работы в списке
    let checker = new SingleIdRequest(this.UserId, this.SessionKey, this.job.Id)
    this.httpServiceJobNew.refreshSingle(checker).subscribe((data: JobGiver) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if(data.Code == 300){
        this.deleteJob.emit();
        return;
      }
      if(data.Code == 301){
        this.deleteJob.emit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      if (data.Code == 0){
        this.job = data.Jobs[0];
        if(!data.Jobs[0].Changeable){
          this.JobCanBeEdited = false;
        }
      }
      
    })
  }

  ConfirmChangingJobStatusKeyControl(event: any, job: JobLight) {
    if (event.keyCode == 13 && event.ctrlKey) {
      this.ConfirmChangingJobStatus(job);
    }
    if (event.keyCode == 27) {
      job.VisibleEndJob = false;
      job.JobEndingByAdmin = false;
    }
    setTimeout(() => {
      if (job.MessageToSend != undefined) {
        if (job.MessageToSend.length > 0) {
          // (this.JobsLocalStorage.length == 0 || this.JobsLocalStorage == []) &&
          let localJob = new JobLocalStorage(job.Id, job.MessageToSend, job.ModeToSend);
          let jobIndex = this.JobsLocalStorage.findIndex(c => c.Id == job.Id);
          if (jobIndex != -1) {
            this.JobsLocalStorage[jobIndex] = localJob;
          } else {
            this.JobsLocalStorage.push(localJob);
          }
          let localSt = this.localStorageService.isSupported;
          if (localSt) {
            this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
          }
        }
      }
    }, 100);
  }

  ConfirmChangingJobStatus(job: JobLight) {
    //метод решатель, который вызовет необходимый метод завершения работы
    this.jobChangeStatusContainer = new JobChangeStatusContainer(job, job.ModeToSend);
    this.jobChangeStatusContainer.Message = job.MessageToSend;
    if (this.context == this.ContextJobEnum.Notification) this.jobChangeStatusContainer.JobId = job.JobId;
    if (this.jobChangeStatusContainer.Mode == this.jobChangeStatusMode.SendToReworking)
      this.SendJobToReworking();
    else if (this.jobChangeStatusContainer.Mode == this.jobChangeStatusMode.SendToChecking)
      this.SendJobToChecking();
    else if (this.jobChangeStatusContainer.Mode == this.jobChangeStatusMode.EndByWorkerWithMessage)
      this.EndByWorkerWithMessage();
  }

  CancelChangingJobStatus(job: JobLight) {
    job.VisibleEndJob = false;
    job.JobEndingByAdmin = false;
    job.MessageToSend = '';
    let jobIndexLocal = this.JobsLocalStorage.findIndex(c => c.Id == job.Id);
    if (jobIndexLocal != -1) {
      this.JobsLocalStorage.splice(jobIndexLocal, 1);
    }
    let localSt = this.localStorageService.isSupported;
    if (localSt) {
      this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
    }
    this.GetJobCounter();
  }

  SendJobToChecking() {
    if (this.IsChangingJobStatus) return;
    this.IsChangingJobStatus = true;
    let checker = JobChangeStatusAsker.FromContainer(this.UserId, this.SessionKey, this.jobChangeStatusContainer);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = this.jobChangeStatusContainer.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.sendToChecking(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        this.IsChangingJobStatus = false;
        return;
      }
      if(data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        this.IsChangingJobStatus = false;
        this.jobChangeStatusShowDialog = false;
        this.jobChangeStatusContainer = null;
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      let jobIndexLocal = this.JobsLocalStorage.findIndex(c => c.Id == this.jobChangeStatusContainer.JobId);
      if (jobIndexLocal != -1) {
        this.JobsLocalStorage.splice(jobIndexLocal, 1);
      }
      let localSt = this.localStorageService.isSupported;
      if (localSt) {
        this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
      }
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
      } else {
        this.job = data.Jobs[0];
        this.updateCounter.emit();
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
      }
      this.IsChangingJobStatus = false;
      this.jobChangeStatusShowDialog = false;
      this.jobChangeStatusContainer = null;
      this.transportService.updateJobItemById(checker.Id);
    });
  }


  SendJobToReworking() {
    if (this.IsChangingJobStatus) return;
    this.IsChangingJobStatus = true;
    let checker = JobChangeStatusAsker.FromContainer(this.UserId, this.SessionKey, this.jobChangeStatusContainer);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = this.jobChangeStatusContainer.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.sendToReworking(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        this.IsChangingJobStatus = false;
        return;
      }
      if(data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        this.IsChangingJobStatus = false;
        this.jobChangeStatusShowDialog = false;
        this.jobChangeStatusContainer = null;
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      let jobIndexLocal = this.JobsLocalStorage.findIndex(c => c.Id == this.jobChangeStatusContainer.JobId);
      if (jobIndexLocal != -1) {
        this.JobsLocalStorage.splice(jobIndexLocal, 1);
      }
      let localSt = this.localStorageService.isSupported;
      if (localSt) {
        this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
      }
      // this.deleteJob.emit();
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
      } else {
        this.job = data.Jobs[0];
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
      }
      this.IsChangingJobStatus = false;
      this.jobChangeStatusShowDialog = false;
      this.jobChangeStatusContainer = null;
      this.transportService.updateJobItemById(checker.Id);
    });
  }

  EndByWorkerWithMessage() {
    if (this.IsChangingJobStatus) return;
    this.IsChangingJobStatus = true;
    //сохраняем дописанное сообщение в работу и закрываем ее
    let checker = JobChangeStatusAsker.FromContainer(this.UserId, this.SessionKey, this.jobChangeStatusContainer);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = this.jobChangeStatusContainer.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.endByWorker(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        this.IsChangingJobStatus = false;
        return;
      }
      if(data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();

        this.IsChangingJobStatus = false;
        this.jobChangeStatusShowDialog = false;
        this.jobChangeStatusContainer = null;
        this.GetJobCounter();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      // this.deleteJob.emit();
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
      } else {
        this.job = data.Jobs[0];
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
      }
      let jobIndexLocal = this.JobsLocalStorage.findIndex(c => c.Id == this.jobChangeStatusContainer.JobId);
      if (jobIndexLocal != -1) {
        this.JobsLocalStorage.splice(jobIndexLocal, 1);
      }
      let localSt = this.localStorageService.isSupported;
      if (localSt) {
        this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
      }
      this.IsChangingJobStatus = false;
      this.jobChangeStatusShowDialog = false;
      this.jobChangeStatusContainer = null;
      this.transportService.updateJobItemById(checker.Id);
    });
  }

  SendEndJobByAdmin(job: JobLight)
  {
    if (job.NeedChecking)
    {
      this.EndByAdminWithMessageShowDialog(job);
    }
    else
    {
      this.EndByAdminWithoutMessage(job);
    }
  }


  EndByAdminWithMessageShowDialog(job: JobLight) {
    //открываем окно дописывания работы
    this.ChangeJobStatusByAdminShowDialog(job, this.jobChangeStatusMode.EndByWorkerWithMessage);
  }

  ChangeJobStatusByAdminShowDialog(job: JobLight, mode: number) {
    this.jobChangeStatusContainer = new JobChangeStatusContainer(job, mode);
    if (this.context == this.ContextJobEnum.Notification) this.jobChangeStatusContainer.JobId = job.JobId;
    // this.jobChangeStatusShowDialog = true;

    job.VisibleEndJob = true;
    job.JobEndingByAdmin=true;
    job.ModeToSend = mode;
    DOMHelper.FocusElement("changeJobStatusInput" + job.Id, 50);
  }

  ConfirmChangingJobStatusByAdmin(job: JobLight) {
    //метод решатель, который вызовет необходимый метод завершения работы
    this.jobChangeStatusContainer = new JobChangeStatusContainer(job, job.ModeToSend);
    this.jobChangeStatusContainer.Message = job.MessageToSend;
    if (this.context == this.ContextJobEnum.Notification) this.jobChangeStatusContainer.JobId = job.JobId;
    this.EndByAdminWithMessage();
  }

  EndByAdminWithMessage() {
    if (this.IsChangingJobStatus) return;
    this.IsChangingJobStatus = true;
    //сохраняем дописанное сообщение в работу и закрываем ее
    let checker = JobChangeStatusAsker.FromContainer(this.UserId, this.SessionKey, this.jobChangeStatusContainer);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = this.jobChangeStatusContainer.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.endByAdmin(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        this.IsChangingJobStatus = false;
        return;
      }
      if(data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();

        this.IsChangingJobStatus = false;
        this.jobChangeStatusShowDialog = false;
        this.jobChangeStatusContainer = null;
        this.GetJobCounter();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      // this.deleteJob.emit();
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
      } else {
        this.job = data.Jobs[0];
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
      }
      let jobIndexLocal = this.JobsLocalStorage.findIndex(c => c.Id == this.jobChangeStatusContainer.JobId);
      if (jobIndexLocal != -1) {
        this.JobsLocalStorage.splice(jobIndexLocal, 1);
      }
      let localSt = this.localStorageService.isSupported;
      if (localSt) {
        this.localStorageService.set("JobsMessageSaver", JSON.stringify(this.JobsLocalStorage));
      }
      this.IsChangingJobStatus = false;
      this.jobChangeStatusShowDialog = false;
      this.jobChangeStatusContainer = null;
      this.transportService.updateJobItemById(checker.Id);
    });
  }


  EndByAdminWithoutMessage(job: JobLight) {
    //метод завершения работы без дописки

    let checker = new JobChangeStatusAsker(this.UserId, this.SessionKey, job.Id);
    if (this.context == this.ContextJobEnum.Notification) checker.Id = job.JobId;
    this.runJobLoading.emit();
    this.httpServiceJobNew.endByAdmin(checker).subscribe((data: JobGiver) => {
      this.stopJobLoading.emit();
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 300 || data.Code == 303) {
        console.log(data.Message);
        return;
      }
      if(data.Code == 302){
        alert(this.jobs_Langset.JobFinished); // Лангсет сюда
        console.log(data.Message);
        this.refreshSingleJob();
        return;
      }
      if (data.Code == 301) {
        console.log(data.Message);
        this.deleteJob.emit();
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }
      if (this.context == this.ContextJobEnum.Notification) {
        this.deleteJob.emit();
      } else {
        this.job = data.Jobs[0];
        this.updateCounter.emit();
        setTimeout(()=>{this.statusChanged.emit(this.job)},10);
        this.transportService.updateJobItemById(checker.Id);
      }
    });
  }
  

  DeleteNotification() {
    this.deleteNotification.emit();
  }

  CloseJobDeleteWorker(job: JobLight) {
    this.closeJobAsAdmin.emit(job);
  }


  TagListChange()
  {
    this.tagsChanged.emit();
  }

}

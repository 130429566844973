/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,

} from '@angular/core';


import {LocalStorageService} from 'angular-2-local-storage';


import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver, SingleIdGiver } from '../../../models/model.http';
import * as _ from 'lodash';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import {  WorkerProfile } from '../../../models/model.worker';
import { Chats_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { DeleteSession } from '../../../store/actions/session.action';
import { AdditionalMessagesAsker, ChatAccessGiver, ChatGiver, ChatMessagesRefreshWebGiver, ChatNotificator, ChatSingleAsker, FullOpenedChat, ReadMessageNotificator } from '../../../models/model.chat';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { SignalRService } from '../../../services/signalRService';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';


declare var require: any;


@Component({
    selector: 'chatContainer',
    templateUrl: 'temp.chatContainer.html',
    styleUrls: ['chatContainer.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class ChatContainerComponent implements OnInit {
    
    @Input() currentWorker: WorkerProfile;
    @Input() openMode: string;
    
    RefreshChatsInList: Subject<boolean> = new Subject<boolean>();
    ScrollToMessage: Subject<number> = new Subject<number>();
    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    
    langset: LanguageSetCommon = new LanguageSetCommon();
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;

    chatAccess: boolean = false;
    isLoading: boolean = true;
    chatViewMode:number= 0;
    //0 - chatlist; 1 - chat; 2 - add; 3 - edit; 4 -searchall; 5 - searchinchat
    CurrentChat:FullOpenedChat;
    CurrentChatId: number=-1;
    LoadedChats: FullOpenedChat[]=[];
    SearchInChatList: Subject<string> = new Subject<string>();
    SearchInChat: Subject<string> = new Subject<string>()
    StopSearchInChat: Subject<boolean> = new Subject<boolean>()
    ReadMessageInChat: Subject<number> = new Subject<number>();

    BackToChatEv: Subject<any> = new Subject<any>();
    RefreshedCurrent: Subject<any> = new Subject<any>();

    
    @Input () OpenedChatId: Subject<any> = new Subject<any>();
    PersonalChatWorker: number=0;

    chatSelecting: boolean = false;
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;
    //CurrentOpenedChat: 

    @Output() CloseChatEvent: EventEmitter<any> = new EventEmitter<any>();
   
    
    
  constructor(
    private store$: Store,
    private router: Router,
    
    private httpServiceChat: HttpServiceChat,
    public signalRService: SignalRService,
    private sessionService: SessionService,
    private languageService: LanguageService
              ) { }




              
            
              ngOnDestroy() {
                //
                if(this.OpenedChatId.observers==undefined ||this.OpenedChatId.observers==null || this.OpenedChatId.observers.length>0)
                {
                  this.OpenedChatId.observers=[];
                }
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);

                this.OpenedChatId.subscribe((c)=>{
                 setTimeout(()=>{this.SelectChat(c);},100);
                })


                this.signalRService.NewMessage.subscribe((message: ChatNotificator)=>
                {

                  
                  //if(this.chatViewMode==0)
                 // {
                    if((message.NotifiedWorkers!=undefined &&  message.NotifiedWorkers!=null && message.NotifiedWorkers.findIndex(c=>c==this.UserId)>-1) ||
                    (message.UnnotifiedWorkers!=undefined &&  message.UnnotifiedWorkers!=null && message.UnnotifiedWorkers.findIndex(c=>c==this.UserId)>-1))
                    {
                      this.RefreshChatsInList.next(true);
                     
                    }
                 // }
                  if(this.LoadedChats!=undefined && this.LoadedChats!=null && this.LoadedChats.length>0)
                  {
                    

                    if((message.NotifiedWorkers!=undefined &&  message.NotifiedWorkers!=null && message.NotifiedWorkers.findIndex(c=>c==this.UserId)>-1)
                    || (message.UnnotifiedWorkers!=undefined &&  message.UnnotifiedWorkers!=null && message.UnnotifiedWorkers.findIndex(c=>c==this.UserId)>-1))
                    {
                      let ind = this.LoadedChats.findIndex(c=>c.Id==message.Chat)
                        if(ind>-1)
                        {
                          if((message.NotifiedWorkers!=undefined &&  message.NotifiedWorkers!=null && message.NotifiedWorkers.findIndex(c=>c==this.UserId)>-1))
                          {this.LoadedChats[ind].UnreadedCount++;}
                          this.RefreshLoadedChats(message.Chat);
                        }
                      
                    }
                  }
                });

                this.signalRService.MessageReaded.subscribe((message: ReadMessageNotificator)=>
                {
                  this.MarkMessageAsReaded(message.MessageCrossId);
                });
                
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);

                this.CheckChatAccess();
                      
                

                
              }


              closeChat() {
                this.CloseChatEvent.emit();
              }
              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }


              CheckChatAccess()
              {
                let checker = new CommonRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                this.httpServiceChat.chatGetAccesss(checker).subscribe((data: ChatAccessGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }
                  this.isLoading = false;
                  
                  if (data.Code==0)
                  {
                    this.chatAccess=data.Allow;
                  }
                  
                })
              }

              SelectChat(id: number, needRefresh: boolean=false)
              {
                
                if(this.chatSelecting)
                {
                  return;
                }

                this.chatSelecting=true;
                let ind =-1;
                if(!needRefresh)
                {ind = this.LoadedChats.findIndex(c=>c.Id==id);}
                
                if(ind>-1)
                {
                  this.CurrentChat=this.LoadedChats[ind];
                  this.CurrentChatId=this.CurrentChat.Id;
                  this.chatViewMode=1;
                  this.chatSelecting=false;
                  this.BackToChatEv.next();
                }
                else
                {
                  
                  let checker = new ChatSingleAsker();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=id;
                  checker.MessageId=0;
                  this.httpServiceChat.chatGet(checker).subscribe((data: ChatGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 300) {
                      console.log(data.Message)
                      return;
                    }
                    if (data.Code == 301) {
                      console.log(data.Message)
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      
                      
                      let chat = new FullOpenedChat();
                      chat.CreateFromSmall(data.Chat, this.UserId);
                      if(!needRefresh)
                      {
                      this.LoadedChats.push(chat);
                      }
                      else
                      {
                        let indChat=this.LoadedChats.findIndex(c=>c.Id==data.Chat.Id)
                        if(indChat>-1)
                        {
                          this.LoadedChats[indChat].IsPersonal=chat.IsPersonal;
                          this.LoadedChats[indChat].Name=chat.Name;
                          this.LoadedChats[indChat].Messages = chat.Messages;
                         // this.LoadedChats.splice(indChat, 1, chat);
                        }
                      }
                      this.CurrentChat=chat;
                      this.CurrentChatId=this.CurrentChat.Id;
                      this.chatViewMode=1;
                      this.chatSelecting=false;
                    }
                    
                  })
                }
                
              }

              CloseChat(id: number)
              {
                
                this.chatViewMode=0;
                this.CurrentChat=null;
                this.CurrentChatId=-1;
                let ind = this.LoadedChats.findIndex(c=>c.Id==id);
                if(ind>-1)
                {
                  this.LoadedChats.splice(ind,1);
                }
              }

              exitCurrentChat()
              {
                this.CurrentChat.CanEdit=false;
                this.BackToChatList()
              }
       
              BackToChatList()
              {
               
                this.chatViewMode=0;
                this.CurrentChat=null;
                this.CurrentChatId=-1;
              }


              OpenAddChatView()
              {
                this.chatViewMode=2;
                this.CurrentChat=null;
                this.CurrentChatId=-1;
              }


              OpenEditChatView()
              {
                if(this.CurrentChat.IsPersonal)
                {
                  this.PersonalChatWorker=this.CurrentChat.WorkerId;
                }
                else
                {
                  this.PersonalChatWorker=0;
                }
                this.chatViewMode=3;
              }


              SearchFilterChanged(filter: string)
              {

                
                if(filter!=undefined && filter!=null && filter!="")
                {
                  if(this.chatViewMode==0 )
                  {
                      this.chatViewMode=4;
                      setTimeout(()=>{this.SearchInChatList.next(filter)},10);
                  }
                  else
                  {
                    if(this.chatViewMode==4 )
                    {
                     
                      
                      this.SearchInChatList.next(filter);
                    }
                    else
                    {
                      if(this.chatViewMode==1 )
                      {
                       
                        this.SearchInChat.next(filter);
                        //setTimeout(()=>{this.SearchInCurrentChat.next(filter)},10);
                      }
                    }
                  }

                }
                else
                {
                  if(this.chatViewMode==4)
                  {
                    this.chatViewMode=0;
                  }
                  if(this.chatViewMode==1)
                  {
                    this.StopSearchInChat.next(true);
                  }
                }
                
              }


              AddChatWithUser(id: number)
              {
               
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=id;
                this.httpServiceChat.chatAddPersonal(checker).subscribe((data: SingleIdGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }

                  if (data.Code == 300) {
                    alert("Необходимо выбрать пользователядля создания чата")
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.SelectChat(data.Id);
                  }
                  
                })
              }


              LoadMessageInChat(messageChat:number[])
              {

                let ind = this.LoadedChats.findIndex(c=>c.Id==messageChat[1]);
                if(ind>-1)
                {
                  this.CurrentChat=this.LoadedChats[ind];
                  this.CurrentChatId=this.CurrentChat.Id;
                  this.chatViewMode=1;
                }
                else
                {
                  let checker = new ChatSingleAsker();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=messageChat[1];
                  checker.MessageId=messageChat[0];
                  this.httpServiceChat.chatGet(checker).subscribe((data: ChatGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                   
                    if (data.Code == 300) {
                      console.log(data.Message)
                      return;
                    }
                    if (data.Code == 301) {
                      console.log(data.Message)
                      return;
                    }

                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      let chat = new FullOpenedChat();
                      chat.CreateFromSmall(data.Chat, this.UserId);
                      this.LoadedChats.push(chat);
                      this.CurrentChat=chat;
                      this.CurrentChatId=this.CurrentChat.Id;
                      this.chatViewMode=1;
                      setTimeout(()=>{this.ScrollToMessage.next(messageChat[0]);},100)
                      
                    }
                    
                  })
                }



              }


              RefreshLoadedChats(chatId: number)
              {
                
                  let ind = this.LoadedChats.findIndex(c=>c.Id==chatId);
                  if(ind>-1)
                  {
                    let checkerMes = new AdditionalMessagesAsker();
                    checkerMes.SessionKey = this.SessionKey;
                    checkerMes.ChangerId = this.UserId;
                    checkerMes.Id=chatId;
                    if(this.LoadedChats[ind].Messages!=undefined && this.LoadedChats[ind].Messages!=null && this.LoadedChats[ind].Messages.length>0)
                    {
                      checkerMes.MessageDate=this.LoadedChats[ind].Messages[this.LoadedChats[ind].Messages.length-1].Date;
                    }
                    else
                    {
                      checkerMes.MessageDate=new Date(1,1,1);
                    }
                    this.httpServiceChat.chatRefreshMessages(checkerMes).subscribe((data: ChatMessagesRefreshWebGiver) => {
                      if (data.Code == 100) {
                        this.unLogin();
                        return;
                      }
                     
                      if (data.Code == 500) { console.error(data.Message); return; }
    
                      if (data.Code==0)
                      {
                        for(let i=0; i<data.Messages.length; i++)
                        {
                          if(this.LoadedChats[ind].Messages.findIndex(c=>c.Id==data.Messages[i].Id)==-1)
                          {
                            this.LoadedChats[ind].Messages.push(data.Messages[i]);
                          }
                          
                        }
                        this.LoadedChats[ind].CanLoadNext=false;
                        if(this.CurrentChat!=undefined && this.CurrentChat!=null && this.CurrentChat.Id==chatId)
                        {
                          this.RefreshedCurrent.next();
                        }
                      }
                      
                    })
                  }
              }


              MarkMessageAsReaded(crossId: number)
              {
                for(let i=0; i<this.LoadedChats.length; i++)
                {
                  let ind = this.LoadedChats[i].Messages.findIndex(c=>c.CrossId==crossId);
                  if(ind>-1)
                  {
                    this.LoadedChats[i].Messages[ind].Unreaded=false;
                  }
                }

                this.ReadMessageInChat.next(crossId);

              }

              CancelChatAdd(ev: any)
              {
                
                this.chatViewMode=0;
                this.CurrentChat=null;
                this.CurrentChatId=-1;
              }


              CancelChatEdit(ev: any)
              {
                
                this.chatViewMode=1;
                this.SelectChat(this.CurrentChatId);
              }



              ResendInExistChat(delegate:any[])
              {
                
                let ind =-1;
                ind = this.LoadedChats.findIndex(c=>c.Id==delegate[0]);
                
                if(ind>-1)
                {
                  this.LoadedChats[ind].AttachedMessages=_.cloneDeep(delegate[1]);
                  this.CurrentChat=this.LoadedChats[ind];
                  this.CurrentChatId=this.CurrentChat.Id;
                  this.chatViewMode=1;
                  this.BackToChatEv.next();
                }
                else
                {
                  let checker = new ChatSingleAsker();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=delegate[0];
                  checker.MessageId=0;
                  this.httpServiceChat.chatGet(checker).subscribe((data: ChatGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }

                    if (data.Code == 300) {
                      console.log(data.Message)
                      return;
                    }
                    if (data.Code == 301) {
                      console.log(data.Message)
                      return;
                    }
                   
                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      
                      
                      let chat = new FullOpenedChat();
                      chat.CreateFromSmall(data.Chat, this.UserId);
                      chat.AttachedMessages=_.cloneDeep(delegate[1]);
                      this.LoadedChats.push(chat);
                      this.CurrentChat=chat;
                      this.CurrentChatId=this.CurrentChat.Id;
                      this.chatViewMode=1;
                    }
                    
                  })
                }
              }


              ResendInNewChat(delegate:any[])
              {
                
                let checkerAdd = new SingleIdRequest();
                checkerAdd.ChangerId = this.UserId;
                checkerAdd.SessionKey = this.SessionKey;
                checkerAdd.Id=delegate[0];
                this.httpServiceChat.chatAddPersonal(checkerAdd).subscribe((data: SingleIdGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }

                  if (data.Code == 300) {
                    alert("Необходимо выбрать пользователядля создания чата")
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    let checker = new ChatSingleAsker();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=data.Id;
                  checker.MessageId=0;
                  this.httpServiceChat.chatGet(checker).subscribe((data: ChatGiver) => {
                    if (data.Code == 100) {
                      this.unLogin();
                      return;
                    }
                   
                    if (data.Code == 500) { console.error(data.Message); return; }
  
                    if (data.Code==0)
                    {
                      
                      
                      let chat = new FullOpenedChat();
                      chat.CreateFromSmall(data.Chat, this.UserId);
                      chat.AttachedMessages=_.cloneDeep(delegate[1]);
                      this.LoadedChats.push(chat);
                      this.CurrentChat=chat;
                      this.CurrentChatId=this.CurrentChat.Id;
                      this.chatViewMode=1;
                    }
                    
                  })
                  }
                  
                })
              }
}

import { SingleIdRequest } from "../../model.http";
import { JobChangeStatusContainer } from "../Inner/model.JobChangeStatusContainer";
import { ExtendedHelper } from "../../../config/ExtendedHelper";

import { JobChangeStatusFromNotificationsContainer } from "../Inner/model.JobChangeStatusFromNotificationsContainer";

export class JobChangeStatusAsker extends SingleIdRequest {
  Message: string;

  constructor(changerId: number, sessionKey: string, jobId: number) {
    super(changerId, sessionKey, jobId);
  }

  static FromContainer(changerId: number, sessionKey: string, c: JobChangeStatusContainer) {
    let asker = new JobChangeStatusAsker(changerId, sessionKey, c.JobId);
    asker.Message = ExtendedHelper.ReplaceBadThings(c.Message);
    asker.Message = asker.Message.replace(/\n/gi, '<br/>');
    return asker;
  }

  static FromNotifContainer(changerId: number, sessionKey: string, c: JobChangeStatusFromNotificationsContainer) {
    let asker = new JobChangeStatusAsker(changerId, sessionKey, c.JobId);
    asker.Message = ExtendedHelper.ReplaceBadThings(c.Message);
    asker.Message = asker.Message.replace(/\n/gi, '<br/>');
    return asker;
  }

}


import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
/**
 * Created by MosNeuro on 30.01.2017.
 */
import { Injectable } from '@angular/core'; import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';




import { HttpServiceUrl } from './httpserv.url';
import { ParentChecker } from "../models/model.parentChecker";
import { Deleting } from "../models/model.deleting";
import {
    RuleListFromAngular, AccessGroupsToAddAsker,
    AccessGroupInClientProjectAddedAsker, RuleListReduced, AccessGroupNewOwnerSetter,
    AccessGroupsShowToChooseNewOwnerAsker, AccessGroupConnectionDeleter, AccessGroupDeleteAsker
} from "../models/model.accessGroup";
import { ConnectionToSmth } from "../models/model.connection";
import {

    WorkerToAccessGroup
} from "../models/model.connectionToAccessGroup";

@Injectable()
export class HttpServiceAccessGroup {

    constructor(private http: HttpClient, private param: ServerString) {
    }

    getGroups(obj: ParentChecker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'accessgroupshow', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }


    showWorkersToAddToGroup(obj: ConnectionToSmth) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupWorkerShowToAdd', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    addWorkerToGroup(obj: WorkerToAccessGroup) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupWorkerAdd', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    getAccessGroupsOfCurrentWorker(obj: ParentChecker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessSystemController/GetGroupsOfWorker', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    getSingleGroupWithRulesToRedact(obj: Deleting) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupLoadGroupToRedact', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    inClientProjectShowGroupsAdded(obj: AccessGroupInClientProjectAddedAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        //alert(this.param.fullServerString()+'AccessGroupInClientProjectShowAdded');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectShowAdded', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    inClientProjectShowGroupsToAdd(obj: AccessGroupsToAddAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectShowGroupsToAdd', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }



    inClientProjectShowGroupsToChooseNewOwner(obj: AccessGroupsShowToChooseNewOwnerAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectNewOwnerGroupsShow', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }
    inClientProjectChooseNewOwner(obj: AccessGroupNewOwnerSetter) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectNewOwnerChoose', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    inClientProjectLoadGroupConnectionToRedact(obj: AccessGroupInClientProjectAddedAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectLoadPartialGroupToRedact', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    inClientProjectSaveGroupConnection(obj: RuleListReduced) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectConnectSave', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    inClientProjectDeleteGroupConnection(obj: AccessGroupConnectionDeleter) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupInClientProjectConnectDelete', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    /*crudGroup(obj: RuleListFromAngular) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupCrud', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }*/

    /*deleteAccessGroup(obj: AccessGroupDeleteAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
        let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString() + 'AccessGroupDelete', body, { headers: headers, withCredentials: true }).pipe(
            //.map((resp: Response)=>resp.json())
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }*/




}

import { SingleIdRequest, CommonRequest, CommonResponse } from "./model.http";
import { Common } from "./model.common";
import { ExtendedHelper } from "../config/ExtendedHelper";
import { CommonWithSelect, CommonWithSelectAndAdditional } from "./model.commonWithSelect";
import { MergeMapSubscriber } from "rxjs/internal/operators/mergeMap";
import * as _ from 'lodash';
/**
 * Created by MosNeuro on 03.04.2018.
 */


// public enum ParameterTypeEnum
// {
//   String = 0,
//   LongInt,
//   Decimal,
//   Bool,
//   ValueSet
//
// }

export class ParameterTypeRotateAsker extends SingleIdRequest {
  DirectionDownNotUp: boolean;
}
//Класс используется для операций над набором значений для параметра при его СОЗДАНИИ
export class ValueForValueSet extends Common {
  Editable: boolean;
  NewName: string;
  constructor(id: number, name: string, editable: boolean) {
    super();
    this.Id = id;
    this.Name = name;
    this.Editable = editable;
  }
}

export class ParameterTypeCreateContainer {

  InClient: boolean;
  InProject: boolean;
  Name: string;
  TypeList: Common[];
  //SelectedType:Common;
  SelectedTypeId: number = 0;
  SelectedType: Common[];
  AlwaysShow: boolean;
  ShowInResults: boolean;
  UseInFiltering: boolean;

  StringInput: string;
  NumberInput: number;
  Round: number;
  BoolInput: number;

  ValueSet: ValueForValueSet[];
  SelectedValueId: number;
  SelectedValue: ValueForValueSet[];

  NewValueInput: string;
  NewValueShowDialog: boolean;

  EditingValue: ValueForValueSet;

  constructor(data: ParameterTypeCreateGiver, notChoosenTitle: string, type: number = 0) {
    //super();
    this.TypeList = data.TypeList;

    if (type == 0) {
      this.InProject = true;
      this.InClient = false;
    }
    else {
      this.InProject = false;
      this.InClient = true;
    }
    let notChoosen = new ValueForValueSet(-1, notChoosenTitle, false);
    this.ValueSet = [];
    this.ValueSet.splice(0, 0, notChoosen);

    this.SelectedValue=[];
    this.SelectedValue.push(new ValueForValueSet(-1, notChoosenTitle, false));


    this.SelectedTypeId = -1;
    this.SelectedType=[];
    this.SelectedType.push(new Common(-1, "Выбрать"))
  }

}
export class ParameterTypeCreateGiver extends CommonResponse {
  TypeList: Common[];
}

export class ParameterTypeCreateAsker extends CommonRequest {
  Name: string;
  Type: number;
  AlwaysShow: boolean;
  UseInFiltering: boolean;
  ShowInResults: boolean;
  StringValue: string;
  DoubleValue: number;
  BoolValue: number;
  IdValue: number;
  Round: number;

  ValueSet: Common[];

  constructor(userId: number, sessionKey: string, container: ParameterTypeCreateContainer) {
    super();
    this.ChangerId = userId;
    this.SessionKey = sessionKey;
    this.Name = ExtendedHelper.ReplaceBadThings(container.Name);
    this.AlwaysShow = container.AlwaysShow;
    this.Type = container.SelectedTypeId;
    this.ShowInResults = container.ShowInResults;
    //alert(container.SelectedTypeId);
    if (container.SelectedTypeId == 0) {
      this.StringValue = container.StringInput == null ? null : ExtendedHelper.ReplaceBadThings(container.StringInput);
      this.UseInFiltering = container.UseInFiltering;
    }
    else if (container.SelectedTypeId == 1) {

      this.DoubleValue = container.NumberInput;
    }
    else if (container.SelectedTypeId == 2) {
      this.Round = container.Round;
      this.DoubleValue = container.NumberInput;
    }

    else if (container.SelectedTypeId == 3) {
      this.BoolValue = container.BoolInput;
    }
    else if (container.SelectedTypeId == 4) {
      // this.ValueSet = [];
      // let set = container.ValueSet;
      // for (let i = 0; i < set.length; i++) {
      //   if (!set[i].Editable) continue;
      //   let value = new Common();
      //   value.Id = set[i].Id;
      //   value.Name = ExtendedHelper.IsNullOrEmpty(set[i].Name) ? "" : ExtendedHelper.ReplaceBadThings(set[i].Name);
      //   this.ValueSet.splice(this.ValueSet.length, 0, value);
      // }
      this.ValueSet = container.ValueSet.filter(c => c.Editable).map(c => new Common(c.Id, ExtendedHelper.IsNullOrEmpty(c.Name) ? "" : ExtendedHelper.ReplaceBadThings(c.Name)));
      this.IdValue = container.SelectedValueId;
    }
  }
}

export class ParameterSetting {
  SearchFilterProject: string = '';
  SearchFilterClient: string = '';
}

export class ParameterTypeEditContainer extends Common {
  InClient: boolean;
  InProject: boolean;
  OldName: string;
  AlwaysShow: boolean;
  UseInFiltering: boolean;
  SelectedType: Common;
  ShowInResults: boolean;
  NumberInput: number;
  RoundTo: number;

  StringInput: string;

  BoolInput: number;

  ValueSet: ValueForValueSetOutEditable[];
  SelectedValueId: number;
  SelectedValue: ValueForValueSetOutEditable[];

  NewValueInput: string;
  NewValueShowDialog: boolean;

  EditingValue: ValueForValueSetOutEditable;

  public constructor(g: ParameterTypeEditGiver, type: number = 0) {
    super();
    if (type == 0) {
      this.InProject = true;
      this.InClient = false;
    }
    else {
      this.InProject = false;
      this.InClient = true;
    }
    this.Id = g.Id;
    this.Name = g.Name;
    this.OldName = g.Name;
    this.AlwaysShow = g.AlwaysShow;
    this.SelectedType = g.SelectedType;
    this.ShowInResults = g.ShowInResults;
    switch (g.SelectedType.Id) {
      case 0:
        this.StringInput = g.StringValue;
        this.UseInFiltering = g.UseInFiltering;
        break;
      case 1:
        this.NumberInput = g.NumberValue;
        break;
      case 2:
        this.NumberInput = g.NumberValue;
        this.RoundTo = g.RoundTo;
        break;
      case 3:
        this.BoolInput = g.BoolValue;
        break;
      case 4:

        this.ValueSet = g.ValueSet.map(c => ValueForValueSetOutEditable.fromParent(c));
        let index = this.ValueSet.findIndex(c => c.Index == g.SelectedValueOfSet);
        if (index == -1) index = 0;
        this.SelectedValueId = this.ValueSet[index].Index;

        this.SelectedValue=[];
    this.SelectedValue.push(this.ValueSet[index]);

        break;
    }
  }


}

export class ParameterTypeEditGiver extends CommonResponse {
  Id: number;
  Name: string;
  AlwaysShow: boolean;
  UseInFiltering: boolean;
  SelectedType: Common;
  ShowInResults: boolean;
  NumberValue: number;
  RoundTo: number;
  StringValue: string;
  BoolValue: number;

  ValueSet: ValueForValueSetOut[];
  SelectedValueOfSet: number;
}

export class ParameterTypeEditAsker extends SingleIdRequest {
  Name: string;
  AlwaysShow: boolean;
  UseInFiltering: boolean;
  StringValue: string;
  DoubleValue: number;
  BoolValue: number;
  IdValue: number;
  ShowInResults: boolean;
  ValueSet: ValueForValueSetOut[];
  public constructor(userId: number, sessionKey: string, container: ParameterTypeEditContainer) {
    super();
    this.ChangerId = userId;
    this.SessionKey = sessionKey;
    this.Id = container.Id;
    this.Name = ExtendedHelper.ReplaceBadThings(container.Name);
    this.AlwaysShow = container.AlwaysShow;
    this.ShowInResults = container.ShowInResults;

    switch (container.SelectedType.Id) {
      case 0:
        this.StringValue = ExtendedHelper.ReplaceBadThings(container.StringInput);
        this.UseInFiltering = container.UseInFiltering;
        return;

      case 1:
        this.DoubleValue = container.NumberInput;
        return;

      case 2:
        this.DoubleValue = container.NumberInput;
        return;

      case 3:
        this.BoolValue = container.BoolInput;
        return;

      case 4:

       
        this.ValueSet = container.ValueSet.filter(c => c.Editable).map(c => ValueForValueSetOut.fromEditable(c));
        this.IdValue = container.SelectedValueId;
        return;
    }
  }
}

export class ParameterTypeListGiver extends CommonResponse {
  ParameterTypeList: ParameterTypeMoveable[];
}

export class ParameterTypeMoveable extends Common {
  NameBuffer: string = '';
  MoveUp: boolean;
  MoveDown: boolean;
  Visible: boolean = true;
  CountSet: number;
  constructor(data: ParameterTypeMoveable) {
    super(data.Id, data.Name);
    this.NameBuffer = data.Name;
    this.MoveUp = data.MoveUp;
    this.MoveDown = data.MoveDown;
    this.Visible = true;
    this.CountSet = data.CountSet;
  }
}

export class ClientParameterTemplateListGiver extends CommonResponse {
  List: ClientParameterTemplateOut[];
}

export class ClientParameterTemplateOut extends Common {
  Count: number;
  constructor(id: number, name: string, count: number) {
    super(id, name);
    this.Count = count;
  }
}

export class ParameterTypeWithVisible extends ClientParameterTemplateOut {
  NameBuffer: string = '';
  Visible: boolean = true;
  constructor(data: ClientParameterTemplateOut) {
    super(data.Id, data.Name, data.Count);
    this.NameBuffer = data.Name;
    this.Visible = true;
  }
}



export class ParameterInProjectAddDeleteDefaultAsker extends SingleIdRequest {
  ParameterId: number;

  constructor(changerId: number, sessionKey: string, projectId: number, paramId: number) {
    super();
    this.ChangerId = changerId;
    this.SessionKey = sessionKey;
    this.Id = projectId;
    this.ParameterId = paramId;
  }
}

export class ParameterInProjectEditAsker extends ParameterInProjectAddDeleteDefaultAsker {
  StringValue: string;
  DoubleValue: number;
  BoolValue: number;
  IdValue: number;

  constructor(changerId: number, sessionKey: string, projectId: number, param: ParameterInProject) {
    super(changerId, sessionKey, projectId, param.Id);
    if (param.SelectedType.Id == 0) {
      this.StringValue = ExtendedHelper.ReplaceBadThings(param.StringValue);
    }
    else if (param.SelectedType.Id == 1) {
      this.DoubleValue = param.NumberValue;
    }
    else if (param.SelectedType.Id == 2) {
      this.DoubleValue = param.NumberValue;
    }
    else if (param.SelectedType.Id == 3) {
      this.BoolValue = param.BoolValue;
    }
    else if (param.SelectedType.Id == 4) {
      this.IdValue = param.SelectedValueOfSet;
    }
  }
}

export class ParameterInProject extends Common {
  AlwaysShow: boolean;
  SelectedType: Common;

  NumberValue: number;
  StringValue: string;
  StringValueAfterLink: string;


  BoolValue: number;
  SelectedValueOfSet: number;

  ValueSet: Common[];

  Saved: number;// = 0;
  QueryNum: number;

  IsEdit: boolean = false;

  static cloneParam(param): ParameterInProject {
    let item: ParameterInProject = new ParameterInProject();
    item.Id = param.Id;
    item.Name = param.Name;
    item.AlwaysShow = param.AlwaysShow;
    item.SelectedType = param.SelectedType;
    item.NumberValue = param.NumberValue;
    item.StringValue = param.StringValue || '';
    item.StringValueAfterLink = param.StringValue;
    item.BoolValue = param.BoolValue;
    item.SelectedValueOfSet = param.SelectedValueOfSet;
    item.ValueSet = param.ValueSet;
    item.Saved = param.Saved;
    item.QueryNum = param.QueryNum;
    item.findLinks();
    return item;
  }

  initEdit() {
    this.IsEdit = true;
  }

  closeEdit() {
    this.IsEdit = false;
    this.findLinks();
  }

  private findLinks() {
    let separator = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, "gim");
    this.StringValueAfterLink = this.StringValue.replace(separator, (value) => {
      return `<a class="link-param param-link-${this.Id}" target="_blank" href="https://${value}" contenteditable="false" (click)="$event.stopPropagation();">${value}</a>`
    })
    setTimeout(() => {
      let links = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName(`param-link-${this.Id}`)
      Array.prototype.forEach.call(links, function (el) {
        el.contentEditable = false;
      });
    });
  }

}

export class ParameterListInProjectGiver extends CommonResponse {
  ParameterList: ParameterInProject[];
}




export class ParameterInProjectValue {
  NumberValue: number;
  StringValue: string;
  BoolValue: number;
  SelectedValueOfSet: number;
}
export class ParameterInProjectSingleGiver extends CommonResponse {
  Parameter: ParameterInProjectValue;
}

export class ParameterToAddListGiver extends CommonResponse {
  ParameterList: Common[];
}
//Класс используется для выгрузки и загрузки с сервера набора значений для РЕДАКТИРОВАНИЯ параметра
export class ValueForValueSetOut extends Common {
  Index: number;

  static fromEditable(e: ValueForValueSetOutEditable): ValueForValueSetOut {
    let c = new ValueForValueSetOut();
    c.Id = e.Id;
    c.Name = ExtendedHelper.ReplaceBadThings(e.Name);
    c.Index = e.Index;
    return c;
  }
}
//Класс используется для операций над набором значений для параметра при его РЕДАКТИРОВАНИИ
export class ValueForValueSetOutEditable extends ValueForValueSetOut {
  NewName: string;
  Editable: boolean;

  static fromParent(p: ValueForValueSetOut): ValueForValueSetOutEditable {
    let c = new ValueForValueSetOutEditable();
    c.Id = p.Id;
    c.Name = p.Name;
    c.Index = p.Index;
    c.Editable = p.Index !== -1;
    return c;
  }

  constructor(length: number = -1, name: string = "") {
    super();
    if (length != -1) {
      this.Id = 0;
      this.Index = length;
      this.Name = name;
      this.Editable = true;
    }
  }

}




export class ClientParameterTemplateCreateAsker extends CommonRequest {
  Name: string;
  Type: number;//тип параметра
  AlwaysShow: boolean;//всегда показывать на странице клиента
  UseInFiltering: boolean;//использовать при поиске клиентов
  ShowInResults: boolean;//показывать в результатах поиска
  StringValue: string;//значение строкового типа
  NumberValue: number;//значение численных типов
  BoolValue: boolean;//значение логического типа
  ValueIndex: number;//выбранное значение набора значений
  Round: number;//округление для дробного численного типа
  ValueSet: Common[];//набор значений
  constructor(userId: number, sessionKey: string, container: ParameterTypeCreateContainer) {
    super();
    this.ChangerId = userId;
    this.SessionKey = sessionKey;
    this.Name = ExtendedHelper.ReplaceBadThings(container.Name);
    this.AlwaysShow = container.AlwaysShow;
    this.Type = container.SelectedTypeId;
    this.ShowInResults = container.ShowInResults;
    //alert(container.SelectedTypeId);
    if (container.SelectedTypeId == 0) {
      this.StringValue = container.StringInput == null ? null : ExtendedHelper.ReplaceBadThings(container.StringInput);
      this.UseInFiltering = container.UseInFiltering;
    }
    else if (container.SelectedTypeId == 1) {

      this.NumberValue = container.NumberInput;
    }
    else if (container.SelectedTypeId == 2) {
      this.Round = container.Round;
      this.NumberValue = container.NumberInput;
    }

    else if (container.SelectedTypeId == 3) {
      if (container.BoolInput == 1) {
        this.BoolValue = false;
      }
      else if (container.BoolInput == 2) {
        this.BoolValue = true;
      }
      else {
        this.BoolValue = null;
      }
    }
    else if (container.SelectedTypeId == 4) {


      this.ValueSet = container.ValueSet.filter(c => c.Editable).map(c => new Common(c.Id, ExtendedHelper.IsNullOrEmpty(c.Name) ? "" : ExtendedHelper.ReplaceBadThings(c.Name)));

      for (let i = 0; i < this.ValueSet.length; i++) {
        this.ValueSet[i].Id = i;

      }

      this.ValueIndex = container.SelectedValueId;

    }
  }
}
/*public enum ClientParameterType
{
   String = 0,
   LongInt,
   Decimal,
   Bool,
   ValueSet
}*/

export class CommonListGiver extends CommonResponse {
  List: Common[];
}

export class ClientParameterTemplateEditContainer extends Common {
  AlwaysShow: boolean;
  UseInFiltering: boolean;
  ShowInResults: boolean;
  SelectedType: Common;//в Id содержится значение из ClientParameterType

  StringValue: string;
  NumberValue: number;
  BoolValue: boolean;
  ValueIndex: number;//Index текущего выбранного ValueOfSet
  Round: number;
  ValueSet: ValueForValueSetOut[];

  constructor(container: ParameterTypeEditContainer) {
    super();

    this.Id = container.Id;
    this.Name = ExtendedHelper.ReplaceBadThings(container.Name);
    this.AlwaysShow = container.AlwaysShow;
    this.ShowInResults = container.ShowInResults;
    this.UseInFiltering = false;
    this.SelectedType = container.SelectedType;
    this.Round = container.RoundTo;
    switch (container.SelectedType.Id) {
      case 0:
        this.StringValue = ExtendedHelper.ReplaceBadThings(container.StringInput);
        this.UseInFiltering = container.UseInFiltering;
        return;

      case 1:
        this.NumberValue = container.NumberInput;
        return;

      case 2:
        this.NumberValue = container.NumberInput;
        return;

      case 3:

        if (container.BoolInput == 1) {
          this.BoolValue = false;
        }
        else if (container.BoolInput == 2) {
          this.BoolValue = true;
        }
        else {
          this.BoolValue = null;
        }

        return;

      case 4:
        this.ValueSet = container.ValueSet.filter(c => c.Editable).map(c => ValueForValueSetOut.fromEditable(c));

        console.log(container.ValueSet)
        for (let i = 0; i < this.ValueSet.length; i++) {
          //this.ValueSet[i].Id=i;
          this.ValueSet[i].Index = i;
        }
        console.log(this.ValueSet)
        this.ValueIndex = container.SelectedValueId;
        return;
    }
  }

  //ValueSet:ValueOfSet[];
}

export class ValueOfSet extends Common {
  Index: number;
}

export class ClientParameterTemplateEditContainerGiver extends CommonResponse {
  Container: ClientParameterTemplateEditContainer;
}

export class ClientParameterTemplateEditAsker extends CommonRequest {
  Container: ClientParameterTemplateEditContainer;
}


export class ClientParameterRotateAsker extends SingleIdRequest {
  Position: number;
}




export class ClientParameterInstanceAddAsker extends CommonRequest {
  ClientId: number;
  ParameterId: number;
}


export class ClientParameterInstanceListGiver extends CommonResponse {
  Parameters: ClientParameterInstanceOut[];
}

export class ClientParameterInstanceOut extends Common {
  AlwaysShow: boolean;//всегда показывать в клиенте, нельзя удалять
  SelectedType: Common;//выбранныйтип. Id - код типа, Name читаемое название

  StringValue: string; //строковое значений
  StringValueView?: string; //строковое значений
  StringValueAfterLink: string;

  NumberValue: number;//целое или дробное численное значение
  BoolValue: boolean;//логическое значение

  ValueIndex: number;//для набора значений
  ValueSet: ValueForValueSetOut[];//набор значений

  IsEdit: boolean = false;

  static cloneParam(param): ClientParameterInstanceOut {
    let item: ClientParameterInstanceOut = new ClientParameterInstanceOut();
    item.Id = param.Id;
    item.Name = param.Name;
    item.AlwaysShow = param.AlwaysShow;
    item.SelectedType = param.SelectedType;
    item.NumberValue = param.NumberValue;
    item.StringValue = param.StringValue || '';
    item.StringValueAfterLink = param.StringValue;
    item.BoolValue = param.BoolValue;
    item.ValueIndex=param.ValueIndex;
    // item.SelectedValueOfSet = param.SelectedValueOfSet;
    item.ValueSet = param.ValueSet;
    item.StringValueView=param.StringValueView;
    // item.Saved = param.Saved;
    // item.QueryNum = param.QueryNum;
    // item.findLinks();
    return item;
  }

  initEdit() {
    this.IsEdit = true;
  }

  closeEdit() {
    this.IsEdit = false;
    this.findLinks();
  }

  private findLinks() {
    let separator = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, "gim");
    this.StringValueAfterLink = this.StringValue.replace(separator, (value) => {
      return `<a class="link-param param-link-${this.Id}" target="_blank" href="https://${value}" contenteditable="false" (click)="$event.stopPropagation();">${value}</a>`
    })
    setTimeout(() => {
      let links = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName(`param-link-${this.Id}`)
      Array.prototype.forEach.call(links, function (el) {
        el.contentEditable = false;
      });
    });
  }
}


export class ClientParameterInstanceEditAsker extends ClientParameterInstanceAddAsker {
  StringValue: string;
  NumberValue: number;
  BoolValue: boolean;
  ValueIndex: number;
}




export class ClientParameterSettings {
  Settings: ParameterSettings[] = [];
}

export class ParameterSettings {
  ParameterId: number;
  ParameterSaved: boolean;
  constructor(id: number) {
    this.ParameterId = id;
    this.ParameterSaved = true;
  }


}




















/***********************Новые параметры 18.01.2022***************************/



/*export class ValueSetCreateAsker extends CommonRequest
{
    Name: string;//название
    SetType: number;//тип множества
    Values: string[];//значения для набора
}*/





 enum TypeOfSetOfParameterValues
{
    Closed = 0,// Закрытое множество. Пользователь не может добавить новое значение.
    ExtendableOnApply = 1,// Открытое множество. Для добавления нового значения требуется подтверждение пользователя.
    Extendable = 2//  Открытое множество. Для добавления нового значения не требуется подтверждение пользователя.
}


export class ValueSetCreateContainer 
{
    Name: string;//название
    SetType: number;//тип множества
    PossibleSetTypes: CommonWithSelect[];
    ValueSetType: CommonWithSelect[];//тип множества
    PossibleValueSetTypes: CommonWithSelect[];
    Values: ValueOfSetContainer[];//значения для набора

    SetCurrentType(id: number)
    {
      for(let i=0; i<this.PossibleSetTypes.length; i++)
      {
        if(this.PossibleSetTypes[i].Id==id)
        {
          this.PossibleSetTypes[i].Selected=true;
          this.SetType=id;
        }
        else
        {
          this.PossibleSetTypes[i].Selected=false;
        }
      }
    }

    SetCurrentValueType(id: number)
    {
      for(let i=0; i<this.PossibleValueSetTypes.length; i++)
      {
        if(this.PossibleValueSetTypes[i].Id==id)
        {
          this.PossibleValueSetTypes[i].Selected=true;
          //this.ValueSetType=id;
        }
        else
        {
          this.PossibleValueSetTypes[i].Selected=false;
        }
      }
    }
}

export class ValueSetEditContainer 
{
    Id: number;
    Name: string;//название
    SetType: number;//тип множества
    ValueType: number;
    ValueSetType: CommonWithSelect[];//тип множества
    PossibleValueSetTypes: CommonWithSelect[];
    PossibleSetTypes: CommonWithSelect[];
    Values: CommonWithSelectAndAdditional[];//значения для набора

    SetCurrentType(id: number)
    {
      for(let i=0; i<this.PossibleSetTypes.length; i++)
      {
        if(this.PossibleSetTypes[i].Id==id)
        {
          this.PossibleSetTypes[i].Selected=true;
          this.SetType=id;
        }
        else
        {
          this.PossibleSetTypes[i].Selected=false;
        }
      }
    }
}





export class RecordCreateContainer 
{
    Id:  number;
    Name: string;//название
    PossibleFieldTypes: TypeForDropdown[];
    PossibleValueDisplayType: Common[];
    Fields: RecordFieldCreateContainer[];
   // FormulFieldsContainer:FormulFieldsContainer[];
    constructor()
    {
      this.Name="";
      this.Fields=[];
      this.PossibleFieldTypes=[];
    }
}

enum FieldTypesIdType
{
  simpleType= 0,
  valueSet=1,
  record=2
}

export class RecordFieldCreateContainer extends Common
{
  Position: number;
  IsList: boolean;
  ValueType: number;
  ValueSetId: number;
  RecordTemplateId: number;
  SelectedValueType: TypeForDropdown[];
  CanChangeType: boolean;
  CanChangeList: boolean;
  AxisType:number;
  Round: number;
  ValueSetType:number=0;
  ConfirmOnRemove?: boolean=true;
  CanRemove?: number=0;
  Fields: RecordFieldCreateContainer[]=[];
  Formula: FormulaTokenOut[];
  FormulaCorrect: boolean=true;
  SelectedValueDisplayType: Common[];
}

export class FormulLinks
{
  Id: number;
  ValueType: Number;
}


export class FormulFieldsContainer
{
  Type: number;
  Id: number;
  Value:string;
  IsList: boolean;
  IsSum: boolean;
  Parents:FormulFieldsContainer[];
  IsFormul: boolean;
  //FormulLinks: FormulLinks[];
  LinkOnCurrent:boolean=false;
}


export  class RecordTemplateCreateAsker extends CommonRequest
{
    Name: string; //название

    Fields: RecordFieldContainerV2[];
}
export class RecordFieldContainerV2 extends Common
{
    Position: number; // позиция поля в списке.
    IsList: boolean;//данное поле является списком.
    AxisType:number;
    Round: number;
    ValueType: number; // тип значения. Установка ТОЛЬКО для новых полей.
    ValueSetId?: number; // выбранный для поля тип - набор значений. Установка ТОЛЬКО для новых полей.
    RecordTemplateId?: number; // выбранный для поля тип - вложенная запись. Установка ТОЛЬКО для новых полей.
    Formula: FormulaTokenOut[];
    ValueDisplayType: number;

}



export class RecordTemplateRemoveContainerGiver extends CommonResponse
    {
        Container: RecordTemplateRemoveContainer;
    }
    export class RecordTemplateRemoveContainer
    {
        
        AffectedClientParameters:Common[];//удаляемые параметры клиента
        AffectedProjectParameters:Common[];//удаляемые параметры проекта
        AffectedRecordFields: string[];//записи, поля которых будут удалены.
    }




    export class RecordTemplateContainerGiver extends CommonResponse
    {
      Container: RecordTemplateContainer;
    }

    export class RecordTemplateContainer  extends  Common
    {
      Fields: RecordFieldContainerOutV2[];
    }

    export class RecordFieldContainerOutV2 extends Common
    {
        ValueType: number; //тип значения
        TypeName: string; //название набора значений для типа "Набор значений" / шаблона записи для типа "Запись".
        IsList: boolean;//является списком.
        CanUnlist: boolean;//можно убрать галочку "Является списком".
        Round: number;
        AxisType: number;
        ConfirmOnRemove?: boolean;
    }


    export class RecordTemplateEditAsker extends  RecordTemplateCreateAsker
    {
        Id: number;//Ид редактируемого шаблона записи.
    }




export class ParameterValueSetListGiver extends CommonResponse
{
    CommonValueSets: ParameterValueSet[];
    CustomValueSets: ParameterValueSetCustom[];
}

export class ParameterValueSet extends Common
{
    HasUsages: boolean;//если используется, не может быть удален.
}

export class ParameterValueSetCustom extends Common
    {
      CustomType: number; ///V2ParameterValueSetCustomType
    }



export class ParameterValueSetContainerGiver extends CommonResponse
{
    Container: ParameterValueSetContainer;
}

/*export class ParameterValueSetContainer extends Common
{
  ExtensionType: number;//тип множества
  CustomType: number; ///V2ParameterValueSetCustomType
    Values: ParameterValue[];//значения для набора
}*/



 enum V2ParameterValueSetCustomType// Особый тип набора значений.
    {
        Common = 0,// Стандартный пользовательский набор значений.
        StorageUnitMeasure = 1// Служебный набор значений "Единица измерения позиции склада".
    }


export class ParameterValueSetWithValuesListGiver extends CommonResponse
{
    List: ParameterValueSetWithValues[];
}

export  class ParameterValueSetWithValues extends Common
{
    Values: Common[];//значения множества.
    ValueType: number;//тип множества
    ExtensionType: number;
}

/*export class ValueSetEditAsker extends SingleIdRequest
    {
        Name: string;//название
        SetType: number;//тип множества
         Values: ValueOfSetEditContainer[];//значения для набора
    }

export class ValueOfSetEditContainer extends Common
    {
       MergedWith: number;//массив Id значений, которые слиты в данное.
    }*/


export class ParameterTemplateCreateAsker extends CommonRequest
{
    Name: string ; //название
    AttachmentType: number; // к какой сущности будет относиться

    ValueType: number; // тип значения.

    AlwaysShow: boolean; //всегда показывать на странице.
    UsedInSearch: boolean; //использовать при поиске.
                              //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    ShowInResults: boolean; //показывать в результатах поиска.
                               //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    IsList: boolean; //является списком.

    StringValue:string; //значение строкового типа.

    NumberValue?: number; //значение численных типов.
    Round: number; //округление для дробного численного типа.

    BoolValue?: boolean; //значение логического типа.

    ValueSetId?: number; // выбранный для параметра набор значений.
    SelectedValueId?: number; // выбранное значений из набора.
    NewCreatedValue:ValueOfSetContainer ;//для открытых множеств, если было создано и выбрано новое значение.

    //RecordFields: RecordFieldContainer[]; //поля записи для типа "Запись"
    RecordTemplateId?: number;
    AxisType: number;
    DateValue: Date=null;
}


export class ParameterTemplateCreateContainer 
{
    Name: string ; //название
    AttachmentType: number; // к какой сущности будет относиться

    ValueType: number; // тип значения.

    AlwaysShow: boolean; //всегда показывать на странице.
    UsedInSearch: boolean; //использовать при поиске.
                              //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    ShowInResults: boolean; //показывать в результатах поиска.
                               //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    IsList: boolean; //является списком.

    StringValue:string; //значение строкового типа.

    NumberValue?: number; //значение численных типов.
    NumberDoubleValue?: number; //значение численных типов.
    Round: number; //округление для дробного численного типа.

    BoolValue?: boolean; //значение логического типа.

    ValueSetId?: number; // выбранный для параметра набор значений.
    SelectedValueId?: number; // выбранное значений из набора.
    NewCreatedValue:string;//для открытых множеств, если было создано и выбрано новое значение.

    RecordTemplateId?: number;//поля записи для типа "Запись"
    ValueSets: ParameterValueSetWithValues[];
    ValueSetSelected: ParameterValueSetWithValues;
    ValueTypes:TypeForDropdown[];
    SelectedValueType:TypeForDropdown[];
    SelectedValueInCurrentSet:Common;
    AxisTypes: Common[];
    AxisTypeSelected: Common;
    DateValue: Date=null;
    
    constructor()
    {   
      this.Name=null;
      this.AttachmentType=0; // к какой сущности будет относиться

      this.ValueType=0; // тип значения.

      this.AlwaysShow=false; //всегда показывать на странице.
      this.UsedInSearch=false; //использовать при поиске.
      this.ShowInResults=false; //показывать в результатах поиска.
      this.IsList=false; //является списком.
      this.StringValue=null; //значение строкового типа.
      this.NumberValue=null; //значение численных типов.
      this.NumberDoubleValue=null;
      this.Round=2; //округление для дробного численного типа.
      this.BoolValue=null; //значение логического типа.
      this.ValueSetId=null; // выбранный для параметра набор значений.
      this.SelectedValueId=null; // выбранное значений из набора.
      this.NewCreatedValue=null;//для открытых множеств, если было создано и выбрано новое значение.
      this.RecordTemplateId=null; //поля записи для типа "Запись"
      this.ValueSets=[];
      this.ValueSetSelected=null;
      this.ValueTypes=[];
      this.SelectedValueType=[];
      this.AxisTypes=[];
      this.AxisTypeSelected=null;
      this.DateValue=null;
    }
}




export class ParameterTemplateEditContainer 
{
    Id:number;
    Name: string ; //название

    ValueType: number; // тип значения.
    ValueTypeSelected: Common[];
    SetName: string;
    AlwaysShow: boolean; //всегда показывать на странице.
    UsedInSearch: boolean; //использовать при поиске.
                              //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    ShowInResults: boolean; //показывать в результатах поиска.
                               //Будет использовано только если параметр строковый, или запись, и минимум одно из полей строковое.

    IsList: boolean; //является списком.

    StringValue:string; //значение строкового типа.

    NumberValue?: number; //значение численных типов.
    Round: number; //округление для дробного численного типа.

    BoolValue?: boolean; //значение логического типа.
    SelectedValueId?: number; // выбранное значений из набора.
    NewCreatedValue:string;//для открытых множеств, если было создано и выбрано новое значение.

    RecordTemplateId: number; //поля записи для типа "Запись"
   
    Values:Common[];
    SelectedValueInCurrentSet:Common;
    AxisTypes: Common[];
    AxisTypeSelected: Common;
    DateValue: Date=null;
    
    constructor()
    {   
      this.Name=null;
      this.Id=-1;
      this.ValueType=0; // тип значения.
      this.ValueTypeSelected=[];
      this.AlwaysShow=false; //всегда показывать на странице.
      this.UsedInSearch=false; //использовать при поиске.
      this.ShowInResults=false; //показывать в результатах поиска.
      this.IsList=false; //является списком.
      this.StringValue=null; //значение строкового типа.
      this.NumberValue=null; //значение численных типов.
      this.Round=2; //округление для дробного численного типа.
      this.BoolValue=null; //значение логического типа.
      this.SelectedValueId=null; // выбранное значений из набора.
      this.NewCreatedValue=null;//для открытых множеств, если было создано и выбрано новое значение.
      this.RecordTemplateId=null; //поля записи для типа "Запись"
      this.Values=[];
      this.SelectedValueInCurrentSet=null;
      this.AxisTypes=[];
      this.AxisTypeSelected=null;
      this.DateValue=null;
    }
}


/*export class ServiceParameterEditContainer 
{
    Id:number;
    Name: string ; //название

    ValueTypes:TypeForDropdown[];
    SelectedValueType:TypeForDropdown[];
    
    IsList: boolean; //является списком.
    AxisTypes: Common[];
    AxisTypeSelected: Common[];
    IsListCanChange: boolean; //является списком.
    TypeCanChange: boolean; //является списком.
    
    constructor()
    {   
      this.Name=null;
      this.Id=-1;
      this.ValueTypes=[];
      this.SelectedValueType=[];
      this.IsList=false; //является списком.
      this.AxisTypes=[];
      this.AxisTypeSelected=[];
      this.IsListCanChange=true;
      this.TypeCanChange=true;
    }
}*/





export class RecordFieldContainer extends Common
{
    Position: number; // позиция поля в списке.
    ValueType: number; // тип значения. Не может быть записью. Установка ТОЛЬКО для новых полей.

    StringValue: string; //значение строкового типа.

    NumberValue?: number; //значение численных типов.
    Round: number; //округление для дробного численного типа.

    BoolValue?: boolean; //значение логического типа.

    ValueSetId: number;// выбранный для параметра набор значений. Установка ТОЛЬКО для новых полей.
    SelectedValueId: number; // выбранное значений из набора.
    NewCreatedValue: string;//для открытых множеств, если было создано и выбрано новое значение.
    AxisType: number;
    ValueDisplayType: number;
}

enum TypeOfParameterAttachment
{
    Client = 0,// Относится к параметрам проекта.
    Project = 1// Относится к параметрам клиента.
}

enum TypeOfParameterValue
{
    String = 0,// Строковый.
    Int = 1,// Целочисленный.
    Double = 2,// Дробный.
    Bool = 3,// Булево.
    ValueSet = 4,// Набор параметров.
    Record = 5// Запись из нескольких полей. Только для основого параметра.
}    



export class ParameterTemplateListAsker extends CommonRequest
{
    EntityId: number;//Id клиента или проекта. (для данного метода неважно)
    AttachmentType: number;//к какой сущности относится параметр
}

export class ParameterTemplateListGiver extends CommonResponse
    {
        List: ParameterTemplate[];
    }
export class ParameterTemplate extends  Common
    {
        Count: number;//количество вхождений шаблона в клиенты или проекты.
    }


  export class ParameterTemplateContainerGiver extends CommonResponse
    {
        Container: ParameterTemplateContainer;
    }
    
    export class ParameterTemplateContainer extends Common
    {
        AlwaysShow:boolean; //обязательный параметр
        UsedInSearch:boolean; //используется при поиске.
        ShowInResults:boolean; //показывать в результатах поиска
        IsList:boolean; //является списком
        CanUnlist: boolean;
        ValueType: number; //тип значения
        TypeName: string; //название набора значений для типа "Набор значений"
    
        StringValue: string;//Строковое значение
    
        NumberValue?: number;//Численное значение
        Round: number;//округление для дробного численного типа
    
        BoolValue?: boolean;//булево значение
    
        SelectedValueId?: number; //выбранное значение из набора
    
        Values: Common[];//набор значений.
        AxisType: number;
        DateValue: Date=null;
    
        
    }
    

    


    export class ParameterTemplateEditAsker extends ParameterTemplateCreateAsker
{
    Id: number;
}



export class ParameterRotateAsker extends SingleIdRequest
{
    Position: number;
}


export class ParameterInstanceAddAsker extends ParameterTemplateListAsker
    {
      ParameterId: number;//Id шаблона параметра.
    }




    
export class ParameterInstanceContainerGiver extends  CommonResponse
{
  List: ParameterInstanceContainer[];
}

export class ParameterInstanceContainer
{
    Type: number;
    Instance: ParameterInstance;
    ListTitle: ParameterListTitle;

    public UpdateFromContainer(container: ParameterInstanceContainer)
    {
      this.Type=container.Type;
      this.Instance=container.Instance;
      this.ListTitle=container.ListTitle;
    }
}

enum ParameterInstanceContainerType
{
    SingleInstance = 0,// Единичный параметр.
    List = 1// Список параметров.
}

export class ParameterListTitle extends Common
{
    Instances: ParameterInstance[];
    Unremovable: boolean;
}

export class RecordFieldInstance extends Common
{
    ValueType: number; //тип значения

    StringValue: string; //Строковое значение

    NumberValue?: number; //Численное значение

    BoolValue?: boolean; //булево значение

    SelectedValueId?: number; //выбранное значение из набора

    Values: Common[]; //набор значений.
    ValueSetType:number;//тип множества значений.
}



export class ParameterInstance extends Common
{
    ValueType: number; //тип значения

    StringValue: string; //Строковое значение

    NumberValue?: number; //Численное значение
    Round: number; //округление типа "Дробный"

    BoolValue?: boolean; //булево значение

    SelectedValue?: Common; //выбранное значение из набора
    //Values: Common[]; //набор значений.
    //ValueSetType: number; //тип множества значений.

    RecordAxisType: number; //тип отображения записи.
    RecordFields: ParameterInstanceContainer[]; //поля записи.
    Unremovable: boolean;
    //SetId: number;
     ValueSet: ValueSetSmall;

    HorizontalLevel?:number=0;
    DateValue: Date=null;
    ValueDisplayType: number;
}




export class ParameterInstanceEditAsker extends SingleIdRequest
    {
        StringValue: string; //значение строкового типа.

        NumberValue?: number; //значение численных типов.

        BoolValue?: boolean; //значение логического типа.

        SelectedValueId?: number; // выбранное значений из набора.
        NewCreatedValue: ValueOfSetContainer;//для открытых множеств, если было создано и выбрано новое значение.
        DateValue: Date;
    }
    




    
    export class ParameterValueContainerGiver extends CommonResponse
    {
         Container: ParameterValueContainer;
    }


    export class ParameterValueContainer
    {
        StringValue: string; //значение строкового типа.

        NumberValue?: number; //значение численных типов.

        BoolValue?: boolean; //значение логического типа.

        SelectedValue: Common; // выбранное значений из набора.
    }






   export class ParameterInstanceContainerLight
{
    UsedInSearch: boolean;//используется при поиске
    Type: number;//тип - одиночный параметр или список

    Instance: ParameterInstanceLight;//одиночный параметры
    ListTitle: ParameterListTitleLight;//список параметров
}


export class KeyValueStrings
{
    Key: string;
    Value: string;
}

export class ParameterInstanceLight extends KeyValueStrings
{
    IsRecord: boolean;//является записью
    RecordFields: KeyValueStrings[];//поля - только для пар-ра - записи.
}


export class ParameterListTitleLight
{
  Name: string;//название пар-ра списка

  Instances: ParameterInstanceLight[];//элементы пар-ра списка
}



export class ParameterValue extends Common
{
  UsageCount: number;//кол-во использований.
}



export class ValueSetOfInstanceAsker extends CommonRequest
{
  InstanceId: number;//Id вхождения параметра / поля.
  InstanceOfParameter: boolean;//True - вхождение параметра, false - вхождение поля.
} 



export class ParameterHintGiver extends CommonResponse
{
    Hints: ParameterHint[];
    DefaultHints: ParameterValue[];
    HintedSetName: string;
    HintedSetType: number; //0закрытое 1 открытое с подтверждением 2 открытое
    DefaultHintsLenght?: number=30;
    HintsLenght?: number=30;
}



export class ParameterHint
{
    HintedValueId: number;//Id подсказываемого значения
    FilteringValue: string;//фильтрующее значение
    HintedValue: string;//подсказываемое значение
    Notes: ParameterHintNote[];//комментарии к подсказке
    UsageCount: number
}

export class ParameterHintView
{
    HintedValueId: number;//Id подсказываемого значения
    FilteringValue: string;//фильтрующее значение
    HintedValue: string;//подсказываемое значение
    Notes: ParameterHintNoteWithVisible[];//комментарии к подсказке
    UsageCount: number
    NoteVisible: boolean=false;
    
    FromParameterHint(hint: ParameterHint, possibleHintSets: number[]=[])
    {
        this.HintedValueId=hint.HintedValueId;
        this.FilteringValue=hint.FilteringValue;
        this.HintedValue=hint.HintedValue;
        this.UsageCount=hint.UsageCount;
        this.Notes=[];
        this.NoteVisible=false;
        
        hint.Notes.forEach(c=>{
          let note = new ParameterHintNoteWithVisible();
          note.Visible=false;
          note.ValueSetId=c.ValueSetId;
          note.ValueSetName=c.ValueSetName;
          note.SelectedHint=null;
          note.HintsLenght=c.HintsLenght*14;
          if(possibleHintSets.length>0)
          {
            if(possibleHintSets.findIndex(h=>h==c.ValueSetId)>-1)
            {note.CanBeInsert=true;}
            else
            {note.CanBeInsert=false;}
          }
          note.Hints=c.Hints.map(c=>{
            let hint = new HintView();
            hint.Count=c.UsageCount;
            hint.Name=c.Name;
            hint.NameBack=c.Name;
            hint.Id=c.Id;
            hint.Selected=false;
            hint.Visible=true;
            return hint;
          })
          
          this.Notes.push(note);
        })
    }
}


export class ParameterHintNote
    {
        ValueSetId: number;//Id и название группирующего набора значений.
        ValueSetName: string;
        Hints: ParameterValue[];//значения данного набора в подсказке.
        HintsLenght?: number=30;
    }

    export class ParameterHintNoteWithVisible
    {
        ValueSetId: number;//Id и название группирующего набора значений.
        ValueSetName: string;
        CanBeInsert: boolean=false;
        Hints: HintView[];//значения данного набора в подсказке.
        
        Visible: boolean=false;
        SelectedHint: HintView;
        HintsLenght: number=30;
    }

    export class CommonEntityWithCount extends Common
    {
      Count: number;
    }
    export class HintView extends CommonEntityWithCount
    {
      Selected: boolean=false;
      Visible: boolean=true;
      NameBack: string;
    }


    export class CommonEntityWithCountListGiver extends CommonResponse
    {
      List: CommonEntityWithCount[];
    }




export class ParameterHintAddAsker extends CommonRequest
    {
        FilteringSetId: number; // набор значений - ФИЛЬТР

        HintedSetId: number; //подсказываемый набор значений.

        NoteSetIds: number[]; //наборы значений - комментарии
    }


    export class ParameterHintEditAsker extends ParameterHintAddAsker
    {
        HintTemplateId: number;//Id редактируемого шаблона подсказки.
    }

    

    export class ParameterHintContainerGiver extends CommonResponse
    {
        Container: ParameterHintContainer;
    }
    export class ParameterHintContainer
    {
        FilteringSet: Common;//фильтрующий набор
        HintedSet: Common;//подсказываемый набор
        NoteSets: Common[];//наборы - комментарии
    }


    export class HintCreateContainer 
    {
        Id:  number;
        Name: string;//название
        SetsFull: SetForDropdown[];
        PossibleFilteringSet: SetForDropdown[];
        SelectedFilteringSet: SetForDropdown[];
        PossibleHintedSet: SetForDropdown[];
        SelectedHintedSet: SetForDropdown[];
        PossibleNoteSet: SetForDropdown[];
        SelectedNoteSet: SetForDropdown[];


        constructor()
        {
          this.Id=0;
          this.Name="";//название
          this.SetsFull=[];
          this.PossibleFilteringSet=[];
          this.SelectedFilteringSet=[];
          this.PossibleHintedSet=[];
          this.SelectedHintedSet=[];
          this.PossibleNoteSet=[];
          this.SelectedNoteSet=[];
        }
    }

    export class  SetForDropdown extends Common
    {
      TypeName:string;
      constructor(id: number=0, name: string="", typeName)
      {
        super(id, name);
        this.TypeName=typeName;
      }
    }


    
   
    export class TypeForDropdown extends CommonWithSelect{
      Additionals:any[];
      TypeName:string;
      constructor(id: number=0, name: string="", selected:boolean=false, additionals:any[]=[], typeName)
    {
      super(id, name, selected);
      this.Additionals = additionals;
      this.TypeName=typeName;
    }
    }



    export class ParameterTemplateCustomListGiver extends CommonResponse
    {
      List: ParameterTemplateCustom[];
    }
    export class ParameterTemplateCustom extends Common
    {
        CustomType: number;
    }

    enum V2CustomParameterType// Тип параметра - общий или особый.
    {
        Common = 0,// Обычный пользовательский параметр. 
        StorageItemName = 8// Название позиции склада. Служебный неудаляемый параметр, можно настраивать и даже менять тип.
    }

    

    
    export class StorageItemNameParameterTemplateContainerGiver extends CommonResponse
    {
      Container: StorageItemNameParameterTemplateContainer;
    }
    export class StorageItemNameParameterTemplateContainer extends Common
    {
        ValueType: number; //тип значения

        Type: Common; //название набора значений для типа "Набор значений" / шаблона записи для типа "Запись".
        IsList: boolean; //является списком.
        CanUnlist: boolean; //можно убрать галочку "Является списком".
        AxisType: number; //Отображение полей записи - только для случая, когда тип поля - запись.
    }

    export class StorageItemNameParameterTemplateFullContainer extends Common
    {
        ValueType: number; //тип значения

        Type: Common; //название набора значений для типа "Набор значений" / шаблона записи для типа "Запись".
        IsList: boolean; //является списком.
        CanUnlist: boolean; //можно убрать галочку "Является списком".
        AxisType: number; //Отображение полей записи - только для случая, когда тип поля - запись.

        ValueTypes:TypeForDropdown[];
        SelectedValueType:TypeForDropdown[];
        

        AxisTypes: Common[];
        AxisTypeSelected: Common[];
        TypeCanChange: boolean; //является списком.
    }



    export class StorageItemNameParameterTemplateEditAsker extends CommonRequest
    {
        ValueType: number; // тип значения.
        IsList: boolean; //является списком.
        AxisType: number;//Отображение полей записи - только для случая, когда тип параметра - запись.
        ValueSetId?: number; // выбранный для параметра набор значений.
        RecordTemplateId?: number;//выбранный для типа "Запись" шаблон записи.
    }



    export class ValueSetBaseAsker extends CommonRequest
    {
        Name: string;//название

        Round: number;//округление для типа значений сета "Дробный".

        ExtensionType: number;//тип множества
    }



    enum V2ParameterValueSet_ValueType
    {
        String = 0, // Строка
        Int = 1, // Целочисленный
        Double = 2,// Дробный
        Date = 3 // Дата
    }


export class ValueOfSetContainer
    {
      Id: number; // Идентификатор значения
      NameString: string; //Название значения типа "Строка".
      NameNumber?: number; //Название значения типа "Целый / Дробный".
      NameDate?:Date; //Название значения типа "Дата".
    }


export class ValueSetSmall extends Common
    {
        ValueType: number;
    }


    export class ValueSetCreateAsker extends ValueSetBaseAsker
    {
        ValueType: number;//тип значений набора

       Values:ValueOfSetContainer[];//значения для набора
    }


    export class ValueSetEditAsker extends ValueSetBaseAsker
    {
        Id: number;//Id редактируемого сета

         Values: ValueOfSetEditContainer[];//значения для набора
    }

    export class ValueOfSetEditContainer extends ValueOfSetContainer
    {
        MergedWith: number[];//массив Id значений, которые слиты в данное.
    }


    export class ParameterValueSetContainer extends Common
    {
        ExtensionType: number; //тип расширения множества
        CustomType: number; //тип множества - общий или особый.

        ValueType: number; //тип значения множества
        Round: number;//округление для типа значения "Дробное".

        Values: ValueOfSetWithUsageContainer[]; //значения для набора
    }

    export class ValueOfSetWithUsageContainer extends ValueOfSetContainer
    {
        UsageCount: number;//кол-во использований.

      toValue():ValueOfSetContainer
      {
        let val= new ValueOfSetContainer();
        val.Id=this.Id;
        val.NameString=this.NameString;
        val.NameDate=this.NameDate;
        val.NameNumber=this.NameNumber;
        return val;
      }
        


      
    }



    // Тип токена.
 enum FormulaTokenType
  {
      // Литерал
      Literal = 0,

      // Ссылка на поле записи.
      FieldLink=1,

      // Плюс 
      Plus=2,

      // Минус
      Minus=3,

      // Умножить
      Multiply=4,

      // Разделить
      Divide=5,

      // Левая скобка
      LeftBracket=6,

      // Правая скобка
      RightBracket=7
  }



    export class FieldLinkPartOut
    {
        // Id шаблона поля.
        FieldTemplateId: number;

        // Данное поле является списком и в формуле нужно использовать сумму элементов списка.
        IsList: boolean;
    }


    export class FormulaTokenOut
    {
        // Тип токена.
         Type: number;

        // Значение токена.
        Value: number;

        // Ссылка на шаблон поля записи.
        FieldLink: FieldLinkPartOut[];
    }





    export class RecordFieldContainerOutLite extends Common
{
    // Тип поля. 
    ValueType: number;

    // Название набора значений для типа "Набор значений" / шаблона записи для типа "Запись".
    TypeName: string;

    // Является списком.
    IsList: boolean;

    // Отображение полей записи (если поле является записью)
    AxisType: number;

    // Рекурсивно построенный список полей, принадлежащих данной записи (если поле является записью)
    Fields: RecordFieldContainerOutLite[];

    // Формула, записанная в поле (если поле формульное).
    Formula: FormulaTokenOut[];
    SetValueType: number;
    ValueDisplayType: number;
}



    export class RecordFieldLiteListGiver extends CommonResponse
    {
        Fields: RecordFieldContainerOutLite[];// Шаблоны полей записи. 
    }


   export class RecordFieldContainerOut extends RecordFieldContainerOutLite
{
    // Округление для дробного типа поля.
    Round: number;

    // Можно убрать галочку "Является списком".
    CanUnlist: boolean;

    // Способ удаления поля.
    RemoveType: number;
}


/*export class RecordFieldContainerOut extends Common
    {
        ValueType: number; //тип значения
        SetName: string; //название набора значений для типа "Набор значений"
    
        StringValue: string;//Строковое значение
    
        NumberValue?: number;//Численное значение
        Round: number;//округление для дробного численного типа
    
        BoolValue?: boolean;//булево значение
    
        SelectedValueId?: number; //выбранное значение из набора
    
        Values: Common[];//набор значений.
        AxisType: number;
    }
    */


   enum RecordFieldRemoveType// Способ удаления поля записи.
{
    Allowed = 0,// Удаление разрешено безусловно.
    AllowedOnConfirm=1,// Для удаления требуется подтверждение пользователя.
    Forbidden=2// Удаление поля запрещено.
}



export class PastedValues
{
    tags: HTMLElement[];
    fields: FormulFieldsContainer[];
}


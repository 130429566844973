export interface ISessionState {
  id: number;
  sessionKey: string;
  jwtToken: string;
}

export const initialSessionState: ISessionState = {
  id: 0,
  sessionKey: '',
  jwtToken: '',
}

export class ClientToAddTypeEnum
{
  Client:number;
  Project:number;
  Person:number;

  constructor()
  {
    this.Client = 0;
    this.Project = 1;
    this.Person = 2;
  }
}

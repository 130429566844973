import {Common} from "../../model.common";
import {AddressComponentInPerson} from "./model.AddressComponentInPerson";
import { AddressComponentInPersonInner } from "../Inner/model.AddressComponentInPersonInner";

export class PersonInList extends Common {
  Post: string;
  Contacts: string;
  Clients: Common[];
  Tags: Common[];
  Changeable:boolean;
  MainInProject:boolean;
  AddressChain: AddressComponentInPerson[];
  CreateTime: Date;
  isEditing: boolean = false;
  
  constructor(p: PersonInList) {
    super(p.Id, p.Name);
    this.Post = p.Post;
    this.Contacts = p.Contacts;
    this.Clients = p.Clients;
    this.Tags = p.Tags;
    this.Changeable = p.Changeable;
    this.MainInProject = p.MainInProject;
    this.CreateTime = p.CreateTime;
    this.AddressChain = p.AddressChain == null ? null : p.AddressChain.map(c => new AddressComponentInPersonInner(c));
  }

  RefreshAfterEdit(p: PersonInList) {
    this.isEditing = p.isEditing;
    this.Name = p.Name;
    this.Post = p.Post;
    this.Contacts = p.Contacts;
    this.Tags = p.Tags;
    this.AddressChain = p.AddressChain == null ? null : p.AddressChain.map(c => new AddressComponentInPersonInner(c));
  }
}

import {SingleIdRequest} from "../../model.http";
import {ExtendedHelper} from "../../../config/ExtendedHelper";

export class ClientListPossibleAsker extends SingleIdRequest
{
  Filter:string;
  Take: number;
  Skip:number;

  constructor(userId:number, sessionKey: string, entityId:number, filter:string, take:number=50, skip:number=0)
  {
    super(userId, sessionKey, entityId);
    this.Filter = ExtendedHelper.ReplaceBadThings(filter);
    this.Take=take;
    this.Skip=skip;
  }
}

import { ConfirmDoneIcon, CloseIcon } from './../svg-icon/svg-icon.data';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChange, HostListener, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ArrowLeftIcon } from '../svg-icon/svg-icon.data';
import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
import { Common } from '../../../models/model.common';
import * as _ from 'lodash';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Address_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { getScale } from '@swimlane/ngx-charts';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SvgIconsModule } from '../svg-icon/svg-icon.module';


type Changes = {
    errorArray: SimpleChange;
};


@Component({
    selector: 'float-text-field',
    templateUrl: './float-text-field.component.html',
    styleUrls: ['./float-text-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})


export class FloatTextFieldComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() borderOnHover:boolean=false
    
    @Input('value')
    value: string = '';
 
    @Input() placeholder: string="Введите текст";

    @Output()
    valueChange = new EventEmitter<string>();

    @Output()
    blurInput = new EventEmitter<string>();

    @Output()
    newSize = new EventEmitter<number>();

    @ViewChild('textInput') el: ElementRef;
    empty: boolean=false;

    @ViewChild('textSizeInput') elS: ElementRef;


    @ViewChild('floatCont') fc: ElementRef;
    @Input() editable: boolean=true;

    @Input() innerClear: boolean=false;
    @Output()
    isClear = new EventEmitter<boolean>();

    @Input() blurByClear: boolean=true;

    constructor(
        private svgIconsRegistry: SvgIconsRegistry,
        private languageService: LanguageService
    ) {
        this.svgIconsRegistry.registerIcons([
            ArrowLeftIcon,
            ConfirmDoneIcon,
            CloseIcon
        ])
    }



    ngOnInit() {
        
       if(this.value==null || this.value=='')
       {
           this.empty=true;
       }
       else
       {
           this.empty=false;
       }
    }

    ngAfterViewInit()
    {
        if(this.el!=undefined)
        {
            this.el.nativeElement.textContent=this.value;
        }
        
    }
    ngOnChanges(changes: SimpleChanges){
        for (let prop in changes) {
            if(prop=="value")
            {
              let chng = changes[prop];

              if (!chng.isFirstChange()) 
              {
                  if(this.el.nativeElement.innerText!=this.value)
                  {
                    
                    this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.value);
                  }
                
                if(this.value==null || this.value=='')
                {
                    this.empty=true;
                    
                }
                else
                {
                    this.empty=false;
                    
                }
              }
            }
            
          }
    }


    Paste(event: any)
    {
        let txt=event.clipboardData.getData('text/plain');
        
        
        this.el.nativeElement.innerText=this.el.nativeElement.innerText+ ExtendedHelper.ReplaceHtmlTags(txt);
    }
    textChanged(event: KeyboardEvent)
    {
        
        
        if(this.el!=undefined)
        {
           
            if( this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
            {
                
                //this.empty=true;
                this.valueChange.emit("");
                //this.focusTextWithoutPlaceholder();
            }
            else
            {
                
               // this.empty=false;
                this.valueChange.emit(ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText));
            }
            //this.valueChange.emit(ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.textContent));
        }
        
        else
        {
           
           // this.empty=true;
            this.valueChange.emit("");
           // this.focusTextWithoutPlaceholder();
        }
        
         
         //this.value=this.el.nativeElement.innerText;
        
         //this.elS.nativeElement.width;
        


         
         let elemS=<HTMLElement>this.elS.nativeElement;
         let fcs=<HTMLElement>this.fc.nativeElement;
         let elemS1=<HTMLElement>this.el.nativeElement;
         
         this.newSize.emit(elemS.getBoundingClientRect().width+30+fcs.getBoundingClientRect().width);
       
       //временное для вставки
         if(event.ctrlKey && event.keyCode==86)
        {
            
            setTimeout(()=>{
                let focus =<HTMLElement>this.el.nativeElement;
                
                this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
                setTimeout(()=>{this.el.nativeElement.focus();
                    let [r, s] = [document.createRange(), window.getSelection()];
                    r.selectNodeContents(<Node>event.target);
                    r.collapse(false);
                    s.removeAllRanges();
                    s.addRange(r);
                    
                
                },100)
            },100)
            this.empty=false;
            
        }
       
        
    }



    changeInput()
    {

    }

    BlurInput(event: Event)
    {

        
        setTimeout(()=>{
        
            //let dd= <HTMLElement>this.el.nativeElement;
            //dd.textContent
            if(this.el!=undefined)
        {
            this.el.nativeElement.innerText=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
            //this.el.nativeElement.innerText=ExtendedHelper.ReplaceBadThings(this.el.nativeElement.innerText);

            
            this.value=ExtendedHelper.ReplaceHtmlTags(this.el.nativeElement.innerText);
        }
        else
        {
            this.value="" 
        }
        
        if(ExtendedHelper.IsNullOrEmptyOrContainsOnlySeparators(this.el.nativeElement.innerText))
        {
            this.el.nativeElement.innerText='';
            this.value="" 
        }
            this.valueChange.emit(ExtendedHelper.ReplaceHtmlTags(this.value));
            this.blurInput.emit(ExtendedHelper.ReplaceHtmlTags(this.value));
           /* if(this.el!=undefined)
            {
                if(this.el.nativeElement.innerText==null || this.el.nativeElement.innerText=='')
                {
                    this.empty=true;
                }
                else
                {
                    this.empty=false;
                }
            }
            else
                {
                    this.empty=true;
                }*/
        },300)
        
    }

    focusText()
    {
        
        if(this.el!=undefined)
        {
            this.el.nativeElement.focus();
            this.empty=false;
        }
        
           
        
        
    }


    clear(event: Event)
    {

        
        if(!this.blurByClear)
        {
            
            event.stopPropagation();
        }
        this.el.nativeElement.innerText="";
        this.value="";
        //this.empty=true;
        
        this.valueChange.emit(ExtendedHelper.ReplaceHtmlTags(this.value));
        this.isClear.emit(true);
        ///this.blurInput.emit(ExtendedHelper.ReplaceHtmlTags(this.value));
        this.focusTextWithoutPlaceholder()
    }


    focusTextWithoutPlaceholder()
    {
        //this.empty=false;
       
        setTimeout(()=>{this.el.nativeElement.focus();},100)
        
            
    }


    TextClick(ev: MouseEvent)
    {
        
        this.el.nativeElement.focus();
        if(this.value==null || this.value=='')
        {
            setTimeout(()=>{
                let [r, s] = [document.createRange(), window.getSelection()];
                        r.selectNodeContents(<Node>ev.target);
                        r.collapse(false);
                        s.removeAllRanges();
                        s.addRange(r);
            },10)
        }
       
        
    }
}

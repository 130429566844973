
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 29.11.2016.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {JobLight, JobToSend, FilterJob, JobRedact, JobAsker, JobAskerOneOrUpper} from '../models/model.job'



import {Deleting} from "../models/model.deleting";
import {IdToId} from "../models/model.idtoid";
import {CommonRequest, SingleIdRequest} from '../models/model.http';
import {
  AutoJobListResreshAsker,
  AutoJobTemplateAddAsker,
  AutoJobTemplateEditAsker,
  AutoJobTemplateMoveAsker,
  MultiIdRequest
} from '../models/model.Autojob/model.AutojobTemplate';

@Injectable()
export class HttpServiceAutojob {

    constructor(private http: HttpClient,private param:ServerString) {
    }

    /*
  GetAutojobTemplateContainer(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AutoJob/template/ContainerGet/New', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  AddAutojobTemplate(obj: AutojobTemplateAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AutoJob/template/Add', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  GetAutojobTemplateContainerToEdit(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AutoJob/template/ContainerGet/Existing', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  EditAutojobTemplate(obj: AutojobTemplateAsker) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AutoJob/template/Edit', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  DeleteAutojobTemplate(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'AutoJob/template/Delete', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  GetStageAutojobsList(obj: SingleIdRequest) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'!!!!!', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }
*/
  /*GetAutojobsInProjectList(obj: ) {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'!!!!!!!', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }

  CreateJobsAndChangeStage(obj: )
    {
        const body = "'" + JSON.stringify(obj) + "'";
         let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
        return this.http.post(this.param.fullServerString()+'!!!!!!!!', body, {headers: headers, withCredentials: true})
            //.map((resp: Response)=>resp.json())
            .catch((error: any) => {return Observable.throw(error);});
    }*/

  AddAutojobTemplateContainer(obj: AutoJobTemplateAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/Add', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }


  DeleteAutojobTemplate(obj: MultiIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/Delete', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  GetAutojobTemplateContainer(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/ContainerGet', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  EditAutojobTemplateContainer(obj: AutoJobTemplateEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/Edit', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  GetStagesToMove(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/GetStagesToMoveOn', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  MoveTemplatesToOtherStage(obj: AutoJobTemplateMoveAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'AutoJob/template/MoveToOtherStage', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }






}


import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
/**
 * Created by MosNeuro on 22.11.2016.
 */
import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {LoginLangSetAsker, LoginMailEx, RecoverAsker, LoginAsker} from '../models/model.login';




@Injectable()
export class HttpServiceLoginNew{

    constructor(private http: HttpClient,private param:ServerString){ }

  postLogin(obj: LoginAsker){
    const body = "'"+JSON.stringify(obj)+"'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'login/login', body, { headers: headers, withCredentials: true }).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }


  recoverByEmail(obj: RecoverAsker){
    const body = "'"+JSON.stringify(obj)+"'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'login/RecoverByEmail', body, { headers: headers, withCredentials: true }).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }



  recoverByName(obj: RecoverAsker){
    const body = "'"+JSON.stringify(obj)+"'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'login/RecoverByName', body, { headers: headers, withCredentials: true }).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }


  loginLangsetGet(obj: LoginLangSetAsker){
    const body = "'"+JSON.stringify(obj)+"'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'login/langSetGet', body, { headers: headers, withCredentials: true }).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }

  servTest(obj: LoginLangSetAsker){
    const body = "'"+JSON.stringify(obj)+"'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString()+'login/langSetGettt', body, { headers: headers, withCredentials: true }).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error:any) =>{return observableThrowError(error);}));
  }


  checkServer() {
    return this.http.get(this.param.fullServerString()+'login/checkServer', { withCredentials: true }).pipe(catchError((error:any) =>{return observableThrowError(error);}));
  }

}

import { Component, OnInit, Output, EventEmitter, Input, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @ViewChild('contentWrapper') contentWrapper;
  visibleText: boolean;


  // @Input('checked')
  // checked: boolean;


  @Input()
  get checked(): boolean { return this._checked; }
  set checked(value: boolean) {
    if (value != this.checked) {
      this._checked = value;
      // this._changeDetectorRef.markForCheck();
    }
  }
  private _checked: boolean = false;

  @Output()
  checkedChange = new EventEmitter();

  @Output()
  change = new EventEmitter<any>();

  @Input()
  disabled: boolean;


  // @HostListener("click") onClick() {
  //   this.checked = !this.checked;
  //   this.checkedChange.emit(this.checked);
  //   this.change.emit(this.checked);
  // }

  clickCkech(event: Event) {
    event.stopPropagation();
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    this.change.emit(this.checked);
  }


  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // if (this.contentWrapper.nativeElement.childNodes > 0) {
    //   this.visibleText = true;
    // } else {
    //   this.visibleText = false;
    // }


  }

}

import {SingleIdRequest} from "../../model.http";

export class ClientAttachAsker extends SingleIdRequest
{
  ClientId: number;

  constructor(userId:number, sessionKey: string, entityId:number, clientToAddId:number)
  {
    super(userId, sessionKey, entityId);
    this.ClientId = clientToAddId;
  }
}

import { AddressInputComponentIn } from "./model.AddressInputComponentIn";
import { AddressInputContainerInner } from "../../Inner/InputContainer/model.AddressInputContainerInner";
import { AddressInputComponentOut } from "../../In/Any/InputContainer/model.AddressInputComponentOut";

export class AddressInputContainerIn {
  Components: AddressInputComponentIn[];
  ActiveLevel?: number;

  constructor(c: AddressInputComponentOut[], activeLevel?: number) {
    //alert(c.Components.length);
    this.Components = c.map(c => new AddressInputComponentIn(c));
    this.ActiveLevel = activeLevel;
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input("percent") Percent: number;
  constructor() { }

  ngOnInit() {
    if (this.Percent > 100) {
      this.Percent = 100;
    }
  }

}

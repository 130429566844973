import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.css',
    '../../../style/clientProjectAreaStyle.scss',
    '../../../style/communicationAreaStyle.scss',
    '../../../style/jobAreaStyle.scss',
    '../../../style/personAreaStyle.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',
    '../../../style/clientRedactAreaStyle.scss',
    '../../../style/calendarStyle.scss',
    '../../../style/control-inputs.scss',
    '../../../style/supportLibrary.scss',
    '../../../iconfont/material-icons.scss',
    '../../../style/font-styles.scss'
  ]
})
export class ClientCardComponent implements OnInit {

  @Input() client: any
  @Input() clientId: number;
  @Input() textFilter: string="";

  constructor() { }

  ngOnInit(): void {
  }

}

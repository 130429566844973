import { GetSession, LoadSession } from './../store/actions/session.action';
import { ISessionState } from './../store/state/session.state';

import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';



import { select, Store } from '@ngrx/store';
import { selectSession } from '../store/selectors/session.selectors';
import { IAppState } from '../store/state/app.state';


export const SESSION_LOCALSTORAGE_KEY = 'session';



@Injectable({
    providedIn: 'root'
})
export class SessionSyncService {
    private isInit = false;

    constructor(private store$: Store<IAppState>) { }


    init() {
        if (this.isInit) {
            return;
        }
        this.isInit = true;
        this.loadFromStorage();


        this.store$.pipe(
            select(selectSession),
            filter(state => !!state)
        ).subscribe(state => {
            localStorage.setItem(SESSION_LOCALSTORAGE_KEY, JSON.stringify(state));
        });

        window.addEventListener('storage', () => this.loadFromStorage());

    }


    private loadFromStorage() {
        const storageState = localStorage.getItem(SESSION_LOCALSTORAGE_KEY);
        if (storageState) {
            this.store$.dispatch(LoadSession(JSON.parse(storageState)));
        }
    }

}

import { AddressInputComponent } from './../components/address-input/address-input.component';
import { BtnTagComponent } from './../components/tag-system/btn-tag/btn-tag.component';
import { TagListComponent } from './../components/tag-system/tag-list/tag-list.component';
import { FolderElementComponent } from './../components/file-system/folder-element/folder-element.component';
import { FileElementComponent } from './../components/file-system/file-element/file-element.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
// import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { AppListComponent } from './components/list/list.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ButtonComponent } from './components/button/button.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyNumber } from '../directives/onlynumber.directive';
import { MouseDragable } from '../directives/mouseDragable.directive';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { SvgIconsModule } from './components/svg-icon/svg-icon.module';
import { RolesFormElementComponent } from '../components/rolesFormElement/comp.rolesFormElement';
import { SearchFilterComponent } from '../components/searchFilter/comp.searchFilter';
import { PersonItemComponent } from '../components/person-item/person-item.component';
import { JobItemComponent } from '../components/job-item/job-item.component';
import { SearchResultsComponent } from '../components/searchResults/comp.searchResults';
import { VisibleDragDropDirective } from '../directives/visibleDrag.directive';
import { DragDropDirective } from '../components/file-system/folder-element/drag-drop.directive';
import { SvgIconsRegistry } from './components/svg-icon/svg-icon-registry.service';
import { CloseIcon, InfoCircleIcon, TrashIcon, EditIcon, HashTagIcon, AddIcon, UploadIcon, LoadingIcon, SuccessIcon, ConfirmDoneIcon, SearchIcon, ArrowLeftIcon, ArrowRightIcon, FilterIcon, ArrowPreviousIcon, ArrowNextIcon, SettingsIcon, ChatBackIcon, MessageSendIcon, MessageReplyIcon, ArrowTopIcon, ArrowBottomIcon, HideIcon, ShowIcon, ErrorIcon, InSearchIcon, CopyIcon } from './components/svg-icon/svg-icon.data';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { SelectorComponent } from './components/selector/selector.component';
import { SelectorOptionComponent } from './components/selector/components/selector-option/selector-option.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DropdownModule } from './components/dropdown/dropdown.component';
import { AutojobInSettingsContainerComponent } from '../components/autojobInSettingsContainer/comp.autojobInSettingsContainer';
import { autojobTemplateMultiselectComponent } from '../components/autojobTemplateMultiselect/comp.autojobTemplateMultiselect';
import { AutojobInProjectContainerComponent } from '../components/autojobInProjectContainer/comp.autojobInProjectContainer';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { autojobProjectMultiselectComponent } from '../components/autojobProjectMultiselect/comp.autojobProjectMultiselect';
import { ChatContainerComponent } from '../components/chat/chatContainer/comp.chatContainer';
import { chatListContainerComponent } from '../components/chat/chatListContainer/comp.chatListContainer';
import { singleChatContainerComponent } from '../components/chat/singleChatContainer/comp.singleChatContainer';
import { chatSearchPanelComponent } from '../components/chat/chatSearchPanel/comp.chatSearchPanel';
import { openedChatsPanelComponent } from '../components/chat/openedChatsPanel/comp.openedChatsPanel';
import { chatListserachResultContainerComponent } from '../components/chat/chatListserachResultContainer/comp.chatListserachResultContainer';
import { currentChatContainerComponent } from '../components/chat/currentChatContainer/comp.currentChatContainer';
import { singleMessageInChatComponent } from '../components/chat/singleMessageInChat/comp.singleMessageInChat';
import { addChatContainerComponent } from '../components/chat/addChatContainer/comp.addChatContainer';
import { usersInChatListComponent } from '../components/chat/usersInChatList/comp.usersInChatList';
import { ImageAddFormComponent } from '../components/imageAddForm/comp.imageAddForm';
import { ImageCropperModule } from 'ngx-image-cropper';
import { editChatContainerComponent } from '../components/chat/editChatContainer/comp.editChatContainer';
import { TextFieldAutocompleteComponent } from './components/text-field-autocomplete/text-field-autocomplete.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { SelectElementsFormComponent } from './components/select-elements-form/select-elements-form.component';
import { NumberFieldComponent } from './components/number-field/number-field.component';
import { ParameterInstanceStringContainerComponent } from '../components/parameters/parameterInstanceStringContainer/comp.parameterInstanceStringContainer';
import { ParameterInstanceNumberContainerComponent } from '../components/parameters/parameterInstanceNumberContainer/comp.parameterInstanceNumberContainer';
import { ParameterInstanceDoubleContainerComponent } from '../components/parameters/parameterInstanceDoubleContainer/comp.parameterInstanceDoubleContainer';
import { ParameterInstanceBooleanContainerComponent } from '../components/parameters/parameterInstanceBooleanContainer/comp.parameterInstanceBooleanContainer';
import { ParameterInstanceValueSetContainerComponent } from '../components/parameters/parameterInstanceValueSetContainer/comp.parameterInstanceValueSetContainer';
import { ValueSetDropdownComponent } from '../components/parameters/valueSetDropdown/comp.valueSetDropdown';
import { ParameterInstanceRecordContainerComponent } from '../components/parameters/parameterInstanceRecordContainer/comp.parameterInstanceRecordContainer';
import { ParameterInstanceRecordElementComponent } from '../components/parameters/parameterInstanceRecordElement/comp.parameterInstanceRecordElement';
import { FloatTextFieldComponent } from './components/float-text-field/float-text-field.component';
import { ClientCardComponent } from '../components/client-card/client-card.component';
import { ProjectCardComponent } from '../components/project-card/project-card.component';
import { ParameterInSearchViewComponent } from '../components/parameters/parameterInSearchView/comp.parameterInSearchView';
import { FloatNumberFieldComponent } from './components/float-number-field/float-number-field.component';
import { ParameterInstanceSearchContainerComponent } from '../components/parameters/parameterInstanceSearchContainer/comp.parameterInstanceSearchContainer';
import { ParameterInstanceShowContainerComponent } from '../components/parameters/parameterInstanceShowContainer/comp.parameterInstanceShowContainer';
import { ParameterInstanceOuterContainerComponent } from '../components/parameters/parameterInstanceOuterContainer/comp.parameterInstanceOuterContainer';
import { FormulFieldComponent } from '../components/parameters/formulField/comp.formulField';
import { ParameterInstanceDateContainerComponent } from '../components/parameters/parameterInstanceDateContainer/comp.parameterInstanceDateContainer';
import { DateFieldComponent } from './components/date-field/date-field.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SvgIconsModule,
    VirtualScrollerModule,
    AngularMyDatePickerModule,
    ImageCropperModule,
    ReactiveFormsModule
  ],
  declarations: [
    ButtonComponent,
    DialogComponent,
    AppListComponent,
    ListItemComponent,
    TextFieldComponent,
    TooltipComponent,
    OnlyNumber,
    MouseDragable,
    ProgressBarComponent,
    FileElementComponent,
    FolderElementComponent,
    RolesFormElementComponent,
    SearchFilterComponent,
    TagListComponent,
    BtnTagComponent,
    AddressInputComponent,

    PersonItemComponent,
    JobItemComponent,
    SearchResultsComponent,
    VisibleDragDropDirective,
    DragDropDirective,
    TextAreaComponent,
    SelectorComponent,
    SelectorOptionComponent,
    CheckboxComponent,
    AutojobInSettingsContainerComponent,
    autojobTemplateMultiselectComponent,
    AutojobInProjectContainerComponent,
    autojobProjectMultiselectComponent,
    ChatContainerComponent,
    chatListContainerComponent,
    singleChatContainerComponent,
    chatSearchPanelComponent,
    openedChatsPanelComponent,
    chatListserachResultContainerComponent,
    currentChatContainerComponent,
    singleMessageInChatComponent,
    addChatContainerComponent,
    usersInChatListComponent,
    ImageAddFormComponent,
    editChatContainerComponent,
    TextFieldAutocompleteComponent,
    SearchFieldComponent,
    SelectElementsFormComponent,
    NumberFieldComponent,
    ParameterInstanceStringContainerComponent,
    ParameterInstanceNumberContainerComponent,
    ParameterInstanceDoubleContainerComponent,
    ParameterInstanceBooleanContainerComponent,
    ParameterInstanceValueSetContainerComponent,
    ValueSetDropdownComponent,
    ParameterInstanceRecordContainerComponent,
    ParameterInstanceRecordElementComponent,
    FloatTextFieldComponent,
    FloatNumberFieldComponent,
    ClientCardComponent,
    ProjectCardComponent,
    ParameterInSearchViewComponent,
    ParameterInstanceSearchContainerComponent,
    ParameterInstanceShowContainerComponent,
    ParameterInstanceOuterContainerComponent,
    FormulFieldComponent,
    ParameterInstanceDateContainerComponent,
    DateFieldComponent
  ],
  exports: [
    VirtualScrollerModule,
    CommonModule,
    FormsModule,
    ButtonComponent,
    DialogComponent,
    AppListComponent,
    ListItemComponent,
    TextFieldComponent,
    TooltipComponent,
    OnlyNumber,
    MouseDragable,
    ProgressBarComponent,
    SvgIconsModule,
    FileElementComponent,
    FolderElementComponent,
    RolesFormElementComponent,
    SearchFilterComponent,
    TagListComponent,
    BtnTagComponent,
    AddressInputComponent,
    SelectorComponent,
    SelectorOptionComponent,
    CheckboxComponent,
    DropdownModule,

    PersonItemComponent,
    JobItemComponent,
    SearchResultsComponent,
    VisibleDragDropDirective,
    DragDropDirective,
    TextAreaComponent,
    AutojobInSettingsContainerComponent,
    autojobTemplateMultiselectComponent,
    AutojobInProjectContainerComponent,
    autojobProjectMultiselectComponent,
    ChatContainerComponent,
    chatListContainerComponent,
    singleChatContainerComponent,
    chatSearchPanelComponent,
    openedChatsPanelComponent,
    chatListserachResultContainerComponent,
    currentChatContainerComponent,
    singleMessageInChatComponent,
    addChatContainerComponent,
    usersInChatListComponent,
    ImageAddFormComponent,
    editChatContainerComponent,
    TextFieldAutocompleteComponent,
    SearchFieldComponent,
    SelectElementsFormComponent,
    NumberFieldComponent,
    ParameterInstanceStringContainerComponent,
    ParameterInstanceNumberContainerComponent,
    ParameterInstanceDoubleContainerComponent,
    ParameterInstanceBooleanContainerComponent,
    ParameterInstanceValueSetContainerComponent,
    ValueSetDropdownComponent,
    ParameterInstanceRecordContainerComponent,
    ParameterInstanceRecordElementComponent,
    FloatNumberFieldComponent,
    FloatTextFieldComponent,
    ClientCardComponent,
    ProjectCardComponent,
    ParameterInSearchViewComponent,
    ParameterInstanceSearchContainerComponent,
    ParameterInstanceShowContainerComponent,
    ParameterInstanceOuterContainerComponent,
    FormulFieldComponent,
    ParameterInstanceDateContainerComponent,
    DateFieldComponent
  ]
})

export class SharedModule {
  constructor(
    private svgIconsRegistry: SvgIconsRegistry
  ) {
    this.svgIconsRegistry.registerIcons([
      CloseIcon,
      InfoCircleIcon,
      TrashIcon,
      EditIcon,
      HashTagIcon,
      AddIcon,
      LoadingIcon,
      SuccessIcon,
      UploadIcon,
      ConfirmDoneIcon,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      FilterIcon,
      ArrowPreviousIcon,
      ArrowNextIcon,
      ArrowTopIcon,
      ArrowBottomIcon,
      SettingsIcon,
      ChatBackIcon,
      MessageSendIcon,
      MessageReplyIcon,
      ShowIcon,
      HideIcon, 
      ErrorIcon,
      InSearchIcon,
      CopyIcon
    ])
  }
}
import { CommonRequest, CommonResponse } from "./model.http";
import { Common, CommonSelectable } from "./model.common";
import { RoleInAttachedWorker } from "./model.workerRole";
import { SearchSelectHelper } from "../Helpers/SearchSelectHelper";
import { ExtendedHelper } from "../config/ExtendedHelper";
import * as _ from 'lodash';
/**
 * Created by MosNeuro on 28.03.2018.
 */
export class WorkerAttachAsker extends CommonRequest {
  EntityId: number;
  WorkerId: number;
  constructor(changerId: number = 0, sessionKey: string = "", entityId?: number, workerId?: number) {
    super(changerId, sessionKey);
    this.EntityId = entityId;
    this.WorkerId = workerId;
  }
}

export class WorkerUnfastenAsker extends CommonRequest {
  EntityId: number;
  WorkerId: number;
}

export class WorkerRoleAddingContainer {

}

export class WorkerAttached extends Common {
  Roles: Common[];
  Removed: boolean;

}
export class WorkerAttachedListGiver extends CommonResponse {
  WorkerList: WorkerAttached[];
}


export class WorkerListToSelectFromGiver extends CommonResponse {
  WorkerList: Common[];
}

export class CommonWorkerHolderGiver extends CommonResponse {
  Holders: CommonWorkerHolder[];
}

export class CommonWorkerHolder {
  IsWorker: boolean;
  Position: number;
  Worker: Common;
  Departament: CommonDepartment;
}


class CommonDepartment extends Common {
  Workers: Common[];
}


export class CommonWorkerHolderSelectable {
  IsWorker: boolean;
  Worker: CommonSelectableWorker;
  Departament: CommonDepartmentSelectable;
  constructor(holder?: CommonWorkerHolder) {
    if (holder != null) {
      this.IsWorker = holder.IsWorker;
      this.Departament = new CommonDepartmentSelectable();

      if (!holder.IsWorker) {
        this.Departament = new CommonDepartmentSelectable();
        this.Departament.Id = holder.Departament.Id;
        this.Departament.Name = holder.Departament.Name;
        if (holder.Departament.Workers.length > 0) {
          holder.Departament.Workers.forEach(inner => {
            this.Departament.Workers.push(new CommonSelectableWorker(inner.Id, inner.Name))
          });
        }
      } else {
        this.Worker = new CommonSelectableWorker(holder.Worker.Id, holder.Worker.Name);
      }
    }
  }
}

class CommonDepartmentSelectable extends Common {
  Workers: CommonSelectableWorker[];
  constructor() {
    super();
    this.Workers = [];
  }
}
class CommonSelectableWorker extends Common {
  Selected: boolean;
  constructor(id: number, name: string) {
    super(id, name);
    this.Selected = false;
  }

}




export class WorkerDepartmentsContainer {
  ElementList: CommonWorkerHolderSelectable[];
  BackElemList: CommonWorkerHolder[];

  FormPlacement: string;

  constructor(elements: CommonWorkerHolder[], placement: string) {
    this.BackElemList = elements;
    this.FormPlacement = placement;
    this.ElementList = [];
    for (let i = 0; i < this.BackElemList.length; i++) {
      let worker = this.BackElemList[i];
      if (worker.IsWorker) {
        let cs = new CommonWorkerHolderSelectable(worker);
        cs.Worker.Id = worker.Worker.Id;
        cs.IsWorker = worker.IsWorker;
        cs.Worker.Name = worker.Worker.Name;
        cs.Worker.Selected = false;
        this.ElementList.push(cs);
      } else {
        if (worker.Departament.Workers.length > 0) {
          let buffer = [];
          worker.Departament.Workers.forEach(innerWorker => {
            let incs = new CommonSelectableWorker(innerWorker.Id, innerWorker.Name);
            buffer.push(incs);
          })

          let cs = new CommonWorkerHolderSelectable(worker);
          cs.Worker = null;
          cs.IsWorker = false;
          cs.Departament.Id = worker.Departament.Id;
          cs.Departament.Name = worker.Departament.Name;
          cs.Departament.Workers = buffer;
          this.ElementList.push(cs);
        }
      }
    }

  }

  // public FindSelected(): number {
  //   return this.ElementList.findIndex(c => {
  //     if (c.IsWorker) {
  //       if (c.Departament.Workers > 0) {
  //         array.forEach(element => {

  //         });
  //       }
  //     } else {
  //       c.Worker.Selected
  //     }
  //   });
  // }

  public converToCommonList(): Common[] {
    let mass = [];
    for (let i = 0; i < this.BackElemList.length; i++) {
      let worker = this.BackElemList[i];
      if (worker.IsWorker) {
        let common = new Common(worker.Worker.Id, worker.Worker.Name);
        mass.push(common);
      } else {
        if (worker.Departament.Workers.length > 0) {
          worker.Departament.Workers.forEach(innerWorker => {
            let incommon = new Common(innerWorker.Id, innerWorker.Name);
            mass.push(incommon);
          })
        }
      }
    }    
    return mass;
  }

  private refreshElementList() {
    for (let i = 0; i < this.BackElemList.length; i++) {
      let worker = this.BackElemList[i];
      if (worker.IsWorker) {
        let cs = new CommonWorkerHolderSelectable(worker);
        cs.Worker.Id = worker.Worker.Id;
        cs.IsWorker = worker.IsWorker;
        cs.Worker.Name = worker.Worker.Name;
        cs.Worker.Selected = false;
        this.ElementList.push(cs);
      } else {
        if (worker.Departament.Workers.length > 0) {
          let buffer = [];
          worker.Departament.Workers.forEach(innerWorker => {
            let incs = new CommonSelectableWorker(innerWorker.Id, innerWorker.Name);
            buffer.push(incs);
          })

          let cs = new CommonWorkerHolderSelectable(worker);
          cs.Worker = null;
          cs.IsWorker = false;
          cs.Departament.Id = worker.Departament.Id;
          cs.Departament.Name = worker.Departament.Name;
          cs.Departament.Workers = buffer;
          this.ElementList.push(cs);
        }
      }
    }
  }

  public IsFindSelected(): boolean {
    for (let i = 0; i < this.ElementList.length; i++) {
      const c = this.ElementList[i];
      if (c.IsWorker) {
        if (c.Worker.Selected) return true;
      } else {
        if (c.Departament.Workers.length > 0) {
          for (let j = 0; j < c.Departament.Workers.length; j++) {
            const inner = c.Departament.Workers[j];
            if (inner.Selected) return true;
          }
        }
      }
    }
    return false;
  }

  public GiveCommonSelectedWorker(): CommonSelectable {
    for (let i = 0; i < this.ElementList.length; i++) {
      const c = this.ElementList[i];
      if (c.IsWorker) {
        if (c.Worker.Selected) {
          return new CommonSelectable(c.Worker.Id, c.Worker.Name);
        };
      } else {
        if (c.Departament.Workers.length > 0) {
          for (let j = 0; j < c.Departament.Workers.length; j++) {
            const inner = c.Departament.Workers[j];
            if (inner.Selected) {
              return new CommonSelectable(inner.Id, inner.Name);
            };
          }
        }
      }
    }
  }

  private GoFirst(commonId: string, listId: string) {
    for (let i = 0; i < this.ElementList.length; i++) {
      const c = this.ElementList[i];
      if (c.IsWorker) {
        c.Worker.Selected = false;
      } else {
        if (c.Departament.Workers.length > 0) {
          for (let j = 0; j < c.Departament.Workers.length; j++) {
            c.Departament.Workers[j].Selected = false;
          }
        }
      }
    }
    if (this.ElementList[0].IsWorker) {
      this.ElementList[0].Worker.Selected = true;
      this.FocusOnElement(commonId + this.ElementList[0].Worker.Id, listId);
    } else {
      if (this.ElementList[0].Departament.Workers.length > 0) {
        this.ElementList[0].Departament.Workers[0].Selected = true;
        this.FocusOnElement(commonId + this.ElementList[0].Departament.Workers[0].Id, listId);
      }
    }
  }

  public GoDown(commonId: string, listId: string) {
    if (!this.IsFindSelected()) {
      this.GoFirst(commonId, listId);
      return;
    }

    for (let i = 0; i < this.ElementList.length; i++) {
      const c = this.ElementList[i];
      if (c.IsWorker) {
        if (c.Worker.Selected) {
          if (this.ElementList.length - 1 == i) { this.GoFirst(commonId, listId); return; }

          if (this.ElementList[i + 1].IsWorker) {
            if (this.ElementList.length - 1 > i) {
              this.ElementList[i + 1].Worker.Selected = true;
              this.ElementList[i].Worker.Selected = false;
              this.FocusOnElement(commonId + this.ElementList[i + 1].Worker.Id, listId);
              return;
            }
          } else {
            this.ElementList[i + 1].Departament.Workers[0].Selected = true;
            this.ElementList[i].Worker.Selected = false;
            this.FocusOnElement(commonId + this.ElementList[i + 1].Departament.Workers[0].Id, listId);
            return;
          }
        }
      } else {
        if (c.Departament.Workers.length > 0) {
          for (let j = 0; j < c.Departament.Workers.length; j++) {
            // c.Departament.Workers[j].Selected = false;
            if (c.Departament.Workers[j].Selected) {

              if (this.ElementList[i].Departament.Workers.length - 1 > j) {
                this.ElementList[i].Departament.Workers[j].Selected = false;
                this.ElementList[i].Departament.Workers[j + 1].Selected = true;
                this.FocusOnElement(commonId + this.ElementList[i].Departament.Workers[j + 1].Id, listId);
                return;
              }
              if (this.ElementList.length - 1 > i && this.ElementList[i].Departament.Workers.length - 1 == j) {
                if (this.ElementList[i + 1].IsWorker) {
                  this.ElementList[i + 1].Worker.Selected = true;
                  this.ElementList[i].Departament.Workers[j].Selected = false;
                  this.FocusOnElement(commonId + this.ElementList[i + 1].Worker.Id, listId);
                  return;
                } else {
                  this.ElementList[i + 1].Departament.Workers[0].Selected = true;
                  this.ElementList[i].Departament.Workers[j].Selected = false;
                  this.FocusOnElement(commonId + this.ElementList[i + 1].Departament.Workers[0].Id, listId);
                  return;
                }
              }
              if (this.ElementList.length - 1 == i && this.ElementList[i].Departament.Workers.length - 1 == j) this.GoFirst(commonId, listId);
            }
          }
        }
      }
    }
  }

  public GoUp(commonId: string, listId: string) {
    if (!this.IsFindSelected()) {
      this.GoFirst(commonId, listId);
      return;
    }

    for (let i = this.ElementList.length - 1; i >= 0; i--) {
      const c = this.ElementList[i];
      if (c.IsWorker) {
        if (c.Worker.Selected) {
          if (i == 0) { this.GoFirst(commonId, listId); return; }

          if (this.ElementList[i - 1].IsWorker) {
            if (i != 0) {
              this.ElementList[i - 1].Worker.Selected = true;
              this.ElementList[i].Worker.Selected = false;
              this.FocusOnElement(commonId + this.ElementList[i - 1].Worker.Id, listId);
              return;
            }
          } else {
            this.ElementList[i - 1].Departament.Workers[this.ElementList[i - 1].Departament.Workers.length - 1].Selected = true;
            this.ElementList[i].Worker.Selected = false;
            this.FocusOnElement(commonId + this.ElementList[i - 1].Departament.Workers[this.ElementList[i - 1].Departament.Workers.length - 1].Id, listId);
            return;
          }
        }
      } else {
        if (c.Departament.Workers.length > 0) {
          for (let j = c.Departament.Workers.length - 1; j >= 0; j--) {
            // c.Departament.Workers[j].Selected = false;
            if (c.Departament.Workers[j].Selected) {

              if (j != 0) {
                this.ElementList[i].Departament.Workers[j].Selected = false;
                this.ElementList[i].Departament.Workers[j - 1].Selected = true;
                this.FocusOnElement(commonId + this.ElementList[i].Departament.Workers[j - 1].Id, listId);
                return;
              }
              if (i == 0 && j == 0) { this.GoFirst(commonId, listId); return; }

              if (j == 0) {
                if (this.ElementList[i - 1].IsWorker) {
                  this.ElementList[i - 1].Worker.Selected = true;
                  this.ElementList[i].Departament.Workers[j].Selected = false;
                  this.FocusOnElement(commonId + this.ElementList[i - 1].Worker.Id, listId);
                  return;
                } else {
                  this.ElementList[i - 1].Departament.Workers[this.ElementList[i - 1].Departament.Workers.length - 1].Selected = true;
                  this.ElementList[i].Departament.Workers[j].Selected = false;
                  this.FocusOnElement(commonId + this.ElementList[i - 1].Departament.Workers[this.ElementList[i - 1].Departament.Workers.length - 1].Id, listId);
                  return;
                }
              }
            }
          }
        }
      }
    }
  }

  public Sort(filter: string) {
    this.ElementList = [];
    if (filter != null && filter.length > 0) {
      for (let i = 0; i < this.BackElemList.length; i++) {
        let worker: CommonWorkerHolderSelectable = new CommonWorkerHolderSelectable(this.BackElemList[i]);
        if (worker.IsWorker) {
          if (ExtendedHelper.ContainsNoCase(worker.Worker.Name, filter)) {
            worker.Worker.Name = SearchSelectHelper.getSearchedText(filter, worker.Worker.Name);
            this.ElementList.push(worker);
          }
        } else {
          let worker: CommonWorkerHolderSelectable = new CommonWorkerHolderSelectable(this.BackElemList[i]);
          let buffer = [];
          for (let j = 0; j < this.BackElemList[i].Departament.Workers.length; j++) {
            const innerWorker = this.BackElemList[i].Departament.Workers[j];
            if (ExtendedHelper.ContainsNoCase(innerWorker.Name, filter)) buffer.push(innerWorker);
          }
          if (buffer.length > 0) {
            worker.Departament.Workers = [];
            buffer.forEach(element => {
              // let inner: Common = new Common();
              let inner = new CommonSelectableWorker(element.Id, element.Name);
              inner.Selected = false;
              // _.cloneDeep(element);
              inner.Name = SearchSelectHelper.getSearchedText(filter, inner.Name);
              worker.Departament.Workers.push(inner);
            });
            this.ElementList.push(worker);
          }
        }
      }
    } else {
      this.refreshElementList();
    }
  }

  FocusOnElement(elemId: string, listId: string) {
    let visible = this.IsElementVisible(elemId, listId);

    if (visible == 1 || visible == -1) {//тег ниже или выше списка

      let elem = document.getElementById(elemId);
      if (elem != null) {
        elem.scrollIntoView();
      }
    }
  }

  IsElementVisible(elemId: string, formListId: string): number {
    //Функция определеяет видимость элемента списка для пользователя. возвращает 0, если тег видимый. возвращает 1, если тег выше списка.
    // возвращает -1, если тег ниже списка. возвращает 2, если введенные данные ошибочны.

    let elem = document.getElementById(elemId);
    let list = document.getElementById(formListId);
    if (elem != null && list != null) {
      let elemCoordinates = elem.getBoundingClientRect();
      let listCoordinates = list.getBoundingClientRect();

      if (elemCoordinates.top < listCoordinates.top) {
        return 1;
      }
      if (elemCoordinates.bottom > listCoordinates.bottom) {
        return -1;
      }
      return 0;
    }
    return 2;
  }
}




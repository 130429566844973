
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ServerString} from "../config/serverstring";
import {Injectable} from "@angular/core";
import {JobAddAsker} from "../models/model.Job/Out/model.JobAddAsker";
import {JobEditAsker} from "../models/model.Job/Out/model.JobEditAsker";
import {SingleIdRequest} from "../models/model.http";
import {JobMainPageCountAsker, JobClientPageCountAsker, JobProjectPageCountAsker, JobMainPageSearchAsker, JobClientPageSearchAsker, JobProjectPageSearchAsker} from "../models/model.Job/Out/model.JobSearchAsker";
import {JobAroundOneAsker} from "../models/model.Job/Out/model.JobAroundOneAsker";
import {JobChangeStatusAsker} from "../models/model.Job/Out/model.JobChangeStatusAsker";

@Injectable()
export class HttpServiceJobNew {

  private route:string = "job/";

  constructor(private http: HttpClient,private param:ServerString) {
  }

  add(obj: JobAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'add', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  edit(obj: JobEditAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'edit', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  remove(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'delete', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  searchMain(obj: JobMainPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'Search/Main', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }
  searchClient(obj: JobClientPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'Search/Client', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }
  searchProject(obj: JobProjectPageSearchAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'Search/Project', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getOne(obj: JobAroundOneAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'getone', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getCountMain(obj: JobMainPageCountAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'GetCount/Main', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getCountClient(obj: JobClientPageCountAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'GetCount/Client', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getCountProject(obj: JobProjectPageCountAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'GetCount/Project', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }



  endByWorker(obj: JobChangeStatusAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'endByWorker', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  endByCreator(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'endByCreator', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  sendToChecking(obj: JobChangeStatusAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'sendToChecking', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  sendToReworking(obj: JobChangeStatusAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'sendToReworking', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }



  endByAdmin(obj: JobChangeStatusAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route +'EndByAdmin', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  refreshSingle(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'RefreshOne', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {return observableThrowError(error);}));
  }

}

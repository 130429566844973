import {AddressAutocompleteElement} from "../../In/Any/Autocomplete/model.AddressAutocompleteElement";

export class AddressAutocompleteElementInner {
  Value: string;
  AddressNodeId: number;
  Level: number;
  Count: number;

  constructor(c:AddressAutocompleteElement)
  {
    this.Value = c.Value;
    this.AddressNodeId = c.AddressNodeId;
    this.Level = c.Level;
    this.Count = c.Count;
  }
}

import { AddressInputContainerIn } from "./model.AddressInputContainerIn";
import { CommonRequest } from "../../../model.http";
import { AddressInputContainerInner } from "../../Inner/InputContainer/model.AddressInputContainerInner";
import { AddressInputComponentOut } from "../../In/Any/InputContainer/model.AddressInputComponentOut";

export class AddressFilterAsker extends CommonRequest {
  Container: AddressInputContainerIn;


  constructor(changerId: number, sessionKey: string, container: AddressInputComponentOut[] = null, activeLevel?: number) {
    super(changerId, sessionKey);
    if (container == null) {
      this.Container = null;
    }
    else {
      this.Container = new AddressInputContainerIn(container, activeLevel || 0);
    }
  }
}

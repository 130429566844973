/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { Chats_LangSet, Common_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { AdditionalMessagesAsker, AttachedMessage, ChatCreateAsker, ChatGiver, ChatInList, ChatInReplyList, ChatListGiver, ChatMessagesRefreshWebGiver, ChatMessagesSearchAsker, ChatMessagesWebGiver, ChatNotificator, ChatRefreshAsker, ChatReplyListGiver, ChatSearchAsker, ChatSearched, ChatSearchGiver, ChatSingleAsker, FullOpenedChat, MessageAddAsker, MessageInOpenedChat, MessageSearched } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { cloneDeep } from 'lodash';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'currentChatContainer',
    templateUrl: 'temp.currentChatContainer.html',
    styleUrls: ['currentChatContainer.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class currentChatContainerComponent implements OnInit {
    

  @ViewChild('chatTextArea') chatTextArea: TextAreaComponent;
    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    @Input() SendMessageMethod: number = 0;
    
    
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;

    @Input() Chat: FullOpenedChat= new FullOpenedChat();
    @Input() BackInChat: Subject<any> = new Subject<any>();

    @Input() StopSearchInChat: Subject<any> = new Subject<any>();
    @Input() SearchInChat: Subject<string> = new Subject<string>();
    @Output() ResendInExistChat: EventEmitter<any> = new EventEmitter<any>();
    @Output() ResendInNewChat: EventEmitter<any> = new EventEmitter<any>();

    @Output() CloseChatEvent: EventEmitter<any> = new EventEmitter<any>();

    @Input()  ScrollToMessage: Subject<number> = new Subject<number>();

    @Input() RefreshedMessages: Subject<any> = new Subject<any>();

    needResize: Subject<any> = new Subject<any>();
    



    chatTracker: any;
    chatTrackerSub: Subscription;
    isSearching:boolean=false;
    isLoading:boolean=true;
    isSearchingResend:boolean=false;
    isLoadingResend:boolean=false;

    selectingChatForResend: boolean=false;
    ChatsToResend: ChatInReplyList[]=[];
    ChatsToResendBack: ChatInReplyList[]=[];
    chatsToResendFilter: string="";
    LastVisible: boolean=false;
    MessageIsAdding: boolean = false;
    //SelectedMessages: MessageInOpenedChat[]=[];

    //CurrentOpenedChat: 
   
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService,
    private httpServiceChat: HttpServiceChat,
    private sessionService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                if(this.SearchInChat.observers==undefined ||this.SearchInChat.observers==null || this.SearchInChat.observers.length>0)
                {
                  this.SearchInChat.observers=[];
                }

                if(this.ScrollToMessage.observers==undefined ||this.ScrollToMessage.observers==null || this.ScrollToMessage.observers.length>0)
                {
                  this.ScrollToMessage.observers=[];
                }

                if(this.StopSearchInChat.observers==undefined ||this.StopSearchInChat.observers==null || this.StopSearchInChat.observers.length>0)
                {
                  this.StopSearchInChat.observers=[];
                }


                if(this.RefreshedMessages.observers==undefined ||this.RefreshedMessages.observers==null || this.RefreshedMessages.observers.length>0)
                {
                  this.RefreshedMessages.observers=[];
                }
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);
                
                setTimeout(() => { DOMHelper.ScrollToBottom('messagesInChatContainer', 100) }, 100);
                        this.MessagesScrollTrackerSubscribe(100);
                      
                this.BackInChat.subscribe(c => {
                  
                  DOMHelper.ScrollToPosition('messagesInChatContainer', 300, this.Chat.ScrollPosition);
                  
                });

                this.SearchInChat.subscribe(c => {
                  if(!this.Chat.SearchMode)
                  {
                    this.Chat.SearchMode=true;
                  }
                  this.SearchChat(c)
                 // DOMHelper.ScrollToPosition('messagesInChatContainer', this.Chat.ScrollPosition, 100);
                  
                });


                this.ScrollToMessage.subscribe(c => {
                  if(!this.Chat.SearchMode)
                  {
                    let idToScrollTo="chatMes"+c;
                          DOMHelper.ScrollElementToView(idToScrollTo, 1000);
                          
                  }
                  
                  
                });


                this.StopSearchInChat.subscribe(c => {

                  this.ReloadChat();
                 
                  
                });

                this.RefreshedMessages.subscribe(c => {

                  setTimeout(() => { DOMHelper.ScrollToBottom('messagesInChatContainer', 100) }, 100);
                 
                  
                });
                this.isLoading=false;
                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }


            


                
              MessagesScrollTrackerSubscribe(timeOut: number=100) {
                setTimeout(() => {
                  this.ChatScrollTrackerUnSubscribe();
                  let chatTrackerElement = document.getElementById('messagesInChatContainer');
                  this.chatTracker = observableFromEvent(chatTrackerElement, 'scroll').pipe(map(() => {
                    return chatTrackerElement.scrollTop;
                  }));
                  this.chatTrackerSub = this.chatTracker.subscribe((scrollPos) => {
                   
                    let chat = this.Chat;
                    if (chat != null && !chat.SearchMode) {
                      this.OnChatScrollPositionChanged(scrollPos);
                      if (DOMHelper.IsScrollAtTheBottom('messagesInChatContainer', 0) ) {                                            
                          this.LoadNextMessages();
                          this.LastVisible=false;//скролл внизу, если можем грузим свежие сообщения.
                          
                      }
                      if (DOMHelper.IsScrollAtTheTop('messagesInChatContainer', 0) ) {
                          this.LoadPrevMessages();//скролл вверху, если можем грузим старые сообщения.
                          
                      }


                      if(!DOMHelper.IsScrollAtTheBottom('messagesInChatContainer', 0) )
                      {
                          this.LastVisible=true;
                      }
                      
                    }
                  });
                }, timeOut);
              }

              ChatScrollTrackerUnSubscribe() {
                this.chatTracker = null;
                if (this.chatTrackerSub) this.chatTrackerSub.unsubscribe();
              }

              OnChatScrollPositionChanged(position: number) {
                let chat = this.Chat;
                if (chat != null) 
                {
                  chat.ScrollPosition = position;}
              }



              GoToLastMessages()
              {
                DOMHelper.ScrollToBottom('messagesInChatContainer', 100);
              }


              closeChat() {
                this.CloseChatEvent.emit();
              }
              

              MessageAdd()
              {

                if(this.MessageIsAdding)
                {
                  return;
                }
                this.MessageIsAdding=true;
                
                let checker = new MessageAddAsker();
                checker.SessionKey = this.SessionKey;
                checker.ChangerId = this.UserId;
                checker.ChatId=this.Chat.Id;
                checker.Text=this.extendedHelper.ReplaceBadThings(this.Chat.TextArea);
                checker.AttachedMessageIds=[];
                checker.AttachedMessageIds=this.Chat.AttachedMessages.map(c=>c.Id);
                

                this.httpServiceChat.chatAddMessage(checker).subscribe((data: CommonResponse) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 300) {
                    alert(this.chats_Langset.CantSendEmptyMessage);
                    this.MessageIsAdding=false;
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    this.MessageIsAdding=false;
                    return;
                  }
                  if (data.Code == 302) {
                    alert("Нельзя отправить сообщение в системный чат");
                    this.MessageIsAdding=false;
                    console.log(data.Message);
                    return;
                  }
                  if (data.Code == 303) {
                    this.MessageIsAdding=false;
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Chat.TextArea="";
                    this.Chat.AttachedMessages=[];
                    this.MessageIsAdding=false;
                    setTimeout(()=>{
                      this.needResize.next();
                    },100)
                    


                  }
                  
                })

              }


              LoadPrevMessages()
              {
                    if(!this.Chat.CanLoadPrev)
                    {
                      return;
                    }
                    let checkerMes = new AdditionalMessagesAsker();
                    checkerMes.SessionKey = this.SessionKey;
                    checkerMes.ChangerId = this.UserId;
                    checkerMes.Id=this.Chat.Id;
                    checkerMes.MessageDate=this.Chat.Messages[0].Date;
                    this.httpServiceChat.chatGetPreviousMessages(checkerMes).subscribe((data: ChatMessagesWebGiver) => {
                      if (data.Code == 100) {
                        this.unLogin();
                        return;
                      }
                     
                      if (data.Code == 500) { console.error(data.Message); return; }
    
                      if (data.Code==0)
                      {
                        if(data.Messages!=undefined && data.Messages!=null && data.Messages.length>0)
                        {
                          for(let i=data.Messages.length-1; i>=0; i--)
                          {
                            this.Chat.Messages.splice(0,0,data.Messages[i]);
                          }

                          if(data.Messages.length<30)
                          {
                            this.Chat.CanLoadPrev=false;
                          }
  
                          let idToScrollTo="chatMes"+data.Messages[data.Messages.length-1].Id;
                          DOMHelper.ScrollElementToView(idToScrollTo, 0);
                        }
                        else
                        {
                          this.Chat.CanLoadPrev=false;
                        }
                        


                      }
                      
                    })
              }


              LoadNextMessages()
              {
            
                if(!this.Chat.CanLoadNext)
                {
                  return;
                }
                let checkerMes = new AdditionalMessagesAsker();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.Chat.Id;
                checkerMes.MessageDate=this.Chat.Messages[this.Chat.Messages.length-1].Date;
                this.httpServiceChat.chatGetNextMessages(checkerMes).subscribe((data: ChatMessagesWebGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    if(data.Messages!=undefined &&data.Messages!=null && data.Messages.length>0)
                    {
                      for(let i=0; i<data.Messages.length; i++)
                      {
                        this.Chat.Messages.push(_.cloneDeep(data.Messages[i]));
                      }
                      if(data.Messages.length<30)
                          {
                            this.Chat.CanLoadNext=false;
                          }
                    }
                    else
                    {
                      this.Chat.CanLoadNext=false;
                    }
                    
                    
                  }
                  
                })
              }


              MessageReaded(id: number)
              {
                this.Chat.UnreadedCount--;
              }

              SearchChat(filter: string)
              {
                if(this.isSearching)
                {
                  return;
                }
                filter=this.extendedHelper.ReplaceBadThings(filter);
                this.isSearching=true;
                let checkerMes = new ChatMessagesSearchAsker();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.Chat.Id;
                checkerMes.Filter=this.extendedHelper.ReplaceBadThings(filter);
                this.httpServiceChat.chatSearchMessagesInChat(checkerMes).subscribe((data: ChatMessagesWebGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Chat.Messages=[];
                    if (!ExtendedHelper.IsNullOrEmpty(filter)) 
                    { 
                      for(let i=0; i<data.Messages.length; i++)
                      {
                        this.Chat.Messages.push(data.Messages[i]);
                        
                        let chatsLen = this.Chat.Messages.length - 1;
                        if(this.Chat.SelectedMessages.findIndex(c=>c.Id==data.Messages[i].Id)>-1)
                        {
                          this.Chat.Messages[chatsLen].Selected=true;
                        }
                        this.Chat.Messages[chatsLen].Text = SearchSelectHelper.getSearchedText(filter, this.Chat.Messages[chatsLen].Text);
                        
                      }
                      
                     }
                     else
                     {
                      this.Chat.Messages=_.cloneDeep(data.Messages);
                      if(this.Chat.SelectedMessages!=undefined && this.Chat.SelectedMessages!=null && this.Chat.SelectedMessages.length>0)
                    {
                      for(let i=0; i<this.Chat.SelectedMessages.length; i++)
                      {
                        let ind = this.Chat.Messages.findIndex(c=>c.Id==this.Chat.SelectedMessages[i].Id);
                        if(ind>-1)
                        {
                          this.Chat.Messages[ind].Selected=true;
                        }
                      }
                    }
                     }

                     DOMHelper.ScrollToBottom('messagesInChatContainer', 100) 
                     this.isSearching=false;
                  }
                  
                })
              }


              ReloadChat()
              {
                
                this.isLoading=true;
                let checkerMes = new ChatSingleAsker();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                checkerMes.Id=this.Chat.Id;
                checkerMes.MessageId=0;
                this.httpServiceChat.chatGet(checkerMes).subscribe((data: ChatGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  if (data.Code == 301) {
                    console.log(data.Message)
                    return;
                  }

                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Chat.Messages=[];
                    this.Chat.Messages=_.cloneDeep(data.Chat.Messages);
                    let chatsLen = this.Chat.Messages.length - 1;
                    if(this.Chat.SelectedMessages!=undefined && this.Chat.SelectedMessages!=null && this.Chat.SelectedMessages.length>0)
                    {
                      for(let i=0; i<this.Chat.SelectedMessages.length; i++)
                      {
                        let ind = this.Chat.Messages.findIndex(c=>c.Id==this.Chat.SelectedMessages[i].Id);
                        if(ind>-1)
                        {
                          this.Chat.Messages[ind].Selected=true;
                        }
                      }
                    }
                        
                    this.isLoading=false;

                    this.Chat.SearchMode=false;
                    setTimeout(()=>{DOMHelper.ScrollToBottom('messagesInChatContainer',100)},100);
                  }
                  
                })
              }


              SelectMessage(mes: MessageInOpenedChat)
              {
                
                this.Chat.SelectedCount++;
                mes.Text=mes.Text.replace('<span class="searchedText">',"");
                mes.Text=mes.Text.replace('</span>',"");
                this.Chat.SelectedMessages.push(mes);
              }

              UnselectMessage(mes: MessageInOpenedChat)
              {
                this.Chat.SelectedCount--;
                let ind =this.Chat.SelectedMessages.findIndex(c=>c.Id==mes.Id);
                if(ind>-1)
                {
                  this.Chat.SelectedMessages.splice(ind, 1);
                } 
              }

              UnselectAll()
              {
                this.Chat.SelectedCount=0;
                this.Chat.SelectedMessages=[];
                for(let i=0; i<this.Chat.Messages.length; i++)
                {
                  if(this.Chat.Messages[i].Selected)
                  {
                    this.Chat.Messages[i].Selected=false;
                  }
                }
              }

              AnswerOnMessage(message: MessageInOpenedChat)
              {
                this.Chat.AttachedMessages=[];
                let attached = new AttachedMessage();
                attached.CreateFromMessage(message);
                this.Chat.AttachedMessages.push(attached);
              }

              LoadChatsToResend()
              {
                this.isLoadingResend=true;
                this.chatsToResendFilter="";
                let checkerMes = new CommonRequest();
                checkerMes.SessionKey = this.SessionKey;
                checkerMes.ChangerId = this.UserId;
                this.httpServiceChat.chatGetChatsListToReply(checkerMes).subscribe((data: ChatReplyListGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.ChatsToResend=[];
                    this.ChatsToResendBack=[];
                    this.ChatsToResend=data.ChatList;
                    this.ChatsToResendBack=data.ChatList;
                    this.selectingChatForResend=true;
                    this.isLoadingResend=false;
                  }
                  
                })
              }


              SearchChatsToResend()
              {
                this.isSearchingResend=true;
                this.ChatsToResend = [];
                let filter=this.extendedHelper.ReplaceBadThings(this.chatsToResendFilter);
                if (ExtendedHelper.IsNullOrEmpty(filter)) 
                { this.ChatsToResend = _.cloneDeep(this.ChatsToResendBack); }
                else {
                  let separator = new RegExp(/ *\s/, "gi");
                  let filtersChat = filter.toLowerCase().split(separator);
                  
                  let containFilter: boolean = false;
                  for (let i = 0; i < this.ChatsToResendBack.length; i++) {
                    containFilter = false;
                    for (var f = 0; f < filtersChat.length; f++) {
                      if (filtersChat[f] != "") {
                        if (this.ChatsToResendBack[i].Name.toLowerCase().indexOf(filtersChat[f]) != -1) {
                          containFilter = true;
                        }
                      }
                    }
                    if (containFilter) {
                      this.ChatsToResend.push(_.cloneDeep(this.ChatsToResendBack[i]));
                      let chatsLen = this.ChatsToResend.length - 1;
                      this.ChatsToResend[chatsLen].Name = SearchSelectHelper.getSearchedText(filter, this.ChatsToResend[chatsLen].Name);
                    }
                  }
                  
                }
                this.isSearchingResend=false;
              }

              selectChatToResend(chat: ChatInReplyList)
              {
                let SelectedToAttach=[];
                for(let i=0; i<this.Chat.SelectedMessages.length; i++)
                {
                  let attach = new AttachedMessage();
                  attach.CreateFromMessage(this.Chat.SelectedMessages[i]);
                  SelectedToAttach.push(_.cloneDeep(attach));
                }
                this.UnselectAll();
                this.selectingChatForResend=false;
                if(chat.IsPotential)
                {
                  let delegate = [_.cloneDeep(chat.Id), SelectedToAttach];
                  this.ResendInNewChat.emit(delegate)
                }
                if(!chat.IsPotential)
                {
                  let delegate = [_.cloneDeep(chat.Id), SelectedToAttach];
                  this.ResendInExistChat.emit(delegate)
                }
                
                

              }
}

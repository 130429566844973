/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { Chats_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { AttachedMessage, AttachedMessagesGiver, ChatCreateAsker, ChatInList, ChatListGiver, ChatSearchAsker, MessageInOpenedChat } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { DOMHelper } from '../../../Helpers/DOMHelper';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'singleMessageInChat',
    templateUrl: 'temp.singleMessageInChat.html',
    styleUrls: ['singleMessageInChat.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class singleMessageInChatComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    
    langset: LanguageSetCommon = new LanguageSetCommon();
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;

    //ChatAccess: boolean = false;

    @Input() Message: MessageInOpenedChat;
    @Input() NextMessageWorkerId: number=-1;
    @Output() ReadedMessageId: EventEmitter<number> = new EventEmitter<number>();
    @Output() AnsweredMessageId: EventEmitter<number> = new EventEmitter<number>();
    @Output() SelectedMessageId: EventEmitter<number> = new EventEmitter<number>();
    @Output() UnselectedMessageId: EventEmitter<number> = new EventEmitter<number>();
   // @Output() ClearedChatId: EventEmitter<number> = new EventEmitter<number>();


    //CurrentOpenedChat: 
    attachedMessagesTree: AttachedMessage[]=[];
    attachedMessagesPaddings: string [] = [];
    attachedMessagesTreeShowDialog : boolean=false;
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private languageService: LanguageService,
    private sessionService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);
                
                this.HandleLinks([this.Message]);
                

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }

             
              AnswearOnMessage()
              {
                this.AnsweredMessageId.emit(this.Message.Id);
              }

              SelectMessage()
              {
                if(this.Message.IsSystem)
                {
                  return
                }
                if(!this.Message.Selected)
                {this.SelectedMessageId.emit(this.Message.Id);
                  this.Message.Selected=true;}
                  else
                  {
                    this.UnselectedMessageId.emit(this.Message.Id);
                this.Message.Selected=false;
                  }
                
              }

              ReadMessage()
              {
                
                if(this.Message.WorkerId==this.UserId)
                {
                  return
                }
                if(!this.Message.Unreaded)
                {
                  return
                }
                let checker = new SingleIdRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Id=this.Message.Id;
                this.httpServiceChat.chatReadMessage(checker).subscribe((data: CommonResponse) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }

                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Message.Unreaded=false;
                    this.ReadedMessageId.emit(this.Message.Id);
                  }
                  
                })
              }

              onKeyDown(e : KeyboardEvent){
                switch(e.keyCode){
                  case 27:{
                    this.attachedMessagesTreeShowDialog = false;
                  }
                }
              }

              GetAttachedMessagesTree(messageId: number) {
                let checker = new SingleIdRequest();
                checker.Id = messageId;
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                this.httpServiceChat.chatShowAttachedMessagesTree(checker).subscribe((data: AttachedMessagesGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return
                  }
                  if (data.Code == 300) {
                    console.log(data.Message);
                    return;
                  }
                  if (data.Code == 500) { console.error(data.Message); return; }
            
                //  this.HandleLinks(data.Messages, 1);
                  this.attachedMessagesTree = data.Messages;
                  this.attachedMessagesPaddings = [];
                  for (let i = 0; i < data.Messages.length; i++) {
                    let padding = data.Messages[i].Level * 12;
                    this.attachedMessagesPaddings.push(padding + "px");
                  }
                  this.HandleLinks(this.attachedMessagesTree,1)
                  this.attachedMessagesTreeShowDialog = true;
                  DOMHelper.FocusElement(`attachedMessagesTreeShowDialogId${this.Message.Id}`, 50);
                });
                event.stopPropagation();
              }



              HandleLinks(messages: any[], type: number = 0) {
                if (type < 2) {
                  for (let i = 0; i < messages.length; i++) {
                    if (messages[i].Links != null && messages[i].Links.length > 0) {
                      for (let j = 0; j < messages[i].Links.length; j++) {
                        let link = "";
                        if (messages[i].Links[j].Type == 0) {
                          link = '<a href="/client/' + messages[i].Links[j].Id + '">' + messages[i].Links[j].Name + '</a>';
                        }
                        if (messages[i].Links[j].Type == 1) {
                          link = '<a href="/project/' + messages[i].Links[j].Id + '">' + messages[i].Links[j].Name + '</a>';
                        }
                        messages[i].Text = messages[i].Text.replace("{{L_i-n*k$:" + j + "*", link);
                      }
                    }
            
                    if (type == 0 && (messages[i].AttachedMessages != null && messages[i].AttachedMessages.length > 0)) {
                      for (let k = 0; k < messages[i].AttachedMessages.length; k++) {
            
                        if (messages[i].AttachedMessages[k].Links != null && messages[i].AttachedMessages[k].Links.length > 0) {
                          for (let j = 0; j < messages[i].AttachedMessages[k].Links.length; j++) {
                            let link = "";
                            if (messages[i].AttachedMessages[k].Links[j].Type == 0) {
                              link = '<a href="/client/' + messages[i].AttachedMessages[k].Links[j].Id + '">' + messages[i].AttachedMessages[k].Links[j].Name + '</a>';
                            }
                            if (messages[i].Links[j].Type == 1) {
                              link = '<a href="/project/' + messages[i].AttachedMessages[k].Links[j].Id + '">' + messages[i].AttachedMessages[k].Links[j].Name + '</a>';
                            }
                            messages[i].AttachedMessages[k].Text = messages[i].AttachedMessages[k].Text.replace("{{L_i-n*k$:" + j + "*", link);
                          }
                        }
                      }
                    }
                  }
                }
                else if (type == 2) {
                }


                // stop events propagation on link click
                setTimeout(() => {
                  const links = document.getElementsByTagName("a")
                  for (var i = 0; i < links.length; i++) {
                    links[i].addEventListener("click", function(e) {
                      e.stopPropagation(); // this line prevents the link click from bubbling
                      console.log('a clicked');
                    });
                  }
                }, 500)
              }
}

/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges
} from '@angular/core';

import { LocalStorageService } from 'angular-2-local-storage';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { Common_LangSet, Search_LangSet } from '../../models/model.language';
import { LanguageService } from '../../services/language.service';




@Component({
  selector: 'searchFilter',
  templateUrl: 'temp.searchFilter.html',
  styleUrls: ['searchFilter.scss',
    '../../../style/control-inputs.scss',
    '../../../style/supportLibrary.scss',
    '../../../style/font-styles.scss'],

})
export class SearchFilterComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() isLoading: boolean = false;
  @Input() top: string;
  @Input() left: string;
  @Input() ProjectsCount: number;
  @Input() Clients: ListElement[] = [];
  @Input() Addresses: ListElement[] = [];
  @Output()
  AddressesChange = new EventEmitter<any>();

  AddressesBuffer: ListElement[] = [];
  AllAddresses: ListElement[] = [];
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  CloseText: string = "";
  FilterText: string = "";
  search_Langset: Search_LangSet;
  sr_sub: Subscription=null;
  langset: Common_LangSet;
  ln_sub: Subscription=null;
  constructor(
    private localStorageService: LocalStorageService,
    private languageService: LanguageService
  ) { }




  ngOnInit() {
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
      this.sr_sub=this.languageService.searchLanguage$.subscribe(lang=>this.search_Langset=lang);
    this.SetAddresses();
    setTimeout(() => { this.AddressListScrollSubscribe() }, 0);
  }

  SetAddresses() {
    this.AllAddresses = cloneDeep(this.Addresses);
    // this.Addresses = this.Addresses.slice(0, 50);
    this.search();
  }


  ngOnChanges(changes: SimpleChanges) {
    // if (changes.Addresses && changes.Addresses.currentValue) {
    // this.SetAddresses();
    // }
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'Addresses': {
            this.SetAddresses();
          }
        }
      }
    }
  }

  AddressListScrollSubscribe() {
    let trackerClientToAddList = document.getElementById('addressFilterToProjectScrollTracker');
    let windowYOffsetObservable = observableFromEvent(trackerClientToAddList, 'scroll').pipe(map(() => {
      return trackerClientToAddList.scrollTop;
    }));

    let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
      let limit = trackerClientToAddList.scrollHeight - trackerClientToAddList.clientHeight;
      if (((scrollPos === limit) || (scrollPos > limit - 1)) && this.Addresses) {
        this.LoadAddresAdditional();
      }
    });
  }

  LoadAddresAdditional() {
    let containFilter: boolean = false;
    let separator = new RegExp(/ *\s/, "gi");
    if (this.FilterText != null && this.FilterText != "") {
      let filters = this.FilterText.toLowerCase().split(separator);
      for (let i = 0; i < this.AddressesBuffer.length; i++) {
        containFilter = false;
        this.AddressesBuffer[i].Visible = false;
        for (let f = 0; f < filters.length; f++) {
          if (filters[f] != "") {
            if (this.AddressesBuffer[i].Element.Name.toLowerCase().indexOf(filters[f]) != -1) {
              containFilter = true;
            }
          }
        }
        if (containFilter) {
          this.AddressesBuffer[i].Visible = true;
          this.AddressesBuffer[i].Text = SearchSelectHelper.getSearchedText(this.FilterText, this.AddressesBuffer[i].Element.Name);
        }
        else {
          this.AddressesBuffer[i].Visible = false;
          this.AddressesBuffer[i].Text = this.AddressesBuffer[i].Element.Name;
        }
      }
    }
    this.Addresses = this.Addresses.concat(this.AddressesBuffer.filter((el, index) => {
      if ((index >= this.Addresses.length) && (index <= this.Addresses.length + 50)) {
        return el
      }
    }));
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }



  

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode == 27) {
      this.Save();
    }
  }

  Save() {
    for (let i = 0; i < this.Clients.length; i++) {
      this.Clients[i].SelectedBuffer = this.Clients[i].Selected;
      if (this.Clients[i].InnerElement != undefined && this.Clients[i].InnerElement != null) {
        for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
          this.Clients[i].InnerElement[j].SelectedBuffer = this.Clients[i].InnerElement[j].Selected;
        }
      }
    }
    for (let i = 0; i < this.Addresses.length; i++) {
      this.Addresses[i].SelectedBuffer = this.Addresses[i].Selected;
    }
    this.FilterText = "";
    this.AddressesChange.emit(this.Addresses);
    this.saveChanges.emit();
  }

  ResetFilter() {
    this.FilterText = "";
    this.search();
  }

  CancelChanges() {
    for (let i = 0; i < this.Clients.length; i++) {
      this.Clients[i].Selected = this.Clients[i].SelectedBuffer;
      if (this.Clients[i].InnerElement != undefined && this.Clients[i].InnerElement != null) {
        for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
          this.Clients[i].InnerElement[j].Selected = this.Clients[i].InnerElement[j].SelectedBuffer;
        }
      }

    }
    for (let i = 0; i < this.Addresses.length; i++) {
      this.Addresses[i].Selected = this.Addresses[i].SelectedBuffer;
    }
    this.FilterText = "";
    this.saveChanges.emit();
  }


  search() {
    let trackerPersonList = document.getElementById('addressFilterToProjectScrollTracker');
    trackerPersonList.scrollTo(0, 0);
    this.Addresses = [];
    this.AddressesBuffer = [];
    let separator = new RegExp(/ *\s/, "gi");
    if (this.FilterText != null && this.FilterText != "") {
      let filters = this.FilterText.toLowerCase().split(separator);
      let containFilter: boolean = false;
      for (let i = 0; i < this.Clients.length; i++) {
        containFilter = false;
        this.Clients[i].Visible = false;
        for (let f = 0; f < filters.length; f++) {
          if (filters[f] != "") {
            if (this.Clients[i].Element.Name.toLowerCase().indexOf(filters[f]) != -1) {
              this.Clients[i].Visible = true;
              containFilter = true;
            }
          }
        }
        if (containFilter) {
          this.Clients[i].Visible = true;
          this.Clients[i].Text = SearchSelectHelper.getSearchedText(this.FilterText, this.Clients[i].Element.Name);
        }
        else {
          this.Clients[i].Visible = false;
          this.Clients[i].Text = this.Clients[i].Element.Name;
        }

        if (this.Clients[i].InnerElement != undefined && this.Clients[i].InnerElement != null) {
          for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
            containFilter = false;
            this.Clients[i].InnerElement[j].Visible = false;
            for (let ft = 0; ft < filters.length; ft++) {
              if (filters[ft] != "") {
                if (this.Clients[i].InnerElement[j].Element.Name.toLowerCase().indexOf(filters[ft]) != -1) {
                  this.Clients[i].InnerElement[j].Visible = true;
                  containFilter = true;
                }
              }
            }
            if (containFilter) {
              this.Clients[i].InnerElement[j].Visible = true;
              this.Clients[i].InnerElement[j].Text = SearchSelectHelper.getSearchedText(this.FilterText, this.Clients[i].InnerElement[j].Element.Name);
            }
            else {
              this.Clients[i].InnerElement[j].Visible = false;
              this.Clients[i].InnerElement[j].Text = this.Clients[i].InnerElement[j].Element.Name;
            }
          }
        }
      }

      for (let i = 0; i < this.AllAddresses.length; i++) {
        containFilter = false;
        this.AllAddresses[i].Visible = false;
        for (let f = 0; f < filters.length; f++) {
          if (filters[f] != "") {
            if (ExtendedHelper.ContainsNoCase(this.AllAddresses[i].Element.Name.toLowerCase(), filters[f])) {
              // if (this.AllAddresses[i].Element.Name.toLowerCase().indexOf(filters[f]) != -1) {
              this.AllAddresses[i].Visible = true;
              containFilter = true;
            }
          }
        }
        if (containFilter) {
          this.AllAddresses[i].Visible = true;
          this.AllAddresses[i].Text = SearchSelectHelper.getSearchedText(this.FilterText, this.AllAddresses[i].Element.Name);
          this.AddressesBuffer.push(this.AllAddresses[i]);
        }
        else {
          this.AllAddresses[i].Visible = false;
          this.AllAddresses[i].Text = this.AllAddresses[i].Element.Name;
        }
      }
    }
    else {
      for (let i = 0; i < this.Clients.length; i++) {
        this.Clients[i].Visible = true;
        this.Clients[i].Text = this.Clients[i].Element.Name;
        if (this.Clients[i].InnerElement != undefined && this.Clients[i].InnerElement != null) {
          for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
            this.Clients[i].InnerElement[j].Visible = true;
            this.Clients[i].InnerElement[j].Text = this.Clients[i].InnerElement[j].Element.Name;
          }
        }
      }

      for (let i = 0; i < this.AllAddresses.length; i++) {
        this.AddressesBuffer.push(this.AllAddresses[i]);
        this.AllAddresses[i].Visible = true;
        this.AllAddresses[i].Text = this.AllAddresses[i].Element.Name;
      }
    }
    this.Addresses = this.AddressesBuffer.slice(0, 50);
  }


  UnselectUnion(elementSelect: boolean, unionSelect: ListElement) {
    if (!elementSelect && unionSelect.Selected) {
      unionSelect.Selected = false;
    }
  }


  ClearFilters() {
    for (let i = 0; i < this.Addresses.length; i++) {
      this.Addresses[i].Selected = false;
    }
    for (let i = 0; i < this.Clients.length; i++) {
      this.Clients[i].Selected = false;

      if (this.Clients[i].InnerElement != undefined && this.Clients[i].InnerElement != null && this.Clients[i].InnerElement.length > 0) {
        for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
          this.Clients[i].InnerElement[j].Selected = false;
        }
      }
    }
    this.Save();
  }


  public UnselectFilter(id: number, useUnion: boolean) {
    for (let i = 0; i < this.Clients.length; i++) {
      if (this.Clients[i].Element.Id == id && this.Clients[i].WithInner == useUnion) {
        this.Clients[i].Selected = false;
      }
      for (let j = 0; j < this.Clients[i].InnerElement.length; j++) {
        if (this.Clients[i].InnerElement[j].Element.Id == id && this.Clients[i].InnerElement[j].WithInner == useUnion) {
          this.Clients[i].InnerElement[j].Selected = false;
        }
      }
    }
  }

  ChooseElem(element: ListElement) {
    element.Selected = true;
    this.Save();
  }
}

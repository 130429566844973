import { SvgIconsRegistry } from '../svg-icon/svg-icon-registry.service';
/**
 * Created by MosNeuro on 30.11.2016.
 */
import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'
import { LocalStorageService } from 'angular-2-local-storage';
import { CloseIcon } from '../svg-icon/svg-icon.data';
import { Common_LangSet } from '../../../models/model.language';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language.service';


declare var require: any;


@Component({
    selector: 'app-dialog',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss',
        // '../../style/font-awesome.min.scss',
        '../../../../style/supportLibrary.scss',
        // '../../iconfont/material-icons.scss',
    ],
    

    animations: [
        trigger('dialog', [
            transition('void => *', [
                style({ transform: 'scale3d(.3, .3, .3)' }),
                animate(100)
            ]),
            transition('* => void', [
                animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
            ])
        ])
    ],


    
})
export class DialogComponent implements OnInit {
    @Input() closable = true;
    @Input() overlay = true;
    //  @Input() renderText :boolean;
    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    CloseText: string = "";
    @Input() level = 0;
    @Input() closeConfirm: boolean=false;
    @Input() closeConfirmText: string="Вы уверенны что хотите закрыть всплывающее окно?";

    langset: Common_LangSet;
    ln_sub: Subscription=null;


    constructor(
        private localStorageService: LocalStorageService,
        private languageService: LanguageService
    ) {

    }




  

    ngOnInit() {
        this.LanguageSetGet();
        // this.renderText=true;
        // this.element[0].focus();
    }

    close() {
        if(!this.closeConfirm)
        {
            if (this.overlay) {

                this.visible = false;
                //  this.renderText=false;
                this.visibleChange.emit(this.visible);
                
            }
        }
        else if(this.closeConfirm && confirm(this.closeConfirmText))
        {
            if (this.overlay) {

                this.visible = false;
                //  this.renderText=false;
                this.visibleChange.emit(this.visible);
                
            }
        }
        
    }

    // @HostListener('keydown.esc')
    // public onEsc(): void {
    //
    //
    //     //this.renderText=false;
    //     this.visible=false;//.dialog.hide();
    //     this.visibleChange.emit(this.visible);
    // }


    LanguageSetGet() {
        this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);

        let localSt = this.localStorageService.isSupported;
        if (localSt) {
            console.log();
            
            this.CloseText = this.localStorageService.get("Close");
        }

    }



}

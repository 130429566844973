/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { ChatCreateAsker, ChatInList, ChatListGiver, ChatNotificator, ChatRefreshAsker } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { cloneDeep } from 'lodash';
import { SessionService } from '../../../services/session.service';
import { LoginGiver } from '../../../models/model.login';

declare var require: any;


@Component({
    selector: 'chatListContainer',
    templateUrl: 'temp.chatListContainer.html',
    styleUrls: ['chatListContainer.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class chatListContainerComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    jwtToken: string;
    
    
    langset: LanguageSetCommon = new LanguageSetCommon();
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    jwtToken$: Subscription=null;

    //ChatAccess: boolean = false;

    ChatList: ChatInList[];
    @Output() SelectedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Input() RefreshChatsInList: Subject<boolean> = new Subject<boolean>();
    @Input() ReadMessageInChat: Subject<number> = new Subject<number>();
    

    isSearching: boolean = false;
    isLoading: boolean=true;
    //CurrentOpenedChat: 
   
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private sessionService:SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                if(this.RefreshChatsInList.observers==undefined ||this.RefreshChatsInList.observers==null || this.RefreshChatsInList.observers.length>0)
                {
                  this.RefreshChatsInList.observers=[];
                }
                if(this.ReadMessageInChat.observers==undefined ||this.ReadMessageInChat.observers==null || this.ReadMessageInChat.observers.length>0)
                {
                  this.ReadMessageInChat.observers=[];
                }
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.isLoading=true;
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
                this.jwtToken$=this.sessionService.jwtToken$.subscribe(token => this.jwtToken = token);
                this.RefreshChatsInList.subscribe(()=>{
                  if(this.ChatList!=undefined && this.ChatList!=null && this.ChatList.length>0)
                  {
                    this.RefreshChatList();
                  }
                  else
                  {
                    this.GetChatList();
                  }
                })

                this.ReadMessageInChat.subscribe((c)=>{
                 this.MarkMessageAsReaded(c);
                })
                
                if (!(this.UserId > 0)) {//куков нет значит не авторизованы значит идем логниться
                  this.unLogin();
                }
                else {//куки есть, чекаем сессию
                  let sessionChecker = new CommonRequest();
                  sessionChecker.SessionKey = this.SessionKey;
                  sessionChecker.ChangerId = this.UserId;
                  sessionChecker.UserAuthToken = this.jwtToken;
                  this.httpServiceSession.checkSession(sessionChecker).subscribe((data: LoginGiver) => {
                    if (data.Code == 1) {//если нужно заменить сессию
                      this.SessionKey = data.SessionKey;
                      this.jwtToken = data.UserAuthToken;
                      sessionChecker.SessionKey = this.SessionKey;
                      sessionChecker.UserAuthToken = this.jwtToken;
                      this.cookieService.put('SessionKey', this.SessionKey, this.cookieOptionsMy.get());
                      this.cookieService.put('UserAuthToken', this.jwtToken, this.cookieOptionsMy.get());
                    }
                    if (data.Code == 301 || data.Code == 302 || data.Code == 303 || data.Code == 304 || data.Code == 500) {
                      console.error(data.Message);
                      this.unLogin();
                      return;
                    }
                    if (data.Code == 306) {
                      alert('The request object is filled incorrectly.');
                      return;
                    }
                    if (data.Code == 500) { console.error(data.Message); return; }
            
                    if (data.Code == 0 || data.Code == 1) {//иначе все ок и продолжаем рабоать
            
                      
                        this.extendedHelper.ResetLastMessageTime();
                        this.GetChatList();
                      
                    }
                  });
                }

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }

              GetChatList()
              {
                this.isLoading=true;
                let checker = new CommonRequest();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                this.httpServiceChat.chatGetChatsList(checker).subscribe((data: ChatListGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    data.ChatList.forEach(item => {
                      item.Message = item.Message.length > 0? item.Message:"Пересланное сообщение";
                    })
                    this.ChatList=_.cloneDeep(data.ChatList);
                    
                    this.isLoading=false;
                  }
                  
                })
              }

              RefreshChatList()
              {
                this.isLoading=true;
                let checker = new ChatRefreshAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                if(this.ChatList!=undefined && this.ChatList!=null && this.ChatList.length>0)
                {checker.UpdateAfter=this.ChatList[0].MessageDate;}
                else{
                  checker.UpdateAfter=new Date();
                }
                
                this.httpServiceChat.chatListRefresh(checker).subscribe((data: ChatListGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    for(let i=data.ChatList.length-1; i>=0; i--)
                    {
                      let ind=this.ChatList.findIndex(c=>c.Id==data.ChatList[i].Id)
                      {
                        if(ind>-1)
                        {
                          
                          this.ChatList.splice(ind,1);
                          this.ChatList.splice(0,0,_.cloneDeep(data.ChatList[i]));
                        }
                        else
                        {
                          this.ChatList.splice(0,0,_.cloneDeep(data.ChatList[i]))
                        }
                      }
                    }
                    this.isLoading=false;
                  }
                  
                })
              }


              DeleteChatFromList(id: number)
              {
                let ind = this.ChatList.findIndex(c=>c.Id==id);
                if(ind>-1)
                {
                  this.ChatList.splice(ind,1);
                }
              }

              GoToChat(id: number)
              {
                this.SelectedChatId.emit(id);
              }
       

              MarkMessageAsReaded(crossId: number)
              {
                for(let i=0; i<this.ChatList.length; i++)
                {
                  if(this.ChatList[i].CrossId==crossId)
                  {
                    this.ChatList[i].Unreaded=false;
                  }
                  
                }
              }

}

import { element } from 'protractor';
import { SearchSelectHelper } from "../Helpers/SearchSelectHelper";
import { Common } from "./model.common";
import { ExtendedHelper } from "../config/ExtendedHelper";
import { CommonWorkerHolder } from "./model.workerAttached";
import { AutoJobAuthorHolder, AutoJobExectutorHolder } from './model.Autojob/model.AutojobTemplate';

export class MultyselectContainer {
  MultyselectElements: MultyselectElement[] = [];
  IsMultyselect: boolean = true;
  Visible: boolean = false;
  Filter: string = "";



  CopyFromExisting(existing) {
    //let container = new MultyselectContainer();
    this.IsMultyselect = existing.IsMultyselect;
    this.Visible = existing.Visible;
    this.Filter = existing.Filter;
    for (let i = 0; i < existing.MultyselectElements.length; i++) {
      let element = new MultyselectElement();
      element.Visible = existing.MultyselectElements[i].Visible;
      element.Element = existing.MultyselectElements[i].Element;
      element.TextToShow = existing.MultyselectElements[i].TextToShow;
      element.Selected = existing.MultyselectElements[i].Selected;
      element.elementType = existing.MultyselectElements[i].elementType;
      element.Focus = existing.MultyselectElements[i].Focus;
      element.IsExecutor=existing.MultyselectElements[i].IsExecutor;
      this.MultyselectElements.push(element);
    }
    //return(container);

  }

  AddElements(ElementContainer?: CommonWorkerHolder[], elementRole?: Common[], type: number = 0) {
    if (type == 1) {
      for (let i = 0; i < ElementContainer.length; i++) {
        if (ElementContainer[i].IsWorker) {
          let elem = new MultyselectElement();
          elem.Element = ElementContainer[i];
          elem.TextToShow = ElementContainer[i].Worker.Name;
          elem.IsExecutor=true;
          elem.Selected = false;
          elem.Focus = false;
          elem.elementType = type;
          this.MultyselectElements.push(elem);
        } else {
          let elem = new MultyselectElement();
          elem.IsHolder = true;
          elem.IsExecutor=false;
          elem.Element.IsWorker = false;
          elem.TextToShow = ElementContainer[i].Departament.Name;
          elem.Selected = false;
          elem.Focus = false;

          if (ElementContainer[i].Departament != null && ElementContainer[i].Departament.Workers.length > 0) {
            elem.Child = [];
            ElementContainer[i].Departament.Workers.forEach(element => {
              let childElem = new MultyselectElement();
              childElem.Element.IsWorker = true;
              childElem.Element.Worker = element;
              childElem.TextToShow = element.Name;
              childElem.Selected = false;
              childElem.Focus = false;
              childElem.Selected = false;
              childElem.Focus = false;
              childElem.elementType = type;
              elem.Child.push(childElem);
            });

          }
          this.MultyselectElements.push(elem);

        }
      }
    }
    if (type == 0) {
      for (let i = 0; i < elementRole.length; i++) {
        let elem = new MultyselectElement();
        elem.Element.Worker = new Common()
        elem.Element.Worker = elementRole[i];
        elem.Element.IsWorker = true;
        elem.IsExecutor=true;
        elem.TextToShow = elementRole[i].Name;
        elem.Selected = false;
        elem.Focus = false;
        elem.elementType = type;
        this.MultyselectElements.push(elem);
      }
    }
  }


  UnselectAll() {
    for (let i = 0; i < this.MultyselectElements.length; i++) {
      if (this.MultyselectElements[i].Element.IsWorker) {
        this.MultyselectElements[i].Selected = false;
      } else {
        if (this.MultyselectElements[i].Child != null && this.MultyselectElements[i].Child.length > 0) {
          this.MultyselectElements[i].Child.forEach(el => {
            el.Selected = false;
          })
        }
      }
    }
  }

  ConverAndAddElement(Author: AutoJobAuthorHolder, Executor: AutoJobExectutorHolder, type: number = 0) {
    let elem = new MultyselectElement();

    if (Author != null) {
      let commonWorker = new CommonWorkerHolder();
      if (Author.IsAuthor) {
        elem.IsExecutor=true;
        commonWorker.IsWorker = Author.IsAuthor;
        commonWorker.Worker = new Common(Author.Author.Id, Author.Author.Name);
        commonWorker.Departament = null;
        elem.TextToShow = Author.Author.Name;
        elem.Selected = Author.Author.Selected;
        elem.Focus = false;
        elem.elementType = type;//Author.Author.Type
        elem.Element = commonWorker;
       
      } else {
        elem.IsExecutor=false;
        elem.Element.IsWorker = false;
        elem.Element.Worker = new Common(Author.Department.Id, Author.Department.Name);
        elem.Focus = false;
        elem.Visible = true;
        elem.Child = [];
        elem.TextToShow = Author.Department.Name;
        if (Author.Department != null) {
          Author.Department.Authors.forEach(elementCh => {
            let elemChild = new MultyselectElement();
            let commonChild = new CommonWorkerHolder();
            commonChild.IsWorker = true;
            commonChild.Worker = new Common(elementCh.Id, elementCh.Name);
            commonChild.Departament = null;
            elemChild.Element = commonChild;

            elemChild.TextToShow = elementCh.Name;
            elemChild.Selected = elementCh.Selected;
            elemChild.Focus = false;
            elemChild.elementType = elementCh.Type;
            elem.Child.push(elemChild);
          });
        }
      }
      this.MultyselectElements.push(elem);
    }

    if (Executor != null) {
      let commonWorker = new CommonWorkerHolder();
      if (Executor.IsExecutor) {
        //commonWorker.IsExecutor=true;
        elem.IsExecutor=true;
        commonWorker.IsWorker = Executor.Executor.IsWorker;
        commonWorker.Worker = new Common(Executor.Executor.Id, Executor.Executor.Name);
        commonWorker.Departament = null;
        elem.TextToShow = Executor.Executor.Name;
        elem.Selected = Executor.Executor.Selected;
        elem.Focus = false;
        elem.elementType = type;//Author.Author.Type
        elem.Element = commonWorker;
      } else {
       // commonWorker.IsExecutor=false;
       elem.IsExecutor=false;
        elem.Element.IsWorker = false;
        elem.Element.Worker = new Common(Executor.Department.Id, Executor.Department.Name);
        elem.Focus = false;
        elem.Visible = true;
        elem.Child = [];
        elem.TextToShow = Executor.Department.Name;

        if (Executor.Department != null) {
          Executor.Department.Executors.forEach(elementCh => {
            let elemChild = new MultyselectElement();
            let commonChild = new CommonWorkerHolder();
            commonChild.IsWorker = true;
            commonChild.Worker = new Common(elementCh.Id, elementCh.Name);
            commonChild.Departament = null;
            elemChild.Element = commonChild;

            elemChild.TextToShow = elementCh.Name;
            elemChild.Selected = elementCh.Selected;
            elemChild.Focus = false;
            elemChild.elementType = type;
            elem.Child.push(elemChild);
          });
        }
      }
      this.MultyselectElements.push(elem);
    }
    // console.log(this.MultyselectElements)

  }

  AddElement(element: any, type: number = 0) {
    if (!element.IsHolder) {
      let elem = new MultyselectElement();
      elem.Element = element;
      elem.TextToShow = element.Name;
      elem.elementType = type;
      elem.IsExecutor=true;
      elem.Selected = (element.Executor != undefined) ? element.Executor.Selected : null
        || (element.Author != undefined) ? element.Author.Selected : null
        || false;
      elem.Focus = false;
      elem.Visible = true;
      elem.Element.IsWorker = true;
      elem.TextToShow = element.Name;
      this.MultyselectElements.push(elem);
    } else {
      let elem = new MultyselectElement();
      elem.IsHolder = true;
      elem.TextToShow = element.Department.Name;
      elem.Selected = false;
      elem.Focus = false;
      elem.IsExecutor=false;

      element.Department.forEach(element => {
        let childElem = new MultyselectElement();
        childElem.Element.IsWorker = true;
        childElem.Element.Worker = element;
        childElem.TextToShow = element.Name;
        childElem.Selected = element.Selected;
        childElem.Focus = false;
        childElem.Selected = false;
        childElem.Focus = false;
        childElem.elementType = element.Type;
        elem.Child.push(childElem);
      });
      this.MultyselectElements.push(elem);

    }
  }


  AddElementToStart(element: any) {
    let elem = new MultyselectElement();
    elem.Element = element;
    elem.TextToShow = element.Name;
    elem.Selected = false;
    elem.Focus = false;
    this.MultyselectElements.splice(0, 0, elem);
  }

  IsEmptySelected() {
    let empty = this.MultyselectElements.map(c => c.Selected).indexOf(true);
    if (empty == -1) { return true; }
    else { return false; }
  }

  SelectElement(id: number) {
    if (!this.IsMultyselect) {
      this.UnselectAll();
    }
    let index = this.MultyselectElements.map(c => c.Element.Worker.Id).findIndex(c => c == id);
    if (index > -1) {
      this.MultyselectElements[index].Selected = true;
    }
  }


  SelectElementsByIdAndType(element: number[], type: number = 0) {
    // this.UnselectAll();
    if (element != null) {
      if (type == 0) {
        for (let i = 0; i < element.length; i++) {

          let index = this.MultyselectElements
            .findIndex(c => c.Element.IsWorker && c.Element.Worker.Id == element[i] && c.elementType == 0);

          if (index > -1) {
            this.MultyselectElements[index].Selected = true;
          }
        }
      }


      if (type > 0) {
        for (let i = 0; i < element.length; i++) {
          this.MultyselectElements.forEach(elementList => {
            if (elementList.Element.IsWorker) {
              if (elementList.Element.Worker.Id == element[i] && elementList.elementType == 1) {
                elementList.Selected = true;
              }
            }
            // } else {
            if (elementList.IsHolder) {
              if (elementList.Child != null && elementList.Child.length > 0) {
                elementList.Child.forEach(elementCh => {
                  if (elementCh.Element.Worker.Id == element[i] && elementCh.elementType == 1) {
                    elementCh.Selected = true;
                  }
                });
              }
            }
            // }
          });
        }
      }
    }



  }


  SelectElementByIdAndType(element: number, type: number = 0) {
    this.UnselectAll();
    if (type == 0) {
      let index = this.MultyselectElements.findIndex(c => c.Element.Worker.Id == element && c.elementType == 0);
      if (index > -1) {
        this.MultyselectElements[index].Selected = true;
      }

    }

    if (type > 0) {
      this.MultyselectElements.forEach(elementList => {
        if (elementList.Element.IsWorker) {
          if (elementList.Element.Worker.Id == element && elementList.elementType == 1) {
            elementList.Selected = true;
          }
        } else {
          if (elementList.Child != null && elementList.Child.length > 0) {
            elementList.Child.forEach(elementCh => {
              if (elementCh.Element.Worker.Id == element && elementCh.elementType == 1) {
                elementCh.Selected = true;

              }
            });
          }
        }
      });

    }
  }

  UnselectElementByIdAndType(element: number, type: number = 0) {
    if (type == 0) {
      let index = this.MultyselectElements.findIndex(c => c.Element.Worker.Id == element && c.elementType == 0);
      if (index > -1) {
        this.MultyselectElements[index].Selected = false;
      }

    }


    if (type > 0) {
      this.MultyselectElements.forEach(elementList => {
        if (elementList.Element.IsWorker) {
          if (elementList.Element.Worker.Id == element && elementList.elementType == 1) {
            elementList.Selected = false;
          }
        } else {
          if (elementList.Child != null && elementList.Child.length > 0) {
            elementList.Child.forEach(elementCh => {
              if (elementCh.Element.Worker.Id == element && elementCh.elementType == 1) {
                elementCh.Selected = false;
              }
            });
          }
        }
      });

    }
  }


  GetAllSelectedIds() {
    let list: number[] = [];

    for (let i = 0; i < this.MultyselectElements.length; i++) {
      //if (this.MultyselectElements[i].Selected) { list.push(this.MultyselectElements[i].Element.Worker.Id); }
      if (this.MultyselectElements[i].IsExecutor) {
        if (this.MultyselectElements[i].Selected) list.push(this.MultyselectElements[i].Element.Worker.Id);
      } else {
        if (this.MultyselectElements[i].Child != null && this.MultyselectElements[i].Child.length > 0) {
          this.MultyselectElements[i].Child.forEach(element => {
            if (element.Selected) list.push(element.Element.Worker.Id);
          });
        }
      }
    }
    return list;
  }



  GetAllSelected() {
    let list: MultyselectElement[] = [];
    for (let i = 0; i < this.MultyselectElements.length; i++) {
      if (this.MultyselectElements[i].IsExecutor) {
        if (this.MultyselectElements[i].Selected) list.push(this.MultyselectElements[i]);
      } else {
        if (this.MultyselectElements[i].Child != null && this.MultyselectElements[i].Child.length > 0) {

          this.MultyselectElements[i].Child.forEach(element => {
            if (element.Selected) list.push(element);
          });
        }
      }
    }
    return list;
  }



  SearchByFilter() {
    for (let i = 0; i < this.MultyselectElements.length; i++) {
      this.MultyselectElements[i].Visible = false;
    }

    if (this.Filter != null && this.Filter != undefined && this.Filter != "") {
      let separator = new RegExp(/ *\s/, "gi");
      for (let i = 0; i < this.MultyselectElements.length; i++) {
        if (this.MultyselectElements[i].Element.IsWorker) {
          let filters = this.Filter.split(separator);
          for (let f = 0; f < filters.length; f++) {
            if (filters[f] != "") {
              if (ExtendedHelper.ContainsNoCase(this.MultyselectElements[i].Element.Worker.Name, filters[f])) {
                this.MultyselectElements[i].TextToShow = SearchSelectHelper.getSearchedText(this.Filter, this.MultyselectElements[i].Element.Worker.Name);
                this.MultyselectElements[i].Visible = true;
              }
            }
          }
        } else {
          if (this.MultyselectElements[i].Child != null && this.MultyselectElements[i].Child.length > 0) {
            this.MultyselectElements[i].Child.forEach(element => {
              let filters = this.Filter.split(separator);
              for (let f = 0; f < filters.length; f++) {
                if (filters[f] != "") {
                  if (ExtendedHelper.ContainsNoCase(element.Element.Worker.Name, filters[f])) {
                    element.TextToShow = SearchSelectHelper.getSearchedText(this.Filter, element.Element.Worker.Name);
                    element.Visible = true;
                  } else {
                    element.Visible = false;
                  }
                }
              }
            });
            if (this.MultyselectElements[i].Child.find(c => c.Visible)) this.MultyselectElements[i].Visible = true;
          }
        }


      }
    }

    if (this.Filter == "") {
      for (let i = 0; i < this.MultyselectElements.length; i++) {
        if (this.MultyselectElements[i].Element.IsWorker) {
          this.MultyselectElements[i].TextToShow = this.MultyselectElements[i].Element.Worker.Name;
          this.MultyselectElements[i].Visible = true;
        } else {
          if (this.MultyselectElements[i].Child != null && this.MultyselectElements[i].Child.length > 0) {
            this.MultyselectElements[i].Child.forEach(element => {
              element.TextToShow = element.Element.Worker.Name;
              element.Visible = true;
            });
          }
        }
      }
    }
  }

  ClearFilter() {
    this.Filter = '';
    for (let i = 0; i < this.MultyselectElements.length; i++) {
      if (this.MultyselectElements[i].Element.IsWorker) {
        this.MultyselectElements[i].TextToShow = this.MultyselectElements[i].Element.Worker.Name;
        this.MultyselectElements[i].Visible = true;
      } else {
        if (this.MultyselectElements[i].Element.Departament != null) {
          this.MultyselectElements[i].Child.forEach(element => {
            element.TextToShow = element.Element.Worker.Name;
            element.Visible = true;
          });
        }
      }
    }
  }

}

export class MultyselectElement {
  TextToShow: string;
  Element: CommonWorkerHolder = new CommonWorkerHolder();
  elementType: number = 0;
  Selected: boolean;
  Focus: boolean;
  Visible: boolean = true;
  IsHolder: boolean = false;
  Child: MultyselectElement[];
  IsExecutor: boolean = true;
}



export class AutojobMultyselectContainer {
  MultyselectElements: AutojobMultyselectElement[] = [];
  IsMultyselect: boolean = true;
  Visible: boolean = false;
  Filter: string = "";
  SelectedElements: AutojobMultyselectElement[] = [];
  SelectedValueOuterTypes: number[]=[];


  SelectElement(element: AutojobMultyselectElement) {
      for(let i=0; i<this.MultyselectElements.length; i++)
      {
        if(this.MultyselectElements[i].ElementType!=2 && this.MultyselectElements[i].ElementType==element.ElementType && this.MultyselectElements[i].Id==element.Id)
        {
          this.MultyselectElements[i].Selected=true;
          this.SelectedElements.push(this.MultyselectElements[i]);
        }
        else
        {
          if( this.MultyselectElements[i].ElementType==2)
          {
            for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
            {
              if(this.MultyselectElements[i].Children[j].ElementType!=2 && this.MultyselectElements[i].Children[j].ElementType==element.ElementType && this.MultyselectElements[i].Children[j].Id==element.Id)
              {
                this.MultyselectElements[i].Children[j].Selected=true;
                this.SelectedElements.push(this.MultyselectElements[i].Children[j]);
              }
            }
          }

        }
      }
  }


  UnselectElement(element: AutojobMultyselectElement) {
    
    for(let i=0; i<this.MultyselectElements.length; i++)
    {
      if(this.MultyselectElements[i].ElementType!=2 && this.MultyselectElements[i].ElementType==element.ElementType && this.MultyselectElements[i].Id==element.Id)
      {
        this.MultyselectElements[i].Selected=false;
        //this.SelectedElements.push(this.MultyselectElements[i]);
      }
      else
      {
        if( this.MultyselectElements[i].ElementType==2)
        {
          for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
          {
            if(this.MultyselectElements[i].Children[j].ElementType!=2 && this.MultyselectElements[i].Children[j].ElementType==element.ElementType && this.MultyselectElements[i].Children[j].Id==element.Id)
            {
              this.MultyselectElements[i].Children[j].Selected=false;
              //this.SelectedElements.push(this.MultyselectElements[i].Children[j]);
            }
          }
        }

      }

      let selInd = this.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
      if(selInd>-1)
      {
        this.SelectedElements.splice(selInd,1);
      }
    }
  }


    UnselectAll() {
    
      for(let i=0; i<this.MultyselectElements.length; i++)
      {
        
          this.MultyselectElements[i].Selected=false;
          if( this.MultyselectElements[i].ElementType==2)
          {
            for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
            {
                this.MultyselectElements[i].Children[j].Selected=false;
            }
          }
      }
      this.SelectedElements=[];
    }

    ShowAllElements()
    {
      if(this.MultyselectElements!=undefined && this.MultyselectElements!=null && this.MultyselectElements.length!=0)
      {
        for(let i=0; i<this.MultyselectElements.length; i++)
        {
          if(this.MultyselectElements[i].ElementType!=2)
          {
            this.MultyselectElements[i].TextToShow=this.MultyselectElements[i].Element.Name;
          }
          this.MultyselectElements[i].Visible=true;
          if(this.MultyselectElements[i].Children!=undefined && this.MultyselectElements[i].Children!=null && this.MultyselectElements[i].Children.length!=0)
          {
            for(let k=0; k<this.MultyselectElements[i].Children.length; k++)
            {
              this.MultyselectElements[i].Children[k].TextToShow=this.MultyselectElements[i].Children[k].Element.Name;
              this.MultyselectElements[i].Children[k].Visible=true;
            }
          }
        }
      }
      
    }

    HideAllElements()
    {
      if(this.MultyselectElements!=undefined && this.MultyselectElements!=null && this.MultyselectElements.length!=0)
      {
        for(let i=0; i<this.MultyselectElements.length; i++)
        {
          if(this.MultyselectElements[i].ElementType!=2)
          {
            this.MultyselectElements[i].TextToShow=this.MultyselectElements[i].Element.Name;
          }
          this.MultyselectElements[i].Visible=false;
          if(this.MultyselectElements[i].Children!=undefined && this.MultyselectElements[i].Children!=null && this.MultyselectElements[i].Children.length!=0)
          {
            for(let k=0; k<this.MultyselectElements[i].Children.length; k++)
            {
              this.MultyselectElements[i].Children[k].TextToShow=this.MultyselectElements[i].Children[k].Element.Name;
              this.MultyselectElements[i].Children[k].Visible=false;
            }
          }
        }
      }
    }
    

    Search()
    {
      if(this.Filter == undefined ||this.Filter == null ||  this.Filter=="")
      {
        this.ShowAllElements();
      }
      else
      {
        this.HideAllElements();

        let separator = new RegExp(/ *\s/, "gi");
        for (let i = 0; i < this.MultyselectElements.length; i++) {
          if (this.MultyselectElements[i].ElementType!=2) {
            let filters = this.Filter.split(separator);
            for (let f = 0; f < filters.length; f++) {
              if (filters[f] != "") {
                if (ExtendedHelper.ContainsNoCase(this.MultyselectElements[i].Element.Name, filters[f])) {
                  this.MultyselectElements[i].TextToShow = SearchSelectHelper.getSearchedText(this.Filter, this.MultyselectElements[i].Element.Name);
                  this.MultyselectElements[i].Visible = true;
                }
              }
            }
          } else {
            if (this.MultyselectElements[i].Children != null && this.MultyselectElements[i].Children.length > 0) {
              this.MultyselectElements[i].Children.forEach(element => {
                let filters = this.Filter.split(separator);
                for (let f = 0; f < filters.length; f++) {
                  if (filters[f] != "") {
                    if (ExtendedHelper.ContainsNoCase(element.Element.Name, filters[f])) {
                      element.TextToShow = SearchSelectHelper.getSearchedText(this.Filter, element.Element.Name);
                      element.Visible = true;
                    } else {
                      element.Visible = false;
                    }
                  }
                }
              });
              if (this.MultyselectElements[i].Children.find(c => c.Visible)) this.MultyselectElements[i].Visible = true;
            }
          }
  
  
        }

      }
    }
    
}

export class AutojobMultyselectElement {
  Id: number;
  TextToShow: string;
  Element: AutojobMultyselectElementValue = new AutojobMultyselectElementValue();
  ElementType: number = 0;
  Selected: boolean;
  Focus: boolean;
  Visible: boolean = true;
  IsHolder: boolean = false;
  Children: AutojobMultyselectElement[];
  IsSelectable: boolean = true;
}

export class AutojobMultyselectElementValue {
  Id: number;
  Name: string;
  ValueOuterType: number;
}









///////////////////////////////Для проектов//////////////////////////////////
export class AutojobMultyselectInProjectContainer {
  MultyselectElements: AutojobMultyselectInProjectElement[] = [];
  IsMultyselect: boolean = true;
  Visible: boolean = false;
  Filter: string = "";
  SelectedElements: AutojobMultyselectInProjectElement[] = [];
  SelectedValueOuterTypes: number[]=[];
  CheckInAdditional: boolean=false;
  IsValid: boolean = true;
  IsChangeable: boolean = true;


  SelectElement(element: AutojobMultyselectInProjectElement) {
      for(let i=0; i<this.MultyselectElements.length; i++)
      {
        if(this.MultyselectElements[i].ElementType!=2 && this.MultyselectElements[i].ElementType==element.ElementType && this.MultyselectElements[i].Id==element.Id)
        {
          this.MultyselectElements[i].Selected=true;
          this.SelectedElements.push(this.MultyselectElements[i]);
        }
        else
        {
          if( this.MultyselectElements[i].ElementType==2)
          {
            for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
            {
              if(this.MultyselectElements[i].Children[j].ElementType!=2 && this.MultyselectElements[i].Children[j].ElementType==element.ElementType && this.MultyselectElements[i].Children[j].Id==element.Id)
              {
                this.MultyselectElements[i].Children[j].Selected=true;
                this.SelectedElements.push(this.MultyselectElements[i].Children[j]);
              }
            }
          }

        }
      }
  }


  UnselectElement(element: AutojobMultyselectInProjectElement) {
    
    for(let i=0; i<this.MultyselectElements.length; i++)
    {
      if(this.MultyselectElements[i].ElementType!=2 && this.MultyselectElements[i].ElementType==element.ElementType && this.MultyselectElements[i].Id==element.Id)
      {
        this.MultyselectElements[i].Selected=false;
        //this.SelectedElements.push(this.MultyselectElements[i]);
      }
      else
      {
        if( this.MultyselectElements[i].ElementType==2)
        {
          for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
          {
            if(this.MultyselectElements[i].Children[j].ElementType!=2 && this.MultyselectElements[i].Children[j].ElementType==element.ElementType && this.MultyselectElements[i].Children[j].Id==element.Id)
            {
              this.MultyselectElements[i].Children[j].Selected=false;
              //this.SelectedElements.push(this.MultyselectElements[i].Children[j]);
            }
          }
        }

      }

      let selInd = this.SelectedElements.findIndex(c=>c.Id==element.Id && c.ElementType==element.ElementType);
      if(selInd>-1)
      {
        this.SelectedElements.splice(selInd,1);
      }
    }
  }


    UnselectAll() {
    
      for(let i=0; i<this.MultyselectElements.length; i++)
      {
        
          this.MultyselectElements[i].Selected=false;
          if( this.MultyselectElements[i].ElementType==2)
          {
            for(let j=0; j<this.MultyselectElements[i].Children.length; j++)
            {
                this.MultyselectElements[i].Children[j].Selected=false;
            }
          }
      }
      this.SelectedElements=[];
    }

    ShowAllElements()
    {
      if(this.MultyselectElements!=undefined && this.MultyselectElements!=null && this.MultyselectElements.length!=0)
      {
        for(let i=0; i<this.MultyselectElements.length; i++)
        {
          if(this.MultyselectElements[i].ElementType!=2)
          {
            this.MultyselectElements[i].TextToShow=this.MultyselectElements[i].Element.Name;
          }
          this.MultyselectElements[i].Visible=true;
          if(this.MultyselectElements[i].Children!=undefined && this.MultyselectElements[i].Children!=null && this.MultyselectElements[i].Children.length!=0)
          {
            for(let k=0; k<this.MultyselectElements[i].Children.length; k++)
            {
              this.MultyselectElements[i].Children[k].TextToShow=this.MultyselectElements[i].Children[k].Element.Name;
              this.MultyselectElements[i].Children[k].Visible=true;
            }
          }
        }
      }
      
    }

    HideAllElements()
    {
      if(this.MultyselectElements!=undefined && this.MultyselectElements!=null && this.MultyselectElements.length!=0)
      {
        for(let i=0; i<this.MultyselectElements.length; i++)
        {
          if(this.MultyselectElements[i].ElementType!=2)
          {
            this.MultyselectElements[i].TextToShow=this.MultyselectElements[i].Element.Name;
          }
          this.MultyselectElements[i].Visible=false;
          if(this.MultyselectElements[i].Children!=undefined && this.MultyselectElements[i].Children!=null && this.MultyselectElements[i].Children.length!=0)
          {
            for(let k=0; k<this.MultyselectElements[i].Children.length; k++)
            {
              this.MultyselectElements[i].Children[k].TextToShow=this.MultyselectElements[i].Children[k].Element.Name;
              this.MultyselectElements[i].Children[k].Visible=false;
            }
          }
        }
      }
    }
    

    Search()
    {
      if(this.Filter == undefined ||this.Filter == null ||  this.Filter=="")
      {
        this.ShowAllElements();
      }
      else
      {
        this.HideAllElements();

        let separator = new RegExp(/ *\s/, "gi");
        for (let i = 0; i < this.MultyselectElements.length; i++) {
          if (this.MultyselectElements[i].ElementType!=2) {
            let filters = this.Filter.split(separator);
            for (let f = 0; f < filters.length; f++) {
              if (filters[f] != "") {
                if (ExtendedHelper.ContainsNoCase(this.MultyselectElements[i].Element.Name, filters[f])) {
                  this.MultyselectElements[i].TextToShow = SearchSelectHelper.getSearchedText(this.Filter, this.MultyselectElements[i].Element.Name);
                  this.MultyselectElements[i].Visible = true;
                }
              }
            }
          } else {
            if (this.MultyselectElements[i].Children != null && this.MultyselectElements[i].Children.length > 0) {
              this.MultyselectElements[i].Children.forEach(element => {
                let filters = this.Filter.split(separator);
                for (let f = 0; f < filters.length; f++) {
                  if (filters[f] != "") {
                    if (ExtendedHelper.ContainsNoCase(element.Element.Name, filters[f])) {
                      element.TextToShow = SearchSelectHelper.getSearchedText(this.Filter, element.Element.Name);
                      element.Visible = true;
                    } else {
                      element.Visible = false;
                    }
                  }
                }
              });
              if (this.MultyselectElements[i].Children.find(c => c.Visible)) this.MultyselectElements[i].Visible = true;
            }
          }
  
  
        }

      }
    }
    
}

export class AutojobMultyselectInProjectElement {
  Id: number;
  TextToShow: string;
  Element: AutojobMultyselectInProjectElementValue = new AutojobMultyselectInProjectElementValue();
  ElementType: number = 0;
  Selected: boolean;
  Focus: boolean;
  Visible: boolean = true;
  IsHolder: boolean = false;
  Children: AutojobMultyselectInProjectElement[];
  IsSelectable: boolean = true;
  HasAccess: boolean = true;
  Additionals:Common[]=[];
}

export class AutojobMultyselectInProjectElementValue {
  Id: number;
  Name: string;
  ValueOuterType: number;
}
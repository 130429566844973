import { Client } from "./model.client";
import { Project } from "./model.project";
import { Common } from "./model.common";
import { ParentChecker } from "./model.parentChecker";
import { CommonRequest, CommonResponse } from "./model.http";
/**
 * Created by MosNeuro on 29.11.2016.
 */


export class JobLight {
    Id: number;
    JobId?: number;
    //ChangeTime: Date;
    CreateTime: Date;
    
    Message: string;
    MessageInput: string;

    ToDoDate: Date;
    NeedChecking: boolean;
    Status: number;
    Tags: Common[];
    Lost: boolean;
    Changeable: boolean;
    FinishedByAdmin: boolean;

    ModeToSend: number;
    MessageToSend: string = '';
    VisibleEndJob: boolean = false;
    JobEndingByAdmin: boolean = false;

    //Changer: Common = new Common();
    Creator: Common = new Common();
    Worker: Common = new Common();
    //Client: Common = new Common();
    Clients: Common[];
    Project: Common = new Common();

    ClientsExpanded: boolean = false;

    Additions: AdditionToJobOut[];
    CompletionDate: Date;
    AdminFinisher: string;

    static ExpandClients(job: JobLight) {
        job.ClientsExpanded = true;
    }


    static initialEdit(job: JobLight) {
        job.MessageInput = job.Message;
    }

    static convertNewLineToInput(job: JobLight) {
        let newLine = new RegExp('<br/>', "gi");
        job.MessageInput = job.MessageInput.replace(newLine, '\n');
    }

    static convertNewLineToShow(job: JobLight) {
        let newLine = new RegExp('\n', "gi");
        job.Message = job.Message.replace(newLine, '<br/>');
    }


    static removeAllHtmlTags(job: JobLight) {
        let tags = new RegExp(/<[^>]+>/, "gi");
        job.MessageInput = job.MessageInput.replace(tags, '');
        for (let i = 0; i < (job.Additions ? job.Additions.length : 0); i++) {

            job.Additions[i].Message = job.Additions[i].Message.replace(tags, '');
        }
    }


}

export class JobLocalStorage extends Common {
    jobStatus: number;
    constructor(id: number, name: string, status: number) {
        super(id, name);
        this.jobStatus = status;
    }
}

export class AdditionToJobOut {
    Message: string;
    Username: string;
    Date: Date;
    Title: string;
}

export class JobWithTomorrowCheck extends JobLight {
    ForTomorrow: boolean;
    ForToDay: boolean;
}

export class JobToSend extends ParentChecker {
    Message: string;
    ToDoDate: Date;
    NeedChecking: boolean;
    CreatorId: number;
    WorkerId: number;
    ClientId: number;
    ProjectId: number;
    Tags: Common[] = [];
}

export class JobRedact extends ParentChecker {
    Id: number;
    WorkerId: number;
    CreatorId: number;
    RedactingType: number;
    Message: string;
    ExistingMessage: string;
    NewStatus: number;
    ToDoDate: Date;
    NeedChecking: boolean;
    Tags: Common[];
}

export class FilterJob extends ParentChecker {
    ClientId: number;
    WorkerId: number;
    ProjectId: number;
    Type: number;
    Filter: string;
    ToTake: number;
    ToSkip: number;
    Tags: Common[] = [];
}
export class JobAsker extends CommonRequest {
    ClientId: number;
    WorkerId: number;
    ProjectId: number;
    GetActual: boolean = false;
    GetLost: boolean = false;
    Filter: string;
    ToTake: number;
    SkipFrom: Date;
    Tags: Common[] = [];
}

export class JobAskerOneOrUpper extends ParentChecker {
    JobId: number;
    ClientId: number;
    ProjectId: number;
    SkipFrom: Date;
    ToTake: number;
}

export class JobGiver extends CommonResponse {
    Jobs: JobLight[] = [];
}



export class JobCounterOld {
    Id: number;
    Actual: number;
    Lost: number;
}

export class JobCloseAsker extends CommonRequest {
    JobId: number;
    WorkerId: number
    constructor(userId: number, sessionKey: string, jobId: number, workerId: number) {
        super(userId, sessionKey);
        this.JobId = jobId;
        this.WorkerId = workerId;
    };
}

import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {ParentChecker} from "../models/model.parentChecker";
/**
 * Created by MosNeuro on 14.09.2017.
 */

import {Injectable} from '@angular/core'; import {ServerString} from "../config/serverstring";
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Login, LoginLangSetAsker} from '../models/model.login'



import {CommonRequest} from "../models/model.http";

@Injectable()
export class HttpServiceLanguage {
  route: string = "language/";

  constructor(private http: HttpClient, private param: ServerString) {
  }

  getPossibleLanguages(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'possible', body, {headers: headers, withCredentials: true}).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getCommon(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'common', body, {headers: headers, withCredentials: true}).pipe(
    //map((resp:Response)=>resp.json())
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getAddress(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'Address', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getAnalytics(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'analytics', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  getParameters(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'parameters', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getLogin(obj: LoginLangSetAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'login', body, {headers: headers, withCredentials: true}).pipe(
    //.map((resp: Response)=>resp.json())
      catchError((error: any) => {return observableThrowError(error);}));
  }

  getChat(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'chat', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  getAutojob(obj: CommonRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + this.route + 'AutoJob', body, {headers: headers, withCredentials: true}).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

}

import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    HostListener,
    OnInit,
    HostBinding
} from '@angular/core';
import { Subject } from 'rxjs';
import { SelectorComponent } from '../../selector.component';

@Component({
    selector: 'app-option',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './selector-option.component.html',
    styleUrls: ['./selector-option.component.scss']

    // template: `<ng-content (click)="select()"></ng-content>`
})
export class SelectorOptionComponent implements OnChanges, OnDestroy {

    @Input() value: any;
    // @Input() label: string;
    @Input() disabled: boolean = false;

    readonly stateChange$ = new Subject<{ value: any, disabled: boolean, label?: string }>();

    private _previousLabel: string;

    @HostListener('click', ['$event'])
    select() {
        // if (this.disabled) return;
        this.selector.selectItem(this.value, this.label)
    }

    @HostBinding('class.disabled') disabledClass: boolean = false;



    constructor(public elementRef: ElementRef<HTMLElement>,
        private selector: SelectorComponent) { }

    get label(): string {
        return (this.elementRef.nativeElement.textContent || '').trim();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled) {
            if (this.disabled) {
                this.disabledClass = true;
            } else {
                this.disabledClass = false;
            }
            this.stateChange$.next({
                value: this.value,
                disabled: this.disabled
            });
        }
        if (changes.value) {
            // this.checkSelectirng()
        }
    }




    ngOnInit(): void {
        setTimeout(() => {
            this.checkSelectirng()
        }, 10);
    }

    ngAfterViewInit(): void {
    }

    public checkSelectirng() {
        if (this.selector.model == this.value) this.select();
    }


    ngOnDestroy() {
        this.stateChange$.complete();
    }

}
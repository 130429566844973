/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import { LocalStorageService } from 'angular-2-local-storage';
import { ClientInProjectFilterWithUnion } from '../../models/model.ClientInProjectFilter';
import { ListElement } from '../../models/List/model.ListElement';
import { SearchSelectHelper } from '../../Helpers/SearchSelectHelper';
import { ClientLight } from '../../models/model.client';
import { ProjectLight } from '../../models/model.project';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TagHelper } from '../../Helpers/taghelper';
import { ElementWithVisible } from '../../models/model.elementWithVisible';
import * as _ from 'lodash';
import { ExtendedHelper } from '../../config/ExtendedHelper';
import { AutoJobAuthorListGiver, AutoJobExecutorListGiver, AutoJobFullContainer, AutoJobListResreshAsker, AutoJobTemplateEditAsker, AutoJobTemplateFullContainer, AutoJobTemplateInProject, AutoJobTemplateInSettings, MultiIdRequest } from '../../models/model.Autojob/model.AutojobTemplate';
import { LangSetAutojob, LangSetAutoJobGiver } from '../../models/model.autojobLanguage';
import { CommonRequest, CommonResponse } from '../../models/model.http';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../store/selectors/session.selectors';
import { HttpServiceLanguage } from '../../httpserv/httpserv.language';
import { AutojobMultyselectElementValue, AutojobMultyselectInProjectContainer, MultyselectElement } from '../../models/model.MultyselectContainer';
import { TagEntityTypeEnum } from '../../models/model.tag';
import { HttpServiceAutojob } from '../../httpserv/httpserv.autojob';
import { CommonWorkerHolder } from '../../models/model.workerAttached';
import { Common } from '../../models/model.common';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { HttpServiceProject } from '../../httpserv/httpserv.project';
import { ChangeSession, DeleteSession } from '../../store/actions/session.action';
import { SessionService } from '../../services/session.service';
import { Autojobs_LangSet, Common_LangSet, Jobs_LangSet } from '../../models/model.language';
import { LanguageService } from '../../services/language.service';

declare var require: any;


@Component({
  selector: 'autojobInProjectContainer',
  templateUrl: 'temp.autojobInProjectContainer.html',
  styleUrls: ['autojobInProjectContainer.scss',
    '../../../style/style.scss',
    '../../../style/font-awesome.min.scss',],

    providers: [HttpServiceLanguage, HttpServiceAutojob, HttpServiceProject],

})
export class AutojobInProjectContainerComponent implements OnInit {
  
  @Input() currentTemplate: AutoJobFullContainer;
  @Input() selectedStageId: number;
  @Input() projectId: number;
  @Output() ClearTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() DeleteTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateChangeAuthor: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateDeleteExecutor: EventEmitter<any> = new EventEmitter<any>();
  @Output() TemplateAddExecutor: EventEmitter<any> = new EventEmitter<any>();
  
 
  TagEntityTypeEnum: TagEntityTypeEnum = new TagEntityTypeEnum();
  langset: Common_LangSet;
  ln_sub: Subscription=null;
  autojobs_Langset: Autojobs_LangSet;
  aj_sub: Subscription=null;
  jobs_Langset: Jobs_LangSet;
  jb_sub: Subscription=null;
 
  sessionKey$: Subscription=null;
  userId$: Subscription=null;
  UserId: number;
  SessionKey: string;
  entityTypeJob: number = 0;
  isMultiselectsInit: boolean=false;

  authorMultiselectTitle: string="Поручитель";
  authorMultiselectPlaceholder: string="исполнитель";
  authorHolders: CommonWorkerHolder[]=[];
  authorRoles: Common[]=[];
  authorAdditionalValues: AutojobMultyselectElementValue[]=[];
  authorRolesOuterType: number=0;
  authorUsersOuterType: number=1;
  authorSelectedUsers: Common[]=[];
  authorSelectedRoles: Common[]=[];
  authorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];


  executorMultiselectTitle: string="Поручитель";
  executorMultiselectPlaceholder: string="исполнитель";
  executorHolders: CommonWorkerHolder[]=[];
  executorRoles: Common[]=[];
  executorAdditionalValues: AutojobMultyselectElementValue[]=[];
  executorRolesOuterType: number=0;
  executorUsersOuterType: number=1;
  executorSelectedUsers: Common[]=[];
  executorSelectedRoles: Common[]=[];
  executorSelectedAdditionalValues: AutojobMultyselectElementValue[]=[];

  refreshAuthor: Subject<boolean> = new Subject();

  refreshExecutor: Subject<boolean> = new Subject();
  refreshAuthorFull: Subject<boolean> = new Subject();

  refreshExecutorFull: Subject<boolean> = new Subject();
  isRefresh: boolean=false;

  
  @ViewChild('dp') mydp: AngularMyDatePickerDirective;

  private myDatePickerOptions: IAngularMyDpOptions = {
    dayLabels: { su: "Вс", mo: "Пн", tu: "Вт", we: "Ср", th: "Чт", fr: "Пт", sa: "Сб" },
    monthLabels: {
      1: "Янв",
      2: "Фев",
      3: "Март",
      4: "Апр",
      5: "Май",
      6: "Июнь",
      7: "Июль",
      8: "Авг",
      9: "Сент",
      10: "Окт",
      11: "Ноя",
      12: "Дек"
    },
    stylesData: {
      selector: 'dp',
      styles: `
          .dp .myDpSelector {
              top: 72px !important;
              left: 64px !important;
          }
      `
    },
    dateFormat: "dd.mm.yyyy",
    showSelectorArrow: true,
    todayTxt: "Сегодня",
    firstDayOfWeek: "mo",
    sunHighlight: true,
    closeSelectorOnDocumentClick: true,
  };




  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableUntilYesterday(): void {
    let copy = this.getCopyOfOptions();
    let d: Date = new Date();
    d.setDate(d.getDate() - 1);
    copy.disableUntil = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
    this.myDatePickerOptions = copy;
  }



  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private store$: Store,
    private httpServiceLanguage: HttpServiceLanguage,
    private httpServiceAutojob: HttpServiceAutojob,
    private httpServiceProject: HttpServiceProject,
    private sessionService: SessionService,
    
    private languageService: LanguageService
  ) { }




  ngOnInit() {
    this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
    this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
    this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
    this.aj_sub=this.languageService.autojobsLanguage$.subscribe(lang=>this.autojobs_Langset=lang);
    this.jb_sub=this.languageService.jobsLanguage$.subscribe(lang=>this.jobs_Langset=lang);
    this.onDisableUntilYesterday();
    this.CheckTextValid();
    

    //this.InitMultiselects();
    
  }


  unLogin(message: string = null) {
    if (!ExtendedHelper.IsNullOrEmpty(message)) {
      console.log(message);
    }
    this.sessionService.unLogin();
  }


  goToMain(event: any) {
    if (event == null || event.button == 0 && !event.ctrlKey) {
      if (event != null) event.preventDefault();
      let link = ['/main'];
      this.router.navigate(link);
    }
  }


  close() {

  }


  /*InitMultiselects()
  {
    this.isMultiselectsInit=false;

    this.authorMultiselectTitle="Поручитель";
    this.authorMultiselectPlaceholder="Выберите вариант";
    this.authorHolders=this.currentTemplate.AuthorHolders;
    this.authorRoles=this.currentTemplate.Roles;
    this.authorAdditionalValues=[];
    let adval = new AnalyticMultyselectElementValue();
    adval.Id=1;
    adval.ValueOuterType=1;
    adval.Name="Пользователь изменяющий этап";
    this.authorAdditionalValues.push(_.cloneDeep(adval));
    adval.Id=2;
    adval.ValueOuterType=2;
    adval.Name="Пользователь создавший проект";
    this.authorAdditionalValues.push(_.cloneDeep(adval));
    this.authorRolesOuterType=3;
    this.authorUsersOuterType=4;
    this.authorSelectedUsers = [];
    this.authorSelectedRoles=[];
    this.authorSelectedAdditionalValues=[];

    if(this.currentTemplate.Template.Container.AuthorType==1)
    {
      this.authorSelectedAdditionalValues.push(this.authorAdditionalValues[0]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==2)
    {
      this.authorSelectedAdditionalValues.push(this.authorAdditionalValues[1]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==3)
    {
      let indR = this.currentTemplate.Template.Container.Roles.findIndex(c=>c.Id==this.currentTemplate.Template.Container.AuthorId);
      this.authorSelectedRoles.push(this.currentTemplate.Template.Container.Roles[indR]);
    }
    if(this.currentTemplate.Template.Container.AuthorType==4)
    {
      

      for(let i=0; i<this.currentTemplate.Template.Container.Holders.length; i++)
      {
        if(this.currentTemplate.Template.Container.Holders[i].IsWorker && this.currentTemplate.Template.Container.Holders[i].Worker.Id==this.currentTemplate.Template.Container.AuthorId)
        {
          this.authorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Worker);
        }
        if(!this.currentTemplate.Template.Container.Holders[i].IsWorker)
        {
          for(let j=0; j<this.currentTemplate.Template.Container.Holders[i].Departament.Workers.length; j++)
          {
            if(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j].Id==this.currentTemplate.Template.Container.AuthorId)
            {
              this.authorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j]);
            }
          }
        }
      }
    }






    this.executorMultiselectTitle="Исполнитель";
    this.executorMultiselectPlaceholder="Выберите вариант";
    this.executorHolders=this.currentTemplate.Template.Container.Holders;
    this.executorRoles=this.currentTemplate.Template.Container.Roles;
    this.executorAdditionalValues=[];
    
    this.executorRolesOuterType=1;
    this.executorUsersOuterType=2;
    this.executorSelectedUsers = [];
    this.executorSelectedRoles=[];
    this.executorSelectedAdditionalValues=[];

    
    if(this.currentTemplate.Template.Container.ExecutorRoles!=undefined && this.currentTemplate.Template.Container.ExecutorRoles!=null && this.currentTemplate.Template.Container.ExecutorRoles.length>0)
    {
      for(let j=0; j<this.currentTemplate.Template.Container.ExecutorRoles.length; j++)
      {
        let indR = this.currentTemplate.Template.Container.Roles.findIndex(c=>c.Id==this.currentTemplate.Template.Container.ExecutorRoles[j]);
        if(indR>-1)
        {
          this.executorSelectedRoles.push(this.currentTemplate.Template.Container.Roles[indR]);
        }
        
      }
      
    }




    if(this.currentTemplate.Template.Container.ExecutorWorkers!=undefined && this.currentTemplate.Template.Container.ExecutorWorkers!=null && this.currentTemplate.Template.Container.ExecutorWorkers.length>0)
    {
      
      for(let k=0; k<this.currentTemplate.Template.Container.ExecutorWorkers.length; k++)
      {
        for(let i=0; i<this.currentTemplate.Template.Container.Holders.length; i++)
        {
          if(this.currentTemplate.Template.Container.Holders[i].IsWorker && this.currentTemplate.Template.Container.Holders[i].Worker.Id==this.currentTemplate.Template.Container.ExecutorWorkers[k])
          {
            this.executorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Worker);
          }
          if(!this.currentTemplate.Template.Container.Holders[i].IsWorker)
          {
            for(let j=0; j<this.currentTemplate.Template.Container.Holders[i].Departament.Workers.length; j++)
            {
              if(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j].Id==this.currentTemplate.Template.Container.ExecutorWorkers[k])
              {
                this.executorSelectedUsers.push(this.currentTemplate.Template.Container.Holders[i].Departament.Workers[j]);
              }
            }
          }
        }
      }

      
    }






    this.isMultiselectsInit=true;
  }*/


  
    

    ClearCurrentTemplate()
    {
      this.isRefresh=true;
      this.currentTemplate.Template=_.cloneDeep(this.currentTemplate.TemplateBackup);
      setTimeout(()=>{this.refreshAuthorFull.next(true);
        this.refreshExecutorFull.next(true);
        setTimeout(()=>{this.isRefresh=false;},100);
      },100);

     
    }

    
    AutojobChangeAuthor(Select: AutojobMultyselectInProjectContainer)
    {
      if(!Select.IsValid)
      {
        this.currentTemplate.InvalidAuthor=true;
        this.currentTemplate.NeedRefreshAuthor = true;
        this.currentTemplate.InvalidAuthorText="";
        this.currentTemplate.Template.AuthorIsValid =false;
        if(Select.SelectedElements.length==0)
        {
          this.currentTemplate.InvalidAuthorText=this.autojobs_Langset.NoAuthor;
        }
        else
        {
          let noaccess=false;
          let noroles=false;
          for(let i=0; i<Select.SelectedElements.length; i++)
          {
            if(Select.SelectedElements[i].ElementType==1 && !Select.SelectedElements[i].HasAccess)
            {
              noaccess=true;
            }
            if(Select.SelectedElements[i].ElementType==0 && (Select.SelectedElements[i].Additionals==undefined ||Select.SelectedElements[i].Additionals==null || Select.SelectedElements[i].Additionals.length==0))
            {
              noroles=true;
            }
          }
          if(noaccess)
          {
            this.currentTemplate.InvalidAuthorText=this.autojobs_Langset.SomeWorkersHasNoAccess;
          }
  
          if(noroles)
          {
            this.currentTemplate.InvalidAuthorText= this.currentTemplate.InvalidAuthorText + this.autojobs_Langset.NoWorkersInRoleInProject;
          }
        }
        
        
      }
      else
      {
        this.currentTemplate.InvalidAuthor=false;
        this.currentTemplate.NeedRefreshAuthor = false;
        this.currentTemplate.InvalidAuthorText="";
        this.currentTemplate.Template.AuthorIsValid =true;
        this.currentTemplate.SelectedAuthor=[];
        this.currentTemplate.SelectedAuthor=Select.SelectedElements;
      }
    }

    AutojobChangeExecutor(Select: AutojobMultyselectInProjectContainer)
    {
      if(!Select.IsValid)
      {
        this.currentTemplate.InvalidExecutor=true;
        this.currentTemplate.NeedRefreshExecutor = true;
        this.currentTemplate.InvalidExecutorText="";
        this.currentTemplate.Template.ExecutorIsValid =false;
        if(Select.SelectedElements.length==0)
        {
          this.currentTemplate.InvalidExecutorText=this.autojobs_Langset.NoExecutor;
        }
        else
        {
          let noaccess=false;
          let noroles=false;
          let rolesCounter=0;
          let rolesInvalidCounter=0;
          for(let i=0; i<Select.SelectedElements.length; i++)
          {
            if(Select.SelectedElements[i].ElementType==1 && !Select.SelectedElements[i].HasAccess)
            {
              noaccess=true;
            }
            if(Select.SelectedElements[i].ElementType==0)
            {
              rolesCounter++;
            }
            if(Select.SelectedElements[i].ElementType==0 && (Select.SelectedElements[i].Additionals==undefined ||Select.SelectedElements[i].Additionals==null || Select.SelectedElements[i].Additionals.length==0))
            {
              rolesInvalidCounter++;
            }
          }
          if(rolesCounter>0 && rolesCounter==rolesInvalidCounter)
          {
            noroles=true;
          }
          if(noaccess)
          {
            this.currentTemplate.InvalidExecutorText=this.autojobs_Langset.SomeWorkersHasNoAccess;
          }
  
          if(noroles)
          {
            this.currentTemplate.InvalidExecutorText= this.currentTemplate.InvalidExecutorText + this.autojobs_Langset.NoWorkersInRoleInProject;
          }
        }
        
        
      }
      else
      {
        this.currentTemplate.InvalidExecutor=false;
        this.currentTemplate.NeedRefreshExecutor = false;
        this.currentTemplate.InvalidExecutorText="";
        this.currentTemplate.Template.ExecutorIsValid =true;
        this.currentTemplate.SelectedExecutors=[];
        this.currentTemplate.SelectedExecutors=Select.SelectedElements;
      }
    }


    CheckTextValid()
    {
      if(this.currentTemplate.Template.Text==undefined || this.currentTemplate.Template.Text==null|| this.currentTemplate.Template.Text=="" ||this.currentTemplate.Template.Text.trim()=="")
      {
        this.currentTemplate.InvalidText=true;

      }
      else
      {
        this.currentTemplate.InvalidText=false;
      }
    }


    RefreshAuthorList()
    {
      let checker = new AutoJobListResreshAsker();
    checker.ChangerId = this.UserId;
    checker.SessionKey = this.SessionKey;
    checker.Id = this.projectId;
    checker.StageId = this.selectedStageId;
    checker.TemplateId = this.currentTemplate.Template.Id;
    this.httpServiceProject.RefreshAuthorList(checker).subscribe((data: AutoJobAuthorListGiver) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 201) {
        this.goToMain(null);
        return;
      }
      if (data.Code == 202) {
        alert(data.Message);
        this.DeleteTemplate.emit(true);
        return;
      }

      if (data.Code == 300 || data.Code == 301) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      this.currentTemplate.Template.AuthorHolders=data.AuthorHolders;
      this.currentTemplate.Template.AuthorIsValid = data.AuthorIsValid;
      this.currentTemplate.Template.AuthorLocked = data.AuthorLocked;
      this.currentTemplate.NeedRefreshAuthor = false;
      this.currentTemplate.InvalidAuthor = false
      if (!this.currentTemplate.Template.AuthorIsValid) {
        this.currentTemplate.InvalidAuthor = true;
        this.currentTemplate.NeedRefreshAuthor = true;
      }
      this.currentTemplate.TemplateBackup = new AutoJobTemplateInProject();
      this.currentTemplate.TemplateBackup=_.cloneDeep(this.currentTemplate.Template);
      setTimeout(()=>{this.refreshAuthor.next(true)},100);
    });






    

      
    }


    RefreshExecutorList()
    {

      let checker = new AutoJobListResreshAsker();
    checker.ChangerId = this.UserId;
    checker.SessionKey = this.SessionKey;
    checker.Id = this.projectId;
    checker.StageId = this.selectedStageId;
    checker.TemplateId = this.currentTemplate.Template.Id;
    this.httpServiceProject.RefreshExecutorList(checker).subscribe((data: AutoJobExecutorListGiver) => {
      if (data.Code == 100) {
        this.unLogin(data.Message);
        return;
      }
      if (data.Code == 201) {
        this.goToMain(null);
        return;
      }
      
      if (data.Code == 202) {
        alert(data.Message);
        this.DeleteTemplate.emit(true);
        return;
      }
      if (data.Code == 300 || data.Code == 301) {
        console.log(data.Message);
        return;
      }
      if (data.Code == 500) { console.error(data.Message); return; }

      
      this.currentTemplate.Template.ExecutorLocked = data.ExecutorLocked;
      this.currentTemplate.Template.ExectutorHolders=data.ExectutorHolders;
      this.currentTemplate.NeedRefreshExecutor = false;
      this.currentTemplate.InvalidExecutor = false
      if (!this.currentTemplate.Template.ExecutorIsValid) {
        this.currentTemplate.InvalidExecutor = true;
        this.currentTemplate.NeedRefreshExecutor = true;
      }


      this.currentTemplate.TemplateBackup = new AutoJobTemplateInProject();
      this.currentTemplate.TemplateBackup=_.cloneDeep(this.currentTemplate.Template);
      setTimeout(()=>{this.refreshExecutor.next(true)},100);
    });


      
    }
  
}

/**
 * Created by MosNeuro on 18.01.2022.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostListener,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { ExtendedHelper } from '../../../config/ExtendedHelper';
import * as _ from 'lodash';
import {  Subject, Subscription,  fromEvent as observableFromEvent } from 'rxjs';
import {  Common_LangSet} from '../../../models/model.language';
import { LanguageService } from '../../../services/language.service';
import { ElementWithVisible } from '../../../models/model.elementWithVisible';
import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import { skip } from 'rxjs/operators';
import { Common } from '../../../models/model.common';
import { LangSetAutoJobGiver } from '../../../models/model.autojobLanguage';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { HttpServiceParameters } from '../../../httpserv/httpserv.parameters';
import { SingleIdRequest } from '../../../models/model.http';
import { SessionService } from '../../../services/session.service';
import { Router } from '@angular/router';
import { ParameterHintGiver, ParameterHintNoteWithVisible, ParameterHintView, ParameterValue } from '../../../models/model.parameters';
import { createElementCssSelector } from '@angular/compiler';
import { ParameterInstanceContainerFilled, ParameterInstanceCreateContainerFilled, ParameterInstanceFilledHintAsker } from '../../../models/model.storage';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { PieGridComponent } from '@swimlane/ngx-charts';
import { DateExtended } from '../../../models/model.date';
import { DateFieldComponent } from '../../../shared/components/date-field/date-field.component';
import { isNumber } from 'lodash';


declare var require: any;


@Component({
    selector: 'valueSetDropdown',
    templateUrl: 'temp.valueSetDropdown.html',
    styleUrls: ['valueSetDropdown.scss',
        '../../../../style/style.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [ExtendedHelper]

})
export class ValueSetDropdownComponent implements OnInit, OnChanges {
    
   
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    @Input() addingType: number=1; //0-закрытое 1-с подтверждением 2-открытое
    @Input() values: any[]=[];
    @Input() PossibleValueSetsIds: number[]=[];
    @Input() SetId: number=1;
    @Input() valuesType: number=0; //0 - строка, 1 - целое, 2 - дробное, 3 - дата


    @Input() borderOnHover:boolean=false;

    @Input() nameKey: string="Name";
    @Input() idKey: string="Id";
    valuesList: any[]=[];
    valuesListBack: any[]=[];

    @Input() id:number=-1;
    @Input() selectedValues: any[]=[];
    //@Output() valuesChange:  EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() selectedValuesChange:  EventEmitter<any[]> = new EventEmitter<any[]>();  
    @Output() changeSize:  EventEmitter<number> = new EventEmitter<number>(); 

    @Output() onSelect:  EventEmitter<boolean> = new EventEmitter<boolean>();  
    @Output() addNewValue:  EventEmitter<string> = new EventEmitter<string>(); 
    @ViewChild('optionsList', { static: false }) optionsListElem: ElementRef;
    @ViewChild('searchContainer', { static: false }) searchContainerElem: ElementRef;
    @ViewChild('selectedList', { static: false }) selectedListElem: ElementRef;
    @ViewChild('vals', { static: false }) valsElem: ElementRef;
    @ViewChild('search', { static: false }) searchElem: ElementRef;
    @ViewChild('valuesScrollContainer', { static: false }) valuesScrollContainerElem: ElementRef;

    @ViewChild('defaultHintsScrollContainer', { static: false }) defaultHintsScrollContainerElem: ElementRef;
    @ViewChild('hintsScrollContainer', { static: false }) hintsScrollContainerElem: ElementRef;


    @ViewChild('dayInput', { static: false }) dayInputElem: ElementRef;
    @ViewChild('monthInput', { static: false }) monthInputElem: ElementRef;
    @ViewChild('yearInput', { static: false }) yearInputElem: ElementRef;
    @ViewChild('dateInput', { static: false }) dateInputElem: ElementRef;

    //@ViewChildren(DateFieldComponent)dates:QueryList<ElementRef>;


    //@Input() addBtn:boolean=false;
    @Input() clearBtn:boolean=true;
    @Input() defaultText:string="Выбрать";
    isActive: boolean=false;
    isHidden: boolean=false;

    topList:number= 64;
    topSearch:number=32;
    bottomList:number=null;
    bottomSearch:number=null;
    listWidth: number=500;
    leftList: number=0;
    rightList: number=null;
    filterInput = new FormControl()
    filterSub: Subscription = null;
    filterValue:string="";
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    UserId: number;
    SessionKey: string;
    @Input() haveHints: boolean=false;

    @Output() selectedHint:  EventEmitter<any[]> = new EventEmitter<any[]>(); 

    openValueChanged: boolean=false;
    hoveredElement= {Type:0, Id:0, ParentId:0, RootId:0};

    notesVisible: boolean=false;


    @Input() selectWithoutAdding: boolean=false;
    @Input() setLocationType: number=0;

    @Input() ContainerForSearch: ParameterInstanceCreateContainerFilled=null;

    @Input()  searchIndex: number=0;

    @Input()  outerId: number=0;
    @Output() SearchFieldText:  EventEmitter<string> = new EventEmitter<string>(); 

    @Input()  canBeEmpty: boolean=true;
    searchedText: string="";

    defWidth: string="fill-available";
    hintWidth: string="fill-available";
    filterValueDate:Date=null;

    filterValueDay: string=null;
    filterValueMonth: string=null;
    filterValueYear: string=null;


    lastDateField: number=-1;


    selectInputDate: Subject<number> = new Subject();
    valueChanged: Subject<any[]> = new Subject();
    blurInputDateEv: Subject<boolean> = new Subject();
  constructor(
    private languageService: LanguageService,
    private sessionService: SessionService,
    private httpServiceParameters: HttpServiceParameters,
    private router: Router
              ) { }

            
              @HostListener('document:click', ['$event'])
              public onClickActive(e: Event): void {

                if (this.isActive)
                {
                  

                  if(!(this.optionsListElem.nativeElement.contains(e.target) || this.searchContainerElem.nativeElement.contains(e.target)))
                  {
                    
                    setTimeout(()=>{this.closeForm();
                      this.filterValueDay=null;
                      this.filterValueMonth=null;
                      this.filterValueYear=null;
                    },500)
                    //this.closeForm()
                    //this.isActive=false;
                     // this.isHidden=false;
                     // this.clear();
                  }
                  
                  
                }
              }

              

             // @HostListener('document:keyup.escape', ['$event'])
              //public onEscClose(e: KeyboardEvent): void {
                onEscClose(e: KeyboardEvent): void {
                  if(e.keyCode==27)
                  {
                    
                    e.stopPropagation();
                    if (this.isActive)
                    {
                      if(this.haveHints)
                      {
                        if(this.valuesList!=null && this.valuesList.length>0 && this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                        {
                          let visibleNoteInd=-1;
                          let visibleNoteValInd=-1;
                          let i=0;
                          
                          this.valuesList[0].hints.forEach(c=>{
                            if(c.Notes!=null && c.Notes.length>0)
                            {
                              let ni=c.Notes.findIndex(n=>n!=null && n.Visible==true);
                              
                              if(ni>-1)
                              {visibleNoteValInd=i;
                                visibleNoteInd=ni;}
                            }
                            i++;
                          });
    
                          
                          if(visibleNoteInd>-1 && visibleNoteValInd>-1)
                          {
                            this.closeAdditional(this.valuesList[0].hints[visibleNoteValInd].Notes[visibleNoteInd], this.valuesList[0].hints[visibleNoteValInd].HintedValueId);
                            //this.valuesList[0].hints[visibleNoteValInd].Notes[visibleNoteInd].Visible=false;
                            
                          }
                          else
                          {
                            
                            this.closeForm()
                          }
                        }
                        else
                        {
                          
                          this.closeForm()
                        }
                      }
    
                      else
                      {
                        
                        this.closeForm()
                      }
    
                      
                      
                          //this.isActive=false;
                          //this.isHidden=false;
                         // this.clear();
    
                    }
                  }
                
              }

              
              ngOnDestroy() {
              
              
              }




              changeSearchedText(ev:Event)
              {
               //ev.stopPropagation()
               setTimeout(()=>{this.selectedValues=[];
                setTimeout(()=>{this.onChangeSize();},100)
                this.closeForm();},500)
                
               
              }




              closeAdditional(note: any, valId: number)
              {
                
                note.Visible=false;
                this.hoveredElement.Type=3;
                this.hoveredElement.Id=-1;
                this.hoveredElement.ParentId=note.ValueSetId;
                this.hoveredElement.RootId=valId;
                let se= <HTMLElement>this.searchContainerElem.nativeElement;
                                se.childNodes.forEach(n=>{
                                  if(n.nodeName=="INPUT")
                                  {
                                    let el=<HTMLElement>n;
                                    
                                   
                                    el.focus();
                                  }
                                })
              }
              
              ngOnChanges(changes: SimpleChanges): void {
                
                if(!this.haveHints)
                {
                  for (let prop in changes) {
                    if(prop=="values")
                    {
                      let chng = changes[prop];
  
                      if (!chng.isFirstChange()) 
                      {
                       // this.valuesList=_.cloneDeep(this.values);
                      }
                    }

                    if(prop=="selectedValues")
                    {
                      let chng = changes[prop];
  
                      if (!chng.isFirstChange()) 
                      {
                        
                        this.onChangeSize();
                      }
                      
                    }
                    
                    
                  }
                }

                if(this.haveHints)
                {
                  for (let prop in changes) {
                    if(prop=="selectedValues")
                    {
                      let chng = changes[prop];
                      this.onChangeSize();
                      if (!chng.isFirstChange()) 
                      {
                        
                       // this.onChangeSize();
                      }
                      
                    }
                    
                  }
                }
                
              }



              ngOnInit() 
              {
                
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=> {
                  this.langset=lang;
                  this.defaultText === 'Выбрать' && (this.defaultText = this.langset.Pick);
                });
               // this.valuesList=_.cloneDeep(this.valuesListBack);
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);
        
                this.filterSub=this.filterInput.valueChanges.pipe(skip(1)).debounceTime(500)
                .subscribe(newValue => {if (!this.openValueChanged)
                  {this.search();}
                else
                {
                  this.openValueChanged=false;
                }
                });
                

              }


              unLogin(message: string = "") {
                if (!ExtendedHelper.IsNullOrEmpty(message)) { console.log(message); }
                this.sessionService.unLogin();
            }


            goToMain(event: any) {
              if (event == null || event.button == 0 && !event.ctrlKey) {
                if (event != null) event.preventDefault();
                let link = ['/main'];
                this.router.navigate(link);
              }
            }



              checkButton(event: KeyboardEvent)
              {   
                  
                  if(event.keyCode==27)
                  {
                      this.clear();
                  }
                  if(this.valuesType==1 &&(event.key=="." || event.key==","))
                  { 
                    event.preventDefault();
                    return;
                  }
                  if((this.valuesType==1 || this.valuesType==2) &&(event.key=="+" || event.key=="-") && this.filterValue!=null && this.filterValue.toString().length>0)
                  {
                    event.preventDefault();
                    return;
                  }
                  if(event.keyCode==13 && this.addingType>0 && this.hoveredElement.Type==0 && !this.selectWithoutAdding)
                  {

                   
                    if(this.valuesType!=3)
                    
                    {
                      if(this.filterValue!=null &&  this.filterValue.toString().trim()=="")
                    {
                      this.filterValue="";
                      this.closeForm();
                      
                    }
                    else
                    {
                      this.addNew();
                    }
                  }

                    if(this.valuesType==3)
                    {



                      let focused=<HTMLElement>document.activeElement;
                      focused.blur();
                      this.blurInputDateEv.next(true);
                      setTimeout(()=>{
                        if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                        {
                          this.closeForm();
                          
                          
                        }
                        else
                        {
                          
                          this.addNew();
                        }
                      },500)




                     
                    }
                      
                  }

                 if(event.keyCode==13 && this.hoveredElement.Type==0 && this.selectWithoutAdding)
                  {

                    if(this.valuesType!=3)
                    {
                    if(this.filterValue!=null  && this.filterValue.toString().trim()=="")
                    {
                      this.filterValue="";
                      this.closeForm();
                    }
                    else
                    {
                      this.changeSearchedText(null);
                      event.stopPropagation()
                    }
                  }

                  if(this.valuesType==3)
                    {



                      let focused=<HTMLElement>document.activeElement;
                      focused.blur();
                      this.blurInputDateEv.next(true);
                      setTimeout(()=>{
                        if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                        {
                          this.closeForm();
                          
                          
                        }
                        else
                        {
                          
                          this.changeSearchedText(null);
                    event.stopPropagation()
                        }
                      },500)


                  
                }
                    
                  }

                  if(event.keyCode==13 && this.hoveredElement.Type==1)
                  {

                      let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id);
                      if(valInd>-1)
                      {
                        this.SelectValWithHinted(this.valuesList[0].hints[valInd]);
                      }
                  }


                  if(event.keyCode==13 &&  this.hoveredElement.Type==2)
                  {

                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      if(noteInd>-1)
                      {
                        let hintInd=this.valuesList[0].hints[valInd].Notes[noteInd].Hints.findIndex(c=>c.Id==this.hoveredElement.Id);
                        if(hintInd>-1)
                        {
                          this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected=!this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected;
                          this.SelectValHint(this.valuesList[0].hints[valInd], this.valuesList[0].hints[valInd].Notes[noteInd], this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd] );
                        }
                      }
                      
                    }
                  }


                  if(event.keyCode==13 && this.hoveredElement.Type==3)
                  {

                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      if(noteInd>-1)
                      {
                        
                          this.ShowNoteHints(this.valuesList[0].hints[valInd].Notes[noteInd], this.valuesList[0].hints[valInd].HintedValueId);
                        
                      }
                      
                    }
                  }


                  if(event.keyCode==13 &&  this.hoveredElement.Type==4)
                  {

                    

                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      if(noteInd>-1)
                      {
                        let hintInd=this.valuesList[0].hints[valInd].Notes[noteInd].Hints.findIndex(c=>c.Id==this.hoveredElement.Id);
                        if(hintInd>-1)
                        {
                          this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected=!this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected;
                          this.SelectValHint(this.valuesList[0].hints[valInd], this.valuesList[0].hints[valInd].Notes[noteInd], this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd] );
                        }
                      }
                      
                    }
                  }


                  if(event.keyCode==13 &&  this.hoveredElement.Type==5)
                  {

                      if(this.hoveredElement.Id==-1)
                      {
                        this.skipHintedValue();
                      }
                      else
                      {
                        let valInd=this.valuesList[0].defaultHints.findIndex(c=>c.Id==this.hoveredElement.Id);
                        if(valInd>-1)
                        {
                          this.selectValueFromHint(this.valuesList[0].defaultHints[valInd]);
                        }
                      }
                  }

                  if(event.keyCode==13 &&  this.hoveredElement.Type==6)
                  {

                      if(this.hoveredElement.Id==-1)
                      {
                        this.skipValue();
                      }
                      else
                      {
                        let valInd=this.valuesList.findIndex(c=>c[this.idKey]==this.hoveredElement.Id);
                        if(valInd>-1)
                        {
                          this.selectValue(this.valuesList[valInd]);
                        }
                      }
                  }



                  if(event.keyCode==37) {
                    if(this.hoveredElement.Type!=0)
                    {event.preventDefault();}
                    //left
                    if(this.haveHints)
                      {
                        this.pressLeft();
                      }
                    }
                    if(event.keyCode==38) {
                      //up
                      event.preventDefault();
                      if(this.haveHints)
                      {
                        this.pressUp();
                      }
                      else
                      {
                        this.pressUpWithoutHints();
                      }
                    }
                    if(event.keyCode==39)
                    {
                      if(this.hoveredElement.Type!=0)
                      {event.preventDefault();}
                      //right
                      if(this.haveHints)
                      {
                        this.pressRight();
                      }
                    }
                    if(event.keyCode==40) {
                      //down
                      event.preventDefault();
                      if(this.haveHints)
                      {
                        this.pressDown();
                      }
                      else
                      {
                        this.pressDownWithoutHints();
                      }
                    }
  
              }


              pressUpWithoutHints()
              {
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
      
                    if( !this.isActive &&this.isHidden)
                    {
                      this.showList();
                    }

                    else if( this.isActive && !this.isHidden)
                    {
                      if(this.valuesList!=undefined && this.valuesList!=null && this.valuesList.length>0)
                      {
                        this.hoveredElement.Type=6;
                        this.hoveredElement.Id=this.valuesList[0][this.idKey];
                        this.scrollToHintListElement("withouthinted_val_"+this.hoveredElement.Id, this.valuesScrollContainerElem);
                      }
                    }

                    break;
                  }
                  case 6: 
                  {
      
                    if(this.hoveredElement.Id>-1)
                    {

                    
                      let ind= this.valuesList.findIndex(c=>c[this.idKey]==this.hoveredElement.Id)
                      if(ind>-1)
                      {
                        if(ind>0)
                        {
                          this.hoveredElement.Id=this.valuesList[ind-1][this.idKey];
                        }
                        else if(ind==0)
                        {
                        this.hoveredElement.Id=-1;
                        }
                      }
                    }
                    else if(this.hoveredElement.Id==-1)
                    {
                      this.hoveredElement.Type=0;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                    }
                    
                    this.scrollToHintListElement("withouthinted_val_"+this.hoveredElement.Id, this.valuesScrollContainerElem);
                    
                    break;
                  }
                  
                }
                
              }

              pressDownWithoutHints()
              {
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
      
                    if( !this.isActive &&this.isHidden)
                    {
                      this.showList();
                    }

                    else if( this.isActive && !this.isHidden)
                    {
                      if(this.valuesList!=undefined && this.valuesList!=null && this.valuesList.length>0)
                      {
                        this.hoveredElement.Type=6;
                        this.hoveredElement.Id=this.valuesList[0][this.idKey];
                        this.scrollToHintListElement("withouthinted_val_"+this.hoveredElement.Id, this.valuesScrollContainerElem);
                      }
                    }

                    break;
                  }
                  case 6: 
                  {
                    if(this.hoveredElement.Id>-1)
                    {
                      let ind= this.valuesList.findIndex(c=>c[this.idKey]==this.hoveredElement.Id)
                      if(ind>-1)
                      {
                        if(ind<this.valuesList.length-1)
                        {
                          this.hoveredElement.Id=this.valuesList[ind+1][this.idKey];
                        }
                      }
                    }
                    else if(this.hoveredElement.Id==-1 && this.valuesList!=undefined && this.valuesList!=null && this.valuesList.length>0)
                    {
                      this.hoveredElement.Type=6;
                      this.hoveredElement.Id=this.valuesList[0][this.idKey];
                    }

                    this.scrollToHintListElement("withouthinted_val_"+this.hoveredElement.Id, this.valuesScrollContainerElem);
                    
                    break;
                  }
                  
                }
                
              }





              pressUp()
              {
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
      
                    if( !this.isActive &&this.isHidden)
                    {
                      this.showList();
                    }

                    else if( this.isActive && !this.isHidden)
                    {
                      if(this.valuesList[0].hints!=undefined && this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        this.hoveredElement.Type=1;
                        this.hoveredElement.Id=this.valuesList[0].hints[0].HintedValueId;
                        this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                      else if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                      {
                       
                        this.hoveredElement.Type=5;
                        this.hoveredElement.RootId=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                        
                      }
                    }

                    break;
                  }
                  case 1: 
                  {
      
                    let ind= this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id)
                    if(ind>-1)
                    {
                      if(ind>0)
                      {
                        this.hoveredElement.Id=this.valuesList[0].hints[ind-1].HintedValueId;
                      }
                      else if(ind==0)
                      {
                        this.hoveredElement.Type=0;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }

                    this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                    
                    break;
                  }
                  case 2: 
                  {
      
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1 && valInd>0)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      let newInd=-1;
                      let i=valInd-1; 
                      while(newInd==-1 && i>=0)
                      {
                        if(this.valuesList[0].hints[i].Notes!=null && this.valuesList[0].hints[i].Notes[noteInd]!=null)
                        {
                          newInd=i;
                        }
                        i--;
                      }
                      if(newInd!=-1)
                      {
                        this.hoveredElement.Type=2;
                        this.hoveredElement.Id=this.valuesList[0].hints[newInd].Notes[noteInd].Hints[0].Id;
                        this.hoveredElement.RootId=this.valuesList[0].hints[newInd].HintedValueId;
                        this.hoveredElement.ParentId=this.valuesList[0].hints[newInd].Notes[noteInd].ValueSetId;
                        this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                    }
                    else if(valInd==0)
                      {
                        this.hoveredElement.Type=0;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    break;
                  }
                  case 3: 
                  {
                    
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1 && valInd>0)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      let newInd=-1;
                      let i=valInd-1; 
                      while(newInd==-1 && i>=0)
                      {
                        
                        if(this.valuesList[0].hints[i].Notes!=null && this.valuesList[0].hints[i].Notes[noteInd]!=null && this.valuesList[0].hints[i].Notes[noteInd].Hints!=null &&  this.valuesList[0].hints[i].Notes[noteInd].Hints.length>1)
                        {
                          newInd=i;
                        }
                        
                        i--;
                      }
                      if(newInd!=-1)
                      {
                        
                        this.hoveredElement.Type=3;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.RootId=this.valuesList[0].hints[newInd].HintedValueId;
                        this.hoveredElement.ParentId=this.valuesList[0].hints[newInd].Notes[noteInd].ValueSetId;
                        this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                    }
                    else if(valInd==0)
                      {
                        this.hoveredElement.Type=0;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    break;
                  }
                  case 4: 
                  {
                    
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      if(this.hoveredElement.Id==-1)
                      {
                        this.hoveredElement.Type=4;
                        this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                      }
                      else
                      {
                        let hintInd=this.valuesList[0].hints[valInd].Notes[noteInd].Hints.findIndex(c=>c.Id==this.hoveredElement.Id)
                        if(hintInd>-1 && hintInd>0)
                        {
                          this.hoveredElement.Type=4;
                          this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd-1].Id;
                        }
                        else if(hintInd==0)
                          {
                            this.hoveredElement.Type=4;
                            this.hoveredElement.Id=-1;
                          }
                        
                      }
                    }

                    let form= document.getElementsByClassName("additional-note");
                    if(form!=null)
                    {
                      this.scrollToHintListElement("note_hints_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id,  null, form[0]);
                        
                    }
                    
                    break;
                  }

                  case 5: 
                  {
                    if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                    {
                      
                      if(this.hoveredElement.Id==-1)
                      {
                        this.hoveredElement.Type=0;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                      else
                      {
                        let ind= this.valuesList[0].defaultHints.findIndex(c=>c[this.idKey]==this.hoveredElement.Id)
                        if(ind>-1)
                        {
                          if(ind>0)
                          {
                            this.hoveredElement.Id=this.valuesList[0].defaultHints[ind-1][this.idKey];
                          }
                          else if(ind==0)
                          {
                            
                          this.hoveredElement.Id=-1;
                          }
                        }
                        

                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                        
                      }
                      
                    }
                    
                    break;
                  }
                }
                
              }



              pressLeft()
              {
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
                    break;
                  }
                  case 1: 
                  {
                    
                    break;
                  }
                  case 2: 
                  {
                    
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      
                        let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                        {
                          if(noteInd==0)
                          {
                            this.hoveredElement.Type=1;
                            this.hoveredElement.Id=this.valuesList[0].hints[valInd].HintedValueId;
                            this.hoveredElement.RootId=-1;
                            this.hoveredElement.ParentId=-1;
                            this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else if(noteInd>0)
                          {
                            noteInd=noteInd-1;
                            if(this.valuesList[0].hints[valInd].Notes[noteInd]!=null)
                            {
                              if(this.valuesList[0].hints[valInd].Notes[noteInd].Hints.length>1)
                              {
                                this.hoveredElement.Type=3;
                                this.hoveredElement.Id=-1;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                                this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                              }
                              else
                              {
                                this.hoveredElement.Type=2;
                                this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                                this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                              }
                            }

                            else
                            {
                              let ind=valInd;
                                while(ind>=0 && this.valuesList[0].hints[ind].Notes[noteInd]==null)
                                {
                                  ind--;
                                }
                                if(ind<0)
                                {
                                  ind=valInd;
                                  while(ind<this.valuesList[0].hints.length && this.valuesList[0].hints[ind].Notes[noteInd]==null)
                                  {
                                    ind++;
                                  }
                                }
                                if(ind>=0 && ind<this.valuesList[0].hints.length)
                                {
                                  
                                  if(this.valuesList[0].hints[ind].Notes[noteInd].Hints.length>1)
                                  {
                                    this.hoveredElement.Type=3;
                                    this.hoveredElement.Id=-1;
                                    this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                                    this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[noteInd].ValueSetId;
                                    this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                                  }
                                  else
                                  {
                                    this.hoveredElement.Type=2;
                                    this.hoveredElement.Id=this.valuesList[0].hints[ind].Notes[noteInd].Hints[0].Id;
                                    this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                                    this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[noteInd].ValueSetId;
                                    this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                                  }
                                }
                            }

                          }
                        }
                    }
                    
                    break;
                  }
                  case 3: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                      {
                        if(noteInd>-1)
                        {
                                this.hoveredElement.Type=2;
                                this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                                this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                              
                        }
                      }
                    }
                    
                    break;
                  }
                  case 4: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                      if(noteInd>-1)
                        {
                          this.valuesList[0].hints[valInd].Notes[noteInd].Visible=false;

                        }
                      }
                    this.hoveredElement.Type=3;
                    this.hoveredElement.Id=-1;
                    let se= <HTMLElement>this.searchContainerElem.nativeElement;
                                se.childNodes.forEach(n=>{
                                  if(n.nodeName=="INPUT")
                                  {
                                    let el=<HTMLElement>n;
                                    
                                  
                                
                                    el.focus();
                                  }
                                })
                    break;
                  }

                  case 5: 
                  {

                    if(this.valuesList[0].hints!=undefined && this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {

                      if(this.valuesList[0].hints[0].Notes==null || this.valuesList[0].hints[0].Notes.length==0)
                        {
                          this.hoveredElement.Type=1;
                          this.hoveredElement.Id=this.valuesList[0].hints[0].HintedValueId;
                          this.hoveredElement.RootId=-1;
                          this.hoveredElement.ParentId=-1;
                          this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                        }
                        else
                        {
                          let notesInd=this.valuesList[0].hints[0].Notes.length-1;
                          let valInd=-1;
                          let i=0;
                          while(valInd<this.valuesList[0].hints.length && valInd==-1)
                          {
                            if(this.valuesList[0].hints[i].Notes!=null && this.valuesList[0].hints[i].Notes[notesInd]!=null)
                            {
                              valInd=i;
                            }
                          }
                          if(this.valuesList[0].hints[valInd].Notes[notesInd].Hints.length==1)
                          {
                            this.hoveredElement.Type=2;
                            this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[notesInd].Hints[0].Id;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[notesInd].ValueSetId;
                            this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else if(this.valuesList[0].hints[valInd].Notes[notesInd].Hints.length>1)
                          {
                            this.hoveredElement.Type=3;
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[notesInd].ValueSetId;
                            this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }

                        }
                      }


                    break;
                  }
                }
              }


              pressRight()
              {
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
                    break;
                  }
                  case 1: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id);
                    if(this.valuesList[0].hints[valInd].Notes!=undefined && this.valuesList[0].hints[valInd].Notes!=null && this.valuesList[0].hints[valInd].Notes.length>0)
                    {
                      if(this.valuesList[0].hints[valInd].Notes[0]!=null)
                      {
                        this.hoveredElement.Type=2;
                        this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[0].Hints[0].Id;
                        this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                        this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[0].ValueSetId;
                        this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                      else
                      {
                        let ind=valInd;
                        while(ind>=0 && this.valuesList[0].hints[ind].Notes[0]==null)
                        {
                          ind--;
                        }
                        if(ind<0)
                        {
                          ind=valInd;
                          while(ind<this.valuesList[0].hints.length && this.valuesList[0].hints[ind].Notes[0]==null)
                          {
                            ind++;
                          }
                        }
                        if(ind>=0 && ind<this.valuesList[0].hints.length)
                        {
                          this.hoveredElement.Type=2;
                          this.hoveredElement.Id=this.valuesList[0].hints[ind].Notes[0].Hints[0].Id;
                          this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                          this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[0].ValueSetId;
                          this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                        }
                      }
                    }
                    else
                    {
                      if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                      {
                        this.hoveredElement.Type=5;
                        this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                        this.hoveredElement.RootId=-1;
                        this.hoveredElement.ParentId=-1;
                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                      }
                    }
                    break;
                  }
                  case 2: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                      {
                        if(noteInd>-1)
                        {
                          if(this.valuesList[0].hints[valInd].Notes[noteInd].Hints!=undefined && this.valuesList[0].hints[valInd].Notes[noteInd].Hints!=null && this.valuesList[0].hints[valInd].Notes[noteInd].Hints.length>1)
                          {
                            this.hoveredElement.Type=3;
                            this.hoveredElement.Id=-1;
                            this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);

                          }
                          else
                          {
                            if(noteInd==this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                              {
                                this.hoveredElement.Type=5;
                                this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                                this.hoveredElement.RootId=-1;
                                this.hoveredElement.ParentId=-1;
                                this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                              }
                            }
                            else if(noteInd<this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].hints[valInd].Notes[noteInd+1]!=null)
                              {
                                this.hoveredElement.Type=2;
                                this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd+1].Hints[0].Id;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd+1].ValueSetId;
                                this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                              }
                              else
                              {
                                let ind=valInd;
                                while(ind>=0 && this.valuesList[0].hints[ind].Notes[noteInd+1]==null)
                                {
                                  ind--;
                                }
                                if(ind<0)
                                {
                                  ind=valInd;
                                  while(ind<this.valuesList[0].hints.length && this.valuesList[0].hints[ind].Notes[noteInd+1]==null)
                                  {
                                    ind++;
                                  }
                                }
                                if(ind>=0 && ind<this.valuesList[0].hints.length)
                                {
                                  this.hoveredElement.Type=2;
                                  this.hoveredElement.Id=this.valuesList[0].hints[ind].Notes[noteInd+1].Hints[0].Id;
                                  this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                                  this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[noteInd+1].ValueSetId;
                                  this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    
                    break;
                  }
                  case 3: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                      {
                        if(noteInd>-1)
                        {
                          if(noteInd==this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                              {
                                this.hoveredElement.Type=5;
                                this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                                this.hoveredElement.RootId=-1;
                                this.hoveredElement.ParentId=-1;
                                this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                              }
                            }
                            else if(noteInd<this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].hints[valInd].Notes[noteInd+1]!=null)
                              {
                                this.hoveredElement.Type=2;
                                this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd+1].Hints[0].Id;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd+1].ValueSetId;
                                this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                              }
                              else
                              {
                                let ind=valInd;
                                while(ind>=0 && this.valuesList[0].hints[ind].Notes[noteInd+1]==null )
                                {
                                  ind--;
                                }
                                if(ind<0)
                                {
                                  ind=valInd;
                                  while(ind<this.valuesList[0].hints.length && this.valuesList[0].hints[ind].Notes[noteInd+1]==null )
                                  {
                                    ind++;
                                  }
                                }
                                if(ind>=0 && ind<this.valuesList[0].hints.length)
                                {
                                  this.hoveredElement.Type=2;
                                  this.hoveredElement.Id=this.valuesList[0].hints[ind].Notes[noteInd+1].Hints[0].Id;
                                  this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                                  this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[noteInd+1].ValueSetId;
                                  this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                                }
                              }
                            }
                          
                        }
                      }
                    }
                    
                    break;
                  }
                  case 4: 
                  {
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId)
                      if(noteInd>-1)
                        {
                          this.valuesList[0].hints[valInd].Notes[noteInd].Visible=false;

                        }



                        if(noteInd==this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                              {
                                this.hoveredElement.Type=5;
                                this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                                this.hoveredElement.RootId=-1;
                                this.hoveredElement.ParentId=-1;
                                this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                              }
                              else
                              {
                                this.hoveredElement.Type=3;
                                this.hoveredElement.Id=-1;
                              }
                            }
                            else if(noteInd<this.valuesList[0].hints[valInd].Notes.length-1)
                            {
                              if(this.valuesList[0].hints[valInd].Notes[noteInd+1]!=null)
                              {
                                this.hoveredElement.Type=2;
                                this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd+1].Hints[0].Id;
                                this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                                this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd+1].ValueSetId;
                              }
                              else
                              {
                                let ind=valInd;
                                while(ind>=0 && this.valuesList[0].hints[ind].Notes[noteInd+1]==null)
                                {
                                  ind--;
                                }
                                if(ind<0)
                                {
                                  ind=valInd;
                                  while(ind<this.valuesList[0].hints.length && this.valuesList[0].hints[ind].Notes[noteInd+1]==null)
                                  {
                                    ind++;
                                  }
                                }
                                if(ind>=0 && ind<this.valuesList[0].hints.length)
                                {
                                  this.hoveredElement.Type=2;
                                  this.hoveredElement.Id=this.valuesList[0].hints[ind].Notes[noteInd+1].Hints[0].Id;
                                  this.hoveredElement.RootId=this.valuesList[0].hints[ind].HintedValueId;
                                  this.hoveredElement.ParentId=this.valuesList[0].hints[ind].Notes[noteInd+1].ValueSetId;
                                  this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                                }
                              }
                            }
                    }
                    let se= <HTMLElement>this.searchContainerElem.nativeElement;
                                se.childNodes.forEach(n=>{
                                  if(n.nodeName=="INPUT")
                                  {
                                    let el=<HTMLElement>n;
                                    
                                   
                                    el.focus();
                                  }
                                })
                    break;
                  }

                  case 5: 
                  {
                    break;
                  }
                }
              }

              pressDown()
              {
                
                switch(this.hoveredElement.Type)
                {
                  case 0: 
                  {
      
                    if( !this.isActive &&this.isHidden)
                    {
                      this.showList();
                    }

                    else if( this.isActive && !this.isHidden)
                    {
                      if(this.valuesList[0].hints!=undefined && this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        this.hoveredElement.Type=1;
                        this.hoveredElement.Id=this.valuesList[0].hints[0].HintedValueId;
                        this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                      else if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                      {
                       
                        this.hoveredElement.Type=5;
                        this.hoveredElement.Id=this.valuesList[0].defaultHints[0].Id;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                      }
                    }

                    break;
                  }
                  case 1: 
                  {
      
                    let ind= this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id)
                    if(ind>-1)
                    {
                      if(ind<this.valuesList[0].hints.length-1)
                      {
                        this.hoveredElement.Id=this.valuesList[0].hints[ind+1].HintedValueId;
                      }
                    }

                    this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                    
                    break;
                  }
                  case 2: 
                  {
      
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1 && valInd<this.valuesList[0].hints.length-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      let newInd=-1;
                      let i=valInd+1; 
                      while(newInd==-1 && i<this.valuesList[0].hints.length)
                      {
                        if(this.valuesList[0].hints[i].Notes!=null && this.valuesList[0].hints[i].Notes[noteInd]!=null)
                        {
                          newInd=i;
                        }
                        i++;
                      }
                      if(newInd!=-1)
                      {
                        this.hoveredElement.Type=2;
                        this.hoveredElement.Id=this.valuesList[0].hints[newInd].Notes[noteInd].Hints[0].Id;
                        this.hoveredElement.RootId=this.valuesList[0].hints[newInd].HintedValueId;
                        this.hoveredElement.ParentId=this.valuesList[0].hints[newInd].Notes[noteInd].ValueSetId;
                        this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                    }
                    break;
                  }
                  case 3: 
                  {
                   
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1 && valInd<this.valuesList[0].hints.length-1)
                    {
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      let newInd=-1;
                      let i=valInd+1; 
                      while(newInd==-1 && i<this.valuesList[0].hints.length)
                      {
                        
                        if(this.valuesList[0].hints[i].Notes!=null && this.valuesList[0].hints[i].Notes[noteInd]!=null && this.valuesList[0].hints[i].Notes[noteInd].Hints!=null &&  this.valuesList[0].hints[i].Notes[noteInd].Hints.length>1)
                        {
                          newInd=i;
                        }
                        
                        i++;
                      }
                      if(newInd!=-1)
                      {
                       
                        this.hoveredElement.Type=3;
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.RootId=this.valuesList[0].hints[newInd].HintedValueId;
                        this.hoveredElement.ParentId=this.valuesList[0].hints[newInd].Notes[noteInd].ValueSetId;
                        this.scrollToHintListElement("all_button_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                      }
                    }
                    break;
                  }
                  case 4: 
                  {
                    
                    let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                    if(valInd>-1)
                    {
                      
                      let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                      if(this.hoveredElement.Id==-1)
                      {
                       
                        this.hoveredElement.Type=4;
                        this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                      }
                      else
                      {
                        let hintInd=this.valuesList[0].hints[valInd].Notes[noteInd].Hints.findIndex(c=>c.Id==this.hoveredElement.Id)
                        if(hintInd>-1 && hintInd<this.valuesList[0].hints[valInd].Notes[noteInd].Hints.length-1)
                        {
                          this.hoveredElement.Type=4;
                          this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd+1].Id;
                        }
                      }
                    }

                    let form= document.getElementsByClassName("additional-note");
                    if(form!=null)
                    {
                      this.scrollToHintListElement("note_hints_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id,  null, form[0]);
                        
                    }
                    
                    break;
                  }

                  case 5: 
                  {
                    if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                    {
                      
                      if(this.hoveredElement.Id==-1)
                      {
                        this.hoveredElement.Id=this.valuesList[0].defaultHints[0][this.idKey];
                      }
                      else
                      {
                        let ind= this.valuesList[0].defaultHints.findIndex(c=>c[this.idKey]==this.hoveredElement.Id)
                        if(ind>-1)
                        {
                          if(ind<this.valuesList[0].defaultHints.length-1)
                          {
                            this.hoveredElement.Id=this.valuesList[0].defaultHints[ind+1][this.idKey];
                          }
                        }

                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                        
                        
                      }
                      
                    }
                    
                    break;
                  }
                }
                

              }




              scrollToHintListElement(elementId:string, container: ElementRef, elem=null)
              {

                setTimeout(()=>{
                  let el = document.getElementById(elementId);
                  if(el!=null)
                  {
                    let parent;
                    if(container!=null)
                    {
                      parent=<HTMLElement>container.nativeElement;
                    }
                    else
                    {
                      parent=elem;
                    }
                    
                    let needScroll=false;
                    let elContainer = el.getBoundingClientRect();
                    let parentContainer = parent.getBoundingClientRect();
  
                    if (elContainer.top < parentContainer.top) {
                      needScroll=true;
                    }
                    if (elContainer.bottom > parentContainer.bottom) {
                      needScroll=true;
                    }
                    if (needScroll) {
                      
                      el.scrollIntoView();
  
                    }
                  }
                },100)
                
              }

              addNew()
              {
                setTimeout(()=>{
                let name="";
                if(this.valuesType==3)
                {
                  name=this.filterValueDate.getDate()<10?name+"0"+this.filterValueDate.getDate()+".":this.filterValueDate.getDate()+".";
                  name=(this.filterValueDate.getMonth()+1)<10?name+"0"+(this.filterValueDate.getMonth()+1)+".":name+(this.filterValueDate.getMonth()+1)+".";
                  let year=this.filterValueDate.getFullYear().toString();
                  while(year.length<4)
                  {
                    year="0"+year;
                  }
                  name=name+year;
                }
                    
                if(this.addingType==0)
                {
                  alert("невозможно добавить значение в данный набор")
                }
                if(this.addingType>0)
                {
                  if(this.valuesType<3 && this.filterValue!=null && this.filterValue.toString()!="" && this.filterValue.toString().trim()=="")
                  {
                    alert("Невозможно сохранить значение набора состоящее только из пробелов и переносов строк")
                    return;
                  }
                  if(this.addingType==2  || (this.addingType==1 && ((this.valuesType<3&& confirm("Вы действительно хотите добавить значение '"+this.filterValue+"' в набор?"))|| (this.valuesType==3&& confirm("Вы действительно хотите добавить значение '"+name+"' в набор?")))))
                  {
                    
                    //let newVal=_.cloneDeep(this.values[0]);

                    let newVal={};
                    if(this.valuesType<3)
                    {
                      newVal[this.nameKey]=this.filterValue;
                    }
                    else
                    {
                      newVal[this.nameKey]=name;
                    }
                    
                    newVal[this.idKey]=null;
                    newVal["ValueType"]=this.valuesType;
                    this.selectedValues=[];
                    this.selectedValues.push(newVal);
                    this.addNewValue.emit(this.filterValue);
                    //this.clear();
                    this.onSelectedValueChange();
                    //this.isActive=false;
                     // this.isHidden=false;

                      this.closeForm()
                  }
                }},800);
                
              }


              closeForm()
              {

                if(this.selectWithoutAdding  && (this.selectedValues==null || this.selectedValues.length==0))
                {
                  if(this.valuesType<3)
                  {
                    this.searchedText=_.cloneDeep(this.filterValue);
                  }
                  if(this.valuesType==3)
                  {
                    
                    this.searchedText="";
                    
                    this.searchedText=this.filterValueDay!=null&&this.filterValueDay!=''?this.searchedText+this.filterValueDay+".":this.searchedText+"--.";
                    this.searchedText=this.filterValueMonth!=null&&this.filterValueMonth!=''?this.searchedText+this.filterValueMonth+".":this.searchedText+"--.";
                    this.searchedText=this.filterValueYear!=null&&this.filterValueYear!=''?this.searchedText+this.filterValueYear:this.searchedText+"--";
                    if((this.filterValueDay==null||this.filterValueDay=='')&&(this.filterValueMonth==null||this.filterValueMonth=='')&& (this.filterValueYear==null||this.filterValueMonth==''))
                    {

                      
                      this.searchedText="";
                    }
                  }
                  this.selectedValues=[];
                  
                  this.SearchFieldText.emit(this.searchedText);
                }

                this.isActive=false;
               let hints:HTMLElement=null;
                let defaultH:HTMLElement=null;
                if(this.hintsScrollContainerElem!=undefined && this.hintsScrollContainerElem!=null)
                {hints=<HTMLElement>this.hintsScrollContainerElem.nativeElement;}
                if(this.defaultHintsScrollContainerElem!=undefined && this.defaultHintsScrollContainerElem!=null)
                {defaultH=<HTMLElement>this.defaultHintsScrollContainerElem.nativeElement;}
                if(hints!=null)
                {
                  
                  hints.scrollTo(0, 0);
                }
                if(defaultH!=null)
                {
                  
                  defaultH.scrollTo(0, 0);
                
                }
                //setTimeout(()=>{this.isHidden=true;},10)
                this.isHidden=false;
                this.clear();
                
              }
              clear()
              {
                this.hoveredElement.Type=0;
                this.hoveredElement.Id=0;
                this.hoveredElement.ParentId=0;
                this.hoveredElement.RootId=0;
                this.filterValue="";
                this.filterValueDate=null;
               // this.filterValueDay=null;
               // //this.filterValueMonth=null;
               // this.filterValueYear=null;
               // this.valueChanged.next(this.filterValueDate)
                if(!this.haveHints)
                {
                  this.valuesList=_.cloneDeep(this.valuesListBack);
                }
                else
                {
                  this.valuesList=_.cloneDeep(this.valuesListBack);
                }
                
              }


              searchFilterNotEmpty():boolean
              {
                let result=false;
                
                if(this.valuesType<3 && this.filterValue != null && this.filterValue != undefined && this.filterValue.toString() != "")
                {
                  result=true;
                }
                else
                {
                  if(this.valuesType==3 && (this.filterValueDay!=null && this.filterValueDay!=''||this.filterValueMonth!=null && this.filterValueMonth!='' || this.filterValueYear!=null && this.filterValueYear!=''))
                  {
                    result=true;
                  }
                }

                return result;
              }


              matchesInDateFull(dateString:string):boolean
              {
                let result =false;
                let nameParts=dateString.split(".");
                if((this.filterValueMonth==null || this.filterValueMonth=='') && (this.filterValueYear==null || this.filterValueYear=='') &&this.filterValueDay!=null && this.filterValueDay!='' && Number(nameParts[0])==Number(this.filterValueDay))
                {
                  result =true;
                }
                if((this.filterValueDay==null || this.filterValueDay=='') && (this.filterValueYear==null || this.filterValueYear=='') && this.filterValueMonth!=null && this.filterValueMonth!='' && Number(nameParts[1])==Number(this.filterValueMonth))
                {
                  result =true;
                }
                if((this.filterValueDay==null || this.filterValueDay=='') && (this.filterValueMonth==null || this.filterValueMonth=='') && this.filterValueYear!=null && this.filterValueYear!='' && Number(nameParts[2])==Number(this.filterValueYear))
                {
                  result =true;
                }


                if((this.filterValueYear==null || this.filterValueYear=='') && (this.filterValueDay!=null && this.filterValueDay!='' && Number(nameParts[0])==Number(this.filterValueDay)) && (this.filterValueMonth!=null && this.filterValueMonth!='' && Number(nameParts[1])==Number(this.filterValueMonth)))
                {
                  result =true;
                }

                if((this.filterValueMonth==null || this.filterValueMonth=='') && (this.filterValueDay!=null && this.filterValueDay!='' && Number(nameParts[0])==Number(this.filterValueDay)) && ( this.filterValueYear!=null && this.filterValueYear!='' && Number(nameParts[2])==Number(this.filterValueYear)))
                {
                  result =true;
                }

                if((this.filterValueDay==null || this.filterValueDay=='') && (this.filterValueMonth!=null && this.filterValueMonth!='' && Number(nameParts[1])==Number(this.filterValueMonth)) && ( this.filterValueYear!=null && this.filterValueYear!='' && Number(nameParts[2])==Number(this.filterValueYear)))
                {
                  result =true;
                }


                if((this.filterValueDay!=null && this.filterValueDay!='' && Number(nameParts[0])==Number(this.filterValueDay)) && (this.filterValueMonth!=null && this.filterValueMonth!='' && Number(nameParts[1])==Number(this.filterValueMonth)) && ( this.filterValueYear!=null && this.filterValueYear!='' && Number(nameParts[2])==Number(this.filterValueYear)))
                {
                  result =true;
                }





                if((this.filterValueDay==null || this.filterValueDay=='')&&(this.filterValueMonth==null || this.filterValueMonth=='')&&(this.filterValueYear==null || this.filterValueYear==''))
                {
                  result =true;
                }

                return result;
              }

              search()
              {

               
                if(!this.haveHints)
                {
                  
                  this.valuesList = _.cloneDeep(this.valuesListBack);
                  if (this.searchFilterNotEmpty()) {
                    
                    if(this.valuesType<3)
                    {
                      
                      this.valuesList = this.valuesList.filter(element => {
                        return element[this.nameKey]!=null && ExtendedHelper.ContainsNoCaseStrict(element[this.nameKey], this.filterValue.toString());
                    });
                    }
                    else
                    {
                      if(this.valuesType==3)
                      {
                        
                        this.valuesList = this.valuesList.filter(element => {
                          let haveMatches=this.matchesInDateFull(element[this.nameKey]);
                          return haveMatches;
                        });
                      }
                    }
                      
                      if(this.valuesList!=null && this.valuesList.length>0)
                      {
                        if(this.hoveredElement.Type==6)
                        {
                          let indS=this.valuesList.findIndex(c=>c[this.idKey]==this.hoveredElement.Id);
                          if(indS>-1)
                          {
                            this.hoveredElement.Id=this.valuesList[indS][this.idKey];
                          }
                          else
                          {
                            this.hoveredElement.Id=this.valuesList[0][this.idKey];
                          }
                          this.scrollToHintListElement("withouthinted_val_"+this.hoveredElement.Id, this.valuesScrollContainerElem);
                    
                        }
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      } 
                  }
                  
                }
                else
                {
                  this.valuesList = _.cloneDeep(this.valuesListBack);
                  if (this.searchFilterNotEmpty()) {
                    if(this.valuesList[0].defaultHints!=undefined && this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0  )
                    {

                      if(this.valuesType<3)
                      {
                        
                      this.valuesList[0].defaultHints = this.valuesList[0].defaultHints.filter(element => {
                        return element[this.nameKey]!=null && ExtendedHelper.ContainsNoCaseStrict(element[this.nameKey], this.filterValue.toString());
                    });}
                    else
                    {
                      if(this.valuesType==3)
                      {
                        
                        this.valuesList[0].defaultHints = this.valuesList[0].defaultHints.filter(element => {
                          let haveMatches=this.matchesInDateFull(element[this.nameKey]);
                          return haveMatches;
                        });
                      }
                    }
                  }

                  if(this.valuesList[0].hints!=undefined && this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0  )
                    {
                      if(this.valuesType<3)
                      {
                      this.valuesList[0].hints = this.valuesList[0].hints.filter(element => {
                        return element.HintedValue!=null && ExtendedHelper.ContainsNoCaseStrict(element.HintedValue, this.filterValue.toString());
                    });}
                    else
                    {
                      if(this.valuesType==3)
                      {
                        
                        this.valuesList[0].hints = this.valuesList[0].hints.filter(element => {
                          let haveMatches=this.matchesInDateFull(element.HintedValue);
                          return haveMatches;
                        });
                      }
                    }
                  }
                 if(this.valuesList[0]!=null  && (this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0 || this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0))
                  {
                    if(this.hoveredElement.Type==1)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let indS=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id);
                        if(indS>-1)
                        {
                          this.hoveredElement.Id=this.valuesList[0].hints[indS].HintedValueId;
                        }
                        else
                        {
                          this.hoveredElement.Id=this.valuesList[0].hints[0].HintedValueId;
                        }
                        this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                       
                      }
                      else
                      {
                        
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==2)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let valInd=-1;
                        let noteInd=-1;
                        let vI=0;
                        let nI=0;
                        let valIndCur=-1;
                        let noteIndCur=-1;
                        this.valuesList[0].hints.forEach(c=>
                          {
                            if(c.Notes!=null)
                            {
                              c.Notes.forEach(n=>
                                {
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>0 && valInd==-1 && noteInd==-1)
                                  {
                                    valInd=vI;
                                    noteInd=nI;
                                  }
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>0 && vI==this.hoveredElement.RootId && nI==this.hoveredElement.ParentId)
                                  {
                                    valIndCur=vI;
                                    noteIndCur=nI;
                                  }
                                  nI++;
                                })
                            }
                            vI++;
                          })

                          if(valIndCur!=-1 && noteIndCur!=-1)
                          {
                            this.hoveredElement.Id=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].Hints[0].Id;
                            this.hoveredElement.Type=2;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valIndCur].HintedValueId;
                            this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }

                          else if(valInd!=-1 && noteInd!=-1)
                          {
                            this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                            this.hoveredElement.Type=2;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=0;
                            this.hoveredElement.ParentId=-1;
                            this.hoveredElement.RootId=-1;
                          }
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==3)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let valInd=-1;
                        let noteInd=-1;
                        let valIndCur=-1;
                        let noteIndCur=-1;
                        let vI=0;
                        let nI=0;
                        this.valuesList[0].hints.forEach(c=>
                          {
                            if(c.Notes!=null)
                            {
                              c.Notes.forEach(n=>
                                {
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>1 && valInd==-1 && noteInd==-1)
                                  {
                                    valInd=vI;
                                    noteInd=nI;
                                  }
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>1 && vI==this.hoveredElement.RootId && nI==this.hoveredElement.ParentId)
                                  {
                                    valIndCur=vI;
                                    noteIndCur=nI;
                                  }
                                  nI++;
                                })
                            }
                            vI++;
                          })

                          if(valIndCur!=-1 && noteIndCur!=-1)
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=3;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valIndCur].HintedValueId;
                            this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }

                          else if(valInd!=-1 && noteInd!=-1)
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=3;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=0;
                            this.hoveredElement.ParentId=-1;
                            this.hoveredElement.RootId=-1;
                          }
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==5)
                    {
                      
                      if(this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                      {
                        let indS=this.valuesList[0].defaultHints.findIndex(c=>c[this.idKey]==this.hoveredElement.Id);
                        if(indS>-1)
                        {
                          this.hoveredElement.Id=this.valuesList[0].defaultHints[indS][this.idKey];
                        }
                        else
                        {
                          this.hoveredElement.Id=this.valuesList[0].defaultHints[0][this.idKey];
                        }
                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                       
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                  }
                  else
                  {
                    
                    this.hoveredElement.Id=-1;
                    this.hoveredElement.Type=0;
                    this.hoveredElement.ParentId=-1;
                    this.hoveredElement.RootId=-1;
                  }
                    
                }
                if(this.valuesList[0]!=null  && (this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0 || this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0))
                  {
                   
                    if(this.hoveredElement.Type==1)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let indS=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.Id);
                        if(indS>-1)
                        {
                          this.hoveredElement.Id=this.valuesList[0].hints[indS].HintedValueId;
                        }
                        else
                        {
                          this.hoveredElement.Id=this.valuesList[0].hints[0].HintedValueId;
                        }
                        this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                       
                      }
                      else
                      {
                        
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==2)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let valInd=-1;
                        let noteInd=-1;
                        let vI=0;
                        let nI=0;
                        let valIndCur=-1;
                        let noteIndCur=-1;
                        this.valuesList[0].hints.forEach(c=>
                          {
                            if(c.Notes!=null)
                            {
                              c.Notes.forEach(n=>
                                {
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>0 && valInd==-1 && noteInd==-1)
                                  {
                                    valInd=vI;
                                    noteInd=nI;
                                  }
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>0 && vI==this.hoveredElement.RootId && nI==this.hoveredElement.ParentId)
                                  {
                                    valIndCur=vI;
                                    noteIndCur=nI;
                                  }
                                  nI++;
                                })
                            }
                            vI++;
                          })

                          if(valIndCur!=-1 && noteIndCur!=-1)
                          {
                            this.hoveredElement.Id=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].Hints[0].Id;
                            this.hoveredElement.Type=2;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valIndCur].HintedValueId;
                            this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }

                          else if(valInd!=-1 && noteInd!=-1)
                          {
                            this.hoveredElement.Id=this.valuesList[0].hints[valInd].Notes[noteInd].Hints[0].Id;
                            this.hoveredElement.Type=2;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.scrollToHintListElement("hinted_val_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=0;
                            this.hoveredElement.ParentId=-1;
                            this.hoveredElement.RootId=-1;
                          }
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==3)
                    {
                      
                      if(this.valuesList[0].hints!=null && this.valuesList[0].hints.length>0)
                      {
                        let valInd=-1;
                        let noteInd=-1;
                        let valIndCur=-1;
                        let noteIndCur=-1;
                        let vI=0;
                        let nI=0;
                        this.valuesList[0].hints.forEach(c=>
                          {
                            if(c.Notes!=null)
                            {
                              c.Notes.forEach(n=>
                                {
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>1 && valInd==-1 && noteInd==-1)
                                  {
                                    valInd=vI;
                                    noteInd=nI;
                                  }
                                  if(n!=null && n.ValueSetId==this.hoveredElement.ParentId && n.Hints!=null && n.Hints.length>1 && vI==this.hoveredElement.RootId && nI==this.hoveredElement.ParentId)
                                  {
                                    valIndCur=vI;
                                    noteIndCur=nI;
                                  }
                                  nI++;
                                })
                            }
                            vI++;
                          })

                          if(valIndCur!=-1 && noteIndCur!=-1)
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=3;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valIndCur].Notes[noteIndCur].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valIndCur].HintedValueId;
                            this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }

                          else if(valInd!=-1 && noteInd!=-1)
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=3;
                            this.hoveredElement.ParentId=this.valuesList[0].hints[valInd].Notes[noteInd].ValueSetId;
                            this.hoveredElement.RootId=this.valuesList[0].hints[valInd].HintedValueId;
                            this.scrollToHintListElement("note_val_"+this.hoveredElement.ParentId+"_"+this.hoveredElement.Id, this.hintsScrollContainerElem);
                          }
                          else
                          {
                            this.hoveredElement.Id=-1;
                            this.hoveredElement.Type=0;
                            this.hoveredElement.ParentId=-1;
                            this.hoveredElement.RootId=-1;
                          }
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                    if(this.hoveredElement.Type==5)
                    {
                      
                      if(this.valuesList[0].defaultHints!=null && this.valuesList[0].defaultHints.length>0)
                      {
                        let indS=this.valuesList[0].defaultHints.findIndex(c=>c[this.idKey]==this.hoveredElement.Id);
                        if(indS>-1)
                        {
                          this.hoveredElement.Id=this.valuesList[0].defaultHints[indS][this.idKey];
                        }
                        else
                        {
                          this.hoveredElement.Id=this.valuesList[0].defaultHints[0][this.idKey];
                        }
                        this.scrollToHintListElement("default_val_"+this.hoveredElement.Id, this.defaultHintsScrollContainerElem);
                       
                      }
                      else
                      {
                        this.hoveredElement.Id=-1;
                        this.hoveredElement.Type=0;
                        this.hoveredElement.ParentId=-1;
                        this.hoveredElement.RootId=-1;
                      }
                    }
                  }
                  else
                  {
                    
                    this.hoveredElement.Id=-1;
                    this.hoveredElement.Type=0;
                    this.hoveredElement.ParentId=-1;
                    this.hoveredElement.RootId=-1;
                  }

                }
                
              }



              
                getHintsStyles() {
                  return {
                    display: 'grid',
                    'grid-template-columns': `repeat(${this.valuesList[0].titles.length}, 1fr)`,
                  };
                }
              

                showList()
                {
                  if(this.setLocationType==0)
                  {
                    this.showListInParameter();
                  }
                  if(this.setLocationType==1)
                  {
                    this.showListInStorage();
                  }
                }

              showListInParameter()
              {

                if(this.isActive)
                {
                   // this.isActive=false;
                    //this.isHidden=false;
                   // this.clear();
                    this.closeForm()
                  
                }
                else
                {
                  if(this.selectWithoutAdding)
                  {
                    //this.filterValue=this.searchedText;
                    if(this.valuesType<3)
                    {
                      this.filterValue=this.searchedText;
                    }
                    if(this.valuesType==3)
                    {
                      let dt=this.searchedText.split(".");
                      if(dt[0].includes("-") ||dt[1].includes("-") ||dt[2].includes("-"))
                      {
                        
                        this.filterValueDate=null;
                        this.filterValueDay=dt[0].includes("-")?null:Number(dt[0])<10?"0"+dt[0]:dt[0];
                        this.filterValueMonth=dt[1].includes("-")?null:Number(dt[1])<10?"0"+Number(dt[1]).toString():Number(dt[1]).toString();
                        this.filterValueYear=dt[2].includes("-")?null:dt[2];
                      }
                      else
                      {
                        let date = new Date();
                        date.setUTCHours(0,0,0,0)
                        date.setUTCFullYear(Number(dt[2]));
                        date.setUTCDate(Number(dt[0]));
                        date.setUTCMonth(Number(dt[1])-1);
                        this.filterValueDate=date;
                      }
                      
                      this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                    }
                  }
                  
                  let opt=<HTMLElement>this.optionsListElem.nativeElement;
                  opt.style.width="max-content";

                  if(this.haveHints)
                  {

                    let checker = new SingleIdRequest();
                    checker.ChangerId = this.UserId;
                    checker.SessionKey = this.SessionKey;
                    checker.Id=this.id;
                    
                    this.httpServiceParameters.parameterInstanceGetHintsToField(checker).subscribe((data: ParameterHintGiver) => {
                        if (data.Code == 100) {
                            this.unLogin(data.Message);
                            return;
                        }
                        
                        
                          if(data.Code==300)
                          {
                              alert("клиент или проект не найден");
                              this.goToMain(null);
                          }
                          if(data.Code==301)
                          {
                              alert("клиент или проект был удален");
                              this.goToMain(null);
                          }
                          if(data.Code==302)
                          {
                              alert("нет доступа к клиенту или проекту");
                              this.goToMain(null);
                          }
    
                          if(data.Code==303)
                          {
                            
                              alert("поле не найдено");
                              //field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                          }
                          if(data.Code==304)
                          {
                            alert("поле было удалено");
                            //field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                          }
    
    
                          if(data.Code==305)
                          {
                            alert("полее не является набором значений");
                           // field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                          }
                      
                      
                        if (data.Code == 500) { console.error(data.Message); return; }
                        
                        if(data.Code==0)
                        {

                          this.hintWidth=data.HintsLenght?(data.HintsLenght+5)*14+"px":"fill-available";

                       
                          this.defWidth=data.DefaultHintsLenght? (data.DefaultHintsLenght+5)*14+"px":"fill-available";


                          this.addingType=data.HintedSetType;
                          let hints=[];
                          if(data.Hints!=null && data.Hints.length>0)
                          {
                            data.Hints.forEach(c=>{
                              let hint= new ParameterHintView();
                              hint.FromParameterHint(c, this.PossibleValueSetsIds.filter(c=>c!=this.SetId));
                              hints.push(hint);
                            })
                          }

                          let titles=[];
                          titles.push(new Common(this.id, data.HintedSetName));
                          let notesList=new Map();
                          hints.forEach(h=>{
                            h.Notes.forEach(n=>
                              {
                                notesList.set(n.ValueSetId, n.ValueSetName);
                                
                              })
                          })

                          
                         
                            notesList.forEach((value, key, map) => {
                              titles.push(new Common(key, value))
                              
                            });


                          let buffNotes=[];
                          hints.forEach(h=>
                            {
                              buffNotes=[];
                              buffNotes=_.cloneDeep(h.Notes);
                              h.Notes=[];
                              


                                notesList.forEach((value, key, map) => {
                                  let ind=buffNotes.findIndex(c=>c.ValueSetId==key)
                                  if(ind>-1)
                                  {
                                    h.Notes.push(buffNotes[ind]);
                                  }
                                  else
                                  {
                                    h.Notes.push(null);
                                  }
                                  
                                });
                            })
                          
                          this.valuesList=[{'defaultHints': data.DefaultHints, 'hints':hints, 'titles':titles}];

                         
                          this.valuesListBack=_.cloneDeep(this.valuesList);
                          
                          
                          
                          const viewportHeight = document.documentElement.clientHeight;
                          const viewportWidth = document.documentElement.clientWidth;
                          const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();
                          this.topSearch=selectedListBounds.y+ selectedListBounds.height;
                          this.topList=selectedListBounds.y+ selectedListBounds.height+30;
                          this.bottomSearch=null;
                          this.bottomList=null;
                          /*this.listWidth=selectedListBounds.width;
                          if(selectedListBounds.width<500)
                          {
                            this.listWidth=500;
                          }*/



                         
                          this.isHidden=true;
                          setTimeout(()=>{
                            let el=<HTMLElement>this.optionsListElem.nativeElement;
                            el.style.maxHeight="600px";

                            //////////havehints





                            const dropdownHeight = this.optionsListElem.nativeElement.getBoundingClientRect();
                            

                            //this.listWidth=selectedListBounds.width;
                            this.isActive=true;
                          
                         /* if(dropdownHeight.width<500)
                          {
                            this.listWidth=500;
                          }*/

                          this.listWidth=dropdownHeight.width;
                            
                            if(selectedListBounds.x+dropdownHeight.width<viewportWidth)
                            {
                              this.leftList=selectedListBounds.x;
                              this.rightList=null;
                            }
                            else
                            {
                              this.leftList=null;
                              
                                this.rightList=viewportWidth-selectedListBounds.right;
                                
                                if(viewportWidth-this.rightList<dropdownHeight.width)
                                {
                                  this.leftList=10;
                                  this.rightList=null;
                                }
                            }
                            let opt=<HTMLElement>this.optionsListElem.nativeElement;
                            if(opt.getBoundingClientRect().left+this.listWidth>=viewportWidth)
                            {
                              this.listWidth=viewportWidth-opt.getBoundingClientRect().left-20;
                            }
                              opt.style.width=this.listWidth+"px";
                            if(dropdownHeight.width<selectedListBounds.width)
                            {
                              let opt=<HTMLElement>this.optionsListElem.nativeElement;
                              opt.style.width=selectedListBounds.width+"px";
                              this.listWidth=selectedListBounds.width;
                            }

                            if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                            {
                              
                              this.topSearch=selectedListBounds.y;
                              this.topList=selectedListBounds.y+30;
                              this.bottomSearch=null;
                              this.bottomList=null;
                            }
                            else if(viewportHeight - selectedListBounds.y-selectedListBounds.height+28 -dropdownHeight.height>0)
                            {
        
                              
                              this.topSearch=null;
                              this.topList=null;
                              this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                              this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                            }
                            else
                            {
                              if(viewportHeight-dropdownHeight.top> dropdownHeight.top)
                              {
        
                               
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=viewportHeight-dropdownHeight.top-5+"px";
                                setTimeout(()=>{this.topSearch=selectedListBounds.y;
                                  this.topList=selectedListBounds.y+30;
                                  this.bottomSearch=null;
                                  this.bottomList=null;},100)
                                
                              }
                              else
                              {
                                
                                
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=selectedListBounds.y-selectedListBounds.height+28 - 5+"px";
                                  this.topSearch=null;
                                  this.topList=null;
                                  this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                                  this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                                
                              }
                            }


                          //  this.isActive=true;
                            
                            setTimeout(()=>{
                              this.isHidden=false;
                              
                              setTimeout(()=>{
                                if(this.selectedValues!=undefined && this.selectedValues!=null && this.selectedValues.length>0)
                                {
                                  this.openValueChanged=true;
                                  if(this.valuesType<3)
                                  {this.filterValue=this.selectedValues[0][this.nameKey];}
                                  
                                  if(this.valuesType==3)
                                      {
                                        let dt=this.selectedValues[0][this.nameKey].split(".");
                                          let date = new Date();
                                          date.setUTCHours(0,0,0,0);
                                          date.setUTCFullYear(Number(dt[2]));
                                          date.setUTCDate(Number(dt[0]));
                                          date.setUTCMonth(Number(dt[1])-1);
                                          this.filterValueDate=date;
                      
                                        this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                      }
                                }
                                let se= <HTMLElement>this.searchContainerElem.nativeElement;
                                if(this.valuesType<3)
                                {
                                  se.childNodes.forEach(n=>{
                                    if(n.nodeName=="INPUT")
                                    {
                                      let el=<HTMLElement>n;
                                      
                                     
                                      el.focus();
                                    }
                                  })
                                }
                                else
                                {
                                  this.selectDateFilterElement(0);
                                }
                              },100);
        


                             },100);
                          
                          },100)
                        
                  
                        }
    
                    });  
                  }


                  else
                {


                  let checker = new SingleIdRequest();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=this.id;
                  
                  this.httpServiceParameters.parameterInstanceGetHintsToParameter(checker).subscribe((data: ParameterHintGiver) => {
                      if (data.Code == 100) {
                          this.unLogin(data.Message);
                          return;
                      }
                      
                      
                        if(data.Code==300)
                        {
                            alert("клиент или проект не найден");
                            this.goToMain(null);
                        }
                        if(data.Code==301)
                        {
                            alert("клиент или проект был удален");
                            this.goToMain(null);
                        }
                        if(data.Code==302)
                        {
                            alert("нет доступа к клиенту или проекту");
                            this.goToMain(null);
                        }
  
                        if(data.Code==303)
                        {
                          
                            alert("поле не найдено");
                            //field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                        }
                        if(data.Code==304)
                        {
                          alert("поле было удалено");
                          //field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                        }
  
  
                        if(data.Code==305)
                        {
                          alert("полее не является набором значений");
                         // field.ListTitle.Instances=field.ListTitle.Instances.filter(c=>c.Id!=value.Id);
                        }
                    
                    
                      if (data.Code == 500) { console.error(data.Message); return; }
                      
                      if(data.Code==0)
                      {

                        this.hintWidth=data.HintsLenght?(data.HintsLenght+5)*14+"px": "fill-available";

                       
                          this.defWidth=data.DefaultHintsLenght?(data.DefaultHintsLenght+5)*14+"px": "fill-available";

                        this.addingType=data.HintedSetType;
                        this.valuesList=data.DefaultHints;
                        this.valuesListBack=_.cloneDeep(data.DefaultHints);







                          const viewportHeight = document.documentElement.clientHeight;
                  const viewportWidth = document.documentElement.clientWidth;
                    const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();
                    this.topSearch=selectedListBounds.y+ selectedListBounds.height;
                    this.topList=selectedListBounds.y+ selectedListBounds.height+30;
                    this.bottomSearch=null;
                    this.bottomList=null;
                  //  this.listWidth=selectedListBounds.width;
                    this.isHidden=true;
                  setTimeout(()=>{
                    let el=<HTMLElement>this.optionsListElem.nativeElement;
                    el.style.maxHeight="600px";

                    //////////havehints

                    const dropdownHeight = this.optionsListElem.nativeElement.getBoundingClientRect();


/*
                    
                    if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                    {
                      this.topSearch=selectedListBounds.y;
                      this.topList=selectedListBounds.y+30;
                      this.bottomSearch=null;
                      this.bottomList=null;
                    }
                    else if(viewportHeight - selectedListBounds.y-selectedListBounds.height+28 -dropdownHeight.height>0)
                    {

                      
                      this.topSearch=null;
                      this.topList=null;
                      this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                      this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                    }
                    else
                    {
                      if(viewportHeight-dropdownHeight.top> dropdownHeight.top)
                      {

                        let el=<HTMLElement>this.optionsListElem.nativeElement;
                        el.style.maxHeight=viewportHeight-dropdownHeight.top-5+"px";
                        setTimeout(()=>{this.topSearch=selectedListBounds.y;
                          this.topList=selectedListBounds.y+30;
                          this.bottomSearch=null;
                          this.bottomList=null;},100)
                        
                      }
                      else
                      {
                        

                        let el=<HTMLElement>this.optionsListElem.nativeElement;
                        el.style.maxHeight=viewportHeight - selectedListBounds.y-selectedListBounds.height+28 - 5+"px";
                          this.topSearch=null;
                          this.topList=null;
                          this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                          this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                        
                      }
                    }
*/

                    this.isActive=true;
                          
                         /* if(dropdownHeight.width<500)
                          {
                            this.listWidth=500;
                          }
*/
                          this.listWidth=dropdownHeight.width;


                    if(selectedListBounds.x+this.listWidth<viewportWidth)
                            {
                              this.leftList=selectedListBounds.x;
                              this.rightList=null;
                            }
                            else
                            {
                              this.leftList=null;
                              this.rightList=viewportWidth-selectedListBounds.right;
                              if(viewportWidth-this.rightList<dropdownHeight.width)
                                {
                                  this.leftList=10;
                                  this.rightList=null;
                                }
                            }

                            let opt=<HTMLElement>this.optionsListElem.nativeElement;
                            if(opt.getBoundingClientRect().left+this.listWidth>=viewportWidth)
                            {
                              this.listWidth=viewportWidth-opt.getBoundingClientRect().left-20;
                            }
                              opt.style.width=this.listWidth+"px";
                            if(dropdownHeight.width<selectedListBounds.width)
                            {
                              let opt=<HTMLElement>this.optionsListElem.nativeElement;
                              opt.style.width=selectedListBounds.width+"px";
                              this.listWidth=selectedListBounds.width;
                            }



                            if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                            {
                              this.topSearch=selectedListBounds.y;
                              this.topList=selectedListBounds.y+30;
                              this.bottomSearch=null;
                              this.bottomList=null;
                            }
                            else if(viewportHeight - selectedListBounds.y-selectedListBounds.height+28 -dropdownHeight.height>0)
                            {
        
                              
                              this.topSearch=null;
                              this.topList=null;
                              this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                              this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                            }
                            else
                            {
                              if(viewportHeight-dropdownHeight.top> dropdownHeight.top)
                              {
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=viewportHeight-dropdownHeight.top-5+"px";
                                setTimeout(()=>{this.topSearch=selectedListBounds.y;
                                  this.topList=selectedListBounds.y+30;
                                  this.bottomSearch=null;
                                  this.bottomList=null;},100)
                                
                              }
                              else
                              {
                                
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=selectedListBounds.y-selectedListBounds.height+28 - 5+"px";
                                  this.topSearch=null;
                                  this.topList=null;
                                  this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                                  this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                                
                              }
                            }

                            
                    this.isActive=true;
                    
                    setTimeout(()=>{this.isHidden=false;
                      setTimeout(()=>{
                        if(this.selectedValues!=undefined && this.selectedValues!=null && this.selectedValues.length>0)
                                {
                                  this.openValueChanged=true;
                                  if(this.valuesType<3)
                                  {
                                  this.filterValue=this.selectedValues[0][this.nameKey];
                                  }
                                  if(this.valuesType==3)
                                  {
                                    let dt=this.selectedValues[0][this.nameKey].split(".");
                                      let date = new Date();
                                      date.setUTCHours(0,0,0,0);
                                      date.setUTCFullYear(Number(dt[2]));
                                      date.setUTCDate(Number(dt[0]));
                                      date.setUTCMonth(Number(dt[1])-1);
                                      this.filterValueDate=date;
                  
                                    this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                  }
                                  
                                }
                        let se= <HTMLElement>this.searchContainerElem.nativeElement;
                        if(this.valuesType<3)
                        {
                          se.childNodes.forEach(n=>{
                            if(n.nodeName=="INPUT")
                            {
                              let el=<HTMLElement>n;
                              
                             
                              el.focus();
                            }
                          })
                        }
                        else
                        {
                          this.selectDateFilterElement(0);
                        }
                      },100);


                      },100);
                  },100)
                      
                
                      }
  
                  }); 
 
                }   
                  
                }
               
                
              }



  fill2(container: ParameterInstanceCreateContainerFilled, parent:ParameterInstanceContainerFilled)
  {
    if(container.ValueType==0)
      {
        container.StringValues.forEach(v=>{
          let nCo=new ParameterInstanceContainerFilled();
          nCo.StringValue=ExtendedHelper.ReplaceBadThings(v);
          nCo.TemplateId=container.Template.Id;
          parent.RecordFields.push(nCo);
        })
      }

      if(container.ValueType==1 || container.ValueType==2)
      {
        container.NumberValues.forEach(v=>{
          let nCo=new ParameterInstanceContainerFilled();
          nCo.NumberValue=v;
          nCo.TemplateId=container.Template.Id;
          parent.RecordFields.push(nCo);
        })
      }

      if(container.ValueType==3)
      {
        container.BooleanValues.forEach(v=>{
          let nCo=new ParameterInstanceContainerFilled();
          nCo.BoolValue=v;
          nCo.TemplateId=container.Template.Id;
          parent.RecordFields.push(nCo);
        })
      }

      if(container.ValueType==4)
      {
        container.ValueSetSelected.forEach(v=>{
          let nCo=new ParameterInstanceContainerFilled();
          nCo.ValueOfSet=v!=null && v.length>0?v[0].Id:null;
          nCo.TemplateId=container.Template.Id;
          parent.RecordFields.push(nCo);
        })
      }


      if(container.ValueType==5)
      {
        container.RecordFields.forEach(v=>{
          let nCo=new ParameterInstanceContainerFilled();
          nCo.TemplateId=container.Template.Id;
          v.forEach(v1=>
            {
              this.fill2(v1,nCo);
              
            })
            parent.RecordFields.push(nCo);
          
        })
      }


  }




              fillRecordFIelds(outerContainer:ParameterInstanceContainerFilled, searchContainer: ParameterInstanceCreateContainerFilled, parent: any)
              {
               
                 
                    
                    if(searchContainer.ValueType==0)
                    {
                    if(!searchContainer.IsList)
                      {
                        
                        outerContainer.StringValue=searchContainer.StringValues[0];
                        outerContainer.TemplateId=searchContainer.Template.Id;
                      }
                      if(searchContainer.IsList)
                      {
                        searchContainer.StringValues.forEach(v=>{
                          let nCo=new ParameterInstanceContainerFilled();
                          nCo.StringValue=v;
                          nCo.TemplateId=searchContainer.Template.Id;
                          outerContainer.RecordFields.push(nCo);
                        })
                        
                      }
                    }
                    if(searchContainer.ValueType==1 || searchContainer.ValueType==2)
                    {
                      if(!searchContainer.IsList)
                      {
                        outerContainer.NumberValue=searchContainer.NumberValues[0];
                        outerContainer.TemplateId=searchContainer.Template.Id;
                      }
                      if(searchContainer.IsList)
                      {
                        searchContainer.NumberValues.forEach(v=>{
                          let nCo=new ParameterInstanceContainerFilled();
                          nCo.NumberValue=v;
                          nCo.TemplateId=searchContainer.Template.Id;
                          outerContainer.RecordFields.push(nCo);
                        })
                        
                      }
                    }

                    
                    if(searchContainer.ValueType==3)
                    {
                      if(!searchContainer.IsList)
                      {
                        outerContainer.BoolValue=searchContainer.BooleanValues[0];
                        outerContainer.TemplateId=searchContainer.Template.Id;
                      }
                      if(searchContainer.IsList)
                      {
                        searchContainer.BooleanValues.forEach(v=>{
                          let nCo=new ParameterInstanceContainerFilled();
                          nCo.BoolValue=v;
                          nCo.TemplateId=searchContainer.Template.Id;
                          outerContainer.RecordFields.push(nCo);
                        })
                        
                      }
                    }

                    if(searchContainer.ValueType==4)
                    {
                      if(!searchContainer.IsList)
                      {
                        outerContainer.ValueOfSet=searchContainer.ValueSetSelected[0]!=null && searchContainer.ValueSetSelected[0].length>0?searchContainer.ValueSetSelected[0][0].Id:null;
                        outerContainer.TemplateId=searchContainer.Template.Id;
                      }
                      if(searchContainer.IsList)
                      {
                        searchContainer.ValueSetSelected.forEach(v=>{
                          let nCo=new ParameterInstanceContainerFilled();
                          nCo.ValueOfSet=v!=null && v.length>0?v[0].Id:null;
                          nCo.TemplateId=searchContainer.Template.Id;
                          outerContainer.RecordFields.push(nCo);
                        })
                        
                      }
                    }



                    if(searchContainer.ValueType==5)
                    {
                      outerContainer.TemplateId=searchContainer.Template.Id;
                      searchContainer.RecordFields.forEach(c=>{ 
                        let contain= new ParameterInstanceContainerFilled();
                        contain.TemplateId=searchContainer.Template.Id;
                        contain.RecordFields=[];
                        c.forEach(cn=>{
                          let containInner= new ParameterInstanceContainerFilled();
                          containInner.TemplateId=cn.Template.Id
                          this.fillRecordFIelds(containInner, cn, containInner);
                          parent.RecordFields.push(containInner)
                          
                        })
                        //parent.RecordFields.push(contain);
                        
                        
                      })
                    }



                 
                
              }




              showListInStorage()
              {

                if(this.isActive)
                {
                   
                    this.closeForm()
                  
                }
                else
                {
                  let opt=<HTMLElement>this.optionsListElem.nativeElement;
                  opt.style.width="max-content";

                  if(this.haveHints)
                  {

                    
                    
                    let checker = new ParameterInstanceFilledHintAsker();
                    checker.ChangerId = this.UserId;
                    checker.SessionKey = this.SessionKey;
                    checker.Id=this.id;
                    checker.Container= new ParameterInstanceContainerFilled();
                  
                    if(this.ContainerForSearch.ValueType==4)
                    {
                        checker.Container.ValueOfSet=this.ContainerForSearch.ValueSetSelected[this.searchIndex]!=null && this.ContainerForSearch.ValueSetSelected[this.searchIndex].length>0?this.ContainerForSearch.ValueSetSelected[this.searchIndex][0].Id:null;
                        checker.Container.TemplateId=this.ContainerForSearch.Template.Id;
                     
                    }

                    if(this.ContainerForSearch.ValueType==5)
                    {
                      checker.Container.TemplateId=this.ContainerForSearch.Template.Id;
                      
                            this.ContainerForSearch.RecordFields.forEach(f=>{
                             f.forEach(f1=>{
                              this.fill2(f1, checker.Container);
                             })
                              
                            })

                    }

                    this.httpServiceParameters.instanceGetHintsToInstanceContainerFilled(checker).subscribe((data: ParameterHintGiver) => {
                        if (data.Code == 100) {
                            this.unLogin(data.Message);
                            return;
                        }
                        
                        
                          if(data.Code==300)
                          {
                              alert(data.Message);
                              
                          }
                          if(data.Code==301)
                          {
                              alert(data.Message);
                              
                          }
                          if(data.Code==302)
                          {
                              alert(data.Message);
                              
                          }
    
                         
                      
                        if (data.Code == 500) { console.error(data.Message); return; }
                        
                        if(data.Code==0)
                        {


                          

                          this.hintWidth=data.HintsLenght?(data.HintsLenght+5)*14+"px": "fill-available";

                       
                          this.defWidth=data.DefaultHintsLenght? (data.DefaultHintsLenght+5)*14+"px": "fill-available";
                        

                          this.addingType=data.HintedSetType;
                          let hints=[];
                          if(data.Hints!=null && data.Hints.length>0)
                          {
                            data.Hints.forEach(c=>{
                              let hint= new ParameterHintView();
                              hint.FromParameterHint(c, this.PossibleValueSetsIds.filter(c=>c!=this.SetId));
                              hints.push(hint);
                            })
                          }

                          let titles=[];
                          titles.push(new Common(this.id, data.HintedSetName));
                          let notesList=new Map();
                          hints.forEach(h=>{
                            h.Notes.forEach(n=>
                              {
                                notesList.set(n.ValueSetId, n.ValueSetName);
                                
                              })
                          })

                          
                         
                            notesList.forEach((value, key, map) => {
                              titles.push(new Common(key, value))
                              
                            });


                          let buffNotes=[];
                          hints.forEach(h=>
                            {
                              buffNotes=[];
                              buffNotes=_.cloneDeep(h.Notes);
                              h.Notes=[];
                              


                                notesList.forEach((value, key, map) => {
                                  let ind=buffNotes.findIndex(c=>c.ValueSetId==key)
                                  if(ind>-1)
                                  {
                                    h.Notes.push(buffNotes[ind]);
                                  }
                                  else
                                  {
                                    h.Notes.push(null);
                                  }
                                  
                                });
                            })
                          
                          this.valuesList=[{'defaultHints': data.DefaultHints, 'hints':hints, 'titles':titles}];
                          
                          this.valuesListBack=_.cloneDeep(this.valuesList);
                          
                          
                          
                          const viewportHeight = document.documentElement.clientHeight;
                          const viewportWidth = document.documentElement.clientWidth;
                          const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();
                          this.topSearch=selectedListBounds.y+ selectedListBounds.height;
                          this.topList=selectedListBounds.y+ selectedListBounds.height+30;
                          this.bottomSearch=null;
                          this.bottomList=null;
                          



                         
                          this.isHidden=true;
                          
                          
                         // this.hintWidth=30*14+"px";

                       
                         // this.defWidth=40*14+"px";
                        

                          setTimeout(()=>{

                            let el=<HTMLElement>this.optionsListElem.nativeElement;
                            el.style.maxHeight="600px";

                            //////////havehints





                            const dropdownHeight = this.optionsListElem.nativeElement.getBoundingClientRect();
                            


                           
                            this.isActive=true;
                          
                          /*if(dropdownHeight.width<500)
                          {
                            this.listWidth=500;
                          }*/

                          this.listWidth=dropdownHeight.width;

                          
                            
                            if(selectedListBounds.x+dropdownHeight.width<viewportWidth)
                            {
                              this.leftList=selectedListBounds.x;
                              this.rightList=null;
                            }
                            else
                            {
                              this.leftList=null;
                              
                                this.rightList=viewportWidth-selectedListBounds.right;
                                
                                if(viewportWidth-this.rightList<dropdownHeight.width)
                                {
                                  this.leftList=10;
                                  this.rightList=null;
                                }
                            }
                            let opt=<HTMLElement>this.optionsListElem.nativeElement;
                            if(opt.getBoundingClientRect().left+this.listWidth>=viewportWidth)
                            {
                              this.listWidth=viewportWidth-opt.getBoundingClientRect().left-20;
                            }
                              opt.style.width=this.listWidth+"px";
                            if(dropdownHeight.width<selectedListBounds.width)
                            {
                              let opt=<HTMLElement>this.optionsListElem.nativeElement;
                              opt.style.width=selectedListBounds.width+"px";
                              this.listWidth=selectedListBounds.width;
                            }


                            if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                            {
                              this.topSearch=selectedListBounds.y;
                              this.topList=selectedListBounds.y+30;
                              this.bottomSearch=null;
                              this.bottomList=null;
                            }
                            else if(viewportHeight - selectedListBounds.y-selectedListBounds.height+28 -dropdownHeight.height>0)
                            {
        
                              
                              this.topSearch=null;
                              this.topList=null;
                              this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                              this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                            }
                            else
                            {
                              if(viewportHeight-dropdownHeight.top> dropdownHeight.top)
                              {
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=viewportHeight-dropdownHeight.top-5+"px";
                                setTimeout(()=>{this.topSearch=selectedListBounds.y;
                                  this.topList=selectedListBounds.y+30;
                                  this.bottomSearch=null;
                                  this.bottomList=null;},100)
                                
                              }
                              else
                              {
                                
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=selectedListBounds.y-selectedListBounds.height+28 - 5+"px";
                                  this.topSearch=null;
                                  this.topList=null;
                                  this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                                  this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                                
                              }
                            }

                          //  this.isActive=true;
                            




                            



                            setTimeout(()=>{
                              this.isHidden=false;
                              
                              setTimeout(()=>{
                                if(this.selectedValues!=undefined && this.selectedValues!=null && this.selectedValues.length>0)
                                {
                                  this.openValueChanged=true;
                                  if(this.selectedValues!=null && this.selectedValues.length>0)
                                  {
                                    if(this.valuesType<3)
                                      {
                                        this.filterValue=this.selectedValues[0][this.nameKey];
                                      }
                                      if(this.valuesType==3)
                                      {
                                        let dt=this.selectedValues[0][this.nameKey].split(".");
                                          let date = new Date();
                                          date.setUTCHours(0,0,0,0);
                                          date.setUTCFullYear(Number(dt[2]));
                                          date.setUTCDate(Number(dt[0]));
                                          date.setUTCMonth(Number(dt[1])-1);
                                          this.filterValueDate=date;
                      
                                        this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                      }
                                  }
                                  
                                  
                                }
                                else
                                  {
                                    
                                    if(this.selectWithoutAdding && this.searchedText!=null && this.searchedText!='')
                                    {
                                      if(this.valuesType<3)
                                      {
                                        this.filterValue=this.searchedText;
                                      }
                                      if(this.valuesType==3)
                                      {
                                        let dt=this.searchedText.split(".");
                                        
                                        if( dt[0].includes("-") || dt[1].includes("-") || dt[2].includes("-"))
                                        {
                                          
                                          this.filterValueDate=null;
                                          this.filterValueDay=dt[0].includes("-")?null:Number(dt[0])<10?"0"+dt[0]:dt[0];
                        this.filterValueMonth=dt[1].includes("-")?null:Number(dt[1])<10?"0"+Number(dt[1]).toString():Number(dt[1]).toString();
                        this.filterValueYear=dt[2].includes("-")?null:dt[2];
                        
                                        }
                                        else
                                        {
                                          
                                          let date = new Date();
                                          date.setUTCHours(0,0,0,0);
                                          date.setUTCFullYear(Number(dt[2]));
                                          date.setUTCDate(Number(dt[0]));
                                          date.setUTCMonth(Number(dt[1])-1);
                                          this.filterValueDate=date;
                                        }
                      
                                        this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                      }
                                    }
                                  }
                                let se= <HTMLElement>this.searchContainerElem.nativeElement;
                                if(this.valuesType<3)
                                {
                                  se.childNodes.forEach(n=>{
                                    if(n.nodeName=="INPUT")
                                    {
                                      let el=<HTMLElement>n;
                                      
                                     
                                      el.focus();
                                    }
                                  })
                                }
                                else
                                {
                                  this.selectDateFilterElement(0);
                                }
                                
                              },100);
        


                             },100);
                          
                          },100)
                        
                  
                        }
    
                    });  
                  }


                  else
                {

                  

                  let checker = new SingleIdRequest();
                  checker.ChangerId = this.UserId;
                  checker.SessionKey = this.SessionKey;
                  checker.Id=this.id;
                  
                  


                        this.addingType=0;
                        this.valuesList=this.values.map(v=>{
                          let val= new ParameterValue(v.Id, v.Name);
                          val.UsageCount=1;
                          return val;
                        });
                        this.valuesListBack=_.cloneDeep(this.valuesList);





                          const viewportHeight = document.documentElement.clientHeight;
                  const viewportWidth = document.documentElement.clientWidth;
                    const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();
                    this.topSearch=selectedListBounds.y+ selectedListBounds.height;
                    this.topList=selectedListBounds.y+ selectedListBounds.height+30;
                    this.bottomSearch=null;
                    this.bottomList=null;
                    this.listWidth=selectedListBounds.width;
                    
                    this.isHidden=true;
                    
                  setTimeout(()=>{
                    let el=<HTMLElement>this.optionsListElem.nativeElement;
                    el.style.maxHeight="600px";
                    
                    let def=<HTMLElement>document.getElementById("default_hints_scroll_container");
                    this.defWidth=40*14+"px";
                    if(def!=null)
                    {
                     
                      def.style.width=40*14+"px";
                    }
                         
                    let dropdownHeight = this.optionsListElem.nativeElement.getBoundingClientRect();

                    

                   /* if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                    {
                     // this.topSearch=selectedListBounds.y+ selectedListBounds.height;
                     // this.topList=selectedListBounds.y+ selectedListBounds.height+30;
                     this.topSearch=selectedListBounds.y;
                     this.topList=selectedListBounds.y+30;
                      this.bottomSearch=null;
                      this.bottomList=null;
                    }
                    else
                    {
                      this.topSearch=null;
                      this.topList=null;
                      //this.bottomSearch=viewportHeight - selectedListBounds.y;
                     // this.bottomList=viewportHeight - selectedListBounds.y+30;

                      this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                      this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                    }*/

                    this.isActive=true;
                          
                          if(dropdownHeight.width<500)
                          {
                            this.listWidth=500;
                          }

                          this.listWidth=dropdownHeight.width;


                    if(selectedListBounds.x+this.listWidth<viewportWidth)
                            {
                              this.leftList=selectedListBounds.x;
                              this.rightList=null;
                            }
                            else
                            {
                              this.leftList=null;
                              this.rightList=viewportWidth-selectedListBounds.right;
                              if(viewportWidth-this.rightList<dropdownHeight.width)
                                {
                                  this.leftList=10;
                                  this.rightList=null;
                                }
                            }

                            let opt=<HTMLElement>this.optionsListElem.nativeElement;
                            if(opt.getBoundingClientRect().left+this.listWidth>=viewportWidth)
                            {
                              this.listWidth=viewportWidth-opt.getBoundingClientRect().left-20;
                            }
                              opt.style.width=this.listWidth+"px";
                            if(dropdownHeight.width<selectedListBounds.width)
                            {
                              let opt=<HTMLElement>this.optionsListElem.nativeElement;
                              opt.style.width=selectedListBounds.width+"px";
                              this.listWidth=selectedListBounds.width;
                            }

                            if(dropdownHeight.top+dropdownHeight.height<viewportHeight-10)
                            {
                              this.topSearch=selectedListBounds.y;
                              this.topList=selectedListBounds.y+30;
                              this.bottomSearch=null;
                              this.bottomList=null;
                            }
                            else if(viewportHeight - selectedListBounds.y-selectedListBounds.height+28 -dropdownHeight.height>0)
                            {
        
                              
                              this.topSearch=null;
                              this.topList=null;
                              this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                              this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                            }
                            else
                            {
                              if(viewportHeight-dropdownHeight.top> dropdownHeight.top)
                              {
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=viewportHeight-dropdownHeight.top-5+"px";
                                setTimeout(()=>{this.topSearch=selectedListBounds.y;
                                  this.topList=selectedListBounds.y+30;
                                  this.bottomSearch=null;
                                  this.bottomList=null;},100)
                                
                              }
                              else
                              {
                                
        
                                let el=<HTMLElement>this.optionsListElem.nativeElement;
                                el.style.maxHeight=selectedListBounds.y-selectedListBounds.height+28 - 5+"px";
                                  this.topSearch=null;
                                  this.topList=null;
                                  this.bottomSearch=viewportHeight - selectedListBounds.y-selectedListBounds.height-2;
                                  this.bottomList=viewportHeight - selectedListBounds.y-selectedListBounds.height+28;
                                
                              }
                            }

                            
                    this.isActive=true;
                    
                    setTimeout(()=>{this.isHidden=false;
                      setTimeout(()=>{
                        if(this.selectedValues!=undefined && this.selectedValues!=null && this.selectedValues.length>0)
                                {
                                  this.openValueChanged=true;
                                  //this.filterValue=this.selectedValues[0][this.nameKey];
                                  if(this.selectedValues!=null && this.selectedValues.length>0)
                                  {
                                    if(this.valuesType<3)
                                    {
                                    this.filterValue=this.selectedValues[0][this.nameKey];
                                    }
                                    if(this.valuesType==3)
                                      {
                                        let dt=this.selectedValues[0][this.nameKey].split(".");
                                          let date = new Date();
                                          date.setUTCHours(0,0,0,0);
                                          date.setUTCFullYear(Number(dt[2]));
                                          date.setUTCDate(Number(dt[0]));
                                          date.setUTCMonth(Number(dt[1])-1);
                                          this.filterValueDate=date;
                      
                                        this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                      }
                                  }
                                  
                                }

                                else
                                  {
                                    if(this.selectWithoutAdding && this.searchedText!=null && this.searchedText!='')
                                    {
                                      //this.filterValue=this.searchedText;
                                      if(this.valuesType<3)
                                      {
                                        this.filterValue=this.searchedText;
                                      }
                                      if(this.valuesType==3)
                                      {
                                        
                                        let dt=this.searchedText.split(".");
                                        if(dt[0].includes("-") ||dt[1].includes("-") ||dt[2].includes("-") )
                                        {
                                          
                                          this.filterValueDate=null;
                                          this.filterValueDay=dt[0].includes("-")?null:Number(dt[0])<10?"0"+dt[0]:dt[0];
                        this.filterValueMonth=dt[1].includes("-")?null:Number(dt[1])<10?"0"+Number(dt[1]).toString():Number(dt[1]).toString();
                        this.filterValueYear=dt[2].includes("-")?null:dt[2];
                        
                                        }
                                        else
                                        {
                                          let date = new Date();
                                          date.setUTCHours(0,0,0,0);
                                          
                                          date.setUTCFullYear(Number(dt[2]));
                                          date.setUTCDate(Number(dt[0]));
                                          date.setUTCMonth(Number(dt[1])-1);
                                          this.filterValueDate=date;
                                        }
                                        
                                        this.valueChanged.next([this.filterValueDate, this.filterValueDay, this.filterValueMonth, this.filterValueYear])
                                      }
                                    }
                                  }
                        let se= <HTMLElement>this.searchContainerElem.nativeElement;
                        if(this.valuesType<3)
                        {
                          se.childNodes.forEach(n=>{
                            if(n.nodeName=="INPUT")
                            {
                              let el=<HTMLElement>n;
                              
                             
                              el.focus();
                            }
                          })
                        }
                        else
                        {
                          this.selectDateFilterElement(0);
                        }
                      },100);


                      },100);
                  },100)
                      
                /*
                }
  
                  }); */
 
                }   
                  
                }
               
               
              }


              skipValue()
              {
                this.selectedValues=[];
                this.selectedValues=null;
                this.onSelectedValueChange();
              
                this.closeForm()
               // this.isActive=false;
               // this.isHidden=false;
               // this.clear();
              }

              skipHintedValue()
              {
                this.selectedValues=[];
                this.selectedValues=null;
                this.onSelectedValueChange();
              
                this.closeForm()
                // this.isActive=false;
                //this.isHidden=false;
                //this.clear();
              }



              selectValue(val: any)
              {
                let ind=this.valuesListBack.findIndex(c=>c[this.idKey]==val[this.idKey]);
                if(ind>-1)
                {
                  this.selectedValues=[];
                  this.selectedValues.push(_.cloneDeep(this.valuesListBack[ind]));
                  this.onSelectedValueChange();
                  //this.onChangeSize();
                }
                this.closeForm()
               
                
              }


              selectValueFromHint(val: any)
              {

                  this.selectedValues=[];
                  this.selectedValues.push(_.cloneDeep(val));
                  this.onSelectedValueChange();
                  //this.onChangeSize();

               this.closeForm()
                
                
              }

             

              SelectValHint(val: any, note: any, hint: any)
              {
               
                if(note.CanBeInsert)
                {
                  
                  note.Hints.forEach(c=>{
                    if(c.Id!=hint.Id)
                      {
                        c.Selected=false;
                      }
                    }
                  )
                  let sel=null;
                  note.Hints.forEach(c=>{
                    if(c.Selected)
                      {
                        sel=c;
                      }
                    }
                  )
                  
                  note.SelectedHint=sel;
                  if(sel!=null)
                  {
                    let mas=note.Hints.filter(c=>c.Id!=hint.Id);
                    mas.unshift(sel);
                    note.Hints=mas;
                  }

                  
                  this.valuesList[0].hints.forEach(h=>{
                    if(h.HintedValueId!=val.HintedValueId)
                    {
                      h.Notes.forEach(n=>{
                        if(n!=null && n.ValueSetId==note.ValueSetId)
                        {
                          n.Hints.forEach(c=>c.Selected=false);
                        }
                      })
                    }
                  })
                  
                  note.Visible=false;


                  if(this.hoveredElement.Type==4)
                  {
                    let se= <HTMLElement>this.searchContainerElem.nativeElement;
                    se.childNodes.forEach(n=>{
                      if(n.nodeName=="INPUT")
                      {
                        let el=<HTMLElement>n;
                        
                       
                        el.focus();
                      }
                    });
                    this.hoveredElement.Type=3;
                    this.hoveredElement.Id=-1;
                  }
                  


                }
                
              }




              SelectValWithHinted(val: any)
              {
                if(this.selectedValues!=null && this.selectedValues.length>0)
                {
                  if(this.selectedValues[0].Id!=val.HintedValueId)
                  {
                    this.selectedValues=[];
                    this.selectedValues.push(new Common(val.HintedValueId, val.HintedValue));
                    //this.onSelectedValueChange();
                    this.searchedText="";
                    this.SearchFieldText.emit(this.searchedText)
                    this.selectedValuesChange.emit(this.selectedValues);
                    //this.onChangeSize();
                  }
                }
                else
                {
                  this.selectedValues=[];
                  this.selectedValues.push(new Common(val.HintedValueId, val.HintedValue));
                 // this.onSelectedValueChange();
                 this.searchedText="";
                 this.SearchFieldText.emit(this.searchedText)
                  this.selectedValuesChange.emit(this.selectedValues);
                 // this.onChangeSize();
                }

                let hints=[];

                val.Notes.forEach(n=>{
                  if(n!=null)
                  {
                    n.Hints.forEach(h=>
                      {
                        if(h.Selected)
                        {
                          hints.push([n.ValueSetId,h]);
                        }
                      })
                  }
                  
                })

                if(hints.length>0)
                {
                  this.selectedHint.emit(hints);
                  
                }
                else
                {
                  this.onSelect.emit(true);
                  
                }
                






















                this.closeForm()
                //this.isActive=false;
                    //  this.isHidden=false;
                     // this.clear();
              }



              onChangeSize()
              {
                
                setTimeout(()=>{
                  
                  let outer=<HTMLElement>this.selectedListElem.nativeElement;
                  if(outer!=null)
                  {
                    let outerSize=outer.getBoundingClientRect().width;
                    let inner:HTMLElement;
                    if(this.selectedValues!=null && this.selectedValues.length>0)
                    {inner=<HTMLElement>this.valsElem.nativeElement;}
                    if((this.selectedValues==null || this.selectedValues.length==0) && this.selectWithoutAdding && this.searchElem!=undefined)
                    {inner=<HTMLElement>this.searchElem.nativeElement;}
                    
                    if(inner!=undefined && inner!=null && ((this.selectedValues!=null && this.selectedValues.length>0) || ((this.selectedValues==null || this.selectedValues.length==0) && this.selectWithoutAdding)) )
                    {
                      
                      let innerSize=inner.getBoundingClientRect().width;
                      
                      if(innerSize+40>outerSize)
                      {
                       
                        this.changeSize.emit(innerSize+25);
                        setTimeout(()=>{
                          innerSize=inner.getBoundingClientRect().width;
                          outerSize=outer.getBoundingClientRect().width;
                          if(innerSize>outerSize)
                          {
                            inner.style.width=outerSize-10+"px";
                          }
                          
                        },100)
                      }
                    }
                  }
                  
                  
                },100)
                
              }

              onSelectedValueChange()
              {
                this.searchedText="";
                    this.SearchFieldText.emit(this.searchedText)
                this.selectedValuesChange.emit(this.selectedValues);
                this.onSelect.emit(true);
              }

              onValuesListChange()
              {
               // this.valuesChange.emit(this.values);
              }


              searchHintInNote(val: any, note: ParameterHintNoteWithVisible, ev: KeyboardEvent)
              {

                if(ev.keyCode!=13 && ev.keyCode!=37 && ev.keyCode!=38 && ev.keyCode!=39 && ev.keyCode!=40)
                {
                  if (val != null && val!= undefined && val != "") {

                    let noCases:boolean=true;
                    note.Hints.forEach(c=>{
                      if( ExtendedHelper.ContainsNoCaseStrict(c.NameBack, val))
                      {
                        c.Name = SearchSelectHelper.getSearchedText(val, c.NameBack);
                        c.Visible=true;
                        noCases=false;
                      }
                      else
                      {
                        c.Name=c.NameBack;
                        c.Visible=false;
                      }
                    })
                    if(noCases)
                    {
                      this.hoveredElement.Id=-1;
                      this.hoveredElement.Type=4;
                    }
                    else
                    {
                      if(this.hoveredElement.Id!=-1)
                      {
                        let ind=note.Hints.findIndex(c=>c.Visible==true)
                        if(ind>-1)
                        {
                          this.hoveredElement.Id=note.Hints[ind].Id;
                        }
                        
                        this.hoveredElement.Type=4;
                      }
                      
                    }
                   
                }
                else
                {
                  note.Hints.forEach(c=>{
                    c.Name=c.NameBack;
                    c.Visible=true;
                  })
                }
                
                }
                else
                {
                
                }
                    
                
            }


            ShowNoteHints(note: ParameterHintNoteWithVisible, valId: number)
            {
              this.notesVisible=false;
              note.Hints.forEach(c=>{c.Name=c.NameBack;
              c.Visible=true;
              })
              note.Visible=true;

              setTimeout(()=>{

                let form= document.getElementsByClassName("additional-note");
              if(form!=null)
              {

                form[0].childNodes.forEach(n=>{
                  if(n.nodeName=="INPUT")
                  {
                    let el=<HTMLElement>n;
                    el.focus();
                  }
                });


                this.hoveredElement.Type=4;
                this.hoveredElement.Id=-1;
                this.hoveredElement.ParentId=note.ValueSetId;
                this.hoveredElement.RootId=valId;
                
              }
              const viewportHeight = document.documentElement.clientHeight;
              const viewportWidth = document.documentElement.clientWidth;
              let list=<HTMLElement> document.getElementById("additionalList"+note.ValueSetId);
              if(list!=null)
              {
                
                let listRect=list.getBoundingClientRect();
                if(listRect.top+listRect.height>viewportHeight)
                {list.style.bottom="10px";}

                if(listRect.right>viewportWidth)
                {list.style.left=viewportWidth-listRect.width-10+"px";}

                
                this.notesVisible=true;
              }
              
              },100)
             

            }



            checkButtonHintForm(event, note: ParameterHintNoteWithVisible, val:any)
            {
              if(event.keyCode==13 &&  this.hoveredElement.Type==4 && this.hoveredElement.Id!=-1)
              {

                

                let valInd=this.valuesList[0].hints.findIndex(c=>c.HintedValueId==this.hoveredElement.RootId);
                if(valInd>-1)
                {
                  let noteInd=this.valuesList[0].hints[valInd].Notes.findIndex(c=>c!=null && c.ValueSetId==this.hoveredElement.ParentId);
                  if(noteInd>-1)
                  {
                    let hintInd=this.valuesList[0].hints[valInd].Notes[noteInd].Hints.findIndex(c=>c.Id==this.hoveredElement.Id);
                    if(hintInd>-1)
                    {
                      this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected=!this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd].Selected;
                      this.SelectValHint(this.valuesList[0].hints[valInd], this.valuesList[0].hints[valInd].Notes[noteInd], this.valuesList[0].hints[valInd].Notes[noteInd].Hints[hintInd] );
                    }
                  }
                  
                }
              }

              if(event.keyCode==37) {
                      
                //left
                this.pressLeft();
                }
                if(event.keyCode==38) {
                  //up
                  this.pressUp();
                }
                if(event.keyCode==39)
                {
                  //right
                  
                  this.pressRight();
                }
                if(event.keyCode==40) {
                  //down
                  
                  this.pressDown();
                  
                }
                
            }


 parseDate(dateString: string): Date {
 
                if (dateString) {
                    return new Date(dateString);
                }
                return null;
              }



              onDateFilterChange(event: Event= null)
              {
               
                
              // this.filterValueDay=this.filterValueDate.getDate()<10?"0"+this.filterValueDate.getDate().toString():this.filterValueDate.getDate().toString();
               //this.filterValueMonth=this.filterValueDate.getMonth()+1<10? "0"+(this.filterValueDate.getMonth()+1).toString():(this.filterValueDate.getMonth()+1).toString();
               //this.filterValueYear=this.filterValueDate.getFullYear().toString();
                this.search();
              }


              selectDateFilterElement(type: number=0)
              {


                this.selectInputDate.next(type);
                  /*let inp;
                  if(type==0)
                  {
                   inp =<HTMLElement>this.dayInputElem.nativeElement;
                   this.dayInputElem.nativeElement.focus();
                  }
                  if(type==1)
                  {
                    inp =<HTMLElement>this.monthInputElem.nativeElement;
                    this.monthInputElem.nativeElement.focus();
                  }
                  if(type==2)
                  {
                    inp =<HTMLElement>this.yearInputElem.nativeElement;
                    this.yearInputElem.nativeElement.focus();
                  }
                  
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                  this.lastDateField=-1;*/

                  
              }


              checkButtonDate(ev:KeyboardEvent)
              {
                if(ev.keyCode==27)
                {
                    this.clear();
                }
                /*
                if(ev.keyCode==13 && this.addingType>0 && this.hoveredElement.Type==0 && !this.selectWithoutAdding)
                {

                 
                  if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                  {
                    //this.filterValue="";
                    this.closeForm();
                    
                  }
                  else
                  {
                    this.addNew();
                  }
                    
                }

               if(ev.keyCode==13 && this.hoveredElement.Type==0 && this.selectWithoutAdding)
                {

                  
                  if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                  {
                    
                    this.closeForm();
                  }
                  else
                  {

                    this.changeSearchedText(null);
                    ev.stopPropagation()
                  }
                  
                }
*/

                if(ev.keyCode==38) {
                  //up
                  
                  if(this.haveHints)
                  {
                    this.pressUp();
                  }
                  else
                  {
                    this.pressUpWithoutHints();
                  }
                }
                
                if(ev.keyCode==40) {
                  //down
                  if(this.haveHints)
                  {
                    this.pressDown();
                  }
                  else
                  {
                    this.pressDownWithoutHints();
                  }
                }


                if(ev.keyCode==8 || ev.keyCode==46 || ev.key.match(/^\d$/))
                {
                  
                  this.search();
                }

                

              }

/*
              checkButtonDay(ev:KeyboardEvent)
              {
                
                ev.preventDefault();


                if(ev.keyCode==27)
                  {
                      this.clear();
                  }
                  if(ev.keyCode==13 && this.addingType>0 && this.hoveredElement.Type==0 && !this.selectWithoutAdding)
                  {

                   
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      //this.filterValue="";
                      this.closeForm();
                      
                    }
                    else
                    {
                      this.addNew();
                    }
                      
                  }

                 if(ev.keyCode==13 && this.hoveredElement.Type==0 && this.selectWithoutAdding)
                  {

                    
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      
                      this.closeForm();
                    }
                    else
                    {
                      this.changeSearchedText();
                      ev.stopPropagation()
                    }
                    
                  }






                if(ev.keyCode==39)
                {
                  this.monthInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.monthInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                  
                }

                
                
                  if(ev.keyCode==38) {
                    //up
                    
                    if(this.haveHints)
                    {
                      this.pressUp();
                    }
                    else
                    {
                      this.pressUpWithoutHints();
                    }
                  }
                  
                  if(ev.keyCode==40) {
                    //down
                    if(this.haveHints)
                    {
                      this.pressDown();
                    }
                    else
                    {
                      this.pressDownWithoutHints();
                    }
                  }



                if(ev.keyCode==8 || ev.keyCode==46)
                {
                  this.filterValueDate=null;
                  this.filterValueDay=null;
                  this.lastDateField=-1;
                  this.dayInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.dayInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                  this.search();
                }
                
                if(ev.key.match(/^\d$/))
                {
                if(this.lastDateField==0)
                {
                  //добавляем к уже имеющейся
                  if(this.filterValueDay.toString().length==2 && this.filterValueDay[0]!="0")
                  {
                    this.selectDateFilterElement(1);
                    this.checkButtonMonth(ev);
                  }
                  else
                  {
                    let newVal=Number(this.filterValueDay.toString()+ev.key);
                    if (newVal>31)
                    {
                      newVal=31;
                      
                    }
                  this.filterValueDay=newVal.toString();
                  this.selectDateFilterElement(1);
                  }
                }
                else
                {
                  //затираем старое значение
                 
                  this.filterValueDay="0"+ev.key;

                  this.lastDateField=0;
                  if(Number(this.filterValueDay)>3)
                  {
                    this.selectDateFilterElement(1);
                  }
                 
                }
                if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
                {
                  let newDate= new Date();
                  newDate.setUTCDate(Number(this.filterValueDay));
                  newDate.setUTCMonth(Number(this.filterValueMonth)-1);
                  newDate.setUTCFullYear(Number(this.filterValueYear));
                  this.filterValueDate=newDate;
                }
                else
                {
                  this.filterValueDate=null;
                }
                this.search();
              }
                
              }

              checkButtonMonth(ev:KeyboardEvent)
              {
                ev.preventDefault()

                
                if(ev.keyCode==27)
                  {
                      this.clear();
                  }
                  if(ev.keyCode==13 && this.addingType>0 && this.hoveredElement.Type==0 && !this.selectWithoutAdding)
                  {

                   
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      //this.filterValue="";
                      this.closeForm();
                      
                    }
                    else
                    {
                      this.addNew();
                    }
                      
                  }

                 if(ev.keyCode==13 && this.hoveredElement.Type==0 && this.selectWithoutAdding)
                  {

                    
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      
                      this.closeForm();
                    }
                    else
                    {
                      this.changeSearchedText();
                      ev.stopPropagation()
                    }
                    
                  }



                if(ev.keyCode==37)
                {
                  this.dayInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.dayInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);

                }
                if(ev.keyCode==39)
                {
                  this.yearInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.yearInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                }


                if(ev.keyCode==38) {
                  //up
                  
                  if(this.haveHints)
                  {
                    this.pressUp();
                  }
                  else
                  {
                    this.pressUpWithoutHints();
                  }
                }
                
                if(ev.keyCode==40) {
                  //down
                  if(this.haveHints)
                  {
                    this.pressDown();
                  }
                  else
                  {
                    this.pressDownWithoutHints();
                  }
                }


                if(ev.keyCode==8 || ev.keyCode==46)
                {
                  this.filterValueDate=null;
                  this.filterValueMonth=null;
                  this.lastDateField=-1;
                  this.monthInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.monthInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                  this.search();
                }

                if(ev.key.match(/^\d$/))
                {
                if(this.lastDateField==1)
                {
                  //добавляем к уже имеющейся
                  
                  if(this.filterValueMonth.toString().length==2 && this.filterValueMonth[0]!="0")
                  {
                    this.selectDateFilterElement(2);
                    this.checkButtonYear(ev);
                  }
                  else
                  {
                    let newVal=Number(this.filterValueMonth.toString()+ev.key);
                    if (newVal>12)
                    {
                      newVal=12;
                      
                    }
                  this.filterValueMonth=newVal.toString();
                  this.selectDateFilterElement(2);
                  }
                    
                  
                }
                else
                {
                  //затираем старое значение
                  
                  this.filterValueMonth="0"+ev.key;
                  this.lastDateField=1;
                  if(Number(this.filterValueMonth)>1)
                  {
                    this.selectDateFilterElement(2);
                  }
                  
                }

                if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
                {
                  let newDate= new Date();
                  newDate.setUTCDate(Number(this.filterValueDay));
                  newDate.setUTCMonth(Number(this.filterValueMonth)-1);
                  newDate.setUTCFullYear(Number(this.filterValueYear));
                  this.filterValueDate=newDate;
                }
                else
                {
                  this.filterValueDate=null;
                }
                this.search();
              }

              }

              checkButtonYear(ev:KeyboardEvent)
              {
                ev.preventDefault()

                
                if(ev.keyCode==27)
                  {
                      this.clear();
                  }
                  if(ev.keyCode==13 && this.addingType>0 && this.hoveredElement.Type==0 && !this.selectWithoutAdding)
                  {

                   
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      //this.filterValue="";
                      this.closeForm();
                      
                    }
                    else
                    {
                      this.addNew();
                    }
                      
                  }

                 if(ev.keyCode==13 && this.hoveredElement.Type==0 && this.selectWithoutAdding)
                  {

                    
                    if(this.filterValueDay==null || this.filterValueDay=='' || this.filterValueMonth==null || this.filterValueMonth=='' || this.filterValueYear==null || this.filterValueYear=='' )
                    {
                      
                      this.closeForm();
                    }
                    else
                    {
                      this.changeSearchedText();
                      ev.stopPropagation()
                    }
                    
                  }


                
                if(ev.keyCode==37)
                {
                  this.monthInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.monthInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                }


                if(ev.keyCode==38) {
                  //up
                  
                  if(this.haveHints)
                  {
                    this.pressUp();
                  }
                  else
                  {
                    this.pressUpWithoutHints();
                  }
                }
                
                if(ev.keyCode==40) {
                  //down
                  if(this.haveHints)
                  {
                    this.pressDown();
                  }
                  else
                  {
                    this.pressDownWithoutHints();
                  }
                }

                
                if(ev.keyCode==8 || ev.keyCode==46)
                {
                  this.filterValueDate=null;
                  this.filterValueYear=null;
                  this.lastDateField=-1;
                  this.yearInputElem.nativeElement.focus();
                  let inp =<HTMLElement>this.yearInputElem.nativeElement;
                  let selection= document.getSelection();
                  selection.removeAllRanges();
                  let range = document.createRange();
                  range.selectNode(inp)
                  selection.addRange(range);
                  this.search();
                }

                if(ev.key.match(/^\d$/))
                  {
                if(this.lastDateField==2)
                {
                  //добавляем к уже имеющейся
                  //добавляем к уже имеющейся
                  if(this.filterValueYear.toString().length<4 ||this.filterValueYear.toString()[0]=='0')
                  {
                    this.filterValueYear=this.filterValueYear.substring(1)+ev.key;
                  }
                  
                }
                else
                {
                  //затираем старое значение
                  
                  this.filterValueYear="000"+ev.key;
                  this.lastDateField=2
                  
                }

                if(this.filterValueDay!=null && this.filterValueDay!="" && this.filterValueMonth!=null && this.filterValueMonth!="" && this.filterValueYear!=null && this.filterValueYear!="")
                {
                  let newDate= new Date();
                  newDate.setUTCDate(Number(this.filterValueDay));
                  newDate.setUTCMonth(Number(this.filterValueMonth)-1);
                  newDate.setUTCFullYear(Number(this.filterValueYear));
                  this.filterValueDate=newDate;
                }
                else
                {
                  this.filterValueDate=null;
                }
                this.search();
              }
              }*/
              

}


import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {CommonRequest, CommonResponse, SingleIdRequest} from "../models/model.http";
import {ServerString} from "../config/serverstring";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {
  ParameterTypeCreateAsker, ParameterTypeEditAsker, ParameterTypeRotateAsker,
  ParameterInProjectAddDeleteDefaultAsker, ParameterInProjectEditAsker, ClientParameterTemplateCreateAsker, ClientParameterTemplateEditAsker, ClientParameterRotateAsker, ClientParameterInstanceAddAsker, ClientParameterInstanceEditAsker, ValueSetCreateAsker, ValueSetEditAsker, ParameterTemplateCreateAsker, ParameterTemplateListAsker, ParameterTemplateEditAsker, ParameterRotateAsker, ParameterInstanceAddAsker, ParameterInstanceEditAsker, RecordTemplateCreateAsker, RecordTemplateEditAsker, ValueSetOfInstanceAsker, ParameterHintEditAsker, ParameterHintAddAsker, StorageItemNameParameterTemplateEditAsker
} from "../models/model.parameters";
import { ParameterInstanceFilledHintAsker } from '../models/model.storage';
/**
 * Created by MosNeuro on 03.04.2018.
 */
 @Injectable({
  providedIn: 'root'
})
export class HttpServiceParameters {

  constructor(private http: HttpClient, private param: ServerString) {
  }


/********VALUE SETS********/

parameterValueSetAdd(obj: ValueSetCreateAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterValueSetListGet(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterValueSetGetContainer(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/GetContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterValueSetGetListForNewTemplate(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/GetListForNewTemplate', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterValueSetEdit(obj: ValueSetEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/Edit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterValueSetRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/ValueSet/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}




/********RECORDS********/

parameterRecordTemplateListGet(obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterRecordTemplateAdd(obj: RecordTemplateCreateAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterRecordTemplateRemoveCheck (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/RemoveCheck', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterRecordTemplateRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterRecordTemplateGetContainer(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/GetContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterRecordTemplateEdit(obj: RecordTemplateEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/Edit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterRecordTemplateGetFieldsOfRecordTemplate(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/RecordTemplate/GetFieldsOfRecordTemplate', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


/********TEMPLATES********/


parameterTemplateAdd(obj: ParameterTemplateCreateAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterTemplateGetList(obj: ParameterTemplateListAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterTemplateGetContainer(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/GetContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterTemplateEdit(obj: ParameterTemplateEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Edit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterTemplateMove(obj: ParameterRotateAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Move', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterTemplateRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}













/***********INSTANCES************/




parameterInstanceGetTemplatesToAttach(obj: ParameterTemplateListAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetTemplatesToAttach', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterInstanceAdd(obj: ParameterInstanceAddAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}





parameterInstanceGetAttachedList(obj: ParameterTemplateListAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetActualList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterInstanceAddElementToList(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/AddElementToList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterInstanceRemoveElementFromList(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/RemoveElementFromList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterInstanceAddElementToFieldList(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/AddElementToFieldList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterInstanceRemoveElementFromFieldList(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/RemoveElementFromFieldList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}





parameterInstanceEditValue(obj: ParameterInstanceEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/EditValue', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterInstanceEditRecordFieldValue(obj: ParameterInstanceEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/EditFieldValue', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterInstanceSetToDefaultValue(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/SetValueToDefault', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}







parameterInstanceSetRecordFieldToDefaultValue(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/SetFieldValueToDefault ', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterInstanceRemove(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterInstanceRemoveList(obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/RemoveList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterInstanceGetValueSet (obj: ValueSetOfInstanceAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetValueSet ', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



/************************HINTS***************************/

parameterInstanceGetHintsToField (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetHintsToField', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterInstanceGetHintsToParameter (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetHintsToParameter', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}



parameterHintGetList (obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Hint/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterHintAdd (obj: ParameterHintAddAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Hint/Add', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterHintEdit (obj: ParameterHintEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Hint/Edit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

parameterHintRemove (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Hint/Remove', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


parameterHintGetContainer (obj: SingleIdRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Hint/GetContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}





/************************************СЛУЖЕБНЫЙ ПАРАМЕТР****************************************/



serviceParameterGetList (obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Custom/GetList', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}

serviceParameterStorageItemNameGetContainer (obj: CommonRequest) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Custom/StorageItemName/GetContainer', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


serviceParameterStorageItemNameEdit (obj: StorageItemNameParameterTemplateEditAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Template/Custom/StorageItemName/Edit', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}


















/************************ПОДСКАЗКИ К  СКЛАДУ***********************8 */
instanceGetHintsToInstanceContainerFilled  (obj: ParameterInstanceFilledHintAsker) {
  const body = "'" + JSON.stringify(obj) + "'";
  let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  return this.http.post(this.param.fullServerStringAdditional()+'ParameterV2/Instance/GetHintsToInstanceContainerFilled ', body, {headers: headers, withCredentials: true}).pipe(
    catchError((error: any) => {return observableThrowError(error);}));
}
}

/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { Chats_LangSet, Common_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { ChatCreateAsker, ChatInList, ChatListGiver, ChatNotificator, ChatRefreshAsker, ChatSearchAsker, ChatSearched, ChatSearchGiver, MessageSearched } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { cloneDeep } from 'lodash';
import { SearchSelectHelper } from '../../../Helpers/SearchSelectHelper';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'chatListserachResultContainer',
    templateUrl: 'temp.chatListserachResultContainer.html',
    styleUrls: ['chatListserachResultContainer.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class chatListserachResultContainerComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;
    
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;

    //ChatAccess: boolean = false;
    @Output() SelectedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() AddChatWithUserId: EventEmitter<number> = new EventEmitter<number>();
    @Output() SelectedMessageChatId: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Input() Search: Subject<string> = new Subject<string>();
    //@Input() FilterForSearch: string="";

    Chats: ChatSearched[]=[];
    Messages: MessageSearched[]=[];

    MessagesSearching: boolean = false;
    ChatsSearching: boolean = false;
    //CurrentOpenedChat: 
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;
    langset: Common_LangSet;
    ln_sub: Subscription=null;
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private languageService: LanguageService,
    private sessinService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //????????????????
                if(this.Search.observers==undefined ||this.Search.observers==null || this.Search.observers.length>0)
                {
                  this.Search.observers=[];
                }
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                
                this.sessionKey$=this.sessinService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessinService.userId$.subscribe(id => this.UserId = id);
                this.ln_sub=this.languageService.commonLanguage$.subscribe(lang=>this.langset=lang);
                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);

                
                this.Search.subscribe((filter)=>{
                  this.SearchChats(filter);
                  this.SearchMessages(filter);
                })

                
                if (!(this.UserId > 0)) {//куков нет значит не авторизованы значит идем логниться
                  this.unLogin();
                }
                else {//куки есть, чекаем сессию
                  
                      
                        this.extendedHelper.ResetLastMessageTime();
                        //this.GetChatList();
                      
                    
                }

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessinService.unLogin();
              }

            

             
       

              SearchMessages(filter: string)
              {
                this.MessagesSearching=true;
                filter=this.extendedHelper.ReplaceBadThings(filter);
                let checker = new ChatSearchAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Mode=1;
                checker.Value=this.extendedHelper.ReplaceBadThings(filter);
                this.httpServiceChat.chatSearchCommon(checker).subscribe((data: ChatSearchGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Messages=[];
                    
                    //Выделение

                    if (!ExtendedHelper.IsNullOrEmpty(filter)) 
                    { 
                      for(let i=0; i<data.Messages.length; i++)
                      {
                        this.Messages.push(data.Messages[i]);
                        let chatsLen = this.Messages.length - 1;
                        this.Messages[chatsLen].Message = SearchSelectHelper.getSearchedText(filter, this.Messages[chatsLen].Message);
                        
                      }
                      
                     }
                     else
                     {
                      this.Messages=data.Messages;
                     }
                    
                     this.MessagesSearching=false;    
                  }
                  
                })

              }

              SearchChats(filter: string)
              {
                this.ChatsSearching=true;
                filter=this.extendedHelper.ReplaceBadThings(filter);
                let checker = new ChatSearchAsker();
                checker.ChangerId = this.UserId;
                checker.SessionKey = this.SessionKey;
                checker.Mode=0;
                checker.Value=this.extendedHelper.ReplaceBadThings(filter);;
                this.httpServiceChat.chatSearchCommon(checker).subscribe((data: ChatSearchGiver) => {
                  if (data.Code == 100) {
                    this.unLogin();
                    return;
                  }
                  if (data.Code == 300) {
                    console.log(data.Message)
                    return;
                  }
                  

                 
                  if (data.Code == 500) { console.error(data.Message); return; }

                  if (data.Code==0)
                  {
                    this.Chats=[];
                    //Выделение


                    if (!ExtendedHelper.IsNullOrEmpty(filter)) 
                    { 
                      for(let i=0; i<data.Chats.length; i++)
                      {
                        this.Chats.push(data.Chats[i]);
                        let chatsLen = this.Chats.length - 1;
                        this.Chats[chatsLen].Name = SearchSelectHelper.getSearchedText(filter, this.Chats[chatsLen].Name);
                        
                      }
                      
                     }
                     else
                     {
                      this.Chats=data.Chats;
                     }
                     this.ChatsSearching=false;
                  }
                })
              }


              GoToChat(chat: ChatSearched)
              {
                if(chat.IsPotential)
                {
                  this.AddChatWithUserId.emit(chat.Id);
                }
                else
                {
                  this.SelectedChatId.emit(chat.Id);
                }
              }

              GoToMessage(message: MessageSearched)
              {
                this.SelectedMessageChatId.emit([message.Id, message.ChatId])
              }




}

import { initialUserState, IUserState } from "../state/user.state";
import * as userActions from '../actions/user.action';
import { Action, createReducer, on } from '@ngrx/store';

const UserReducer = createReducer(
  initialUserState,
  on(userActions.SetUser, (state, payload) => ({
    ...state,
    currectUser: payload
  })),
  on(userActions.GetUser, state => ({
    ...state
  }))
)

export function userReducer(state: IUserState | undefined, action: Action) {
  return UserReducer(state, action);
}

import {CommonRequest} from "../../model.http";

export class PersonMakeMainAsker extends CommonRequest {
  ProjectId: number;
  PersonId: number;

  constructor(userId:number, sessionKey:string, projectId:number, personId:number)
  {
    super(userId, sessionKey);
    this.ProjectId = projectId;
    this.PersonId = personId;
  }
}


import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
/**
 * Created by MosNeuro on 24.11.2016.
 */
import { Injectable } from '@angular/core'; import { ServerString } from "../config/serverstring";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {
  ProjectAddAsker, ProjectEditAsker, ProjectsAndStageSetsAsker,
  ProjectsAdditionalAsker, ProjectChangeStageAsker, ProjectInitialSearchAsker, ProjectAdditionalSearchAsker
} from '../models/model.project';



import { FilterProject } from "../models/model.FilterProject";
import { CommonRequest, RenameAsker, SingleIdRequest } from '../models/model.http';
import { ClientListPossibleAsker } from "../models/model.Client/Out/model.ClientListPossibleAsker";
import { ClientAttachAsker } from "../models/model.Client/Out/model.ClientAttachAsker";
import { AutoJobListResreshAsker, ChangeStageWithAutoJobsAsker } from '../models/model.Autojob/model.AutojobTemplate';

@Injectable()
export class HttpServiceProject {

  constructor(private http: HttpClient, private param: ServerString) {
  }

  getProjects() {
    return this.http.get(this.param.fullServerString() + 'project', { withCredentials: true });
  }

  /*clientsAttachedLoad(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'ClientsAttachedToProjectShow', body, {headers: headers, withCredentials: true})
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }*/

  clientsAttachedLoad(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/AttachedClients/ListGet', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  attachClient(obj: ClientAttachAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/AttachedClients/Attach', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  unfastenClient(obj: ClientAttachAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/AttachedClients/Unfasten', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  loadPossibleClientsToAttach(obj: ClientListPossibleAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/AttachedClients/GetPossibleList', body, { headers: headers, withCredentials: true }).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }


  getProjectById(obj: SingleIdRequest) {
    let link = this.param.fullServerString() + 'project/GetLightSingle';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  addProject(obj: ProjectAddAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/Add', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  editProject(obj: RenameAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/Rename', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  deleteProjectById(obj: SingleIdRequest) {
    let link = this.param.fullServerString() + 'Project/Delete';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }


  loadProjectsAndStageSets(obj: ProjectsAndStageSetsAsker) {
    let link = this.param.fullServerString() + 'ProjectsAndStageSetsFilteredGet';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  loadProjectsAdditional(obj: ProjectsAdditionalAsker) {
    let link = this.param.fullServerString() + 'ProjectsAdditionalGet';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }


  searchProjectsAndStageSetsInitialMain(obj: CommonRequest) {
    let link = this.param.fullServerString() + 'Project/Search/InitialMain';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  searchProjectsAndStageSetsInitial(obj: ProjectInitialSearchAsker) {
    let link = this.param.fullServerString() + 'Project/Search/Initial';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  searchProjectsAndStageSetsAdditional(obj: ProjectAdditionalSearchAsker) {
    let link = this.param.fullServerString() + 'Project/Search/Additional';
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(link, body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }


  loadProjectContainer(obj: SingleIdRequest) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/Get', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  changeProjectStage(obj: ProjectChangeStageAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'Project/ChangeStage', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  changeProjectStageCreateAutojobs(obj: ChangeStageWithAutoJobsAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'AutoJob/project/ChangeStageWithAutoJobs', body, { headers: headers, withCredentials: true }).pipe(
      //map((resp:Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }

  RefreshAuthorList(obj: AutoJobListResreshAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'AutoJob/project/RefreshAuthors', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }


  RefreshExecutorList(obj: AutoJobListResreshAsker) {
    const body = "'" + JSON.stringify(obj) + "'";
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
    return this.http.post(this.param.fullServerString() + 'AutoJob/project/RefreshExecutors', body, { headers: headers, withCredentials: true }).pipe(
      //.map((resp: Response)=>resp.json())
      catchError((error: any) => { return observableThrowError(error); }));
  }



}

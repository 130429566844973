/**
 * Created by MosNeuro on 30.11.2016.
 */
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations'

import {LocalStorageService} from 'angular-2-local-storage';

import { QueryLite, EditQueryNameAsker, DraggableTreeElement, DraggableListElement, AddQueryGiver, ProjectionLiteGiver, LoadElementAndQueryAsker, ProjectionLite, EditOrderAsker, AddChildElementAsker, DraggableListElementFull, ProjectionParent } from '../../../models/model.Analytic/model.analyticQuery';
import { SessionInfo } from '../../../models/model.session';
import { HttpServiceAnalytic } from '../../../httpserv/httpserv.analytic';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { CookieOptionsMy } from '../../../config/CookieOptionsMy';
import { HttpServiceSession } from '../../../httpserv/httpserv.session';
import { ExtendedHelper } from '../../../config/ExtendedHelper';
import { SingleIdRequest, CommonResponse, CommonRequest, SingleStringGiver } from '../../../models/model.http';
import { DiagramCloneAsker, DiagramLite, DiagramLiteGiver, EditLinearChartCurveAsker } from '../../../models/model.Analytic/model.analyticDiagaram';
import * as _ from 'lodash';
import { Common } from '../../../models/model.common';
import { CommonWithSelect } from '../../../models/model.commonWithSelect';
import { Observable, Subject, Subscription, fromEvent as observableFromEvent} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSessionKey, selectUserId } from '../../../store/selectors/session.selectors';
import { JobChangeStatusContainer } from '../../../models/model.Job/Inner/model.JobChangeStatusContainer';
import { JobCrudContainer } from '../../../models/model.Job/Inner/model.JobCrudContainer';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { WorkerGiver, WorkerInJobFilterGiver, WorkerProfile } from '../../../models/model.worker';
import { CalendarDay, CalendarMonth, CalendarMonthAsker, CalendarMonthGiver, CalendarSideJobsAsker, CalendarWeek, DayJobsAdditionalAsker, DayJobsGiver, DayToBack,  SideJobsGiver,  SideJobsToBack,  WeekDayAsker, WeekToBack } from '../../../models/model.calendar';
import { JobGiver, JobLight, JobToSend } from '../../../models/model.job';
import { AccessJobPersonGiver } from '../../../models/model.accessGiver';
import { Chats_LangSet, LanguageSetCommon, LanguageSetCommonGiver } from '../../../models/model.language';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { VersionGiver } from '../../../config/Version';
import { HttpServiceJob } from '../../../httpserv/httpserv.job';
import { HttpServiceWorker } from '../../../httpserv/httpserv.worker';
import { HttpServiceAccessGroup } from '../../../httpserv/httpserv.accessGroup';
import { HttpServiceLanguage} from '../../../httpserv/httpserv.language';
import { HttpServiceTag } from '../../../httpserv/httpserv.tag';
import { HttpServiceAccessGiver } from '../../../httpserv/httpserv.accessGiver';
import { HttpServiceService } from '../../../httpserv/httpService.service';
import { HttpServiceJobNew } from '../../../httpserv/httpserv.jobNew';
import { HttpServiceCalendar } from '../../../httpserv/httpserv.calendar';
import { LogsHelper } from '../../../Helpers/LogsHelper';
import { Title } from '@angular/platform-browser';
import { IdToId } from '../../../models/model.idtoid';
import { DeleteSession } from '../../../store/actions/session.action';
import { JobAddAsker } from '../../../models/model.Job/Out/model.JobAddAsker';
import { DateExtended } from '../../../models/model.date';
import { JobEditAsker } from '../../../models/model.Job/Out/model.JobEditAsker';
import { TagEntityTypeEnum } from '../../../models/model.tag';
import { ElementWithInnerSelectable } from '../../../models/model.elementWithVisible';
import { ChatCreateAsker, ChatInList, ChatListGiver, ChatOpenedSmall, ChatSearchAsker, FullOpenedChat } from '../../../models/model.chat';
import { map } from 'rxjs/operators';
import { StageToBackLightSelect } from '../../../models/model.stageSet';
import { HttpServiceChat } from '../../../httpserv/httpserv.chat';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';

declare var require: any;


@Component({
    selector: 'openedChatsPanel',
    templateUrl: 'temp.openedChatsPanel.html',
    styleUrls: ['openedChatsPanel.scss',
        '../../../../iconfont/material-icons.scss',
        '../../../../style/supportLibrary.scss',
        '../../../../style/font-awesome.min.scss'],

        providers: [HttpServiceSession,  HttpServiceJob, HttpServiceWorker, HttpServiceAccessGroup,
          HttpServiceTag, HttpServiceLanguage, CookieOptionsMy,
          HttpServiceAccessGiver, HttpServiceCalendar,  ExtendedHelper, 
          HttpServiceService, LogsHelper, HttpServiceJobNew]

})
export class openedChatsPanelComponent implements OnInit {
    

    
    UserId: number = 0;
    UserName: string;
    SessionKey: string;

    
    
    langset: LanguageSetCommon = new LanguageSetCommon();
    SystemVersion: VersionGiver = new VersionGiver();
    sessionKey$: Subscription=null;
    userId$: Subscription=null;
    chats_Langset: Chats_LangSet;
    ch_sub: Subscription=null;

    //ChatAccess: boolean = false;

    @Input() LoadedChats: FullOpenedChat[]=[];
    @Input() CurrentChatId: number=-1;
    @Output() SelectedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() ClosedChatId: EventEmitter<number> = new EventEmitter<number>();
    @Output() BackToListEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


    //CurrentOpenedChat: 
   
    
    
  constructor(
    private store$: Store,
    private router: Router,
    private cookieService: CookieService,
    private cookieOptionsMy: CookieOptionsMy,
    private titleService: Title,
    private httpServiceCalendar: HttpServiceCalendar,
    private httpServiceSession: HttpServiceSession,
    private httpServiceJob: HttpServiceJob,
    private httpServiceWorker: HttpServiceWorker,
    private httpServiceAccessGiver: HttpServiceAccessGiver,
    private httpServiceLanguage: HttpServiceLanguage,
    private extendedHelper: ExtendedHelper,
    private localStorageService: LocalStorageService,
    private httpServiceChat: HttpServiceChat,
    private languageService: LanguageService,
    private sessionService: SessionService
              ) { }




            
            
              ngOnDestroy() {
                //
                
                
                
              }
            
            
              goToMain(event: any) {
                if (event == null || event.button == 0 && !event.ctrlKey) {
                  if (event != null) event.preventDefault();
                  let link = ['/main'];
                  this.router.navigate(link);
                }
              }
            
            
            
            
            
              ngOnInit() 
              {
                this.sessionKey$=this.sessionService.sessionKey$.subscribe(key => this.SessionKey = key);
                this.userId$=this.sessionService.userId$.subscribe(id => this.UserId = id);

                this.ch_sub=this.languageService.chatsLanguage$.subscribe(lang=>this.chats_Langset=lang);
                

                
              }


              
            
            
              unLogin(message: string = null) {
                if (!ExtendedHelper.IsNullOrEmpty(message)) {
                  console.log(message);
                }
                this.sessionService.unLogin();
              }

              GoToChat(id: number)
              {
                this.SelectedChatId.emit(id);
              }

              CloseChat(id: number)
              {
                this.ClosedChatId.emit(id);
              }

              BackToList()
              {
                this.BackToListEvent.emit(true);
              }
             

}
